import { GST_TYPE, TAX_SYSTEM } from '../Constants/Constant';
import { TdsInfoList } from './TdsInfoList';

export interface DocumentItem {
  lineNumber: number;
  unitPrice: number;
  productName: string;
  productCode: string;
  productQuantity: number;
  product?: any; //TODO use Product model
  productDescription?: string;
  documentItemCode?: string;
  discount?: any;
  discountInPercent?: boolean;
  isRcmApplied?: boolean;
  totalAmount?: number;
  id?: number;
  tax?: any; //TODO use Tax model
  basePrice?: number;
  allocationType?: any;
  revenueRecognitionInfo?: any;
  taxAmount?: number;
  taxCode?: string;
  taxName?: string;
  currency?: string;
  taxSystem?: string;
  customField?: any[];
  type?: string;
  availableQuantity?: number;
  unitPriceGstInclusive?: boolean;
  account?: any;
  accountCode?: string;
  accountDescription?: string;
  isPartialInvoice?: boolean;
  isPartialBill?: boolean;
  linkedQuoteItem?: number;
  linkedOrderItem?: number;
  pendingQuantity?: number;
  pendingAmount?: number;
  fulfilledQuantity?: number;
  quantityFulfilled?: number;
  fulfillmentByDoc?: any;
  advancedTracking?: any;
  tdsInfoIndia?: TdsInfoList;
  isTdsApplicableContact?: boolean;
  isTdsApplicableProduct?: boolean;
  isTdsApplicableAccount?: boolean;
  receivedQuantityInBills?: number;
  quantityInParent?: number;
  receivedQuantityInOrder?: number;
  documentUom?: number;
  stockUom?: number;
  documentUOMSchemaDefinition?: any;
  uomQuantity?: number;
  uomUnitPrice?: number;
  uomAvailableQuantity?: number;
  uomPendingQuantity?: number;
  uomFulfilledQuantity?: number;
  uomQuantityFulfilled?: number;
  uomReceivedQuantityInBills?: number;
  uomReceivedQuantityInOrder?: number;
  reservedQuantitiesData?: any;
  purchaseInvoiceItemCode?: string;
  purchaseOrderItemCode?: string;
  jobWorkOutOrderItemCode?: string;
  quotationItemCode?: string;
  salesInvoiceItemCode?: string;
  salesOrderItemCode?: string;
  exchangeRate?: any;
  invalidFields?: string[];
  nonEditableColumns?: string[];
  documentSequenceCode?: any; // Added to handle product sequence code in line items

  /* Temp keys required for calculations - START */
  totalWithDiscount?: number;
  cascadingDiscountAmount?: number;
  itemDiscountMethod?: string;
  totalDiscountAmount?: number;
  subTotal?: number;
  total?: number;
  discountAmount?: number;
  /* Temp keys required for calculations - END */

  /* India fields - START */
  gstType?: GST_TYPE;
  hsnOrSacCode?: string;
  isTaxable?: boolean;
  taxPreference?: boolean;
  taxExemptionReason?: string | null;
  igstRate?: number;
  igstAmount?: number;
  cgstRate?: number;
  cgstAmount?: number;
  sgstRate?: number;
  sgstAmount?: number;
  cessRule?: string;
  cessRuleDescription?: string;
  cessNonAdvol?: any;
  cessAmount?: number | null;
  cessPercentage?: number;
  rcmRate?: number;
  uaeRcmRate?: number;
  otherRate?: number;
  otherTaxAmount?: number;
  itcIneligibleType?: string | null;
  /* India fields - END */

  /* Excise fields - START */
  exciseApplicable?: boolean;
  exciseType?: string;
  exciseRate?: number;
  exciseAmount?: number;
  /* Excise fields - END */

  /* compositionTaxPercent field */
  compositionTaxPercent?: number;

  /* Australia fields -START */
  taxDetails?: any;
  /* Australia fields -END */

  /* Saudi Fields  -START*/
  rcmRateSA?: number;
  rcmTaxAmountSA?: number;
  vatCustomRateSA?: number;
  vatTaxAmountSA?: number;
  /* Saudi Fields --END */
  /*Only to show selected UOM on UI*/
  uom?: any;
  landedCostDetails?: any;
  firstAmountChangeDone?: boolean;
  userSetTaxes?: any;
  amount?: number;
  amortizationDocumentItemDetails?: {
    deferralAccountCode?: string;
    templateCode?: string;
    startDate?: any;
    endDate?: any;
  };
  amortizationTemplate?: any;
  amortizationStartDate?: any;
  amortizationEndDate?: any;
  revRecDocumentItemDetails?: {
    startDate?: any;
    endDate?: any;
  };
  revRecStartDate?: any;
  revRecEndDate?: any;
  expectedDeliveryDt?: Date;
  pendingQtyToConvert?: number;

  qtyOrdered?: number;
  qtyCommitted?: number;
  pickedQty?: number;
  shippedQty?: number;
  shippedFulfilledQty?: number;
  backorderQty?: number;
  availableQty?: number;
  invoicedQty?: number;
  // SG Peppol
  lineRefNo?: string;
  reservedQuantity?: number;

  bomComponentGroupDetails?: any;
  unmodifiedBomComponentGroupDetails?: any;

  /** CRM Optional products feature keys */
  optional?: boolean;

  /** CRM Quote grouping feature keys  */
  productGroupId?: number;
  productGroupIndex?: number;
  productGroupName?: string;
  productGroupUuid?: string;

  /* CRM Uplift/downlift feature keys */
  previousPrice?: number;
  upliftPrice?: number;
  downliftPrice?: number;

  /** For displaying unit price with MoQ in CRM */
  minimumQuantity?: number;

  /** For fullfilment and receive we are getting default warehouse RRB information for product  */
  warehouseCode?: string;
  rowCode?: any;
  rowNme?: string;
  rackCode?: any;
  rackNme?: string;
  binCode?: any;
  binNme?: string;
}

export const DocumentItemDefaultObj: DocumentItem = {
  igstAmount: 0,
  cgstAmount: 0,
  sgstAmount: 0,
  cessAmount: 0,
  rcmRate: 0,
  uaeRcmRate: 0,
  otherRate: 0,
  otherTaxAmount: 0,
  rcmRateSA: 0,
  rcmTaxAmountSA: 0,
  vatCustomRateSA: 0,
  vatTaxAmountSA: 0,
  gstType: 1,
  hsnOrSacCode: '',
  taxPreference: true,
  taxExemptionReason: null,
  igstRate: 0,
  cgstRate: 0,
  sgstRate: 0,
  cessRule: '',
  itcIneligibleType: null,
  exciseApplicable: false,
  exciseType: '',
  exciseRate: 0,
  exciseAmount: 0,
  compositionTaxPercent: undefined,
  taxDetails: [],
  id: undefined,
  lineNumber: 0,
  unitPriceGstInclusive: false,
  discount: 0,
  discountInPercent: false,
  productCode: '',
  product: null,
  productDescription: '',
  productName: '',
  productQuantity: 1,
  availableQuantity: 0,
  taxAmount: 0,
  account: {},
  accountCode: '',
  accountDescription: '',
  taxCode: '',
  taxName: '',
  tax: null, // TODO: add default tax object
  unitPrice: 0,
  currency: '',
  isRcmApplied: false,
  totalAmount: 0,
  taxSystem: '',
  type: '',
  isPartialInvoice: false,
  isPartialBill: false,
  linkedQuoteItem: undefined,
  linkedOrderItem: undefined,
  pendingQuantity: 0,
  pendingAmount: 0,
  fulfilledQuantity: 0,
  quantityFulfilled: 0,
  receivedQuantityInBills: 0,
  receivedQuantityInOrder: 0,
  quantityInParent: 1,
  fulfillmentByDoc: null,
  advancedTracking: '',
  tdsInfoIndia: undefined,
  isTdsApplicableProduct: false,
  isTdsApplicableAccount: false,
  documentUom: 2,
  documentUOMSchemaDefinition: null,
  uomQuantity: 0,
  uomUnitPrice: 0,
  uomAvailableQuantity: 0,
  uomFulfilledQuantity: 0,
  uomQuantityFulfilled: 0,
  uomPendingQuantity: 0,
  uomReceivedQuantityInBills: 0,
  uomReceivedQuantityInOrder: 0,
  invalidFields: [],
  nonEditableColumns: [],
  customField: [],
  landedCostDetails: {},
  firstAmountChangeDone: false,
  userSetTaxes: false,
  amount: 0,
  amortizationDocumentItemDetails: {
    deferralAccountCode: undefined,
    templateCode: undefined,
    startDate: undefined,
    endDate: undefined
  },
  amortizationTemplate: null,
  amortizationStartDate: null,
  amortizationEndDate: null,
  revRecDocumentItemDetails: {
    startDate: undefined,
    endDate: undefined
  },
  revRecStartDate: null,
  revRecEndDate: null
};
