import {
  DKButton,
  DKInput,
  DKLabel,
  DKListPicker2,
  DKSpinner,
  INPUT_TYPE,
  showAlert,
  DKCheckMark
} from 'deskera-ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';

import ApiConstants from '../../../../../Constants/ApiConstants';
import {
  INPUT_VIEW_DIRECTION,
  MRP,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import AppManager from '../../../../../Managers/AppManager';
import { Invoice } from '../../../../../Models/Invoice';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { selectInvoices } from '../../../../../Redux/Slices/InvoicesSlice';
import { selectMRPProducts } from '../../../../../Redux/Slices/MRP/MRPProductSlice';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import { selectSalesOrder } from '../../../../../Redux/Slices/SalesOrderSlice';
import InvoiceService, {
  InvoiceAPIConfig
} from '../../../../../Services/Invoice';
import { IWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import ProductService, {
  ProductAPIConfig,
  defaultConfig
} from '../../../../../Services/Product';
import SalesOrderService, {
  ISalesOrder,
  Product,
  SalesOrderAPIConfig
} from '../../../../../Services/SalesOrder';
import Utility from '../../../../../Utility/Utility';
import { isViewportLarge } from '../../../../../Utility/ViewportSizeUtils';
import { BomConfigurationModel } from '../../../BOM_Assembly/BomDetailsTabProduct/BomConfigurationModel';
import CreateMRPProductView from '../../../BOM_Assembly/CreateMrpProduct/CreateMRPProductView';
import { INPUT_TITLE_STYLE } from '../../../Constants/UIHelper';
import {
  WorkOrderHelper,
  getAllSalesOrdersForSelectedMaterial,
  getFilteredInvoices,
  getFilteredSO,
  populateProductDetailWithSelectedOrDefaultBom,
  salesOrderSelectedFromCheckboxes,
  validateSelectedInvoiceForInterLinkedWO,
  validateSelectedSOForInterLinkedWO
} from '../../WorkOrderHelper';
import BomSelectionPopup from './BomSelectionPopup';

interface WorkOrderInitializerProps {
  isEditMode: boolean;
  workOrders: IWorkOrder[];
  preSelectedBOM: any;
  salesOrder?: ISalesOrder | ISalesOrder[] | null;
  salesInvoice?: Invoice | null;
  bomProductDetailsList?: any[];
  loading: boolean;
  needBomSelectionView?: boolean;
  showBOMListPicker?: boolean;
  onCloseBomListPicker?: () => void;
  onSelectSalesOrder: (
    salesOrder: ISalesOrder | ISalesOrder[],
    revertSalesOrderSelectionToOld?: boolean
  ) => void;
  onSelectSalesInvoice: (
    salesOrder: Invoice,
    revertSalesInvoiceSelectionToOld?: boolean
  ) => void;
  onMaterialSelected: (materialData: any[]) => void;
  canValidate: boolean;
}

const WorkOrderBomSelectionView = (props: WorkOrderInitializerProps) => {
  //   const [showStockTransferPopup, setShowStockTransferPopup] = useState(false);
  //   const [stockTransferItem, setStockTransferItem] = useState<any>({});

  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedBom, setSelectedBom] = useState<any>();
  const [showBomSelector, setShowBomSelector] = useState(false);
  const [showBomSelectionPopup, setShowBomSelectionPopup] = useState(false);
  const [
    salesOrdersRelatedToSelectedProduct,
    setsalesOrdersRelatedToSelectedProduct
  ] = useState<any[]>([]);
  const [showRelatedSOPicker, setShowRelatedSOPicker] = useState(false);
  const [salesOrder, setSalesOrder] = useState(
    !Array.isArray(props.salesOrder) ? props.salesOrder : props.salesOrder?.[0]
  );
  const [isProductFetchInProgress, setIsProductFetchInProgress] =
    useState(false);
  const showProductDropdown =
    Utility.isEmpty(salesOrder?.salesOrderCode) &&
    Utility.isEmpty(props.salesInvoice?.salesInvoiceCode);

  const salesOrdersData = useAppSelector(selectSalesOrder);
  const salesInvoicesData = useAppSelector(selectInvoices);
  const mrpProductsData = useAppSelector(selectMRPProducts);
  const previouslySelectedSalesOrderRef = useRef(salesOrder);
  const previouslySelectedSalesInvoiceRef = useRef(props.salesInvoice);

  const {
    isEditMode,
    preSelectedBOM,
    onMaterialSelected,
    onSelectSalesOrder,
    onSelectSalesInvoice
  } = props;

  const [adhocBomCreate, setAdhocBomCreate] = useState(false);
  const isAdHocEnabled = useAppSelector(selectIsWOAdhocEnable);

  useEffect(() => {
    const onWindowResize = () => {
      setIsDesktop(isViewportLarge);
    };

    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  useEffect(() => {
    if (props.needBomSelectionView) {
      setShowBomSelectionPopup(true);
    } else {
      const SO = !Array.isArray(props.salesOrder)
        ? props.salesOrder
        : props.salesOrder?.[0];
      /* Saving selected sales order, if no bom selection is needed */
      if (SO) {
        previouslySelectedSalesOrderRef.current = SO;
      }
      if (props.salesInvoice) {
        previouslySelectedSalesInvoiceRef.current = props.salesInvoice;
      }
    }
  }, [props.needBomSelectionView, props?.salesOrder, props.salesInvoice]);

  useEffect(() => {
    setSalesOrder(
      !Array.isArray(props.salesOrder)
        ? props.salesOrder
        : props.salesOrder?.[0]
    );
  }, [props?.salesOrder]);

  const onProductSelect = useCallback(
    async (bomProduct: any) => {
      if (Utility.isEmpty(bomProduct?.bomMetaDetailsList)) return;

      setSelectedProduct(bomProduct);

      const productId = bomProduct?.productId ?? '';
      const allSOs: any =
        (await getAllSalesOrdersForSelectedMaterial(productId)) ?? [];
      setsalesOrdersRelatedToSelectedProduct(allSOs);

      if (bomProduct.bomMetaDetailsList.length > 1) {
        let defaultBomConfig = bomProduct.bomMetaDetailsList.find(
          (item: BomConfigurationModel) => item.isDefault
        );
        setSelectedBom(defaultBomConfig);
        Utility.isEmpty(props.workOrders)
          ? setShowBomSelector(true)
          : setShowBomSelectionPopup(true);
        return;
      } else {
        setSelectedBom(bomProduct.bomMetaDetailsList[0]);

        // need to stop from opening the WO with values
        if (Utility.isNotEmpty(allSOs)) {
          setShowBomSelector(true);
          return;
        }
        onMaterialSelected([
          {
            ...bomProduct,
            selectedBom: bomProduct.bomMetaDetailsList[0]
          }
        ]);
      }
    },
    [props.workOrders, onMaterialSelected]
  );

  useEffect(() => {
    if (
      Utility.isEmptyObject(props.workOrders) &&
      selectedProduct !== preSelectedBOM
    ) {
      onProductSelect(preSelectedBOM);
    }
  }, [preSelectedBOM, props.workOrders, onProductSelect, selectedProduct]);

  const onCreateButtonClick = useCallback(() => {
    let obj = populateProductDetailWithSelectedOrDefaultBom(
      selectedProduct,
      selectedBom
    );
    onMaterialSelected([obj]);
  }, [onMaterialSelected, selectedBom, selectedProduct]);

  const onCreateButtonClickUsingRelatedSOFlow = useCallback(() => {
    let selectedSalesOrdersForSelectedProduct =
      salesOrdersRelatedToSelectedProduct?.filter(
        (so: any) => so?.selectedForWO
      );
    // Updating Product quantity for SO to WO conversion
    selectedSalesOrdersForSelectedProduct =
      selectedSalesOrdersForSelectedProduct?.map((item: any) => {
        return WorkOrderHelper.deductConvertedQuantityForSOtoWOFlow(item);
      });
    onSelectSalesOrder(selectedSalesOrdersForSelectedProduct);
  }, [onSelectSalesOrder, salesOrdersRelatedToSelectedProduct]);

  const filterBOMProducts = (products: Product[]) =>
    products?.filter(
      (product) =>
        product.type === PRODUCT_TYPE.BILL_OF_MATERIALS && product.active
    );

  const renderEmptyState = () => {
    const hasLinkedSalesOrderWithNoBOM = Utility.isNotEmpty(
      salesOrder?.salesOrderCode
    );
    const hasLinkedSalesinvoiceWithNoBOM = Utility.isNotEmpty(
      props.salesInvoice?.salesInvoiceCode
    );
    let message = '';
    if (hasLinkedSalesOrderWithNoBOM) {
      message =
        'Either there are no WIP materials for this selected sales order, or, BOM is not selected.';
    } else if (hasLinkedSalesinvoiceWithNoBOM) {
      message =
        'Either there are no WIP materials for this selected sales invoice, or, BOM is not selected.';
    } else {
      message = 'Select Sales Order/Sales Invoice/Product to Create Work Order';
    }
    return props.loading ? (
      <DKSpinner
        title={
          Utility.isEmpty(props.bomProductDetailsList)
            ? `Please wait, loading material details from ${
                hasLinkedSalesOrderWithNoBOM ? 'sales order' : 'sales invoice'
              }...`
            : 'Please wait, loading work order...'
        }
        className="align-self-center m-auto"
      />
    ) : (
      <div className="row parent-size align-items-center justify-content-center">
        {message}
      </div>
    );
  };

  const relatedSOPicker = () => {
    return (
      <DKListPicker2
        data={salesOrdersRelatedToSelectedProduct}
        className={'position-absolute  bg-white border-m '}
        renderer={(index: number, soObj: any) => {
          return Utility.isNotEmpty(soObj?.documentSequenceCode) ? (
            <div
              className="row parent-width"
              onClick={() => {
                let salesOrdersRelatedToSelectedProductCopy = [
                  ...salesOrdersRelatedToSelectedProduct
                ];
                salesOrdersRelatedToSelectedProductCopy[index].selectedForWO =
                  !salesOrdersRelatedToSelectedProductCopy[index].selectedForWO;
                setsalesOrdersRelatedToSelectedProduct(
                  salesOrdersRelatedToSelectedProductCopy
                );
              }}
            >
              <DKCheckMark
                isSelected={soObj?.selectedForWO}
                color="bg-blue"
                onClick={() => {}}
                className="mr-s"
              />
              <DKLabel text={soObj?.documentSequenceCode} />
            </div>
          ) : null;
        }}
        onSelect={(index: number, soObj: any) => {}}
        onClose={() => {
          setShowRelatedSOPicker(false);
        }}
        allowSearch={true}
        searchableKey="documentSequenceCode"
        searchApiConfig={null}
      />
    );
  };

  const getSalesOrderSelectionView = () => {
    return (
      <div
        className={`row align-items-end p-h-m mt-m ${
          !isDesktop ? 'row-responsive' : ''
        }`}
        style={{
          columnGap: '10px'
        }}
      >
        {!showBomSelector && !props.salesInvoice && (
          <div
            className="row"
            style={{
              width: !isDesktop ? '100%' : '25%'
            }}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Sales Order'}
              placeholder={'Select a Sales Order'}
              titleStyle={INPUT_TITLE_STYLE}
              valueStyle={{
                backgroundColor: '#f4f4f6'
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={false}
              value={salesOrder}
              formatter={(obj: any) => {
                return obj.documentSequenceCode;
              }}
              required={false}
              canValidate={props.canValidate}
              disabled={false}
              onChange={async (newSalesOrderObj: any) => {
                let newSalesOrder =
                  WorkOrderHelper.deductConvertedQuantityForSOtoWOFlow(
                    newSalesOrderObj
                  );
                await validateSelectedSOForInterLinkedWO(newSalesOrder, () => {
                  previouslySelectedSalesOrderRef.current = newSalesOrder;
                  onSelectSalesOrder(newSalesOrder);
                });
              }}
              dropdownConfig={{
                title: '',
                allowSearch: true,
                searchableKey: 'documentSequenceCode',
                className: 'shadow-m width-auto',
                searchApiConfig: {
                  getUrl: (searchValue: any) => {
                    const config: SalesOrderAPIConfig = {
                      ...SalesOrderService.apiConfig,
                      SearchTerm: searchValue,
                      Limit: 25,
                      Page: 0
                    };
                    SalesOrderService.apiConfig = config;
                    return (
                      ApiConstants.URL.BASE +
                      SalesOrderService.salesOrderEndpoint()
                    );
                  },
                  dataParser: (data: any) => {
                    return getFilteredSO(data?.content) ?? [];
                  }
                },
                data: getFilteredSO(salesOrdersData?.content) ?? [],
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={`${obj.documentSequenceCode}`} />;
                }
              }}
            />
          </div>
        )}
        {!showBomSelector && showProductDropdown && !salesOrder && (
          <div
            className={`row ${
              !isDesktop
                ? 'parent-width mt-r mb-r justify-content-center'
                : 'width-auto mb-r'
            }`}
          >
            OR
          </div>
        )}
        {!showBomSelector && !salesOrder && (
          <div
            className="row"
            style={{
              width: !isDesktop ? '100%' : '25%'
            }}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Sales Invoice'}
              placeholder={'Select a Sales Invoice'}
              titleStyle={INPUT_TITLE_STYLE}
              valueStyle={{
                backgroundColor: '#f4f4f6'
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              readOnly={false}
              value={props.salesInvoice}
              formatter={(obj: any) => {
                return obj.documentSequenceCode;
              }}
              required={false}
              canValidate={props.canValidate}
              disabled={false}
              onChange={async (newSalesInvoice: any) => {
                await validateSelectedInvoiceForInterLinkedWO(
                  newSalesInvoice,
                  () => {
                    previouslySelectedSalesInvoiceRef.current = newSalesInvoice;
                    onSelectSalesInvoice(newSalesInvoice);
                  }
                );
              }}
              dropdownConfig={{
                title: '',
                allowSearch: true,
                searchableKey: 'documentSequenceCode',
                className: 'shadow-m width-auto',
                searchApiConfig: {
                  getUrl: (searchValue: any) => {
                    const config: InvoiceAPIConfig = {
                      ...InvoiceService.apiConfig,
                      SearchTerm: searchValue,
                      Limit: 25,
                      Page: 0
                    };
                    InvoiceService.apiConfig = config;
                    return (
                      ApiConstants.URL.BASE +
                      InvoiceService.salesInvoiceEndpoint()
                    );
                  },
                  dataParser: (data: any) => {
                    return getFilteredInvoices(data?.content) ?? [];
                  }
                },
                data: getFilteredInvoices(salesInvoicesData?.content) ?? [],
                renderer: (index: any, obj: any) => {
                  return <DKLabel text={`${obj.documentSequenceCode}`} />;
                }
              }}
            />
          </div>
        )}
        {!showProductDropdown && (
          <DKButton
            title={`Select BOM`}
            className={'bg-button text-white ml-r'}
            onClick={() => setShowBomSelectionPopup(true)}
          />
        )}
        {!showBomSelector && showProductDropdown && (
          <div
            className={`row ${
              !isDesktop
                ? 'parent-width mt-r mb-r justify-content-center'
                : 'width-auto mb-r'
            }`}
          >
            OR
          </div>
        )}
        {showProductDropdown && (
          <div
            style={{
              width: !isDesktop ? '100%' : '25%'
            }}
            className="row"
            onClick={() => {
              setIsProductFetchInProgress(true);
            }}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Material'}
              readOnly={false}
              placeholder={`Select Material to Add`}
              titleStyle={INPUT_TITLE_STYLE}
              valueStyle={{
                backgroundColor: '#f4f4f6'
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              formatter={(obj: any) => {
                return `${obj.name} (${obj.documentSequenceCode})`;
              }}
              required={false}
              canValidate={props.canValidate}
              disabled={false}
              value={selectedProduct}
              onChange={(obj: any) => {
                onProductSelect(obj);
              }}
              dropdownConfig={{
                title: 'Select Material',
                allowSearch: true,
                searchableKey: 'name',
                className: 'shadow-m',
                searchApiConfig: {
                  getUrl: (searchValue: any) => {
                    const config: ProductAPIConfig = {
                      ...defaultConfig,
                      QueryParam: `&query=type=${PRODUCT_TYPE.BILL_OF_MATERIALS}`,
                      SearchTerm: searchValue
                    };
                    ProductService.apiConfig = config;
                    return (
                      ApiConstants.URL.BASE.replace('v1/', 'v1') +
                      ProductService.getProductEndPoint()
                    );
                  },
                  dataParser: (data: any) => {
                    setIsProductFetchInProgress(false);
                    return filterBOMProducts(data?.content || []);
                  }
                },
                data: [],
                // data: filterBOMProducts(mrpProductsData?.content ?? []),
                renderer: (index: any, obj: any) => {
                  return (
                    <DKLabel
                      text={`${obj.name} (${obj.documentSequenceCode})`}
                      className={`parent-width ${
                        isProductFetchInProgress ? 'text-gray' : ''
                      }`}
                    />
                  );
                }
              }}
            />
          </div>
        )}
        {showBomSelector && (
          <div
            style={{
              width: !isDesktop ? '100%' : '25%'
            }}
            className={'row ' + (!isDesktop ? 'mt-r mb-r' : '')}
          >
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              title={'Select Bom'}
              readOnly={false}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              titleStyle={INPUT_TITLE_STYLE}
              formatter={(obj: any) => {
                return `${obj.name}`;
              }}
              value={selectedBom}
              valueStyle={{
                backgroundColor: '#f4f4f6'
              }}
              onChange={(selectedBom: any) => {
                setSelectedBom(selectedBom);
              }}
              dropdownConfig={{
                title: '',
                allowSearch: true,
                searchableKey: 'name',
                className: 'shadow-m',
                searchApiConfig: null,
                data: selectedProduct?.bomMetaDetailsList?.filter(
                  (productObj: BomConfigurationModel) => {
                    return productObj.active;
                  }
                ),
                renderer: (index: any, obj: any) => {
                  return (
                    <DKLabel text={`${obj.name}`} className="parent-width" />
                  );
                }
              }}
            />
          </div>
        )}
        {showBomSelector && selectedProduct && selectedBom && (
          <div className="position-relative">
            <DKButton
              className={`bg-button text-white ${
                !isDesktop ? 'mt-r mb-r mx-auto' : ''
              }`}
              title={'Show Related SOs'}
              onClick={() => {
                setShowRelatedSOPicker(!showRelatedSOPicker);
              }}
            />
            {showRelatedSOPicker && relatedSOPicker()}
          </div>
        )}
        {showBomSelector && selectedProduct && selectedBom && (
          <DKButton
            className={`bg-button text-white ${
              !isDesktop ? 'mt-r mb-r mx-auto' : ''
            }`}
            title={'Create'}
            onClick={() => {
              if (
                salesOrderSelectedFromCheckboxes(
                  salesOrdersRelatedToSelectedProduct
                ) === 0
              ) {
                onCreateButtonClick();
              } else if (
                Utility.isNotEmpty(salesOrdersRelatedToSelectedProduct)
              ) {
                onCreateButtonClickUsingRelatedSOFlow();
              }
            }}
          />
        )}
        {showProductDropdown && isAdHocEnabled && (
          <>
            <DKLabel
              text="OR"
              className={`row ${
                !isDesktop
                  ? 'parent-width mt-r mb-r justify-content-center'
                  : 'width-auto mb-r'
              }`}
            />
            <DKButton
              title={'Create Adhoc BOM'}
              className={`bg-button text-white ${
                !isDesktop ? 'mt-r mb-r mx-auto' : ''
              }`}
              onClick={() => setAdhocBomCreate(true)}
            />
          </>
        )}
      </div>
    );
  };

  const getBomSelectionPopup = () => {
    let productsToSelectBOM: any = props.bomProductDetailsList || [];
    if (!Utility.isEmpty(props.workOrders) && selectedProduct) {
      productsToSelectBOM = [selectedProduct];
    }
    return (
      <BomSelectionPopup
        isPopup={true}
        currentProducts={productsToSelectBOM}
        salesOrders={props?.salesOrder}
        salesInvoiceSequenceCode={props?.salesInvoice?.documentSequenceCode}
        salesOrderSequenceCode={salesOrder?.documentSequenceCode}
        onContinueButtonTap={(productListWithSelectedBom: any[]) => {
          onMaterialSelected(productListWithSelectedBom);
          setShowBomSelectionPopup(false);
        }}
        onCancel={() => {
          //Handle multiple checkboxes selection for SOs
          if (
            salesOrderSelectedFromCheckboxes(
              salesOrdersRelatedToSelectedProduct
            ) > 1
          ) {
            previouslySelectedSalesOrderRef.current = null;
            let salesOrdersRelatedToSelectedProductCopy = [
              ...salesOrdersRelatedToSelectedProduct
            ];
            salesOrdersRelatedToSelectedProductCopy =
              salesOrdersRelatedToSelectedProductCopy?.map((item: any) => {
                return {
                  ...item,
                  selectedForWO: false
                };
              });
            setsalesOrdersRelatedToSelectedProduct(
              salesOrdersRelatedToSelectedProductCopy
            );
            setSalesOrder(null);
          }

          // Handle SO selection
          if (
            previouslySelectedSalesOrderRef.current?.salesOrderCode &&
            salesOrder &&
            previouslySelectedSalesOrderRef.current.salesOrderCode !==
              salesOrder.salesOrderCode
          ) {
            onSelectSalesOrder(previouslySelectedSalesOrderRef.current, true);
          }

          // Handle SI selection
          if (
            previouslySelectedSalesInvoiceRef.current?.salesInvoiceCode &&
            props.salesInvoice &&
            previouslySelectedSalesInvoiceRef.current.salesInvoiceCode !==
              props.salesInvoice.salesInvoiceCode
          ) {
            onSelectSalesInvoice(
              previouslySelectedSalesInvoiceRef.current,
              true
            );
          }
          setShowBomSelectionPopup(false);
        }}
      />
    );
  };

  const getBOMListPicker = () => {
    const selectedProductIds = props.workOrders?.map(
      (item) => item.product?.productId
    );
    const filterBOMProducts = (products: Product[]) =>
      products?.filter(
        (product) =>
          product.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
          product.active &&
          !selectedProductIds.includes(product.productId)
      );
    return (
      <DKListPicker2
        data={filterBOMProducts(mrpProductsData?.content ?? [])}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          right: 25,
          top: 60
        }}
        onSelect={(index: number, obj: any) => {
          onProductSelect(obj);
          props.onCloseBomListPicker?.();
        }}
        onClose={() => props.onCloseBomListPicker?.()}
        allowSearch={true}
        searchableKey={'name'}
        searchApiConfig={{
          getUrl: (searchValue: any) => {
            const config: ProductAPIConfig = {
              ...defaultConfig,
              QueryParam: `&query=type=${PRODUCT_TYPE.BILL_OF_MATERIALS}`,
              SearchTerm: searchValue
            };
            ProductService.apiConfig = config;
            return (
              ApiConstants.URL.BASE.replace('v1/', 'v1') +
              ProductService.getProductEndPoint()
            );
          },
          dataParser: (data: any) => filterBOMProducts(data?.content || [])
        }}
        renderer={(index: any, obj: any) => {
          return <DKLabel text={`${obj.name} (${obj.documentSequenceCode})`} />;
        }}
      />
    );
  };

  return (
    <>
      {!isEditMode &&
        (!props.loading || props.needBomSelectionView) &&
        Utility.isEmpty(props.workOrders) &&
        getSalesOrderSelectionView()}
      {adhocBomCreate && (
        <CreateMRPProductView
          isFromAdHoc={true}
          product={null}
          type={MRP.FINISHED_GOODS}
          isCopy={false}
          readOnly={false}
          onCancel={() => {
            setAdhocBomCreate(false);
          }}
          onProductDashboard={() => {}}
          apiInProgress={() => {}}
          onSuccess={(data: any) => {
            onProductSelect(data);
            props.onCloseBomListPicker?.();
            setAdhocBomCreate(false);
          }}
        />
      )}
      {!isEditMode && showBomSelectionPopup ? getBomSelectionPopup() : null}
      {!isEditMode && props.showBOMListPicker && getBOMListPicker()}
      {Utility.isEmpty(props.workOrders) &&
        (isEditMode || props.loading || Utility.isEmpty(salesOrder)) &&
        renderEmptyState()}
    </>
  );
};

export default WorkOrderBomSelectionView;
