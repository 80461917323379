import { FC, useEffect, useRef, useState, Fragment } from 'react';
import {
  INPUT_TYPE,
  DKButton,
  DKLabel,
  DKIcons,
  DKInput,
  showAlert,
  DKLine,
  DKDataGrid
} from 'deskera-ui-library';
import { useAppSelector } from '../../Redux/Hooks';
import { selectContacts } from '../../Redux/Slices/ContactsSlice';
import {
  COUNTRY_CODES,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TRANSACTIONTYPE_PURCHASE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  STATUS_TYPE,
  RECEIVED_GOODS_STATUS,
  BUY_TYPE
} from '../../Constants/Constant';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import {
  ISecurityGateEntry,
  LINKABLE_DOCUMENT
} from '../../Models/SecurityGateEntry';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import ApiConstants from '../../Constants/ApiConstants';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import {
  activeTenantInfo,
  usersListShortInfo
} from '../../Redux/Slices/AuthSlice';
import DKInputDateTime from '../../SharedComponents/DateTimePicker/DKInputDateTime';
import { IColumn } from '../../Models/Table';
import {
  convertDocItemsToGateEntryItem,
  convertResponseToFormData,
  createGateEntry,
  getBlackGateEntryItem,
  preparePayload,
  updateGateEntry,
  validateArrivalDate,
  validateDepartureDate
} from '../../Services/SecurityGateEntry';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { cloneDeep } from 'lodash';
import { CallBackPayloadType } from '../../Models/Interfaces';
import { selectUOMs } from '../../Redux/Slices/CommonDataSlice';
import CustomNumberFormatInput from '../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import useScreenResize from '../../Hooks/useScreenResize';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { CustomFieldsHolder } from '../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { DKIcon, showToast } from 'deskera-ui-library';
import { triggerDownload } from '../ImportExport/utility/ExportData';
import AttachmentService from '../../Services/Attachment';
import { Store } from '../../Redux/Store';
import { getUomQuantity } from '../../SharedComponents/DocumentForm/NewDocumentHelper';

interface IAddSecurityGateEntryProps {
  passingInteraction?: (callback: CallBackPayloadType) => void;
  onSave?: (data: any) => void;
  onError?: (data: any) => void;
  gateEntry?: ISecurityGateEntry | null;
}
const REMARKS_CHAR_LIMIT = 500;
const COLUMNS: IColumn[] = [
  {
    id: 'product',
    key: 'product',
    columnCode: 'product',
    name: 'Product',
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: DocumentConfigUtility.productFormatterDocument,
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null as any,
        dataParser: (res) => res.content,
        debounceTime: 300
      },
      data: [],
      renderer: DocumentConfigUtility.productRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    name: 'Product Code',
    columnCode: 'productCode',
    key: 'productCode',
    id: 'productCode',
    type: INPUT_TYPE.DROPDOWN,
    editable: false,
    index: 0,
    width: 180,
    formatter: ({ rowData }: any) => rowData?.product?.documentSequenceCode
  },
  {
    name: 'Description',
    columnCode: 'productDescription',
    key: 'productDescription',
    id: 'productDescription',
    editable: true,
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180
  },
  {
    name: 'Quantity',
    columnCode: 'quantity',
    key: 'quantity',
    id: 'quantity',
    type: INPUT_TYPE.NUMBER,
    editable: true,
    index: 2,
    renderer: DocumentConfigUtility.quantityRenderer,
    width: 180
  },
  {
    name: 'UOM',
    columnCode: 'uom',
    key: 'uom',
    id: 'uom',
    type: INPUT_TYPE.DROPDOWN,
    editable: true,
    index: 3,
    width: 180,
    formatter: DocumentConfigUtility.uomFormatter,
    dropdownConfig: {
      title: 'Select UOM',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      data: [],
      renderer: DocumentConfigUtility.uomOptionRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'action',
    columnCode: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 150,
    options: []
  }
];

const initialFormState: ISecurityGateEntry = {
  vendorInfo: null,
  vendorCode: null,
  arrivalTime: new Date(),
  challanDate: new Date(),
  challanNumber: null,
  departureTime: new Date(),
  distanceKm: 0,
  ewayDate: new Date(),
  ewayBillNumber: null,
  gateEntryItems: [],
  referenceDate: new Date(),
  customField: [],
  contactNumber: null,
  currencyCode: null,
  documentSequenceCode: null,
  driverName: null,
  freightType: null,
  vehicleCapacity: null,
  referenceDocument: null,
  referenceDocumentType: 'NONE',
  weighbridgeDate: new Date(),
  fromAddress: null,
  licenseNumber: null,
  lrDate: new Date(),
  lrNumber: null,
  referenceNumber: null,
  remarks: null,
  sequenceFormat: null,
  toAddress: null,
  transporter: null,
  vehicleNumber: null,
  vehicleType: null,
  weighbridgeName: null,
  weighbridgeSlipDate: new Date(),
  warehouse: null,
  weighbridgeSlipNumber: null
};

const AddSecurityGateEntry: FC<IAddSecurityGateEntryProps> = (props) => {
  const contactsData: { content: [] } = useAppSelector(selectContacts);
  const warehouseData = useAppSelector(selectWarehouse);
  const uomsData = useAppSelector(selectUOMs);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const [formState, setFormState] = useState<ISecurityGateEntry>(
    cloneDeep(initialFormState)
  );
  const [columns, setColumns] = useState<IColumn[]>(cloneDeep(COLUMNS));
  const tenantInfo = useAppSelector(activeTenantInfo);
  const isEditCase = Utility.isNotEmpty(props.gateEntry);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    checkForEditCase();
    updateConfig();
    fetchAttachments();
  }, []);

  useEffect(() => {
    updateConfig();
  }, [formState.referenceDocumentType, selectedProductUOMS]);

  useEffect(() => {
    setFormSubmitted(false);
  }, [formState]);

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      let processedUoms = productCopy?.uomSchemaDto?.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  const getSelectedUomForEdit = (item: any) => {
    if (item.documentUOMSchemaDefinition) {
      return item.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === item?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };

  const updateUomSchemaForLineItem = (selectedRow: any, uomData: any) => {
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow?.product?.uomSchemaDto?.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
      selectedRow.productQuantity = selectedRow.quantity;
    }
    return selectedRow;
  };

  const registerInteractions = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_SECURITY_GATE_ENTRY,
        data: () => saveGateEntry()
      });
    }
  };

  const checkForEditCase = () => {
    if (props?.gateEntry && Utility.isNotEmpty(props.gateEntry)) {
      const newFormState = convertResponseToFormData(props.gateEntry);
      newFormState.gateEntryItems?.forEach((item: any) => {
        item.uom = getSelectedUomForEdit(item);
        item.productQuantity = item.quantity;
      });
      setFormState({ ...formState, ...newFormState });
    }
  };

  //** handler and helper renderer goes here */
  const updateConfig = () => {
    const copyOfColumns = [...columns];
    copyOfColumns.forEach((column) => {
      if (column.key === 'product') {
        if (column.dropdownConfig?.searchApiConfig) {
          column.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getProductURL(
              search,
              tenantInfo,
              PRODUCT_TRANSACTIONTYPE_PURCHASE
            );
        }
      }
      if (column.key === 'uom') {
        if (column.dropdownConfig) {
          column.dropdownConfig.data = selectedProductUOMS || [];
        }
      }
    });
    setColumns(copyOfColumns);
  };

  const saveGateEntry = () => {
    const payload = preparePayload(formState, isEditCase);
    payload.currencyCode = tenantInfo.currency;
    setFormSubmitted(true);
    if (validateForm()) {
      (isEditCase ? updateGateEntry(payload) : createGateEntry(payload))
        .then((res) => {
          props?.onSave?.(res);
          console.log(res);
        })
        .catch((err) => {
          props?.onError?.(err);
          console.error(err);
        });
    } else {
      props?.onError?.(null);
    }
  };

  const updateForm = (key: keyof ISecurityGateEntry, value: any) => {
    const copyOfFormState = cloneDeep(formState);
    copyOfFormState[key] = value;
    switch (key) {
      case 'referenceDocumentType':
      case 'vendorInfo':
        copyOfFormState['referenceDocument'] = null;
        copyOfFormState['gateEntryItems'] = [];
        break;
    }

    setFormState(copyOfFormState);
  };

  const getSearchAPIConfig = () => {
    return {
      getUrl: (searchValue: string) => {
        switch (formState.referenceDocumentType) {
          case DOC_TYPE.ORDER:
            return (
              ApiConstants.URL.BASE +
              ApiConstants.URL.ORDERS.FETCH_ORDERS +
              Utility.getTenantSpecificApiCode(
                COMPLIANCE_SPECIFIC_FIELD_NAME.PURCHASE_ORDER
              ) +
              `?search=${encodeURIComponent(searchValue)}`
            );
          // case DOC_TYPE.RFQ:
          //   return (
          //     ApiConstants.URL.BASE +
          //     ApiConstants.URL.RFQ.FETCH_RFQ +
          //     `?search=${searchValue}`
          //   );
          case DOC_TYPE.BILL:
            return (
              ApiConstants.URL.BASE +
              ApiConstants.URL.BILLS.FETCH_BILLS +
              `?search=${encodeURIComponent(searchValue)}`
            );
        }
      },
      dataParser: (response: any) => {
        if (formState.referenceDocumentType === DOC_TYPE.ORDER) {
          return (response?.content || []).filter(
            (po: any) =>
              po.receiptStatus !== RECEIVED_GOODS_STATUS.FULLY_RECEIVED &&
              po?.orderType !== BUY_TYPE.ASSET
          );
        }

        if (formState.referenceDocumentType === DOC_TYPE.BILL) {
          return (response?.content || []).filter(
            (po: any) =>
              po.receiveGoodsStatus !== RECEIVED_GOODS_STATUS.FULLY_RECEIVED
          );
        }
        return response?.content || [];
      },
      debounceTime: 300
    };
  };

  const getActiveWH = (warehouse: any[]) => {
    return warehouse.filter(
      (warehouse: any) =>
        warehouse && warehouse.active && warehouse.warehouseType === 'NONE'
    );
  };

  const addGateEntryItem = () => {
    const copyOfFormState = cloneDeep(formState);
    copyOfFormState.gateEntryItems.push(getBlackGateEntryItem());
    setFormState(copyOfFormState);
  };

  const onDocumentSelected = (document: any) => {
    let gridItems: any[] = convertDocItemsToGateEntryItem({
      ...document,
      docType: formState.referenceDocumentType
    });
    gridItems?.forEach((item) => {
      item.uom = getUomForLineItem(item);
      item.productQuantity = item.quantity;
    });
    setFormState({
      ...formState,
      gateEntryItems: gridItems,
      referenceDocument: document
    });
  };

  const getUomForLineItem = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };

  const validateForm = () => {
    if (
      Utility.isEmptyObject(formState.vendorInfo) ||
      (formState.referenceDocumentType !== 'NONE' &&
        Utility.isEmptyObject(formState.referenceDocument)) ||
      Utility.isEmptyObject(formState.referenceDocumentType) ||
      (formState.remarks?.length ?? 0) > REMARKS_CHAR_LIMIT
    ) {
      return false;
    }
    if (validateArrivalDate(formState)) {
      showAlert('Error!', 'Arrival Date can not be before book beginning date');
      return;
    }
    if (validateDepartureDate(formState)) {
      showAlert('Error!', 'Departure Date can not be before arrival date');
      return;
    }

    if (
      Utility.isEmptyObject(formState.gateEntryItems) ||
      formState?.gateEntryItems?.some(
        (item) =>
          Utility.isNotEmpty(item?.invalidFields) ||
          Utility.isEmptyObject(item?.productCode)
      )
    ) {
      showAlert('Error!', 'Please add at least one Item');
      if (Utility.isEmptyObject(formState?.gateEntryItems)) {
        setFormState({
          ...formState,
          gateEntryItems: [getBlackGateEntryItem()]
        });
      }
      return false;
    }
    if (
      Utility.isNotEmpty(formState.customField) &&
      formState?.customField?.some(
        (field) => field.mandatory && Utility.isEmpty(field.value)
      )
    ) {
      showAlert('Error!', 'Please add mandatory Custom Fields');
      return false;
    }
    return true;
  };

  const getDefaultUom = (uomID: any) => {
    let filtered = Store.getState().commonData.data.uoms.filter(
      (uom: any) => uom.id === uomID
    );
    if (!Utility.isEmpty(filtered)) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    const copyOfFormState = cloneDeep(formState);
    const gridItems = copyOfFormState.gateEntryItems;
    switch (columnKey) {
      case 'product':
        gridItems[rowIndex][columnKey] = rowData[columnKey];
        gridItems[rowIndex]['productDescription'] =
          rowData[columnKey]['description'] ?? '';
        gridItems[rowIndex]['productCode'] =
          rowData[columnKey]['productId'] ?? '';
        gridItems[rowIndex]['quantity'] = 1;
        gridItems[rowIndex]['uomQuantity'] = 1;
        gridItems[rowIndex]['documentUom'] = rowData[columnKey]['stockUom'];
        gridItems[rowIndex]['uom'] = rowData[columnKey]['stockUom']
          ? getDefaultUom(rowData[columnKey]['stockUom'])
          : null;
        break;
      case 'quantity':
      case 'uom':
        gridItems[rowIndex][columnKey] = rowData[columnKey];
        gridItems[rowIndex] = updateUomSchemaForLineItem(
          gridItems[rowIndex],
          rowData['uom']
        );
        gridItems[rowIndex]['uomQuantity'] = gridItems[rowIndex]['quantity'];
        break;
      default:
        gridItems[rowIndex][columnKey] = rowData[columnKey];
        break;
    }
    if (Utility.isNotEmpty(gridItems[rowIndex][columnKey])) {
      gridItems[rowIndex]['invalidFields'] = gridItems[rowIndex][
        'invalidFields'
      ]?.filter((key: string) => key != columnKey);
    }
    copyOfFormState.gateEntryItems = gridItems;
    setFormState(copyOfFormState);
  };

  const onItemDelete = ({ rowData, rowIndex }: any) => {
    const copyOfFormState = cloneDeep(formState);
    const gridItems = copyOfFormState.gateEntryItems;
    gridItems.splice(rowIndex, 1);
    copyOfFormState.gateEntryItems = gridItems;
    setFormState(copyOfFormState);
  };

  const getRows = () => {
    const rows = cloneDeep(formState.gateEntryItems);
    rows.forEach((row) => {
      row['rowContextMenu'] = [
        {
          title: 'Delete',
          icon: DKIcons.ic_delete,
          onClick: (data: any) => {
            onItemDelete(data);
          }
        }
      ];
    });
    return rows;
  };

  const fetchAttachments = () => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.GATE_ENTRY];

    const entityId = props.gateEntry?.id;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);

      setFormState((prevState: any) => {
        return {
          ...prevState,
          attachmentIds: attachmentList.map(
            (attachment: any) => attachment.attachmentId
          ),
          attachments: attachmentList.map((attachment: any) =>
            JSON.stringify(attachment)
          )
        };
      });
    });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.GATE_ENTRY];
    const entityId = formState.id || '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setFormState((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch((err) => {
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setFormState((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r gap-2">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>
            <DKIcon
              src={DKIcons.ic_delete}
              className={`ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60 `}
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  //** view renderer goes here */
  const renderCustomNumberFormat = () => {
    if (isEditCase) {
      return (
        <div className={'column mt-m'} style={{ width: '24%' }}>
          <DKInput
            className="parent-width"
            title="No."
            value={formState.documentSequenceCode}
            type={INPUT_TYPE.TEXT}
            onChange={(value: any) => {}}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={true}
          />
        </div>
      );
    }
    return (
      <div className="column mt-m" style={{ width: '24%' }}>
        <CustomNumberFormatInput
          module={CUSTOM_NUMBER_INPUT_MODULES.SECURITY_ENTRY_GATE}
          selectedFormat={(selected: any) => {
            updateForm('sequenceFormat', selected);
          }}
          isRow={false}
          autoNumberingFormat={formState.sequenceFormat}
        />
      </div>
    );
  };

  const renderContactDropDown = () => {
    let contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    return (
      <div className="column mt-m" style={{ width: '24%' }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={`Vendor`}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={formState.vendorInfo}
          formatter={(obj: any) => {
            return obj.name;
          }}
          onChange={(contact: any) => updateForm('vendorInfo', contact)}
          required={true}
          canValidate={formSubmitted}
          dropdownConfig={{
            title: 'Select Contact',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                const config: ContactAPIConfig = {
                  ...ContactService.apiConfig,
                  Page: 0,
                  SearchTerm: searchValue,
                  Limit: 20,
                  IncludeOpeningAmounts: false,
                  IncludeOweAmounts: false,
                  Query: 'status=active',
                  SortDir: 'DESC',
                  Sort: 'documentSequenceCode'
                };
                ContactService.apiConfig = config;
                return ContactService.getContactsApiUrl();
              },
              dataParser: (response: any) => {
                return response?.content || [];
              },
              debounceTime: 300
            },
            data: contactList ?? [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: null
          }}
          readOnly={isEditCase}
        />
      </div>
    );
  };

  const renderRefTypeDocumentDropDown = () => {
    return (
      <div className="column mt-m" style={{ width: '24%' }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={`Reference Document Type`}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={LINKABLE_DOCUMENT[formState.referenceDocumentType]}
          formatter={(obj: any) => {
            return obj;
          }}
          onChange={(referenceDoc: any) =>
            updateForm('referenceDocumentType', referenceDoc)
          }
          required={true}
          canValidate={formSubmitted}
          dropdownConfig={{
            title: '',
            allowSearch: false,
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            data: Object.keys(LINKABLE_DOCUMENT),
            renderer: (index: any, obj: keyof typeof LINKABLE_DOCUMENT) => {
              return <DKLabel text={`${LINKABLE_DOCUMENT[obj]}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: null
          }}
          readOnly={isEditCase}
        />
      </div>
    );
  };

  const renderRefDocumentDropDown = () => {
    const isDocTypeNone = formState.referenceDocumentType === 'NONE';
    return (
      <div className="column mt-m" style={{ width: '24%' }}>
        <DKInput
          type={isDocTypeNone ? INPUT_TYPE.TEXT : INPUT_TYPE.DROPDOWN}
          title={
            isDocTypeNone
              ? `Reference Document No`
              : LINKABLE_DOCUMENT[formState.referenceDocumentType]
          }
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={
            isDocTypeNone
              ? formState?.referenceNumber
              : formState.referenceDocument
          }
          formatter={
            isDocTypeNone
              ? null
              : (obj: any) => {
                  return `${obj.documentSequenceCode} ${
                    obj.supplierInvoiceNo ? `(${obj.supplierInvoiceNo})` : ''
                  }`;
                }
          }
          onChange={(document: any) => {
            if (formState.referenceDocumentType === 'NONE') {
              updateForm('referenceNumber', document);
            } else {
              onDocumentSelected(document);
            }
          }}
          required={!isDocTypeNone}
          canValidate={formSubmitted}
          dropdownConfig={{
            title: `Select ${
              LINKABLE_DOCUMENT[formState.referenceDocumentType]
            }`,
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: getSearchAPIConfig(),
            data: [],
            renderer: (index: any, obj: any) => {
              return (
                <>
                  <div className="column parent-width">
                    <DKLabel
                      text={`${obj.documentSequenceCode}`}
                      className="row parent-width"
                    />
                    {obj.supplierInvoiceNo && (
                      <DKLabel
                        text={`Supplier Inv No. ${obj.supplierInvoiceNo}`}
                        className="row parent-width fs-s text-gray"
                      />
                    )}
                  </div>
                </>
              );
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: null
          }}
          readOnly={isEditCase}
        />
      </div>
    );
  };

  const renderWarehouseDropDown = () => {
    return (
      <div className="column mt-m" style={{ width: '24%' }}>
        <DKInput
          className=""
          required={false}
          canValidate={formSubmitted}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          title={'Location'}
          value={formState.warehouse}
          renderer={(obj: any) => obj.name}
          onChange={(value: any) => updateForm('warehouse', value)}
          type={INPUT_TYPE.DROPDOWN}
          dropdownConfig={{
            style: { minWidth: 230 },
            className: 'shadow-m',
            title: 'Select Warehouse',
            allowSearch: true,
            searchableKey: 'name',
            canEdit: false,
            canDelete: false,
            data: getActiveWH(warehouseData?.content || []),
            renderer: (index: any, warehouse: any) => {
              return warehouse.name;
            },
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                const query: string = `?limit=50&page=0&filterOnRolesFlg=false&search=${searchValue}&allRRBDetails=true&query=active=true`;
                const finalEndPoint =
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                  query;
                return finalEndPoint;
              },
              dataParser: (response: any) => {
                return getActiveWH(response?.content || []);
              },
              debounceTime: 300
            }
          }}
        />
      </div>
    );
  };

  const renderChallanNumber = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Challan Number"
          value={formState.challanNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('challanNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderChallanDate = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Challan Date"
          value={formState.challanDate}
          type={INPUT_TYPE.DATE}
          onChange={(value: any) => updateForm('challanDate', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderTransporter = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Transporter"
          value={formState.transporter}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('transporter', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderDriverName = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Driver Name"
          value={formState.driverName}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('driverName', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderContactNumber = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Contact No"
          value={formState.contactNumber}
          type={INPUT_TYPE.NUMBER}
          onChange={(value: any) => updateForm('contactNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderRefDate = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Reference Date"
          value={formState.referenceDate}
          type={INPUT_TYPE.DATE}
          onChange={(value: any) => updateForm('referenceDate', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderLicenseNumber = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="License Number"
          value={formState.licenseNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('licenseNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderVehicleNumber = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Vehicle Number"
          value={formState.vehicleNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('vehicleNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderArrivalTime = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInputDateTime
          className="parent-width"
          title="Arrival Date/Time"
          showCalendarView={true}
          selectedDate={formState.arrivalTime}
          type={INPUT_TYPE.DATE}
          onSave={(value: any) => updateForm('arrivalTime', new Date(value))}
          onChange={(value: any) => {}}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderDepartureTime = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInputDateTime
          className="parent-width"
          title="Departure Date/Time"
          selectedDate={formState.departureTime}
          showCalendarView={true}
          type={INPUT_TYPE.DATE}
          onSave={(value: any) => updateForm('departureTime', new Date(value))}
          onChange={(value: any) => {}}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderWeighBridgeName = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Weigh Bridge Name"
          value={formState.weighbridgeName}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('weighbridgeName', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderWeighBridgeDate = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Weigh Bridge Date"
          value={formState.weighbridgeDate}
          type={INPUT_TYPE.DATE}
          onChange={(value: any) => updateForm('weighbridgeDate', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderWeighBridgeSlipNo = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Weigh Bridge Slip No"
          value={formState.weighbridgeSlipNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('weighbridgeSlipNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderWeighBridgeSlipDate = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Weigh Bridge Slip Date"
          value={formState.weighbridgeSlipDate}
          type={INPUT_TYPE.DATE}
          onChange={(value: any) => updateForm('weighbridgeSlipDate', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderEWayBillNo = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="E-Way Bill No"
          value={formState.ewayBillNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('ewayBillNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderEWayDate = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="E-Way Date"
          value={formState.ewayDate}
          type={INPUT_TYPE.DATE}
          onChange={(value: any) => updateForm('ewayDate', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          dateFormat={convertBooksDateFormatToUILibraryFormat(
            tenantInfo.dateFormat
          )}
        />
      </div>
    );
  };

  const renderLRNumber = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="LR Number"
          value={formState.lrNumber}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('lrNumber', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderFromAddress = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="From Address"
          value={formState.fromAddress}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('fromAddress', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderToAddress = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="To Address"
          value={formState.toAddress}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('toAddress', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderFreightType = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Freight Type"
          value={formState.freightType}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('freightType', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderDistance = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Distance in KM"
          value={formState.distanceKm}
          type={INPUT_TYPE.NUMBER}
          onChange={(value: any) => updateForm('distanceKm', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderVehicleType = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Vehicle Type"
          value={formState.vehicleType}
          type={INPUT_TYPE.TEXT}
          onChange={(value: any) => updateForm('vehicleType', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderVehicleCapacity = () => {
    return (
      <div className={'column mt-m'} style={{ width: '24%' }}>
        <DKInput
          className="parent-width"
          title="Vehicle Capacity"
          value={formState.vehicleCapacity}
          type={INPUT_TYPE.NUMBER}
          onChange={(value: any) => updateForm('vehicleCapacity', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
        />
      </div>
    );
  };

  const renderItemGrid = () => {
    return (
      <div className="parent-width mt-s" ref={gridContainerRef}>
        <div className="row">
          <DKDataGrid
            width={gridWidth}
            title="Item Details"
            rows={getRows()}
            columns={columns}
            needNoDataView={true}
            onRowUpdate={onRowUpdate}
            allowBulkOperation={false}
            allowColumnEdit={false}
            allowRowEdit={true}
            needTrailingColumn={true}
            needColumnIcons={false}
            needShadow={false}
            needBorder={true}
            allowColumnSort={false}
            dateFormat={convertBooksDateFormatToUILibraryFormat(
              tenantInfo.dateFormat
            )}
            buttons={null}
            onRowClick={({ rowData }: any) => {
              setUomsForSelectedProduct(rowData.product);
            }}
          />
        </div>

        <div className="row">
          <DKButton
            title={`+ Add Item`}
            onClick={addGateEntryItem}
            className={`text-blue fw-m p-0`}
            style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
          />
        </div>
      </div>
    );
  };

  const renderRemarks = () => {
    return (
      <div className={'column mt-m'} style={{ width: '50%' }}>
        <DKInput
          className="parent-width"
          title="Remarks"
          value={formState.remarks}
          type={INPUT_TYPE.LONG_TEXT}
          maxLength={REMARKS_CHAR_LIMIT}
          onChange={(value: any) => updateForm('remarks', value)}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          canValidate={formSubmitted}
        />
      </div>
    );
  };

  const renderAttachment = () => {
    return (
      <Fragment>
        <DKButton
          title={
            <>
              + Attach files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          className={`text-blue mt-r fw-m`}
          style={{ paddingLeft: 0 }}
          onClick={triggerAttachmentUpload}
        />
        <div className="row pointer-events-auto">{getAttachments()}</div>
      </Fragment>
    );
  };

  return (
    <div className="p-h-l">
      <>
        <DKLabel text="Document Fields" className="fw-m fs-m mt-l mb-s" />
        <DKLine />
        <div className="row flex-wrap" style={{ gap: '1%' }}>
          {renderCustomNumberFormat()}
          {renderContactDropDown()}
          {renderRefTypeDocumentDropDown()}
          {renderRefDocumentDropDown()}
        </div>
      </>
      <>
        <DKLabel text="General Fields" className="fw-m fs-m mt-l mb-s" />
        <DKLine />
        <div className="row flex-wrap" style={{ gap: '1%' }}>
          {renderWarehouseDropDown()}
          {renderChallanNumber()}
          {renderChallanDate()}
          {renderTransporter()}
          {renderDriverName()}
          {renderContactNumber()}
          {renderLicenseNumber()}
          {renderVehicleNumber()}
          {renderWeighBridgeName()}
          {renderWeighBridgeDate()}
          {renderWeighBridgeSlipNo()}
          {renderWeighBridgeSlipDate()}
          {renderRefDate()}
          {renderArrivalTime()}
          {renderDepartureTime()}
          {renderLRNumber()}
          {tenantInfo?.country === COUNTRY_CODES.IN && renderEWayBillNo()}
          {tenantInfo?.country === COUNTRY_CODES.IN && renderEWayDate()}
          {renderFromAddress()}
          {renderToAddress()}
          {renderFreightType()}
          {renderDistance()}
          {renderVehicleType()}
          {renderVehicleCapacity()}
        </div>
      </>
      <div className="row">
        <div className="column parent-width mt-m">
          <CustomFieldsHolder
            moduleName={MODULES_NAME.SECURITY_GATE_ENTRY}
            customFieldsList={
              props?.gateEntry?.customField ? props?.gateEntry.customField : []
            }
            columnConfig={[]}
            columnConfigTableId={''}
            documentMode={isEditCase ? DOCUMENT_MODE.EDIT : DOCUMENT_MODE.NEW}
            onUpdate={(customField) => {
              updateForm('customField', customField);
            }}
          />
        </div>
      </div>

      {renderItemGrid()}
      <div className="row flex-wrap mb-l">{renderRemarks()}</div>
      {renderAttachment()}
    </div>
  );
};

export default AddSecurityGateEntry;
