import {
  DKIcon,
  DKIcons,
  DKLabel,
  removeLoader,
  showAlert,
  showLoader,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_delete from '../../Assets/Icons/ic_delete.png';
import {
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import useConfirm from '../../Hooks/useConfirm';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import { selectContacts } from '../../Redux/Slices/ContactsSlice';
import { getBuyDashboard } from '../../Redux/Slices/DashboardSlice';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import DateFormatService from '../../Services/DateFormat';
import PaymentService from '../../Services/Payment';
import { deepClone } from '../../Utility/Utility';
import PopupWrapper from '../PopupWrapper';
import PrintPreview from '../Printing/PrintPreview';
import AddEmailPopUp from './AddEmailPopUp';
import EmailPaymentRecord from './EmailPaymentRecord';
import PrintCheque from './PrintCheque';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import PaymentRecordsDetail from './PaymentRecordsDetail';

interface PaymentRecordProps {
  documentSequenceCode?: any;
  paymentRecordList: any[] | undefined;
  documentType: DOC_TYPE;
  documentCode: string;
  contactCode: string;
  isReport?: boolean;
  closePopup: () => void;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  isDocumentEmailFlow?: boolean;
  isActionDisabled?: boolean;
  deleteHide?: boolean;
  contactId?: any;
}

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

const PaymentRecords: React.FC<PaymentRecordProps> = (props) => {
  const { confirm } = useConfirm();
  const { t, i18n } = useTranslation();
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  const [recordList, setRecordList] = useState<any>(props.paymentRecordList);
  const [documentSequenceCode, setDocumentSequenceCode] = useState<any>(
    props.documentSequenceCode
  );

  const [selectedIndex, setSelectedIndex] = useState<any>(0);

  const [addEmailPopUp, setAddEmailPopUp] = useState(false);

  const [emailPaylaod, setEmailPaylaod] = useState<any>({});

  const emailPaymentRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const [feeAccountName, setFeeAccountName] = useState<string>('');

  const [showPrintCheque, setShowPrintCheque] = useState<boolean>(false);

  const [chequeData, setChequeData] = useState({});

  const [paymentRecordEmailPopup, setPaymentRecordEmailPopup] = useState(false);

  const contactData = useAppSelector(selectContacts);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [customField, setCustomField] = useState<any>([]);
  let module = MODULES_NAME.BILL;
  if (props.documentType === DOC_TYPE.INVOICE) {
    module = MODULES_NAME.INVOICE;
  }

  const [paymentData, setPaymentData] = useState<any>({
    accountName: '-',
    code: '-',
    id: '-',
    currency: 'INR',
    amount: '0.00',
    documentDate: '-',
    paymentType: '',
    referenceDate: '-',
    referenceNumber: '-',
    contactCode: '-',
    transactionFee: '-1',
    transactionFeeAccountName: ''
  });

  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const [isActionDisabled, setIsActionDisabled] = useState<boolean>(
    props?.isActionDisabled ?? false
  );

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    setFeeAccountName('');
    getPaymentDetails();
  }, [selectedIndex, recordList]);

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const dispatch = useAppDispatch();
  const printFulfillment = (fulfillmentData: any) => {
    setShowPrintPreview(true);
  };

  const deletePaymentRecords = (record: any, documentCode: any, i: any) => {
    let lastElement = false;
    if (recordList.length <= 1) {
      lastElement = true;
    }
    showLoader();

    let paymentCode = record.documentCode;
    let docCode = documentCode;
    if (
      record.documentType === DOC_TYPE.INVOICE ||
      record.documentType === DOC_TYPE.BILL
    ) {
      paymentCode = record.contraDocumentCode;
      docCode = record.documentCode;
    }
    PaymentService.deletePaymentRecord(
      paymentCode,
      docCode,
      record.documentType,
      record.uid
    ).then(
      (res: any) => {
        const updatedState = deepClone(recordList);
        updatedState.splice(i, 1);
        setRecordList([...updatedState]);
        if (
          record.documentType === DOC_TYPE.BILL ||
          record.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
          record.documentType === DOC_TYPE.CREDIT_NOTE ||
          record.documentType === DOC_TYPE.RECEIVE_PAYMENT
        ) {
          dispatch(fetchInvoices());
        } else {
          dispatch(fetchBills());
          dispatch(getBuyDashboard());
        }

        setSelectedIndex(0);
        lastElement === true ? closePopup() : setSelectedIndex(0);
        showAlert('Success!', 'Payment record unlinked successfully.');
        removeLoader();
      },
      (err) => {
        showAlert('Error!', 'Payment is in use, cannot be unlinked');
        removeLoader();
      }
    );
  };

  const getTransactionFeeAccount = (code: any) => {
    try {
      PaymentService.getFeeAccountData(code).then((data: any) => {
        let response = data.content[0];
        setFeeAccountName(`(` + response.name + `)`);
      });
    } catch (err) {
      console.error('Error loading Fee Accounts: ', err);
    }
  };

  const getTransactionFee = (receivePaymentFeeDtoList: any) => {
    if (receivePaymentFeeDtoList.length > 0) {
      getTransactionFeeAccount(receivePaymentFeeDtoList[0].accountCode);
      let sum = 0;
      receivePaymentFeeDtoList.map((paymentFee: any) => {
        sum += paymentFee.amount;
      });
      return sum;
    } else {
      return -1;
    }
  };

  const getPaymentDetails = () => {
    const newState = [...recordList];
    const obj = newState[selectedIndex];
    setCustomField(null);
    if (obj) {
      if (
        obj.documentType === 'RECEIVE_PAYMENT' ||
        obj.documentType === 'MAKE_PAYMENT' ||
        obj.documentType === 'DEPOSIT_ADVPAYMENT' ||
        obj.documentType === 'EXPENSE_PREPAYMENT'
      ) {
        PaymentService.fetchPaymentRecordDetails(
          obj.documentCode,
          props.documentType
        ).then((res: any) => {
          const content: any[] = res.content;
          if (content.length > 0 && newState) {
            const paymentDetails: any = content[0];

            setCustomField(paymentDetails.customField);
            var documentDate = new Date(paymentDetails.documentDate);
            var referenceDate = new Date(paymentDetails.referenceDate);
            if (
              obj.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
              obj.documentType === DOC_TYPE.PREPAYMENT
            ) {
              documentDate = new Date(obj.documentDate);
            }
            if (obj.documentType === 'RECEIVE_PAYMENT') {
              const obj1 = {
                accountName: paymentDetails.accountName,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode,
                whtApplicable: !!paymentDetails?.whtApplicable,
                whtInfoIsrael: paymentDetails?.whtInfoIsrael,
                transactionFee:
                  paymentDetails.receivePaymentFeeDtoList.length > 0
                    ? getTransactionFee(paymentDetails.receivePaymentFeeDtoList)
                    : -1
              };
              setPaymentData(obj1);
            } else {
              const obj1 = {
                accountName: paymentDetails.accountName,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                whtApplicable: !!paymentDetails?.whtApplicable,
                whtInfoIsrael: paymentDetails?.whtInfoIsrael,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode
              };
              setPaymentData(obj1);
            }
          }
        });
      } else {
        const obj1 = {
          code: obj.code,
          linkedDocumentSequence: obj.linkedDocumentSequence,
          documentCode: obj.linkedDocumentSequence,
          id: obj.id,
          currency: obj.currency,
          amount: obj.amount,
          paymentType: obj.documentType,
          referenceDate: '',
          referenceNumber: '',
          documentDate: obj.documentDate
            ? DateFormatService.getDateStrFromDate(new Date(obj.documentDate))
            : '-'
        };
        if (
          obj.documentType === 'CREDIT_NOTE' ||
          obj.documentType === 'DEBIT_NOTE'
        ) {
          obj1['documentCode'] = obj.documentCode;
        }
        setPaymentData(obj1);
      }
    }
  };

  const getDocumentNumber = (record: any) => {
    switch (record.documentType) {
      case 'CREDIT_NOTE':
        return record.linkedDocumentSequence;
      case 'DEBIT_NOTE':
        return record.linkedDocumentSequence;
      case 'RECEIVE_PAYMENT':
      case 'MAKE_PAYMENT':
      case 'DEPOSIT_ADVPAYMENT':
      case 'EXPENSE_PREPAYMENT':
        return record.documentCode;
      case 'SALES_INVOICE':
      case 'PURCHASE_INVOICE':
        return record.linkedDocumentSequence;
      case 'Bill Offset':
        return record.documentCode;
      case 'Invoice Offset':
        return record.documentCode;
      default:
        return '';
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setAddEmailPopUp(false);
        break;
      case POPUP_CLICK_TYPE.SEND_PAYMENT_MAIL:
        emailPaymentRef.current.storeCallbacksRef.sendPaymentEmail();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setAddEmailPopUp(false);
        break;
      case POPUP_CALLBACKS_TYPE.SEND_PAYMENT_MAIL:
        emailPaymentRef.current.storeCallbacksRef.sendPaymentEmail =
          passingData.data;
        break;
    }
  };

  const popupBtnConfigForAddWareHouse: BtnType[] = [
    {
      title: t(`WAREHOUSES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Send',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SEND_PAYMENT_MAIL
    }
  ];

  const makeEmailPayLoad = (
    email: any,
    contactName: any,
    contactAddress: any
  ) => {
    let invoiceNo = '';
    if (
      paymentData.receivePaymentItemDtoList &&
      paymentData.receivePaymentItemDtoList.length > 0
    ) {
      invoiceNo = paymentData.receivePaymentItemDtoList[0].documentSequenceCode;
    }

    let tenantName = '';
    if (tenantInfo && tenantInfo.data) {
      tenantName = tenantInfo.data.name ? tenantInfo.data.name : '';
    }

    let documentType: any = '';
    switch (props.documentType) {
      case DOC_TYPE.INVOICE:
        documentType = 'Invoice';
        break;
      case DOC_TYPE.BILL:
        documentType = 'Bill';
        break;
    }

    const a = {
      application: 'ERP',
      body: `Hello%2C%3Cbr%2F%3E%3Cbr%2F%3EThank%20you%20for%20reaching%20out%20to%20us.%3Cbr%2F%3E%3Cbr%2F%3EPlease%20find%20the%20${documentType}%20%5B${documentSequenceCode}%5D%20attached%20with%20this%20mail%2C%20in%20response%20to%20your%20inquiry.%3Cbr%2F%3E%3Cbr%2F%3EKindly%20review%20the%20same%20and%20contact%20us%20for%20any%20further%20queries%20or%20details.%20We%20look%20forward%20to%20doing%20business%20with%20you.%3Cbr%2F%3E%3Cbr%2F%3EThanks%20%26%20Regards%2C%3Cbr%2F%3E%5BIN%20org%5D`,
      category: 'PAYMENT',
      exportDocumentRequest: {
        categoryName: 'PAYMENT',
        documentNumber: paymentData.code,
        fileName: 'PAYMENT.DOCX',
        headers: [
          {
            contactCode: paymentData.contactCode,
            contactName: contactName,
            contactBillingAddress: contactAddress,
            items: [
              {
                SNo: 1,
                Description: `${documentType} # ${documentSequenceCode}`,
                Total: paymentData.currency + ' ' + paymentData.amount
              }
            ],
            checkDate: '',
            refDate: paymentData.referenceDate,
            exchangeRate: '',
            tenantCurrency: paymentData.currency,
            docCurrSymbol: paymentData.currency,
            tranCurrSymbol: paymentData.currency,
            amtPaidTranCurrency:
              paymentData.currency + ' ' + paymentData.amount,
            whtAmount: '',
            companyName: tenantName,
            docMemo: '',
            receiptNo: paymentData.code,
            date: paymentData.documentDate,
            refNumber: paymentData.referenceNumber,
            totalAmount: paymentData.currency + ' ' + paymentData.amount,
            paymentDescription: `${documentType} # ${invoiceNo}`,
            bankName: paymentData.accountName,
            paymentMode: paymentData.paymentType,
            amtPaidDocCurrency: paymentData.currency + ' ' + paymentData.amount
          }
        ],
        moduleName: 'ERP',
        templateId: 4
      },
      subject: `Confirmation of payment for ${documentType} ${documentSequenceCode}`,
      to: email
    };
    setEmailPaylaod(a);
    return a;
  };
  const emailFunction = (code: any, i: any) => {
    if (i != selectedIndex) {
      return;
    }
    let email: string = '';
    let contactName: string = '';
    let contactAddress: string = '';

    if (contactData && contactData.content && contactData.content.length > 0) {
      contactData.content.forEach((contact: any) => {
        if (contact.code === code) {
          email = contact.emailId ? contact.emailId : '';
          contactName = contact.name ? contact.name : '';
          contactAddress =
            contact.shippingAddress && contact.shippingAddress.length > 0
              ? contact.shippingAddress[0].state &&
                contact.shippingAddress[0].country
                ? contact.shippingAddress[0].state +
                  ',' +
                  contact.shippingAddress[0].country
                : ''
              : '';
        }
      });
    }

    if (props?.isDocumentEmailFlow) {
      makeEmailPayLoad(email, contactName, contactAddress);
      setPaymentRecordEmailPopup(true);
      return;
    }

    if (!email) {
      makeEmailPayLoad('', contactName, contactAddress);
      setAddEmailPopUp(true);
    } else {
      const payload = makeEmailPayLoad(email, contactName, contactAddress);
      PaymentService.emailPayment(payload)
        .then((response) => {
          showToast('Mail sent successfully', TOAST_TYPE.SUCCESS);
          setAddEmailPopUp(false);
        })
        .catch((error) => {
          console.error('Error sending mail: ', error);
        });
    }
  };
  const shouldShowPrint = (record: any) => {
    switch (record.documentType) {
      case 'RECEIVE_PAYMENT':
      case 'MAKE_PAYMENT':
      case 'DEPOSIT_ADVPAYMENT':
      case 'EXPENSE_PREPAYMENT':
        return true;
      default:
        return false;
    }
  };

  const getDocumentTypeForPrintRecord = () => {
    const type =
      recordList[selectedIndex] && recordList[selectedIndex].documentType
        ? recordList[selectedIndex].documentType
        : '';

    return type;
  };

  const getParentDocumentType = () => {
    return props.documentType;
  };

  const getParentDocumentCode = () => {
    return props.documentCode;
  };

  const getUid = () => {
    return recordList[selectedIndex].uid;
  };

  let amount = paymentData.amount;
  if (paymentData.whtApplicable) {
    amount =
      Number(amount || 0) -
      Number(paymentData?.whtInfoIsrael?.ilWhtAmount || 0);
  }
  return (
    <div className="flex justify-content-between parent-height ">
      {showPrintPreview && (
        <PrintPreview
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...recordList[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setShowPrintPreview(val)}
          parentDocumentType={getParentDocumentType()}
          parentDocumentCode={getParentDocumentCode()}
          uid={getUid()}
        />
      )}

      {paymentRecordEmailPopup && (
        <EmailPaymentRecord
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...recordList[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setPaymentRecordEmailPopup(val)}
          parentDocumentType={getParentDocumentType()}
          parentDocumentCode={getParentDocumentCode()}
          uid={getUid()}
          emailPaylaod={emailPaylaod}
        />
      )}

      <div
        className="flex"
        style={{
          width: !isDesktop ? '35%' : '25%',
          height: 400,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <ul className="flex flex-col w-full h-full">
          {recordList &&
            recordList.map((record: any, i: any) => {
              return (
                <li
                  className={
                    'flex mr-2 cursor-hand p-2 flex-col hover:-100 rounded mb-2' +
                    (selectedIndex === i ? ' bg-blue-100 ' : '')
                  }
                  onClick={() => {
                    setCustomField(null);
                    setTimeout(() => {
                      setSelectedIndex(i);
                    }, 0);
                  }}
                >
                  <div className="text-black row-responsive fs-r ml-0 flex justify-content-between ">
                    <DKLabel
                      className="pr-2  font-medium"
                      text={getDocumentNumber(record)}
                    />
                    <div className="flex" style={{ marginTop: '3px' }}>
                      {!props.isReport && (
                        <>
                          {shouldShowPrint(record) && (
                            <>
                              <DKIcon
                                style={{ marginRight: '5px' }}
                                src={DKIcons.ic_email}
                                className="ic-s cursor-hand"
                                title="Print"
                                onClick={() => {
                                  if (isActionDisabled) {
                                    showAlert(
                                      'Action Prevented.',
                                      'You cannot perform this action because it is read-only popup.'
                                    );
                                    return;
                                  }
                                  emailFunction(paymentData.contactCode, i);
                                }}
                              />
                              {!isTabletView() && (
                                <DKIcon
                                  style={{ marginRight: '3px' }}
                                  src={DKIcons.ic_printer}
                                  className="ic-s cursor-hand"
                                  title="Print"
                                  onClick={() => {
                                    if (isActionDisabled) {
                                      showAlert(
                                        'Action Prevented.',
                                        'You cannot perform this action because it is read-only popup.'
                                      );
                                      return;
                                    }
                                    printFulfillment(record);
                                  }}
                                />
                              )}
                            </>
                          )}
                          {!props.deleteHide && (
                            <DKIcon
                              src={ic_delete}
                              className="ic-s cursor-hand"
                              title="Delete"
                              onClick={async () => {
                                if (isActionDisabled) {
                                  showAlert(
                                    'Action Prevented.',
                                    'You cannot perform this action because it is read-only popup.'
                                  );
                                  return;
                                }
                                const isConfirmed = await confirm(
                                  t(`CONFIRMATION_POPUP.SURE_UNLINK_TEXT`)
                                );
                                if (isConfirmed) {
                                  deletePaymentRecords(
                                    record,
                                    props.documentCode,
                                    i
                                  );
                                }
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-content-between">
                    <span className="text-black-light fs-r">{}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="flex flex-col px-4 pl-5"
        style={{
          width: !isDesktop ? '65%' : '75%',
          // overflowY: 'auto',
          // scrollbarWidth: 'thin',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12'
        }}
      >
        <PaymentRecordsDetail
          documentSequenceCode={props.documentSequenceCode}
          paymentRecordList={props.paymentRecordList}
          paymentData={paymentData}
          documentType={props.documentType}
          documentCode={props.documentCode}
          contactCode={props.contactCode}
          closePopup={() => {}}
          passingInteraction={(callback: CallBackPayloadType) => {}}
          isDocumentEmailFlow={props.isDocumentEmailFlow}
          selectedIndex={selectedIndex}
          contactId={props?.contactId}
        />
      </div>
      {addEmailPopUp && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add Email address for Contact'}
          btnList={popupBtnConfigForAddWareHouse}
          width={'30%'}
          height={'200px'}
        >
          <AddEmailPopUp
            payload={emailPaylaod}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
      {showPrintCheque && (
        <PrintCheque
          data={chequeData}
          onCancel={() => {
            setShowPrintCheque(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentRecords;
