import { BOOKS_DATE_FORMAT, DOC_TYPE } from '../../../Constants/Constant';
import { Store } from '../../../Redux/Store';
import DateFormatService from '../../../Services/DateFormat';
import Utility from '../../../Utility/Utility';
import {
  IStockAdjustmentItem,
  IStockAdjustmentPayload,
  StockAdjustment,
  StockAdjustmentItems
} from './StockAdjustmentConstant';

export const checkDateValidation = (inpDate: any, tenantInfo: any) => {
  return Utility.checkActiveDateRangeValidation(
    inpDate,
    tenantInfo,
    'Stock Adjustment Date',
    'STOCK_ADJUSTMENT'
  );
};
const putQtyInBatch = (batch: any, item: any) => {
  return {
    ...batch,
    quantity: Utility.isNotEmpty(item?.documentUOMSchemaDefinition?.schemaId)
      ? Utility.getUomWarehouseQuantity(
          +batch.quantity,
          item.documentUOMSchemaDefinition
        )
      : +batch.quantity
  };
};

export const createPayload = (
  state: StockAdjustment,
  isEdit = false
): IStockAdjustmentPayload => {
  const copyOfState = { ...state };
  const payload: IStockAdjustmentPayload = {
    notes: copyOfState?.notes?.toString(),
    reason:
      copyOfState?.adjustmentReason?.code ||
      copyOfState?.adjustmentReason?.value,
    adjustmentReason:
      copyOfState?.adjustmentReason?.code ||
      copyOfState?.adjustmentReason?.value,
    customField: Utility.isNotEmpty(copyOfState?.customField)
      ? copyOfState?.customField
      : [],
    approvalConfig: getApprovalConfig(DOC_TYPE.STOCK_ADJUSTMENT),
    isApprovalFlowEnabled: getIsApprovalFlowEnabled(DOC_TYPE.STOCK_ADJUSTMENT)
  };
  if (isEdit) {
    payload['code'] = copyOfState?.autoNumberingFormat;
    return payload;
  }
  let totalValue = 0;
  const stockAdjustmentItems: IStockAdjustmentItem[] =
    copyOfState?.stockAdjustmentItems?.map((item: StockAdjustmentItems) => {
      totalValue += item.totalAmount;
      // *Info - Just send batch details with quantity more than 0
      const batchDetails: any[] = [];
      item.batchDetails?.reduce((acc: any[], batch: any) => {
        batch = putQtyInBatch(batch, item);
        if (batch.quantity > 0) {
          batchDetails.push(batch);
        }
        return batchDetails;
      }, batchDetails);

      const stockItem: IStockAdjustmentItem = {
        batchDetails,
        customField: [],
        stockItemNumber: item.stockItemNumber,
        documentUom: item.documentUom,
        documentUOMSchemaDefinition: item.uomSchema,
        perUnitValue: item.perUnitValue,
        productName: item.productName,
        productType: item.productType,
        productVariantCode: item.productVariantCode,
        quantity: item.quantity,
        stockAdjustmentAccountCode: item.stockAdjustmentAccount?.code,
        stockAdjustmentAccountName: item.stockAdjustmentAccount?.name,
        uomQuantity: item.uomQuantity,
        uomUnitPrice: item.uomUnitPrice,
        serialNumbers: item.serialNumbers
      };
      return stockItem;
    });
  payload['warehouseCode'] = copyOfState?.warehouseCode?.code;
  payload['rowCode'] = copyOfState?.rowCode || null;
  payload['rackCode'] = copyOfState?.rackCode || null;
  payload['binCode'] = copyOfState?.binCode || null;
  payload['adjustmentDate'] = DateFormatService.getDateStrFromDate(
    copyOfState?.adjustmentDate,
    BOOKS_DATE_FORMAT['YYYY-MM-DD']
  );
  payload['adjustmentType'] = copyOfState?.adjustmentType?.value;
  payload['totalValue'] = totalValue;
  payload['stockAdjustmentItems'] = stockAdjustmentItems;

  return payload;
};

export const getIsApprovalFlowEnabled = (docType: any): boolean => {
  const tenantDetails = Store.getState().authInfo.currentTenantInfo.data;
  let booksApprovalList = Store.getState().automation.data;
  let isModulePresent =
    tenantDetails?.additionalSettings?.INVENTORY_APPROVAL_SETTING?.find(
      (doc: any) => doc.module === docType
    )?.enable || false;
  let isStockAdjAutomationPresent = booksApprovalList?.filter(
    (ele: any) => ele.fields[0].field_type === docType
  );
  return isModulePresent && isStockAdjAutomationPresent?.length > 0;
};

export const getApprovalConfig = (docType: any) => {
  let booksApprovalList = Store.getState().automation.data;
  const data = booksApprovalList?.find(
    (ele: any) => ele.fields[0].field_type === docType
  );
  return data?.fields?.[0] ?? {};
};
