import { APPROVAL_STATUS, DOC_TYPE, LABELS } from './../Constants/Constant';

export const DraftTypes = {
  NEW: 'new',
  UPDATE: 'update',
  DRAFT: 'draft',
  COPY: 'copy',
  READONLY: 'read-only'
};

export const DOCUMENT_TYPE_LIST = [
  LABELS.PRODUCTS,
  LABELS.CONTACT,
  LABELS.INVOICES,
  LABELS.BILLS,
  LABELS.PURCHASE_ORDERS,
  LABELS.WORK_OUT,
  LABELS.QUOTES,
  LABELS.SALES_ORDER,
  LABELS.EXPENSE_BILL,
  LABELS.REQUISITION
];

export const UPDATE_TYPES = {
  SAVE_POPULATE_DATA: 'save_populate_data',
  UPDATE: 'update'
};

export interface DraftApprovalPayload {
  draftIds: string[];
  approvalStatus: APPROVAL_STATUS;
  documentType: DOC_TYPE;
}
