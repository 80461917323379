import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT
} from '../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { selectBatchTrackingProduct } from '../../Redux/Slices/BatchTrackingSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../Utility/Utility';

import '../../App.scss';
import { selectAdvancedTrackingData } from '../../Redux/Slices/AdvancedTrackingDataSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import ProductService from '../../Services/Product';
import WarehouseManagementHelper from '../WarehouseManagement/WarehouseManagementHelper';
import { DynamicPopupWrapper } from '../PopupWrapper';
import {
  fetchWarehouseWithRRBCombination,
  selectedWarehouseWithRRBCombination
} from '../../Redux/Slices/WarehouseSlice';
import ApiConstants from '../../Constants/ApiConstants';
import ReceiveJWOProduct from '../../Components/StockManagement/StockTransfer/ReceiveJWOProduct';

const BatchTrackingReceive: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [itemDetails, setItem] = useState(props.itemDetails);

  const [pendingQuantity, setPendingQuantity] = useState(
    props.itemDetails.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          itemDetails.quantityRequired ||
            Utility.pendingToBeReceivedQuantity(itemDetails),
          props.itemDetails.documentUOMSchemaDefinition
        )
      : itemDetails.quantityRequired ||
          Utility.pendingToBeReceivedQuantity(itemDetails)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const productWarehouse = useAppSelector(selectBatchTrackingProduct);
  const allWarehouseData = useAppSelector(selectedWarehouseWithRRBCombination);

  const [productInventoryWarehouse, setProductInventoryWarehouse] = useState<
    any[]
  >([]);
  const [availableBatchData, setAvailableBatchData] = useState<any[]>([]);
  const [availableRowData, setAvailableRowData] = useState<any[]>([]);
  const [availableRackData, setAvailableRackData] = useState<any[]>([]);
  const [availableBinData, setAvailableBinData] = useState<any[]>([]);
  const advancedTrackingProductsData = useAppSelector(
    selectAdvancedTrackingData
  );
  const [productDetails, setProductDetails] = useState<any>();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [gridData, setGridData] = useState<any>([
    {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: 0,
      batchSizeFulfilled: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    }
  ]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    itemDetails.requiredQuantity
  );

  const [isBOMRawProductSerialBatch, setIsBOMRawProductSerialBatch] = useState(
    Utility.isBatchOrSerialAdvanceTracking(
      props.itemDetails?.product?.bomProductsConfiguration
    )
  );

  const [rawMaterialData, setRawMaterialData] = useState(null);
  const [rawMaterialToConsumedData, setRawMaterialToConsumedData] = useState(
    props.details?.rawMaterialToConsume || []
  );
  const dispatch = useAppDispatch();

  const getDataGridColumns = () => {
    let columns = [
      {
        key: 'warehouseCode',
        name: 'Warehouse',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj.value.name;
        },
        dropdownConfig: {
          className: '',
          style: {},
          allowSearch: true,
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const query: string = `?limit=50&page=0&search=${searchValue}&allRRBDetails=true&query=active=true`;
              const finalEndPoint =
                ApiConstants.URL.BASE +
                ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                query;
              return finalEndPoint;
            },
            dataParser: (response: any) => {
              let filtered = response?.content?.filter(
                (wh: any) => wh.warehouseType === 'NONE'
              );
              return filtered;
            },
            debounceTime: 300
          },
          data: [],
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj.name} />;
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'serialBatchNumber',
        name: 'Batch Number',
        type: INPUT_TYPE.TEXT,
        textAlign: 'left',
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: ({ value }: any) => {
          return value;
        },
        dropdownConfig: {
          data: [],
          renderer: (value: any) => {
            return value.serialBatchNumber;
          }
        }
      },
      {
        key: 'row',
        name: 'Select Row',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj?.value ? obj?.value?.name : '';
        },
        dropdownConfig: {
          allowSearch: true,
          searchableKey: 'name',
          data: [],
          renderer: (index: any, value: any) => {
            return value?.name ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'rack',
        name: 'Select Rack',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj?.value ? obj?.value?.name : '';
        },
        dropdownConfig: {
          allowSearch: true,
          searchableKey: 'name',
          data: [],
          renderer: (index: any, value: any) => {
            return value?.name ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'bin',
        name: 'Select Bin',
        type: INPUT_TYPE.DROPDOWN,
        textAlign: 'left',
        width: 120,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj?.value ? obj?.value?.name : '';
        },
        dropdownConfig: {
          allowSearch: true,
          searchableKey: 'name',
          data: [],
          renderer: (index: any, value: any) => {
            return value?.name ?? '';
          },
          onSelect: (index: any, value: any) => {}
        }
      },
      {
        key: 'manufacturingDate',
        name: 'Manufactured Date',
        textAlign: 'center',
        type: INPUT_TYPE.DATE,
        width: 150,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: (obj: any) => {
          return (
            <div className="row">
              <DKTooltipWrapper content={'Manufactured Date'}>
                <div className="row">
                  <DKIcon
                    src={DKIcons.ic_calendar}
                    className="ic-s  ml-s cursor-hand mr-5"
                    onClick={() => {}}
                  />
                </div>
              </DKTooltipWrapper>
              <DKLabel
                text={`${
                  obj?.value
                    ? DateFormatService.getDateStrFromDate(new Date(obj.value))
                    : ''
                }`}
              />
            </div>
          );
        }
      },
      {
        key: 'expiryDate',
        name: 'Expiry Date',
        type: INPUT_TYPE.DATE,
        width: 150,
        textAlign: 'center',
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: (obj: any) => {
          return (
            <div className="row">
              <DKTooltipWrapper content={'Expiry Date'}>
                <div className="row">
                  <DKIcon
                    src={DKIcons.ic_calendar}
                    className="ic-s  ml-s cursor-hand mr-5"
                    onClick={() => {}}
                  />
                </div>
              </DKTooltipWrapper>

              <DKLabel
                text={`${
                  obj?.value
                    ? DateFormatService.getDateStrFromDate(new Date(obj.value))
                    : ''
                }`}
              />
            </div>
          );
        }
      },
      {
        key: 'batchSize',
        name: 'Quantity Assigned',
        type: INPUT_TYPE.NUMBER,
        textAlign: 'right',
        width: 140,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: (obj: any) => {
          if (
            obj.rowData.invalidFields &&
            obj.rowData.invalidFields.length > 0 &&
            obj.rowData.invalidFields.includes('batchSize') &&
            obj.rowData.serialBatchNumber !== ''
          ) {
            return (
              <div className="row justify-content-between">
                <DKTooltipWrapper
                  content={
                    'Quantity should be less than or equal to required quantity (' +
                    pendingQuantity +
                    ')'
                  }
                  tooltipClassName=""
                >
                  <div className="row">
                    <DKIcon
                      src={ic_warning_red}
                      className="ic-xs  ml-s cursor-hand"
                      onClick={() => {}}
                    />
                  </div>
                </DKTooltipWrapper>

                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          } else {
            return (
              <div className="row justify-content-between">
                <div></div>
                <DKLabel
                  text={`${Utility.roundingOff(
                    Number(obj.value),
                    QTY_ROUNDOFF_PRECISION
                  )}`}
                />
              </div>
            );
          }
        },
        formatter: (obj: any = 0) => {
          return `${Utility.roundingOff(
            Number(obj.value),
            QTY_ROUNDOFF_PRECISION
          )}`;
        }
      },
      {
        id: 'action',
        key: 'action',
        name: '',
        type: INPUT_TYPE.BUTTON,
        width: 80,
        options: []
      }
    ];

    let updatedColumns = getUpdatedColumnConfigs(columns);

    return updatedColumns;
  };

  const getRowButtons = () => {
    let buttons: any[] = [];
    buttons.push({
      icon: DKIcons.ic_delete,
      className: 'ic-xs',
      onClick: onDelete
    });

    return buttons;
  };

  const getMappedRows = (rows: any[]) => {
    return rows.map((row: any) => {
      const newRow = {
        ...row,
        rowButtons: getRowButtons(),
        rowContextMenu: null
      };
      return newRow;
    });
  };

  const getUpdatedColumnConfigs = (columns: any) => {
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    const enabledRRBNames = rrbEnabled
      ?.filter((rrb: any) => rrb.enable)
      ?.map((itemRRB: any) => {
        return itemRRB?.name;
      });

    const updatedColumns: any = [];
    columns?.forEach((col: any) => {
      if (
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        //check and push ROW RACK BIN here
        if (enabledRRBNames?.includes(col.key.toUpperCase())) {
          const foundRRB = rrbEnabled?.find(
            (rrb: any) => rrb.name === col.key.toUpperCase()
          );
          col = {
            ...col,
            name: `Select ${foundRRB?.label ?? ''}`
          };
          updatedColumns.push(col);
        }
      } else {
        updatedColumns.push(col);
      }
    });

    return updatedColumns;
  };

  const [gridColumnConfig, setGridColumnConfig] = useState(
    getDataGridColumns()
  );

  const [defaultWarehouse, setDefaultWarehouse] = useState<any>();

  useEffect(() => {
    if (Utility.isEmpty(allWarehouseData)) {
      const config: any = {
        SearchTerm: '',
        Limit: 50,
        Page: 0,
        Query: 'active=true&allRRBDetails=true'
      };
      dispatch(fetchWarehouseWithRRBCombination(config));
    }
    updateConfig();
  }, []);

  useEffect(() => {
    updateConfig();
  }, [
    availableBatchData,
    availableBinData,
    availableRackData,
    availableRowData
  ]);

  useEffect(() => {
    if (!Utility.isEmpty(allWarehouseData?.content)) {
      let filteredData = allWarehouseData?.content.filter(
        (ele: any) => ele.warehouseType === 'NONE'
      );
      if (!Utility.isEmpty(filteredData)) {
        setProductInventoryWarehouse(filteredData || []);
      }
    }
  }, [allWarehouseData]);

  const updateConfig = (data: any = null) => {
    let config = [...gridColumnConfig];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouseCode':
          conf.dropdownConfig.data = getFilteredWarehousesForBuy() ?? [];
          conf.renderer = ({ rowData }: any) =>
            rrbColumnRenderer({ rowData }, rowData?.warehouseCode?.name, conf);
          conf.dropdownConfig.allowSearch = props?.isSearchAllow ?? true;
          if (Utility.isRRBTaggingEnabled()) {
            conf.dropdownConfig.allowSearch = props?.isRRBTaggingEnabled
              ? false
              : true;
          }
          conf.dropdownConfig.searchApiConfig = {
            getUrl: (searchValue: string) => {
              const query: string = `?limit=50&page=0&search=${searchValue}&query=active=true&allRRBDetails=true`;
              const finalEndPoint =
                ApiConstants.URL.BASE +
                ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                query;
              return finalEndPoint;
            },
            dataParser: (response: any) => {
              let filtered = response?.content?.filter(
                (wh: any) => wh.warehouseType === 'NONE'
              );
              return filtered;
            },
            debounceTime: 300
          };
          break;
        case 'serialBatchNumber':
          conf.dropdownConfig.data = availableBatchData ?? [];
          conf.renderer = ({ rowData }: any) =>
            rrbColumnRenderer({ rowData }, rowData?.serialBatchNumber, conf);
          break;
        case 'row':
          conf.dropdownConfig.data = availableRowData ?? [];
          conf.renderer = ({ rowData }: any) =>
            rrbColumnRenderer({ rowData }, rowData?.row?.name, conf);
          break;
        case 'rack':
          conf.dropdownConfig.data = availableRackData ?? [];
          conf.renderer = ({ rowData }: any) =>
            rrbColumnRenderer({ rowData }, rowData?.rack?.name, conf);
          break;
        case 'bin':
          conf.dropdownConfig.data = availableBinData ?? [];
          conf.renderer = ({ rowData }: any) =>
            rrbColumnRenderer({ rowData }, rowData?.bin?.name, conf);
          break;
        case 'batchSize':
          conf.renderer = batchSizeRenderer;
          break;
      }
    });

    setGridColumnConfig([...config]);
  };

  useEffect(() => {
    if (
      !Utility.isEmpty(productInventoryWarehouse) &&
      !Utility.isEmpty(productDetails)
    ) {
      setDefaultWarehouseAction();
    }
  }, [productInventoryWarehouse, productDetails]);

  useEffect(() => {
    setDataAfterWarehouseFetch();
  }, [productInventoryWarehouse]);

  const setDataAfterWarehouseFetch = async () => {
    let initialGridData: any = [];
    let serialData: any[] = [];
    let updatedData: any[] = [];

    if (Utility.isNotEmpty(props?.itemDetails?.advancedTrackingMetaData)) {
      props.itemDetails.advancedTrackingMetaData.forEach((a: any) => {
        let warehouseObject = productInventoryWarehouse?.find(
          (warehouse: any) => warehouse.code === a.warehouseCode
        );
        const item = {
          ...a,
          warehouseCode: warehouseObject,
          serialBatchNumber: a.serialBatchNumber,
          manufacturingDate: DateFormatService.getDateFromStr(
            a.manufacturingDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          expiryDate: DateFormatService.getDateFromStr(
            a.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          batchSize: a.batchSize,
          batchSizeFulfilled: 0,
          invalidFields: [],
          reservedQuantity: a?.reservedQuantity
        };
        updatedData.push(item);
      });
      initialGridData = updatedData;
      let totalItem = updatedData.reduce(
        (a: number, b: any) =>
          Number(a) + Number(b?.batchSize ?? b?.quantity ?? 0),
        0
      );

      //set rrb data
      props.itemDetails.advancedTrackingMetaData?.forEach(
        (itemDetails: any) => {
          let rrbWarehouseSelected = productInventoryWarehouse.find(
            (warehouse: any) => warehouse.code === itemDetails.warehouseCode
          );

          //row
          setAvailableRowData(rrbWarehouseSelected?.warehouseRowInfos ?? []);

          //rack
          if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)) {
            let racks = rrbWarehouseSelected?.warehouseRackInfos?.filter(
              (rackChip: any) => {
                return rackChip.rowCode === itemDetails?.row?.code;
              }
            );
            setAvailableRackData(racks ?? []);
          }

          //bin
          if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseBinInfos)) {
            let bins = rrbWarehouseSelected?.warehouseBinInfos?.filter(
              (binChip: any) => {
                return binChip.rackCode === itemDetails?.rack?.code;
              }
            );
            setAvailableBinData(bins ?? []);
          }
        }
      );

      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
      setAvailableBatchData(serialData);
    } else {
      let productDetailsData: any;
      if (props?.module === MODULE_TYPE.BUY) {
        let data = await ProductService.getProductsByProductIds([
          props.itemDetails?.productCode
        ])
          .then((res: any) => {
            productDetailsData = res?.[0];
            setProductDetails(res?.[0]);
          })
          .catch((err: any) => {});
      }

      const nextYearDate = new Date();
      nextYearDate.setDate(nextYearDate.getDay() + 365);
      const defaultWarehouse = getDefaultWarehouse(productDetailsData);
      initialGridData = [
        {
          warehouseCode: getDefaultWarehouse(productDetailsData),
          serialBatchNumber: '',
          manufacturingDate: new Date(),
          expiryDate: nextYearDate,
          batchSize: 0,
          batchSizeFulfilled: 0,
          invalidFields: ['serialBatchNumber']
        }
      ];
      if (Utility.isEmptyObject(advancedTrackingProductsData)) {
        const filtered = advancedTrackingProductsData?.filter(
          (serial: any) =>
            serial.warehouseCode === defaultWarehouse?.code &&
            serial.batchSizeFulfilled < serial.batchSize &&
            serial.reservedQuantity < serial.batchSize
        );
        if (filtered) {
          setAvailableBatchData(filtered);
        }
      }
    }
    setGridData((prevState: any[]) => initialGridData);
  };

  const setDefaultWarehouseAction = () => {
    let warehouses = getFilteredWarehousesForBuy();
    let defaultWarehouseData: any;
    defaultWarehouseData =
      warehouses.find(
        (warehouse: any) =>
          warehouse.code === productDetails?.inventory?.warehouseCode
      ) || warehouses[0];
    if (Utility.isEmpty(defaultWarehouseData)) {
      defaultWarehouseData =
        warehouses.find((warehouse: any) => warehouse.primary) || warehouses[0];
    }
    setDefaultWarehouse({ ...defaultWarehouseData });
  };

  const getDefaultWarehouse = (productDetailsData?: any) => {
    let productData = productDetails || productDetailsData;
    let defaultWarehouseData: any;
    let warehouses = getFilteredWarehousesForBuy();
    if (!Utility.isEmpty(warehouses)) {
      defaultWarehouseData =
        warehouses.find(
          (warehouse: any) =>
            warehouse.code === productData?.inventory?.warehouseCode
        ) || warehouses[0];
      if (Utility.isEmpty(defaultWarehouseData)) {
        defaultWarehouseData =
          warehouses.find((warehouse: any) => warehouse.primary) ||
          warehouses[0];
      }
      if (
        Utility.isEmpty(defaultWarehouse) ||
        defaultWarehouse?.code !== defaultWarehouseData?.code
      ) {
        setDefaultWarehouse({ ...defaultWarehouseData });
      }
    }
    return defaultWarehouseData;
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: itemDetails
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const filterWarehousesForEdit = (item: any, batchData: any) => {
    let filteredAdvancedTrackingData =
      props?.filteredWarehouseInventoryData?.warehouseInventoryData?.find(
        (x: any) => {
          return x?.warehouseCode === item?.warehouseCode?.code;
        }
      );
    let filteredAllottedData: any[] = [];

    batchData.forEach((advData: any) => {
      let advDataInFiltered =
        filteredAdvancedTrackingData?.advancedTrackingData?.find(
          (aData: any) => aData.serialBatchNumber === advData.serialBatchNumber
        );
      if (!Utility.isEmpty(advDataInFiltered)) {
        filteredAllottedData.push({
          ...advDataInFiltered,
          ...advData,
          batchSize: advDataInFiltered?.qtyToFulfil,
          reservedQuantity: 0,
          batchSizeFulfilled: 0,
          batchSizeReturned: 0
        });
      }
    });
    return filteredAllottedData;
  };
  /**
   *
   * @param param - object consisting currentRow, currentIndex, for RRB rows,racks, bins
   * @returns array of string consisting invalid fields for currentRow
   * @description this function validates current row against the BatchTrack Assignment rules and returns a array of invalid strings
   */
  const getInvalidFields = ({
    allRows,
    index,
    rows,
    racks,
    bins
  }: any): string[] => {
    const invalidFields: string[] = [];
    const currentRow = allRows[index];
    const hasWareHouse = Utility.isNotEmpty(currentRow?.warehouseCode);
    const hasTrackingNumber = Utility.isNotEmpty(currentRow?.serialBatchNumber);
    const hasRows = Utility.isNotEmpty(rows);
    const hasRacks = Utility.isNotEmpty(racks);
    const hasBins = Utility.isNotEmpty(bins);
    const isRRBEnabled = hasRows || hasRacks || hasBins;
    const indexOfDuplicate = (columnKey: string, row: any): number => {
      return allRows.findIndex((lineItem: any) => {
        if (columnKey === 'warehouseCode') {
          return lineItem?.warehouseCode?.code === row?.warehouseCode?.code;
        }
        if (columnKey === 'serialBatchNumber') {
          return (
            lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
            lineItem?.serialBatchNumber === row?.serialBatchNumber
          );
        }
        if (columnKey === 'row') {
          return (
            lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
            lineItem?.serialBatchNumber === row?.serialBatchNumber &&
            lineItem?.row?.code === row?.row?.code
          );
        }
        if (columnKey === 'rack') {
          return (
            lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
            lineItem?.serialBatchNumber === row?.serialBatchNumber &&
            lineItem?.row?.code === row?.row?.code &&
            lineItem?.rack?.code === row?.rack?.code
          );
        }
        if (columnKey === 'bin') {
          return (
            lineItem?.warehouseCode?.code === row?.warehouseCode?.code &&
            lineItem?.serialBatchNumber === row?.serialBatchNumber &&
            lineItem?.row?.code === row?.row?.code &&
            lineItem?.rack?.code === row?.rack?.code &&
            lineItem?.bin?.code === row?.bin?.code
          );
        }
        return false;
      });
    };
    if (!hasWareHouse) {
      invalidFields.push('warehouseCode');
    } else {
      if (!hasTrackingNumber) {
        invalidFields.push('serialBatchNumber');
      } else {
        if (
          !isRRBEnabled &&
          indexOfDuplicate('serialBatchNumber', currentRow) !== index
        ) {
          invalidFields.push('serialBatchNumber');
        }
      }
      if (hasRows && Utility.isEmptyObject(currentRow?.row?.code)) {
        invalidFields.push('row');
      } else {
        if (!hasRacks && indexOfDuplicate('row', currentRow) !== index) {
          invalidFields.push('row');
        }
      }
      if (hasRacks && Utility.isEmptyObject(currentRow?.rack?.code)) {
        invalidFields.push('rack');
      } else {
        if (!hasBins && indexOfDuplicate('rack', currentRow) !== index) {
          invalidFields.push('rack');
        }
      }
      if (hasBins && Utility.isEmptyObject(currentRow?.bin?.code)) {
        invalidFields.push('bin');
      } else {
        if (indexOfDuplicate('bin', currentRow) !== index) {
          invalidFields.push('bin');
        }
      }
    }
    if (
      currentRow?.expiryDate?.setHours?.(0, 0, 0, 0) <
      currentRow?.manufacturingDate?.setHours?.(0, 0, 0, 0)
    ) {
      invalidFields.push('manufacturingDate');
    }

    if (props?.module === MODULE_TYPE.BUY) {
      const totalItem = Utility.roundingOff(
        allRows.reduce(
          (a: number, b: any) => Number(a) + Number(b?.batchSize || 0),
          0
        ),
        QTY_ROUNDOFF_PRECISION
      );

      if (
        !Utility.isPurchaseToleranceSettingsEnabled(
          props.parentDocumentType,
          props.parentDocumentDetails
        ) &&
        (parseFloat(currentRow['batchSize']) > parseFloat(pendingQuantity) ||
          totalItem > parseFloat(pendingQuantity))
      ) {
        invalidFields.push('batchSize');
        let { isBillLinked } = Utility.getPurchaseLinkedDocument(
          props.parentDocumentType,
          props.parentDocumentDetails
        );
        if (isBillLinked) {
          invalidFields.push('isBillLinked');
        }
      }
    }
    if (props?.module === MODULE_TYPE.SELL) {
      if (props.isMrpFlow) {
        if (
          Number(currentRow?.batchSizeFulfilled) <
          Number(currentRow?.reservedQuantity)
        ) {
          invalidFields.push('batchSizeFulfilled');
        }
      } else {
        if (
          Number(currentRow['batchSizeFulfilled']) >
          Number(currentRow['batchSize'])
        ) {
          invalidFields.push('batchSizeFulfilled');
        }
      }
    }

    return invalidFields;
  };

  const onRowUpdate = (val: any) => {
    const { columnKey, rowIndex, rowData } = val;

    let copyOfGridData = [...gridData];

    let rrbWarehouseSelected = productInventoryWarehouse.find(
      (warehouse: any) => warehouse.code === rowData.warehouseCode.code
    );
    let uniqueRows = availableRowData ?? [];
    let uniqueRacks = availableRackData ?? [];
    let uniqueBins = availableBinData ?? [];

    if (columnKey === 'warehouseCode') {
      copyOfGridData[rowIndex] = rowData;
      let warehouseData;
      let result;
      let batchData: any;
      warehouseData = advancedTrackingProductsData;
      result = warehouseData.filter(
        (data: any) => data.warehouseCode === rowData.warehouseCode.code
      );
      batchData = result?.filter(
        (batchItem: any) =>
          batchItem.batchSizeFulfilled < batchItem.batchSize &&
          batchItem.reservedQuantity < batchItem.batchSize
      );
      // for RRB
      uniqueRows = rrbWarehouseSelected?.warehouseRowInfos ?? [];
      uniqueRacks = rrbWarehouseSelected?.warehouseRackInfos ?? [];
      uniqueBins = rrbWarehouseSelected?.warehouseBinInfos ?? [];
      const sameWHAsProduct =
        rowData.warehouseCode.code === productDetails?.inventory?.warehouseCode;
      const selectedRow = sameWHAsProduct
        ? {
            code: productDetails?.inventory?.rowCode,
            name: productDetails?.inventory?.rowName
          }
        : null;
      const selectedRack = sameWHAsProduct
        ? {
            code: productDetails?.inventory?.rackCode,
            name: productDetails?.inventory?.rackName
          }
        : null;
      const selectedBin = sameWHAsProduct
        ? {
            code: productDetails?.inventory?.binCode,
            name: productDetails?.inventory?.binName
          }
        : null;
      copyOfGridData[rowIndex]['row'] = selectedRow;
      copyOfGridData[rowIndex]['rack'] = selectedRack;
      copyOfGridData[rowIndex]['bin'] = selectedBin;
      copyOfGridData[rowIndex]['serialBatchNumber'] = null;
      setAvailableBatchData(batchData);
    } else if (columnKey === 'serialBatchNumber') {
      if (typeof rowData.serialBatchNumber !== 'string') {
        // object
        copyOfGridData[rowIndex]['batchSize'] = 0;
        copyOfGridData[rowIndex]['manufacturingDate'] =
          DateFormatService.getDateFromStr(
            rowData.serialBatchNumber?.manufacturingDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
        copyOfGridData[rowIndex]['expiryDate'] =
          DateFormatService.getDateFromStr(
            rowData.serialBatchNumber?.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
        if (props?.module === MODULE_TYPE.SELL) {
          copyOfGridData[rowIndex]['serialBatchNumber'] =
            rowData.serialBatchNumber;
        } else {
          copyOfGridData[rowIndex]['serialBatchNumber'] =
            rowData.serialBatchNumber.serialBatchNumber;
          let totalItem = gridData.reduce(
            (a: any, b: any) => +Number(a) + +parseFloat(b.batchSize || 0),
            0
          );

          setTotalAllocatedItem(
            Utility.roundingOff(Number(totalItem), QTY_ROUNDOFF_PRECISION)
          );
        }
        copyOfGridData[rowIndex]['destinationWarehouseName'] =
          rowData?.serialBatchNumber?.destinationWarehouseName || '';
        copyOfGridData[rowIndex]['destinationWarehouseCode'] =
          rowData?.serialBatchNumber?.destinationWarehouseCode || '';
      } else {
        copyOfGridData[rowIndex]['serialBatchNumber'] =
          rowData?.serialBatchNumber?.trim();
        copyOfGridData[rowIndex]['batchSize'] = 0;
      }
      copyOfGridData[rowIndex]['row'] = null;
      copyOfGridData[rowIndex]['rack'] = null;
      copyOfGridData[rowIndex]['bin'] = null;
      uniqueRows = rowData?.warehouseCode?.warehouseRowInfos || [];
      uniqueRacks = rowData?.warehouseCode?.warehouseRackInfos || [];
      uniqueBins = rowData?.warehouseCode?.warehouseBinInfos || [];
      copyOfGridData[rowIndex]['editable'] = false;
    } else if (columnKey === 'batchSizeFulfilled') {
      let totalItem = 0;
      totalItem = gridData.reduce(
        (a: any, b: any) => +Number(a) + +parseFloat(b.batchSizeFulfilled || 0),
        0
      );
      setTotalAllocatedItem(
        Utility.roundingOff(Number(totalItem), QTY_ROUNDOFF_PRECISION)
      );
    } else if (columnKey === 'batchSize') {
      copyOfGridData[rowIndex]['batchSize'] = Utility.roundingOff(
        Number(rowData?.batchSize) || 0,
        QTY_ROUNDOFF_PRECISION
      );
      let totalItem = 0;
      if (props?.module === MODULE_TYPE.BUY) {
        totalItem = copyOfGridData.reduce(
          (a: number, b: any) => Number(a) + Number(b?.batchSize || 0),
          0
        );
      }
      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
    } else if (columnKey === 'manufacturingDate') {
      copyOfGridData[rowIndex]['manufacturingDate'] =
        props?.module === MODULE_TYPE.SELL
          ? rowData.serialBatchNumber.manufacturingDate
          : new Date(rowData?.manufacturingDate);
    } else if (columnKey === 'expiryDate') {
      copyOfGridData[rowIndex]['expiryDate'] =
        props?.module === MODULE_TYPE.SELL
          ? rowData.serialBatchNumber.expiryDate
          : new Date(rowData?.expiryDate);
    } else if (columnKey === 'row') {
      uniqueRows = availableRowData ?? [];
      if (props?.module === MODULE_TYPE.BUY) {
        copyOfGridData[rowIndex]['row'] = rowData?.row;
        copyOfGridData[rowIndex]['rack'] = null;
        copyOfGridData[rowIndex]['bin'] = null;
        uniqueRacks = !Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
          ? rrbWarehouseSelected?.warehouseRackInfos?.filter((rack: any) => {
              return rack.rowCode === rowData?.row?.code;
            })
          : [];
        uniqueBins = !Utility.isEmpty(rrbWarehouseSelected?.warehouseBinInfos)
          ? rrbWarehouseSelected?.warehouseBinInfos?.filter((bin: any) => {
              return bin.rowCode === rowData?.row?.code;
            })
          : [];
        if (
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(props.taggedWarehouse) &&
          !Utility.isEmpty(rrbWarehouseSelected)
        ) {
          if (rrbWarehouseSelected.code === props?.targetWarehouse) {
            uniqueRows =
              rrbWarehouseSelected?.warehouseRowsInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rowCode
              ) ?? [];
            uniqueRacks =
              rrbWarehouseSelected?.warehouseRackInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rackCode
              ) ?? [];
            uniqueBins =
              rrbWarehouseSelected?.warehouseBinInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.binCode
              ) ?? [];
          }
        }
      } else {
        copyOfGridData[rowIndex]['row'] = rowData?.row;
        copyOfGridData[rowIndex]['rack'] = null;
        copyOfGridData[rowIndex]['bin'] = null;
        let warehouseData;
        warehouseData = productWarehouse;
        let inventoryWarehouse = warehouseData?.find(
          (warehouse: any) => warehouse.code === rowData.warehouseCode.code
        );
        const rrbDtoForProduct =
          inventoryWarehouse?.advancedTrackingMeta &&
          Array.isArray(inventoryWarehouse?.advancedTrackingMeta)
            ? [...inventoryWarehouse?.advancedTrackingMeta]
            : [];
        //racks
        uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
          rrbDtoForProduct ?? [],
          rowData?.row?.rowCode,
          rowData?.bin?.binCode
        );
        //bins
        uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          rowData?.row?.rowCode,
          rowData?.rack?.rackCode
        );
      }
    } else if (columnKey === 'rack') {
      uniqueRows = availableRowData ?? [];
      uniqueRacks = availableRackData ?? [];

      if (props?.module === MODULE_TYPE.BUY) {
        copyOfGridData[rowIndex]['rack'] =
          props?.module === MODULE_TYPE.SELL ? rowData?.rack : rowData?.rack;
        copyOfGridData[rowIndex]['bin'] = null;
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseBinInfos)) {
          uniqueBins =
            rrbWarehouseSelected?.warehouseBinInfos?.filter((binChip: any) => {
              return binChip.rackCode === rowData?.rack?.code;
            }) || [];
        }
        if (
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(props.taggedWarehouse) &&
          !Utility.isEmpty(rrbWarehouseSelected)
        ) {
          if (rrbWarehouseSelected.code === props?.targetWarehouse) {
            uniqueBins =
              rrbWarehouseSelected?.warehouseBinInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.binCode
              ) ?? [];
          }
        }
      } else {
        copyOfGridData[rowIndex]['rack'] = rowData?.rack;
        copyOfGridData[rowIndex]['bin'] = null;
        let warehouseData;
        warehouseData = productWarehouse;
        let inventoryWarehouse = warehouseData?.find(
          (warehouse: any) => warehouse.code === rowData.warehouseCode.code
        );
        const rrbDtoForProduct =
          inventoryWarehouse?.advancedTrackingMeta &&
          Array.isArray(inventoryWarehouse?.advancedTrackingMeta)
            ? [...inventoryWarehouse?.advancedTrackingMeta]
            : [];
        //bins
        uniqueBins =
          WarehouseManagementHelper.getUniqueBins(
            rrbDtoForProduct ?? [],
            rowData?.row?.rowCode,
            rowData?.rack?.rackCode
          ) || [];
      }
    } else if (columnKey === 'bin') {
      uniqueRows = availableRowData ?? [];
      uniqueRacks = availableRackData ?? [];
      uniqueBins = availableBinData ?? [];
      copyOfGridData[rowIndex]['bin'] = rowData?.bin;
    }

    if (props?.isRRBTaggingEnabled) {
      uniqueBins =
        uniqueBins?.filter(
          (ele: any) =>
            ele?.taggedQuantity <
            tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
        ) ?? [];
    }

    setAvailableRowData(uniqueRows);
    setAvailableRackData(uniqueRacks);
    setAvailableBinData(uniqueBins);

    copyOfGridData[rowIndex]['invalidFields'] = getInvalidFields({
      allRows: copyOfGridData,
      index: rowIndex,
      rows: uniqueRows,
      racks: uniqueRacks,
      bins: uniqueBins
    });
    setGridData(copyOfGridData);
  };

  const addNewItem = () => {
    let rows = [...gridData];
    const nextYearDate = new Date();
    nextYearDate.setDate(nextYearDate.getDay() + 365);
    let newRow = {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: new Date(),
      expiryDate: nextYearDate,
      batchSize: 0,
      batchSizeFulfilled: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    };

    rows.push(newRow);
    let totalItem = 0;
    if (props?.module === MODULE_TYPE.SELL) {
      totalItem = rows.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );

      setTotalAllocatedItem(
        Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
      );
    }
    setGridData(rows);
    setAvailableRowData([]);
    setAvailableRackData([]);
    setAvailableBinData([]);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...gridData];
    rows.splice(rowIndex, 1);
    let totalItem = 0;
    if (props?.module === MODULE_TYPE.SELL) {
      totalItem = rows.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );
    } else {
      totalItem = rows.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSize),
        0
      );
    }
    setTotalAllocatedItem(
      Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION)
    );
    setGridData(rows);
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        needShadow={true}
        buttons={
          props.isMrpFlow &&
          props?.module === MODULE_TYPE.SELL &&
          !props?.showOnlyQAWarehouse
            ? [
                {
                  title: 'Auto Allocate',
                  className: 'text-blue underline',
                  onClick: () => {
                    allocateAutomatically();
                  }
                }
              ]
            : []
        }
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
        currentPage={1}
        totalPageCount={1}
        title={''}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        columns={[...(gridColumnConfig ?? [])]}
        rows={getMappedRows(gridData)}
      />
    );
  };

  const onRowClick = ({ rowData, columnData }: any) => {
    const onBatchColumnClicked = (
      item: any,
      rrbDtoForProduct: any,
      rrbWarehouseSelected: any
    ) => {
      let warehouseData;
      let result;
      let batchData: any;
      if (props.moduleName === MODULE_TYPE.SELL) {
        warehouseData = productWarehouse;
        let inventoryWarehouse = warehouseData.find(
          (warehouse: any) => warehouse.code === item?.warehouseCode?.code
        );
        result = inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
          return { ...item, warehouseName: inventoryWarehouse?.name };
        });
        batchData = result?.filter(
          (serial: any) =>
            serial.batchSizeFulfilled < serial.batchSize &&
            serial.reservedQuantity < serial.batchSize
        );

        //for RRB

        const uniqueRows = WarehouseManagementHelper.getUniqueRows(
          rrbDtoForProduct ?? [],
          item?.rack?.rackCode,
          item?.bin?.binCode
        );
        //racks
        const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
          rrbDtoForProduct ?? [],
          item?.row?.rowCode,
          item?.bin?.binCode
        );
        //bins
        const uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          item?.row?.rowCode,
          item?.rack?.rackCode
        );
        setAvailableRowData(uniqueRows);
        setAvailableRackData(uniqueRacks);
        setAvailableBinData(uniqueBins);
      } else {
        warehouseData = advancedTrackingProductsData;
        result = warehouseData.filter(
          (data: any) => data.warehouseCode === item?.warehouseCode?.code
        );

        batchData = result?.filter(
          (serial: any) =>
            serial.batchSizeFulfilled < serial.batchSize &&
            serial.reservedQuantity < serial.batchSize
        );

        // for RRB
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRowInfos)) {
          setAvailableRowData(rrbWarehouseSelected?.warehouseRowInfos ?? []);
        } else {
          setAvailableRowData([]);
        }
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)) {
          setAvailableRackData(rrbWarehouseSelected?.warehouseRackInfos ?? []);
        } else {
          setAvailableRackData([]);
        }
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseBinInfos)) {
          setAvailableBinData(rrbWarehouseSelected?.warehouseBinInfos ?? []);
        } else {
          setAvailableBinData([]);
        }
      }

      if (
        props?.moduleName === MODULE_TYPE.SELL &&
        props?.isMrpFlow &&
        props?.filterBatchData
      ) {
        let filteredAllottedData: any[] = filterWarehousesForEdit(
          item,
          batchData
        );

        setAvailableBatchData(filteredAllottedData);
      } else {
        setAvailableBatchData(batchData);
      }
    };
    const onRowColumnClicked = (item: any, rrbWarehouseSelected: any) => {
      if (
        props?.moduleName === MODULE_TYPE.BUY ||
        props?.module === MODULE_TYPE.BUY
      ) {
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRowInfos)) {
          setAvailableRowData(rrbWarehouseSelected?.warehouseRowInfos ?? []);
        } else {
          setAvailableRowData([]);
        }
        let racks = !Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
          ? rrbWarehouseSelected?.warehouseRackInfos?.filter(
              (rackChip: any) => {
                return rackChip.rowCode === item?.row?.code;
              }
            )
          : [];
        let bins = Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
          ? rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
              return rackChip.rowCode === item?.row?.code;
            })
          : rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
              return rackChip.rackCode === item?.rack?.code;
            });
        if (
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(props.taggedWarehouse) &&
          !Utility.isEmpty(rrbWarehouseSelected)
        ) {
          if (rrbWarehouseSelected.code === props?.targetWarehouse) {
            let rows =
              rrbWarehouseSelected?.warehouseRowInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rowCode
              ) ?? [];
            setAvailableRowData(rows);
            racks =
              rrbWarehouseSelected?.warehouseRackInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rackCode
              ) ?? [];
            bins =
              rrbWarehouseSelected?.warehouseBinInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.binCode
              ) ?? [];
          }
        }
        if (props?.isRRBTaggingEnabled) {
          bins =
            bins?.filter(
              (ele: any) =>
                ele?.taggedQuantity <
                tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
            ) ?? [];
        }

        setAvailableRackData(racks ?? []);
        setAvailableBinData(bins ?? []);
      } else {
        let inventoryWarehouse = productWarehouse?.find(
          (warehouse: any) => warehouse.code === item?.warehouseCode?.code
        );
        let rrbDtoForProduct = [];
        if (inventoryWarehouse?.advancedTrackingMeta) {
          rrbDtoForProduct = deepClone(
            inventoryWarehouse?.advancedTrackingMeta
          );
        }
        //racks
        const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
          rrbDtoForProduct ?? [],
          item?.row?.rowCode,
          item?.bin?.binCode
        );
        //bins
        const uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          item?.row?.rowCode,
          item?.rack?.rackCode
        );
        setAvailableRackData(uniqueRacks);
        setAvailableBinData(uniqueBins);
      }
    };
    const onRackColumnClicked = (item: any, rrbWarehouseSelected: any) => {
      if (
        props?.moduleName === MODULE_TYPE.BUY ||
        props?.module === MODULE_TYPE.BUY
      ) {
        if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRowInfos)) {
          setAvailableRowData(rrbWarehouseSelected?.warehouseRowInfos ?? []);
        } else {
          setAvailableRowData([]);
        }
        let racks = !Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
          ? rrbWarehouseSelected?.warehouseRackInfos?.filter(
              (rackChip: any) => {
                return rackChip.rowCode === item?.row?.code;
              }
            )
          : [];
        let bins = Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
          ? rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
              return rackChip.rowCode === item?.row?.code;
            })
          : rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
              return rackChip.rackCode === item?.rack?.code;
            });
        if (
          Utility.isRRBTaggingEnabled() &&
          Utility.isNotEmpty(props.taggedWarehouse) &&
          !Utility.isEmpty(rrbWarehouseSelected)
        ) {
          if (rrbWarehouseSelected.code === props?.targetWarehouse) {
            let rows =
              rrbWarehouseSelected?.warehouseRowInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rowCode
              ) ?? [];
            setAvailableRowData(rows);
            racks =
              rrbWarehouseSelected?.warehouseRackInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.rackCode
              ) ?? [];
            bins =
              rrbWarehouseSelected?.warehouseBinInfos?.filter(
                (ele: any) => ele.code === props.taggedWarehouse?.binCode
              ) ?? [];
          }
        }
        if (props?.isRRBTaggingEnabled) {
          bins =
            bins?.filter(
              (ele: any) =>
                ele?.taggedQuantity <
                tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
            ) ?? [];
        }
        setAvailableRackData(racks ?? []);
        setAvailableBinData(bins ?? []);
      } else {
        let warehouseData;
        warehouseData = productWarehouse;
        let inventoryWarehouse = warehouseData?.find(
          (warehouse: any) => warehouse.code === item?.warehouseCode?.code
        );
        let rrbDtoForProduct = [];
        if (inventoryWarehouse?.advancedTrackingMeta) {
          rrbDtoForProduct = deepClone(
            inventoryWarehouse?.advancedTrackingMeta
          );
        }
        //bins
        const uniqueBins = WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          item?.row?.rowCode,
          item?.rack?.rackCode
        );
        setAvailableBinData(uniqueBins);
      }
    };
    const onBinColumnClicked = (item: any, rrbWarehouseSelected: any) => {
      if (!Utility.isEmpty(rrbWarehouseSelected?.warehouseRowInfos)) {
        setAvailableRowData(rrbWarehouseSelected?.warehouseRowInfos ?? []);
      } else {
        setAvailableRowData([]);
      }
      let racks = !Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
        ? rrbWarehouseSelected?.warehouseRackInfos?.filter((rackChip: any) => {
            return rackChip.rowCode === item?.row?.code;
          })
        : [];
      let bins = Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos)
        ? rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
            return rackChip.rowCode === item?.row?.code;
          })
        : rrbWarehouseSelected?.warehouseBinInfos?.filter((rackChip: any) => {
            return rackChip.rackCode === item?.rack?.code;
          });

      if (
        Utility.isEmpty(rrbWarehouseSelected?.warehouseRackInfos) &&
        Utility.isEmpty(rrbWarehouseSelected?.warehouseRowInfos)
      ) {
        bins = rrbWarehouseSelected?.warehouseBinInfos;
      }
      if (
        Utility.isRRBTaggingEnabled() &&
        Utility.isNotEmpty(props.taggedWarehouse) &&
        !Utility.isEmpty(rrbWarehouseSelected)
      ) {
        if (rrbWarehouseSelected.code === props?.targetWarehouse) {
          let rows =
            rrbWarehouseSelected?.warehouseRowInfos?.filter(
              (ele: any) => ele.code === props.taggedWarehouse?.rowCode
            ) ?? [];
          setAvailableRowData(rows);
          racks =
            rrbWarehouseSelected?.warehouseRackInfos?.filter(
              (ele: any) => ele.code === props.taggedWarehouse?.rackCode
            ) ?? [];
          bins =
            rrbWarehouseSelected?.warehouseBinInfos?.filter(
              (ele: any) => ele.code === props.taggedWarehouse?.binCode
            ) ?? [];
        }
      }
      if (props?.isRRBTaggingEnabled) {
        bins =
          bins?.filter(
            (ele: any) =>
              ele?.taggedQuantity <
              tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
          ) ?? [];
      }
      setAvailableRackData(racks ?? []);
      setAvailableBinData(bins ?? []);
    };

    if (rowData && columnData) {
      const item = rowData;
      const inventoryWarehouse = productWarehouse.find(
        (warehouse: any) => warehouse.code === item?.warehouseCode?.code
      );
      let rrbDtoForProduct = [];
      if (inventoryWarehouse?.advancedTrackingMeta) {
        rrbDtoForProduct = deepClone(inventoryWarehouse?.advancedTrackingMeta);
      }
      const rrbWarehouseSelected = productInventoryWarehouse?.find(
        (warehouse: any) => warehouse.code === item?.warehouseCode?.code
      );

      switch (columnData?.key) {
        case 'serialBatchNumber':
          onBatchColumnClicked(item, rrbDtoForProduct, rrbWarehouseSelected);
          break;
        case 'row':
          onRowColumnClicked(item, rrbWarehouseSelected);
          break;
        case 'rack':
          onRackColumnClicked(item, rrbWarehouseSelected);
          break;
        case 'bin':
          onBinColumnClicked(item, rrbWarehouseSelected);
          break;
        default:
          break;
      }
    }
  };

  const getFilteredWarehousesForBuy = () => {
    if (props?.targetWarehouse) {
      let onlyTargetWh = productInventoryWarehouse?.filter((wh: any) => {
        return wh.code === props?.targetWarehouse;
      });
      if (
        Utility.isRRBTaggingEnabled() &&
        Utility.isNotEmpty(props.taggedWarehouse) &&
        !Utility.isEmpty(onlyTargetWh)
      ) {
        let uniqueRows =
          onlyTargetWh?.[0]?.warehouseRowInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rowCode
          ) ?? [];
        let uniqueRacks =
          onlyTargetWh?.[0]?.warehouseRackInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rackCode
          ) ?? [];
        let uniqueBins =
          onlyTargetWh?.[0]?.warehouseBinInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.binCode
          ) ?? [];
        onlyTargetWh = [
          {
            ...onlyTargetWh[0],
            warehouseRowInfos: uniqueRows,
            warehouseRackInfos: uniqueRacks,
            warehouseBinInfos: uniqueBins
          }
        ];
      }

      return onlyTargetWh;
    } else {
      return productInventoryWarehouse;
    }
  };

  const allocateAutomatically = () => {
    let productAvailableQuantity = productWarehouse.reduce(
      (prev: any[], current: any) => {
        let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
          return {
            ...item,
            warehouseName: current.name
          };
        });
        return [...prev, ...advTracking];
      },
      []
    );
    let allocated = 0;
    let batchTrackingData: any = [];
    productAvailableQuantity?.every((element: any) => {
      let availableQtyToAssign = props.itemDetails.documentUOMSchemaDefinition
        ? Utility.getUomWarehouseQuantity(
            parseFloat(element.batchSize),
            props.itemDetails.documentUOMSchemaDefinition
          )
        : parseFloat(element.batchSize) - parseFloat(element.reservedQuantity);
      let lineItem = {
        acquiredCost: element?.acquiredCost || 0,
        batchSize: element?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(element?.acquiredCost) / Number(element?.batchSize)
        ),
        qtyToFulfil: 0,
        serialBatchNumber: element?.serialBatchNumber,
        warehouseCode: element?.warehouseCode,
        warehouseName: element?.warehouseName
      };
      if (allocated < requiredQuantity) {
        if (availableQtyToAssign < requiredQuantity - allocated) {
          lineItem.qtyToFulfil = availableQtyToAssign;
          allocated = allocated + lineItem.qtyToFulfil;
        } else {
          lineItem.qtyToFulfil = requiredQuantity - allocated;
          allocated = allocated + lineItem.qtyToFulfil;
          batchTrackingData.push(lineItem);
          return false; //breaks the loop
        }
      }
      batchTrackingData.push(lineItem);
      return true;
    });
    props.onBatchSave(batchTrackingData, allocated);
  };

  const onSave = () => {
    let hasError: any;
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-white border-m',
        onClick: () => {}
      },
      {
        title: 'Continue',
        className: 'bg-button border-m text-white',
        onClick: () => {
          if (props.onBatchSave) {
            onBatchSave();
          }
        }
      }
    ];
    gridData.forEach((item: any, i: any) => {
      if (item && item.invalidFields && item.invalidFields.length > 0) {
        hasError = item.invalidFields;
      }
    });
    if (
      hasError &&
      hasError.length > 0 &&
      hasError.includes('batchSizeFulfilled')
    ) {
      showToast(
        'Quantity used cannot be more than the available quantity',
        TOAST_TYPE.FAILURE
      );
      return false;
    } else if (
      hasError &&
      hasError.length > 0 &&
      hasError.includes('serialBatchNumber')
    ) {
      showAlert('', 'Duplicate batch number is not allowed', buttons);
      return false;
    } else if (totalAllocatedItem > pendingQuantity) {
      showAlert(
        'Limit Exceeded',
        `Quantity allocated cannot be more than the required quantity ( ${requiredQuantity} )`,
        buttons
      );

      return false;
    }

    let showExpiryAlert = false;
    gridData.forEach((data: any) => {
      if (
        data.expiryDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
      ) {
        showExpiryAlert = true;
      }
    });
    if (showExpiryAlert) {
      showAlert(
        'Batch expired',
        'Please note, the Batch selected belongs to a date which is beyond the Expiry Date. Do you wish to proceed further with allocation',
        buttons
      );
      return;
    }

    if (props?.isRRBTaggingEnabled && gridData?.length > 1) {
      let isAllBinSame = gridData.every(
        (ele: any) => ele.bin?.code === gridData[0]?.bin?.code
      );
      if (!isAllBinSame) {
        showAlert(
          'Error',
          'Row Rack Bin should be same for all batches when bin tagging setting is on.'
        );
        return;
      }
    }

    if (props?.isRRBTaggingEnabled && Utility.isNotEmpty(gridData?.[0]?.bin)) {
      if (
        gridData?.[0]?.bin?.taggedQuantity >=
        tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
      ) {
        showAlert(
          'Error',
          `${gridData?.[0]?.bin?.name} has threshold limit(${tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD}) is reached.`
        );
        return;
      }
    }
    if (
      Utility.isEmpty(rawMaterialToConsumedData) &&
      isBOMRawProductSerialBatch &&
      props.docType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      showAlert('Warning', t('JOB_WORK_OUT.RAW_MATERIAL_ALLOCATE_ERROR'));
      return;
    }

    if (props.onBatchSave) {
      onBatchSave();
    }
  };

  const onBatchSave = () => {
    const batchTrackingData = gridData.map((item: any, i: any) => {
      if (props?.module === MODULE_TYPE.SELL) {
        return {
          acquiredCost: item?.serialBatchNumber?.acquiredCost || 0,
          qtyToFulfil: props.itemDetails.documentUOMSchemaDefinition
            ? Utility.getUomWarehouseQuantity(
                parseFloat(item.batchSizeFulfilled),
                props.itemDetails.documentUOMSchemaDefinition
              )
            : parseFloat(item.batchSizeFulfilled),
          batchSize: item?.serialBatchNumber?.batchSize,
          costPerUnit: Utility.roundOffToTenantDecimalScale(
            Number(item?.serialBatchNumber?.acquiredCost) /
              Number(item?.serialBatchNumber?.batchSize)
          ),
          serialBatchNumber: item.serialBatchNumber.serialBatchNumber,
          warehouseCode: item?.warehouseCode?.code,
          warehouseName: item?.warehouseCode?.name,
          destinationWarehouseCode: item?.destinationWarehouseCode,
          destinationWarehouseName: item?.destinationWarehouseName,
          manufacturingDate: DateFormatService.getDateStrFromDate(
            item.manufacturingDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          expiryDate: DateFormatService.getDateStrFromDate(
            item.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          row: item?.row,
          rowCode: item?.row?.rowCode,
          rack: item?.rack,
          rackCode: item?.rack?.rackCode,
          bin: item?.bin,
          binCode: item?.bin?.binCode
        };
      } else {
        let object = {
          acquiredCost: props.itemDetails.documentUOMSchemaDefinition
            ? (itemDetails?.unitPrice || 0) *
              (props.itemDetails.documentUOMSchemaDefinition
                .sourceConversionFactor /
                props.itemDetails.documentUOMSchemaDefinition
                  .sinkConversionFactor) *
              parseFloat(item.batchSize)
            : (itemDetails?.unitPrice || 0) * parseFloat(item.batchSize),

          batchSize: parseFloat(item.batchSize || item?.quantity),
          committingValue: 0,
          manufacturingDate: DateFormatService.getDateStrFromDate(
            item.manufacturingDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          expiryDate: DateFormatService.getDateStrFromDate(
            item.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          serialBatchNumber: item.serialBatchNumber
            ? item.serialBatchNumber
            : '',
          warehouseCode: item.warehouseCode.code,
          warehouseName: item.warehouseCode.name,
          row: item?.row,
          rowCode: item?.row?.rowCode,
          rack: item?.rack,
          rackCode: item?.rack?.rackCode,
          bin: item?.bin,
          binCode: item?.bin?.binCode
        };

        return { ...object, advancedTrackingData: [{ ...object }] };
      }
    });

    props.onBatchSave(
      batchTrackingData,
      totalAllocatedItem,
      rawMaterialToConsumedData
    );
  };

  const checkToleranceWithAllocatedQuantity = (
    allocatedItemQuantity: any,
    requiredQuantity: any
  ) => {
    return (
      !Utility.isPurchaseToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) && allocatedItemQuantity > requiredQuantity
    );
  };

  const getAllocatedQty = () => {
    const sumofUsedQuantities =
      gridData?.reduce((acc: any, item: any) => {
        return Number(item?.batchSize ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0;
    const allocatedQty = Utility.roundingOff(
      sumofUsedQuantities ?? 0,
      QTY_ROUNDOFF_PRECISION
    );
    return allocatedQty;
  };

  const modifyRawMaterialData = () => {
    const objWithInvalidField = gridData?.find((item: any) => {
      return !Utility.isEmpty(item?.invalidFields);
    });

    const objWithInvalidFieldWarehouse = gridData?.find((item: any) => {
      return Utility.isEmpty(item?.warehouseCode);
    });
    if (objWithInvalidField || objWithInvalidFieldWarehouse) {
      return;
    }
    let modifyData = {
      ...props.itemDetails,
      details: props.itemDetails
    };
    if (modifyData?.product?.bomProductsConfiguration) {
      modifyData.product.bomProductsConfiguration.forEach((bomProduct: any) => {
        bomProduct['requiredQuantity'] =
          bomProduct.quantity * getAllocatedQty();
      });
    }
    setRawMaterialData(modifyData);
  };

  const getHeader = () => {
    const allRowsValid = gridData?.every(
      (row: any) => (row?.invalidFields?.length || 0) === 0
    );
    const disableSave =
      !allRowsValid ||
      totalAllocatedItem <= 0 ||
      props?.disableBtn ||
      checkToleranceWithAllocatedQuantity(totalAllocatedItem, requiredQuantity);

    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t-md">
        <div className="row pop-header-drag-handle">
          <DKLabel
            // text="Allocate Batch-Tracked Products"
            text={itemDetails?.product?.name}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />

          {props.docType &&
            props.docType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
            isBOMRawProductSerialBatch && (
              <DKButton
                title={t('JOB_WORK_OUT.ALLOCATE')}
                className={`${
                  getAllocatedQty() !== 0
                    ? 'bg-button text-white'
                    : 'bg-gray1 cursor-not-allowed '
                } mr-r border-m`}
                onClick={() => {
                  if (getAllocatedQty() !== 0) {
                    modifyRawMaterialData();
                  }
                }}
              />
            )}

          <DKButton
            title={'Save'}
            className={
              !disableSave ? 'bg-button text-white' : 'bg-gray-300 text-white'
            }
            disabled={disableSave}
            onClick={() => {
              onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    if (props?.module === MODULE_TYPE.SELL) {
      return (
        <div className="row justify-content-between">
          <div className="column width-auto " style={{ maxWidth: 500 }}>
            <DKLabel
              text={`${props?.mrpTitle ? 'Material' : 'Product'} Name`}
              className="fs-r pl-2 font-medium "
            />
            <DKLabel text={itemDetails?.product?.name} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text={`${props?.mrpTitle ? 'Material' : 'Product'} Code`}
              className="fs-r pl-2 font-medium "
            />
            <DKLabel text={itemDetails?.productCode} className="fs-r pl-2" />
          </div>
          <div className="column width-auto">
            <DKLabel
              text="Required Quantity"
              className="fs-r pl-2 font-medium "
            />
            <div className="row justify-content-end align-items-end gap-1 ">
              <DKLabel
                text={requiredQuantity >= 0 ? requiredQuantity : '-'}
                className=""
              />
              <DKLabel
                style={{
                  height: 15
                }}
                text={
                  props.isMrpFlow
                    ? Utility.getUOMForStockUOMId(
                        itemDetails?.product?.stockUom
                      )?.name
                    : itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-s fw-m text-gray "
              />
            </div>
          </div>
          <div className="column width-auto">
            <DKLabel
              text="Quantity Allocated"
              className="fs-r pl-2 font-medium "
            />
            <div className="row justify-content-end align-items-end gap-1 ">
              <DKLabel
                text={totalAllocatedItem >= 0 ? totalAllocatedItem : '-'}
                className=""
              />
              <DKLabel
                style={{
                  height: 15
                }}
                text={
                  props.isMrpFlow
                    ? Utility.getUOMForStockUOMId(
                        itemDetails?.product?.stockUom
                      )?.name
                    : itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-s fw-m text-gray "
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-between align-items-start">
          <div className="column w-80">
            <DKLabel text="Product Name" className="fs-r font-medium " />
            <DKLabel text={itemDetails?.product?.name} className="fs-r " />
          </div>
          <div className="column 1w-80">
            <DKLabel text="Inventory Details" className="fs-r font-medium " />
            <div className="flex">
              <DKLabel text={'Tracking Method:'} className="fs-r font-medium" />
              <DKLabel text={'Batch Number'} className="fs-r " />
            </div>
            <div className="flex">
              <DKLabel text={'Stock Available:'} className="fs-r font-medium" />
              <DKLabel
                text={
                  props.itemDetails.documentUOMSchemaDefinition
                    ? Utility.getUomQuantity(
                        itemDetails?.product?.availableQuantity,
                        props.itemDetails.documentUOMSchemaDefinition
                      )
                    : itemDetails?.product?.availableQuantity
                }
                className="fs-r"
              />
              <DKLabel
                text={
                  itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-r pl-2"
              />
            </div>
            {props.itemDetails.documentUOMSchemaDefinition && (
              <div className="flex">
                <DKLabel
                  text={'Stock Available In Base UOM:'}
                  className="fs-r  font-medium"
                />
                <DKLabel
                  text={itemDetails?.product?.availableQuantity}
                  className="fs-r pl-4"
                />
              </div>
            )}
            <div className="flex">
              <DKLabel text={'Batch Assigned:'} className="fs-r font-medium" />
              <DKLabel
                text={totalAllocatedItem}
                className="fs-r pl-4 text-right"
              />
            </div>
          </div>
          <div className="column 1width-auto">
            <DKLabel
              text="Goods Receipt Details"
              className="fs-r pl-2 font-medium "
            />
            <div className="flex">
              <DKLabel
                text={'Required Quantity:'}
                className="fs-r pl-2 font-medium"
              />
              <DKLabel
                text={pendingQuantity >= 0 ? pendingQuantity : '-'}
                className="fs-r pl-3"
              />
              <DKLabel
                text={
                  itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-r pl-2"
              />
            </div>
            {props.itemDetails.documentUOMSchemaDefinition && (
              <div className="flex">
                <DKLabel
                  text={'Required Quantity In Base UOM:'}
                  className="fs-r pl-2 font-medium"
                />
                <DKLabel
                  text={props.itemDetails?.quantityRequired}
                  className="fs-r pl-4"
                />
              </div>
            )}
            <div className="flex">
              <DKLabel text={'Receiving:'} className="fs-r pl-2 font-medium" />
              <DKLabel text={requiredQuantity} className="fs-r pl-3" />
              <DKLabel
                text={
                  itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-r pl-2"
              />
            </div>
            <div className="flex">
              <DKLabel text={'To Assign:'} className="fs-r pl-2 font-medium" />
              <DKLabel
                text={Utility.roundingOff(
                  requiredQuantity - totalAllocatedItem,
                  10
                )}
                className="fs-r pl-3"
                style={{
                  color: requiredQuantity > pendingQuantity ? 'red' : 'black'
                }}
              />
              <DKLabel
                text={
                  itemDetails?.documentUOMSchemaDefinition
                    ? itemDetails?.documentUOMSchemaDefinition?.name
                    : ''
                }
                className="fs-r pl-2"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const rrbColumnRenderer = ({ rowData }: any, value: string, column: any) => {
    if (rowData?.invalidFields?.includes(column?.key)) {
      let errorMessage = `Row with selected ${column?.name} already exist`;
      if (!value) {
        errorMessage = `${column?.name} is required, can not be empty`;
      }
      return (
        <div className="row justify-content-between">
          <DKLabel text={`${value || ''}`} />
          <DKTooltipWrapper content={errorMessage} tooltipClassName="">
            <div className="row">
              <DKIcon
                src={ic_warning_red}
                className="ic-xs ml-s cursor-hand"
                onClick={() => {}}
              />
            </div>
          </DKTooltipWrapper>
        </div>
      );
    }
    return value || '';
  };

  const batchSizeRenderer = ({ rowData }: any) => {
    const isBatchSizeNumber =
      rowData?.invalidFields?.includes('batchSizeNumber');
    if (
      (rowData?.invalidFields?.includes('batchSize') || isBatchSizeNumber) &&
      rowData.serialBatchNumber !== ''
    ) {
      const totalAssignedQuantity = gridData.reduce(
        (a: number, b: any) => Number(a) + Number(b?.batchSize),
        0
      );

      let errorMessage = `Quantity should be less than or equal to remaining quantity (${
        pendingQuantity - (totalAssignedQuantity - Number(rowData.batchSize))
      })`;
      if (rowData?.invalidFields?.includes('isBillLinked')) {
        errorMessage = `Quantity should be less than or equal to remaining quantity (${
          pendingQuantity - (totalAssignedQuantity - Number(rowData.batchSize))
        }) as the Bill is already generated for this document.`;
      }
      if (isBatchSizeNumber) {
        errorMessage = t('JOB_WORK_OUT.QTY_DECIMAL');
      }
      return (
        <div className="row justify-content-between">
          <DKTooltipWrapper content={errorMessage} tooltipClassName="">
            <div className="row">
              <DKIcon
                src={ic_warning_red}
                className="ic-xs  ml-s cursor-hand"
                onClick={() => {}}
              />
            </div>
          </DKTooltipWrapper>

          <DKLabel
            text={`${Utility.roundingOff(
              Number(rowData?.batchSize || 0),
              QTY_ROUNDOFF_PRECISION
            )}`}
          />
        </div>
      );
    } else {
      return (
        <div className="row justify-content-between">
          <div></div>
          <DKLabel
            text={`${Utility.roundingOff(
              Number(rowData?.batchSize || rowData?.quantity || 0),
              QTY_ROUNDOFF_PRECISION
            )}`}
          />
        </div>
      );
    }
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: '90%',
            width: Utility.isRRBEnabled() ? 1320 : 1010,
            maxHeight: '95%',
            minHeight: 300,
            padding: 0,
            overflow: 'visible'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-5 1overflow-y-auto show-scroll-bar">
            {getHeaderSection()}
            <div className="column parent-size1 mt-2 parent-width batch-tracking-grid">
              {getBatchTrackingGrid()}
            </div>
            {!props?.disableBtn && (
              <div className="">
                <DKButton
                  title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
                  onClick={() => addNewItem()}
                  className={` fw-m p-0 text-blue`}
                  style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {rawMaterialData && (
        <ReceiveJWOProduct
          records={[]}
          selectedRecord={null}
          detail={rawMaterialData}
          document={DOC_TYPE.JOB_WORK_OUT_ORDER}
          passingInteraction={() => {}}
          onSave={(data: any) => {
            const rawMaterialData: any = [];
            data.forEach((d: any) => {
              rawMaterialData.push(...d.serialData);
            });

            setRawMaterialToConsumedData(rawMaterialData);
            setRawMaterialData(null);
          }}
          onCancel={() => {
            setRawMaterialData(null);
          }}
          allowFullScreen
        />
      )}
    </DynamicPopupWrapper>
  );
};

export default BatchTrackingReceive;
