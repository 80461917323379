import ApiConstants from '../../Constants/ApiConstants';
import Utility from '../../Utility/Utility';
import http from '../Interceptor';

export interface OperationsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: string;
  QueryParam?: string;
  fetchLinkedMachineDetails?: boolean;
}

const defaultConfig: OperationsAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Sort: 'createdOn',
  SortDir: 'DESC',
  Query: '',
  QueryParam: '',
  fetchLinkedMachineDetails: true
};

class OperationsService {
  static apiConfig: OperationsAPIConfig = defaultConfig;

  static operationsEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = 'mrp/operation/search';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;

    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static getOperations() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = ApiConstants.URL.MRP.OPERATIONS.GET_OPERATIONS;
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}&page=${this.apiConfig.Page}&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    if (this.apiConfig.Query) query += `&query=${this.apiConfig.Query}`;
    if (this.apiConfig.QueryParam) query += `${this.apiConfig.QueryParam}`;
    finalEndpoint = finalEndpoint + query;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteOperation(id: any) {
    return http
      .delete(`${ApiConstants.URL.MRP.OPERATIONS.DELETE_OPERATION}`, {
        data: id
      })
      .catch((err: any) => {
        console.error('Error deleting operation: ', err);
      });
  }

  static addOperation(payload: any) {
    return http
      .post(ApiConstants.URL.MRP.OPERATIONS.ADD_OPERATION, payload)
      .catch((err: any) => {
        console.error('Error while adding operation: ', err);
        return Promise.reject(err);
      });
  }

  static updateOperation(payload: any, id: any) {
    return http
      .put(ApiConstants.URL.MRP.OPERATIONS.EDIT_OPERATION(id), payload)
      .catch((err: any) => {
        console.error('Error while updating operation: ', err);
        return Promise.reject(err);
      });
  }

  static getOperationsById(ids: any[]) {
    let finalEndpoint =
      ApiConstants.URL.MRP.OPERATIONS.GET_OPERATION_BY_ID +
      '?fetchLinkedMachineDetails=true';
    return http
      .post(`${finalEndpoint}`, ids)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default OperationsService;
