import React, { Fragment, useEffect, useState } from 'react';
import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from 'deskera-ui-library';
import Utility, { deepClone } from '../../Utility/Utility';
import {
  FormState,
  FORM_FIELD_NAMES,
  initialFormState
} from '../../Constants/SourceDestinationWarehouseManagementRRB';
import { formState } from '../../Constants/ReturnWarehouseManagementRRB';
import { DOCUMENT_TYPE, DOC_TYPE } from '../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../Constants/Enum';
import ApiConstants from '../../Constants/ApiConstants';

const SourceDestinationWarehouseManagementRRB = (props: any) => {
  const [formState, setFormState] = useState<FormState>(
    deepClone(initialFormState)
  );

  useEffect(() => {
    return () => {
      setFormState(deepClone(initialFormState));
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(props)) {
      let newState = { ...formState };

      // Source Warehouse
      let sourceDetail = deepClone(props.sourceWarehouseData);
      let sd = [...props.sourceWarehouseData];
      sourceDetail.forEach((element: any) => {
        let currentDetail = sd.filter((res: any) => res.code === element.code);
        if (currentDetail && currentDetail?.length > 0) {
          element.warehouseBinInfos = currentDetail?.[0]?.warehouseBinInfos;
          element.warehouseRowInfos = currentDetail?.[0]?.warehouseRowInfos;
          element.warehouseRackInfos = currentDetail?.[0]?.warehouseRackInfos;
        }
      });
      sourceDetail.forEach((s: any) => {
        if (
          (s.warehouseBinInfos && s.warehouseBinInfos.length > 0) ||
          (s.warehouseRowInfos && s.warehouseRowInfos.length > 0) ||
          (s.warehouseRackInfos && s.warehouseRackInfos.length > 0)
        ) {
          let tmpWarehouseCode = s.code;
          if (props && props.warehouseProduceByIdWithDTO) {
            if (
              props.warehouseProduceByIdWithDTO &&
              props.warehouseProduceByIdWithDTO.length > 0
            ) {
              let currentWarehouse = props.warehouseProduceByIdWithDTO.filter(
                (res: any) => res.code === s.code
              );

              if (currentWarehouse && currentWarehouse.length > 0) {
                if (
                  currentWarehouse?.[0]
                    ?.rowRackBinProductAvailableQuantityDtos &&
                  currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos
                    .length > 0
                ) {
                  let listOfRows =
                    currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos.map(
                      (rrb: any) => {
                        return rrb.rowCode;
                      }
                    );
                  let listOfRack =
                    currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos.map(
                      (rrb: any) => {
                        return rrb.rackCode;
                      }
                    );
                  let listOfBin =
                    currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos.map(
                      (rrb: any) => {
                        return rrb.binCode;
                      }
                    );
                  let newBin: any[] = [];
                  let newRow: any[] = [];
                  let newRack: any[] = [];

                  s.warehouseBinInfos?.forEach((bin: any) => {
                    if (listOfBin.includes(bin.code)) {
                      newBin.push(bin);
                    }
                  });
                  s.warehouseRowInfos?.forEach((row: any) => {
                    if (listOfRows.includes(row.code)) {
                      newRow.push(row);
                    }
                  });
                  s.warehouseRackInfos?.forEach((rack: any) => {
                    if (listOfRack.includes(rack.code)) {
                      newRack.push(rack);
                    }
                  });

                  s.warehouseBinInfos = newBin;
                  s.warehouseRowInfos = newRow;
                  s.warehouseRackInfos = newRack;
                }
              }
            }
          } else if (
            props &&
            (props.type === ADVANCE_TRACKING.BATCH ||
              props.type === ADVANCE_TRACKING.SERIAL)
          ) {
            if (
              props &&
              props.sourceWarehouseData &&
              props.sourceWarehouseData.length > 0
            ) {
              let currentWarehouse = props.sourceWarehouseData.filter(
                (res: any) => res.code === s.code
              );
              if (currentWarehouse && currentWarehouse.length > 0) {
                if (
                  currentWarehouse[0] &&
                  currentWarehouse[0].advancedTrackingMeta &&
                  currentWarehouse[0].advancedTrackingMeta.length > 0
                ) {
                  let rowCodeList =
                    currentWarehouse[0].advancedTrackingMeta.map((res: any) => {
                      return res.rowCode;
                    });
                  let rackCodeList =
                    currentWarehouse[0].advancedTrackingMeta.map((res: any) => {
                      return res.rackCode;
                    });
                  let binCodeList =
                    currentWarehouse[0].advancedTrackingMeta.map((res: any) => {
                      return res.binCode;
                    });

                  let newRow: any[] = [];
                  let newRack: any[] = [];
                  let newBin: any[] = [];

                  s.warehouseRowInfos?.forEach((row: any) => {
                    if (rowCodeList.includes(row.code)) {
                      newRow.push(row);
                    }
                  });

                  s.warehouseRackInfos?.forEach((rack: any) => {
                    if (rackCodeList.includes(rack.code)) {
                      newRack.push(rack);
                    }
                  });

                  s.warehouseBinInfos?.forEach((bin: any) => {
                    if (binCodeList.includes(bin.code)) {
                      newBin.push(bin);
                    }
                  });
                  s.warehouseBinInfos = newBin;
                  s.warehouseRowInfos = newRow;
                  s.warehouseRackInfos = newRack;
                }
              }
            }
          }
        } else {
          if (props && props.warehouseProduceByIdWithDTO) {
            if (
              props.warehouseProduceByIdWithDTO &&
              props.warehouseProduceByIdWithDTO.length > 0
            ) {
              let currentWarehouse = props.warehouseProduceByIdWithDTO.filter(
                (res: any) => res.code === s.code
              );

              if (currentWarehouse && currentWarehouse.length > 0) {
                // currentWarehouse = currentWarehouse[0].filter((res: any) => res.availableQuantity > 0)
                if (
                  currentWarehouse?.[0]
                    ?.rowRackBinProductAvailableQuantityDtos &&
                  currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos
                    .length > 0
                ) {
                  let listOfRows: any[] = [];
                  let listOfRack: any[] = [];
                  let listOfBin: any[] = [];
                  let cleanUpList =
                    currentWarehouse?.[0]?.rowRackBinProductAvailableQuantityDtos.filter(
                      (res: any) => res.availableQuantity > 0
                    );
                  if (cleanUpList && cleanUpList.length > 0) {
                    listOfRows = cleanUpList.map((rrb: any) => {
                      return { code: rrb.rowCode, name: rrb.rowName };
                    });
                    listOfRack = cleanUpList.map((rrb: any) => {
                      return {
                        code: rrb.rackCode,
                        name: rrb.rackName,
                        rowCode: rrb.rowCode
                      };
                    });
                    listOfBin = cleanUpList.map((rrb: any) => {
                      return {
                        code: rrb.binCode,
                        name: rrb.binName,
                        rackCode: rrb.rackCode,
                        rowCode: rrb.rowCode
                      };
                    });
                  }

                  //cleaning up data because above logic adds null data. Need to refactor above code
                  listOfRows = listOfRows.filter(
                    (row: any) => row.code !== null && row.code !== undefined
                  );
                  listOfRack = listOfRack.filter(
                    (rack: any) => rack.code !== null && rack.code !== undefined
                  );
                  listOfBin = listOfBin.filter(
                    (bin: any) => bin.code !== null && bin.code !== undefined
                  );

                  let newBin: any[] = [];
                  let newRow: any[] = [];
                  let newRack: any[] = [];
                  s.warehouseRowInfos?.forEach((row: any) => {
                    if (listOfRows.includes(row.code)) {
                      newRow.push(row);
                    }
                  });
                  s.warehouseRackInfos?.forEach((rack: any) => {
                    if (listOfRack.includes(rack.code)) {
                      newRack.push(rack);
                    }
                  });
                  s.warehouseBinInfos?.forEach((bin: any) => {
                    if (listOfBin.includes(bin.code)) {
                      newBin.push(bin);
                    }
                  });

                  s.warehouseBinInfos = listOfBin;
                  s.warehouseRowInfos = listOfRows;
                  s.warehouseRackInfos = listOfRack;
                }
              }
            }
          }
        }
      });

      newState.warehouse.source.data = sourceDetail;
      newState.warehouse.source.nonFilteredData = props.sourceWarehouseData;

      // Target Warehouse
      newState.warehouse.target.data = props.targetWarehouseData;
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        let jwoWarehouse = props.targetWarehouseData?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(jwoWarehouse) &&
          Utility.isEmpty(newState?.warehouse?.target?.value)
        ) {
          newState.warehouse.target.value = jwoWarehouse;
        }
      }
      if (
        !Utility.isEmpty(props?.sourceWarehouse) &&
        !Utility.isEmpty(props?.destinationWarehouse) &&
        newState.lastChangeKey === ''
      ) {
        newState.warehouse.source.value = props?.sourceWarehouse;
        if (!Utility.isEmpty(props?.sourceWarehouse?.srcRowCode)) {
          let row = props?.sourceWarehouse?.warehouseRowInfos?.find(
            (ele: any) => props?.sourceWarehouse?.srcRowCode === ele.code
          );
          newState.RRB.source.row.value = row ?? null;
          newState.RRB.source.row.data =
            props?.sourceWarehouse?.warehouseRowInfos;
          newState.RRB.source.row.nonFilteredData =
            props?.sourceWarehouse?.warehouseRowInfos;
        }
        if (!Utility.isEmpty(props?.sourceWarehouse?.srcRackCode)) {
          let rack = props?.sourceWarehouse?.warehouseRackInfos?.find(
            (ele: any) => props?.sourceWarehouse?.srcRackCode === ele.code
          );
          newState.RRB.source.rack.value = rack ?? null;
          newState.RRB.source.rack.data =
            props?.sourceWarehouse?.filteredRackInfos;
          newState.RRB.source.rack.nonFilteredData =
            props?.sourceWarehouse?.warehouseRackInfos;
        }
        if (!Utility.isEmpty(props?.sourceWarehouse?.srcBinCode)) {
          let bin = props?.sourceWarehouse?.warehouseBinInfos?.find(
            (ele: any) => props?.sourceWarehouse?.srcBinCode === ele.code
          );
          newState.RRB.source.bin.value = bin ?? null;
          newState.RRB.source.bin.data =
            props?.sourceWarehouse?.filteredBinInfos;
          newState.RRB.source.bin.nonFilteredData =
            props?.sourceWarehouse?.warehouseBinInfos;
        }
        newState.warehouse.target.value = props?.destinationWarehouse;
        if (!Utility.isEmpty(props?.destinationWarehouse?.destRowCode)) {
          let row = props?.destinationWarehouse?.warehouseRowInfos?.find(
            (ele: any) => props?.destinationWarehouse?.destRowCode === ele.code
          );
          newState.RRB.target.row.value = row ?? null;
          newState.RRB.target.row.data =
            props?.destinationWarehouse?.warehouseRowInfos;
          newState.RRB.target.row.nonFilteredData =
            props?.destinationWarehouse?.warehouseRowInfos;
        }
        if (!Utility.isEmpty(props?.destinationWarehouse?.destRackCode)) {
          let rack = props?.destinationWarehouse?.warehouseRackInfos?.find(
            (ele: any) => props?.destinationWarehouse?.destRackCode === ele.code
          );
          newState.RRB.target.rack.value = rack ?? null;
          newState.RRB.target.rack.data =
            props?.destinationWarehouse?.filteredRackInfos;
          newState.RRB.target.rack.nonFilteredData =
            props?.destinationWarehouse?.warehouseRackInfos;
        }
        if (!Utility.isEmpty(props?.destinationWarehouse?.destBinCode)) {
          let bin = props?.destinationWarehouse?.warehouseBinInfos?.find(
            (ele: any) => props?.destinationWarehouse?.destBinCode === ele.code
          );
          newState.RRB.target.bin.value = bin ?? null;
          newState.RRB.target.bin.data =
            props?.destinationWarehouse?.filteredBinInfos;
          newState.RRB.target.bin.nonFilteredData =
            props?.destinationWarehouse?.warehouseBinInfos;
        }
      }
      newState.warehouse.target.nonFilteredData = props.targetWarehouseData;
      sourceDetail.forEach((data: any) => {});
      setFormState({ ...newState });
    }
  }, [props]);

  const makeSavePayload = (data: FormState) => {
    let Payload: any = {};
    if (!Utility.isEmpty(data)) {
      Payload = {
        lastChangeKey: data?.lastChangeKey,
        sourceWarehouse: data?.warehouse?.source?.value,
        targetWarehouse: data?.warehouse?.target?.value,
        srcRowCode: data?.RRB?.source?.row?.value?.code ?? null,
        srcRackCode: data?.RRB?.source?.rack?.value?.code ?? null,
        srcBinCode: data?.RRB?.source?.bin?.value?.code ?? null,
        destBinCode: data?.RRB?.target?.bin?.value?.code ?? null,
        destRackCode: data?.RRB?.target?.rack?.value?.code ?? null,
        destRowCode: data?.RRB?.target?.row?.value?.code ?? null
      };
    }
    return Payload;
  };

  const onFormFieldChange = (fieldName: string, data: any) => {
    let newState = { ...formState };
    switch (fieldName) {
      case FORM_FIELD_NAMES.SOURCE_WAREHOUSE:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.SOURCE_WAREHOUSE;

        // Warehouse state
        newState.warehouse.source.value = data;

        // RRB State - ROWS
        newState.RRB.source.row.nonFilteredData = data?.warehouseRowInfos;
        let seen = new Set();
        newState.RRB.source.row.data = data?.warehouseRowInfos?.filter(
          (item: any) => {
            let key = JSON.stringify(item);
            return seen.has(key) ? false : seen.add(key);
          }
        );
        // newState.RRB.source.row.value = data?.warehouseRowInfos?.[0];
        // newState.RRB.source.rack.data = data?.warehouseRackInfos.filter((rack: any) => rack.rowCode === );
        newState.RRB.source.rack.data = data?.warehouseRackInfos;
        newState.RRB.source.bin.data = data?.warehouseBinInfos;

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        props.onSourceWarehouseChange({ ...data, ...newState });
        break;
      case FORM_FIELD_NAMES.SOURCE_WAREHOUSE_ROW:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.SOURCE_WAREHOUSE_ROW;

        let tmpRackData =
          formState.warehouse.source.value.warehouseRackInfos.filter(
            (rack: any) => rack.rowCode === data.code
          );
        let tmpBinData =
          formState.warehouse.source.value.warehouseBinInfos.filter(
            (bin: any) => bin.rowCode === data.code
          );
        // RRB State - ROWS
        newState.RRB.source.row.value = data;
        newState.RRB.source.rack.value = null;
        newState.RRB.source.bin.value = null;

        if (newState.RRB?.source?.rack?.data) {
          if (tmpRackData && tmpRackData.length > 0) {
            newState.RRB.source.rack.data = tmpRackData;
          }
        }
        if (newState.RRB?.source?.bin?.data) {
          if (tmpBinData && tmpBinData.length > 0) {
            newState.RRB.source.bin.data = tmpBinData;
          }
        }

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.SOURCE_WAREHOUSE_RACK:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.SOURCE_WAREHOUSE_RACK;

        newState.RRB.source.rack.value = data;
        // RRB State - RACK
        let tmpBin = formState.warehouse.source.value.warehouseBinInfos.filter(
          (bin: any) =>
            bin.rowCode === formState.RRB.source.row.value.code &&
            bin.rackCode === data.code
        );
        if (newState.RRB?.source?.bin?.data) {
          if (tmpBin && tmpBin.length > 0) {
            newState.RRB.source.bin.data = tmpBin;
          }
        }

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.SOURCE_WAREHOUSE_BIN:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.SOURCE_WAREHOUSE_BIN;

        // RRB State - BIN
        newState.RRB.source.bin.value = data;

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.TARGET_WAREHOUSE:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.TARGET_WAREHOUSE;

        // Warehouse State
        newState.warehouse.target.value = data;

        // RRB State - ROW
        newState.RRB.target.row.nonFilteredData = data?.warehouseRowInfos;
        newState.RRB.target.row.data = data?.warehouseRowInfos;
        newState.RRB.target.row.value = data?.warehouseRowInfos?.[0];
        newState.RRB.target.rack.data = data?.warehouseRackInfos;
        newState.RRB.target.rack.value = data?.warehouseRackInfos
          ? data?.warehouseRackInfos
          : null;
        newState.RRB.target.bin.data = data?.warehouseBinInfos;
        newState.RRB.target.bin.data = data?.warehouseBinInfos
          ? data?.warehouseBinInfos
          : null;
        // RRB State - RACK
        let filteredRacksDataForTargetWarehouse: any[] =
          data?.warehouseRackInfos?.filter(
            (rack: any) => rack.rowCode === data?.warehouseRowInfos?.[0]?.code
          );

        newState.RRB.target.rack.nonFilteredData = data?.warehouseRackInfos;
        newState.RRB.target.rack.data =
          filteredRacksDataForTargetWarehouse?.length
            ? filteredRacksDataForTargetWarehouse
            : [];
        newState.RRB.target.rack.value =
          filteredRacksDataForTargetWarehouse?.length
            ? filteredRacksDataForTargetWarehouse?.[0]
            : {};

        // RRB State - BIN
        let filteredBinsDataForTargetWarehouse: any[] =
          data?.warehouseBinInfos?.filter(
            (bin: any) =>
              bin.rackCode === filteredRacksDataForTargetWarehouse?.[0]?.code
          );

        newState.RRB.target.bin.nonFilteredData = data?.warehouseBinInfos;
        newState.RRB.target.bin.data =
          filteredBinsDataForTargetWarehouse?.length
            ? filteredBinsDataForTargetWarehouse
            : [];
        newState.RRB.target.bin.value =
          filteredBinsDataForTargetWarehouse?.length
            ? filteredBinsDataForTargetWarehouse?.[0]
            : {};

        let filteredBinsDataFromRowForTargetWarehouse: any[] =
          data?.warehouseBinInfos?.filter(
            (bin: any) => bin.rowCode === data?.warehouseRowInfos?.[0]?.code
          );

        if (Utility.isEmpty(newState.RRB.target.bin.data)) {
          newState.RRB.target.bin.data =
            filteredBinsDataFromRowForTargetWarehouse?.length
              ? filteredBinsDataFromRowForTargetWarehouse
              : [];
          newState.RRB.target.bin.value =
            filteredBinsDataFromRowForTargetWarehouse?.length
              ? filteredBinsDataFromRowForTargetWarehouse?.[0]
              : {};
        }

        if (Utility.isEmpty(data?.warehouseRowInfos)) {
          newState.RRB.target.rack.nonFilteredData = data?.warehouseRackInfos;
          newState.RRB.target.rack.data = data?.warehouseRackInfos?.length
            ? data?.warehouseRackInfos
            : [];
          newState.RRB.target.rack.value = data?.warehouseRackInfos?.length
            ? data?.warehouseRackInfos?.[0]
            : {};
          newState.RRB.target.bin.nonFilteredData = data?.warehouseBinInfos;

          if (Utility.isEmpty(newState.RRB.target.rack.data)) {
            newState.RRB.target.bin.data = data?.warehouseBinInfos?.length
              ? data?.warehouseBinInfos
              : [];
            newState.RRB.target.bin.value = data?.warehouseBinInfos?.length
              ? data?.warehouseBinInfos?.[0]
              : {};
          } else {
            let filteredBinsDataFromRowForTargetWarehouse: any[] =
              data?.warehouseBinInfos?.filter(
                (bin: any) =>
                  bin.rackCode === newState?.RRB?.target?.rack?.value?.code
              );
            newState.RRB.target.bin.data =
              filteredBinsDataFromRowForTargetWarehouse?.length
                ? filteredBinsDataFromRowForTargetWarehouse
                : [];
            newState.RRB.target.bin.value =
              filteredBinsDataFromRowForTargetWarehouse?.length
                ? filteredBinsDataFromRowForTargetWarehouse?.[0]
                : {};
          }
        } else {
          // RRB State - RACKS
          newState.RRB.target.rack.nonFilteredData = data?.warehouseRackInfos;
          newState.RRB.target.rack.data =
            filteredRacksDataForTargetWarehouse?.length
              ? filteredRacksDataForTargetWarehouse
              : [];
          newState.RRB.target.rack.value =
            filteredRacksDataForTargetWarehouse?.length
              ? filteredRacksDataForTargetWarehouse?.[0]
              : {};
        }

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.TARGET_WAREHOUSE_ROW:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.TARGET_WAREHOUSE_ROW;

        // RRB State - ROW
        newState.RRB.target.row.value = data;

        // RRB State - RACK
        let filteredRacksDataForTargetRow: any[] =
          newState?.RRB?.target?.rack?.nonFilteredData?.filter(
            (rack: any) => rack.rowCode === data?.code
          );
        newState.RRB.target.rack.data = filteredRacksDataForTargetRow?.length
          ? filteredRacksDataForTargetRow
          : [];
        newState.RRB.target.rack.value = filteredRacksDataForTargetRow?.length
          ? filteredRacksDataForTargetRow?.[0]
          : {};

        // RRB State - BIN
        let filteredBinsDataForTargetRow: any[] =
          newState?.RRB?.target?.bin?.nonFilteredData?.filter(
            (bin: any) =>
              bin.rackCode === filteredRacksDataForTargetRow?.[0]?.code
          );
        newState.RRB.target.bin.data = filteredBinsDataForTargetRow?.length
          ? filteredBinsDataForTargetRow
          : [];
        newState.RRB.target.bin.value = filteredBinsDataForTargetRow?.length
          ? filteredBinsDataForTargetRow?.[0]
          : {};

        let filteredBinsDataFromRowsForTargetWarehouse: any[] =
          newState?.RRB?.target?.bin?.nonFilteredData.filter(
            (bin: any) => bin.rowCode === data?.code
          );

        if (Utility.isEmpty(newState.RRB.target.bin.data)) {
          newState.RRB.target.bin.data =
            filteredBinsDataFromRowsForTargetWarehouse?.length
              ? filteredBinsDataFromRowsForTargetWarehouse
              : [];
          newState.RRB.target.bin.value =
            filteredBinsDataFromRowsForTargetWarehouse?.length
              ? filteredBinsDataFromRowsForTargetWarehouse?.[0]
              : {};
        }

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.TARGET_WAREHOUSE_RACK:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.TARGET_WAREHOUSE_RACK;

        // RRB State - RACK
        newState.RRB.target.rack.value = data;

        // RRB State - BIN
        let filteredBinsDataForTargetRack: any[] =
          newState?.RRB?.target?.bin?.nonFilteredData?.filter(
            (bin: any) => bin.rackCode === data?.code
          );

        newState.RRB.target.bin.data = filteredBinsDataForTargetRack?.length
          ? filteredBinsDataForTargetRack
          : [];
        newState.RRB.target.bin.value = filteredBinsDataForTargetRack?.length
          ? filteredBinsDataForTargetRack?.[0]
          : {};

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
      case FORM_FIELD_NAMES.TARGET_WAREHOUSE_BIN:
        // Last Change Key State
        newState.lastChangeKey = FORM_FIELD_NAMES.TARGET_WAREHOUSE_BIN;

        // RRB State - BIN
        newState.RRB.target.bin.value = data;

        setFormState({ ...newState });
        props.onSave(makeSavePayload({ ...newState }));
        break;
    }
  };

  return (
    <div className="column parent-width">
      <div className="row parent-width mt-3">
        <div className="w-1/3 pr-s">
          <DKInput
            className={''}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={formState.warehouse.source.value}
            formatter={(obj: any) => {
              return obj.name;
            }}
            title={'Source Warehouse'}
            icon={''}
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            canValidate={false}
            onChange={(value: any) => {
              onFormFieldChange(FORM_FIELD_NAMES.SOURCE_WAREHOUSE, value);
              onFormFieldChange(FORM_FIELD_NAMES.TARGET_WAREHOUSE, null);
              props.onTargetWarehouseChange(null);
            }}
            dropdownConfig={{
              className: '',
              style: {},
              allowSearch: true,
              searchableKey: 'name',
              canEdit: false,
              canDelete: false,
              data: formState?.warehouse?.source?.data?.length
                ? formState?.warehouse?.source?.data
                : [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              },
              onSelect: (index: any, value: any) => {}
            }}
          />
        </div>
        {formState?.warehouse?.source?.value?.warehouseRowInfos?.length ||
        formState?.warehouse?.source?.value?.warehouseRackInfos?.length ||
        formState?.warehouse?.source?.value?.warehouseBinInfos?.length ? (
          <div className="w-2/3">
            <div className="row">
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.source.row.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Source Row'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.SOURCE_WAREHOUSE_ROW,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.source?.row?.data?.length
                      ? formState?.RRB?.source?.row?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.source.rack.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Source Rack'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.SOURCE_WAREHOUSE_RACK,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.source?.rack?.data?.length
                      ? formState?.RRB?.source?.rack?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.source.bin.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Source Bin'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.SOURCE_WAREHOUSE_BIN,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.source?.bin?.data?.length
                      ? formState?.RRB?.source?.bin?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
      <div className="row parent-width mt-3">
        <div className="w-1/3 pr-s">
          <DKInput
            className={''}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={formState.warehouse.target.value}
            formatter={(obj: any) => {
              return obj.name;
            }}
            title={'Target Warehouse'}
            icon={''}
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            canValidate={false}
            onChange={(value: any) => {
              onFormFieldChange(FORM_FIELD_NAMES.TARGET_WAREHOUSE, value);
              props.onTargetWarehouseChange(value);
            }}
            dropdownConfig={{
              className: '',
              style: {},
              allowSearch: true,
              searchableKey: 'name',
              searchApiConfig: {
                getUrl: (searchValue: string) => {
                  const query: string = `?limit=50&page=0&search=${searchValue}&allRRBDetails=true&query=active=true`;
                  const finalEndPoint =
                    ApiConstants.URL.BASE +
                    ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                    query;
                  return finalEndPoint;
                },
                dataParser: (response: any) => {
                  let filtered = response?.content?.filter(
                    (wh: any) =>
                      wh.warehouseType !== 'REJECTED' &&
                      wh.active &&
                      props?.sourceWarehouse?.code !== wh.code
                  );
                  return filtered;
                },
                debounceTime: 300
              },
              canEdit: false,
              canDelete: false,
              data: formState?.warehouse?.target?.data?.length
                ? formState?.warehouse?.target?.data
                : [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              },
              onSelect: (index: any, value: any) => {}
            }}
          />
        </div>
        {formState?.warehouse?.target?.value?.warehouseRowInfos?.length ||
        formState?.warehouse?.target?.value?.warehouseRackInfos?.length ||
        formState?.warehouse?.target?.value?.warehouseBinInfos?.length ? (
          <div className="w-2/3">
            <div className="row">
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.target.row.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Target Row'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.TARGET_WAREHOUSE_ROW,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.target?.row?.data?.length
                      ? formState?.RRB?.target?.row?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.target.rack.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Target Rack'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.TARGET_WAREHOUSE_RACK,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.target?.rack?.data?.length
                      ? formState?.RRB?.target?.rack?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
              <div className="column w-1/3 p-h-s">
                <DKInput
                  className={''}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={formState.RRB.target.bin.value}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={'Target Bin'}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onFormFieldChange(
                      FORM_FIELD_NAMES.TARGET_WAREHOUSE_BIN,
                      value
                    );
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    data: formState?.RRB?.target?.bin?.data?.length
                      ? formState?.RRB?.target?.bin?.data
                      : [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <Fragment></Fragment>
        )}
      </div>
    </div>
  );
};

export default SourceDestinationWarehouseManagementRRB;
