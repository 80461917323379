import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  CURRENCY_PRECISION,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import ProductService from '../../Services/Product';
import WarehouseService from '../../Services/Warehouse';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import WarehouseManagementHelper, {
  WAREHOUSE_TYPE
} from '../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import Utility from '../../Utility/Utility';

export default function ReserveStockPopUp(props: any) {
  const [columnConfig, setColumnConfig] = useState([
    {
      id: 'selectedWarehouse',
      key: 'selectedWarehouse',
      name: 'Warehouse',
      type: INPUT_TYPE.DROPDOWN,
      width: 162,
      systemField: false,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: (value: any) => {
        return !Utility.isEmpty(value)
          ? value?.rowData?.selectedWarehouse?.name
          : '';
      },
      dropdownConfig: {
        title: 'Select Warehouse',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        onSelect: (data: any) => {},
        data: [],
        renderer: (index: number, value: any) => {
          return value.name;
        }
      }
    },
    {
      id: 'advancedTracking',
      key: 'advancedTracking',
      name:
        props?.selectedProduct?.product?.advancedTracking ===
        TRACKING_TYPE.SERIAL
          ? 'Serial Number'
          : 'Batch Number',
      type: INPUT_TYPE.DROPDOWN,
      width: 162,
      systemField: false,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: (value: any) => {
        return !Utility.isEmpty(value) ? value?.value?.serialBatchNumber : '';
      },
      dropdownConfig: {
        title: '',
        allowSearch: true,
        searchableKey: 'serialBatchNumber',
        style: { minWidth: 230 },
        className: 'shadow-m',
        onSelect: (data: any) => {},
        data: [],
        renderer: (index: number, value: any) => {
          return value.serialBatchNumber;
        }
      }
    },
    {
      id: 'manufacturingDate',
      key: 'manufacturingDate',
      name: 'Mfg Date',
      type: INPUT_TYPE.TEXT,
      width: 120,
      systemField: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'expiryDate',
      key: 'expiryDate',
      name: 'Expiry Date',
      type: INPUT_TYPE.TEXT,
      width: 120,
      systemField: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'row',
      columnCode: 'row',
      key: 'row',
      name: 'Select Row',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: 'Select Row',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'rack',
      columnCode: 'rack',
      key: 'rack',
      name: 'Select Rack',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: 'Select Rack',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'bin',
      columnCode: 'bin',
      key: 'bin',
      name: 'Select Bin',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: 'Select Bin',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'totalQuantity',
      key: 'totalQuantity',
      name: 'Total Qty',
      type: INPUT_TYPE.NUMBER,
      width: 120,
      systemField: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'availableQuantity',
      key: 'availableQuantity',
      name: 'Available Qty',
      type: INPUT_TYPE.NUMBER,
      width: 120,
      systemField: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'reservedQuantity',
      key: 'reservedQuantity',
      name: 'Reserved Qty',
      type: INPUT_TYPE.NUMBER,
      width: 120,
      systemField: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 50,
      options: []
    }
  ]);
  const [selectedProduct, setSelectedProduct] = useState(props.selectedProduct);
  const [records, setRecords] = useState<any[]>([]);
  const [warehousesList, setWarehousesList] = useState<any>();
  const [reservedQuantitiesDataOriginal, setReservedQuantitiesDataOriginal] =
    useState<any[]>(
      props.reservedQuantitiesDataOriginal
        ? props.reservedQuantitiesDataOriginal
        : []
    );
  const [advancedTrackingOptions, setadvancedTrackingOptions] = useState<any[]>(
    []
  );
  const [popupWidth, setPopupWidth] = useState<number>(767);
  const tenantInfo = useAppSelector<any>(activeTenantInfo);
  const [allAvailableWarehouses, setAllAvailableWarehouses] = useState([]);

  useEffect(() => {
    loadAllWarehouses();
    if (selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.BATCH) {
      let updatedConfig: any = removeDatesFromConfig();
      if (selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.SERIAL) {
        makeSelectFieldsEditable(updatedConfig);
      }
    }
    return () => {
      setReservedQuantitiesDataOriginal([]);
      setWarehousesList([]);
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(allAvailableWarehouses)) {
      updateConfig();

      if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
        getNormalTrackedWarehousesData();
      } else {
        getAdvancedTrackedWarehouseData();
      }
    }
  }, [allAvailableWarehouses]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
        (item: any) => item?.enable
      );
      if (!Utility.isEmpty(rrbEnabled)) {
        if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
          setPopupWidth(990);
        } else {
          setPopupWidth(1150);
        }
      } else {
        if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
          setPopupWidth(620);
        }
      }
    }
  }, [tenantInfo]);

  const removeDatesFromConfig = () => {
    let updatedConfig: any = [...columnConfig];
    updatedConfig = updatedConfig.filter(
      (config: any) =>
        config.key !== 'manufacturingDate' && config.key !== 'expiryDate'
    );

    setColumnConfig([...updatedConfig]);
    return [...updatedConfig];
  };

  const makeSelectFieldsEditable = (updatedConfig: any) => {
    updatedConfig.forEach((config: any, index: any) => {
      if (
        config.key === 'selectedWarehouse' ||
        config.key === 'advancedTracking'
      ) {
        updatedConfig[index].editable = true;
      }
    });
    setColumnConfig([...updatedConfig]);
    return updatedConfig;
  };

  const loadAllWarehouses = () => {
    showLoader('Fetching warehouse info...');
    WarehouseService.apiConfig = {
      ...WarehouseService.apiConfig,
      Limit: 200
    };
    WarehouseService.getWarehouses().then(
      (whData: any) => {
        if (!Utility.isEmpty(whData)) {
          if (whData?.content?.length) {
            setAllAvailableWarehouses(whData?.content);
          } else {
            showAlert('Error!', 'No warehouse data available.');
            setAllAvailableWarehouses([]);
          }
        }
        removeLoader();
        WarehouseService.apiConfig = {
          ...WarehouseService.apiConfig,
          Limit: 25
        };
      },
      (err: any) => {
        showAlert('Error!', 'Error fetching warehouse information.');
        console.error('Error fetching warehouses: ', err);
        removeLoader();
        WarehouseService.apiConfig = {
          ...WarehouseService.apiConfig,
          Limit: 25
        };
      }
    );
  };

  const updateConfig = (
    selectedWarehouse: any = null,
    selectedSerialBatchObj: any = null,
    selectedRow: any = null,
    selectedRack: any = null,
    selectedBin: any = null
  ) => {
    // console.log(
    //   'updateConfig: ',
    //   selectedSerialBatchObj,
    //   selectedWarehouse,
    //   selectedRow,
    //   selectedBin
    // );
    let config = [...columnConfig];
    let updatedConfig: any = [];
    let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
    config?.forEach((col: any) => {
      if (
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        const isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
          return i.name === col.key.toUpperCase() && i.enable;
        });
        if (isRRBEnabled) {
          col = {
            ...col,
            name: isRRBEnabled?.label
          };
          updatedConfig.push(col);
        }
      } else {
        updatedConfig.push(col);
      }
    });

    //mainDto
    let rrbDtoForProduct: any[] = [];
    if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
      rrbDtoForProduct =
        WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
          selectedWarehouse?.rowRackBinProductAvailableQuantityDtos,
          selectedProduct?.productCode
        );
    } else {
      if (selectedWarehouse && selectedSerialBatchObj) {
        const filteredDto = selectedWarehouse?.advancedTrackingMeta?.filter(
          (atDt: any) =>
            atDt.serialBatchNumber === selectedSerialBatchObj?.serialBatchNumber
        );
        rrbDtoForProduct = [...filteredDto];
      }
    }

    //rows
    const uniqueRows = WarehouseManagementHelper.getUniqueRows(
      rrbDtoForProduct ?? [],
      selectedRack?.rackCode,
      selectedBin?.binCode
    );
    //racks
    const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
      rrbDtoForProduct ?? [],
      selectedRow?.rowCode ?? '',
      selectedBin?.binCode ?? ''
    );
    //bins
    const uniqueBins = WarehouseManagementHelper.getDtoFiltered(
      WarehouseManagementHelper.getUniqueBins(
        rrbDtoForProduct ?? [],
        selectedRow?.rowCode ?? '',
        selectedRack?.rackCode ?? ''
      ),
      'binCode'
    );

    updatedConfig.forEach((conf: any) => {
      let isRRBEnabled = false;
      switch (conf.key) {
        case 'selectedWarehouse':
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                text={obj.name}
                className={`align-item-center pt-0.5 fw-r fs-r`}
              />
            );
          };
          if (
            selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE
          ) {
            // let selectedWarehouses = records?.map(
            //   (item: any) => item.warehouseCode
            // );
            conf.dropdownConfig.data = warehousesList;
          } else {
            conf.dropdownConfig.data = warehousesList;
          }
          break;
        case 'advancedTracking':
          conf.hidden =
            selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE;
          conf.dropdownConfig.data = advancedTrackingOptions;
          break;
        case 'row':
          isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
            return i.name === conf.key.toUpperCase() && i.enable;
          });
          conf.hidden = !isRRBEnabled;
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.row?.rowName ?? '';
          };
          conf.dropdownConfig.data = uniqueRows ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.rowCode ? (
              <DKLabel text={`${obj?.rowName} (${obj?.rowCode})`} />
            ) : null;
          };
          break;
        case 'rack':
          isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
            return i.name === conf.key.toUpperCase() && i.enable;
          });
          conf.hidden = !isRRBEnabled;
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.rack?.rackName ?? '';
          };
          conf.dropdownConfig.data = uniqueRacks ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.rackCode ? (
              <DKLabel text={`${obj?.rackName} (${obj?.rackCode})`} />
            ) : (
              ''
            );
          };
          break;
        case 'bin':
          isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
            return i.name === conf.key.toUpperCase() && i.enable;
          });
          conf.hidden = !isRRBEnabled;
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.bin?.binName ?? '';
          };
          conf.dropdownConfig.data = uniqueBins ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.binCode ? (
              <DKLabel text={`${obj?.binName} (${obj?.binCode})`} />
            ) : (
              ''
            );
          };
          break;
        default:
          break;
      }
    });
    setColumnConfig(updatedConfig.filter((col: any) => !col.hidden));
  };

  const getTotalQuantity = (
    dto: any = [],
    rowCode = null,
    rackCode = null,
    binCode = null
  ) => {
    let totalQty = dto.reduce((accumulator: any, currentValue: any) => {
      if (
        (!rowCode || currentValue.rowCode === rowCode) &&
        (!rackCode || currentValue.rackCode === rackCode) &&
        (!binCode || currentValue.binCode === binCode)
      ) {
        const availableQty =
          currentValue?.availableQuantity ?? currentValue?.batchSize;
        return accumulator + (availableQty ?? 0);
      } else {
        return accumulator;
      }
    }, 0);
    totalQty = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          totalQty,
          selectedProduct?.documentUOMSchemaDefinition
        )
      : totalQty;
    return totalQty;
  };

  const getAvailableQuantity = (
    dto: any = [],
    rowCode = null,
    rackCode = null,
    binCode = null
  ) => {
    let availableQty = dto.reduce((accumulator: any, currentValue: any) => {
      if (
        (!rowCode || currentValue.rowCode === rowCode) &&
        (!rackCode || currentValue.rackCode === rackCode) &&
        (!binCode || currentValue.binCode === binCode)
      ) {
        const aQty = currentValue?.availableQuantity ?? currentValue?.batchSize;
        return (
          accumulator +
          (aQty ?? 0) -
          ((currentValue.reservedQuantity ?? 0) -
            (currentValue.reservedQuantityFulfilled ?? 0) +
            (currentValue.batchSizeFulfilled ?? 0) ?? 0)
        );
      } else {
        return accumulator;
      }
    }, 0);
    availableQty = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          availableQty,
          selectedProduct?.documentUOMSchemaDefinition
        )
      : availableQty;
    return Utility.roundOff(availableQty, QTY_ROUNDOFF_PRECISION);
  };

  const addRow = (warehouse: any) => {
    setRecords([
      ...records,
      {
        selectedWarehouse: null,
        advancedTracking: null,
        productCode: warehouse.productCode,
        warehouseCode: warehouse.warehouseCode,
        availableQuantity:
          warehouse.availableQuantity - warehouse.reservedQuantity,
        reservedQuantity: 0,
        totalQuantity: warehouse.availableQuantity,
        advancedTrackingType: warehouse.advancedTrackingType,
        advancedTrackingMetaDtos: warehouse.advancedTrackingMetaDtos,
        invalidFields:
          selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE
            ? ['selectedWarehouse']
            : ['selectedWarehouse', 'advancedTracking']
      }
    ]);
  };

  const getRowData = () => {
    let rowButtons: any = [];
    if (
      selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.BATCH &&
      selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.SERIAL
    ) {
      rowButtons = [
        {
          icon: DKIcons.ic_delete,
          className: 'ic-xs',
          onClick: (data: any) => onDelete(data)
        }
      ];
    }
    const rowData = [...records].map((record: any) => ({
      ...record,
      expiryDate: DateFormatService.getFormattedDateString(
        record.expiryDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      manufacturingDate: DateFormatService.getFormattedDateString(
        record.manufacturingDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      rowButtons: rowButtons
    }));
    return rowData;
  };

  const setSerialBatchOptions = (warehouse: any) => {
    // const reservedQuantitiesData = records;
    let reserveQuantityInfoForWarehouse =
      reservedQuantitiesDataOriginal?.filter(
        (data: any) => data.warehouseCode === warehouse.code
      );

    let trackingOptions: any[] = [];
    if (!Utility.isEmpty(reserveQuantityInfoForWarehouse)) {
      reserveQuantityInfoForWarehouse?.forEach((row: any) => {
        if (!Utility.isEmpty(row.advancedTrackingMetaDtos)) {
          trackingOptions = [
            ...trackingOptions,
            ...row?.advancedTrackingMetaDtos
          ];
        }
      });

      trackingOptions = trackingOptions.map((option: any) => {
        const advTrackingInWH = warehouse?.advancedTrackingMeta?.find(
          (data: any) =>
            data.warehouseCode == option.warehouseCode &&
            data.serialBatchNumber == option.serialBatchNumber &&
            data.rowCode == option.rowCode &&
            data.rackCode == option.rackCode &&
            data.binCode == option.binCode
        );
        if (!Utility.isEmpty(advTrackingInWH)) {
          option.acquiredCost = advTrackingInWH.acquiredCost ?? null;
          option.rowName = advTrackingInWH.rowName;
          option.rackName = advTrackingInWH.rackName;
          option.binName = advTrackingInWH.binName;
          option.expiryDate = advTrackingInWH.expiryDate;
          option.manufacturingDate = advTrackingInWH.manufacturingDate;
          option.warehouseName = warehouse.name;
        }
        return option;
      });
    }
    trackingOptions = trackingOptions?.filter(
      (option: any) =>
        option.reservedQuantity -
          option.reservedQuantityFulfilled +
          option.batchSizeFulfilled <
        option.batchSize
    );
    setadvancedTrackingOptions(trackingOptions);
    return trackingOptions;
  };

  const onRowClick = (data: any) => {
    if (
      selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE &&
      data?.rowData?.selectedWarehouse
    ) {
      setSerialBatchOptions(data?.rowData?.selectedWarehouse);
    }
    updateConfig(
      data?.rowData?.selectedWarehouse,
      data?.rowData?.advancedTracking,
      data?.rowData?.row
    );
  };

  const prepareWarehousesList = (warehouses: any) => {
    let warehousesCodes = reservedQuantitiesDataOriginal.map(
      (item: any) => item.warehouseCode
    );
    let warehousesArray = warehouses.filter((item: any) => {
      const isWarehouseTypeNone = allAvailableWarehouses?.find(
        (wh: any) =>
          wh.code === item.code && wh.warehouseType === WAREHOUSE_TYPE.NONE
      );
      return (
        !Utility.isEmpty(isWarehouseTypeNone) &&
        warehousesCodes.includes(item.code)
      );
    });
    setWarehousesList(warehousesArray);
    if (
      selectedProduct?.product?.advancedTracking === TRACKING_TYPE.BATCH ||
      selectedProduct?.product?.advancedTracking === TRACKING_TYPE.SERIAL
    ) {
      return getPrePopulatedGridRows(warehousesArray);
    }
  };

  const getAdvancedTrackedWarehouseData = () => {
    showLoader('Fetching Advance Track Data');
    ProductService.fetchProductAdvancedTrackingWarehouse(
      selectedProduct?.productCode
    )
      .then((res: any) => {
        removeLoader();
        let fullData: any = prepareWarehousesList(res);
        if (props.reservedQuantitiesData) {
          let data = props.reservedQuantitiesData.map((item: any) => {
            // let originalData = reservedQuantitiesDataOriginal.find(
            //   (original: any) =>
            //     original.warehouseCode === item.warehouseCode &&
            //     original.serialBatchNumber ==
            //       item?.advancedTracking?.serialBatchNumber &&
            //     original.rowCode == item.rowCode &&
            //     original.rackCode == item.rackCode &&
            //     original.binCode == item.binCode
            // );
            let warehouse = res?.find(
              (warehouse: any) => warehouse.code === item.warehouseCode
            );
            let filteredWHAdvancedMeta: any[] = [];
            if (!Utility.isEmpty(warehouse)) {
              filteredWHAdvancedMeta = warehouse?.advancedTrackingMeta?.filter(
                (atDt: any) =>
                  atDt.serialBatchNumber ==
                    item?.advancedTracking?.serialBatchNumber &&
                  atDt.rowCode == item.rowCode &&
                  atDt.rackCode == item.rackCode &&
                  atDt.binCode == item.binCode
              );
            }
            let edited = { ...item };

            const rrbDtoForProduct = !Utility.isEmpty(filteredWHAdvancedMeta)
              ? [...filteredWHAdvancedMeta]
              : [];
            //rows
            const uniqueRows = WarehouseManagementHelper.getUniqueRows(
              rrbDtoForProduct ?? [],
              edited.rackCode,
              edited.binCode
            );
            //racks
            const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
              rrbDtoForProduct ?? [],
              edited.rowCode,
              edited.binCode
            );
            //bins
            const uniqueBins = WarehouseManagementHelper.getUniqueBins(
              rrbDtoForProduct ?? [],
              edited.rowCode,
              edited.rackCode
            );

            // const originalAdvancedTrackingMetaDto =
            //   originalData.advancedTrackingMetaDtos?.find(
            //     (obj: any) =>
            //       obj.serialBatchNumber ===
            //         edited?.advancedTracking?.serialBatchNumber &&
            //       obj.rowCode == edited.rowCode &&
            //       obj.rackCode == edited.rackCode &&
            //       obj.binCode == edited.binCode
            //   );
            if (!Utility.isEmpty(rrbDtoForProduct)) {
              const row = uniqueRows?.find(
                (x: any) => x.rowCode === edited?.rowCode
              );
              const rack = uniqueRacks?.find(
                (x: any) => x.rackCode === edited?.rackCode
              );
              const bin = uniqueBins?.find(
                (x: any) => x.binCode === edited?.binCode
              );
              edited = {
                ...edited.advancedTracking,
                advancedTracking: { ...edited.advancedTracking },
                row: row,
                rack: rack,
                bin: bin
              };

              edited.totalQuantity = getTotalQuantity(
                rrbDtoForProduct,
                edited.rowCode,
                edited.rackCode,
                edited.binCode
              );

              edited.availableQuantity = getAvailableQuantity(
                rrbDtoForProduct,
                edited.rowCode,
                edited.rackCode,
                edited.binCode
              );
            } else {
              edited.totalQuantity = edited?.advancedTracking.batchSize;
              edited.availableQuantity =
                edited.totalQuantity - edited?.reservedQuantity;
            }
            edited.advancedTrackingMetaDtos = edited?.advancedTracking;
            setSerialBatchOptions(warehouse);

            updateConfig(
              warehouse,
              edited.advancedTracking,
              edited.row,
              edited.rack,
              edited.bin
            );
            return { ...edited, selectedWarehouse: warehouse };
          });

          if (
            selectedProduct?.product?.advancedTracking ===
              TRACKING_TYPE.BATCH ||
            selectedProduct?.product?.advancedTracking === TRACKING_TYPE.SERIAL
          ) {
            let allRecordsWithSelected: any = [];
            let dataCopy: any = [];
            fullData?.forEach((fullRow: any) => {
              let alreadySelected = false;
              data.forEach((selectedRow: any) => {
                if (
                  fullRow?.advancedTracking?.serialBatchNumber ===
                  selectedRow?.advancedTracking?.serialBatchNumber
                ) {
                  alreadySelected = true;
                  dataCopy.push({
                    ...selectedRow,
                    productCode: fullRow.productCode,
                    advancedTrackingType: fullRow.advancedTrackingType
                  });
                }
              });
              if (!alreadySelected) {
                allRecordsWithSelected.push(fullRow);
              }
            });
            allRecordsWithSelected = [...allRecordsWithSelected];
            dataCopy.forEach((row1: any) => {
              let found = false;
              allRecordsWithSelected.forEach((row2: any) => {
                if (
                  row1.warehouseCode == row2.warehouseCode &&
                  row1.advancedTracking?.serialBatchNumber ==
                    row2.serialBatchNumber &&
                  row1.rowCode == row2.rowCode &&
                  row1.rackCode == row2.rackCode
                ) {
                  found = true;
                }
              });
              if (!found) {
                allRecordsWithSelected.push(row1);
              }
            });
            setRecords(allRecordsWithSelected);
          } else {
            setRecords(data);
          }
        }
      })
      .catch((err: any) => {
        removeLoader();
        console.log('Error Fetching Advanced Tracked Warehouses data: ', err);
      });
  };

  const getNormalTrackedWarehousesData = () => {
    ProductService.fetchWarehouseProductsByID([selectedProduct?.productCode])
      .then((res: any) => {
        prepareWarehousesList(res?.warehouses);
        if (props.reservedQuantitiesData) {
          let data = props.reservedQuantitiesData.map((item: any) => {
            let originalData = reservedQuantitiesDataOriginal.find(
              (original: any) => original.warehouseCode === item.warehouseCode
            );
            let warehouse = res?.warehouses.find(
              (warehouse: any) => warehouse.code === item.warehouseCode
            );
            let edited = { ...item };
            edited.totalQuantity = originalData.availableQuantity;
            edited.availableQuantity =
              originalData?.availableQuantity - originalData?.reservedQuantity;
            edited.advancedTrackingMetaDtos = null;
            edited.advancedTracking = null;
            // set rrbDtoForProduct, row,rack,bin
            const rrbDtoForProduct =
              WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
                warehouse?.rowRackBinProductAvailableQuantityDtos,
                selectedProduct?.productCode
              );
            //rows
            const uniqueRows = WarehouseManagementHelper.getUniqueRows(
              rrbDtoForProduct ?? [],
              edited.rackCode,
              edited.binCode
            );
            //racks
            const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
              rrbDtoForProduct ?? [],
              edited.rowCode,
              edited.binCode
            );
            //bins
            const uniqueBins = WarehouseManagementHelper.getUniqueBins(
              rrbDtoForProduct ?? [],
              edited.rowCode,
              edited.rackCode
            );

            edited.row = uniqueRows?.find(
              (x: any) => x.rowCode === edited?.rowCode
            );
            edited.rack = uniqueRacks?.find(
              (x: any) => x.rackCode === edited?.rackCode
            );
            edited.bin = uniqueBins?.find(
              (x: any) => x.binCode === edited?.binCode
            );

            if (!Utility.isEmpty(rrbDtoForProduct)) {
              edited.totalQuantity = getTotalQuantity(
                rrbDtoForProduct,
                edited.rowCode,
                edited.rackCode,
                edited.binCode
              );

              edited.availableQuantity = getAvailableQuantity(
                rrbDtoForProduct,
                edited.rowCode,
                edited.rackCode,
                edited.binCode
              );
            } else {
              const availableQty = selectedProduct?.documentUOMSchemaDefinition
                ? Utility.getUomQuantity(
                    warehouse?.productAvailableQuantity[
                      selectedProduct?.productCode
                    ] -
                      warehouse?.productReservedQuantity?.[
                        selectedProduct?.productCode
                      ],
                    selectedProduct?.documentUOMSchemaDefinition
                  )
                : warehouse?.productAvailableQuantity?.[
                    selectedProduct?.productCode
                  ] -
                  warehouse?.productReservedQuantity?.[
                    selectedProduct?.productCode
                  ];

              const totalQty = selectedProduct?.documentUOMSchemaDefinition
                ? Utility.getUomQuantity(
                    warehouse?.productAvailableQuantity[
                      selectedProduct?.productCode
                    ],
                    selectedProduct?.documentUOMSchemaDefinition
                  )
                : warehouse?.productAvailableQuantity?.[
                    selectedProduct?.productCode
                  ];

              edited.availableQuantity =
                // availableQty - item?.reservedQuantity;
                getExistiingReservedQuantityForUpdate(
                  selectedProduct?.productCode,
                  item?.reservedQuantity,
                  availableQty,
                  true,
                  warehouse?.code
                ) || 0;
              edited.totalQuantity = totalQty;
            }

            updateConfig(
              warehouse,
              edited.advancedTracking,
              edited.row,
              edited.rack,
              edited.bin
            );
            return { ...edited, selectedWarehouse: warehouse };
          });
          setRecords(data);
        }
      })
      .catch((err: any) => {
        console.log('Error Fetching Warehouses data: ', err);
      });
  };

  // TODO: validate selection if it contains duplicate rows with combination of
  // wh, row, rack, bin
  const selectionHasDuplicates = () => {
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );
    let hasDuplicates = false;
    const recordsCopy = [...records];
    if (!Utility.isEmpty(rrbEnabled)) {
      if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
        for (let record of records) {
          const filterdRecords = recordsCopy.filter(
            (rec: any) =>
              rec.warehouseCode == record.warehouseCode &&
              rec.rowCode == record.rowCode &&
              rec.rackCode == record.rackCode &&
              rec.binCode == record.binCode
          );
          if (filterdRecords.length > 1) {
            hasDuplicates = true;
            break;
          }
        }
      } else {
        records.forEach((record: any, outerIndex: any) => {
          const filterdRecords = recordsCopy.filter(
            (rec: any, innerIndex) =>
              rec.warehouseCode == record.warehouseCode &&
              rec.advancedTracking?.serialBatchNumber ==
                record.advancedTracking?.serialBatchNumber &&
              rec.rowCode == record.rowCode &&
              rec.rackCode == record.rackCode &&
              rec.binCode == record.binCode &&
              innerIndex !== outerIndex
          );
          if (filterdRecords.length > 1) {
            hasDuplicates = true;
          }
        });
      }
    } else {
      if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
        for (let record of records) {
          const filterdRecords = recordsCopy.filter(
            (rec: any) => rec.warehouseCode == record.warehouseCode
          );
          if (filterdRecords.length > 1) {
            hasDuplicates = true;
            break;
          }
        }
      } else {
        for (let record of records) {
          const filterdRecords = recordsCopy.filter(
            (rec: any) =>
              rec.warehouseCode == record.warehouseCode &&
              rec.advancedTracking?.serialBatchNumber ==
                record.advancedTracking?.serialBatchNumber
          );
          if (filterdRecords.length > 1) {
            hasDuplicates = true;
            break;
          }
        }
      }
    }
    return hasDuplicates;
  };

  const isSaveBtnEnabled = () => {
    let enabled =
      !isShowError() &&
      records.findIndex((record: any) => record.invalidFields?.length > 0) ===
        -1;
    enabled = enabled && !selectionHasDuplicates();
    return enabled;
  };

  const getHeader = () => {
    return (
      <div
        className="row justify-content-between p-h-r p-v-s bg-gray1"
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        }}
      >
        <div className="row pop-header-drag-handle">
          <DKLabel text="Reserve Stock" className="fw-m fs-l" />
          <DKTooltipWrapper
            content="User can reserve stock for this document and same will be fulfilled on priority"
            tooltipClassName="bg-deskera-secondary width-auto"
            tooltipStyle={{ whiteSpace: 'pre-wrap' }}
          >
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-xs opacity-40 ml-s cursor-hand"
              onClick={() => {}}
            />
          </DKTooltipWrapper>
        </div>

        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            title={'Reserve'}
            className={
              isSaveBtnEnabled()
                ? 'bg-button text-white'
                : 'bg-white text-gray border-m'
            }
            onClick={() => {
              if (isSaveBtnEnabled()) {
                let copyRecords: any = [...records];
                copyRecords = copyRecords.filter(
                  (item: any) => item.reservedQuantity !== 0
                );
                props.onSave({
                  lineNumber: selectedProduct.lineNumber,
                  records: copyRecords.map((item: any) => {
                    return {
                      productCode: item.productCode,
                      warehouseCode: item.warehouseCode,
                      warehouseName: item?.warehouseName,
                      availableQuantity: item.availableQuantity,
                      reservedQuantity: item.reservedQuantity,
                      advancedTrackingType: item.advancedTrackingType,
                      advancedTrackingMetaDtos: item.advancedTracking
                        ? [item.advancedTracking]
                        : [],
                      advancedTracking: item.advancedTracking,
                      totalQuantity: item.totalQuantity,
                      documentType: props.docType,
                      rowCode: item.rowCode ?? null,
                      rowName: item?.row?.rowName ?? null,
                      rackCode: item.rackCode ?? null,
                      rackName: item?.rack?.rackName ?? null,
                      binCode: item.binCode ?? null,
                      binName: item?.bin?.binName ?? null
                    };
                  })
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getProductDetailsElement = (title: string, value: string) => {
    return (
      <div className="column parent-width">
        <DKLabel className="text-app-color fw-m" text={title} />
        <DKLabel text={value} />
      </div>
    );
  };

  const getUomQtyIntoBaseQtyDecimal = (
    inputQuantity: any,
    documentUOMSchemaDefinition: any,
    precisionVal = CURRENCY_PRECISION
  ) => {
    if (!isNaN(inputQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundingOff(
        (inputQuantity * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor,
        precisionVal
      );
    }
    return inputQuantity;
  };

  const getProductDetailsView = (
    <div className="row justify-content-evenly">
      {getProductDetailsElement(
        'Product',
        selectedProduct?.productName
          ? selectedProduct?.productName
          : selectedProduct?.product.name
          ? selectedProduct?.product.name
          : ''
      )}
      {getProductDetailsElement(
        'Description',
        selectedProduct?.productDescription
          ? selectedProduct?.productDescription
          : selectedProduct?.product.description
          ? selectedProduct?.product.description
          : ' '
      )}
      {getProductDetailsElement(
        'Reserve Stock',
        selectedProduct?.productQuantity
          ? selectedProduct?.productQuantity
          : ' '
      )}
      {selectedProduct?.documentUOMSchemaDefinition &&
        selectedProduct?.product?.advancedTracking === TRACKING_TYPE.SERIAL &&
        getProductDetailsElement(
          'Reserve Stock in Base UOM',
          selectedProduct?.productQuantity
            ? getUomQtyIntoBaseQtyDecimal(
                selectedProduct?.productQuantity,
                selectedProduct?.documentUOMSchemaDefinition
              )
            : ' '
        )}
    </div>
  );

  const getPrePopulatedGridRows = (warehouseArrayList: any) => {
    let updatedRecords: any = [];
    warehouseArrayList.forEach((wh: any) => {
      let advTrackData: any = setSerialBatchOptions(wh);
      let obj: any = updateFieldsForAutoPopulatedRecords(wh);
      advTrackData.forEach((atdt: any) => {
        let availableQty =
          (atdt.batchSize ?? 0) -
          ((atdt.reservedQuantity ?? 0) -
            (atdt.reservedQuantityFulfilled ?? 0) +
            (atdt.batchSizeFulfilled ?? 0) ?? 0);
        if (!Utility.isEmpty(selectedProduct?.documentUOMSchemaDefinition)) {
          availableQty = Utility.getUomQuantity(
            availableQty,
            selectedProduct?.documentUOMSchemaDefinition
          );
        }
        availableQty = Utility.roundOff(availableQty, QTY_ROUNDOFF_PRECISION);

        let totalQty = selectedProduct?.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              atdt?.batchSize || 0,
              selectedProduct?.documentUOMSchemaDefinition
            )
          : atdt?.batchSize || 0;
        let reservedQty = 0;
        if (props.reservedQuantitiesData) {
          props.reservedQuantitiesData.forEach((data: any) => {
            if (
              data.warehouseCode == wh.code &&
              data.advancedTracking?.serialBatchNumber ==
                atdt.serialBatchNumber &&
              data.rowCode == atdt.rowCode &&
              data.rackCode == atdt.rackCode &&
              data.binCode == atdt.binCode
            ) {
              reservedQty = data.reservedQuantity;
            }
          });
        }
        updatedRecords.push({
          selectedWarehouse: wh,
          advancedTracking: atdt,
          productCode: selectedProduct.productCode,
          warehouseCode: wh.code,
          availableQuantity: availableQty,
          reservedQuantity: reservedQty,
          totalQuantity: totalQty,
          advancedTrackingType: selectedProduct?.product?.advancedTracking,
          invalidFields: [],
          manufacturingDate: atdt.manufacturingDate ?? '',
          expiryDate: atdt.expiryDate ?? '',
          row: atdt,
          rack: atdt,
          bin: atdt,
          rowCode: atdt.rowCode,
          rackCode: atdt.rackCode,
          binCode: atdt.binCode
        });
      });
    });
    updatedRecords.sort(
      (d1: any, d2: any) =>
        DateFormatService.getDateFromStr(
          d1?.advancedTracking?.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ).getTime() -
        DateFormatService.getDateFromStr(
          d2?.advancedTracking?.expiryDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ).getTime()
    );
    setRecords([...updatedRecords]);

    return [...updatedRecords];
  };

  const updateFieldsForAutoPopulatedRecords = (selectedWarehouse: any) => {
    const availableQty = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          selectedWarehouse?.productAvailableQuantity[
            selectedProduct?.productCode
          ] -
            selectedWarehouse?.productReservedQuantity?.[
              selectedProduct?.productCode
            ],
          selectedProduct?.documentUOMSchemaDefinition
        )
      : selectedWarehouse?.productAvailableQuantity?.[
          selectedProduct?.productCode
        ] -
        selectedWarehouse?.productReservedQuantity?.[
          selectedProduct?.productCode
        ];

    const totalQty = selectedProduct?.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          selectedWarehouse?.productAvailableQuantity[
            selectedProduct?.productCode
          ],
          selectedProduct?.documentUOMSchemaDefinition
        )
      : selectedWarehouse?.productAvailableQuantity?.[
          selectedProduct?.productCode
        ];
    let returningAvailableQuantity =
      // availableQty -
      getExistiingReservedQuantityForUpdate(
        selectedProduct?.productCode,
        0,
        availableQty,
        !Utility.isEmpty(props.reservedQuantitiesData) ? true : false,
        selectedWarehouse?.code
      ) || 0;
    let obj: any = {
      availableQuantity: returningAvailableQuantity,
      totalQty: totalQty
    };
    return obj;
  };

  const updateLineItemList = (rowData: any, rowIndex: any, columnKey?: any) => {
    let editedRecords = [...records];
    let invalidFields: any = [];
    let edited = editedRecords[rowIndex];
    edited.invalidFields = edited.invalidFields
      ? [...edited.invalidFields].filter((field) => field !== columnKey)
      : [];
    if (columnKey === 'selectedWarehouse') {
      if (selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE) {
        setSerialBatchOptions(rowData?.selectedWarehouse);
      }
      edited.productCode = selectedProduct.productCode;
      edited.warehouseCode = rowData?.selectedWarehouse?.code;
      edited.warehouseName = rowData?.selectedWarehouse?.name;
      edited.selectedWarehouse = rowData.selectedWarehouse;
      // let selected = reservedQuantitiesDataOriginal.find(
      //   (item: any) => item.warehouseCode === rowData.selectedWarehouse.code
      // );
      // if (selected) {
      // edited.totalQuantity = selected.availableQuantity;
      // edited.availableQuantity =
      //   selected?.availableQuantity - selected?.reservedQuantity;
      edited.reservedQuantity = 0;
      edited.advancedTrackingType = selectedProduct?.product?.advancedTracking;
      edited.advancedTrackingMetaDtos = null;
      edited.advancedTracking = null;

      edited.row = null;
      edited.rack = null;
      edited.bin = null;
      // }
    }

    if (columnKey === 'row') {
      edited.row = rowData?.row;
      edited.rowCode = rowData?.row?.rowCode;
      edited.rack = null;
      edited.bin = null;
      edited.reservedQuantity = 0;
      if (rowData?.row) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'row'
        );
      }
      if (
        selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE &&
        edited.advancedTracking
      ) {
        edited.advancedTracking.rowName = rowData?.row?.rowName;
        edited.advancedTracking.rowCode = rowData?.row?.rowCode;
      }
    }

    if (columnKey === 'rack') {
      edited.rack = rowData?.rack;
      edited.rackCode = rowData?.rack?.rackCode;
      edited.bin = null;
      edited.reservedQuantity = 0;
      if (rowData?.rack) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'rack'
        );
      }
      if (
        selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE &&
        edited.advancedTracking
      ) {
        edited.advancedTracking.rackName = rowData?.rack?.rackName;
        edited.advancedTracking.rackCode = rowData?.rack?.rackCode;
      }
    }

    if (columnKey === 'bin') {
      edited.bin = rowData?.bin;
      edited.binCode = rowData?.bin?.binCode;
      edited.reservedQuantity = 0;
      if (rowData?.bin) {
        invalidFields = invalidFields?.filter(
          (iField: any) => iField !== 'bin'
        );
      }
      if (
        selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE &&
        edited.advancedTracking
      ) {
        edited.advancedTracking.binName = rowData?.bin?.binName;
        edited.advancedTracking.binCode = rowData?.bin?.binCode;
      }
    }

    if (columnKey === 'advancedTracking') {
      edited.advancedTracking = rowData.advancedTracking;
      edited.totalQuantity = edited?.advancedTracking.batchSize;

      // let selectedWareHouse = reservedQuantitiesDataOriginal.find(
      //   (item: any) => item.warehouseCode === rowData.selectedWarehouse.code
      // );
      // if (selectedWareHouse) {
      //   let selectedBatchSerialObj =
      //     selectedWareHouse.advancedTrackingMetaDtos?.find(
      //       (item: any) =>
      //         item.serialBatchNumber ===
      //         rowData.advancedTracking?.serialBatchNumber
      //     );
      //   edited.availableQuantity =
      //     edited.totalQuantity - selectedBatchSerialObj.reservedQuantity;
      // } else {
      //   edited.availableQuantity =
      //     edited.totalQuantity - edited?.advancedTracking.reservedQuantity;
      // }
      edited.reservedQuantity = 0;
      edited.advancedTrackingType = selectedProduct?.product?.advancedTracking;
      // edited.advancedTrackingMetaDtos = edited.advancedTracking;
      edited.row = null;
      edited.rack = null;
      edited.bin = null;
    }
    if (columnKey === 'totalQuantity') {
    }
    if (columnKey === 'availableQuantity') {
    }
    if (columnKey === 'reservedQuantity') {
      if (
        +rowData.reservedQuantity <= selectedProduct.productQuantity &&
        +rowData.reservedQuantity <= rowData.availableQuantity
      ) {
        edited.reservedQuantity = +rowData.reservedQuantity;
        if (
          selectedProduct?.product?.advancedTracking !== TRACKING_TYPE.NONE &&
          edited.advancedTracking
        ) {
          edited.advancedTracking.reservedQuantity = +rowData.reservedQuantity;
        }
      } else {
        edited.invalidFields.push('reservedQuantity');
      }
    }
    let rrbDtoForProduct;
    if (selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE) {
      rrbDtoForProduct =
        WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
          rowData?.selectedWarehouse?.rowRackBinProductAvailableQuantityDtos,
          selectedProduct?.productCode
        );
    } else {
      if (rowData?.selectedWarehouse && rowData?.advancedTracking) {
        const filteredDto =
          rowData?.selectedWarehouse?.advancedTrackingMeta?.filter(
            (atDt: any) =>
              atDt.serialBatchNumber ===
              rowData?.advancedTracking?.serialBatchNumber
          );
        rrbDtoForProduct = [...filteredDto];
      }
    }
    //rows
    const uniqueRows = WarehouseManagementHelper.getUniqueRows(
      rrbDtoForProduct ?? [],
      rowData['rack']?.rackCode,
      rowData['bin']?.binCode
    );
    //racks
    const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
      rrbDtoForProduct ?? [],
      rowData['row']?.rowCode,
      rowData['bin']?.binCode
    );
    //bins
    const uniqueBins = WarehouseManagementHelper.getUniqueBins(
      rrbDtoForProduct ?? [],
      rowData['row']?.rowCode,
      rowData['rack']?.rackCode
    );

    // For normal tracked
    edited.totalQuantity = getTotalQuantity(
      rrbDtoForProduct,
      rowData['row']?.rowCode,
      rowData['rack']?.rackCode,
      rowData['bin']?.binCode
    );

    edited.availableQuantity = getAvailableQuantity(
      rrbDtoForProduct,
      rowData['row']?.rowCode,
      rowData['rack']?.rackCode,
      rowData['bin']?.binCode
    );

    //invlidfields for rrb
    if (!Utility.isEmpty(uniqueRows) && Utility.isEmpty(rowData['row'])) {
      invalidFields.push('row');
    }
    if (!Utility.isEmpty(uniqueRacks) && Utility.isEmpty(edited['rack'])) {
      invalidFields.push('rack');
    }
    if (!Utility.isEmpty(uniqueBins) && Utility.isEmpty(edited['bin'])) {
      invalidFields.push('bin');
    }

    edited['invalidFields'] = invalidFields;

    if (
      !invalidFields?.includes('row') ||
      !invalidFields?.includes('rack') ||
      !invalidFields?.includes('bin')
    ) {
      if (!Utility.isEmpty(rrbDtoForProduct)) {
        edited.totalQuantity = getTotalQuantity(
          rrbDtoForProduct,
          rowData['row']?.rowCode,
          rowData['rack']?.rackCode,
          rowData['bin']?.binCode
        );

        edited.availableQuantity = getAvailableQuantity(
          rrbDtoForProduct,
          rowData['row']?.rowCode,
          rowData['rack']?.rackCode,
          rowData['bin']?.binCode
        );
      } else {
        const availableQty = selectedProduct?.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              rowData?.selectedWarehouse?.productAvailableQuantity[
                selectedProduct?.productCode
              ] -
                rowData?.selectedWarehouse?.productReservedQuantity?.[
                  selectedProduct?.productCode
                ],
              selectedProduct?.documentUOMSchemaDefinition
            )
          : rowData?.selectedWarehouse?.productAvailableQuantity?.[
              selectedProduct?.productCode
            ] -
            rowData?.selectedWarehouse?.productReservedQuantity?.[
              selectedProduct?.productCode
            ];

        const totalQty = selectedProduct?.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              rowData?.selectedWarehouse?.productAvailableQuantity[
                selectedProduct?.productCode
              ],
              selectedProduct?.documentUOMSchemaDefinition
            )
          : rowData?.selectedWarehouse?.productAvailableQuantity?.[
              selectedProduct?.productCode
            ];
        edited.availableQuantity =
          // availableQty -
          getExistiingReservedQuantityForUpdate(
            selectedProduct?.productCode,
            rowData.reservedQuantity,
            availableQty,
            !Utility.isEmpty(props.reservedQuantitiesData) ? true : false,
            rowData?.selectedWarehouse?.code
          ) || 0;
        edited.totalQuantity = totalQty;
      }
    }

    editedRecords[rowIndex] = edited;
    updateConfig(
      rowData['selectedWarehouse'],
      rowData['advancedTracking'],
      rowData['row'],
      rowData['rack'],
      rowData['bin']
    );
    setRecords(editedRecords);
  };

  const getExistiingReservedQuantity = (productCode: any) => {
    let reservedData = props.itemsData.flatMap(
      (ele: any) => ele.reservedQuantitiesData
    );
    let filterdData = reservedData?.filter(
      (ele: any) => ele?.productCode === selectedProduct?.productCode
    );
    let existingReservedQty = filterdData?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + currentValue.reservedQuantity;
      },
      0
    );
    return existingReservedQty || 0;
  };
  const getExistiingReservedQuantityForUpdate = (
    productCode: any,
    reservedQty: any,
    availableqty: any,
    isSubtract?: boolean,
    whCode?: any
  ) => {
    let reservedData = props.itemsData.flatMap(
      (ele: any) => ele.reservedQuantitiesData
    );
    let filterdData = reservedData?.filter(
      (ele: any) =>
        ele?.productCode === selectedProduct?.productCode &&
        ele?.warehouseCode === whCode
    );
    let existingReservedQty = filterdData?.reduce(
      (accumulator: any, currentValue: any) => {
        return accumulator + currentValue.reservedQuantity;
      },
      0
    );
    if (filterdData?.length > 0) {
      if (existingReservedQty) {
        if (isSubtract) {
          return (
            availableqty - (existingReservedQty - reservedQty) ?? availableqty
          );
        } else {
          return availableqty - existingReservedQty ?? availableqty;
        }
      } else {
        return availableqty;
      }
    } else {
      return availableqty;
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const onDelete = (data: any) => {
    let recordsCopy = [...records];
    recordsCopy.splice(data.rowIndex, 1);
    setRecords([...recordsCopy]);
  };

  const getDataGrid = () => (
    <div className="column parent-width mt-m">
      <DKDataGrid
        title={''}
        allowRowEdit={true}
        allowColumnSort={false}
        allowColumnEdit={false}
        headerButtons={[]}
        allowDataExport={true}
        columns={columnConfig}
        rows={getRowData()}
        allowBulkOperation={false}
        needTrailingColumn={true}
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
      />
    </div>
  );

  const getAllWarehouseTotalBatchCount = () => {
    let totalCount = 0;
    reservedQuantitiesDataOriginal.forEach((stockObj) => {
      if (
        stockObj.advancedTrackingMetaDtos &&
        stockObj.advancedTrackingMetaDtos.length > 0
      ) {
        stockObj.advancedTrackingMetaDtos.filter((obj: any) => {
          if (Number(obj.batchSize) > Number(obj.reservedQuantity)) {
            totalCount += 1;
          }
        });
      }
    });
    return totalCount;
  };

  const isAddButtonVisible = () => {
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );
    let productQty = selectedProduct.productQuantity;
    if (
      selectedProduct?.documentUOMSchemaDefinition &&
      selectedProduct?.product?.advancedTracking === TRACKING_TYPE.SERIAL
    ) {
      productQty = getUomQtyIntoBaseQtyDecimal(
        selectedProduct.productQuantity,
        selectedProduct?.documentUOMSchemaDefinition
      );
    }
    if (
      !rrbEnabled &&
      selectedProduct?.product?.advancedTracking === TRACKING_TYPE.NONE &&
      warehousesList?.length === records?.length
    ) {
      return false;
    }

    let reservedAlready = records.reduce(function (add: any, current: any) {
      return add + current.reservedQuantity;
    }, 0);

    let recordsWithZeroOrNegativeReservedQty = records.some(
      (record: any) => +record.reservedQuantity <= 0
    );

    let recordsHasInvalidFields =
      records.findIndex((record: any) => record.invalidFields?.length > 0) !==
      -1;

    if (
      reservedAlready >= productQty ||
      recordsWithZeroOrNegativeReservedQty ||
      recordsHasInvalidFields
    ) {
      return false;
    }
    if (
      reservedAlready < productQty &&
      warehousesList?.length > records?.length
    ) {
      return true;
    }
    // let availableQuantity = records.reduce(function (add: any, current: any) {
    //   return add + current.availableQuantity;
    // }, 0);

    return true;
    // if ()
  };

  const isShowError = () => {
    let reservedAlready = records?.reduce(function (add: number, current: any) {
      return add + Number(current.reservedQuantity);
    }, 0);

    reservedAlready = Utility.roundingOff(
      reservedAlready,
      QTY_ROUNDOFF_PRECISION
    );

    if (
      selectedProduct?.product?.advancedTracking === TRACKING_TYPE.SERIAL &&
      selectedProduct?.documentUOMSchemaDefinition
    ) {
      if (
        reservedAlready !==
        getUomQtyIntoBaseQtyDecimal(
          selectedProduct.productQuantity,
          selectedProduct.documentUOMSchemaDefinition
        )
      ) {
        return true;
      }
      return false;
    }
    if (reservedAlready !== selectedProduct.productQuantity) {
      return true;
    }
    return false;
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: popupWidth,
            maxWidth: '90%',
            maxHeight: '90%',
            height: 'auto',
            minHeight: 300,
            padding: 0,
            paddingBottom: 10,
            overflowY: 'auto'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-4 overflow-y-visible">
            {getProductDetailsView}
            {isShowError() && (
              <div className="row text-red gap-2 mt-m" style={{ height: 20 }}>
                <DKIcon src={DKIcons.ic_warning_red} className="ic-s" />
                <DKLabel text="Quantity should be equal to product quantity mentioned in document." />
              </div>
            )}
            {selectionHasDuplicates() && (
              <div className="row text-red gap-2 mt-s" style={{ height: 20 }}>
                <DKIcon src={DKIcons.ic_warning_red} className="ic-s" />
                <DKLabel text="Duplicate records are not allowed." />
              </div>
            )}
            {getDataGrid()}
            {isAddButtonVisible() && (
              <DKButton
                title="Add Record"
                className="bg-button text-white"
                onClick={() => {
                  let emptyWarehouseObject = {
                    selectedWarehouse: null,
                    advancedTracking: null,
                    availableQuantity: 0,
                    reservedQuantity: 0,
                    totalQuantity: 0
                  };
                  addRow(emptyWarehouseObject);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
