import { MODULE_TYPE } from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import BatchTrackingReceive from '../../../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingReceive';
import SerialTrackedReceive from '../../../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedReceive';
import NoneTrackedReceive from '../../../../../SharedComponents/WarehouseManagement/NoneTrackedReceive';
import Utility from '../../../../../Utility/Utility';
import { useEffect } from 'react';
import { fetchSerialTrackingProducts } from '../../../../../Redux/Slices/SerialTrackingSlice';
import { useAppDispatch } from '../../../../../Redux/Hooks';

export default function GetReceivePopupForTargetWarehouseWO(props: any) {
  const selectedTrackingType =
    props?.workOrderObject?.product?.advancedTracking;
  const WOObjectproductCode = props.workOrderObject?.productCode;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (WOObjectproductCode) {
      try {
        switch (selectedTrackingType) {
          case ADVANCE_TRACKING.SERIAL:
            dispatch(
              fetchSerialTrackingProducts({
                productCode: WOObjectproductCode,
                enableQCWarehouse: false
              })
            );
            break;
          default:
        }
      } catch (err) {}
    }
  }, [selectedTrackingType, WOObjectproductCode, dispatch]);

  return (
    <>
      {props?.workOrderObject?.product?.advancedTracking ===
        ADVANCE_TRACKING.NONE && (
        <NoneTrackedReceive
          isMRP={false}
          disableAddRow={false}
          details={{
            ...props?.workOrderObject?.product,
            quantityFulfilled: props?.workOrderObject.actualQuantity,
            productCode: props?.workOrderObject.productCode,
            productQuantity: props?.workOrderObject.actualQuantity,
            product: {
              ...props?.workOrderObject?.product
            },
            warehouseInventoryData:
              props?.workOrderObject?.warehouseInventoryData?.map(
                (noneItem: any) => {
                  let copyNoneItem = { ...noneItem };
                  if (
                    Utility.isEmpty(copyNoneItem?.rowName) &&
                    Utility.isEmpty(copyNoneItem?.rowCode)
                  ) {
                    copyNoneItem.row = null;
                  } else {
                    copyNoneItem.row = {
                      name: copyNoneItem?.rowName,
                      code: copyNoneItem?.rowCode
                    };
                  }
                  if (
                    Utility.isEmpty(copyNoneItem?.rackName) &&
                    Utility.isEmpty(copyNoneItem?.rackCode)
                  ) {
                    copyNoneItem.rack = null;
                  } else {
                    copyNoneItem.rack = {
                      name: copyNoneItem?.rackName,
                      code: copyNoneItem?.rackCode
                    };
                  }
                  if (
                    Utility.isEmpty(copyNoneItem?.binName) &&
                    Utility.isEmpty(copyNoneItem?.binCode)
                  ) {
                    copyNoneItem.bin = null;
                  } else {
                    copyNoneItem.bin = {
                      name: copyNoneItem?.binName,
                      code: copyNoneItem?.binCode
                    };
                  }
                  return copyNoneItem;
                }
              )
          }}
          defaultProductWarehouse={props?.workOrderObject?.targetWarehouse}
          targetWarehouse={
            !props?.showCompleteWorkOrderPopup
              ? props?.workOrderObject?.targetWarehouse?.code ?? ''
              : Utility.isWarehouseTaggingEnabled()
              ? props?.workOrderObject?.targetWarehouse?.code
              : ''
          }
          isSearchAllow={
            !props?.showCompleteWorkOrderPopup
              ? true
              : Utility.isWarehouseTaggingEnabled()
              ? false
              : true
          }
          isRRBTaggingEnabled={
            props?.showCompleteWorkOrderPopup
              ? false
              : Utility.isRRBTaggingEnabled()
          }
          onSave={(data: any, isQuickCommit: boolean, quantity: any) => {
            const updatedWarehouseInventoryData = data?.map(
              (normalTrackedItem: any) => {
                return {
                  advancedTrackingData: [],
                  quantity: normalTrackedItem?.quantity,
                  warehouseCode: normalTrackedItem?.warehouseCode,
                  warehouseName:
                    normalTrackedItem?.warehouseName ??
                    normalTrackedItem?.warehouse?.name,
                  rowName: normalTrackedItem?.row?.name,
                  rowCode: normalTrackedItem?.row?.code,
                  rackName: normalTrackedItem?.rack?.name,
                  rackCode: normalTrackedItem?.rack?.code,
                  binName: normalTrackedItem?.bin?.name,
                  binCode: normalTrackedItem?.bin?.code
                };
              }
            );
            props.onNoneSave(updatedWarehouseInventoryData);
          }}
          onCancel={() => {
            props.onClose();
          }}
        ></NoneTrackedReceive>
      )}
      {props?.workOrderObject?.product?.advancedTracking ===
        ADVANCE_TRACKING.SERIAL && (
        <SerialTrackedReceive
          isMRP={false}
          module={MODULE_TYPE.BUY}
          itemDetails={{
            ...props?.workOrderObject?.product,
            productQuantity: props?.workOrderObject.actualQuantity,
            product: {
              ...props?.workOrderObject?.product
            },
            productCode: props?.workOrderObject.productCode,
            advancedTrackingMetaData:
              props?.workOrderObject.advancedTrackingData
          }}
          targetWarehouse={
            !props?.showCompleteWorkOrderPopup
              ? props?.workOrderObject?.targetWarehouse?.code ?? ''
              : Utility.isWarehouseTaggingEnabled()
              ? props?.workOrderObject?.targetWarehouse?.code
              : ''
          }
          isSearchAllow={
            !props?.showCompleteWorkOrderPopup
              ? true
              : Utility.isWarehouseTaggingEnabled()
              ? false
              : true
          }
          isRRBTaggingEnabled={
            props?.showCompleteWorkOrderPopup
              ? false
              : Utility.isRRBTaggingEnabled()
          }
          onSerialSave={(data: any, isQuickCommit: boolean, quantity: any) => {
            const updatedAdvancedTrackingData = data?.map((serialItem: any) => {
              return { ...serialItem, qtyToFulfil: 1 };
            });
            props.onSerialSave(updatedAdvancedTrackingData);
          }}
          onClose={() => {
            props.onClose();
          }}
        ></SerialTrackedReceive>
      )}
      {props?.workOrderObject?.product?.advancedTracking ===
        ADVANCE_TRACKING.BATCH && (
        <BatchTrackingReceive
          isMrpFlow={true}
          module={MODULE_TYPE.BUY}
          itemDetails={{
            ...props?.workOrderObject?.product,
            requiredQuantity: props?.workOrderObject?.actualQuantity,

            product: {
              name: props?.workOrderObject?.product?.name,
              stockUom: props?.workOrderObject?.product.uomId,
              availableQuantiy:
                props?.workOrderObject?.product.availableQuantity
            },
            advancedTrackingMetaData:
              props?.workOrderObject?.advancedTrackingData
          }}
          targetWarehouse={
            !props?.showCompleteWorkOrderPopup
              ? props?.workOrderObject?.targetWarehouse?.code ?? ''
              : Utility.isWarehouseTaggingEnabled()
              ? props?.workOrderObject?.targetWarehouse?.code
              : ''
          }
          isSearchAllow={
            !props?.showCompleteWorkOrderPopup
              ? true
              : Utility.isWarehouseTaggingEnabled()
              ? false
              : true
          }
          isRRBTaggingEnabled={
            props?.showCompleteWorkOrderPopup
              ? false
              : Utility.isRRBTaggingEnabled()
          }
          onBatchSave={(data: any, quantity: any) => {
            const advanceTrackingData = data?.map((batchItem: any) => {
              return { ...batchItem, qtyToFulfil: batchItem.batchSize };
            });
            props.onBatchSave(advanceTrackingData);
          }}
          onClose={() => {
            props.onClose();
          }}
        ></BatchTrackingReceive>
      )}
    </>
  );
}
