import {
  DKButton,
  DKCheckMark,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { ROW_RACK_BIN_CONSTANT } from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { selectSerialTrackingProduct } from '../../Redux/Slices/SerialTrackingSlice';
import Utility, { deepClone, sortSerialNumbers } from '../../Utility/Utility';

import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { DynamicPopupWrapper } from '../PopupWrapper';
import WarehouseManagementHelper from '../WarehouseManagement/WarehouseManagementHelper';
import ProductService from '../../Services/Product';

export default function SerialTrackedAssignment(props: any) {
  const selectSerialTrackingProductData = useAppSelector(
    selectSerialTrackingProduct
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [item, setItem] = useState(props.itemDetails);
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const [currentSelectedWarehouse, setCurrentSelectedWarehouse] = useState(
    props.defaultProductWarehouse
  );
  const [availableSerialData, setAvailableSerialData] = useState<any[]>([]);
  const [allocatedSerialData, setAllocatedSerialData] = useState<any[]>([]);
  const [allocatedTapped, setAllocatedTapped] = useState(false);
  const [isAllAvailableSerialSelected, setIsAllAvailableSerialSelected] =
    useState(false);
  const [isAllSerialSelected, setIsAllSerialSelected] = useState(false);
  const [selectedSerial, setSelectedSerial] = useState<any[]>([]);
  const [serialData, setSerialData] = useState<any[]>([]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    item?.productQuantity
  );
  const [advancedTrackingMetaData, setAdvancedTrackingMetaData] = useState(
    item && item.advancedTrackingMetaData
      ? [...item.advancedTrackingMetaData]
      : []
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [totalAllocatedQty, setTotalAllocatedQty] = useState(0);

  //rrb states
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [rowRackBinData, setRowRackBinData] = useState<any>({});
  const [selectedFilters, setSelectedFilters] = useState<any>({
    warehouse: { code: '', name: '' },
    row: { code: '', name: '' },
    rack: { code: '', name: '' },
    bin: { code: '', name: '' }
  });

  const [rrbEnabledInSelectedWH, setRrbEnabledInSelectedWH] = useState(false);
  const [isChangedRRBSelection, setIsChangedRRBSelection] = useState(false);
  const [productDetails, setProductDetails] = useState<any>();
  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let copyRowRackBinData = { ...rowRackBinData };
      tenantInfo?.additionalSettings?.ROW_RACK_BIN?.forEach((element: any) => {
        if (element.name === ROW_RACK_BIN_CONSTANT.ROW) {
          copyRowRackBinData.rowData = element;
        }
        if (element.name === ROW_RACK_BIN_CONSTANT.RACK) {
          copyRowRackBinData.rackData = element;
        }
        if (element.name === ROW_RACK_BIN_CONSTANT.BIN) {
          copyRowRackBinData.binData = element;
        }
      });
      setRowRackBinData(copyRowRackBinData);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(localWarehouse) && !Utility.isEmpty(productDetails)) {
      let selectedWarehouse: any = {};
      let copySelectedFilters: any = {};

      if (localWarehouse?.length === 1) {
        selectedWarehouse = localWarehouse[0];
        copySelectedFilters = {
          warehouse: {
            code: selectedWarehouse?.code,
            name: selectedWarehouse?.name
          }
        };
      } else {
        selectedWarehouse = localWarehouse?.find(
          (warehouse: any) =>
            warehouse.code === productDetails?.inventory?.warehouseCode
        );
        if (Utility.isEmpty(selectedWarehouse)) {
          selectedWarehouse = localWarehouse?.find(
            (warehouse: any) => warehouse?.primary
          );
        }
        if (Utility.isEmpty(selectedWarehouse)) {
          selectedWarehouse = localWarehouse?.[0];
        }
        copySelectedFilters = {
          warehouse: {
            code: selectedWarehouse?.code,
            name: selectedWarehouse?.name,
            rowCode: productDetails?.inventory?.rowCode,
            rowName: productDetails?.inventory?.rowName,
            rackCode: productDetails?.inventory?.rackCode,
            rackName: productDetails?.inventory?.rackName,
            binCode: productDetails?.inventory?.binCode,
            binName: productDetails?.inventory?.binName
          }
        };
      }
      onWarehouseSelection(selectedWarehouse, copySelectedFilters);
      setSelectedFilters(copySelectedFilters);
      setCurrentSelectedWarehouse(selectedWarehouse);
    }
  }, [localWarehouse, productDetails]);

  useEffect(() => {
    setTotalAllocatedQty(allocatedSerialData.length);
    setIsAllSerialSelected(
      allocatedSerialData?.every((data: any) => data.allocated)
    );
  }, [allocatedSerialData]);

  const fetchProductDetails = () => {
    ProductService.getProductsByProductIds([props.itemDetails?.productCode])
      .then((res: any) => {
        setProductDetails(res?.[0]);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    fetchProductDetails();
    if (!Utility.isEmpty(props.itemDetails)) {
      let advancedTrackingFulfilmentData =
        props?.itemDetails?.advancedTrackingFulfilmentData;
      if (
        !props?.isMRP &&
        !Utility.isEmpty(props?.itemDetails?.reservedQuantitiesData) &&
        Utility.isEmpty(props?.itemDetails?.advancedTrackingFulfilmentData)
      ) {
        advancedTrackingFulfilmentData =
          props?.itemDetails?.reservedQuantitiesData.flatMap(
            (ele: any) => ele.advancedTrackingMetaDtos
          );
        advancedTrackingFulfilmentData = advancedTrackingFulfilmentData.filter(
          (ele: any) => ele.reservedQuantityFulfilled < ele.batchSize
        );
        advancedTrackingFulfilmentData.forEach((element: any, index: any) => {
          getAllocatedSerial(element, index);
        });
        const newArr1 =
          advancedTrackingFulfilmentData.map((v: any) => ({
            ...v,
            allocated: true,
            selected: true
          })) || [];
        setAllocatedSerialData(newArr1);
        setSerialData(newArr1);
      }
      setAllocatedSerialData(
        advancedTrackingFulfilmentData?.map((v: any) => ({
          ...v,
          allocated: true,
          selected: true
        })) || []
      );
    }
  }, []);

  useEffect(() => {
    // resetAllocation();
    let allWarehouseData: any[] = [];
    if (props.taggedWarehouse) {
      allWarehouseData = selectSerialTrackingProductData.filter((item: any) => {
        return item.code === props.taggedWarehouse?.code;
      });
      if (
        Utility.isBinAllocationMandatory() &&
        Utility.isNotEmpty(allWarehouseData)
      ) {
        let filteredAdvancedTrackData =
          allWarehouseData?.[0]?.advancedTrackingMeta?.filter(
            (ele: any) =>
              ele.rowCode == props.taggedWarehouse?.rowCode &&
              ele.rackCode == props.taggedWarehouse?.rackCode &&
              ele.binCode == props.taggedWarehouse?.binCode
          );
        const taggedRRBDetails =
          allWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
            (RRBItem: any) =>
              RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
              RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
              RRBItem.binCode == props.taggedWarehouse?.binCode
          );
        allWarehouseData = [
          {
            ...allWarehouseData[0],
            rowRackBinProductAvailableQuantityDtos: taggedRRBDetails,
            advancedTrackingMeta: filteredAdvancedTrackData ?? []
          }
        ];
      }
    } else if (props.targetWarehouse) {
      allWarehouseData = selectSerialTrackingProductData.filter((item: any) => {
        return WarehouseManagementHelper.isRRBEnabledForWarehouse(item)
          ? item
          : item.code !== props.targetWarehouse;
      });
    } else {
      allWarehouseData = selectSerialTrackingProductData;
    }
    if (!Utility.isEmpty(allWarehouseData)) {
      if (props?.isMRP && props?.filterBatchData) {
        allWarehouseData = filterWarehouseInventoryData(allWarehouseData);
      }
      setLocalWarehouse(allWarehouseData);
    }
  }, [selectSerialTrackingProductData]);

  useEffect(() => {
    let updateSerial: any[] = [];
    let serialData: any[] = [];
    if (Utility.isEmpty(availableSerialData) && !allocatedTapped) {
      let inventoryWarehouse = localWarehouse.find(
        (warehouse: any) => warehouse.code === selectedWarehouse.code
      );
      let result = inventoryWarehouse?.advancedTrackingMeta?.map(
        (item: any) => {
          return { ...item, warehouseName: inventoryWarehouse?.name };
        }
      );
      serialData = getAvailableStockData(result);
      updateSerial = serialData;
      if (
        props.itemDetails &&
        props.itemDetails.advancedTrackingFulfilmentData &&
        props.itemDetails.advancedTrackingFulfilmentData.length > 0 &&
        serialData &&
        serialData.length > 0
      ) {
        const remainingAvailableSerial = serialData.filter(
          (e: any) =>
            !props.itemDetails.advancedTrackingFulfilmentData.some(
              (a: any) => a.serialBatchNumber === e.serialBatchNumber
            )
        );
        const allocatedAvailableSerial = serialData.filter((e: any) =>
          props.itemDetails.advancedTrackingFulfilmentData.some((a: any) => {
            if (a.serialBatchNumber === e.serialBatchNumber) {
              return {
                ...e,
                selected: !e?.selected
              };
            }
          })
        );

        updateSerial = remainingAvailableSerial;
        if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
          const newArr1 =
            allocatedAvailableSerial.map((v) => ({
              ...v,
              allocated: true,
              selected: true
            })) || [];
          setAllocatedSerialData(newArr1);
          setSerialData(newArr1);
          setTotalAllocatedQty(newArr1.length);
        }
      }

      if (updateSerial && updateSerial.length > 0) {
        setAvailableSerialData(sortSerialNumbers(updateSerial));
      }
    }
  }, [availableSerialData, localWarehouse]);

  const filterWarehouseInventoryData = (allWarehouseData: any[]) => {
    let filteredWHData: any[] = [];
    allWarehouseData?.forEach((localWH: any) => {
      let sameWHAdvancedTrackData: any = [];
      props?.filteredWarehouseInventoryData?.warehouseInventoryData?.forEach(
        (whData: any) => {
          if (whData.warehouseCode === localWH.code) {
            localWH?.advancedTrackingMeta?.forEach((x: any) => {
              const found = whData?.advancedTrackingData?.find((y: any) => {
                return x.serialBatchNumber === y.serialBatchNumber;
              });
              if (!Utility.isEmpty(found)) {
                sameWHAdvancedTrackData.push(x);
              }
            });
          }
        }
      );
      const isWarehousePresent =
        props?.filteredWarehouseInventoryData?.warehouseInventoryData?.some(
          (item: any) => item.warehouseCode === localWH.code
        );
      if (isWarehousePresent) {
        localWH = {
          ...localWH,
          advancedTrackingMeta: sameWHAdvancedTrackData
        };
        filteredWHData.push(localWH);
      }
    });
    const arrayUniqueByKey = filteredWHData.filter(
      (a, i) => filteredWHData.findIndex((s: any) => a.code === s.code) === i
    );
    return arrayUniqueByKey;
  };
  const getRequireQuantity = () => {
    return props.itemDetails.documentUOMSchemaDefinition
      ? Utility.getUomWarehouseQuantityWithoutRoundOff(
          item?.productQuantity
            ? item?.productQuantity
            : props.itemDetails.productQuantity,
          props.itemDetails.documentUOMSchemaDefinition
        )
      : item?.productQuantity
      ? item?.productQuantity
      : props.itemDetails.productQuantity;
  };

  const selectAllAvailableSerial = () => {
    const localData = deepClone(availableSerialData);
    const serialTrackingData = localData.map((item) => {
      return {
        ...item,
        selected: !isAllAvailableSerialSelected
      };
    });
    setIsAllAvailableSerialSelected(!isAllAvailableSerialSelected);
    setSelectedSerial(serialTrackingData);
    setAvailableSerialData(sortSerialNumbers(serialTrackingData));
  };

  const selectAllSelectedSerial = () => {
    const localData = deepClone(allocatedSerialData);
    const selectedSerialNumbers =
      props?.itemDetails?.reservedQuantitiesData?.map(
        (item: any) => item?.serialBatchNumber
      ) || [];
    const serialTrackingData =
      localData.map((item) => {
        if (
          !(
            props.isMRP &&
            selectedSerialNumbers.includes(item.serialBatchNumber)
          )
        ) {
          return {
            ...item,
            allocated: !isAllSerialSelected
          };
        }
        return item;
      }) || [];
    setIsAllSerialSelected(!isAllSerialSelected);
    setSerialData(serialTrackingData);
    setAllocatedSerialData(serialTrackingData);
  };

  const allocateSerialNumber = () => {
    const localData = deepClone(selectedSerial);
    const selectedSerialNumber = localData.filter(
      (option: any) => option.selected
    );
    let getSelectedSerialNumber = [
      ...allocatedSerialData,
      ...selectedSerialNumber
    ];

    getSelectedSerialNumber =
      getSelectedSerialNumber.filter(
        (value, index, self) =>
          self.findIndex(
            (m) => m.serialBatchNumber === value.serialBatchNumber
          ) === index
      ) || [];
    getSelectedSerialNumber = getSelectedSerialNumber.map((item: any) => {
      return {
        ...item,
        allocated: true,
        selected: true
      };
    });
    const updatedSerialData = selectedSerial.filter(
      (x) =>
        !selectedSerialNumber.filter(
          (y) => y.serialBatchNumber === x.serialBatchNumber
        ).length
    );

    setAllocatedTapped(true);
    setIsAllAvailableSerialSelected(false);
    setAllocatedSerialData((prevState: any[]) => getSelectedSerialNumber);
    setSerialData([...getSelectedSerialNumber]);
    setAvailableSerialData(sortSerialNumbers(updatedSerialData));
  };

  const getSelectedSerial = (item: any, index: any) => {
    const localData = [...availableSerialData];
    localData[index] = {
      ...localData[index],
      selected: !localData[index]?.selected
    };
    setSelectedSerial(localData);
    setAvailableSerialData(sortSerialNumbers(localData));
  };

  const isFilterOnForISsue = (warehouse: any, filters: any) => {
    let result = true;
    if (!Utility.isEmpty(warehouse?.warehouseRowInfos)) {
      if (Utility.isEmpty(filters?.row?.code)) {
        result = false;
        return false;
      }
    }
    if (!Utility.isEmpty(warehouse?.warehouseRackInfos)) {
      if (Utility.isEmpty(filters?.rack?.code)) {
        result = false;
        return false;
      }
    }
    if (!Utility.isEmpty(warehouse?.warehouseBinInfos)) {
      if (Utility.isEmpty(filters?.bin?.code)) {
        result = false;
        return false;
      }
    }
    return result;
  };

  const onWarehouseSelection = (warehouse: any, filters: any) => {
    let selectedWarehouse = { ...warehouse };
    setCurrentSelectedWarehouse(selectedWarehouse);
    let inventoryWarehouse = localWarehouse.find(
      (warehouse: any) => warehouse.code === selectedWarehouse.code
    );
    //set if rrb enabled in selected warehouse
    setRrbEnabledInSelectedWH(
      WarehouseManagementHelper.isRRBEnabledForWarehouse(inventoryWarehouse)
    );
    // ischangedRRRBSelection is used for to reset saved allocation if it is previously saved.
    if (
      isChangedRRBSelection ||
      Utility.isEmpty(props?.itemDetails?.advancedTrackingFulfilmentData)
    ) {
      if (props.isFromStockIssue && !isFilterOnForISsue(warehouse, filters)) {
        setAvailableSerialData([]);
        return;
      }
    }

    let updateSerial: any[] = [];
    let serialData: any[] = [];

    //set if rrb enabled in selected warehouse
    setRrbEnabledInSelectedWH(
      WarehouseManagementHelper.isRRBEnabledForWarehouse(inventoryWarehouse)
    );
    let result = inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
      return { ...item, warehouseName: inventoryWarehouse?.name };
    });
    if (props?.isMRP && props?.filterBatchData) {
      serialData = result?.filter((searchItem: any) =>
        searchItem?.serialBatchNumber
          ?.toLowerCase()
          ?.includes(filters?.searchText?.toLowerCase() || '')
      );
    } else {
      serialData = getAvailableStockData(result);
      serialData = serialData.filter(
        (serial: any) =>
          (filters?.row?.code ? filters?.row?.code === serial.rowCode : true) &&
          (filters?.rack?.code
            ? filters?.rack?.code === serial.rackCode
            : true) &&
          (filters?.bin?.code ? filters?.bin?.code === serial.binCode : true) &&
          (filters?.searchText
            ? serial?.serialBatchNumber
                ?.toLowerCase()
                .includes(filters.searchText?.toLowerCase())
            : true)
      );
    }

    updateSerial = serialData;
    if (
      props.itemDetails &&
      props.itemDetails.advancedTrackingFulfilmentData &&
      props.itemDetails.advancedTrackingFulfilmentData.length > 0 &&
      serialData &&
      serialData.length > 0 &&
      !isChangedRRBSelection
    ) {
      const remainingAvailableSerial = serialData.filter(
        (e: any) =>
          !props.itemDetails.advancedTrackingFulfilmentData.some(
            (a: any) => a.serialBatchNumber === e.serialBatchNumber
          )
      );
      const allocatedAvailableSerial = serialData.filter((e: any) =>
        props.itemDetails.advancedTrackingFulfilmentData.some((a: any) => {
          if (a.serialBatchNumber === e.serialBatchNumber) {
            return {
              ...e,
              selected: !e?.selected,
              qtyToFulfil: a.qtyToFulfil
            };
          }
        })
      );

      updateSerial = remainingAvailableSerial;
      if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
        const newArr1 =
          allocatedAvailableSerial.map((v) => ({
            ...v,
            allocated: true,
            selected: true,
            qtyToFulfil: v.batchSize
          })) || [];
        // setAllocatedSerialData(newArr1);
        // setSerialData(newArr1);
        setTotalAllocatedQty(newArr1.length);
      }
    }

    if (updateSerial && updateSerial.length > 0) {
      const finalAvailableSerials = updateSerial.filter(
        (e: any) =>
          !allocatedSerialData.some(
            (a: any) => a.serialBatchNumber === e.serialBatchNumber
          )
      );
      setAvailableSerialData(sortSerialNumbers(finalAvailableSerials));
    } else {
      setAvailableSerialData([]);
    }
  };

  const getAllocatedSerial = (item: any, index: any) => {
    const localData = [...allocatedSerialData];
    localData[index] = {
      ...localData[index],
      allocated: !localData[index]?.allocated
    };
    const totalAllocatedQuantity: any[] = localData.filter(
      (option: any) => option.allocated
    );
    setTotalAllocatedQty(totalAllocatedQuantity.length);
    setSerialData(localData);
    setAllocatedSerialData(localData);
  };

  const getActiveWarehouses = () => {
    if (!Utility.isEmpty(localWarehouse)) {
      return localWarehouse.map((item: any) => item.name);
    } else {
      return [];
    }
  };

  const onSave = () => {
    if (props.onSerialSave) {
      const localSerialData = serialData.filter((item: any) => item.allocated);
      let serialTrackingData: any[] = [];
      serialTrackingData = localSerialData.map((item, i) => {
        let object = {
          qtyToFulfil: 1,
          batchSize: item?.batchSize,
          costPerUnit: Utility.roundOffToTenantDecimalScale(
            Number(item?.acquiredCost) / Number(item?.batchSize)
          ),
          serialBatchNumber: item.serialBatchNumber,
          warehouseCode: item.warehouseCode,
          warehouseName: item.warehouseName,
          destinationWarehouseCode: item?.destinationWarehouseCode,
          destinationWarehouseName: item?.destinationWarehouseName,
          rowCode: item?.rowCode ?? null,
          rowName: item?.rowName ?? null,
          rackCode: item?.rackCode ?? null,
          rackName: item?.rackName ?? null,
          binCode: item?.binCode ?? null,
          binName: item?.binName ?? null
        };
        return {
          ...object,
          advancedTrackingData: [{ ...object }]
        };
      });

      if (!Number.isInteger(requiredQuantity)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as required quantity in decimal.'
        );
        return;
      }
      if (props.itemDetails.documentUOMSchemaDefinition) {
        const uomQty = Utility.getUomQuantityWithoutRoundOff(
          serialTrackingData.length,
          props.itemDetails.documentUOMSchemaDefinition
        );
        if (!Number.isInteger(uomQty)) {
          showAlert(
            'Error',
            'Fulfillment is not allowed for this product as uom conversion in decimal.'
          );
          return;
        }
      }
      const reqQty = props.itemDetails.documentUOMSchemaDefinition
        ? Utility.getUomWarehouseQuantity(
            item?.productQuantity,
            props.itemDetails.documentUOMSchemaDefinition
          )
        : requiredQuantity;
      if (
        !Utility.isSellsToleranceSettingsEnabled(
          props.parentDocumentType,
          props.parentDocumentDetails
        ) &&
        serialTrackingData.length > reqQty
      ) {
        const { isInvoiceLinked, isQuotationLinked, isReservedQuantity } =
          Utility.getSellsLinkedDocument(
            props.parentDocumentType,
            props.parentDocumentDetails
          );
        let errorMessage =
          'Quantity should not be more than required (' + reqQty + ')';
        if (isInvoiceLinked || isQuotationLinked) {
          errorMessage = `Quantity used cannot be more than required quantity as the Invoice/${
            Utility.isIndiaOrganisation() ? 'Quotation' : 'Estimates'
          } is already generated for this document.`;
        } else if (isReservedQuantity) {
          errorMessage =
            'Quantity used cannot be more than required quantity in case of reserved quantity.';
        }
        showToast(errorMessage, TOAST_TYPE.FAILURE);
        return;
      } else if (hasErrorTracking()) {
        showToast('Duplicate Serial Number is not allowed', TOAST_TYPE.FAILURE);
        return;
      }
      props.onSerialSave(serialTrackingData, false, serialTrackingData.length);
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          {/* old title "Allocate Serial-Tracked Products" */}
          <DKLabel text={item?.product?.name} className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title={'Save'}
            disabled={serialData.length === 0 || props?.disableBtn}
            className={
              serialData.length > 0 && !props?.disableBtn
                ? 'bg-button text-white'
                : 'bg-gray-300 text-white'
            }
            onClick={() => {
              !props?.disableBtn && onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getTitleValue = (title: string, value: any, uom = '') => {
    return !uom ? (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m" />
        <DKLabel text={value || '-'} className="" />
      </div>
    ) : (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m " />
        <div className="row align-items-end gap-1 ">
          <DKLabel text={value >= 0 ? value : '-'} className="" />
          <DKLabel
            style={{
              height: 15
            }}
            text={uom}
            className="fs-s fw-m text-gray "
          />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    return (
      <div className="row justify-content-between gap-2">
        {getTitleValue(
          `${props?.mrpTitle ? 'Material' : 'Product'} Name`,
          item?.product?.name
        )}
        {getTitleValue(
          `${props?.mrpTitle ? 'Material' : 'Product'} Code`,
          item?.documentSequenceCode
        )}
        {getTitleValue(
          'Required Quantity',
          item?.productQuantity ? item?.productQuantity : props.itemDetails.productQuantity,
          props.itemDetails.documentUOMSchemaDefinition
            ? props.itemDetails.documentUOMSchemaDefinition.name
            : Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
        )}
        {props.itemDetails.documentUOMSchemaDefinition &&
          getTitleValue(
            'Required Quantity In Base UOM',
            props.itemDetails.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  item?.productQuantity,
                  props.itemDetails.documentUOMSchemaDefinition
                )
              : // +
                //   `${
                //     props.itemDetails.documentUOMSchemaDefinition
                //       ? ' ' + props.itemDetails.documentUOMSchemaDefinition.name
                //       : ''
                //   }`
                item?.productQuantity,
            Utility.getUOMForStockUOMId(
              props.itemDetails.documentUOMSchemaDefinition
                ? props.itemDetails.documentUOMSchemaDefinition.sourceUOM
                : item?.product?.stockUom
            )?.name
          )}
        {getTitleValue(
          'Quantity Allocated',
          props.itemDetails.documentUOMSchemaDefinition
            ? Utility.getUomQuantityWithoutRoundOff(
                allocatedSerialData.length || totalAllocatedQty,
                props.itemDetails.documentUOMSchemaDefinition
              )
            : allocatedSerialData.length || totalAllocatedQty,
          props.itemDetails.documentUOMSchemaDefinition
            ? props.itemDetails.documentUOMSchemaDefinition.name
            : Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
        )}
      </div>
    );
  };

  const allocateAutomatically = () => {
    let warehouseData = localWarehouse;
    if (props?.isMRP && props?.filterBatchData) {
      warehouseData = filterWarehouseInventoryData(warehouseData);
    }
    let productAvailableQuantity = warehouseData.reduce(
      (prev: any[], current: any) => {
        let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
          return {
            ...item,
            warehouseName: current.name
          };
        });
        return [...prev, ...advTracking];
      },
      []
    );
    const { existingWarehouseInventoryData = [] } = props;
    let serialData;
    if (props?.isMRP && props?.filterBatchData) {
      serialData = productAvailableQuantity;
    } else {
      serialData = getAvailableStockData(productAvailableQuantity);
    }

    let serialTrackingData = serialData.map((item, i) => {
      let object = {
        qtyToFulfil: 1,
        batchSize: item?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.acquiredCost) / Number(item?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber,
        warehouseCode: item.warehouseCode,
        warehouseName: item.warehouseName,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName,
        rowCode: item?.rowCode ?? null,
        rowName: item?.rowName ?? null,
        rackCode: item?.rackCode ?? null,
        rackName: item?.rackName ?? null,
        binCode: item?.binCode ?? null,
        binName: item?.binName ?? null
      };
      return {
        ...object,
        advancedTrackingData: [{ ...object }]
      };
    });
    let requiredQuantityValue = getRequireQuantity();
    serialTrackingData = serialTrackingData.slice(
      0,
      requiredQuantityValue - existingWarehouseInventoryData?.length
    );
    const mappedSerialTrackedData = existingWarehouseInventoryData.map(
      (item: any) => ({
        ...item,
        qtyToFulfil: item?.['advancedTrackingData']?.[0]?.qtyToFulfil,
        batchSize: item?.['advancedTrackingData']?.[0]?.batchSize,
        serialBatchNumber:
          item?.['advancedTrackingData']?.[0]?.serialBatchNumber
      })
    );
    serialTrackingData.push(...deepClone(mappedSerialTrackedData));
    if (props.itemDetails.documentUOMSchemaDefinition) {
      const uomQty = Utility.getUomQuantityWithoutRoundOff(
        serialTrackingData.length,
        props.itemDetails.documentUOMSchemaDefinition
      );
      if (!Number.isInteger(uomQty)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as uom conversion in decimal.'
        );
        return;
      }
    }
    props.onSerialSave(serialTrackingData, false, serialTrackingData.length);
  };

  const getSelectWarehouseField = () => {
    return (
      <div
        className="row"
        style={{ width: rrbEnabledInSelectedWH ? '100%' : 250 }}
      >
        <DKInput
          className=""
          required={false}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          canValidate={saveButtonTapped}
          title={'Select Warehouse'}
          formatter={(obj: any) => {
            return obj;
          }}
          value={selectedFilters?.warehouse?.name}
          options={getActiveWarehouses() || []}
          onChange={(value: any) => {
            let copySelectedFilters = {
              row: {},
              rack: {},
              bin: {},
              warehouse: {
                code: value.code,
                name: value.name
              }
            };
            setSelectedFilters(copySelectedFilters);
            onWarehouseSelection(value, copySelectedFilters);
            setIsChangedRRBSelection(true);
          }}
          type={INPUT_TYPE.DROPDOWN}
          dropdownConfig={{
            style: { minWidth: 230 },
            className: 'shadow-m',
            allowSearch: false,
            searchableKey: 'name',
            canEdit: false,
            canDelete: false,
            data: localWarehouse ?? [],
            renderer: (index: any, account: any) => {
              return account.name;
            },
            searchApiConfig: null
          }}
        />
      </div>
    );
  };

  const resetOnRRBChange = () => {
    setAllocatedSerialData([]);
    let inventoryWarehouse = localWarehouse.find(
      (warehouse: any) => warehouse.code === currentSelectedWarehouse.code
    );
    let result = inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
      return { ...item, warehouseName: inventoryWarehouse?.name };
    });

    const updatedSerialData = getAvailableStockData(result);

    setAvailableSerialData(sortSerialNumbers(updatedSerialData));
  };

  const getSelectRowField = () => {
    return (
      <DKInput
        className=""
        required={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        canValidate={saveButtonTapped}
        title={`Select ${rowRackBinData?.rowData?.label}`}
        formatter={(obj: any) => {
          return obj;
        }}
        value={selectedFilters?.row?.name}
        options={getActiveWarehouses() || []}
        onChange={(value: any) => {
          if (props?.isFromStockIssue) {
            resetOnRRBChange();
          }
          let copySelectedFilters = {
            ...selectedFilters,
            searchText: '',
            row: {
              code: value.rowCode,
              name: value.rowName
            }
          };
          setSelectedFilters(copySelectedFilters);
          setIsChangedRRBSelection(true);
          currentSelectedWarehouse &&
            onWarehouseSelection(
              { ...currentSelectedWarehouse },
              copySelectedFilters
            );
        }}
        type={INPUT_TYPE.DROPDOWN}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          allowSearch: false,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data:
            WarehouseManagementHelper.getUniqueRows(
              currentSelectedWarehouse?.advancedTrackingMeta,
              selectedFilters?.rack?.code,
              selectedFilters?.bin?.code
            ) ?? [],
          renderer: (index: any, account: any) => {
            return account.rowName;
          },
          searchApiConfig: null
        }}
      />
    );
  };

  const getSelectRackField = () => {
    return (
      <DKInput
        className=""
        required={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        canValidate={saveButtonTapped}
        title={`Select ${rowRackBinData?.rackData?.label}`}
        formatter={(obj: any) => {
          return obj;
        }}
        value={selectedFilters?.rack?.name}
        options={getActiveWarehouses() || []}
        onChange={(value: any) => {
          if (props?.isFromStockIssue) {
            resetOnRRBChange();
          }
          let copySelectedFilters = {
            ...selectedFilters,
            searchText: '',
            rack: {
              code: value.rackCode,
              name: value.rackName
            }
          };
          setSelectedFilters(copySelectedFilters);
          setIsChangedRRBSelection(true);
          currentSelectedWarehouse &&
            onWarehouseSelection(
              { ...currentSelectedWarehouse },
              copySelectedFilters
            );
        }}
        type={INPUT_TYPE.DROPDOWN}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          allowSearch: false,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data:
            WarehouseManagementHelper.getUniqueRacks(
              currentSelectedWarehouse?.advancedTrackingMeta,
              selectedFilters?.row?.code,
              selectedFilters?.bin?.code
            ) ?? [],
          renderer: (index: any, account: any) => {
            return account.rackName;
          },
          searchApiConfig: null
        }}
      />
    );
  };

  const getSelectBinField = () => {
    return (
      <DKInput
        className=""
        required={false}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        canValidate={saveButtonTapped}
        title={`Select ${rowRackBinData?.binData?.label}`}
        formatter={(obj: any) => {
          return obj;
        }}
        value={selectedFilters?.bin?.name}
        options={getActiveWarehouses() || []}
        onChange={(value: any) => {
          if (props?.isFromStockIssue) {
            resetOnRRBChange();
          }
          let copySelectedFilters = {
            ...selectedFilters,
            searchText: '',
            bin: {
              code: value.binCode,
              name: value.binName
            }
          };
          setSelectedFilters(copySelectedFilters);
          setIsChangedRRBSelection(true);
          currentSelectedWarehouse &&
            onWarehouseSelection(
              { ...currentSelectedWarehouse },
              copySelectedFilters
            );
        }}
        type={INPUT_TYPE.DROPDOWN}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          allowSearch: false,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data:
            WarehouseManagementHelper.getUniqueBins(
              currentSelectedWarehouse?.advancedTrackingMeta,
              selectedFilters?.row?.code,
              selectedFilters?.rack?.code
            ) ?? [],
          renderer: (index: any, account: any) => {
            return account.binName;
          },
          searchApiConfig: null
        }}
      />
    );
  };

  const getSubHeaderSection = () => {
    return (
      <div className="row justify-content-between align-items-end mt-2 gap-2">
        {getSelectWarehouseField()}
        {rowRackBinData?.rowData?.enable &&
          rrbEnabledInSelectedWH &&
          getSelectRowField()}
        {rowRackBinData?.rackData?.enable &&
          rrbEnabledInSelectedWH &&
          getSelectRackField()}
        {rowRackBinData?.binData?.enable &&
          rrbEnabledInSelectedWH &&
          getSelectBinField()}
        {rrbEnabledInSelectedWH && (
          <DKButton
            title="Reset"
            className="bg-button text-white"
            icon={DKIcons.white.ic_repeat}
            onClick={() => {
              let copySelectedFilters = {
                ...selectedFilters,
                row: {},
                rack: {},
                bin: {}
              };
              setIsChangedRRBSelection(true);
              setSelectedFilters(copySelectedFilters);
              onWarehouseSelection(
                { ...currentSelectedWarehouse },
                copySelectedFilters
              );
            }}
          />
        )}
        {
          // Utility.isEmpty(props?.itemDetails?.reservedQuantitiesData) && (
          <DKButton
            title="Auto Allocate"
            className="text-app-color underline"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => {
              allocateAutomatically();
            }}
          />
          // )
        }
      </div>
    );
  };

  const getBodySection = () => {
    const selectedSerialNumbers =
      props?.itemDetails?.reservedQuantitiesData?.map(
        (item: any) => item?.serialBatchNumber
      ) || [];
    return (
      <div
        className="row justify-content-between align-items-start mt-2  position-relative"
        style={{ height: 320 }}
      >
        <div className="column parent-width parent-height border rounded-tl">
          <div className="row border-b cursor-hand table-cell bg-gray1">
            <div
              className="column parent-height bg-gray1 justify-content-center align-items-center"
              style={{ width: 40, height: 36 }}
            >
              <DKCheckMark
                className="parent-width fw-b"
                title={''}
                isSelected={isAllAvailableSerialSelected}
                onClick={() => {
                  selectAllAvailableSerial();
                }}
              />
            </div>
            <div className="row justify-content-between gap-2">
              <DKLabel
                style={{
                  width: 120
                }}
                text={'Serial Number'}
              />
              <DKInput
                direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                placeholder={'Search...'}
                required={false}
                title={''}
                value={selectedFilters?.searchText ?? ''}
                textAlign="right"
                valueStyle={{
                  border: 'none',
                  backgroundColor: 'inherit'
                }}
                onChange={(value: any) => {
                  let copySelectedFilters = {
                    ...selectedFilters,
                    searchText: value
                  };
                  setSelectedFilters(copySelectedFilters);
                  currentSelectedWarehouse &&
                    onWarehouseSelection(
                      { ...currentSelectedWarehouse },
                      copySelectedFilters
                    );
                }}
              />
            </div>
          </div>
          <div className="column parent-size overflow-y-scroll hide-scroll-bar">
            {availableSerialData && availableSerialData.length > 0 ? (
              availableSerialData.map((item, i) => {
                return (
                  <div
                    className="row border-b cursor-hand table-cell align-items-start"
                    onClick={() => {
                      getSelectedSerial(item, i);
                    }}
                  >
                    <div
                      // className="column parent-height align-items-center p-v-s"
                      className="column parent-height align-items-center justify-content-center"
                      style={{ width: 45 }}
                    >
                      <DKCheckMark
                        color={'bg-button'}
                        className=""
                        title={''}
                        isSelected={item?.selected}
                      />
                    </div>
                    <div className="column parent-width p-v-s pr-s">
                      <div className="row justify-content-between">
                        <DKLabel text={item.serialBatchNumber} />
                        {item.warehouseName && (
                          <DKLabel
                            className="fs-s fw-r text-gray-500"
                            text={item.warehouseName}
                          />
                        )}
                      </div>
                      <div className="row justify-content-between gap-1">
                        {item.rowName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.rowData?.label}: ${item.rowName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                        {item.rackName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.rackData?.label}: ${item.rackName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                        {item.binName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.binData?.label}: ${item.binName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="column parent-size align-items-center justify-content-center p-4">
                <DKLabel
                  className="fw-m text-gray"
                  style={{ textAlign: 'center' }}
                  text={
                    selectedWarehouse
                      ? 'Please select different warehouse.'
                      : 'Please select warehouse.'
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="column bg-gray1 border parent-height align-items-center justify-content-center p-h-s"
          style={{ minWidth: 120 }}
        >
          <DKButton
            title={'Allocate'}
            icon={DKIcons.white.ic_arrow_right2}
            className={`row-reverse bg-button text-white ${
              availableSerialData?.filter((serial: any) => serial.selected)
                ?.length > 0
                ? 'bg-button text-white'
                : 'bg-gray-300 text-white'
            }`}
            disabled={
              availableSerialData?.filter((serial: any) => serial.selected)
                ?.length === 0
            }
            onClick={() => {
              allocateSerialNumber();
            }}
          />
          {allocatedSerialData && allocatedSerialData.length > 0 && (
            <DKButton
              title="Reset Allocation"
              onClick={onResetAllocation}
              className="underline text-gray"
              disabled={
                props.isMRP ? Utility.isNotEmpty(selectedSerialNumbers) : false
              }
            />
          )}
        </div>
        <div className="column parent-width parent-height border rounded-tr">
          <div className="row bg-gray1 border-b p-2 fw-b rounded-tr">
            <DKCheckMark
              className="parent-width"
              title={'Allocated Serial Number'}
              isSelected={isAllSerialSelected}
              onClick={() => {
                selectAllSelectedSerial();
              }}
            />
          </div>
          <div className="column parent-size overflow-y-scroll hide-scroll-bar">
            {allocatedSerialData && allocatedSerialData.length > 0 ? (
              allocatedSerialData.map((item, i) => {
                return (
                  <div
                    className="row border-b cursor-hand table-cell align-items-start"
                    onClick={() => {
                      if (
                        props.isMRP &&
                        selectedSerialNumbers.includes(item.serialBatchNumber)
                      ) {
                        return;
                      }
                      getAllocatedSerial(item, i);
                    }}
                  >
                    <div
                      className="column parent-height align-items-center justify-content-center"
                      style={{ width: 45 }}
                    >
                      <DKCheckMark
                        color={'bg-button'}
                        className=""
                        title={''}
                        isSelected={item.allocated}
                      />
                    </div>
                    <div className="column parent-width p-v-s pr-s">
                      <div className="row justify-content-between">
                        <DKLabel text={item.serialBatchNumber} />
                        {item.warehouseName && (
                          <DKLabel
                            className="fs-s fw-r text-gray-500"
                            text={item.warehouseName}
                          />
                        )}
                      </div>
                      <div className="row justify-content-between gap-1">
                        {item.rowName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.rowData?.label}: ${item.rowName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                        {item.rackName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.rackData?.label}: ${item.rackName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                        {item.binName && (
                          <DKLabel
                            className=" fw-r text-gray-500"
                            text={`${rowRackBinData?.binData?.label}: ${item.binName}`}
                            style={{
                              fontSize: 11
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  // <div
                  //   className="row p-2 border-b cursor-hand table-cell"
                  //   onClick={() => {
                  //     if (
                  //       props.isMRP &&
                  //       selectedSerialNumbers.includes(item.serialBatchNumber)
                  //     ) {
                  //       return;
                  //     }
                  //     getAllocatedSerial(item, i);
                  //   }}
                  // >
                  //   <DKCheckMark
                  //     color={'bg-button'}
                  //     className="row align-items-center"
                  //     title={`<div class="row w-auto gap-1"><span>${item?.serialBatchNumber}</span> <span class="text-gray">(${item?.warehouseName})</span></div>`}
                  //     isSelected={item.allocated}
                  //   />
                  // </div>
                );
              })
            ) : (
              <div className="column parent-size align-items-center justify-content-center p-4">
                <DKLabel
                  className="fw-m text-gray "
                  text="No serial number allocated"
                  style={{ textAlign: 'center' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const hasErrorTracking = () => {
    let hasError = false;
    const batchTrackingData = advancedTrackingMetaData.map(
      (item: any, i: any) => {
        if (item && item.invalidFields && item.invalidFields.length > 0) {
          hasError = true;
        } else {
          hasError = false;
        }
      }
    );
    return hasError;
  };

  const onResetAllocation = () => {
    let currentWareHouseData = localWarehouse
      .find(
        (warehouse: any) => warehouse.code === currentSelectedWarehouse.code
      )
      ?.advancedTrackingMeta?.map((item: any) => ({
        ...item,
        warehouseName: currentSelectedWarehouse?.name
      }));
    let availableWarehouseData: any[] =
      getAvailableStockData(currentWareHouseData);
    const selectedWarehouseData: any[] = [];
    if (props.isMRP) {
      const { existingWarehouseInventoryData } = props;

      let trackingData: any[] = existingWarehouseInventoryData?.reduce(
        (acc: any[], currentValue: any) => {
          if (currentValue.warehouseCode === currentSelectedWarehouse.code) {
            acc.push(...currentValue.advancedTrackingData);
          }
          return acc;
        },
        []
      );
      if (trackingData?.length) {
        availableWarehouseData = [];
        const selectedSerialCodes = trackingData?.map(
          (data: any) => data?.serialBatchNumber
        );
        currentWareHouseData?.forEach((wareHouseData: any) => {
          if (
            !selectedSerialCodes?.includes(wareHouseData?.serialBatchNumber)
          ) {
            wareHouseData.selected = false;
            wareHouseData.allocated = false;
            availableWarehouseData.push(wareHouseData);
          } else {
            wareHouseData.selected = true;
            wareHouseData.allocated = true;
            selectedWarehouseData.push(wareHouseData);
          }
        });
      }
    }
    setAllocatedSerialData(selectedWarehouseData);
    setSerialData(selectedWarehouseData);
    setAvailableSerialData(
      sortSerialNumbers(getAvailableStockData(availableWarehouseData))
    );
    setIsChangedRRBSelection(true);
  };

  const getAvailableStockData = (advancedTrackingData: any[]) => {
    if (
      props?.isMRP ||
      Utility.isEmpty(props?.itemDetails?.reservedQuantitiesData)
    ) {
      return advancedTrackingData?.filter(
        (serial: any) =>
          serial.batchSizeFulfilled < serial.batchSize &&
          serial.reservedQuantity < serial.batchSize
      );
    } else {
      // Not MRP flow and doc has reserved quantities
      return advancedTrackingData?.filter((serial: any) => {
        const currentProdQty = serial?.batchSize ?? 0;
        const prodReservedQtyInWH = serial?.reservedQuantity ?? 0;

        const reservedQtyAdvTrackingData =
          props?.itemDetails?.reservedQuantitiesData
            ?.filter(
              (data: any) =>
                data.productCode === props?.itemDetails?.productCode
            )
            ?.flatMap((data: any) => data.advancedTrackingMetaDtos);

        const prodReservedQtyInfoInDoc = reservedQtyAdvTrackingData?.find(
          (resData: any) =>
            resData?.warehouseCode == serial?.warehouseCode &&
            resData?.serialBatchNumber == serial?.serialBatchNumber
        );

        let prodReservedQtyInDoc = 0;
        if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
          prodReservedQtyInDoc =
            prodReservedQtyInfoInDoc?.reservedQuantity ?? 0;
        }

        const availableBatchSize =
          currentProdQty - prodReservedQtyInWH + prodReservedQtyInDoc;

        return (
          serial.batchSizeFulfilled < serial.batchSize &&
          availableBatchSize > 0 &&
          availableBatchSize <= serial.batchSize
        );
      });
    }
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 900,
            width: '90%',
            maxHeight: '95%',
            height: 565,
            padding: 0,
            paddingBottom: 60,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-4 gap-2">
            {getHeaderSection()}
            <DKLine style={{ height: 2 }} />
            {getSubHeaderSection()}
            {getBodySection()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
