import { INPUT_TYPE } from 'deskera-ui-library';
import { DOC_TYPE } from '../../Constants/Constant';
export const QUALITY_CHECK_COLUMNS: any[] = [
  {
    name: 'QC Number',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'documentSeqCode',
    id: 'documentSeqCode',
    columnCode: 'documentSeqCode',
    allowColumnSort: false
  },
  {
    name: 'Product Name',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    key: 'productName',
    id: 'productName',
    columnCode: 'productName',
    allowColumnSort: false
  },
  {
    name: 'Product Code',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'productCode',
    id: 'productCode',
    columnCode: 'productCode',
    allowColumnSort: false
  },
  {
    name: 'Transaction Code',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'transactionCode',
    id: 'transactionCode',
    columnCode: 'transactionCode',
    allowColumnSort: false
  },
  {
    name: 'Transaction Type',
    index: 1,
    type: INPUT_TYPE.SELECT,
    width: 180,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    key: 'transactionType',
    id: 'transactionType',
    columnCode: 'transactionType',
    allowColumnSort: false,
    options: [
      {
        id: 'GOODS_RECEIPT',
        name: 'Goods Receipt',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'FULFILLMENT',
        name: 'Fulfillment',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'STOCK_ADJUSTMENT',
        name: 'Stock Adjustment',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'WIP_PRODUCTION',
        name: 'WIP Production',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'WORK_ORDER',
        name: 'Work Order',
        color: 'data-grid-badge-color-5'
      }
    ]
  },
  {
    name: 'QC Status',
    index: 1,
    type: INPUT_TYPE.SELECT,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    key: 'qcStatus',
    id: 'qcStatus',
    columnCode: 'qcStatus',
    allowColumnSort: false,
    options: [
      {
        id: 'QA_PENDING',
        name: 'Pending',
        color: 'mrp_bg_red text-white fw-m parent-size'
      },
      {
        id: 'QA_DONE',
        name: 'Done',
        color: 'mrp_bg_green text-white fw-m parent-size'
      }
    ]
  },
  {
    name: 'Transaction Date',
    index: 1,
    type: INPUT_TYPE.DATE,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'transactionDate',
    id: 'transactionDate',
    columnCode: 'transactionDate',
    allowColumnSort: false
  },
  {
    name: 'QC Date',
    index: 1,
    type: INPUT_TYPE.DATE,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'qcDate',
    id: 'qcDate',
    columnCode: 'qcDate',
    allowColumnSort: false
  }
];

export const allowedDocumentsForGoodsReceivedQC = [
  DOC_TYPE.ORDER,
  DOC_TYPE.BILL
];

export const allowedDocumentsForFulfillmentQC = [
  DOC_TYPE.QUOTE,
  DOC_TYPE.SALES_ORDER,
  DOC_TYPE.INVOICE
];

export const TRANSACTION_TYPE: any = {
  GOODS_RECEIPT: 'Goods Receipt',
  FULFILLMENT: 'Fulfillment',
  STOCK_ADJUSTMENT: 'Stock Adjustment',
  WIP_PRODUCTION: 'WIP Production',
  WORK_ORDER: 'Work Order'
};
