import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKLabel,
  DKButton,
  isEmpty,
  DKInput,
  DKIcons,
  DKIcon,
  getDataTypeIcon,
  TOAST_TYPE,
  showToast,
  showAlert,
  DKCheckMark
} from 'deskera-ui-library';
import PopupWrapper from '../PopupWrapper';
import Utility, {
  getRandomNumber,
  getCapitalized,
  deepClone,
  convertBooksDateFormatToUILibraryFormat
} from '../../Utility/Utility';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_FIELD_TYPE,
  MODULES_NAME,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import { BtnType, PopupClickActionType } from '../../Models/Interfaces';
import TableDataParser from '../../Helper/TableDataParser';
import CustomFieldService from '../../Services/CustomField';
import { IColumn } from '../../Models/Table';
import { Store } from '../../Redux/Store';
import { addContactColumnConfig } from '../../Redux/Slices/ContactsSlice';
import { addProductColumnConfig } from '../../Redux/Slices/ProductsSlice';
import { addInvoiceColumnConfig } from '../../Redux/Slices/InvoicesSlice';
import { addExpenseColumnConfig } from '../../Redux/Slices/ExpenseSlice';
import { CustomFieldResponse } from '../../Models/CustomField';
import DateFormatService from '../../Services/DateFormat';
import {
  fetchCustomFields,
  fetchProductCustomFields
} from '../../Redux/Slices/CommonDataSlice';
import { addSalesOrderColumnConfig } from '../../Redux/Slices/SalesOrderSlice';
import AppManager from '../../Managers/AppManager';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import { localizedText } from '../../Services/Localization/Localization';
import { addStockTransferColumnConfig } from '../../Redux/Slices/StockTransferSlice';
import { addStockAdjustmentColumnConfig } from '../../Redux/Slices/StockAdjustmentSlice';
import { addOperationsColumnConfig } from '../../Redux/Slices/MRP/OperationSlice';
import { addOperatorColumnConfig } from '../../Redux/Slices/MRP/OperatorSlice';
import { addWorkOrderColumnConfig } from '../../Redux/Slices/MRP/WorkOrderSlice';
import { Provider } from 'react-redux';
import { addMachineToolMasterColumnConfig } from '../../Redux/Slices/MRP/MachineToolMasterSlice';
import { addWorkoutColumnConfig } from '../../Redux/Slices/WorkOutSlice';

export interface IAddCustomFieldPopupProps {
  popupId: string;
  moduleName: string;
  tableId?: string;
  columnConfig?: any;
  forDocument?: boolean;
  moduleNameEnum?: MODULES_NAME;
  customFieldData?: any;
  onSave: (response?: any) => void;
  onClose?: () => void;
}
export interface IAddCustomFieldPopupState {
  formData: any;
  optionFieldText: string;
  submitted: boolean;
  isSaving: boolean;
  isEditMode: boolean;
  configOptions: any;
  parentCustomField: any;
  isDesktop: any;
}
const MAX_LENGTH = 100;

const MODULES = [
  {
    name: 'Contact',
    isChecked: false,
    module: MODULES_NAME.CONTACT
  },
  {
    name: 'Contact Address',
    isChecked: false,
    module: MODULES_NAME.CONTACT_ADDRESS
  },
  {
    name: 'Product',
    isChecked: false,
    module: MODULES_NAME.PRODUCT
  },
  // {
  //   name: 'BOM Assembly',
  //   isChecked: false,
  //   module: 'bom_assembly'
  // },
  {
    name: 'Account',
    isChecked: false,
    module: MODULES_NAME.ACCOUNT
  },
  {
    name: 'Journal',
    isChecked: false,
    module: MODULES_NAME.JOURNAL,
    allowUserCF: true
  },
  {
    name: 'Expense',
    isChecked: false,
    module: MODULES_NAME.EXPENSE
  },
  {
    name: 'Deposit',
    isChecked: false,
    module: MODULES_NAME.DEPOSIT
  },
  {
    name: 'Debit Note',
    isChecked: false,
    module: MODULES_NAME.DEBITNOTE
  },
  {
    name: 'Credit Note',
    isChecked: false,
    module: MODULES_NAME.CREDITNOTE
  },
  // {
  //   name: 'Stock Adjustment',
  //   isChecked: false,
  //   module: 'stock_adjustment'
  // },
  // {
  //   name: 'Stock Transfer',
  //   isChecked: false,
  //   module: 'stock_transfer'
  // },
  {
    name: `${getCapitalized(localizedText('quotation'))}`,
    isChecked: false,
    module: MODULES_NAME.QUOTATION,
    allowUserCF: true
  },
  {
    name: 'Sales Order',
    isChecked: false,
    module: MODULES_NAME.SALESORDER,
    allowUserCF: true
  },
  {
    name: 'Invoice',
    isChecked: false,
    module: MODULES_NAME.INVOICE,
    allowUserCF: true
  },
  {
    name: 'Purchase Order',
    isChecked: false,
    module: MODULES_NAME.ORDER,
    allowUserCF: true
  },
  {
    name: 'Bill',
    isChecked: false,
    module: MODULES_NAME.BILL,
    allowUserCF: true
  },
  {
    name: 'Requisition',
    isChecked: false,
    module: MODULES_NAME.REQUISITION,
    allowUserCF: true
  },
  {
    name: 'Fixed Asset',
    isChecked: false,
    module: MODULES_NAME.ASSET
  },
  {
    name: 'Build Assembly',
    isChecked: false,
    module: MODULES_NAME.BOM_ASSEMBLY
  },
  {
    name: 'Stock Adjustment',
    isChecked: false,
    module: MODULES_NAME.STOCK_ADJUSTMENT
  },
  {
    name: 'Stock Transfer',
    isChecked: false,
    module: MODULES_NAME.STOCK_TRANSFER
  },
  {
    name: 'Stock Request',
    isChecked: false,
    module: MODULES_NAME.STOCK_REQUEST
  },
  {
    name: 'Stock Issue',
    isChecked: false,
    module: MODULES_NAME.STOCK_ISSUE
  },
  {
    name: 'Operators',
    isChecked: false,
    module: MODULES_NAME.MRP_OPERATOR
  },
  {
    name: 'Operations',
    isChecked: false,
    module: MODULES_NAME.MRP_OPERATION
  },
  {
    name: 'Workorder',
    isChecked: false,
    module: MODULES_NAME.MRP_WORK_ORDER
  },
  {
    name: 'Job Work Outs',
    isChecked: false,
    module: MODULES_NAME.JOB_WORK_OUT
  },
  {
    name: 'Security Gate Entry',
    isChecked: false,
    module: MODULES_NAME.SECURITY_GATE_ENTRY
  },
  {
    name: 'Pick Pack Ship',
    isChecked: false,
    module: MODULES_NAME.PICK_PACK_SHIP
  },
  {
    name: 'Request For Quotation',
    isChecked: false,
    module: MODULES_NAME.REQUEST_FOR_QUOTATION
  },
  {
    name: 'Machine',
    isChecked: false,
    module: MODULES_NAME.MRP_MACHINE
  },
  {
    name: 'Preferred Vendor',
    isChecked: false,
    module: MODULES_NAME.REQUEST_FOR_QUOTATION
  }
];

const CF_INPUT_TYPE = {
  ...INPUT_TYPE,
  USER: 'user'
};

class AddCustomField extends React.Component<
  IAddCustomFieldPopupProps,
  IAddCustomFieldPopupState
> {
  constructor(props: IAddCustomFieldPopupProps) {
    super(props);
    this.state = {
      formData: {
        name: '',
        type: CF_INPUT_TYPE.TEXT,
        required: false,
        options: [],
        showAllModules: false,
        modules: []
      },
      optionFieldText: '',
      submitted: false,
      configOptions: { maxLength: MAX_LENGTH },
      isSaving: false,
      isEditMode: !Utility.isEmpty(props.customFieldData),
      parentCustomField: null,
      isDesktop: isViewportLarge()
    };
  }

  componentDidMount() {
    if (!Utility.isEmpty(this.props.customFieldData)) {
      this.populateForm();
    } else {
      let updatedModules = deepClone(MODULES);
      updatedModules.forEach((module: any, index: any) => {
        if (module.module === MODULES_NAME.QUOTATION) {
          updatedModules[index].name = Utility.isUSorg()
            ? 'Estimate'
            : 'Quotation';
        }
      });
      if (!Utility.isMRPWithURLCheck()) {
        updatedModules = updatedModules.filter(
          (item) =>
            ![
              MODULES_NAME.MRP_OPERATOR,
              MODULES_NAME.MRP_OPERATION,
              MODULES_NAME.MRP_WORK_ORDER,
              MODULES_NAME.MRP_MACHINE,
              MODULES_NAME.JOB_WORK_OUT
            ].includes(item.module)
        );
      }
      updatedModules?.map((item: any) => {
        if (
          item.module === MODULES_NAME.CONTACT_ADDRESS &&
          this.props.moduleName === MODULES_NAME.CONTACT
        ) {
          item.isChecked = true;
        }
        if (item.module === this.props.moduleName) {
          item.isChecked = true;
        }
      });
      this.setState({
        formData: {
          ...this.state.formData,
          modules: updatedModules
        }
      });
    }
    AppManager.handleWindowResizeListener(this.onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(this.onWindowResize, false);
    };
  }

  onWindowResize = () => {
    if (typeof this.setState === 'function') {
      this.setState({
        isDesktop: isViewportLarge()
      });
    }
  };

  populateForm = () => {
    const customFieldData = this.props.customFieldData;
    let fieldType = customFieldData.fieldType;
    let newConfigOptions = {};
    let options: any[] = [];

    if (fieldType === CUSTOM_FIELD_TYPE.DATE) {
      newConfigOptions = {
        defaultValue: customFieldData.defaultValue
      };
      fieldType = CF_INPUT_TYPE.DATE;
      options = [];
    }

    if (fieldType === CUSTOM_FIELD_TYPE.TEXT) {
      newConfigOptions = {
        maxLength: this.props.customFieldData?.maxLength,
        defaultValue: customFieldData.defaultValue
      };
      fieldType = CF_INPUT_TYPE.TEXT;
      options = [];
    }

    if (fieldType === CUSTOM_FIELD_TYPE.NUMBER) {
      newConfigOptions = {
        decimalPrecision: customFieldData.decimalPrecision,
        defaultValue: customFieldData.defaultValue
      };
      fieldType = CF_INPUT_TYPE.NUMBER;
      options = [];
    }

    if (fieldType === CUSTOM_FIELD_TYPE.USER) {
      newConfigOptions = {
        maxLength: MAX_LENGTH,
        defaultValue: ''
      };
      fieldType = CF_INPUT_TYPE.USER;
      options = [];
    }

    if (fieldType === CUSTOM_FIELD_TYPE.DROPDOWN) {
      newConfigOptions = {
        defaultValue: customFieldData.defaultValue
      };
      fieldType = CF_INPUT_TYPE.SELECT;

      if (
        customFieldData?.attributes &&
        customFieldData.attributes.length > 0
      ) {
        options = customFieldData.attributes.map((attribute: any) => {
          let indexList: number[] = [];
          if (attribute?.parentIdList && attribute.parentIdList.length > 0) {
            attribute?.parentIdList?.forEach((parentId: any) => {
              customFieldData?.parent?.attributes?.forEach(
                (obj: any, index: number) => {
                  if (obj.id.toString() === parentId) {
                    indexList.push(index);
                  }
                }
              );
            });
          }

          if (indexList.length > 0) {
            return {
              id: attribute.id,
              name: attribute.value,
              selectedInd: indexList,
              defaultOption: attribute.defaultOption,
              status: attribute.status
            };
          }

          return {
            id: attribute.id,
            name: attribute.value,
            defaultOption: attribute.defaultOption,
            status: attribute.status
          };
        });

        if (customFieldData.parent) {
          this.setState({
            parentCustomField: customFieldData.parent
          });
        }
      }
    }

    let updatedModules = deepClone(MODULES);
    updatedModules?.map((item: any) => {
      this.props?.customFieldData?.modules?.map((item2: any) => {
        if (item.module === item2) {
          if (fieldType === 'user') {
            item.isChecked = item.allowUserCF;
          } else {
            item.isChecked = true;
          }
        }
        if (item.module === MODULES_NAME.QUOTATION) {
          item.name = Utility.isUSorg() ? 'Estimate' : 'Quotation';
        }
      });
    });
    if (!Utility.isMRPWithURLCheck()) {
      updatedModules = updatedModules.filter(
        (item: any) =>
          ![
            MODULES_NAME.MRP_OPERATOR,
            MODULES_NAME.MRP_OPERATION,
            MODULES_NAME.MRP_WORK_ORDER,
            MODULES_NAME.MRP_MACHINE,
            MODULES_NAME.JOB_WORK_OUT
          ].includes(item.module)
      );
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        name: customFieldData.label,
        type: fieldType,
        required: this.props.customFieldData?.mandatory,
        options: options,
        modules: updatedModules
      },
      configOptions: newConfigOptions
    }));
  };

  onOptionChange = (value: string) => {
    this.setState({
      optionFieldText: value
    });
  };

  onOptionRemove = (index: number) => {
    let options = [...this.state.formData.options];
    options.splice(index, 1);
    this.onFormValueChange('options', options);
  };

  onOptionAdd = () => {
    if (
      isEmpty(this.state.optionFieldText) ||
      this.state.optionFieldText?.trim() === ''
    )
      return;
    let options = [...this.state.formData.options];
    let { optionFieldText } = this.state;
    const newID = this.props.forDocument
      ? optionFieldText
      : parseInt(
          getRandomNumber(1000) + '' + this.state.formData.options.length
        );
    options.push({
      id: newID,
      name: optionFieldText,
      defaultOption: false,
      status: STATUS_TYPE.ACTIVE
    });
    if (options.length === 1 && this.state.formData.required) {
      options[0].defaultOption = true;
    }
    this.onFormValueChange('options', options);
    this.setState({
      optionFieldText: '',
      configOptions: {
        ...this.state.configOptions,
        defaultValue: optionFieldText
      }
    });
  };

  onFormValueChange = (key: string, value: any) => {
    let newConfigOptions = { ...this.state.configOptions };

    if (key === 'type' && this.state.formData.type !== value) {
      newConfigOptions = {};
      if (value === 'text') {
        newConfigOptions = { maxLength: MAX_LENGTH };
      }

      if (value === 'number') {
        let decimalScale =
          Store.getState().authInfo.currentTenantInfo.data?.decimalScale;
        if (typeof decimalScale === 'undefined' || decimalScale === null) {
          decimalScale = 0;
        }
        newConfigOptions = { decimalPrecision: decimalScale };
      }

      let updatedModules = [...this.state.formData.modules];
      if (value === 'user') {
        updatedModules = updatedModules.map((m) => {
          if (m.isChecked && !m.allowUserCF) {
            m.isChecked = false;
          }
          return m;
        });
      } else if (this.props.customFieldData) {
        updatedModules = updatedModules.map((m) => {
          if (Array.isArray(this.props.customFieldData.modules)) {
            m.isChecked =
              this.props.customFieldData.modules.findIndex(
                (n: string) => m.module === n
              ) !== -1;
          }
          return m;
        });
      }

      this.setState({
        formData: {
          ...this.state.formData,
          [key]: value
        },
        configOptions: newConfigOptions
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          [key]: value
        },
        configOptions: newConfigOptions
      });
    }
  };

  onUpdate = () => {
    this.setState({
      submitted: true,
      isSaving: true
    });

    if (
      this.state.formData.type === CF_INPUT_TYPE.DATE &&
      this.state.formData.required &&
      (this.state.configOptions.defaultValue === undefined ||
        this.state.configOptions.defaultValue.length === 0)
    ) {
      this.resetLoading();
      return;
    }

    let column: IColumn = {
      ...TableDataParser.getColumnToAdd({
        ...this.state.formData
      }),
      editable: false,
      index: 0,
      columnCode: 'customField'
    };

    CustomFieldService.updateCustomFieldForDocument(
      column,
      this.getSelectedModules(),
      this.state.parentCustomField,
      this.state.configOptions,
      this.props.tableId,
      this.props.columnConfig,
      this.props.customFieldData
    )
      ?.then(
        async (res: any) => {
          this.props.onSave(res.customFieldRes);
          await Store.dispatch(fetchCustomFields({ limit: 1000, page: 0 }));
          if (this.getSelectedModules().includes(MODULES_NAME.PRODUCT)) {
            await Store.dispatch(
              fetchProductCustomFields({
                limit: 1000,
                page: 0,
                module: MODULES_NAME.PRODUCT
              })
            );
          }
          this.resetLoading();
          this.removePopUp();
        },
        (err) => {
          console.error('Error updating custom field: ', err);
          this.resetLoading();
        }
      )
      .catch(() => {
        console.error('Catch Error updating custom field');
        this.setState({
          isSaving: false
        });
      });
  };

  resetLoading = () => {
    this.setState({
      isSaving: false
    });
  };

  onSave = () => {
    this.setState({
      submitted: true,
      isSaving: true
    });

    if (this.state.formData.name.trim().length === 0) {
      this.resetLoading();
      return;
    }

    if (this.state.formData.name.trim().length > 255) {
      this.resetLoading();
      return;
    }

    if (
      (this.state.formData.type === CF_INPUT_TYPE.TEXT &&
        isNaN(this.state.configOptions?.maxLength)) ||
      this.state.configOptions?.maxLength < 1 ||
      this.state.configOptions?.defaultValue?.trim().length >
        this.state.configOptions?.maxLength
    ) {
      this.resetLoading();
      return;
    }

    if (
      (this.state.formData.type === CF_INPUT_TYPE.SELECT ||
        this.state.formData.type === CF_INPUT_TYPE.MULTI_SELECT) &&
      (this.state.formData.options?.length === 0 ||
        this.state.formData?.options?.some(
          (x: any) => x.name === this.state.optionFieldText
        ))
    ) {
      this.resetLoading();
      return;
    }

    if (
      this.state.formData.type === CF_INPUT_TYPE.DATE &&
      this.state.formData.required &&
      (this.state.configOptions.defaultValue === undefined ||
        this.state.configOptions.defaultValue.length === 0)
    ) {
      this.resetLoading();
      return;
    }

    const atleastOneModuleSelected = this.state.formData?.modules?.find(
      (item: any) => {
        return item.isChecked === true;
      }
    );
    if (Utility.isEmpty(atleastOneModuleSelected)) {
      this.resetLoading();
      showAlert('Oops!', 'Please select atleast one module.');
      return;
    }

    if (
      this.state.formData.required &&
      (this.state.formData.type === CF_INPUT_TYPE.SELECT ||
        this.state.formData.type === CF_INPUT_TYPE.MULTI_SELECT) &&
      this.state.formData?.options?.length > 0
    ) {
      const atleastOneDefaultOptionSelected =
        this.state.formData?.options?.find((item: any) => {
          return item.defaultOption;
        });

      if (Utility.isEmpty(atleastOneDefaultOptionSelected)) {
        showAlert('Oops', 'Please select a default option.');
        this.resetLoading();
        return;
      }
    }

    if (this.state.isEditMode) {
      this.onUpdate();
      return;
    }

    let column: IColumn = {
      ...TableDataParser.getColumnToAdd({
        ...this.state.formData
      }),
      editable: false,
      index: 0,
      columnCode: 'customField'
    };

    if (!this.props.forDocument) {
      let tableId = this.props.tableId ? this.props.tableId : '';
      CustomFieldService.createCustomField(
        column,
        this.props.moduleNameEnum
          ? this.props.moduleNameEnum
          : MODULES_NAME.CONTACT,
        tableId,
        this.state.parentCustomField
      )
        .then((res: any) => {
          const data: any = res;
          const newColumnConfigs: IColumn[] = data.columnConfig;
          switch (this.props.moduleNameEnum) {
            case MODULES_NAME.CONTACT:
              Store.dispatch(
                addContactColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.PRODUCT:
              Store.dispatch(
                addProductColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.INVOICE:
              Store.dispatch(
                addInvoiceColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.SALESORDER:
              Store.dispatch(
                addSalesOrderColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.EXPENSE:
              Store.dispatch(
                addExpenseColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.PREPAYMENT:
              Store.dispatch(
                addExpenseColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.STOCK_TRANSFER:
              Store.dispatch(
                addStockTransferColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.MRP_OPERATION:
              Store.dispatch(
                addOperationsColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.MRP_OPERATOR:
              Store.dispatch(
                addOperatorColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.MRP_WORK_ORDER:
              Store.dispatch(
                addWorkOrderColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.JOB_WORK_OUT:
              Store.dispatch(
                addWorkoutColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.STOCK_ADJUSTMENT:
              Store.dispatch(
                addStockAdjustmentColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            case MODULES_NAME.JOURNAL:
              break;
            case MODULES_NAME.MRP_MACHINE:
              Store.dispatch(
                addMachineToolMasterColumnConfig({
                  tableId: tableId,
                  config: newColumnConfigs
                })
              );
              break;
            default:
              break;
          }
          this.props.onSave(res);
          showToast(
            `Custom field is added to ${this.props.moduleName} successfully.`,
            TOAST_TYPE.SUCCESS
          );
          this.resetLoading();
          this.removePopUp();
        })
        .catch((err) => {
          switch (this.props.moduleNameEnum) {
            case MODULES_NAME.CONTACT:
              Store.dispatch(
                addContactColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.PRODUCT:
              Store.dispatch(
                addProductColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.INVOICE:
              Store.dispatch(
                addInvoiceColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.SALESORDER:
              Store.dispatch(
                addSalesOrderColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.MRP_OPERATION:
              Store.dispatch(
                addOperationsColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.MRP_OPERATOR:
              Store.dispatch(
                addOperatorColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.MRP_WORK_ORDER:
              Store.dispatch(
                addWorkOrderColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.JOB_WORK_OUT:
              Store.dispatch(
                addWorkoutColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.EXPENSE:
              Store.dispatch(
                addExpenseColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.PREPAYMENT:
              Store.dispatch(
                addExpenseColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.STOCK_TRANSFER:
              Store.dispatch(
                addStockTransferColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.STOCK_ADJUSTMENT:
              Store.dispatch(
                addStockAdjustmentColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            case MODULES_NAME.JOURNAL:
              break;
            case MODULES_NAME.MRP_MACHINE:
              Store.dispatch(
                addMachineToolMasterColumnConfig({
                  tableId: tableId,
                  config: this.props.columnConfig
                })
              );
              break;
            default:
              break;
          }
          if (err.data?.errorMessage?.includes('system reserved')) {
            showAlert('Error', err.data?.errorMessage);
          } else if (err.data?.errorMessage?.includes('is already present')) {
            showAlert(
              'Error',
              `Field with name "${this.state.formData.name}" is already present.`
            );
          } else {
            showAlert(
              'Error',
              'Something went wrong while creating custom field. Please try again.'
            );
          }

          this.resetLoading();
        });
    } else {
      CustomFieldService.createCustomFieldForDocument(
        column,
        this.getSelectedModules(),
        this.state.parentCustomField,
        this.state.configOptions
      )
        .then(
          async (res: any) => {
            this.props.onSave(res.customFieldRes);
            await Store.dispatch(fetchCustomFields({ limit: 1000, page: 0 }));
            if (this.getSelectedModules().includes(MODULES_NAME.PRODUCT)) {
              await Store.dispatch(
                fetchProductCustomFields({
                  limit: 1000,
                  page: 0,
                  module: MODULES_NAME.PRODUCT
                })
              );
            }
            this.resetLoading();
            this.removePopUp();
          },
          (err: any) => {
            if (err.data?.errorMessage?.includes('system reserved')) {
              showAlert(
                'Error',
                'Custom field with name "CLASS" and "LOCATION" are system reserved.'
              );
            } else if (err.data?.errorMessage?.includes('is already present')) {
              showAlert(
                'Error',
                `Field with name "${this.state.formData.name}" is already present.`
              );
            } else {
              showAlert(
                'Error',
                'Something went wrong while creating custom field. Please try again.'
              );
            }
            this.resetLoading();
          }
        )
        .catch(() => {
          showAlert(
            'Error',
            'Something went wrong while creating custom field. Please try again.'
          );
          this.resetLoading();
        });
    }
  };

  onCancel = () => {
    this.removePopUp();
  };

  removePopUp = () => {
    if (this.props.popupId) {
      const popupContainer = document.getElementById(this.props.popupId);
      popupContainer && ReactDOM.unmountComponentAtNode(popupContainer);
      popupContainer?.remove();
    }

    if (this.props.onClose) this.props.onClose();
  };

  catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        this.removePopUp();
        break;
      case POPUP_CLICK_TYPE.SAVE_CUSTOM_FIELD:
        this.onSave();
        break;
    }
  };

  render() {
    const popupBtnConfig: BtnType[] = [
      {
        title: `Cancel`,
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: this.state.isEditMode ? `Update` : `Save`,
        class: 'bg-app border-m text-white border-transparent',
        clickAction: POPUP_CLICK_TYPE.SAVE_CUSTOM_FIELD
      }
    ];
    return (
      <PopupWrapper
        clickAction={this.catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`${this.state.isEditMode ? 'Edit' : 'Add'} Custom Field`}
        btnList={popupBtnConfig}
        width={this.state.isDesktop ? '450px' : '80%'}
        height={'auto'}
        isActionBtnDisabled={this.state.isSaving}
        disableClickOutside={true}
      >
        {this.getForm()}
      </PopupWrapper>
    );
  }

  getForm = () => {
    let nameErrorMsg = '';
    if (this.state.formData?.name?.trim()?.length === 0) {
      nameErrorMsg = 'Name is required';
    }
    if (this.state.formData?.name?.trim()?.length > 255) {
      nameErrorMsg = 'Name must not contain more than 255 characters';
    }
    return (
      <div className="column p-h-s parent-height pb-xl">
        <div className="row">
          <DKInput
            required={true}
            validator={(value: any) =>
              value?.trim()?.length > 0 && value?.trim()?.length <= 255
            }
            canValidate={
              this.state.submitted &&
              (this.state.formData?.name?.trim()?.length === 0 ||
                this.state.formData?.name?.trim()?.length > 255)
            }
            errorMessage={nameErrorMsg}
            title="Name"
            type={CF_INPUT_TYPE.TEXT}
            value={this.state.formData.name}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(changedValue: any) =>
              this.onFormValueChange('name', changedValue)
            }
          />
        </div>
        <div className="row mt-xl">{this.getFieldTypeSelection()}</div>
        <div className={`row align-items-center`}>
          <DKCheckMark
            title="Is mandatory?"
            className="mt-l"
            isSelected={this.state.formData.required}
            color={'bg-button'}
            onClick={() => {
              if (
                !this.state.formData.required &&
                (this.state.formData.type === CF_INPUT_TYPE.SELECT ||
                  this.state.formData.type === CF_INPUT_TYPE.MULTI_SELECT) &&
                this.state.formData?.options?.length > 0
              ) {
                let updatedState = { ...this.state };
                const atleastOneDefaultOptionSelected =
                  this.state.formData?.options?.find((item: any) => {
                    return item.defaultOption;
                  });

                if (
                  Utility.isEmpty(atleastOneDefaultOptionSelected) &&
                  updatedState.formData?.options?.length > 0
                ) {
                  updatedState.formData.options[0].defaultOption = true;
                  updatedState.formData.options[0].status = STATUS_TYPE.ACTIVE;
                  updatedState = {
                    ...updatedState,
                    configOptions: {
                      ...updatedState.configOptions,
                      defaultValue: updatedState.formData.options[0].name
                    }
                  };
                }
                this.setState(updatedState);
              }
              this.onFormValueChange('required', !this.state.formData.required);
            }}
          />
        </div>
        {this.getConfigOptions()}
        {this.state.formData?.modules?.length > 0 && this.getAllModules()}
      </div>
    );
  };

  getIconForDataType = (type: any) => {
    switch (type) {
      case CF_INPUT_TYPE.USER:
        return DKIcons.ic_user;
      default:
        return getDataTypeIcon(type);
    }
  };

  getFieldTypeSelection = () => {
    const INPUT_TYPE_ARR = [
      CF_INPUT_TYPE.TEXT,
      CF_INPUT_TYPE.NUMBER,
      CF_INPUT_TYPE.SELECT,
      CF_INPUT_TYPE.DATE,
      CF_INPUT_TYPE.USER
    ];
    return (
      <div className="parent-width">
        <DKLabel text={'Select field type'} />
        <div className={`column-type-wrapper gap-2`}>
          {INPUT_TYPE_ARR.map((item) => {
            return (
              <div
                className={`p-s bg-white border-radius-s border-m d-flex align-items-center cursor-hand
                            ${
                              this.state.formData.type === item
                                ? 'border-blue text-blue fw-m'
                                : ''
                            }`}
                onClick={() => {
                  this.onFormValueChange('type', item);
                  setTimeout(() => {
                    if (
                      item === 'select' &&
                      this.props?.customFieldData?.fieldType === 'DROPDOWN' &&
                      this.props?.customFieldData?.attributes?.length > 0
                    ) {
                      let options =
                        this.props?.customFieldData?.attributes?.map(
                          (attribute: any) => ({
                            id: attribute.id,
                            name: attribute.value,
                            defaultOption: attribute.defaultOption,
                            status: attribute.status
                          })
                        );

                      this.onFormValueChange('options', options);
                    } else {
                      this.onFormValueChange('options', []);
                    }
                    this.setState({
                      optionFieldText: ''
                    });
                  }, 0);
                }}
              >
                <DKIcon
                  src={this.getIconForDataType(item)}
                  className="ic-xs cursor-hand"
                />
                <span className="ml-s">{Utility.convertInTitleCase(item)}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  getAddOptionField = () => {
    return (
      <div className="row align-items-end">
        <DKInput
          required={true}
          canValidate={true}
          validator={(text: any) =>
            this.state.formData.options.length &&
            !this.state.formData?.options?.some(
              (option: any) => option.name === text
            )
          }
          errorMessage={
            !this.state.formData.options.length
              ? 'At least one option is required'
              : this.state.formData?.options?.some(
                  (option: any) => option.name === this.state.optionFieldText
                )
              ? 'Enter a unique value'
              : ''
          }
          title="Options"
          type={CF_INPUT_TYPE.TEXT}
          value={this.state.optionFieldText}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(changedValue: any) => this.onOptionChange(changedValue)}
        />
        <DKButton
          title="Add"
          className="ml-r bg-button text-white"
          disabled={this.state.formData?.options?.some(
            (option: any) => option.name === this.state.optionFieldText
          )}
          onClick={this.onOptionAdd}
        />
      </div>
    );
  };

  dropdownCFSelected(selectedIndex: number[], index: number) {
    const updatedState = this.state;
    updatedState.formData.options[index].selectedInd = selectedIndex;
    this.setState(updatedState);
  }

  getOptionList = () => {
    return (
      <div className="parent-width pt-s">
        <div style={{ width: '48%' }} className="mt-l">
          <DKInput
            formatter={(obj: any) => {
              return obj.label;
            }}
            value={this.state.parentCustomField}
            title="Parent Custom Field"
            type={CF_INPUT_TYPE.DROPDOWN}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(value: any) => {
              if (value?.id === 'none') {
                let updatedStateOptions: any = [...this.state.formData.options];
                updatedStateOptions.map((item: any) => {
                  item.selectedInd = [];
                });
                this.setState({
                  parentCustomField: null,
                  formData: {
                    ...this.state.formData,
                    options: updatedStateOptions
                  }
                });
              } else {
                this.setState({
                  parentCustomField: value
                });
              }
            }}
            required={false}
            dropdownConfig={{
              title: 'Select custom field',
              allowSearch: true,
              searchApiConfig: {
                getUrl: (searchValue: string) =>
                  CustomFieldService.getCustomFieldUrl(
                    searchValue,
                    'DROPDOWN',
                    10
                  ),
                dataParser: (response: any) => {
                  let listWithNoneOption: any[] = [];

                  if (response?.content?.length > 0) {
                    listWithNoneOption = [
                      { id: 'none', label: 'None', value: '' },
                      ...response?.content
                    ];
                    if (this.state.formData.name !== '') {
                      listWithNoneOption = [
                        { id: 'none', label: 'None', value: '' },
                        ...response?.content.filter(
                          (x: any) => x.label !== this.state.formData.name
                        )
                      ];
                    }
                  }
                  return listWithNoneOption;
                },
                debounceTime: 500,
                data: []
              },
              searchableKey: 'name',
              renderer: (index: any, obj: any) => {
                return (
                  <div className="flex flex-row w-full justify-content-between">
                    <DKLabel
                      style={{ fontSize: '13px' }}
                      className="text-base border-radius-s"
                      text={obj.label}
                    />
                  </div>
                );
              }
            }}
          />
        </div>

        {this.state.formData.options.map((option: any, index: number) => {
          return (
            <div className="mt-m">
              <div
                className={`p-v-s pr-s border-radius-s mr-s mt-s d-flex align-items-center justify-content-between`}
                id={`${option.id}`}
              >
                <span>{option.name}</span>
                <div className="flex align-items-center">
                  <div style={{ width: '15rem' }}>
                    <DKInput
                      value={option.selectedInd ? option.selectedInd : []}
                      displayKey="value"
                      title=""
                      type={CF_INPUT_TYPE.DROPDOWN}
                      onChange={(value: number[]) => {
                        this.dropdownCFSelected(value, index);
                      }}
                      className="ml-r"
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      dropdownConfig={{
                        className: '',
                        title: 'Custom Field',
                        style: {},
                        allowSearch: true,
                        searchableKey: 'value',
                        multiSelect: true,
                        selectedIndexes: [],
                        checkMarkColor: 'bg-blue',
                        data: this.state.parentCustomField?.attributes
                          ? this.state.parentCustomField.attributes
                          : [],
                        renderer: (index: number, obj: any) => {
                          return <DKLabel text={obj.value} />;
                        }
                      }}
                    />
                  </div>
                  <DKIcon
                    src={DKIcons.ic_close}
                    className="ic-s ml-l cursor-hand"
                    onClick={() => {
                      let buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Yes',
                          className: 'bg-red text-white ml-r',
                          onClick: () => this.onOptionRemove(index)
                        }
                      ];
                      showAlert(
                        'Confirm Delete',
                        'Are you sure, you want to delete this option?',
                        buttons
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{ marginLeft: '198px' }}>
                {/* visibility */}
                <DKCheckMark
                  isSelected={option.status === STATUS_TYPE.ACTIVE}
                  title="Visibility"
                  onClick={() => {
                    const updatedState = this.state;
                    updatedState?.formData?.options?.forEach(
                      (item: any, interatingIndex: number) => {
                        if (option.defaultOption) {
                          return;
                        }
                        if (index === interatingIndex) {
                          updatedState.formData.options[index].status =
                            option.status === STATUS_TYPE.ACTIVE
                              ? STATUS_TYPE.INACTIVE
                              : STATUS_TYPE.ACTIVE;
                        }
                      }
                    );
                    this.setState(updatedState);
                  }}
                  className="mr-s"
                />
                {/* set as default */}
                <DKCheckMark
                  isSelected={option.defaultOption}
                  title="Set as default"
                  onClick={() => {
                    let updatedState = { ...this.state };
                    updatedState?.formData?.options?.forEach(
                      (item: any, interatingIndex: number) => {
                        if (
                          this.state.formData.required &&
                          updatedState.formData?.options?.length === 1
                        ) {
                          return;
                        }
                        if (index === interatingIndex) {
                          updatedState.formData.options[index].status =
                            STATUS_TYPE.ACTIVE;
                          updatedState.formData.options[index].defaultOption =
                            !option.defaultOption;
                        } else {
                          updatedState.formData.options[
                            interatingIndex
                          ].defaultOption = false;
                        }
                        updatedState = {
                          ...updatedState,
                          configOptions: {
                            ...updatedState.configOptions,
                            defaultValue: item.name
                          }
                        };
                      }
                    );
                    this.setState(updatedState);
                  }}
                  className="mr-s"
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  getConfigOptions = () => {
    const { configOptions } = this.state;
    switch (this.state.formData.type) {
      case CF_INPUT_TYPE.TEXT:
        return (
          <>
            <div className="row parent-width mt-l">
              <DKInput
                title="Max Length"
                required={true}
                canValidate={true}
                validator={(text: string) => {
                  let len = parseInt(text);
                  return !isNaN(len) && len > 0;
                }}
                errorMessage={'Max length should be positive number.'}
                value={configOptions?.maxLength || ''}
                type={CF_INPUT_TYPE.NUMBER}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                onChange={(changedValue: any) =>
                  this.setState({
                    configOptions: {
                      ...configOptions,
                      maxLength: parseInt(changedValue)
                    }
                  })
                }
              />
            </div>
            <div className="row parent-width mt-l">
              <DKInput
                title="Default Value"
                required={false}
                canValidate={
                  configOptions?.defaultValue?.length > configOptions?.maxLength
                }
                validator={(text: string) => {
                  return text === 'india';
                }}
                errorMessage={`Default Value length cannot be more than ${configOptions?.maxLength} characters`}
                value={configOptions?.defaultValue || ''}
                type={CF_INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                onChange={(changedValue: any) =>
                  this.setState({
                    configOptions: {
                      ...configOptions,
                      defaultValue: changedValue
                    }
                  })
                }
              />
            </div>
          </>
        );
      case CF_INPUT_TYPE.NUMBER:
        return (
          <div className="row parent-width mt-l">
            <DKInput
              title="Default Value"
              required={false}
              value={
                Utility.isNullish(configOptions?.defaultValue)
                  ? ''
                  : configOptions?.defaultValue
              }
              type={CF_INPUT_TYPE.NUMBER}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(changedValue: any) => {
                this.setState({
                  configOptions: {
                    ...configOptions,
                    defaultValue: changedValue
                  }
                });
              }}
            />
          </div>
        );
      case CF_INPUT_TYPE.SELECT:
        return (
          <>
            {(this.state.formData.type === CF_INPUT_TYPE.SELECT ||
              this.state.formData.type === CF_INPUT_TYPE.MULTI_SELECT) && (
              <div className="row mt-l">{this.getAddOptionField()}</div>
            )}
            {this.state.formData.options?.length > 0 && this.getOptionList()}
          </>
        );
      case CF_INPUT_TYPE.DATE:
        return (
          <div className="row parent-width mt-l">
            <DKInput
              key="Default Date"
              title="Default Value"
              required={this.state.formData.required}
              canValidate={this.state.formData.required}
              validator={(value: string) => {
                return value.length !== 0;
              }}
              errorMessage="Date is required"
              type={CF_INPUT_TYPE.DATE}
              value={
                Utility.isNullish(configOptions?.defaultValue)
                  ? ''
                  : DateFormatService.getDateFromStr(
                      configOptions?.defaultValue,
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    )
              }
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(changedValue: any) => {
                this.setState({
                  configOptions: {
                    ...configOptions,
                    defaultValue: DateFormatService.getDateStrFromDate(
                      changedValue,
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    )
                  }
                });
              }}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                Store.getState().authInfo.currentTenantInfo.data.dateFormat
              )}
            />
          </div>
        );
      default:
        return null;
    }
  };

  getAllModules = () => {
    return (
      <div className="parent-width mt-l">
        <div
          className="row align-items-start cursor-hand"
          onClick={() => {
            this.toggleModulesView();
          }}
        >
          <DKLabel
            text={`Modules ${
              this.getSelectedModules()?.length > 0
                ? `<span class="fw-b">(${
                    this.getSelectedModules()?.length
                  } selected)</span>`
                : ''
            }`}
          />
          <DKIcon
            src={
              this.state.formData.showAllModules
                ? DKIcons.ic_arrow_up2
                : DKIcons.ic_arrow_down2
            }
            className="ic-s ml-s cursor-hand"
            onClick={() => {}}
          />
        </div>
        {this.state.formData.showAllModules && this.getModuleView()}
      </div>
    );
  };

  toggleModulesView = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        showAllModules: !this.state.formData.showAllModules
      }
    });
  };

  getSelectedModules = () => {
    const values = this.state.formData?.modules?.map((item: any) =>
      item.isChecked ? item.module : null
    );
    return values.filter((item: any) => item);
  };

  isModuleVisible = (module: any) => {
    return (
      this.state.formData.type !== CF_INPUT_TYPE.USER ||
      (this.state.formData.type === CF_INPUT_TYPE.USER && module.allowUserCF)
    );
  };

  getModuleView = () => {
    return (
      <div className="row flex-wrap">
        {this.state.formData?.modules?.map((module: any) => {
          return (
            <>
              {this.isModuleVisible(module) && (
                <div className="p-s" style={{ width: '50%', paddingLeft: 0 }}>
                  <DKCheckMark
                    title={module.name}
                    isSelected={module.isChecked}
                    // disabled={true}
                    color="bg-blue"
                    onClick={() => {
                      let updatedModules = [...this.state.formData.modules];
                      updatedModules.map((item: any) => {
                        if (item.module === module.module) {
                          item.isChecked = !item.isChecked;
                        }
                      });
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          modules: updatedModules
                        }
                      });
                    }}
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };
}
export const showAddCustomField = (
  config: {
    module: string;
    forDocument?: boolean;
    tableId?: string;
    columnConfig?: any;
    moduleNameEnum?: MODULES_NAME;
    customFieldData?: CustomFieldResponse | null;
  },
  onSave: (response?: any) => void,
  onClose?: () => void
) => {
  const id = `add-custom-field-popup-${new Date().getTime()}`;
  let div = document.createElement('div');
  div.className = 'app-font';
  div.setAttribute('id', id);
  ReactDOM.render(
    <Provider store={Store}>
      <AddCustomField
        popupId={id}
        moduleName={config.module}
        tableId={config.tableId}
        columnConfig={config.columnConfig}
        forDocument={config.forDocument}
        moduleNameEnum={config.moduleNameEnum}
        customFieldData={config.customFieldData}
        onSave={onSave}
        onClose={onClose}
      />
    </Provider>,
    document.body.appendChild(div)
  );
};
export default AddCustomField;
