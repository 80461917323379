import { FORM_ELEMENTS, TRACKING_TYPE } from '../Constants/Constant';
import { CallBackPayloadType } from './Interfaces';
import { InputTag } from './NewContact';

export interface BatchSerialTrackingState {
  batch: BatchTracking;
  serial: SerialTracking;
  saveBtnDisabled: boolean;
}

export interface BatchSerialTrackingProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  productDetails: any;
  advancedTrackingMetaData?: any[];
}

export interface SerialTracking {
  openingQuantity: number;
  unassignedQuantity: number;
  serialInputField: InputTag<string>;
  serialList: SerialRow[];
  unitPrice: string;
  currencyCode: string;
  isValid: boolean;
  noNewSerials: boolean;
}

export interface BatchTracking {
  openingQuantity: number;
  unassignedQuantity: number;
  toalQuantityInBatches: number;
  batchInputField: InputTag<string>;
  batchQuantityField: InputTag<string>;
  batchList: BatchRow[];
}

export interface SerialRow {
  inputField: InputTag<string>;
  amount: string;
}

export interface BatchRow {
  batchNumber: InputTag<string>;
  manufacturedDate: InputTag<string>;
  expiryDate: InputTag<string>;
  qtyInBatch: InputTag<string>;
  availableQtyInBatch: InputTag<string>;
  isExisting?: boolean;
  id?: any;
}

export const serialRow: SerialRow = {
  amount: '0',
  inputField: {
    key: '',
    hidden: false,
    value: '',
    type: FORM_ELEMENTS.INPUT,
    hasError: false,
    isMandatory: true
  }
};

export const initialBatchSerialTrackingState: BatchSerialTrackingState = {
  batch: {
    openingQuantity: 0,
    unassignedQuantity: 0,
    toalQuantityInBatches: 0,
    batchInputField: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    batchQuantityField: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    batchList: []
  },
  serial: {
    openingQuantity: 0,
    unassignedQuantity: 0,
    serialInputField: {
      key: '',
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: ''
    },
    serialList: [],
    unitPrice: '0',
    isValid: true,
    currencyCode: '',
    noNewSerials: false
  },
  saveBtnDisabled: true
};

export enum BATCH_SERIAL_TRACKING_FIELD_NAMES {
  SERIAL_NUMBER = 'serialNumber',
  BATCH_NUMBER = 'batchNumber',
  BATCH_QUANTITY = 'batchQuantity',
  EXPIRY_DATE = 'expiryDate',
  MANUFACTURED_DATE = 'manufacturedDate'
}

export interface BatchTrackedMetaData {
  serialBatchNumber: string;
  manufacturingDate: string;
  expiryDate: string;
  batchSize: number;
  id?: any;
}

export interface SerialTrackedMetaData {
  serialBatchNumber: string;
  batchSize: number;
}
