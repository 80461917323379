// Import createSlice from redux toolkit
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColumn } from '../../Models/Table';
import Utility from '../../Utility/Utility';
import { API_STATUS, LABELS } from '../../Constants/Constant';
import DraftService from '../../Services/Drafts';
import { COLUMN_CODE } from '../../Constants/TableConstants';
import { DraftTypes } from '../../Models/Drafts';
import { RootState } from '../Store';

export interface DraftState {
  data: any;
  columnConfig: IColumn[];
  status: API_STATUS;
  draftData: any;
  tableId: string;
  isSaved: string;
  appNameId: string;
  populateFormData: any;
  draftType: string;
  isLoading: boolean;
  canValidate?: boolean;
}

// initialized initial state of Draft
const initialState: DraftState = {
  data: [] as any,
  columnConfig: [],
  status: API_STATUS.IDLE,
  draftData: {} as any,
  tableId: '',
  isSaved: '',
  appNameId: '',
  populateFormData: {} as any,
  draftType: DraftTypes.NEW,
  isLoading: false,
  canValidate: false
};

export const fetchDrafts = createAsyncThunk(
  'drafts',
  async (data: any, thunkApi) => {
    const currentState = thunkApi.getState() as RootState;
    const response = await DraftService.getDraftRecords(
      data.tableId,
      data.isSaveColumnId,
      currentState?.drafts.appNameId,
      data.draftTypeColId,
      data.draftTypeColValue,
      data?.filter
    );
    return response;
  }
);

export const deleteDrafts = createAsyncThunk(
  'delete_drafts',
  async (data: any) => {
    const response = await DraftService.deleteDraftRecords(
      data.recordId,
      data.tableId
    );
    return response;
  }
);

export const bulkDeleteDrafts = createAsyncThunk(
  'bulk_delete_drafts',
  async (data: any) => {
    try {
      const response = await DraftService.bulkDeleteDraftRecords(
        data.recordIds,
        data.tableId
      );
      return response;
    } catch (err) {
      console.error('Error bulk deleting drafts: ', err);
    }
  }
);

export const createBlankDraft = createAsyncThunk(
  'create_draft',
  async (draftData: any) => {
    let response: any;
    if (
      draftData.draftType === DraftTypes.NEW ||
      draftData.draftType === DraftTypes.COPY
    ) {
      const draftType = draftData.draftType;
      draftData = draftData.payloadData;
      draftData.draftType = draftType;
    } else if (draftData.draftType === DraftTypes.DRAFT) {
      draftData = draftData.payloadData;
      draftData.draftType = DraftTypes.DRAFT;
      // draftData.canValidate = true;
      response = { id: draftData.id };
    } else if (draftData.draftType === DraftTypes.UPDATE) {
      draftData = draftData.payloadData;
      draftData.draftType = DraftTypes.UPDATE;
      // draftData.canValidate = true;
      response = { id: draftData.id };
    } else if (draftData.draftType === DraftTypes.READONLY) {
      if (draftData.hideSave) {
        draftData = { ...draftData.payloadData, hideSave: true };
      } else {
        draftData = draftData.payloadData;
      }
      draftData.draftType = DraftTypes.READONLY;
      response = { id: draftData.id };
    }
    return { response, draftData };
  }
);

// Creating slice for drafts
export const DraftsSlice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    addTableId: (state, action) => {
      state.tableId = action.payload;
    },
    addDraftColumnConfig: (state: any, action) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.forEach((config: any) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      let isSaved: any = Utility.getKeyOfColumn(
        configsToStore,
        COLUMN_CODE.DRAFTS.isSaved
      );
      let type: any = Utility.getKeyOfColumn(
        configsToStore,
        COLUMN_CODE.DRAFTS.Type
      );
      let appName: any = Utility.getKeyOfColumn(
        configsToStore,
        COLUMN_CODE.DRAFTS.AppName
      );
      let payloadKey: any = Utility.getKeyOfColumn(
        configsToStore,
        COLUMN_CODE.DRAFTS.Payload
      );
      state.isSaveColumnId = isSaved[0].id;
      state.draftTypeColumnId = type[0].id;
      state.appNameId = appName?.[0].id;
      state.columnConfig = configsToStore;
      state.payloadKey = payloadKey?.[0]?.id ?? '';
    },
    addColumnToDraftColumnConfig: (state, action: PayloadAction<IColumn>) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    addNewDraft: (state, action) => {
      let _blankDraft = {
        id: Math.floor(Math.random() * 100),
        isMaximized: action.payload.isMaximized
          ? action.payload.isMaximized
          : true,
        isCenterAlign: action.payload.isCenterAlign
          ? action.payload.isCenterAlign
          : false,
        type: action.payload.type,
        data: action.payload
      };
      state.data = [...state.data, _blankDraft];
    },
    removeDraft: (state, action) => {
      state.data = state.data.filter((item: any) => item.id !== action.payload);
    },
    setViewOfDraft: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (!action.payload.isMaximized) {
        state.data[index].isMaximized = action.payload.isMaximized;
        state.data[index].isCenterAlign = false;
      } else {
        state.data[index].isMaximized = action.payload.isMaximized;
      }
    },
    setDraftCenter: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (action.payload.isCenterAlign) {
        state.data[index].isCenterAlign = action.payload.isCenterAlign;
        state.data[index].isMaximized = true;
      } else {
        state.data[index].isCenterAlign = action.payload.isCenterAlign;
      }
    },
    setCurrentDraftSaved: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      state.data[index].isSaved = true;
    },
    setPopulateFormData: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (index > -1) {
        state.data[index].populateFormData = action.payload.formdata;
      }
    },
    updatePopulateFormData: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (index > -1) {
        state.data[index].populateFormData = action.payload.formdata;
        state.data[index].draftType = action.payload.draftType;
        state.data[index].data.populateFormData = action.payload.formdata;
        state.data[index].data.draftType = action.payload.draftType;
        state.data[index].actionFromDocument =
          action.payload.actionFromDocument;
      }
    },
    setDraftActionAvailibility: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (index > -1) {
        state.data[index].isLoading = action.payload.status;
      }
    },
    setDraftValidationDisplayStatus: (state, action) => {
      let index = state.data.findIndex(
        (draft: any) => draft.id == action.payload.id
      );
      if (index > -1) {
        state.data[index].canValidate = action.payload.status;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrafts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchDrafts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.draftData = action.payload;
      })
      .addCase(deleteDrafts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(deleteDrafts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
      })
      .addCase(bulkDeleteDrafts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(bulkDeleteDrafts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
      })
      .addCase(createBlankDraft.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(createBlankDraft.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let _blankDraft = {
          id:
            action.payload.draftData.draftType === DraftTypes.NEW ||
            action.payload.draftData.draftType === DraftTypes.COPY
              ? Math.floor(Math.random() * 100)
              : action.payload.response.id,
          isMaximized: action.payload.draftData.isMaximized
            ? action.payload.draftData.isMaximized
            : true,
          isCenterAlign: action.payload.draftData.isCenterAlign
            ? action.payload.draftData.isCenterAlign
            : false,
          type: action.payload.draftData.type,
          populateFormData: action.payload.draftData.populateFormData
            ? action.payload.draftData.populateFormData
            : {},
          data: action.payload.draftData,
          isSaved: action.payload.draftData.isSaved
            ? action.payload.draftData.isSaved
            : false,
          draftType: action.payload.draftData.draftType,
          isLoading: action.payload.draftData.isLoading
            ? action.payload.draftData.isLoading
            : false,
          canValidate: action.payload.draftData.canValidate || false,
          hideMinimizer: action.payload.draftData.hideMinimizer || false
        };
        state.data = [...state.data, _blankDraft];
      });
  }
});

// Export actions of drafts
export const {
  addTableId,
  addNewDraft,
  removeDraft,
  setViewOfDraft,
  setDraftCenter,
  addDraftColumnConfig,
  setCurrentDraftSaved,
  setPopulateFormData,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} = DraftsSlice.actions;

// Export drafts data to display
export const selectDrafts = (state: any) => state.drafts.data;
export const selectDraftsColumnConfig = (state: any) =>
  state.drafts.columnConfig;
export const allDraftData = (state: any) => state.drafts.draftData;
export const draftTableId = (state: any) => state.drafts.tableId;
export const isSaveColumnId = (state: any) => state.drafts.isSaveColumnId;
export const payloadColumnKey = (state: any) => state.drafts.payloadKey;
export const draftTypeColumnId = (state: any) => state.drafts.draftTypeColumnId;
export const selectInvoiceDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.INVOICES
  );
};
export const selectSalesOrderDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.SALES_ORDER
  );
};
export const selectQuoteDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.QUOTES
  );
};

export const selectOrderDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.PURCHASE_ORDERS
  );
};

export const selectRequisitionDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.REQUISITION
  );
};

export const selectWorkOutDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) => x.cells[type[0].id] === LABELS.WORK_OUT
  );
};

export const selectBillDraftsData = (state: RootState) => {
  const type: any = Utility.getKeyOfColumn(
    state.drafts.columnConfig,
    COLUMN_CODE.DRAFTS.Type
  );
  return state.drafts.draftData?.data?.filter(
    (x: any) =>
      x.cells[type[0].id] === LABELS.BILLS ||
      x.cells[type[0].id] === LABELS.EXPENSE_BILL
  );
};

// Export DraftSlice reducer
export default DraftsSlice.reducer;
