import http from '../Services/Interceptor';
import http2 from '../Services/Interceptor2';
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import IVendorFormPayload from '../Models/RequestForQuote';
import DateFormatService from './DateFormat';
import { BOOKS_DATE_FORMAT } from '../Constants/Constant';

export interface RFQAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  sort?: string;
  sortBy?: string;
  Query?: string;
  QueryParam?: any;
  fields?: string;
}

export const defaultConfig: RFQAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  sort: 'DESC',
  sortBy: '',
  Query: '',
  QueryParam: ''
};

class RFQService {
  static apiConfig: RFQAPIConfig;

  static getConfigUrl() {
    let urlString = `?fetchAttachmentDetails=true&limit=${
      Utility.isEmpty(this.apiConfig.Limit) ? 25 : this.apiConfig.Limit
    }&search=${this.apiConfig?.SearchTerm || ''}&page=${
      this.apiConfig?.Page || 0
    }&sort=${this.apiConfig.sortBy ? this.apiConfig.sortBy : ''}&query=${
      this.apiConfig?.Query || ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}&sortDir=${
      this.apiConfig.sort ? this.apiConfig.sort : 'DESC'
    }`;
    return urlString;
  }

  static fetchAllRFQs() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalURL =
      ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.FETCH_ALL_RFQS +
      this.getConfigUrl();
    return http
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static createRFQDocument(payload: any) {
    return http
      .post(ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.CREATE, payload)
      .catch((err: any) => {
        console.error('Error while creating RFQ: ', err);
        return Promise.reject(err);
      });
  }

  static updateRFQDocument(payload: any, id: any) {
    return http
      .put(
        ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.UPDATE_RFQ_DOC(id),
        payload
      )
      .catch((err: any) => {
        console.error('Error while updating rfq document: ', err);
        return Promise.reject(err);
      });
  }

  static deleteRFQ(code: any) {
    return http
      .delete(`${ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.DELETE(code)}`)
      .catch((err: any) => {
        console.error('Error deleting rfq: ', err);
        return Promise.reject(err);
      });
  }

  static getRFQDetailsByCode(code: any) {
    return http
      .get(
        `${ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.GET_RFQ_DETAIL_BY_CODE(
          code
        )}`
      )
      .catch((err: any) => {
        console.error('Error while fetching rfq: ', err);
        return Promise.reject(err);
      });
  }

  static closeRFQManually(code: any) {
    return http
      .patch(ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.CLOSE_MANUALLY(code))
      .catch((err: any) => {
        console.error('Error while closing RFQ: ', err);
        return Promise.reject(err);
      });
  }

  static fetchQuotesComparisonReportData(docSeqCode: any) {
    let finalURL =
      ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.FETCH_QUOTES_COMPARISON(
        docSeqCode
      );
    return http2
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export const fetchRFQDetails = (id: any, tid: any, cid: any) => {
  return http.get(
    ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.OPEN.GET(id, tid, cid)
  );
};
export const postRFQDetails = (payload: IVendorFormPayload) => {
  return http.post(
    ApiConstants.URL.REQUEST_FOR_QUOTATION_RFQ.OPEN.POST,
    payload
  );
};
export const buildVendorFormPayload = (formData: any): IVendorFormPayload => {
  return {
    contact: formData?.contactDto,
    memo: formData?.memo,
    rfqCode: formData?.rfqCode,
    subTotal: formData.subTotal,
    tenantId: formData.tenantId,
    totalAmount: formData.total,
    rfqSeqCode: formData?.documentSequenceCode,
    totalDiscount: formData?.totalDiscount,
    vendorEmail: formData?.contactDto?.emailId,
    vendorCode: formData?.contactDto?.code,
    vendorSeqCode: formData?.contactDto?.documentSequenceCode,
    piqReceiveDate: DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    ),
    purchaseInwardQuotationItems: formData?.requestForQuotationItems?.map(
      (item: any) => {
        if (Utility.isNotEmpty(item?.documentUOMSchemaDefinition)) {
          item.productQuantity = item?.uomQuantity ?? item?.productQuantity;
        }
        delete item?.invalidFields;
        return item;
      }
    ),
    currencyCode: formData.currency,
    validByDate: formData.validByDate
  };
};
export default RFQService;
