import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  appCustomizationInfo,
  eInvoiceAuthInfo,
  featurePermissions,
  fetchEInvoiceAuthInfo
} from '../../Redux/Slices/AuthSlice';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized,
  getRandomHexString
} from '../../Utility/Utility';
import './NewDocument.scss';
import {
  CASCADING_DISCOUNT_PREFIX,
  DEFAULT_PRICE_BOOK,
  DEFAULT_PRICE_BOOK_ID,
  DOCS_SUPPORTING_FULLSCREEN,
  calculateUSTaxAPIErrorMessages,
  checkContact,
  checkGSTINPresentForSelectedContact,
  convertToCurrenctExchangeRate,
  getCascadingDiscountsFromStore,
  getFormattedAddress,
  getMainModuleName,
  getNewCFItem,
  getNewColumnForCF,
  getNewColumnForDiscount,
  getNewColumnForTotalDiscount,
  getProductPriceKeyFromDocType,
  getTenantTaxSystem,
  getValidTillDateFromDocDate,
  inactiveContactMessage,
  isDocContactInactive,
  isGSTExchangeRateRequired,
  isSGAndPeppolOptInEnabled,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo,
  roundingOffStr,
  updateAvailableQtyBasedOnUOM,
  updateGstType,
  updateRowDataWithParentCFValues,
  updateColumnConfigOnRowClick,
  getComponentDetailsForDocumentLineItems,
  showComponenListSection,
  getQtyMultipliedComponentList,
  getPrimaryCurrencyCheck,
  checkIfLineLevelCustomFieldIsValid,
  rcmAppliedIndiaWithCheckRCMApply,
  isSalesDocumentShortfallAlertsEnabled,
  updateBOMDetailsForProdQtyRenderer,
  getDestinationOfSupplyAccordingToDOCType
} from './NewDocumentHelper';

import { addDays, isAfter, subDays, isEqual } from 'date-fns';
import {
  DKButton,
  DKCalendar,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKListPicker2,
  DKTooltipWrapper,
  INPUT_TYPE,
  showLoader,
  removeLoader,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKLine,
  DKCheckMark
} from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';
import ic_inventory_fulfill from '../../Assets/Icons/ic_inventory_fulfill.svg';
import ic_inventory_pending from '../../Assets/Icons/ic_inventory_pending.svg';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../Assets/Icons/ic_barcode_red.svg';
import ReCheckButton from '../ReCheckButton';
import { COUNTRIES_WITH_CURRENCIES } from '../../Components/PriceBook/CountriesWithCurrencies';
import AddContact from '../../Components/Contacts/AddContact';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import {
  INVOICE_NOW_TYPES,
  getInvoiceNowStatus,
  getUpdatedInvoiceObject
} from '../../Components/Invoices/InvoiceHelper';
import ReserveStockPopUp from '../../Components/Invoices/ReserveStockPopUp';
import CreateProductView from '../../Components/Product/CreateProductView';
import AddClass from '../../Components/Settings/Classes/AddClass';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import CancelEInvoice from '../../Components/Settings/OrganisationProfile/CancelEInvoice';
import { IRPCredPopup } from '../../Components/Settings/OrganisationProfile/IRPCredPopup';
import OrganisationProfileForm from '../../Components/Settings/OrganisationProfile/OrganisationProfileForm';
import AddTax from '../../Components/Settings/Tax/AddTax';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  COMMON_CONSTANT,
  COMPLAINCE_CURRENCY,
  COMPOSTION_TAX_PERCENT,
  COUNTRY_CODES,
  CURRENCIES,
  CURRENCY_PRECISION,
  CUSTOM_FIELD_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOCUMENT_TYPE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  GST_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  PAYMENT_STATUS as PAYMENT,
  PAYMENT_MILESTONE_ENABLED_DOCS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_OFFERING_TYPE,
  PRODUCT_TRANSACTIONTYPE_PURCHASE,
  PRODUCT_TRANSACTIONTYPE_SALES,
  PRODUCT_TYPE,
  RECEIVED_GOODS_STATUS,
  REGEX,
  SETUP_PAYMENT,
  STATUS_TYPE,
  TAX_SYSTEM,
  TRACKING_TYPE,
  UOM_NA_ID,
  DOC_PATH_WITH_ID_REGEX,
  OVERSEAS,
  VENDOR_IMPORT,
  PricingColumnKeys,
  BILL_RCM_ITC_ERROR,
  RCM_DOCUMENTS,
  GOOGLE_NO_TRANSLATE_CLASS
} from '../../Constants/Constant';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE
} from '../../Constants/Permission';
import { INDIAN_STATES_MOCK_DATA } from '../../Constants/StaticData';
import AppManager from '../../Managers/AppManager';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import {
  BillingAddressType,
  Document,
  ShippingAddressType
} from '../../Models/Document';
import { DocumentItem } from '../../Models/DocumentItem';
import { DraftTypes } from '../../Models/Drafts';
import {
  BooksAddress,
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  LocationDTO,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { removeDraft } from '../../Redux/Slices/DraftsSlice';
import {
  selectInvoicesColumnConfig,
  selectInvoicesColumnConfigTableId
} from '../../Redux/Slices/InvoicesSlice';
import {
  fetchCategoryDimensions,
  locationsData,
  selectDimensions
} from '../../Redux/Slices/LocationSlice';
import {
  selectPurchaseOrdersColumnConfig,
  selectPurchaseOrdersColumnConfigTableId
} from '../../Redux/Slices/PurchaseOrdersSlice';
import {
  selectQuotesColumnConfig,
  selectQuotesColumnConfigTableId
} from '../../Redux/Slices/QuotesSlice';
import {
  selectSalesOrderColumnConfig,
  selectSalesOrderColumnConfigTableId
} from '../../Redux/Slices/SalesOrderSlice';
import AccountsService from '../../Services/Accounts';
import AttachmentService from '../../Services/Attachment';
import AuthService from '../../Services/Auth';
import DateFormatService from '../../Services/DateFormat';
import EInvoiceService from '../../Services/EInvoice';
import InvoiceService from '../../Services/Invoice';
import { localizedText } from '../../Services/Localization/Localization';
import NumberFormatService from '../../Services/NumberFormat';
import PriceListService from '../../Services/PriceList';
import SalesOrderService from '../../Services/SalesOrder';
import TaxService from '../../Services/Tax';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../CustomNumberFormat/CustomNumberFormatInput';
import PopupWrapper from '../PopupWrapper';
import DocumentSummaryView from './DocumentSummaryView';
import { GSTExchangeRateDialog } from './GSTExchangeRateDialog';
import GetEmailForPayment from './GetEmailForPayment';
import ItcOptions from './ItcOptions';
import { ItemTaxCalculator } from './ItemTaxCalculator';
import { createLineItem } from './NewDocumentHelper';
import TDSCalculation from './TDSCalculation';
import UpdateAddress from './UpdateAddress';

import ic_milestone from '../../Assets/Icons/milestone.png';
import BarcodeIcon from '../../Assets/ic_barcode.png';
import BarcodeSearchPopup from '../../Components/BarcodeSerach/BarcodeSearchPopUp';
import { PaymentMileStone } from '../../Components/Common/PaymentMileStone';
import TaxGroupDistribution from '../../Components/Tax/TaxGroupDistribution';
import ApiConstants from '../../Constants/ApiConstants';
import {
  selectWorkOutsColumnConfig,
  selectWorkOutsColumnConfigTableId
} from '../../Redux/Slices/WorkOutSlice';
import AmortizationService from '../../Services/Amortization';
import { getDataForMilestonePopup } from '../../Services/PaymentMilestone';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { ADDITIONAL_CHARGE_METHODS } from '../AdditionalCharges/AdditionalCharges';
import CustomFieldSettings from '../CustomFieldsHolder/CustomFieldSettings';
import {
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import {
  fetchClassesByDimensionId,
  fetchCurrencyExchangeRateByDocDate,
  fetchProductCustomFields,
  fetchTaxes,
  fetchUOMs,
  selectCurrencyListWithExchangeRate,
  selectCurrencyListWithExchangeRateByDocDate,
  selectIsLoadingPopupWrapper,
  selectProductCustomFields,
  selectPurchaseTax,
  selectSalesTax,
  selectShowMainDocsInFullScreen,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import {
  fetchContacts,
  selectContacts
} from '../../Redux/Slices/ContactsSlice';
import {
  fetchProductsWithVariants,
  selectProductsWithVariants
} from '../../Redux/Slices/ProductsSlice';
import {
  ADVANCE_TRACKING,
  CONTACT_FORM_TAB,
  ORGANISATION_FORM_TAB
} from '../../Constants/Enum';
import QuotationService from '../../Services/Quotation';
import { makePaymentMethod } from '../../Models/PaymentMethod';
import LocationService from '../../Services/Location';
import ProductService from '../../Services/Product';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../Models/TDS';
import DocumentActionMenu from './DocumentActionMenu';
import { Store } from '../../Redux/Store';
import HSNForm from './HSNForm';
import GSTValueForm from './GSTValueEdit';
import AllocateLandedCost from './AllocateLandedCost';
import CommonStateListPicker from './CommonStateListPicker';
import {
  fetchPriceBookList,
  priceBookSelector
} from '../../Redux/Slices/PriceBookSlice';
import { useHistory } from 'react-router-dom';
import DocProductSelectionPopup from './DocProductSelectionPopup';
import useScreenResize from '../../Hooks/useScreenResize';
import { selectPaymentTerms } from '../../Redux/Slices/PaymentTermsSlice';
import ComponentDetailsForFG from './ComponentDetailsForFG';
import TaxValueEditForm from './TaxValueEditForm';
import DefaultAccountsSettingsPopup from '../../Components/Accounting/COA/DefaultAccountsSettingsPopup';
import {
  fetchDefaultAccounts,
  selectDefaultAccounts
} from '../../Redux/Slices/AccountsSlice';
import { DEFAULT_ACCOUNT_MODULE } from '../../Models/Common';
import { populateProductDetailWithSelectedOrDefaultBom } from '../../Components/Mrp/WorkOrder/WorkOrderHelper';
import { selectApplySalesPersonFilter } from '../../Redux/Slices/BookFilterSlice';

export interface NewDocumentProps2 {
  booksDocument: Document;
  onDocumentUpdate: (document: Document) => void;
  isCenterAlign: boolean;
  documentMode?: DOCUMENT_MODE;
  draftData?: any;
  draftType?: any;
  canValidate?: any;
  isDocumentLoading?: boolean;
  updateDocWithoutClosing?: () => void;
  permissionKeys: any;
  autoProcessDoc?: boolean;
  auditLogView?: boolean;
  isConvertFromPOToBill?: boolean;
}

export default function NewDocument2(props: NewDocumentProps2) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  let history = useHistory();
  const lastUpdatedIndex = useRef<any>(null);
  const isDocDateUpdatedManually = useRef<boolean>(false);
  const isContactChangedManually = useRef<boolean>(false);
  const isQuantityChangedManually = useRef<boolean>(false);
  const isUOMChangedManually = useRef<boolean>(false);
  const isExchangeRateChangedManually = useRef<boolean>(false);
  const isDocContactAddressChangedManually = useRef<boolean>(false);
  const usTaxCalculateAlertVisible = useRef<boolean>(false);
  const [isTDSDeducted, setIsTDSDeducted] = useState(false);
  const shippingAddressBlock = useRef<any>(null);
  const additionalCharges = useRef<any>(null);
  const existingCascadingDiscountMethod = useRef<string>('');
  const showMainDocsInFullScreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const invoiceColumnConfig = useAppSelector(selectInvoicesColumnConfig);
  const invoiceConfigTableId = useAppSelector(
    selectInvoicesColumnConfigTableId
  );
  const billColumnConfig = useAppSelector(selectBillsColumnConfig);
  const billConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  const quoteColumnConfig = useAppSelector(selectQuotesColumnConfig);
  const quoteConfigTableId = useAppSelector(selectQuotesColumnConfigTableId);
  const salesOrderColumnConfig = useAppSelector(selectSalesOrderColumnConfig);
  const salesOrderConfigTableId = useAppSelector(
    selectSalesOrderColumnConfigTableId
  );
  const poColumnConfig = useAppSelector(selectPurchaseOrdersColumnConfig);
  const jobWorkOutColumnConfig = useAppSelector(selectWorkOutsColumnConfig);
  const poConfigTableId = useAppSelector(
    selectPurchaseOrdersColumnConfigTableId
  );
  const jobWorkOutConfigTableId = useAppSelector(
    selectWorkOutsColumnConfigTableId
  );
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const locationData = useAppSelector(locationsData);
  const productCFfromStore = useAppSelector(selectProductCustomFields);
  const paymentTerms = useAppSelector(selectPaymentTerms);
  const defaultAccounts = useAppSelector(selectDefaultAccounts);

  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];

  const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Saving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };
  const editAddressRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const currentDate = new Date();
  const laterDate = addDays(currentDate, 30);

  /* document level states */
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [booksDocument, setBooksDocument] = useState<any>(
    getBooksDocumentFromProps()
  );
  const [previousMemo, setPreviousMemo] = useState(
    props.booksDocument?.memo ?? ''
  );
  const [roundOffDirty, setRoundOffDirty] = useState<any>(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY
      ? true
      : false
  );
  const [dueAmount, setDueAmount] = useState<number>(
    (props.booksDocument.isPartialInvoice
      ? props.booksDocument.totalAmount
      : props.booksDocument.dueAmount) || 0
  );

  /* contact panel states  */
  const countriesWithTaxDependantOnContact = [COUNTRY_CODES.UK];
  const coutriesWithTaxAmountNonEditable = [COUNTRY_CODES.UK];
  const skipTaxDuration = 5000;
  const skipCalculateTaxLocal = useRef(false);
  const [showTaxGroupDetailsPopup, setShowTaxGroupDetailsPopup] =
    useState(false);
  const contactsData = useAppSelector(selectContacts);
  const [openContactSelector, setOpenContactSelector] = useState(false);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const [contact, setContact] = useState<any>(null);
  // const [showAddressPopup, setShowAddressPopup] = useState<boolean>(false);
  const [showUpdateAddressPopup, setShowUpdateAddressPopup] =
    useState<boolean>(false);
  const [booksAddressType, setBooksAddressType] = useState<BOOKS_ADDRESS_TYPES>(
    BOOKS_ADDRESS_TYPES.NONE
  );
  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType | null>(null);
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddressType | null>(null);
  const [editModeBillingAddresses, setEditModeBillingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.billingAddress);
  const [editModeShippingAddresses, setEditModeShippingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.shippingAddress);

  // setup payment states
  const [setupPaymentListOpen, setSetupPaymentListOpen] =
    useState<boolean>(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();
  const [showEmailPopupForPayment, setShowEmailPopupForPayment] =
    useState<boolean>(false);

  /* right info panel states */
  const [customNumberFormatModule, setCustomNumberFormatModule] = useState('');
  const [invoiceDateOpen, setInvoiceDateOpen] = useState(false);
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const [eInvoiceData, seteInvoiceData] = useState<any>(props.booksDocument);
  const [shipByDateOpen, setShipByDateOpen] = useState(false);
  const [shipByDate, setShipByDate] = useState<Date>(
    props.booksDocument?.fulfillmentDate
      ? DateFormatService.getDateFromStr(
          props.booksDocument.fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : laterDate
  );
  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [dueDate, setDueDate] = useState<Date>(laterDate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const priceBookList = useAppSelector(priceBookSelector);
  const [selectedPriceBook, setSelectedPriceBook] = useState<any>({
    id: 0,
    name: 'Default'
  });
  const [paymentAccountGroupsOptions, setPaymentAccountGroupsOptions] =
    useState<any[]>([]);
  const [isdocumentDateChanged, setIsdocumentDateChanged] =
    useState<Boolean>(false);
  /* Grid & summary states */
  const productsData = useAppSelector(selectProductsWithVariants);
  const uomsData = useAppSelector(selectUOMs);
  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const [columnConfig, setColumnConfig] = useState(
    DocumentConfigManager.get(props.booksDocument.documentType)
  );
  const [productRows, setProductRows] = useState<DocumentItem[]>([]);
  const [barcodeSearchData, setBarcodeSearchData] = useState<any[]>([]);
  const [barcodeCalculation, setBarcodeCalculation] = useState(false);

  const salesTaxes = useAppSelector(selectSalesTax);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const dimensionData = useAppSelector(selectDimensions);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [showTDSCalculationPopup, setShowTDSCalculationPopup] = useState(false);
  const [showITCPopup, setShowITCPopup] = useState(false);
  const [showIRPCredPopup, setShowIRPCredPopup] = useState(false);
  const [showCancelEInvoice, setShowCancelEInvoice] = useState(false);
  const [showLandedCostPopup, setShowLandedCostPopup] = useState(false);
  const addTaxRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const tdsAmountRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [productToAttachmentsMapping, setProductToAttachmentsMapping] =
    useState<{ [productId: string]: any[] }>();
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [currentRowTDSInfo, setCurrentRowTDSInfo] = useState<any>(null);
  const [currentRowITCInfo, setCurrentRowITCInfo] = useState<any>(null);
  const [currentRowHSNInfo, setCurrentRowHSNInfo] = useState<any>(null);
  const [showHSNPopup, setShowHSNPopup] = useState(false);
  const [currentRowGSTInfo, setCurrentRowGSTInfo] = useState<any>(null);
  const [showGSTPopup, setShowGSTPopup] = useState(false);
  const [currentRowTaxInfo, setCurrentRowTaxInfo] = useState<any>(null);
  const [showTaxRowPopup, setShowTaxRowPopup] = useState(false);

  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRateByDocDate
  );
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(-1); // used only for tracking addition of new product
  const [updateFromIndex, setUpdateFromIndex] = useState(-1);
  const [contactMode, setContactMode] = useState(DOCUMENT_MODE.NEW);
  const [showTaxExchangeRatePopup, setShowTaxExchangeRatePopup] =
    useState(false);
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const [existingLineNumbers, setExistingLineNumbers] = useState<number[]>([]);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();
  const [ignoreCalculateUSTax, setIgnoreCalculateUSTax] = useState(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY ||
      props.booksDocument.isPartialBill ||
      props.booksDocument.isPartialInvoice
  );
  const multiPaymentOptions = useRef<any>();
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const [currentRowLandedCostInfo, setCurrentRowLandedCostInfo] =
    useState<any>(null);

  const [openBillingAddressList, setOpenBillingAddressList] = useState(false);
  const [openShippingAddressList, setOpenShippingAddressList] = useState(false);
  const [openShipFromForBuy, setOpenShipFromForBuy] = useState(false);
  const [activeContactTab, setActiveContactTab] = useState<CONTACT_FORM_TAB>(
    CONTACT_FORM_TAB.GENERAL_INFO
  );
  const [activeOrgProfileTab, setActiveOrgProfileTab] =
    useState<ORGANISATION_FORM_TAB>(ORGANISATION_FORM_TAB.GENERAL_INFO);
  const [needToUpdateAddresses, setNeedToUpdateAddresses] = useState(
    props.booksDocument?.needToUpdateAddresses ? true : false
  );
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const [indianStatesOptions, setIndianStatesOptions] = useState<any>([]);
  const [pos, setPos] = useState<any>([]);
  const [dos, setDos] = useState<any>([]);
  const [showAutoChargePopup, setShowAutoChargePopup] = useState(false);
  const [hasShownProductLimitAlert, setHasShownProductLimitAlert] =
    useState(false);

  const [lineItemsTouched, setLineItemsTouched] = useState(false);
  const [dropshipShipToContact, setDropshipShipToContact] = useState<string>();

  const [productStockData, setProductStockData] = useState<any>({});
  const [showReserveStockPopup, setShowReserveStockPopup] = useState(false);
  const [showBarcodePopup, setShowBarcodePopup] = useState(false);
  const [shipToOrFromAddressChangedForUS, setShipToOrFromAddressChangedForUS] =
    useState(false);
  const [openProductCFSettings, setOpenProductCFSettings] = useState(false);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [filteredTaxList, setFilteredTaxList] = useState<any[]>([]);

  const [zeroAmountDocument, setZeroAmountDocument] = useState(
    booksDocument.totalAmount === 0
  );
  const [checkForCustomField, setCheckforCustomeField] = useState(true);
  const [paymentMilestoneBeingSaved, setPaymentMilestoneBeingSaved] =
    useState(false);
  const addPaymentMilestoneRef =
    useRef<UpdateCallBacksRefType>(refInitialState);
  const [showPaymentMilestonePopup, setShowPaymentMilestonePopup] =
    useState<boolean>(false);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  const [amortizationTmpLoading, setAmortizationTmpLoading] = useState(false);
  const eInvoiceAuthInfoData = useAppSelector(eInvoiceAuthInfo);
  const [amortizationTemplates, setAmortizationTemplates] = useState<any>([]);
  const [showProductSelectionPopup, setShowProductSelectionPopup] =
    useState(false);
  const [isBillToAddressUpdated, setIsBillToAddressUpdated] = useState(false);
  const [isShipToAddressUpdated, setIsShipToAddressUpdated] = useState(false);
  const [isShipFromAddressUpdated, setIsShipFromAddressUpdated] =
    useState(false);
  const [isContactUpdated, setIsContactUpdated] = useState(false);
  const [showDefaultAccountSettings, setShowDefaultAccountsSettings] =
    useState(false);
  const [doesDefaultRCMAccountExists, setDoesDefaultRCMAccountExists] =
    useState(false);
    const canApplySalesPersonFilterOnContact = useAppSelector(
      selectApplySalesPersonFilter
    );
  const isProductGroupingEnabled =
    tenantInfo?.additionalSettings?.PRODUCT_GROUP_ENABLED;
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const isDocumentInFullScreen =
    showMainDocsInFullScreen &&
    DOCS_SUPPORTING_FULLSCREEN.includes(booksDocument.documentType);
  const cfUpdatedTimeMap = useRef<any>({});
  const blankProductGroupRef = useRef({
    id: 0,
    name: '',
    uuid: getRandomHexString(),
    index: 0
  });

  function getBooksDocumentFromProps() {
    return {
      ...props.booksDocument,
      showComponentList: false,
      previousExchangeRate: props.booksDocument?.exchangeRate || 1
    };
  }
  const [isClearAmortizationSelection, setIsClearAmortizationSelection] =
    useState(false);

  useEffect(() => {
    const billEditCustomFieldCheck =
      !(
        props.booksDocument.documentType === DOC_TYPE.BILL &&
        (booksDocument.receiveGoodsStatus !==
          RECEIVED_GOODS_STATUS.NOT_RECEIVED ||
          booksDocument.paymentStatus !== PAYMENT.PENDING)
      ) || booksDocument.totalAmount === 0;
    const invoiceEditCustomFieldCheck =
      !(
        props.booksDocument.documentType === DOC_TYPE.INVOICE &&
        (booksDocument.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED ||
          booksDocument.paymentStatus !== PAYMENT.PENDING)
      ) || booksDocument.totalAmount === 0;
    if (props.draftType === DraftTypes.UPDATE) {
      if (props.booksDocument.documentType === DOC_TYPE.BILL) {
        setCheckforCustomeField(billEditCustomFieldCheck);
      } else if (props.booksDocument.documentType === DOC_TYPE.INVOICE) {
        setCheckforCustomeField(invoiceEditCustomFieldCheck);
      } else {
        setCheckforCustomeField(true);
      }
    }
    //if attachments forwarded from work order
    if (props.draftData?.data?.attachmentList) {
      let attachmentsList = props.draftData?.data?.attachmentList;
      setAttachments([...attachments, ...attachmentsList]);
      setNewAttachments([...newAttachments, ...attachmentsList]);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          attachmentIds: attachmentsList.map(
            (attachment: any) => attachment.attachmentId
          ),
          attachments: attachmentsList.map((attachment: any) =>
            JSON.stringify(attachment)
          )
        };
      });
    }
  }, []);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  /* Roles and Permissions */
  useEffect(() => {
    if (isClearAmortizationSelection === true) {
      ClearAmortizationSelection();
    }
  }, [isClearAmortizationSelection]);

  useEffect(() => {
    let isIndiaTenantAndRcmDoc =
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      RCM_DOCUMENTS.includes(props.booksDocument.documentType)
        ? true
        : false;

    if (isIndiaTenantAndRcmDoc) {
      let isRCMApplicable = false;
      if (!!props?.booksDocument?.applyRcmCheck) {
        isRCMApplicable = props?.booksDocument?.applyRcmCheck as boolean;
      } else {
        isRCMApplicable = false;
      }

      setBooksDocument((prev: any) => {
        return { ...prev, applyRcmCheck: isRCMApplicable };
      });
    }
  }, [props.booksDocument.applyRcmCheck, props.booksDocument.documentType]);

  useEffect(() => {
    if (
      lastUpdatedIndex.current !== null &&
      !Utility.isEmpty(productRows[lastUpdatedIndex.current as any]?.product) &&
      Utility.isEmpty(barcodeSearchData)
    ) {
      let index = lastUpdatedIndex.current as number;
      lastUpdatedIndex.current = null;
      calculateTaxAfterProductRowsUpdate(index);
    } else {
      setBarcodeSearchData([]);
    }
    if (!hasShownProductLimitAlert) {
      if (productRows.length >= 50) {
        setHasShownProductLimitAlert(true);
        showAlert(
          'Warning',
          'Application may behave slower as there are more than 50 line items in the form.'
        );
      }
    }
  }, [productRows]);
  const calculateTaxAfterProductRowsUpdate = (
    index: number,
    isBarcode?: boolean | undefined
  ) => {
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      lastUpdatedColumn &&
      lastUpdatedColumn !== 'taxAmount'
    ) {
      calculateUSTax(index);
    } else {
      setLastUpdatedColumn(null);
      return calculateTaxesAndAmount(index, undefined, isBarcode);
    }
  };

  useEffect(() => {
    if (
      RCM_DOCUMENTS.includes(props.booksDocument.documentType) &&
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST
    ) {
      let documentItems = [...(booksDocument.items as Array<DocumentItem>)];
      let gstType = updateGstType(booksDocument);
      documentItems = documentItems.map((item, index) => {
        return { ...item, gstType: gstType };
      });
      documentItems.map((item, index) =>
        calculateTaxesAndAmount(index, documentItems)
      );
      setBooksDocument((prevState: Document) => {
        return {
          ...prevState,
          gstType: gstType,
          items: [...documentItems]
        };
      });
    }
  }, [booksDocument.applyRcmCheck]);

  useEffect(() => {
    updateConfig();
  }, [
    productsData,
    salesTaxes,
    purchaseTaxes,
    uomsData,
    productRows,
    selectedProductPriceList,
    selectedProductUOMS,
    dimensionData,
    productCFfromStore,
    props.draftType,
    props.documentMode
  ]);

  const updateTaxList = () => {
    let taxData = Utility.isSalesDocument(props.booksDocument)
      ? salesTaxes
      : purchaseTaxes;
    const tmpDocumentDate = new Date(documentDate.setHours(0, 0, 0, 0));
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          tmpDocumentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          tmpDocumentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          tmpDocumentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  useEffect(() => {
    if (
      !Utility.isEmpty(activeMultiCurrencyList) &&
      isdocumentDateChanged === true
    ) {
      setIsdocumentDateChanged(false);
      const currencyCode = booksDocument.currency;
      const currexchangeRate = booksDocument?.isConverting
        ? booksDocument.exchangeRate
        : activeMultiCurrencyList.find(
            (data) => data.currencyCode == currencyCode
          )?.currencyExchangeRate;
      let tempDocument: any = { ...booksDocument, isDocumentTouched: true };

      const gstExchangeRate = tempDocument.gstExchangeRate;
      const preciseCurrencyExchangeRate = roundingOffStr(
        1 / currexchangeRate,
        CURRENCY_PRECISION
      );
      const oldCurrency = tempDocument.currency;
      const currencyChanged = oldCurrency !== currencyCode;
      const exchangeRate = 1 / parseFloat(preciseCurrencyExchangeRate);
      const previousExchangeRate = tempDocument.exchangeRate;
      let calculatedGSTExchangeRate = 1;

      if (currencyChanged && gstExchangeRate) {
        calculatedGSTExchangeRate =
          (gstExchangeRate * exchangeRate) / tempDocument.previousExchangeRate;
      }

      let primaryExchangeRate = booksDocument?.primaryExchangeRate;
      if (getPrimaryCurrencyCheck()) {
        const primaryCurrencyCode =
          tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
        primaryExchangeRate = activeMultiCurrencyList.find(
          (data) => data.currencyCode == primaryCurrencyCode
        )?.currencyExchangeRate;
      }

      tempDocument = {
        ...tempDocument,
        currencyCode: currencyCode,
        currency: currencyCode,
        exchangeRate: exchangeRate,
        previousExchangeRate: previousExchangeRate,
        gstExchangeRate: calculatedGSTExchangeRate,
        primaryExchangeRate: primaryExchangeRate
      };

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument
        };
      });
    }
  }, [activeMultiCurrencyList]);

  useEffect(() => {
    if (documentDate && documentDate instanceof Date) {
      const docDate = addDays(documentDate, 1);
      dispatch(
        fetchCurrencyExchangeRateByDocDate(
          DateFormatService.getDateStrFromDate(
            docDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        )
      );

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          documentDate: DateFormatService.getDateStrFromDate(
            documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
      if (isDocDateUpdatedManually.current) {
        const updatedValidTillDate = getValidTillDateFromDocDate(
          documentDate,
          contact
        );
        setDueDate((prevState) =>
          updatedValidTillDate ? updatedValidTillDate : prevState
        );
        setShipByDate(documentDate);

        if (productRows.length && isDocDateUpdatedManually.current) {
          //Update tax object on line item level for new tax system of sg and Malaysia
          let mappedProductRows = [...productRows];
          if (
            getTenantTaxSystem() === TAX_SYSTEM.SG ||
            getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA
          ) {
            mappedProductRows = mappedProductRows.map(
              (item: any, index: number) => {
                if (item && Object.keys(item.product).length !== 0) {
                  let taxObject = getTax(item?.product);
                  let copyItem = { ...item, tax: taxObject };
                  ItemTaxCalculator.item = copyItem;
                  ItemTaxCalculator.setInitialValues();
                  const taxAmount = ItemTaxCalculator.calculateTaxAmount();
                  ItemTaxCalculator.item.taxAmount = taxAmount;
                  ItemTaxCalculator.updateCalculatedValues();
                  return {
                    ...ItemTaxCalculator.item,
                    taxCode: taxObject?.code,
                    taxName: taxObject?.name
                  };
                } else {
                  return item;
                }
              }
            );
            setProductRows(mappedProductRows);
            setBooksDocument((prevState: any) => {
              return {
                ...prevState,
                items: mappedProductRows
              };
            });
          }

          const productsPayload = mappedProductRows
            .filter((item) => !Utility.isEmpty(item.product))
            .map((item) => ({
              productId: item.product.productId,
              uomId: item.documentUom,
              quantity: +item.productQuantity
            }));
          getPricing(
            productsPayload,
            mappedProductRows,
            true,
            booksDocument.currency,
            true,
            booksDocument.exchangeRate,
            { dateChanged: true }
          );
        }

        if (
          getTenantTaxSystem() === TAX_SYSTEM.US &&
          Utility.isSalesDocument(props.booksDocument)
        ) {
          calculateUSTax(undefined);
        }
      }
    }
  }, [documentDate]);

  useEffect(() => {
    if (shipByDate && shipByDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          fulfillmentDate: DateFormatService.getDateStrFromDate(
            shipByDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
  }, [shipByDate]);

  useEffect(() => {
    if (dueDate && dueDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          validTillDate: DateFormatService.getDateStrFromDate(
            dueDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
  }, [dueDate]);

  /** For CRM Quote grouping feature, ZEN-12975 */
  const initializeProductGroupUuid = () => {
    const lineItems = props.booksDocument?.items || [];
    const lineItemWithBlankGroup = lineItems.find(
      (lineItem) =>
        !lineItem.productGroupId && Utility.isEmpty(lineItem.productGroupName)
    );

    if (lineItemWithBlankGroup) {
      blankProductGroupRef.current = {
        id: lineItemWithBlankGroup.productGroupId as number,
        name: lineItemWithBlankGroup.productGroupName as string,
        uuid: lineItemWithBlankGroup.productGroupUuid as string,
        index: lineItemWithBlankGroup.productGroupIndex as number
      };
    }
  };

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
    /**
     * Custom number format module
     */
    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        initializeProductGroupUuid();
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.INVOICE
        );
        break;
      case DOC_TYPE.BILL:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.BILL
        );
        break;
      case DOC_TYPE.ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER
        );
        break;
      case DOC_TYPE.QUOTE:
        initializeProductGroupUuid();
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.QUOTE
        );
        break;
      case DOC_TYPE.SALES_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER
        );
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT
        );
        break;
    }
    seteInvoiceData(props.booksDocument);

    if (props.draftData.actionFromDocument) {
      // Evaluate taxSystem and gstType
      setBooksDocument({
        ...props.booksDocument,
        taxSystem: getTenantTaxSystem(),
        gstType: booksDocument.gstType
      });
      setTimeout(() => {
        if (props.booksDocument.items) {
          // CASCADING_DISCOUNTS: handle config update when action is performed using action menu in bottom
          let lineItems = !Utility.isEmpty(props.booksDocument?.items)
            ? deepClone(props.booksDocument?.items)
            : [];
          let updatedLineItemsWithDiscountConfigs = null;
          const cascadingDiscountSettings =
            tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
          if (cascadingDiscountSettings?.enable) {
            updatedLineItemsWithDiscountConfigs =
              getLineItemsWithDiscountColumnConfig([...lineItems]);
          }

          let tempConfigs;
          if (updatedLineItemsWithDiscountConfigs) {
            lineItems = [...updatedLineItemsWithDiscountConfigs?.items];
            tempConfigs = updatedLineItemsWithDiscountConfigs?.updatedConfigs;
          }

          let updatedLineItemsWithCFConfigs = getLineItemsWithCFColumnConfig(
            [...lineItems],
            [],
            tempConfigs
          );

          if (updatedLineItemsWithCFConfigs?.items) {
            lineItems = [...updatedLineItemsWithCFConfigs.items];
          }

          if (updatedLineItemsWithCFConfigs?.updatedConfigs) {
            setColumnConfig([...updatedLineItemsWithCFConfigs?.updatedConfigs]);
          }

          lineItems = getLineItemsWithNonEditableCells(lineItems);
          if (lineItems && lineItems.length > 0) {
            lineItems.forEach((rowData, index) => {
              calculateTaxesAndAmount(index, lineItems);
              calculateAndSetUomRelatedDataOnEdit(index, lineItems);
            });
          }
        }
      }, 100);
      if (
        typeof props.booksDocument?.dueAmount !== 'undefined' &&
        typeof props.booksDocument?.dueAmount === 'number'
      ) {
        setDueAmount(props.booksDocument.dueAmount);
      }
    }
  }, [props.booksDocument]);

  useEffect(() => {
    if (eInvoiceData) {
      getInvoiceNow(eInvoiceData);
    }
  }, [eInvoiceData]);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  useEffect(() => {
    if (!showPaymentMilestonePopup) {
      setPaymentMilestoneBeingSaved(false);
    }
  }, [showPaymentMilestonePopup]);

  const calculateAndSetUomRelatedDataOnEdit = (
    rowIndex: number,
    unsavedRows?: any
  ) => {
    let item = unsavedRows[rowIndex];
    let clonedItem: any = {};

    let unitPrice = item.unitPrice;

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      (item.isPartialInvoice ||
        item.isPartialBill ||
        item.isPartialSalesOrder) &&
      item.product?.stockUom === UOM_NA_ID
    ) {
      unitPrice = item.pendingAmount;
    }

    if (
      item.type === PRODUCT_TYPE.NON_TRACKED &&
      item.product?.stockUom === UOM_NA_ID
    ) {
      if (item.nonEditableColumns?.length) {
        item.nonEditableColumns = [
          ...item.nonEditableColumns,
          'productQuantity'
        ];
      } else {
        item.nonEditableColumns = ['productQuantity'];
      }
    }

    item.unitPrice = unitPrice;

    item.uom = getSelectedUomForEdit(item);
    if (item.documentUOMSchemaDefinition) {
      item.unitPrice = item.uomUnitPrice || item.unitPrice;
      item.productQuantity = item.uomQuantity || item.productQuantity;
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      clonedItem = deepClone(item);
    }

    ItemTaxCalculator.item = item;
    if (!ItemTaxCalculator.item.taxAmount) {
      ItemTaxCalculator.item.taxAmount = 0;
    }
    ItemTaxCalculator.tenantInfo = tenantInfo;
    ItemTaxCalculator.setInitialValues();
    let taxAmount = 0;
    if (skipCalculateTaxLocal.current) {
      taxAmount = item.taxAmount;
    } else if (item.userSetTaxes) {
      taxAmount = item?.taxAmount;
    } else {
      taxAmount = ItemTaxCalculator.calculateTaxAmount();
      // TODO: HANDLE MANUAL TAX AMOUNT UPDATE HERE
    }
    ItemTaxCalculator.item.taxAmount = taxAmount;
    ItemTaxCalculator.updateCalculatedValues();
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      (props.documentMode !== DOCUMENT_MODE.NEW ||
        props.booksDocument.isPartialInvoice ||
        !Utility.isEmpty(contact))
    ) {
      let row = { ...unsavedRows[rowIndex] };
      setGSTValueManual(rowIndex, clonedItem, clonedItem);
    }

    unsavedRows[rowIndex] = { ...ItemTaxCalculator.item };
    lastUpdatedIndex.current = rowIndex;
    setProductRows([...unsavedRows]);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...unsavedRows]
      };
    });
  };

  const getLineItemsWithCFColumnConfig = (
    items: any[],
    existingCFs?: any[],
    tempConfig?: any[]
  ) => {
    let productCFs: any[] = [];
    let tempColConfig: any[] = [];
    if (tempConfig && tempConfig?.length > 0) {
      tempColConfig = tempConfig;
    } else {
      tempColConfig = [...columnConfig];
    }
    if (existingCFs && existingCFs.length) {
      productCFs = [...existingCFs];
    } else if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      productCFs = [...productCFfromStore?.content];
    }
    productCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );
    if (!Utility.isEmpty(productCFs)) {
      let updatedConfigs = [...tempColConfig].filter(
        (field: any) => !field.isCustomField
      );

      productCFs?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(
          prodCF,
          props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY
        );
        updatedConfigs.push(newItem);
      });

      const isPartialOrDropshipOrBackorderDoc =
        booksDocument?.isPartialBill ||
        booksDocument?.isPartialInvoice ||
        booksDocument?.isPartialSalesOrder ||
        booksDocument?.dropShip ||
        booksDocument?.backOrder || items?.length > 0
      if (
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          props.draftType !== 'new' ||
          isPartialOrDropshipOrBackorderDoc) &&
        !Utility.isEmpty(productCFs)
      ) {
        items?.forEach((lineItem: any, lineItemIndex: any) => {
          lineItem?.customField?.forEach((item: any, index: any) => {
            let filteredCF: any = productCFs?.find(
              (cf: any) => cf.id === item.id
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW &&
                  props.draftType !== DraftTypes.READONLY
              );

              let alreadyPresentConfig = updatedConfigs?.find(
                (col: any) => col.id === item.id
              );
              if (Utility.isEmpty(alreadyPresentConfig)) {
                updatedConfigs.push(newItem);
              }

              if (filteredCF) {
                if (
                  typeof item.value !== 'undefined' &&
                  item.value !== null &&
                  item.value !== ''
                ) {
                  if (
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    items[lineItemIndex][item.id] =
                      DateFormatService.getDateFromStr(
                        item.value,
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      );
                  } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                    const tempCF = filteredCF?.attributes?.find(
                      (attr: any) => attr.code === item.value
                    );
                    if (tempCF) {
                      items[lineItemIndex][item.id] = tempCF.value;
                    }
                  } else {
                    items[lineItemIndex][item.id] = item.value;
                  }
                } else {
                  items[lineItemIndex][item.id] = '';
                }
              }
            }
          });
        });
      }
      return { items, updatedConfigs };
    } else {
      return { items: null, updatedConfigs: [...tempColConfig] };
    }
  };

  const updateContactBuyerReference = (contactObj: any) => {
    const contactCF = contactObj?.customField?.find(
      (cf: any) => cf?.label === 'Buyer Reference'
    );
    if (contactCF) {
      let value = contactCF?.value;
      setBooksDocument((prevState: any) => {
        const newState = { ...prevState, buyerReference: value };
        return newState;
      });
    }
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let updatedLineItemsWithConfigs = getLineItemsWithCFColumnConfig(
      [...booksDocument.items],
      updatedCFs
    );
    const classData = dimensionData?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    let updatedConfigs = [...updatedLineItemsWithConfigs.updatedConfigs];
    updatedConfigs = updatedConfigs.map((cf: any) => {
      if (cf?.id === classData?.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(updatedConfigs.filter((col: any) => !col.hidden));
  };

  const getLineItemsWithNonEditableCells = (lineItems: any[]) => {
    lineItems = lineItems.map((item: any) => {
      let updatedItem = { ...item, isTaxable: true };
      if (
        Utility.isMRPWithURLCheck() &&
        (props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType ===
            DOC_TYPE.PURCHASE_INWARD_QUOTATION) &&
        typeof updatedItem.expectedDeliveryDt === 'string'
      ) {
        updatedItem.expectedDeliveryDt = DateFormatService.getDateFromStr(
          updatedItem.expectedDeliveryDt,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
      }
      if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedItem = {
          ...updatedItem,
          isTaxable: updatedItem.product
            ? updatedItem?.product.exemptedMalaysia
            : true
        };
      } else if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        updatedItem = {
          ...updatedItem,
          isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
            props?.booksDocument?.documentType,
            props?.booksDocument?.contact?.gstTreatment,
            updatedItem?.product,
            booksDocument?.applyRcmCheck
          )
        };
      }
      if (
        (typeof updatedItem?.product?.taxPreference !== 'undefined' &&
          updatedItem?.product?.taxPreference !== null &&
          !updatedItem?.product?.taxPreference) ||
        !updatedItem.isTaxable
      ) {
        updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
          ? [...updatedItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedItem?.nonEditableColumns?.push('tax');
      }
      if (
        updatedItem?.product?.taxable === false &&
        !updatedItem?.nonEditableColumns?.includes('taxAmount')
      ) {
        updatedItem.nonEditableColumns = updatedItem?.nonEditableColumns
          ? [...updatedItem?.nonEditableColumns]
          : [];
        if (updatedItem?.nonEditableColumns?.length > 1) {
          updatedItem.nonEditableColumns.push('taxAmount');
        } else {
          updatedItem.nonEditableColumns = ['taxAmount'];
        }
      }
      if (updatedItem?.isRcmApplied) {
        let copyOfNonEditableColumns = updatedItem?.nonEditableColumns || [];
        copyOfNonEditableColumns =
          copyOfNonEditableColumns?.filter(
            (field: string) => field !== 'tax'
          ) || [];
        copyOfNonEditableColumns?.push('tax');
        updatedItem.nonEditableColumns = copyOfNonEditableColumns;
      }

      if (updatedItem) {
        updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
          ? [...updatedItem.nonEditableColumns].filter(
              (field) => field !== 'allocationType'
            )
          : [];
      }

      updatedItem?.nonEditableColumns?.push('allocationType');

      // disable product change for converted document
      if (updatedItem?.linkedQuoteItem || updatedItem?.linkedOrderItem) {
        updatedItem.nonEditableColumns?.push('product');
      }

      if (
        props.booksDocument?.reservedStock &&
        props.draftType !== DraftTypes.DRAFT
      ) {
        updatedItem.nonEditableColumns?.push('productQuantity', 'uom');
      }

      if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
        updatedItem.nonEditableColumns?.push(
          'receivedQuantity',
          'balanceReceivedQuantity',
          'billedQuantity',
          'balanceBilledQuantity'
        );
      }

      return updatedItem;
    });

    return lineItems;
  };

  // CASCADING_DISCOUNT: Show total discount column.
  const getTotalDiscountColumn = (updatedConfigs: any[]) => {
    let taxOrTotalColumnIndexRef = -1;
    if (Utility.isUSorg()) {
      let configKeyRef = '';
      if (Utility.isSalesDocument(booksDocument) && !hideTaxAmountColumn()) {
        configKeyRef = 'taxAmount';
      } else {
        configKeyRef = 'totalAmount';
      }
      taxOrTotalColumnIndexRef = updatedConfigs.findIndex(
        (config: any) => config.key === configKeyRef
      );
    } else {
      taxOrTotalColumnIndexRef = updatedConfigs.findIndex(
        (config: any) => config.key === 'tax'
      );
    }
    if (taxOrTotalColumnIndexRef !== -1) {
      const newConfig = getNewColumnForTotalDiscount();
      updatedConfigs.splice(taxOrTotalColumnIndexRef, 0, newConfig);
    }
    return updatedConfigs;
  };

  // CASCADING_DISCOUNT: get cascading discounts ordered by level from global settings
  const getOrderedCascadingDiscountsFromSettings = () => {
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      let orderedDiscounts = !Utility.isEmpty(
        cascadingDiscountSettings?.discountDetails
      )
        ? [...cascadingDiscountSettings.discountDetails]
        : [];
      orderedDiscounts.sort(
        (discount1: any, discount2: any) => discount1.level - discount2.level
      );
      return orderedDiscounts;
    }
    return [];
  };

  // CASCADING_DISCOUNT: get only updated configs with cascading discounts
  const getUpdatedColumnConfigsWithDiscounts = () => {
    let updatedConfigs = [...columnConfig];
    let unitPriceColumnIndexRef = updatedConfigs.findIndex(
      (config: any) => config.key === 'unitPrice'
    );
    if (unitPriceColumnIndexRef !== -1) {
      unitPriceColumnIndexRef += 1;
    }

    const orderedDiscounts = getOrderedCascadingDiscountsFromSettings();
    orderedDiscounts.forEach((discount: any) => {
      // Need to handle buy/sell discounts
      const discountFromStore = getCascadingDiscountsFromStore(
        booksDocument
      )?.find((dis: any) => dis.id === discount.discountId);
      if (!Utility.isEmpty(discountFromStore)) {
        const newDiscountConfig = getNewColumnForDiscount(discountFromStore);
        unitPriceColumnIndexRef += 1;
        updatedConfigs.splice(unitPriceColumnIndexRef, 0, newDiscountConfig);
      }
    });

    return updatedConfigs;
  };

  // CASCADING_DISCOUNT: check if column config exists for a discount
  const discountConfigPresent = (key: string) => {
    return (
      columnConfig?.filter((config: any) => config.key === key)?.length === 1
    );
  };

  // CASCADING_DISCOUNT: check if totalDiscountAmount column config exists for a discount
  const totalDiscountConfigPresent = () => {
    return (
      columnConfig?.filter(
        (config: any) => config.key === 'totalDiscountAmount'
      )?.length === 1
    );
  };

  // CASCADING_DISCOUNT: get lineitems with updated column config
  const getLineItemsWithDiscountColumnConfig = (lineItems: any[]) => {
    let updatedConfigs = [...columnConfig];
    let unitPriceColumnIndexRef = updatedConfigs.findIndex(
      (config: any) => config.key === 'unitPrice'
    );
    if (unitPriceColumnIndexRef !== -1) {
      unitPriceColumnIndexRef += 1;
    }
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    const discountType = cascadingDiscountSettings?.discountType;

    let orderedDiscounts = !Utility.isEmpty(
      cascadingDiscountSettings?.discountDetails
    )
      ? [...cascadingDiscountSettings.discountDetails]
      : [];
    orderedDiscounts.sort(
      (discount1: any, discount2: any) => discount1.level - discount2.level
    );
    if (lineItems?.length) {
      lineItems = [...lineItems].map((lineItem: any, lineItemIndex: number) => {
        let existingLineDiscounts: any[] = [];
        if (!Utility.isEmpty(lineItem.additionalCharges?.globalDiscounts)) {
          if (Utility.isEmpty(existingCascadingDiscountMethod.current)) {
            existingCascadingDiscountMethod.current =
              lineItem?.itemDiscountMethod;
          }
          // Handle existing documents with cascading discounts
          existingLineDiscounts = lineItem.additionalCharges?.globalDiscounts
            ?.length
            ? lineItem.additionalCharges?.globalDiscounts?.filter(
                (disc: any) => disc.isItemDiscount
              )
            : [];
          // Build configs and lineitem based on ordered discounts from settings, existing discounts and discounts in store
          existingLineDiscounts.forEach((lineDiscount: any, index: number) => {
            if (!Utility.isEmpty(lineDiscount)) {
              // Update discount from Store with existing discount values
              const isPercent = lineDiscount?.isPercent;
              lineDiscount = {
                ...lineDiscount,
                chargeValue: isPercent ? 0 : lineDiscount?.amount,
                isPercent: isPercent,
                percentageValue: isPercent ? lineDiscount?.percent : 0
              };
              const newDiscountConfig = getNewColumnForDiscount(lineDiscount);
              if (
                lineItemIndex === 0 &&
                !discountConfigPresent(newDiscountConfig?.key)
              ) {
                unitPriceColumnIndexRef += 1;
                updatedConfigs.splice(
                  unitPriceColumnIndexRef,
                  0,
                  newDiscountConfig
                );
              }
              let discountValue = 0;
              if (lineDiscount.isPercent) {
                discountValue = +lineDiscount.percentageValue;
              } else {
                discountValue = +lineDiscount.chargeValue;
              }
              const discountKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${lineDiscount.id}`;
              const discountDetailsKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${lineDiscount.id}_details`;
              lineItem[discountKeyToUpdate] = lineDiscount.isPercent
                ? discountValue + '%'
                : discountValue;
              lineItem[discountDetailsKeyToUpdate] = {
                id: lineDiscount.id,
                discount: discountValue,
                isPercent: lineDiscount.isPercent,
                accountCode: lineDiscount.accountCode,
                name: lineDiscount.name,
                discountIndex: index,
                unitDiscount: 0
              };
            }
          });
        }
        return lineItem;
      });
      if (!totalDiscountConfigPresent()) {
        updatedConfigs = getTotalDiscountColumn(updatedConfigs);
      }
      return { items: lineItems, updatedConfigs: updatedConfigs };
    } else {
      updatedConfigs = getUpdatedColumnConfigsWithDiscounts();
      if (!totalDiscountConfigPresent()) {
        updatedConfigs = getTotalDiscountColumn(updatedConfigs);
      }
      return { items: lineItems, updatedConfigs: updatedConfigs };
    }
  };

  useEffect(() => {
    loadMultiPaymentOptions();
    AppManager.handleWindowFocusListeners(loadMultiPaymentOptions, true);
    setIsCenterAlign(props.isCenterAlign);

    // Add a blank row for new document
    if (props.documentMode === DOCUMENT_MODE.NEW && !productRows.length) {
      if (!isProductGroupingEnabled) {
        addNewItem();
      }
    }

    let lineItems = [...(props.booksDocument?.items as DocumentItem[])];

    if (lineItems?.length && props.documentMode === DOCUMENT_MODE.COPY) {
      setExistingLineNumbers(lineItems?.map((item) => item.lineNumber));
    }

    let updatedLineItemsWithDiscountConfigs = null;
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      updatedLineItemsWithDiscountConfigs =
        getLineItemsWithDiscountColumnConfig([...lineItems]);
    }

    let tempConfigs;
    if (updatedLineItemsWithDiscountConfigs) {
      lineItems = [...updatedLineItemsWithDiscountConfigs?.items];
      tempConfigs = updatedLineItemsWithDiscountConfigs?.updatedConfigs;
    }

    let updatedLineItemsWithCFConfigs = getLineItemsWithCFColumnConfig(
      [...lineItems],
      [],
      tempConfigs
    );

    if (updatedLineItemsWithCFConfigs?.items) {
      lineItems = [...updatedLineItemsWithCFConfigs.items];
    }

    if (updatedLineItemsWithCFConfigs?.updatedConfigs) {
      setColumnConfig([...updatedLineItemsWithCFConfigs?.updatedConfigs]);
    }

    lineItems = lineItems?.map((item) => {
      if (Utility.isEmpty(item.invalidFields)) {
        item.invalidFields = [];
      }
      item = checkIfLineLevelCustomFieldIsValid(
        item,
        productCFfromStore?.content
      );
      return item;
    });

    lineItems = getLineItemsWithNonEditableCells(lineItems);
    lineItems = lineItems
      .sort((item1: any, item2: any) => item1.lineNumber - item2.lineNumber)
      .map((item: any, index: any) => {
        return {
          ...item,
          lineNumber: index + 1
        };
      });
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let states: ReactOptionType[] = [];
      const indianStates = INDIAN_STATES_MOCK_DATA;
      indianStates.forEach((state) => {
        let option: ReactOptionType = {
          label: state.name,
          value: state.code,
          code: state.stateCode,
          searchableKey: `${state.name} ${state.stateCode}`
        };
        states.push(option);
      });
      setIndianStatesOptions(states);
    }

    // updateConfig();
    if (props.documentMode) {
      // Don't show tooltip in VIEW mode
      DocumentConfigUtility.documentMode = props.documentMode;
    }
    // Set doc type in dicument config utility
    DocumentConfigUtility.documentType = props.booksDocument.documentType;

    if (tenantInfo.country === COUNTRY_CODES.UK) {
      skipCalculateTaxLocal.current = true;
      setTimeout(() => {
        skipCalculateTaxLocal.current = false;
      }, skipTaxDuration);
    }

    if (lineItems && lineItems.length > 0) {
      lineItems.forEach((rowData, index) => {
        calculateTaxesAndAmount(index, [...(lineItems as DocumentItem[])]);
        calculateAndSetUomRelatedDataOnEdit(index, [
          ...(lineItems as DocumentItem[])
        ]);
      });
    }

    if (
      props.documentMode !== DOCUMENT_MODE.NEW &&
      props.booksDocument.documentType === DOC_TYPE.INVOICE &&
      !Utility.isEmpty(props.booksDocument?.paymentInformation)
    ) {
      setSelectedPaymentOption(props.booksDocument.paymentInformation);
    }

    let documentUpdates: any = {};
    if (!Utility.isEmpty(props.booksDocument.contact)) {
      if (
        ((props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
          props.draftType === DraftTypes.DRAFT) ||
        countriesWithTaxDependantOnContact.includes(tenantInfo.country)
      ) {
        setDetailedContact(props.booksDocument.contact.id);
      } else {
        setContact(props.booksDocument.contact);
      }
    } else {
      // Set default compliance fields
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        documentUpdates.gstType = Utility.getIndiaDefaultTaxType(null, null);
      }
    }
    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      Utility.isSalesDocument(props.booksDocument)
    ) {
      const tempShipFromAddress = getShipFromAddressForNewSalesDocument();
      documentUpdates.shipFrom = tempShipFromAddress;
    }

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      !Utility.isDropship(props.booksDocument) &&
      !props.draftData?.data?.draftCode
    ) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(true);
      if (billingAddress) {
        documentUpdates.billTo = billingAddress;
      }
      if (shippingAddress) {
        documentUpdates.shipTo = shippingAddress;
      }
    }

    if (props.booksDocument.documentDate) {
      setDocumentDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.fulfillmentDate) {
      setShipByDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.validTillDate) {
      setDueDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    // fetch the price books if it is not available in store.
    if (tenantInfo?.isAdvancePriceListEnabled) {
      if (Utility.isEmptyObject(priceBookList)) {
        let query = `isAdvance=true`;
        dispatch(fetchPriceBookList({ search: '', limit: 500, query }));
      } else {
        let priceBook = DEFAULT_PRICE_BOOK;
        if (
          typeof booksDocument?.priceListId !== 'undefined' &&
          booksDocument?.priceListId !== null &&
          booksDocument?.priceListId !== DEFAULT_PRICE_BOOK_ID
        ) {
          priceBook = priceBookList.content?.find(
            (pb: any) => pb.id === booksDocument?.priceListId
          );
          if (!Utility.isEmpty(priceBook)) {
            setSelectedPriceBook(priceBook);
          }
        }
      }
    }

    if (
      (props.draftType === DraftTypes.DRAFT ||
        props.draftData?.data?.isReadOnlyDraft === true) &&
      props.booksDocument.attachments
    ) {
      const newAttachments: any[] = props.booksDocument.attachments.map(
        (attachment: any) => JSON.parse(attachment)
      );
      setAttachments(newAttachments);
      setNewAttachments(newAttachments);
    } else {
      if (
        props.booksDocument.documentType !== DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        fetchAttachments();
      }
    }

    if (!contactsData?.content) {
      const config: ContactAPIConfig = {
        ...ContactService?.apiConfig
      };
      if (
        canApplySalesPersonFilterOnContact.canApplyFilter &&
        !config.Query?.includes('salesperson') &&
        canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id
      ) {
        let configQuery = config?.Query ? config?.Query + ',' : '';
        config.Query =
          configQuery +
          `salesperson=${canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id}`;
        ContactService.apiConfig = config;
      }
      dispatch(fetchContacts());
    }

    // if (!productsData?.content) {

    switch (props?.booksDocument?.documentType) {
      case DOC_TYPE.QUOTE:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_SALES
          })
        );
        break;
      case DOC_TYPE.SALES_ORDER:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_SALES
          })
        );
        break;
      case DOC_TYPE.INVOICE:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_SALES
          })
        );
        break;
      case DOC_TYPE.BILL:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_PURCHASE
          })
        );
        break;
      case DOC_TYPE.ORDER:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_PURCHASE
          })
        );
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false,type=BILL_OF_MATERIALS',
            params: PRODUCT_TRANSACTIONTYPE_PURCHASE
          })
        );
        break;
      default:
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: ''
          })
        );
    }

    // }

    if (!salesTaxes?.length || !purchaseTaxes?.length) {
      dispatch(fetchTaxes());
    }

    if (!uomsData?.length) {
      dispatch(fetchUOMs);
    }

    if (
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY) &&
      props.draftData?.data?.isCashInvoice &&
      props.booksDocument.documentType === DOC_TYPE.INVOICE &&
      !paymentAccountGroupsOptions.length
    ) {
      loadAccountGroups(props.booksDocument.currency || tenantInfo.currency);
    }

    let primaryExchangeRate = booksDocument?.primaryExchangeRate;
    if (
      primaryExchangeRate === null ||
      (primaryExchangeRate === undefined &&
        getPrimaryCurrencyCheck() &&
        !Utility.isEmpty(activeMultiCurrencyList))
    ) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      primaryExchangeRate = activeMultiCurrencyList.find(
        (data) => data.currencyCode == primaryCurrencyCode
      )?.currencyExchangeRate;
    }
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        ...documentUpdates,
        currency: booksDocument.currency
          ? booksDocument.currency
          : tenantInfo.currency,
        currencyCode: booksDocument.currencyCode
          ? booksDocument.currencyCode
          : tenantInfo.currency,
        taxSystem: getTenantTaxSystem(),
        documentDate: props.booksDocument.documentDate
          ? props.booksDocument.documentDate
          : DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        fulfillmentDate: props.booksDocument.fulfillmentDate
          ? props.booksDocument.fulfillmentDate
          : DateFormatService.getDateStrFromDate(
              laterDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        validTillDate: props.booksDocument.validTillDate
          ? props.booksDocument.validTillDate
          : DateFormatService.getDateStrFromDate(
              laterDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        amountToReceiveOrPay: props.booksDocument.totalAmount,
        totalAmount: props.booksDocument.totalAmount,
        primaryExchangeRate: primaryExchangeRate
      };
    });

    if (props.booksDocument.dropShip) {
      getLinkedDocumentDetailsForDropship(props.booksDocument.linkedDocuments);
    }

    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.AMORTIZATION
      ) &&
      tenantInfo?.additionalSettings?.AMORTIZATION
    ) {
      loadAllAmortizationTemplates();
    }

    // Update sales document BOM shortfall details in DocumentConfigUtility
    checkAndUpdateBomShortFallDetailsForLineItems(booksDocument?.items);

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      AppManager.handleWindowFocusListeners(loadMultiPaymentOptions, false);
      DocumentConfigUtility.bomProductsTotalLeadTimeMap = {};
    };
  }, []);

  useEffect(() => {
    const defaultAccountCl = deepClone(defaultAccounts?.content || []);
    const doesRCmAccountEXists = defaultAccountCl.find(
      (defaults) => defaults.module === DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK
    );
    if (
      !Utility.isEmpty(doesRCmAccountEXists?.accountCode) &&
      doesRCmAccountEXists?.accountCode !== 'NA'
    ) {
      setDoesDefaultRCMAccountExists(true);
    } else {
      setDoesDefaultRCMAccountExists(false);
    }
  }, [defaultAccounts]);

  const onMouseUp = (e: any) => {
    const target = e.target;
    if (!(target instanceof Element)) return;

    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setopenCustomNumberList(false);
    }
  };

  const loadAllAmortizationTemplates = () => {
    let limit = 100;
    AmortizationService.getAllAmortizationTemplates(limit).then(
      (templates: any) => {
        setAmortizationTemplates(templates?.content || []);
      },
      (err) => {
        console.error('Error loading detailed invoice: ', err);
      }
    );
  };

  const getLinkedDocumentDetailsForDropship = (linkDocument: any) => {
    const code = linkDocument ? linkDocument[0]?.documentCode : '';
    if (code) {
      let docType = linkDocument ? linkDocument[0]?.documentType : '';
      if (docType === DOC_TYPE.QUOTE) {
        QuotationService.getQuoteByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
            }
          },
          (err: any) => {
            console.error('Error loading detailed invoice: ', err);
          }
        );
      } else if (docType === DOC_TYPE.SALES_ORDER) {
        SalesOrderService.getSalesOrderByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
            }
          },
          (err) => {
            console.error('Error loading detailed SO: ', err);
          }
        );
      } else if (docType === DOC_TYPE.ORDER) {
        PurchaseOrderService.fetchOrderDetails(code).then(
          (data: any) => {
            if (data) {
              if (!Utility.isEmpty(data.linkedDocuments)) {
                getLinkedDocumentDetailsForDropship(data.linkedDocuments);
              } else {
                let contactName = data?.contact?.name || contact.name;
                setDropshipShipToContact(contactName);
                setBooksDocument((prevState: any) => ({
                  ...prevState,
                  dropShipName: data?.contact?.name || ''
                }));
              }
            }
          },
          (err) => {
            console.error('Error loading detailed SO: ', err);
          }
        );
      } else {
        InvoiceService.getInvoiceByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
            }
          },
          (err) => {
            console.error('Error loading detailed invoice: ', err);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(contact)) {
      if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
        checkForDpl(contact);
      }
      if (
        (props.documentMode === DOCUMENT_MODE.COPY ||
          props.documentMode === DOCUMENT_MODE.NEW) &&
        (booksDocument?.duplicate || booksDocument?.isConverting) &&
        isDocContactInactive(contact)
      ) {
        showAlert('Warning', inactiveContactMessage);
      }
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(false);
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        let states: ReactOptionType[] = [];
        const indianStates = INDIAN_STATES_MOCK_DATA;
        indianStates.forEach((state) => {
          if (
            contact.gstTreatment !== 'OVERSEAS' &&
            state.code === 'Overseas'
          ) {
            return;
          }

          let option: ReactOptionType = {
            label: state.name,
            value: state.code,
            code: state.stateCode,
            searchableKey: `${state.name} ${state.stateCode}`
          };
          states.push(option);
        });
        setIndianStatesOptions(states);
      }
      const updateAddresses =
        Utility.isEmpty(booksDocument.contact) ||
        booksDocument.contactCode !== contact.code ||
        needToUpdateAddresses ||
        booksDocument.isPartialInvoice ||
        booksDocument.isPartialBill;

      let tempDocument = {
        ...booksDocument,
        contact: contact,
        contactDto: contact,
        contactCode: contact.code
      };

      if (
        props.documentMode === DOCUMENT_MODE.NEW ||
        (props.documentMode === DOCUMENT_MODE.COPY &&
          isContactChangedManually.current)
      ) {
        const contactCurrency = activeMultiCurrencyList.find(
          (currency) =>
            currency.currencyCode ===
            (props?.booksDocument?.currencyCode || contact.currencyCode)
        );
        if (
          typeof contactCurrency !== 'undefined' &&
          contactCurrency !== null &&
          !props?.booksDocument?.isConverting
        ) {
          let currencyUpdatedDoc = updateCurrencyAndExchangeRate(
            tempDocument,
            contactCurrency.currencyCode,
            contactCurrency.currencyExchangeRate,
            false
          );
          tempDocument = {
            ...tempDocument,
            ...currencyUpdatedDoc
          };
        }

        if (
          tenantInfo.multicurrencyEnabled ||
          isGSTExchangeRateRequired(getTenantTaxSystem(), tenantInfo.currency)
        ) {
          if (COMPLAINCE_CURRENCY[AuthService.userDetails?.country]) {
            const currencyDetails = activeMultiCurrencyList.find(
              (x: any) =>
                x.currencyCode ===
                COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
            );
            if (currencyDetails) {
              const gstExchangeRate = currencyDetails.currencyExchangeRate;
              tempDocument = {
                ...tempDocument,
                gstExchangeRate: 1 / gstExchangeRate
              };
            }
          }
        }
      }

      // Check for opening tax exchange rate popup
      if (
        !isTenantAndCurrencySg() &&
        showGstCurrencyTax() &&
        props.documentMode !== DOCUMENT_MODE.VIEW &&
        !Utility.isEmpty(contact)
      ) {
        let currencyCode = contact.currencyCode;
        if (isGSTExchangeRateRequired(getTenantTaxSystem(), currencyCode)) {
          setShowTaxExchangeRatePopup(true);
        }
      }

      if (!tempDocument.documentDate)
        tempDocument.documentDate = DateFormatService.getDateStrFromDate(
          currentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

      tempDocument = checkContact(
        { ...tempDocument },
        { ...contact },
        updateAddresses,
        isContactChangedManually.current
      );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (Utility.isSalesDocument(props.booksDocument)) {
          let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
            ? tempDocument?.shipTo?.placeOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;
          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
          );
          setPos(pos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = {
            ...tempDocument,
            shipTo: shipToAddress,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
        }
        if (
          props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType ===
            DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
          props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
          Utility.isDropship(props.booksDocument)
        ) {
          let posState: any = !Utility.isEmpty(
            tempDocument?.shipFrom?.placeOfSupply
          )
            ? tempDocument?.shipFrom?.placeOfSupply
            : tempDocument?.shipFrom?.state || shippingAddress?.state;

          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
          );
          setPos(pos[0]);
          let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null;
          let shipFromAddress = {
            ...shipFrom,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipFrom: shipFromAddress };

          let dosState: any = !Utility.isEmpty(
            tempDocument?.shipTo?.destinationOfSupply
          )
            ? tempDocument?.shipTo?.destinationOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;

          let dos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
          );
          setDos(dos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            destinationOfSupply: dos ? dos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
      }
      // Keep the shipTo value if document is not new
      // if (props.documentMode !== DOCUMENT_MODE.NEW) {
      //   tempDocument = {
      //     ...tempDocument,
      //     shipTo: booksDocument.shipTo
      //   };
      // }

      // Update validTillDate only if contact is changed manually
      if (isContactChangedManually.current) {
        // Load account groups if contact currency is different
        if (props.draftData?.data?.isCashInvoice) {
          loadAccountGroups(tempDocument.currency);
        }
        if (tempDocument.validTillDate) {
          setDueDate(
            DateFormatService.getDateFromStr(
              tempDocument.validTillDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          );
        } else {
          tempDocument.validTillDate = DateFormatService.getDateStrFromDate(
            laterDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
        }
      } else {
        // Set initial dates while opening an existing document, if contact is not changed manually
        tempDocument = {
          ...tempDocument,
          documentDate: props.booksDocument.documentDate
            ? props.booksDocument.documentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          fulfillmentDate: props.booksDocument.fulfillmentDate
            ? props.booksDocument.fulfillmentDate
            : DateFormatService.getDateStrFromDate(
                laterDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          validTillDate: props.booksDocument.validTillDate
            ? props.booksDocument.validTillDate
            : DateFormatService.getDateStrFromDate(
                laterDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
        };
      }

      tempDocument = {
        ...tempDocument,
        items: [...tempDocument.items].sort(
          (item1: any, item2: any) => item1.lineNumber - item2.lineNumber
        )
      };

      tempDocument = {
        ...tempDocument,
        items: tempDocument.items?.map((item: any) => {
          const tdsInfoIndia =
            props.documentMode === DOCUMENT_MODE.EDIT ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            (props.draftType === DraftTypes.DRAFT &&
              props.booksDocument?.contact?.code === contact.code)
              ? item.tdsInfoIndia
              : undefined;
          let tax =
            props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY
              ? !Utility.isEmpty(item.tax)
                ? countriesWithTaxDependantOnContact.includes(
                    tenantInfo.country
                  )
                  ? getTax({ ...item.product })
                  : item.tax
                : getTax({ ...item.product })
              : !Utility.isEmpty(item.tax)
              ? item.tax
              : null;
          return { ...item, gstType: tempDocument.gstType, tdsInfoIndia, tax };
        })
      };

      if (tempDocument.items && tempDocument.items.length > 0) {
        if (productRows.length && getTenantTaxSystem() === TAX_SYSTEM.US) {
          calculateUSTaxOnContactChange(tempDocument);
        } else {
          tempDocument.items.forEach((rowData: any, index: number) => {
            calculateTaxesAndAmount(index, tempDocument.items);
          });
        }
      }

      // const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      //   props.booksDocument.documentType
      // );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        let gstType = booksDocument.gstType;
        gstType = updateGstType(tempDocument);
        tempDocument = {
          ...tempDocument,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      setIsContactUpdated(!isContactUpdated);

      const isBillOrOrder = [
        DOC_TYPE.BILL,
        DOC_TYPE.ORDER,
        DOC_TYPE.JOB_WORK_OUT_ORDER
      ].includes(props.booksDocument.documentType);
      if (isBillOrOrder) {
        setIsShipFromAddressUpdated(!isShipFromAddressUpdated);
      }

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument,
          billTo: Utility.isEmpty(tempDocument.billTo)
            ? billingAddress
            : tempDocument.billTo,
          shipTo:
            props.documentMode !== DOCUMENT_MODE.NEW
              ? tempDocument.shipTo
              : tempDocument.shipTo || shippingAddress,
          shipFrom: !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null
        };
      });

      setNeedToUpdateAddresses(false);

      if (productRows.length && isContactChangedManually.current) {
        const productsPayload = productRows
          .filter((item) => !Utility.isEmpty(item.product))
          .map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          true,
          tempDocument.currency,
          true,
          tempDocument.exchangeRate,
          { contactChanged: true },
          { shipTo: tempDocument.shipTo, shipFrom: tempDocument.shipFrom }
        );
      }
      if (productRows.length && !props.auditLogView) {
        fetchProductUnitPrice();
      }

      if (
        Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
        Utility.isNotEmpty(productRows?.[0]?.product) &&
        (props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
        !(
          props?.booksDocument?.isPartialInvoice ||
          props?.booksDocument?.isPartialSalesOrder
        )
      ) {
        getUpdatedProductRowsForComponentListDetails(productRows, contact);
      }
    }
  }, [contact]);

  useEffect(() => {
    if (Utility.isEmpty(contact)) {
      return;
    }
    const { billingAddress, shippingAddress } = !Utility.isDropship(
      props.booksDocument
    )
      ? updateBillingShippingAddress(false)
      : {
          billingAddress: {},
          shippingAddress: {}
        };

    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);

    let updatedDocument: any = {};

    if (isBillOrOrder) {
      updatedDocument.billTo = Utility.isDropship(props.booksDocument)
        ? {}
        : billingAddress || booksDocument.billTo;
      updatedDocument.shipTo = Utility.isDropship(props.booksDocument)
        ? props.booksDocument.shipTo
        : shippingAddress || booksDocument.shipTo;

      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType ===
            DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
          props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER)
      ) {
        updatePlaceOfSupplyOnAddress(shippingAddress);
      }
    } else {
      const preferredShippingAddress = tenantInfo.shippingAddresses?.find(
        (address: any) => address.preferred
      );
      updatedDocument.shipFrom = !Utility.isEmpty(preferredShippingAddress)
        ? preferredShippingAddress
        : !Utility.isEmpty(props.booksDocument.shipFrom)
        ? props.booksDocument.shipFrom
        : null;
    }
    if (billingAddress || shippingAddress) {
      setBooksDocument((prevState: any) => {
        return { ...prevState, ...updatedDocument };
      });
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (shipToOrFromAddressChangedForUS) {
      calculateUSTax(undefined);
      setShipToOrFromAddressChangedForUS(false);
    }
    setIsCenterAlign(props.isCenterAlign);

    props.onDocumentUpdate(booksDocument);
  }, [booksDocument]);

  useEffect(() => {
    if (currentRowTDSInfo) {
      setShowTDSCalculationPopup(true);
    }
  }, [currentRowTDSInfo]);

  useEffect(() => {
    if (currentRowITCInfo) {
      setShowITCPopup(true);
    }
  }, [currentRowITCInfo]);

  useEffect(() => {
    if (currentRowHSNInfo) {
      setShowHSNPopup(true);
    }
  }, [currentRowHSNInfo]);

  useEffect(() => {
    if (currentRowGSTInfo) {
      setShowGSTPopup(true);
    }
  }, [currentRowGSTInfo]);

  useEffect(() => {
    if (currentRowTaxInfo) {
      setShowTaxRowPopup(true);
    }
  }, [currentRowTaxInfo]);

  const getUpdatedProductRowsForComponentListDetails = async (
    productRowsArr: any,
    contactObj: any
  ) => {
    let updatedProductRows = await getComponentDetailsForDocumentLineItems(
      productRowsArr,
      contactObj,
      props.booksDocument.documentType
    );
    try {
      setProductRows([...updatedProductRows]);
      setBooksDocument((prevState: any) => {
        return { ...prevState, items: [...updatedProductRows] };
      });
    } catch (error) {}
  };

  const loadMultiPaymentOptions = () => {
    if (props.booksDocument.documentType !== DOC_TYPE.INVOICE) return;

    InvoiceService.fetchPaymentConfiguredOptions(tenantInfo.currency)
      .then((data: any) => {
        if (!Utility.areObjectsEqual(multiPaymentOptions.current, data)) {
          multiPaymentOptions.current = data;
        }
      })
      .catch((err: any) => {
        console.error('Error loading multipayment options: ', err);
      });
  };

  //////////////////////////////////////////////////////////
  ////////////////////POPUP HANDLERS////////////////////////
  //////////////////////////////////////////////////////////
  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        // showAddressPopup && setShowAddressPopup(false);
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        setShowPaymentMilestonePopup(false);
        showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        setShowIRPCredPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.updateContact();
        break;
      case POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ADDRESS:
        editAddressRef.current?.storeCallbacksRef.updateAddress();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        addTaxRef.current?.storeCallbacksRef.createTax();
        break;
      case POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE:
        setPaymentMilestoneBeingSaved(true);
        addPaymentMilestoneRef.current?.storeCallbacksRef?.savePaymentMileStone();
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.updateContact =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
        setShowAddContactPopup(false);
        setDetailedContact(passingData.data.id);
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ADDRESS:
        editAddressRef.current.storeCallbacksRef.updateAddress =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.ADDRESS_UPDATE_SUCCESSFUL:
        onAddressUpdated();
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        addTaxRef.current.storeCallbacksRef.createTax = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS:
        if (passingData?.data?.incomePayment) {
          setIsTDSDeducted(true);
          onTDSDeduct(passingData.data);
          showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        }
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        if (typeof currentIndex !== 'undefined' && currentIndex !== null) {
          let rows = [...productRows];
          rows[currentIndex]['tax'] = passingData.data;
          updateConfig();
          setProductRows(rows);
        }
        break;
      case POPUP_CALLBACKS_TYPE.SAVE_PAYMENT_MILESTONE:
        addPaymentMilestoneRef.current.storeCallbacksRef.savePaymentMileStone =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        setShowAddContactPopup(false);
        setShowPaymentMilestonePopup(false);
        break;
    }
  };

  //////////////////////////////////////////////////////////
  ///////////////// TENANT ADDRESS HANDLERS ////////////////
  //////////////////////////////////////////////////////////

  const getUpdateOrgPopup = () => {
    const popupConfigForOrganisationProfile: BtnType[] = [
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.UPDATE`),
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
      }
    ];
    return showUpdateOrgPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Update ${getCapitalized(
          localizedText('organisation')
        )} Profile`}
        btnList={popupConfigForOrganisationProfile}
        width={'40%'}
        height={!isDesktop ? '75%' : '95%'}
        disableClickOutside
      >
        <OrganisationProfileForm
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          activeTab={activeOrgProfileTab}
        />
      </PopupWrapper>
    ) : null;
  };

  const showTenantAddressAlert = () => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Add address',
        className: 'bg-button text-white ml-r',
        onClick: () => setShowUpdateOrgPopup(true)
      }
    ];

    const isBillOrOrder =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER;
    showAlert(
      `Tax Compliance`,
      isBillOrOrder
        ? `To calculate purchase tax correctly, we would need your organisation's address.`
        : `To calculate sales tax correctly, we would need your organisation's address where you collect tax.`,
      alertButtonConfig
    );
  };

  const getShipFromAddressForNewSalesDocument = () => {
    if (!Utility.isEmpty(tenantInfo)) {
      const tenantShippingAddresses = tenantInfo?.shippingAddresses;
      const preferredShippingAddress = tenantShippingAddresses?.find(
        (address: any) => address?.preferred
      );
      if (!Utility.isEmpty(preferredShippingAddress)) {
        return preferredShippingAddress;
      }
    }
    return null;
  };

  const updateBillingShippingAddress = (requireAddressCheck?: boolean) => {
    const isBillOrOrder =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER;

    const billingAddressList: BooksAddress[] = isBillOrOrder
      ? tenantInfo.billingAddresses
      : props.documentMode === DOCUMENT_MODE.EDIT
      ? editModeBillingAddresses
      : contact?.billingAddress;

    const shippingAddressList: BooksAddress[] =
      isBillOrOrder && !Utility.isDropship(props.booksDocument)
        ? tenantInfo.shippingAddresses
        : props.documentMode === DOCUMENT_MODE.EDIT
        ? editModeShippingAddresses
        : contact?.shippingAddress;

    let billingAddress: BooksAddress | undefined;
    let shippingAddress: BooksAddress | undefined;

    let canShowTenantAddressAlert = false;
    const complianceStatus = Utility.getTenantComplianceSettings(tenantInfo);
    if (
      !complianceStatus.complianceSetting &&
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY)
    ) {
      canShowTenantAddressAlert = true;
    }

    if (isBillOrOrder) {
      billingAddress =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      shippingAddress =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);

      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !shippingAddress
      ) {
        showTenantAddressAlert();
      }
    } else {
      billingAddress = billingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      shippingAddress = shippingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      const tenantHasShippingAddress = Boolean(
        tenantInfo.shippingAddresses?.length
      );
      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !tenantHasShippingAddress
      ) {
        showTenantAddressAlert();
      }
    }

    setBillingAddress({
      billingAddress: billingAddressList,
      currentBillingAddress: billingAddress as BooksAddress
    });

    setShippingAddress({
      shippingAddress: shippingAddressList,
      currentShippingAddress: shippingAddress as BooksAddress
    });

    return {
      billingAddress,
      shippingAddress
    };
  };

  //////////////////////////////////////////////////////////
  //////////// CONTACT & ADDRESS PANEL HANDLERS ////////////
  //////////////////////////////////////////////////////////
  const onAddressUpdated = () => {
    // setProductRows([]);
    // setShowAddressPopup(false);
    setShowUpdateAddressPopup(false);
    const response = ContactService.getContactDetailsById(contact.id);
    response?.then((res: any) => {
      if (props.documentMode === DOCUMENT_MODE.EDIT) {
        setEditModeBillingAddresses(res.billingAddress);
        setEditModeShippingAddresses(res.shippingAddress);
      }
      setContact({ ...res });
    });
  };

  const onPlaceOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      if (Utility.isSalesDocument(props.booksDocument)) {
        let shipToAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipToAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: value.value
          };
        }
        const gstType = updateGstType({
          ...tempDocument,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipTo: shipToAddress,
          gstType: gstType,
          placeOfSupply: value.value,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      } else {
        let shipFromAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipFromAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipFromAddress = {
            ...tempDocument.shipFrom,
            placeOfSupply: value.value
          };
        }
        let shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: dos.value
        };
        const gstType = updateGstType({
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      setBooksDocument({
        ...tempDocument
      });
      tempDocument.items.forEach((item: any, index: number) => {
        calculateTaxesAndAmount(index, tempDocument.items);
      });
    }
  };

  const onDestinationOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      let shipToAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipToAddress = {
          ...address,
          destinationOfSupply: value.value
        };
      } else {
        shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: value.value
        };
      }
      let shipFromAddress = {
        ...tempDocument.shipFrom,
        placeOfSupply: pos?.value,
        destinationOfSupply: value.value
      };
      const gstType = updateGstType({
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress
      });
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
      setBooksDocument({
        ...tempDocument
      });
      tempDocument.items.forEach((item: any, index: number) => {
        calculateTaxesAndAmount(index, tempDocument.items);
      });
    }
  };

  const handleAndUpdateRCMCheck = (check: boolean) => {
    if (check === true) {
      if (doesDefaultRCMAccountExists === true) {
        setBooksDocument((prev: any) => {
          return {
            ...prev,
            applyRcmCheck: check
          };
        });
      } else {
        const defaultAccountUpdateBtn = [
          {
            title: 'Cancel',
            className: 'bg-gray-2 border-m',
            onClick: () => {}
          },
          {
            title: 'Yes, Update',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              setShowDefaultAccountsSettings(true);
            }
          }
        ];
        showAlert(
          'Alert',
          'Please map default RCM account.',
          defaultAccountUpdateBtn
        );
      }
    } else {
      setBooksDocument((prev: any) => {
        return {
          ...prev,
          applyRcmCheck: check
        };
      });
    }
  };

  const updatePlaceOfSupplyOnAddress = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele.value?.toLowerCase() === (address.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    if (Utility.isSalesDocument(props.booksDocument)) {
      setPos(pos[0]);
      onPlaceOfsupplyChange(obj, address);
    } else {
      setDos(pos[0]);
      onDestinationOfsupplyChange(obj, address);
    }
  };
  const updatePlaceOfSupplyOnAddressBuy = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele?.value?.toLowerCase() === (address?.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    setPos(pos[0]);
    onPlaceOfsupplyChange(obj, address);
  };

  const getContactSelector = () => {
    return (
      <div
        className={`${
          props.canValidate
            ? 'bg-chip-red border-red text-red'
            : 'bg-chip-blue border-blue text-blue'
        } p-v-s p-h-r border-radius-s mt-s cursor-pointer`}
        style={{ border: 'dashed', borderWidth: 1 }}
        onClick={() => setOpenContactSelector((prevValue) => !prevValue)}
      >
        <DKLabel text={`+ Add a Contact`} />
      </div>
    );
  };

  const checkFulfillment = (data: string) => {
    if (
      data == FULFILLMENT_STATUS.FULLY_FULFILLED ||
      data == FULFILLMENT_STATUS.PARTIAL_FULFILLED
    ) {
      return false;
    }
    return true;
  };

  const getCustomCompanyDetails = (title?: string) => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);
    const orgName = customLocation?.locationDetails?.title;
    const address = getFormattedAddress(
      customLocation?.locationDetails?.address,
      isBillOrOrder
    );
    const phone = customLocation?.locationDetails?.phone;
    const email = customLocation?.locationDetails?.email;

    return (
      <div
        className={`column`}
        style={{
          maxWidth: 250
        }}
      >
        <div className={'column width-auto ' + GOOGLE_NO_TRANSLATE_CLASS}>
          <div className="row">
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
          <div className={`row`}>
            <DKLabel text={orgName} className="fw-m fs-r" />
          </div>
          <DKLabel text={address} className="parent-width" />
          {phone && <DKLabel text={phone} className="parent-width" />}
          {email && <DKLabel text={email} className="parent-width" />}
        </div>
      </div>
    );
  };

  const getCompanyDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER,
      DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ].includes(props.booksDocument.documentType);

    const canEditContact =
      isBillOrOrder &&
      props.documentMode !== DOCUMENT_MODE.EDIT &&
      checkForCustomField;

    let companyAddress = booksDocument.shipFrom;
    if (Utility.isEmpty(companyAddress)) {
      if (isBillOrOrder) {
        companyAddress = contact?.billingAddress?.length
          ? contact?.billingAddress[0]
          : null;
      } else {
        companyAddress = tenantInfo?.address;
      }
    }

    return (
      <div
        className={`column parent-block`}
        style={{
          maxWidth: 420,
          marginTop: canEditContact ? -10 : 0,
          marginLeft: canEditContact ? -10 : 0,
          padding: canEditContact ? 10 : 0
        }}
      >
        <div className="row width-auto">
          {isBillOrOrder && Utility.isEmpty(contact) ? (
            getContactSelector()
          ) : (
            <div
              className={`${
                canEditContact && !Utility.isEmpty(contact)
                  ? 'cursor-pointer border-box'
                  : ''
              }`}
              onClick={() => {
                if (canEditContact && !Utility.isEmpty(contact)) {
                  setOpenContactSelector((prevValue) => !prevValue);
                  setBooksAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
                }
              }}
            >
              <div
                className={`row justify-content-between ${
                  canEditContact ? 'listPickerBG' : ''
                }`}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? `${contact.name}-(${contact.currencyCode})`
                      : tenantInfo.name
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
                {isBillOrOrder && getWarningForInactiveContact()}
              </div>
            </div>
          )}
        </div>
        {Utility.isEmpty(companyAddress) ? null : (
          // Ship from for Bill/Order
          <>
            {isBillOrOrder ? (
              <div>
                <div
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (isBillOrOrder) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(companyAddress)}
                    className={'parent-width ' + GOOGLE_NO_TRANSLATE_CLASS}
                  />
                </div>
                {openShipFromForBuy && checkForCustomField && (
                  <DKListPicker2
                    data={contact?.billingAddress}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      ></div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      if (isBillOrOrder) {
                        isDocContactAddressChangedManually.current = true;
                      }
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
                          DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddressBuy(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                      setIsShipFromAddressUpdated(!isShipFromAddressUpdated);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
                {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
              </div>
            ) : (
              // Ship from for Invoice/Quote
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (!isBillOrOrder && checkForCustomField) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(
                      !Utility.isEmpty(booksDocument.shipFrom)
                        ? booksDocument.shipFrom
                        : companyAddress,
                      isBillOrOrder
                    )}
                    className={'parent-width ' + GOOGLE_NO_TRANSLATE_CLASS}
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={tenantInfo?.shippingAddresses}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      >
                        {/* {getFormattedAddress(addressObj)} */}
                      </div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                        setShipToOrFromAddressChangedForUS(true);
                      }
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddress(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                      setIsShipFromAddressUpdated(!isShipFromAddressUpdated);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
                {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const getWarningForInactiveContact = () => {
    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      (booksDocument?.duplicate || booksDocument?.isConverting) &&
      isDocContactInactive(contact)
    ) {
      return (
        <DKTooltipWrapper
          content="Inactive contact"
          tooltipClassName="bg-deskera-secondary width-auto"
          tooltipPositionAbsolute={true}
          tooltipStyle={{ top: 20, left: 'unset' }}
        >
          <div className="row">
            <DKIcon
              src={DKIcons.ic_warning_red}
              className="ic-s ml-s"
              onClick={() => {}}
            />
          </div>
        </DKTooltipWrapper>
      );
    } else {
      return null;
    }
  };

  const getContactCard = (
    title: string,
    addressType: BOOKS_ADDRESS_TYPES,
    isBillOrOrder: boolean
  ) => {
    if (isBillOrOrder && !Utility.isEmpty(customLocation)) {
      return getCustomCompanyDetails(title);
    }

    const canEditContact =
      props.documentMode !== DOCUMENT_MODE.EDIT && !isBillOrOrder;

    const shipToCopy = { ...booksDocument.shipTo };
    if (
      addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS &&
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST
    ) {
      delete shipToCopy.placeOfSupply;
    }

    return (
      <div
        ref={
          addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
            ? shippingAddressBlock
            : null
        }
        className={`column document-address-block`}
        style={{
          minWidth: 320,
          maxWidth: 320,
          marginLeft: -10,
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10
        }}
      >
        <div className="row parent-block justify-content-between">
          <div
            className={`row width-auto ${
              canEditContact ? 'cursor-pointer listPickerBG' : ''
            }`}
            onClick={() => {
              if (canEditContact) {
                setOpenContactSelector((prevValue) => !prevValue);
                setBooksAddressType(addressType);
              }
            }}
          >
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
        </div>
        <div className={`mt-s`}>
          {addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS && (
            <div className="position-relative">
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? tenantInfo.name
                      : `${contact.name}-(${contact.currencyCode})`
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
                {!isBillOrOrder && getWarningForInactiveContact()}
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={(e: any) => {
                  if (checkForCustomField) {
                    setOpenBillingAddressList(true);
                  }
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(
                    booksDocument.billTo ||
                      billingAddress?.currentBillingAddress ||
                      (isBillOrOrder ? tenantInfo?.address : null)
                  )
                ) ? (
                  <DKLabel
                    text={getFormattedAddress(
                      booksDocument.billTo ||
                        billingAddress?.currentBillingAddress ||
                        (isBillOrOrder ? tenantInfo?.address : null)
                    )}
                    className={GOOGLE_NO_TRANSLATE_CLASS}
                  />
                ) : (
                  <DKLabel
                    text={
                      props.documentMode === DOCUMENT_MODE.VIEW
                        ? ''
                        : 'Select address'
                    }
                    className="text-gray"
                  />
                )}
              </div>
              {openBillingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.billingAddresses
                      : billingAddress?.billingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={
                    !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.EDIT) ||
                    (isBillOrOrder && Utility.isDropship(props.booksDocument))
                      ? null
                      : {
                          title: 'Manage address',
                          className:
                            'text-white fw-m bg-button justify-content-center',
                          onClick: () => {
                            if (isBillOrOrder) {
                              setActiveOrgProfileTab(
                                ORGANISATION_FORM_TAB.ADDRESS_INFO
                              );
                              setShowUpdateOrgPopup(true);
                            } else {
                              setActiveContactTab(
                                CONTACT_FORM_TAB.ADDRESS_INFO
                              );
                              setContactMode(DOCUMENT_MODE.EDIT);
                              setShowAddContactPopup(true);
                            }
                            setOpenBillingAddressList(false);
                          }
                        }
                  }
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    if (!isBillOrOrder) {
                      isDocContactAddressChangedManually.current = true;
                    }
                    setBillingAddress({
                      billingAddress:
                        billingAddress?.billingAddress as BooksAddress[],
                      currentBillingAddress: addressObj
                    });
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      billTo: addressObj
                    }));
                    setOpenBillingAddressList(false);
                    setIsBillToAddressUpdated(!isBillToAddressUpdated);
                  }}
                  onClose={() => {
                    setOpenBillingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
            <>
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder && !Utility.isDropship(props.booksDocument)
                      ? tenantInfo.name
                      : dropshipShipToContact
                      ? dropshipShipToContact
                      : contact?.name
                      ? `${contact.name}-(${contact.currencyCode})`
                      : ''
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
                {!isBillOrOrder && getWarningForInactiveContact()}
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={(e: any) => {
                  if (
                    !Utility.isDropship(props.booksDocument) &&
                    checkForCustomField
                  ) {
                    setOpenShippingAddressList(true);
                  }
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(
                    shipToCopy ||
                      shippingAddress?.currentShippingAddress ||
                      (isBillOrOrder && !Utility.isDropship(props.booksDocument)
                        ? tenantInfo?.address
                        : null)
                  )
                ) ? (
                  <DKLabel
                    text={getFormattedAddress(
                      booksDocument.shipTo ||
                        shippingAddress?.currentShippingAddress ||
                        (isBillOrOrder &&
                        !Utility.isDropship(props.booksDocument)
                          ? tenantInfo?.address
                          : null)
                    )}
                    className={GOOGLE_NO_TRANSLATE_CLASS}
                  />
                ) : (
                  <DKLabel
                    text={
                      props.documentMode === DOCUMENT_MODE.VIEW
                        ? ''
                        : 'Select address'
                    }
                    className="text-gray"
                  />
                )}
              </div>
              {openShippingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.shippingAddresses
                      : shippingAddress?.shippingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={
                    checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.EDIT)
                      ? {
                          title: 'Manage address',
                          className:
                            'text-white fw-m bg-button justify-content-center',
                          onClick: () => {
                            if (isBillOrOrder) {
                              setActiveOrgProfileTab(
                                ORGANISATION_FORM_TAB.ADDRESS_INFO
                              );
                              setShowUpdateOrgPopup(true);
                            } else {
                              setActiveContactTab(
                                CONTACT_FORM_TAB.ADDRESS_INFO
                              );
                              setContactMode(DOCUMENT_MODE.EDIT);
                              setShowAddContactPopup(true);
                            }
                            setOpenShippingAddressList(false);
                          }
                        }
                      : null
                  }
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    if (!isBillOrOrder) {
                      isDocContactAddressChangedManually.current = true;
                    }
                    setShippingAddress({
                      shippingAddress:
                        shippingAddress?.shippingAddress as BooksAddress[],
                      currentShippingAddress: addressObj
                    });
                    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                      setShipToOrFromAddressChangedForUS(true);
                    }
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      shipTo: addressObj
                    }));
                    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
                      updatePlaceOfSupplyOnAddress(addressObj);
                    }
                    setOpenShippingAddressList(false);
                    setIsShipToAddressUpdated(!isShipToAddressUpdated);
                  }}
                  onClose={() => {
                    setOpenShippingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getDeliveryAddressDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER,
      DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ].includes(props.booksDocument.documentType);

    return !isBillOrOrder && Utility.isEmpty(contact) ? (
      getContactSelector()
    ) : (
      <div>
        <div className="row width-auto align-items-start gap-2">
          <div className="column">
            {getContactCard(
              t(`DOCUMENT.BILL_TO`),
              BOOKS_ADDRESS_TYPES.BILLING_ADDRESS,
              isBillOrOrder
            )}
            {invoiceConfigTableId &&
              props.booksDocument.documentType !==
                DOC_TYPE.JOB_WORK_OUT_ORDER &&
              getContactAddressCustomFields(
                BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
              )}
          </div>
          <div className="column">
            {getContactCard(
              t(`DOCUMENT.SHIP_TO`),
              BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS,
              isBillOrOrder
            )}
            {invoiceConfigTableId &&
              props.booksDocument.documentType !==
                DOC_TYPE.JOB_WORK_OUT_ORDER &&
              getContactAddressCustomFields(
                BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
              )}
          </div>
        </div>
        {(Utility.isNotEmpty(booksDocument?.billTo?.customFields) ||
          Utility.isNotEmpty(booksDocument?.shipTo?.customFields)) && (
          <DKLine className="mt-s" />
        )}
      </div>
    );
  };

  const setDetailedContact = async (id: number) => {
    try {
      const detailedContact = await ContactService.getContactDetailsById(id);
      setNeedToUpdateAddresses(true);
      setContact(detailedContact);
      if (!booksDocument.isDocumentTouched) {
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      }
      setBillingAddress({
        billingAddress: detailedContact.billingAddress,
        currentBillingAddress: null
      });
      setShippingAddress({
        shippingAddress: detailedContact.shippingAddress,
        currentShippingAddress: null
      });
    } catch (err) {
      console.error('Error loading detailed contact: ', err);
      if (
        props.documentMode === DOCUMENT_MODE.NEW &&
        props.draftType === DraftTypes.DRAFT
      ) {
        // Remove contact info from draft, if contact is deleted
        setBooksDocument((prevState: any) => {
          const updatedState = {
            ...prevState,
            contact: null,
            contactCode: '',
            contactDto: null
          };

          if (!Utility.isSalesDocument(prevState)) {
            updatedState.shipFrom = null;
          }

          return updatedState;
        });
      }
    }
  };

  const getAddContactPopup = () => {
    const buttonConfig: BtnType[] = [
      {
        title: t(`DOCUMENT.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: contactMode === DOCUMENT_MODE.NEW ? 'Create' : 'Update',
        class: 'bg-button text-white mr-ss',
        clickAction:
          contactMode === DOCUMENT_MODE.NEW
            ? POPUP_CLICK_TYPE.CREATE_CONTACT
            : POPUP_CLICK_TYPE.UPDATE_CONTACT
      }
    ];
    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={
          contactMode === DOCUMENT_MODE.NEW
            ? 'Create Contact'
            : 'Update Contact'
        }
        btnList={buttonConfig}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          activeTab={activeContactTab}
          contactMode={contactMode}
          populateFormData={
            contactMode === DOCUMENT_MODE.NEW ? null : { ...contact }
          }
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onSuccess={(res: any) => setDetailedContact(res.id)}
        />
      </PopupWrapper>
    ) : null;
  };

  const getLeftPosForShippingAddress = () => {
    if (shippingAddressBlock) {
      const popup = shippingAddressBlock.current?.closest('.popup-window');
      if (popup) {
        const popupLeft = popup.getBoundingClientRect().left;
        const rect = shippingAddressBlock.current?.getBoundingClientRect();
        return rect.left - popupLeft - 22;
      } else {
        return 198;
      }
    } else {
      return 198;
    }
  };

  const checkForDpl = (contact: any) => {
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    )?.[0];
    if (!dplContactCustomField) return;
    if (dplContactCustomField?.value === 'Yes') {
      showToast(
        'The contact you are using is under denied party list',
        TOAST_TYPE.SUCCESS
      );
    }
  };

  const getContactPicker = () => {
    let contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    const contactPickerTop =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ? -25
        : 50;

    return openContactSelector ? (
      <DKListPicker2
        data={contactList}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          top: contactPickerTop,
          left: [
            BOOKS_ADDRESS_TYPES.NONE,
            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
          ].includes(booksAddressType)
            ? 0
            : getLeftPosForShippingAddress(),
          minWidth: 200
        }}
        renderer={(index: number, contactObj: any) => contactObj.name}
        onSelect={(index: number, contactObj: any) => {
          // setProductRows([]);
          isContactChangedManually.current = true;
          setOpenContactSelector(false);
          setDetailedContact(contactObj.id);
          setSelectedPaymentOption(null);
          updateContactBuyerReference(contactObj);
          const paymentTermValue = paymentTerms?.content.find(
            (paymentTerm: any) =>
              String(paymentTerm.id) === contactObj.paymentTermCode
          );
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              paymentInformation: null,
              paymentTerm: paymentTermValue?.termName ?? ''
            };
          });
          // setIsContactUpdated(!isContactUpdated);
        }}
        onClose={() => setTimeout(() => setOpenContactSelector(false), 100)}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query:
                props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
                  ? 'status=active'
                  : 'status=active'
            };

            if (
              canApplySalesPersonFilterOnContact.canApplyFilter &&
              !config.Query?.includes('salesperson') &&
              canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id
            ) {
              let configQuery = config?.Query ? config?.Query + ',' : '';
              config.Query =
                configQuery +
                `salesperson=${canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id}`;
              config.SalesPerson =
                canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id;
            }

            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        }}
        button={
          checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
            ? {
                title: '+ Add New',
                icon: DKIcons.ic_contact,
                className: 'bg-button text-white',
                style: {},
                onClick: () => {
                  setContactMode(DOCUMENT_MODE.NEW);
                  setShowAddContactPopup(true);
                }
              }
            : null
        }
      />
    ) : null;
  };

  //////////////////////////////////////////////////////////
  //////////// GST EXCHANGE RATE FOR SG HANDLERS ///////////
  //////////////////////////////////////////////////////////
  const isTenantAndCurrencySg = (): boolean => {
    return (
      tenantInfo &&
      tenantInfo.currency === CURRENCIES.SG &&
      tenantInfo.country === COUNTRY_CODES.SG
    );
  };

  const showGstCurrencyTax = () => {
    return isGSTExchangeRateRequired(
      tenantInfo && tenantInfo.country,
      tenantInfo && tenantInfo.currency
    );
  };

  //////////////////////////////////////////////////////////
  ///// DOCUMENT RIGHT PANEL INFO (DOC CODE & DATES) ///////
  //////////////////////////////////////////////////////////
  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    let updatedState: any = {};

    if (selected.manualMode) {
      updatedState = {
        documentSequenceCode: selected.text,
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      };
    } else {
      if (selected.id) {
        updatedState = {
          sequenceFormat: selected.id,
          manualMode: selected.manualMode
        };
      }
    }

    setTimeout(() => {
      setBooksDocument((prevState: any) => {
        return { ...prevState, ...updatedState };
      });
    }, 0);
  };

  const getDateText = () => {
    if (props.booksDocument.documentType === DOC_TYPE.QUOTE) {
      return Utility.isUSorg() ? 'Estimate Date' : t(`QUOTES.QUOTE_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.INVOICE) {
      return t(`INVOICES.INVOICE_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
      return 'Order Date';
    }
    if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
      return t(`PURCHASE_ORDER.ORDER_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      return t(`PURCHASE_ORDER.ORDER_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.BILL) {
      return t(`BILLS.BILL_DATE`);
    }
    if (
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ) {
      return 'PIQ Receive Date';
    }
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        showAlert(
          'Invalid Date',
          `${getDateText()} should not before financial year close date`
        );
        return;
      }
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          (date: Date) => {
            callback(date);
          },
          `${getDateText()} should be in active date range`
        );
        return;
      }
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    callback: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `${getDateText()} should not be less than back date : ${formatedDate}.`
          );
          return;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        callback(newDate);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      } else {
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }
  };

  // Fullscreen layout for dates, place/source/destination of supply,
  // customerOrderNumber, supplierInvoiceNo, buyerReferenceNumber
  const getDateAndOtherInfoBlockForFullScreen = () => {
    const docDateLabel = getDateText();
    const shipByDateLabel = Utility.isSalesDocument(props.booksDocument)
      ? t(`DOCUMENT.SHIP_BY_EXPECTED`)
      : `Receive By`;

    return (
      <>
        <div className="row align-items-end flex-wrap" style={{ gap: 12 }}>
          <div
            style={{
              width: 150,
              maxWidth: 200
            }}
          >
            <DKInput
              className="parent-width"
              title={docDateLabel}
              value={documentDate}
              titleStyle={{ color: 'gray' }}
              valueStyle={{ minHeight: 33 }}
              type={INPUT_TYPE.DATE}
              onChange={(newDate: any) => {
                validateAndUpdateDate(
                  newDate,
                  DateFormatService.getDateFromStr(
                    tenantInfo.bookBeginningStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  (date: any) => {
                    let mappedProductRows = [...productRows];
                    // update line level expected Delivery date for lead time
                    if (
                      Utility.isMRPWithURLCheck() &&
                      (props.booksDocument.documentType === DOC_TYPE.BILL ||
                        props.booksDocument.documentType === DOC_TYPE.ORDER ||
                        props.booksDocument.documentType ===
                          DOC_TYPE.PURCHASE_INWARD_QUOTATION)
                    ) {
                      mappedProductRows = mappedProductRows?.map(
                        (productItem: any) => {
                          let leadTimeValue = productItem?.product
                            ?.leadTimeDetails?.[0]?.leadTime
                            ? productItem?.product?.leadTimeDetails?.[0]
                                ?.leadTime
                            : productItem?.product?.leadTime;
                          productItem.expectedDeliveryDt =
                            updateExpectedDeliveryDateForLineItem(
                              leadTimeValue || 0,
                              date
                            );
                          return productItem;
                        }
                      );
                      updateShipByDateForLineLevelDate(
                        documentDate > date ? date : shipByDate,
                        mappedProductRows
                      );
                    }
                    if (
                      props.booksDocument.documentType === DOC_TYPE.BILL &&
                      featurePermissionsInfo?.Supported?.includes(
                        FEATURE_PERMISSIONS.AMORTIZATION
                      ) &&
                      tenantInfo?.additionalSettings?.AMORTIZATION
                    ) {
                      mappedProductRows.forEach((row, rowIndex) => {
                        if (row?.amortizationTemplate) {
                          let amortizationItemDetails = {};
                          let startDate = new Date(date);
                          let startDateStr =
                            DateFormatService.getDateStrFromDate(
                              startDate,
                              BOOKS_DATE_FORMAT['DD-MM-YYYY']
                            );

                          let endDate = new Date(date);
                          endDate.setMonth(
                            endDate.getMonth() +
                              mappedProductRows[rowIndex]?.product
                                ?.amortizationPeriod || 1
                          );

                          let endDateStr = DateFormatService.getDateStrFromDate(
                            endDate,
                            BOOKS_DATE_FORMAT['DD-MM-YYYY']
                          );

                          if (
                            Utility.isEmpty(
                              mappedProductRows[rowIndex]
                                ?.amortizationDocumentItemDetails
                            )
                          ) {
                            amortizationItemDetails = {
                              startDate: startDateStr,
                              endDate: endDateStr
                            };
                          } else {
                            amortizationItemDetails = {
                              ...mappedProductRows[rowIndex]
                                ?.amortizationDocumentItemDetails,
                              startDate: startDateStr,
                              endDate: endDateStr
                            };
                          }

                          if (mappedProductRows[rowIndex]) {
                            mappedProductRows[
                              rowIndex
                            ].amortizationDocumentItemDetails =
                              amortizationItemDetails;
                            mappedProductRows[rowIndex][
                              'amortizationStartDate'
                            ] = startDate;
                            mappedProductRows[rowIndex]['amortizationEndDate'] =
                              endDate;
                          }
                        }
                      });
                    }
                    setProductRows(mappedProductRows);
                    setBooksDocument({
                      ...booksDocument,
                      items: mappedProductRows
                    });
                    setDocumentDate(date);
                    setIsdocumentDateChanged(true);
                  },
                  `${docDateLabel} cannot be before books beginning date.`,
                  true
                );
                isDocDateUpdatedManually.current = true;
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              readOnly={!checkForCustomField}
            />
          </div>
          <div
            style={{
              width: 150,
              maxWidth: 200
            }}
          >
            <DKInput
              className="parent-width"
              title={t(`DOCUMENT.DUE_DATE`)}
              value={dueDate}
              titleStyle={{ color: 'gray' }}
              valueStyle={{ minHeight: 33 }}
              type={INPUT_TYPE.DATE}
              onChange={(newDate: any) => {
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setDueDate,
                  `${t(`DOCUMENT.DUE_DATE`)} cannot be before ${docDateLabel}.`,
                  false
                );
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              readOnly={!checkForCustomField}
            />
          </div>
          <div
            style={{
              width: 150,
              maxWidth: 200
            }}
          >
            <DKInput
              className="parent-width"
              title={shipByDateLabel}
              value={shipByDate}
              titleStyle={{ color: 'gray' }}
              valueStyle={{ minHeight: 33 }}
              type={INPUT_TYPE.DATE}
              onChange={(newDate: any) => {
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setShipByDate,
                  `${shipByDateLabel} date cannot be before ${docDateLabel}.`,
                  false
                );
              }}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              readOnly={!checkForCustomField}
            />
          </div>

          {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
            !Utility.isEmpty(contact) &&
            getPlaceOfSupply()}
          {(props.booksDocument.documentType === DOC_TYPE.BILL ||
            props.booksDocument.documentType === DOC_TYPE.ORDER ||
            props.booksDocument.documentType ===
              DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
            props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
            Utility.isDropship(props.booksDocument)) &&
            getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
            !Utility.isEmpty(contact) &&
            getSourceOfDestination()}

          {(props.booksDocument.documentType === DOC_TYPE.INVOICE ||
            props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
            (props.booksDocument.documentType === DOC_TYPE.ORDER &&
              (props.booksDocument.dropShip ||
                props.booksDocument.backOrder)) ||
            props.booksDocument.documentType === DOC_TYPE.QUOTE) && (
            <div
              style={{
                width: 150,
                maxWidth: 200,
                wordBreak: 'break-word'
              }}
            >
              <DKInput
                className="parent-width"
                title="Customer Order No."
                value={booksDocument?.customerOrderNumber}
                titleStyle={{ color: 'gray' }}
                valueStyle={{ minHeight: 33 }}
                type={INPUT_TYPE.TEXT}
                onChange={(value: any) => {
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      customerOrderNumber: value
                    };
                  });
                }}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={false}
                readOnly={
                  props.documentMode === DOCUMENT_MODE.VIEW ||
                  (props.booksDocument.documentType === DOC_TYPE.ORDER &&
                    (props.booksDocument.dropShip ||
                      props.booksDocument.backOrder))
                }
              />
            </div>
          )}
          {renderSIBuyerReference()}
          {reanderPEOPPLPaymentTerm()}

          {(((props.documentMode === DOCUMENT_MODE.NEW ||
            props.documentMode === DOCUMENT_MODE.COPY) &&
            props.draftData?.data?.isCashInvoice &&
            props.booksDocument.documentType === DOC_TYPE.INVOICE) ||
            props.autoProcessDoc) &&
            getPaymentRelatedFields()}

          {(props.booksDocument.documentType === DOC_TYPE.BILL ||
            props.booksDocument.documentType === DOC_TYPE.ORDER ||
            props.booksDocument.documentType ===
              DOC_TYPE.PURCHASE_INWARD_QUOTATION) && (
            <>
              <div
                style={{
                  width: 150,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Supplier Inv No."
                  value={booksDocument?.supplierInvoiceNo}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="right"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        supplierInvoiceNo: value
                      };
                    });
                  }}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={
                    (props.documentMode === DOCUMENT_MODE.VIEW &&
                      props.draftType === DraftTypes.READONLY) ||
                    !checkForCustomField
                  }
                />
              </div>
            </>
          )}
          {tenantInfo.country === COUNTRY_CODES.IL && (
            <>
              <div
                style={{
                  width: 150,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Payer Id"
                  value={booksDocument?.contact?.taxPayerIdIsrael || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  width: 150,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Registration Number"
                  value={booksDocument?.contact?.taxNumber || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const getRightInfoPanel = () => {
    const docDateLabel = getDateText();
    let shipByDateLabel = Utility.isSalesDocument(props.booksDocument)
      ? t(`DOCUMENT.SHIP_BY_EXPECTED`)
      : `Receive By`;

    if (
      props?.booksDocument?.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ) {
      shipByDateLabel = 'Valid Till';
    }

    let linkedDocument: any[] = [];
    let linkedWorkOrders = booksDocument?.linkedDocuments?.filter(
      (woItem: any) => woItem?.documentType === DOC_TYPE.WORK_ORDER
    );
    let linkedGateEntries = booksDocument?.linkedDocuments?.filter(
      (item: any) => item?.documentType === DOC_TYPE.GATE_ENTRY
    );
    switch (props.booksDocument.documentType) {
      case DOC_TYPE.QUOTE:
        if (
          booksDocument.linkedSalesInvoices &&
          booksDocument.linkedSalesInvoices?.length
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedSalesInvoices
          ];
        }
        if (
          booksDocument.linkedSalesOrders &&
          booksDocument.linkedSalesOrders?.length
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedSalesOrders
          ];
        }
        if (
          booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
          booksDocument.backOrder
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedDocuments
          ];
        }
        if (linkedWorkOrders && linkedWorkOrders.length) {
          linkedDocument = [...linkedDocument, ...linkedWorkOrders];
        }
        break;
      case DOC_TYPE.SALES_ORDER:
        if (
          booksDocument.linkedQuotationDocuments &&
          booksDocument.linkedQuotationDocuments?.length
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedQuotationDocuments
          ];
        }
        if (
          booksDocument.linkedSalesInvoices &&
          booksDocument.linkedSalesInvoices?.length
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedSalesInvoices
          ];
        }
        if (
          (booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
            booksDocument.backOrder) &&
          booksDocument.linkedDocuments &&
          booksDocument.linkedDocuments?.length
        ) {
          linkedDocument = [
            ...linkedDocument,
            ...booksDocument.linkedDocuments
          ];
        }
        if (linkedWorkOrders && linkedWorkOrders.length) {
          linkedDocument = [...linkedDocument, ...linkedWorkOrders];
        }
        break;
      case DOC_TYPE.ORDER:
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        linkedDocument = booksDocument.linkedPurchaseInvoices?.length
          ? booksDocument.linkedPurchaseInvoices
          : booksDocument.dropShip || booksDocument.backOrder
          ? booksDocument.linkedDocuments
          : null;
        linkedDocument = booksDocument.linkedPurchaseInvoices?.length
          ? booksDocument?.linkedDocuments?.[0]?.documentType ===
            'PURCHASE_REQUEST'
            ? linkedDocument.concat(booksDocument.linkedDocuments)
            : linkedDocument
          : booksDocument.linkedDocuments;
        if (
          props.booksDocument.documentType === DOC_TYPE.ORDER &&
          linkedGateEntries &&
          linkedGateEntries.length
        ) {
          let isGateEntryPresent = linkedDocument?.filter(
            (item: any) => item?.documentType === DOC_TYPE.GATE_ENTRY
          );
          if (Utility.isEmpty(isGateEntryPresent)) {
            linkedDocument = [...linkedDocument, ...linkedGateEntries];
          }
        }
        break;
      case DOC_TYPE.INVOICE:
      case DOC_TYPE.BILL:
      default:
        linkedDocument = booksDocument.linkedDocuments?.length
          ? booksDocument.linkedDocuments
          : booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
            booksDocument.backOrder
          ? booksDocument.linkedDocuments
          : null;
    }
    const showMilestoneBlock =
      PAYMENT_MILESTONE_ENABLED_DOCS.includes(
        props.booksDocument.documentType
      ) && props.booksDocument?.paymentMilestoneFlag;
    return (
      <div className="column align-items-stretch">
        {props.draftType === DraftTypes.DRAFT &&
          props.draftData?.data?.draftCode &&
          !props.booksDocument?.seqCodeAlreadyDumped && (
            <div className="position-relative walkthrough-step-5">
              <div
                className="row width-auto mb-xs justify-content-between p-v-xs"
                style={{
                  width: 240
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text="Draft No." className={'fw-m ml-r'} />
                </div>
                <DKLabel
                  text={props.draftData.data.draftCode}
                  className={'ml-r '}
                />
              </div>
            </div>
          )}
        <div className="position-relative">
          <div
            className={`row width-auto mb-xs justify-content-between ${
              props.documentMode !== DOCUMENT_MODE.EDIT
                ? 'listPickerBG'
                : 'p-v-xs'
            }`}
            style={{
              width: isDocumentInFullScreen ? 'auto' : 240
            }}
          >
            <div
              className="row width-auto cursor-pointer"
              onClick={() => setopenCustomNumberList((value) => !value)}
            >
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel
                text={'No.'}
                className={'fw-m ml-r'}
                style={{ marginRight: isDocumentInFullScreen ? 20 : 0 }}
              />
            </div>
            {(props.documentMode === DOCUMENT_MODE.EDIT ||
              props.documentMode === DOCUMENT_MODE.VIEW ||
              props.booksDocument?.seqCodeAlreadyDumped === true) &&
              props.booksDocument.documentSequenceCode && (
                <DKLabel
                  text={props.booksDocument.documentSequenceCode}
                  className={'ml-r '}
                />
              )}
            {(props.documentMode === DOCUMENT_MODE.NEW ||
              props.documentMode === DOCUMENT_MODE.COPY ||
              (props.documentMode === DOCUMENT_MODE.VIEW &&
                !props.booksDocument.documentSequenceCode)) &&
              !props.booksDocument?.seqCodeAlreadyDumped && (
                <div className="w-9/12 -mr-1">
                  <CustomNumberFormatInput
                    module={customNumberFormatModule}
                    selectedFormat={selectedFormat}
                    showCompact={true}
                    extraClass={'top-12 right-0'}
                    openList={openCustomNumberList}
                    autoNumberingFormat={
                      !!props.booksDocument.documentSequenceCode
                        ? {
                            id: null,
                            manualMode: true,
                            text: props.booksDocument.documentSequenceCode
                          }
                        : {}
                    }
                  />
                </div>
              )}
          </div>
        </div>
        {RCM_DOCUMENTS.includes(props.booksDocument.documentType) &&
          getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && (
            <>
              <div
                style={{
                  wordBreak: 'break-word'
                }}
                className="row width-auto mb-l justify-start  cursor-pointer relative items-center"
              >
                <DKTooltipWrapper
                  content={`
               Click gear icon in COA to
               check/map default RCM account`}
                >
                  <DKIcon
                    src={DKIcons.ic_info}
                    className="ic-r ic-s cursor-hand unselectable"
                    style={{ opacity: 1 }}
                  />
                </DKTooltipWrapper>
                <DKCheckMark
                  color="bg-button"
                  isSelected={booksDocument.applyRcmCheck}
                  onClick={() => {
                    handleAndUpdateRCMCheck(!booksDocument.applyRcmCheck);
                  }}
                  className="text-black z-index-1 ml-s"
                  title={'Is RCM Applicable'}
                />
              </div>
            </>
          )}

        {!isDocumentInFullScreen && (
          <>
            <div className="position-relative">
              <div
                className="row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs"
                style={{
                  width: 240
                }}
                onClick={() => {
                  setInvoiceDateOpen(!invoiceDateOpen);
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_date}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={docDateLabel} className={'fw-m ml-r'} />
                </div>
                <DKLabel
                  text={DateFormatService.getDateStrFromDate(documentDate)}
                  className={'ml-r '}
                />
              </div>
              {checkForCustomField &&
                invoiceDateOpen &&
                getCalendarView(
                  documentDate,
                  (newDate: any) => {
                    validateAndUpdateDate(
                      newDate,
                      DateFormatService.getDateFromStr(
                        tenantInfo.bookBeginningStartDate,
                        BOOKS_DATE_FORMAT['YYYY-MM-DD']
                      ),
                      (date: any) => {
                        let mappedProductRows = [...productRows];
                        // update line level expected Delivery date for lead time
                        if (
                          Utility.isMRPWithURLCheck() &&
                          (props.booksDocument.documentType === DOC_TYPE.BILL ||
                            props.booksDocument.documentType ===
                              DOC_TYPE.ORDER ||
                            props.booksDocument.documentType ===
                              DOC_TYPE.PURCHASE_INWARD_QUOTATION)
                        ) {
                          mappedProductRows = mappedProductRows?.map(
                            (productItem: any) => {
                              let leadTimeValue = productItem?.product
                                ?.leadTimeDetails?.[0]?.leadTime
                                ? productItem?.product?.leadTimeDetails?.[0]
                                    ?.leadTime
                                : productItem?.product?.leadTime;
                              productItem.expectedDeliveryDt =
                                updateExpectedDeliveryDateForLineItem(
                                  leadTimeValue || 0,
                                  date
                                );
                              return productItem;
                            }
                          );
                          updateShipByDateForLineLevelDate(
                            documentDate > date ? date : shipByDate,
                            mappedProductRows
                          );
                        }
                        if (
                          props.booksDocument.documentType === DOC_TYPE.BILL &&
                          featurePermissionsInfo?.Supported?.includes(
                            FEATURE_PERMISSIONS.AMORTIZATION
                          ) &&
                          tenantInfo?.additionalSettings?.AMORTIZATION
                        ) {
                          mappedProductRows.forEach((row, rowIndex) => {
                            if (row?.amortizationTemplate) {
                              let amortizationItemDetails = {};
                              let startDate = new Date(date);
                              let startDateStr =
                                DateFormatService.getDateStrFromDate(
                                  startDate,
                                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                                );

                              let endDate = new Date(date);
                              endDate.setMonth(
                                endDate.getMonth() +
                                  mappedProductRows[rowIndex]?.product
                                    ?.amortizationPeriod || 1
                              );

                              let endDateStr =
                                DateFormatService.getDateStrFromDate(
                                  endDate,
                                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                                );

                              if (
                                Utility.isEmpty(
                                  mappedProductRows[rowIndex]
                                    ?.amortizationDocumentItemDetails
                                )
                              ) {
                                amortizationItemDetails = {
                                  startDate: startDateStr,
                                  endDate: endDateStr
                                };
                              } else {
                                amortizationItemDetails = {
                                  ...mappedProductRows[rowIndex]
                                    ?.amortizationDocumentItemDetails,
                                  startDate: startDateStr,
                                  endDate: endDateStr
                                };
                              }

                              if (mappedProductRows[rowIndex]) {
                                mappedProductRows[
                                  rowIndex
                                ].amortizationDocumentItemDetails =
                                  amortizationItemDetails;
                                mappedProductRows[rowIndex][
                                  'amortizationStartDate'
                                ] = startDate;
                                mappedProductRows[rowIndex][
                                  'amortizationEndDate'
                                ] = endDate;
                              }
                            }
                          });
                        }
                        setProductRows(mappedProductRows);
                        setBooksDocument({
                          ...booksDocument,
                          items: mappedProductRows
                        });
                        setDocumentDate(date);
                        setIsdocumentDateChanged(true);
                      },
                      `${docDateLabel} cannot be before books beginning date.`,
                      true
                    );
                    isDocDateUpdatedManually.current = true;
                  },
                  setInvoiceDateOpen
                )}
            </div>
            {/* due date */}
            {props.booksDocument.documentType !==
              DOC_TYPE.PURCHASE_INWARD_QUOTATION && (
              <div className="position-relative">
                <div
                  className="row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs z-index-1"
                  style={{
                    width: 240
                  }}
                  onClick={() => {
                    setDueDateOpen(!dueDateOpen);
                  }}
                >
                  <div className="row width-auto">
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />
                    <DKLabel
                      text={t(`DOCUMENT.DUE_DATE`)}
                      className={'fw-m ml-r'}
                    />
                    {showMilestoneBlock && (
                      <DKTooltipWrapper
                        content={
                          '<b>Payment milestones added,<br> click to view.</b>'
                        }
                        tooltipClassName="bg-deskera-secondary width-auto"
                      >
                        <div
                          style={{
                            pointerEvents: 'auto'
                          }}
                          onClick={(e: SyntheticEvent) => {
                            e.stopPropagation();
                            setShowPaymentMilestonePopup(true);
                          }}
                        >
                          <DKIcon
                            src={ic_milestone}
                            className="ic-xs-2 ml-s cursor-hand z-index-2"
                          />
                        </div>
                      </DKTooltipWrapper>
                    )}
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(dueDate)}
                    className={'ml-r '}
                  />
                </div>
                {checkForCustomField &&
                  dueDateOpen &&
                  getCalendarView(
                    dueDate,
                    (newDate: any) =>
                      validateAndUpdateDate(
                        newDate,
                        documentDate,
                        setDueDate,
                        `${t(
                          `DOCUMENT.DUE_DATE`
                        )} cannot be before ${docDateLabel}.`,
                        false
                      ),
                    setDueDateOpen
                  )}
              </div>
            )}
            {/* receive by and other dates */}
            <div className="position-relative">
              <div
                className={`row width-auto justify-content-between cursor-pointer listPickerBG p-v-xs ${
                  linkedDocument ? 'mb-xs' : ''
                }`}
                style={{
                  width: 240
                }}
                onClick={() => {
                  setShipByDateOpen(!shipByDateOpen);
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_date}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={shipByDateLabel} className={'fw-m ml-r'} />
                </div>
                <DKLabel
                  text={DateFormatService.getDateStrFromDate(shipByDate)}
                  className={'ml-r '}
                />
              </div>
              {checkForCustomField &&
                shipByDateOpen &&
                getCalendarView(
                  shipByDate,
                  (newDate: any) =>
                    validateAndUpdateDate(
                      newDate,
                      documentDate,
                      setShipByDate,
                      `${shipByDateLabel} date cannot be before ${docDateLabel}.`,
                      false
                    ),
                  setShipByDateOpen
                )}
            </div>
          </>
        )}
        {(props.booksDocument.documentType === DOC_TYPE.INVOICE ||
          props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
          (props.booksDocument.documentType === DOC_TYPE.ORDER &&
            (props.booksDocument.dropShip || props.booksDocument.backOrder)) ||
          props.booksDocument.documentType === DOC_TYPE.QUOTE) &&
          !isDocumentInFullScreen && (
            <div className="position-relative walkthrough-step-5">
              <div
                className={`row width-auto mb-xs justify-content-between`}
                style={{
                  width: 240
                }}
              >
                <div className="row width-auto cursor-pointer">
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel
                    text={'Customer Order No.'}
                    className={'fw-m ml-r'}
                  />
                </div>
                {
                  <div
                    style={{
                      overflowWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                      minWidth: '75px'
                    }}
                    className="-mr-1"
                  >
                    <DKInput
                      required={false}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      readOnly={
                        props.documentMode === DOCUMENT_MODE.VIEW ||
                        (props.booksDocument.documentType === DOC_TYPE.ORDER &&
                          (props.booksDocument.dropShip ||
                            props.booksDocument.backOrder))
                      }
                      type={INPUT_TYPE.TEXT}
                      value={booksDocument?.customerOrderNumber}
                      onChange={(value: any) => {
                        setBooksDocument((prevState: any) => {
                          return {
                            ...prevState,
                            customerOrderNumber: value
                          };
                        });
                      }}
                    />
                  </div>
                }
              </div>
            </div>
          )}
        {renderPeppolPaymentTermRightSide()}
        {renderOrderReferenceNumber()}
        {linkedDocument?.length ? (
          <div className="position-relative">
            <div
              className="row width-auto justify-content-between p-v-xs align-items-start"
              style={{
                width: 240
              }}
            >
              <div className="row" style={{ width: 110 }}>
                <DKIcon
                  src={DKIcons.data_type.ic_url}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel
                  text={`Linked To`}
                  className={'fw-m ml-r'}
                  style={{ whiteSpace: 'nowrap' }}
                />
              </div>
              <div
                className="column ml-r fs-r align-items-end"
                style={{ whiteSpace: 'break-spaces' }}
              >
                {linkedDocument.map((doc: any) => (
                  <DKLabel text={doc.documentSequenceCode} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {(((props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
          props.draftData?.data?.isCashInvoice &&
          !isDocumentInFullScreen &&
          props.booksDocument.documentType === DOC_TYPE.INVOICE) ||
          props.autoProcessDoc) &&
          getPaymentRelatedFields()}
        {(props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType ===
            DOC_TYPE.PURCHASE_INWARD_QUOTATION) &&
          !isDocumentInFullScreen && (
            <div className="position-relative flex flex-col">
              <div
                className="row width-auto mb-m justify-content-between"
                style={{
                  minWidth: 240
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Supplier Inv No.'} className={'fw-m ml-r'} />
                </div>
                <div style={{ width: 100 }}>
                  <DKInput
                    title=""
                    textAlign="right"
                    value={booksDocument.supplierInvoiceNo}
                    valueStyle={{
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    canValidate={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.TEXT}
                    readOnly={
                      props.documentMode === DOCUMENT_MODE.VIEW &&
                      props.draftType === DraftTypes.READONLY &&
                      checkForCustomField
                    }
                    onChange={(value: string) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          supplierInvoiceNo: value
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        {tenantInfo.country === COUNTRY_CODES.IL && !isDocumentInFullScreen && (
          <div className="position-relative walkthrough-step-5">
            <div
              className={`row width-auto mb-xs justify-content-between`}
              style={{
                width: 240
              }}
            >
              <div className="row width-auto cursor-pointer">
                <DKIcon
                  src={DKIcons.data_type.ic_number}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={'Tax Payer Id'} className={'fw-m ml-r'} />
              </div>
              {
                <div
                  style={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    minWidth: '100px'
                  }}
                  className="-mr-1"
                >
                  <DKInput
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    readOnly={true}
                    type={INPUT_TYPE.TEXT}
                    value={booksDocument?.contact?.taxPayerIdIsrael || ''}
                    onChange={(value: any) => {}}
                  />
                </div>
              }
            </div>
            <div
              className={`row width-auto mb-xs justify-content-between`}
              style={{
                width: 240
              }}
            >
              <div className="row width-auto cursor-pointer">
                <DKIcon
                  src={DKIcons.data_type.ic_number}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel
                  text={'Tax Registration Number'}
                  className={'fw-m ml-r'}
                />
              </div>
              {
                <div
                  style={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    minWidth: '100px'
                  }}
                  className="-mr-1"
                >
                  <DKInput
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    readOnly={true}
                    type={INPUT_TYPE.TEXT}
                    value={booksDocument?.contact?.taxNumber || ''}
                    onChange={(value: any) => {}}
                  />
                </div>
              }
            </div>
          </div>
        )}
      </div>
    );
  };

  const loadAccountGroups = async (currency: string) => {
    AccountsService.fetchAccountGroup(currency)
      .then((accountGroups: any[]) => {
        const filterAccounts =
          tenantInfo?.additionalSettings?.ACCOUNT?.showCardCashBankAccountsOnly;
        let filteredAccountGroups = [];
        if (filterAccounts) {
          filteredAccountGroups = accountGroups?.filter(
            (acc: any) =>
              ([COMMON_CONSTANT.CASH, COMMON_CONSTANT.BANK].includes(
                acc.accountGroup
              ) ||
                (acc.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                  acc.isCreditCard === true)) &&
              acc.status === STATUS_TYPE.ACTIVE
          );
        } else {
          filteredAccountGroups = accountGroups.filter(
            (el) =>
              el.accountGroup === COMMON_CONSTANT.BANK ||
              el.accountGroup === COMMON_CONSTANT.CASH ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                el.isCreditCard) ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_ASSETS &&
                el.isUndepositedFundAccount &&
                props.booksDocument.documentType === DOC_TYPE.INVOICE)
          );
        }

        let paymentMethodOptions: any[] = [];
        const paymentMethods = filteredAccountGroups.map(
          (filteredAccountGroup) => makePaymentMethod(filteredAccountGroup)
        );

        paymentMethods.forEach((method) => {
          let option: any = {
            label: method.name,
            accountGroupName: Utility.convertInTitleCase(method.accountGroup),
            value: method.code,
            isUndepositedFundAccount: method.isUndepositedFundAccount
              ? true
              : false
          };
          let found = false;
          paymentMethodOptions.forEach((paymentMethodOption) => {
            if (
              paymentMethodOption.label === option.label &&
              paymentMethodOption.accountGroup === option.accountGroup
            ) {
              found = true;
            }
          });
          if (!found) {
            paymentMethodOptions.push(option);
          }
        });

        const defaultAccountGroupOption =
          paymentMethodOptions.find(
            (el: any) => el.value === COMMON_CONSTANT.DEFAULT_DEPOSIT_TO
          ) || paymentMethodOptions[0];
        setPaymentAccountGroupsOptions(paymentMethodOptions);
        setBooksDocument((prevState: any) => ({
          ...prevState,
          accountGroupForPayment: defaultAccountGroupOption,
          paymentType: 'BANK_TRANSFER'
        }));
      })
      .catch((err: any) => {
        console.error('Error loading account groups: ', err);
      });
  };

  const getOptionForPaymentType = (options: any[]) => {
    switch (booksDocument?.accountGroupForPayment?.accountGroupName) {
      case 'Current Assets':
        return [
          ...options,
          {
            label: 'Cash',
            value: 'CASH'
          }
        ];
      case 'Bank':
        return [
          ...options,
          {
            label: 'ACH',
            value: 'ACH'
          }
        ];
      default:
        return options;
    }
  };

  const getPaymentRelatedFields = () => {
    const PAYMENT_TYPE: any[] = [
      { value: 'CHEQUE', label: getCapitalized(localizedText('cheque')) },
      { value: 'BANK_TRANSFER', label: COMMON_CONSTANT.BANK_TRANSFER_VALUE },
      { value: 'CARD', label: COMMON_CONSTANT.CARD_VALUE }
    ];

    return (
      <>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between ${
              isDocumentInFullScreen ? '' : 'mb-xs mt-xs'
            }`}
            style={{
              minWidth: isDocumentInFullScreen ? 150 : 240
            }}
          >
            {!isDocumentInFullScreen && (
              <div className="row width-auto">
                <DKIcon
                  src={DKIcons.data_type.ic_number}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={'Amt. to Receive'} className={'fw-m ml-r'} />
              </div>
            )}
            <div style={{ width: isDocumentInFullScreen ? 150 : 100 }}>
              <DKInput
                title={isDocumentInFullScreen ? 'Amt. to Receive' : ''}
                textAlign="right"
                titleStyle={{ color: 'gray' }}
                value={booksDocument.amountToReceiveOrPay}
                valueStyle={{
                  minHeight: 33
                }}
                validator={(value: string) => {
                  return (
                    REGEX.DECIMAL_NUMBER.test(
                      booksDocument.amountToReceiveOrPay
                    ) &&
                    (+value > 0 || booksDocument.totalAmount === 0) &&
                    +value <= +dueAmount
                  );
                }}
                canValidate={
                  !REGEX.DECIMAL_NUMBER.test(
                    booksDocument.amountToReceiveOrPay
                  ) ||
                  (booksDocument.totalAmount > 0 &&
                    +booksDocument.amountToReceiveOrPay < 1) ||
                  +booksDocument.amountToReceiveOrPay > +dueAmount
                }
                errorMessage={'Invalid'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                readOnly={
                  props.documentMode === DOCUMENT_MODE.VIEW &&
                  props.draftType === DraftTypes.READONLY
                }
                onChange={(value: string) => {
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      amountToReceiveOrPay: value
                    };
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between ${
              isDocumentInFullScreen ? '' : 'mb-xs'
            }`}
            style={{
              minWidth: isDocumentInFullScreen ? 150 : 240
            }}
          >
            {!isDocumentInFullScreen && (
              <div className="row width-auto">
                <DKIcon
                  src={DKIcons.data_type.ic_select}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={'Deposit To'} className={'fw-m ml-r'} />
              </div>
            )}
            <div style={{ width: isDocumentInFullScreen ? 150 : 120 }}>
              <DKInput
                title={isDocumentInFullScreen ? 'Deposit To' : ''}
                titleStyle={{ color: 'gray' }}
                value={
                  paymentAccountGroupsOptions.find(
                    (aGroup: any) =>
                      aGroup.value ===
                      booksDocument.accountGroupForPayment?.value
                  )?.label
                }
                valueStyle={{
                  minHeight: 33,
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.DROPDOWN}
                readOnly={
                  props.documentMode === DOCUMENT_MODE.VIEW &&
                  props.draftType === DraftTypes.READONLY
                }
                onChange={(obj: any) => {
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      accountGroupForPayment: obj,
                      paymentType:
                        obj?.accountGroupName === 'Cash'
                          ? 'CASH'
                          : booksDocument.paymentType
                    };
                  });
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'label',
                  data: paymentAccountGroupsOptions,
                  renderer: (index: any, obj: any) => {
                    return (
                      <DKLabel
                        text={obj.label}
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}
                      />
                    );
                  },
                  onSelect: (index: any, value: any) => {}
                }}
              />
            </div>
          </div>
        </div>
        {booksDocument?.accountGroupForPayment?.accountGroupName !== 'Cash' && (
          <div className="position-relative">
            <div
              className={`row width-auto justify-content-between ${
                isDocumentInFullScreen ? '' : 'mb-xs'
              }`}
              style={{
                minWidth: isDocumentInFullScreen ? 150 : 240
              }}
            >
              {!isDocumentInFullScreen && (
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_select}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Payment Type'} className={'fw-m ml-r'} />
                </div>
              )}
              <div style={{ width: isDocumentInFullScreen ? 150 : 120 }}>
                <DKInput
                  title={isDocumentInFullScreen ? 'Payment Type' : ''}
                  titleStyle={{ color: 'gray' }}
                  value={
                    getOptionForPaymentType(PAYMENT_TYPE).find(
                      (pType: any) => pType.value === booksDocument.paymentType
                    )?.label
                  }
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.DROPDOWN}
                  readOnly={
                    props.documentMode === DOCUMENT_MODE.VIEW &&
                    props.draftType === DraftTypes.READONLY
                  }
                  onChange={(obj: any) => {
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        paymentType: obj.value
                      };
                    });
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'label',
                    data: getOptionForPaymentType(PAYMENT_TYPE),
                    renderer: (index: any, obj: any) => {
                      return (
                        <div className="flex flex-row w-full justify-content-between">
                          <DKLabel
                            style={{ fontSize: '13px' }}
                            className="text-base border-radius-s"
                            text={obj.label}
                          />
                        </div>
                      );
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getPlaceOfSupply = () => {
    return (
      <div
        className={isDocumentInFullScreen ? '' : 'mt-r'}
        style={{ width: 250 }}
      >
        <CommonStateListPicker
          value={pos || ''}
          valueStyle={{ minHeight: 33 }}
          title={
            Utility.isSalesDocument(props.booksDocument)
              ? 'Place of Supply'
              : 'Source of Supply'
          }
          className="parent-width"
          dropdownOptions={indianStatesOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={!checkForCustomField}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            setPos(value);
            let updatedState = {
              ...booksDocument,
              placeOfSupply: value.value
            };
            setBooksDocument({ ...updatedState });
            onPlaceOfsupplyChange(value);
          }}
        />
      </div>
    );
  };
  const getSourceOfDestination = () => {
    return (
      <div
        className={isDocumentInFullScreen ? '' : 'mt-r'}
        style={{ width: 250 }}
      >
        <CommonStateListPicker
          value={dos || ''}
          valueStyle={{ minHeight: 33 }}
          title={'Destination of Supply'}
          className="parent-width"
          dropdownOptions={indianStatesOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={!checkForCustomField}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            setDos(value);
            let updatedState = {
              ...booksDocument
            };
            setBooksDocument({ ...updatedState });
            onDestinationOfsupplyChange(value);
          }}
        />
      </div>
    );
  };
  //////////////////////////////////////////////////////////
  /////////////////// CUSTOM FIELDS HANDLERS ///////////////
  //////////////////////////////////////////////////////////
  const getCFModuleFromDocType = () => {
    let module: any;

    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        module = MODULES_NAME.INVOICE;
        break;
      case DOC_TYPE.BILL:
        module = MODULES_NAME.BILL;
        break;
      case DOC_TYPE.QUOTE:
        module = MODULES_NAME.QUOTATION;
        break;
      case DOC_TYPE.SALES_ORDER:
        module = MODULES_NAME.SALESORDER;
        break;
      case DOC_TYPE.ORDER:
        module = MODULES_NAME.ORDER;
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        module = MODULES_NAME.JOB_WORK_OUT;
        break;
      default:
        break;
    }

    return module as MODULES_NAME;
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        columnConfigInfo = {
          columnConfig: invoiceColumnConfig,
          tableId: invoiceConfigTableId
        };
        break;
      case DOC_TYPE.BILL:
        columnConfigInfo = {
          columnConfig: billColumnConfig,
          tableId: billConfigTableId
        };
        break;
      case DOC_TYPE.QUOTE:
        columnConfigInfo = {
          columnConfig: quoteColumnConfig,
          tableId: quoteConfigTableId
        };
        break;
      case DOC_TYPE.SALES_ORDER:
        columnConfigInfo = {
          columnConfig: salesOrderColumnConfig,
          tableId: salesOrderConfigTableId
        };
        break;
      case DOC_TYPE.ORDER:
        columnConfigInfo = {
          columnConfig: poColumnConfig,
          tableId: poConfigTableId
        };
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        columnConfigInfo = {
          columnConfig: jobWorkOutColumnConfig,
          tableId: jobWorkOutConfigTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  const getCustomFields = () => {
    return (
      <div
        className={`${
          booksDocument.customField?.length || locationData?.length
            ? 'mb-r mr-l'
            : ''
        }`}
        style={{
          marginLeft: booksDocument.customField?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={getCFModuleFromDocType()}
          customFieldsList={
            booksDocument.customField ? booksDocument.customField : []
          }
          // hideAddfieldButton={
          //   getCFModuleFromDocType() === MODULES_NAME.JOB_WORK_OUT
          // }
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          documentMode={props.documentMode}
          onUpdate={async (updatedCFList) => {
            // Handle location CF, when CF list is updated
            const listContainsLocation = updatedCFList.find(
              (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
            );
            if (listContainsLocation && listContainsLocation?.value) {
              const locationObjForCF = locationData.find(
                (loc: any) => loc.label === listContainsLocation.value
              );
              if (locationObjForCF) {
                setCustomLocation(locationObjForCF);
              } else if (
                props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW
              ) {
                // Handle edit mode when location CF is deleted ...
                // Get the address from payload
                const locationCFinDocument = booksDocument.customField?.find(
                  (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
                );

                if (locationCFinDocument) {
                  if (locationCFinDocument.value === '') {
                    setCustomLocation(undefined);
                  } else {
                    try {
                      const locationObj =
                        await LocationService.getLocationByLabel(
                          locationCFinDocument.value
                        );
                      if (!Utility.isEmpty(locationObj)) {
                        setCustomLocation(locationObj);
                      }
                    } catch (err) {
                      console.error('Error loading location by label: ', err);
                    }
                  }
                }
              }
            }
            setBooksDocument((prevState: any) => {
              return {
                ...prevState,
                customField: [...updatedCFList]
              };
            });
          }}
          onLocationUpdate={(loc: LocationDTO) => {
            if (Utility.isEmpty(loc)) {
              const isBillOrOrder = [
                DOC_TYPE.BILL,
                DOC_TYPE.ORDER,
                DOC_TYPE.JOB_WORK_OUT_ORDER
              ].includes(props.booksDocument.documentType);
              const orgPreferredShippingAddress: any =
                tenantInfo.shippingAddresses?.find(
                  (address: any) => address.preferred
                );
              const orgPreferredBillingAddress: any =
                tenantInfo.billingAddresses?.find(
                  (address: any) => address.preferred
                );
              let tempDocument = { ...booksDocument };
              if (Utility.isSalesDocument(booksDocument)) {
                tempDocument = {
                  ...tempDocument,
                  shipFrom: orgPreferredShippingAddress
                };
              }
              if (isBillOrOrder) {
                if (!Utility.isDropship(props.booksDocument)) {
                  tempDocument = {
                    ...tempDocument,
                    billTo: orgPreferredBillingAddress,
                    shipTo: orgPreferredShippingAddress
                  };
                } else {
                  tempDocument = {
                    ...tempDocument,
                    billTo: orgPreferredBillingAddress
                  };
                }
              }
              setBooksDocument((prevState: any) => ({
                ...prevState,
                ...tempDocument
              }));
            }
            setCustomLocation(loc);
          }}
          contact={contact}
        />
      </div>
    );
  };

  const getContactAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
      addressCustomFields = booksDocument?.billTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      addressCustomFields = booksDocument?.shipTo?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = booksDocument?.shipFrom?.customFields;
    }
    return Utility.isNotEmpty(addressCustomFields) ? (
      <div
        className={`${
          addressCustomFields?.length || locationData?.length ? 'mb-r mr-l' : ''
        }`}
        style={{
          marginLeft: addressCustomFields?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          documentMode={props.documentMode}
          onUpdate={async (updatedCFList) => {
            // Handle location CF, when CF list is updated
            const listContainsLocation = updatedCFList.find(
              (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
            );
            if (listContainsLocation && listContainsLocation?.value) {
              const locationObjForCF = locationData.find(
                (loc: any) => loc.label === listContainsLocation.value
              );
              if (locationObjForCF) {
                setCustomLocation(locationObjForCF);
              } else if (
                props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW
              ) {
                // Handle edit mode when location CF is deleted ...
                // Get the address from payload
                const locationCFinDocument = booksDocument.customField?.find(
                  (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
                );

                if (locationCFinDocument) {
                  if (locationCFinDocument.value === '') {
                    setCustomLocation(undefined);
                  } else {
                    try {
                      const locationObj =
                        await LocationService.getLocationByLabel(
                          locationCFinDocument.value
                        );
                      if (!Utility.isEmpty(locationObj)) {
                        setCustomLocation(locationObj);
                      }
                    } catch (err) {
                      console.error('Error loading location by label: ', err);
                    }
                  }
                }
              }
            }
            let booksDocumentCopy = { ...booksDocument };
            if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                billTo: {
                  ...booksDocumentCopy.billTo,
                  customFields: [...updatedCFList]
                }
              };
            } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                shipTo: {
                  ...booksDocumentCopy.shipTo,
                  customFields: [...updatedCFList]
                }
              };
            } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
              booksDocumentCopy = {
                ...booksDocumentCopy,
                shipFrom: {
                  ...booksDocumentCopy.shipFrom,
                  customFields: [...updatedCFList]
                }
              };
            }
            setBooksDocument(booksDocumentCopy);
          }}
          onLocationUpdate={(loc: any) => {}}
          contact={contact}
          hideAddfieldButton={true}
          addressUpdate={
            isBillToAddressUpdated ||
            isShipToAddressUpdated ||
            isContactUpdated ||
            isShipFromAddressUpdated
          }
          updateAddressField={() => {
            setIsBillToAddressUpdated(false);
            setIsShipToAddressUpdated(false);
            setIsContactUpdated(false);
            setIsShipFromAddressUpdated(false);
          }}
        />
      </div>
    ) : null;
  };

  //////////////////////////////////////////////////////////
  ///////////////// PRODUCT GRID HANDLERS //////////////////
  //////////////////////////////////////////////////////////
  const addNewItem = (returnNewRow: boolean = false) => {
    let rows = returnNewRow ? [] : [...productRows];
    let newRow = DocumentConfigManager.getBlankRow(
      props.booksDocument.documentType
    );
    newRow['productQuantity'] = 1;
    newRow['unitPrice'] = 0;
    newRow.invalidFields = DocumentConfigManager.getRequiredFields(
      props.booksDocument.documentType
    );
    newRow['lineNumber'] = productRows.length + 1;
    newRow['firstAmountChangeDone'] = false;

    /** To support Quote groupings feature in CRM Quotes */
    if (
      [DOC_TYPE.QUOTE, DOC_TYPE.INVOICE].includes(
        props.booksDocument.documentType
      )
    ) {
      newRow.productGroupId = blankProductGroupRef.current?.id;
      newRow.productGroupName = blankProductGroupRef.current?.name;
      newRow.productGroupUuid = blankProductGroupRef.current?.uuid;
      newRow.productGroupIndex = blankProductGroupRef.current?.index;
    }

    // CASCADING_DISCOUNT: handle addition of new item row
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      if (
        props.documentMode === DOCUMENT_MODE.NEW &&
        props.draftType === DraftTypes.NEW
      ) {
        const orderedDiscounts = getOrderedCascadingDiscountsFromSettings();
        if (orderedDiscounts?.length) {
          const discountType = cascadingDiscountSettings?.discountType;
          // Set itemDiscountMethod
          newRow['itemDiscountMethod'] = discountType;
          orderedDiscounts.forEach((discount: any, index: number) => {
            // Need to handle buy/sell discounts
            const discountFromStore = getCascadingDiscountsFromStore(
              booksDocument
            )?.find((dis: any) => dis.id === discount.discountId);
            if (!Utility.isEmpty(discountFromStore)) {
              const accountCode = !Utility.isSalesDocument(booksDocument)
                ? discountFromStore.incomeAccountCode
                : discountFromStore.expenseAccountCode;
              let discountValue = 0;
              if (discountFromStore.isPercent) {
                discountValue = +discountFromStore.percentageValue;
              } else {
                discountValue = +discountFromStore.chargeValue;
              }
              // Set cascading discount
              const discountKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}`;
              const discountDetailsKeyToUpdate =
                CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}_details`;
              newRow[discountKeyToUpdate] = discountFromStore.isPercent
                ? discountValue + '%'
                : discountValue;
              newRow[discountDetailsKeyToUpdate] = {
                id: discountFromStore.id,
                discount: discountValue,
                isPercent: discountFromStore.isPercent,
                accountCode: accountCode,
                name: discountFromStore.name,
                discountIndex: index,
                unitDiscount: 0
              };
            }
          });
        }
      } else {
        if (!Utility.isEmpty(columnConfig)) {
          const allCascadingDiscountConfigs = columnConfig?.filter(
            (config: any) =>
              config?.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX) &&
              !config?.key?.toString()?.endsWith('_details')
          );
          newRow['itemDiscountMethod'] = existingCascadingDiscountMethod.current
            ? existingCascadingDiscountMethod.current
            : cascadingDiscountSettings?.discountType;
          allCascadingDiscountConfigs.forEach(
            (discountConfig: any, index: number) => {
              const discountId = discountConfig?.key?.substring(
                discountConfig?.key?.toString()?.lastIndexOf('_') + 1
              );
              let discountFromStore: any = getCascadingDiscountsFromStore(
                booksDocument
              )?.find((dis: any) => dis.id === +discountId);
              if (!Utility.isEmpty(discountFromStore)) {
                let accountCode = !Utility.isSalesDocument(booksDocument)
                  ? discountFromStore.incomeAccountCode
                  : discountFromStore.expenseAccountCode;
                if (
                  accountCode !== discountConfig.accountCode &&
                  !Utility.isEmpty(discountConfig.accountCode)
                ) {
                  accountCode = discountConfig.accountCode;
                }

                let discountValue = 0;
                if (discountConfig.isPercent) {
                  discountValue = +discountFromStore.percentageValue;
                } else {
                  discountValue = +discountFromStore.chargeValue;
                }
                const discountKeyToUpdate =
                  CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}`;
                const discountDetailsKeyToUpdate =
                  CASCADING_DISCOUNT_PREFIX + `${discountFromStore.id}_details`;
                newRow[discountKeyToUpdate] = discountConfig.isPercent
                  ? discountValue + '%'
                  : discountValue;
                newRow[discountDetailsKeyToUpdate] = {
                  id: discountFromStore.id,
                  discount: discountValue,
                  isPercent: discountConfig.isPercent,
                  accountCode: accountCode,
                  name: discountConfig.discountName,
                  discountIndex: index,
                  unitDiscount: 0
                };
              } else {
                // Handle new item addition when a cascading discount has been deleted
                const discountKeyToUpdate =
                  CASCADING_DISCOUNT_PREFIX + `${discountId}`;
                const discountDetailsKeyToUpdate =
                  CASCADING_DISCOUNT_PREFIX + `${discountId}_details`;
                newRow[discountKeyToUpdate] = discountConfig.isPercent
                  ? 0 + '%'
                  : 0;
                newRow[discountDetailsKeyToUpdate] = {
                  id: +discountId,
                  discount: 0,
                  isPercent: discountConfig.isPercent,
                  accountCode: discountConfig.accountCode,
                  name: discountConfig.discountName,
                  discountIndex: index,
                  unitDiscount: 0
                };
              }
            }
          );
        }
      }
    }

    if (!Utility.isEmpty(productCustomFields)) {
      // Set default values of CFs when new line is added
      productCustomFields?.forEach((item: any) => {
        if (
          item.modules?.includes(MODULES_NAME.PRODUCT) &&
          item.status === STATUS_TYPE.ACTIVE
        ) {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            if (
              item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              newRow[item.id] = DateFormatService.getDateFromStr(
                item.defaultValue,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              newRow[item.id] = item.defaultValue;
            }
          } else {
            newRow[item.id] = '';
          }
        }
      });
    }

    if (returnNewRow) {
      return newRow;
    } else {
      rows.push(newRow);
      setProductRows(rows);
    }
  };

  const clearAllItems = () => {
    setProductRows([]);
    if (booksDocument.memo && getTenantTaxSystem() === TAX_SYSTEM.UK) {
      updateMemoOnProductDelete([]);
    }
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        items: [],
        reservedStock: false
      };
    });
  };

  const toggleReserveStock = () => {
    const reservedStock = !booksDocument.reservedStock;
    if (!reservedStock) {
      resetStockReservedData();
    }
    setBooksDocument((prevState: any) => ({
      ...prevState,
      reservedStock: reservedStock
    }));
  };

  const resetStockReservedData = () => {
    let pRows = [...productRows];
    let lineItems = [...booksDocument.items];
    pRows = pRows.map((pRow: any) => {
      return {
        ...pRow,
        reservedQuantitiesData: null
      };
    });
    lineItems = lineItems.map((lineItem: any) => {
      return {
        ...lineItem,
        reservedQuantitiesData: null
      };
    });
    setProductRows(pRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: lineItems
    }));
    setProductStockData({});
  };

  const [reserveStockLineItem, setReserveStockLineItem] = useState<any>();
  const [reservedQuantitiesOriginal, setReservedQuantitiesOriginal] =
    useState<any[]>();

  const fetchProductReservedInfo = ({ rowData }: any) => {
    const product = rowData?.product;
    const productId = product?.productId;
    if (!Utility.isEmpty(productId)) {
      ProductService.getProductReservedInfo([productId]).then(
        (reserveInfo: any[]) => {
          if (reserveInfo?.length) {
            const selectedProductInfo = reserveInfo?.filter(
              (obj) => obj.productCode === productId
            );

            if (selectedProductInfo?.length) {
              openReservedStockPopup(rowData, selectedProductInfo);
            }
          } else {
            showAlert(
              'No quantity available!',
              'Selected product has no available quantity to reserve stock.'
            );
          }
        },
        (err: any) => {
          console.error('Error while fetching product reserved info: ', err);
        }
      );
    }
  };

  const openReservedStockPopup = (
    lineItem: any,
    selectedProductInfo: any[]
  ) => {
    const productId = lineItem.product.id;
    const exitingReservedQuantitiesDataInLines = booksDocument.items
      ?.filter(
        (item: any) =>
          item.lineNumber !== lineItem.lineNumber &&
          item.product?.id === productId &&
          !Utility.isEmpty(item.reservedQuantitiesData)
      )
      .map((data: any) =>
        rebuildAdvancedTrackingMetaDtosAndUOMInfo(
          data.reservedQuantitiesData,
          data.documentUOMSchemaDefinition
        )
      )
      .reduce((acc: any[], curVal: any[]) => {
        return acc.concat(curVal);
      }, []);

    if (selectedProductInfo && exitingReservedQuantitiesDataInLines) {
      selectedProductInfo.forEach((obj) => {
        const warehouseObj = exitingReservedQuantitiesDataInLines.find(
          (o: any) => o.warehouseCode === obj.warehouseCode
        );
        if (warehouseObj) {
          const availableQty =
            obj.reservedQuantity + Number(warehouseObj.reservedQuantity);
          obj.reservedQuantity = availableQty < 0 ? 0 : Number(availableQty);
          if (
            obj.advancedTrackingType !== TRACKING_TYPE.NONE &&
            obj.advancedTrackingMetaDtos &&
            obj.advancedTrackingMetaDtos.length > 0 &&
            warehouseObj.advancedTrackingMetaDtos
          ) {
            obj.advancedTrackingMetaDtos.forEach((e: any) => {
              const batchObj = warehouseObj.advancedTrackingMetaDtos.find(
                (o: any) => o.serialBatchNumber === e.serialBatchNumber
              );
              if (batchObj) {
                const availableBatchQty =
                  e.reservedQuantity + Number(batchObj.reservedQuantity);
                e.reservedQuantity =
                  availableBatchQty < 0 ? 0 : Number(availableBatchQty);
              }
            });
          }
        }
      });
    }

    setReserveStockLineItem({ ...lineItem });

    convertReservedBaseUOMToSchema(selectedProductInfo, lineItem);

    setReservedQuantitiesOriginal(selectedProductInfo);
    setShowReserveStockPopup(true);
  };

  const convertReservedBaseUOMToSchema = (
    selectedProductInfo: any[],
    lineItem: any
  ) => {
    const reservedData = selectedProductInfo?.[0];
    if (
      reservedData &&
      reservedData.advancedTrackingType !== TRACKING_TYPE.SERIAL
    ) {
      let availableQuantity = lineItem.availableQuantity;
      const documentUOMSchemaDefinition = lineItem.documentUOMSchemaDefinition;
      if (
        documentUOMSchemaDefinition &&
        availableQuantity &&
        !documentUOMSchemaDefinition.isBaseUom
      ) {
        selectedProductInfo.forEach((obj) => {
          if (obj.availableQuantity) {
            obj.availableQuantity = Utility.getUomQuantityDecimalPrecision(
              obj.availableQuantity,
              documentUOMSchemaDefinition,
              10
            );
          }
          if (obj.reservedQuantity) {
            obj.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
              obj.reservedQuantity,
              documentUOMSchemaDefinition,
              10
            );
          }
          if (obj.advancedTrackingType === TRACKING_TYPE.BATCH) {
            obj.advancedTrackingMetaDtos.forEach((item: any) => {
              if (item.batchSize) {
                item.batchSize = Utility.getUomQuantityDecimalPrecision(
                  item.batchSize,
                  documentUOMSchemaDefinition,
                  10
                );
              }
              if (item.reservedQuantity) {
                item.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
                  item.reservedQuantity,
                  documentUOMSchemaDefinition,
                  10
                );
              }
            });
          }
        });
      }
    }
  };

  const setTaxInclusiveFlag = () => {
    if (checkForCustomField) {
      const taxInclusiveFlag = booksDocument.unitPriceGstInclusive;
      let documentItems = [...(booksDocument.items as Array<DocumentItem>)];
      documentItems = documentItems.map((item, index) => {
        return { ...item, unitPriceGstInclusive: !taxInclusiveFlag };
      });
      setRoundOffDirty(false);
      documentItems.map((item, index) =>
        calculateTaxesAndAmount(index, documentItems)
      );
      setBooksDocument((prevState: Document) => {
        return {
          ...prevState,
          items: [...documentItems],
          unitPriceGstInclusive: !taxInclusiveFlag
        };
      });
    }
  };

  const onTCSChange = (newTCS: any) => {
    let doc: Document = { ...booksDocument };
    doc.tcsRateId = newTCS?.id;
    doc.tcsPercentage = newTCS?.ratePercentage;
    doc.tcsAmount = getTCSAmount(doc);
    setBooksDocument(doc);
  };

  const getTCSAmount = (doc: any) => {
    let total = doc.items
      ? doc.items.reduce(
          (total: number, docItem: any) =>
            total + (!docItem.optional ? docItem.total : 0),
          0
        )
      : 0;
    return (Number(total) * Number(doc.tcsPercentage)) / 100;
  };

  const setRoundOffAmountInDocumentCurrency = (val: number) => {
    setRoundOffDirty(true);
    setBooksDocument((prevState: Document) => {
      return {
        ...prevState,
        roundOffAmountInDocumentCurrency: val
      };
    });
  };

  const updateCurrencyAndExchangeRate = (
    tempDocument: any,
    currencyCode: string,
    exchangeRate: number,
    updateState = true
  ) => {
    const preciseCurrencyExchangeRate = roundingOffStr(
      1 / exchangeRate,
      CURRENCY_PRECISION
    );
    return updateCurrency({
      tempDocument,
      currencyCode: currencyCode,
      exchangeRate: 1 / parseFloat(preciseCurrencyExchangeRate),
      gstExchangeRate: tempDocument.gstExchangeRate,
      updateState
    });
  };

  const updateCurrency = ({
    tempDocument,
    currencyCode,
    exchangeRate,
    gstExchangeRate,
    updateState = true
  }: any) => {
    const oldCurrency = tempDocument.currency;
    const currencyChanged = oldCurrency !== currencyCode;
    const previousExchangeRate = tempDocument.exchangeRate;

    let calculatedGSTExchangeRate = 1;
    if (currencyChanged && gstExchangeRate) {
      calculatedGSTExchangeRate =
        (gstExchangeRate * exchangeRate) / tempDocument.previousExchangeRate;
    }
    tempDocument = {
      ...tempDocument,
      currencyCode: currencyCode,
      currency: currencyCode,
      exchangeRate: exchangeRate,
      previousExchangeRate: previousExchangeRate,
      gstExchangeRate: calculatedGSTExchangeRate
    };

    if (updateState) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument
        };
      });
    }

    if (currencyChanged || exchangeRate) {
      if (!updateState) {
        return updateBasedOnCurrency(
          tempDocument,
          exchangeRate,
          previousExchangeRate,
          updateState
        );
      } else {
        updateBasedOnCurrency(tempDocument, exchangeRate, previousExchangeRate);
      }
    }
  };

  const updateBasedOnCurrency = (
    tempDocument: any,
    exchangeRate: number,
    previousExchangeRate: number,
    updateState = true
  ) => {
    if (!tempDocument.items) {
      return;
    }

    // when converting document, skip unit price, discount updates base on exchange rate
    if (props?.booksDocument?.isConverting) {
      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
        calculateUSTax(undefined, tempDocument.items);
      } else {
        tempDocument.items.forEach((item: any, index: number) => {
          calculateTaxesAndAmount(index, tempDocument.items);
        });
      }
      return;
    }

    let documentItems = [...(tempDocument.items as Array<any>)];
    documentItems = documentItems.map((item, index) => {
      const discount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        item.discountAmount
      );
      if (item.discountInPercent) {
        item = {
          ...item,
          discountAmount: discount
        };
      } else {
        item = {
          ...item,
          discountAmount: discount,
          discount: discount
        };
      }

      // CASCADING_DISCOUNT: Handle conversion of cascasing discounts
      const cascadingDiscountSettings =
        tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
      if (cascadingDiscountSettings?.enable) {
        const allCascadingDiscountKeys = Object.keys(item).filter(
          (itemKey: string) =>
            itemKey?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX) &&
            !itemKey?.toString()?.endsWith('_details')
        );
        allCascadingDiscountKeys.forEach((itemKey: string) => {
          const detailsKey = itemKey + '_details';
          const isPercent = item[detailsKey].isPercent;
          // const discountAmount = item[detailsKey].discount;
          const cDiscountAmount = convertToCurrenctExchangeRate(
            exchangeRate,
            previousExchangeRate,
            +item[detailsKey].discount
          );
          if (!isPercent) {
            item = {
              ...item,
              [itemKey]: cDiscountAmount,
              [detailsKey]: {
                ...item[detailsKey],
                discount: cDiscountAmount
              }
            };
          }
        });
      }

      return {
        ...item,
        unitPrice: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.unitPrice
        ),
        taxAmount: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.taxAmount
        )
      };
    });

    let tcsAmount = 0;
    let totalTdsAmount = 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      tcsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.tcsAmount
      );
      totalTdsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.totalTdsAmount
      );
    }

    tempDocument = {
      ...tempDocument,
      items: documentItems,
      tcsAmount,
      totalTdsAmount
    };

    if (tempDocument.items && tempDocument.items.length > 0 && updateState) {
      // if pricelist is enabled, then calculate taxes after pricelist prices are applied
      if (isPriceListEnabled()) {
        const productsPayload = tempDocument.items
          .filter((item: any) => !Utility.isEmpty(item.product))
          .map((item: any) => ({
            productId: item.product.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          true,
          tempDocument.currency,
          true,
          exchangeRate,
          { exchangeRateChanged: true }
        );
      } else {
        if (getTenantTaxSystem() === TAX_SYSTEM.US) {
          calculateUSTax(undefined, tempDocument.items);
        } else {
          tempDocument.items.forEach((item: any, index: number) => {
            calculateTaxesAndAmount(index, tempDocument.items);
          });
        }
      }
    } else {
      return tempDocument;
    }

    // this.calculateTaxLocalAndUpdate();
    // this.updateFormFieldConfig();
    // this.setCurrencyInDropdown();
  };

  const hideTaxAmountColumn = () => {
    let flag = true;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      Utility.isComplianceEnabled()
    ) {
      flag = false;
    } else if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      flag = false;
    }

    return flag;
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  // CASCADING_DISCOUNT: Get cascading discounts total column visibility
  const getCascadingDiscountTotalColumnVisibility = () => {
    let showCascadingDiscountTotal = false;
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    let cascadingDiscountsDetails: any[] = [];
    if (props.documentMode === DOCUMENT_MODE.NEW) {
      cascadingDiscountsDetails = !Utility.isEmpty(
        cascadingDiscountSettings?.discountDetails
      )
        ? cascadingDiscountSettings?.discountDetails
        : [];
      let discountsFromStore: any[] = [];
      cascadingDiscountsDetails.forEach((disc: any) => {
        const discountFromStore = getCascadingDiscountsFromStore(
          booksDocument
        )?.find((dis: any) => dis.id === disc.discountId);
        if (!Utility.isEmpty(discountFromStore)) {
          discountsFromStore.push(discountFromStore);
        }
      });
      showCascadingDiscountTotal = discountsFromStore.length > 0;
    } else {
      const columnConfigContainsCascadingDiscounts = columnConfig?.some(
        (config: any) =>
          config?.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)
      );
      showCascadingDiscountTotal = columnConfigContainsCascadingDiscounts;
    }
    return showCascadingDiscountTotal;
  };

  const ClearAmortizationSelection = () => {
    let rows: any = [...productRows];
    if (currentIndex !== -1 && rows[currentIndex]) {
      rows[currentIndex].amortizationTemplate = null;
      rows[currentIndex].amortizationDocumentItemDetails = null;
      rows[currentIndex].amortizationStartDate = null;
      rows[currentIndex].amortizationEndDate = null;
    }
    setProductRows(rows);
    setIsClearAmortizationSelection(false);
  };
  // Update grid's column config
  const updateConfig = () => {
    const showCascadingDiscountTotal =
      getCascadingDiscountTotalColumnVisibility();

    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        // case 'customField':
        //   const data = getClassDimensionData();
        //   conf.hidden = hideClassColumn();
        //   conf.editable =
        //     props.documentMode !== DOCUMENT_MODE.VIEW &&
        //     props.draftType !== DraftTypes.READONLY;
        //   conf.dropdownConfig.data = data?.length ? data : [];
        //   conf.dropdownConfig.button.onClick = () => setShowAddClassPopup(true);
        //   break;
        case 'documentSequenceCode':
          conf.editable = false;
          break;
        case 'productDescription':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            checkForCustomField;
          break;
        case 'product':
          let productDataArray =
            productsData?.content?.length > 0 ? productsData.content : [];
          conf.editable =
            (((Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.ORDER) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY ||
            props.booksDocument.isPartialInvoice ||
            props.booksDocument.isPartialSalesOrder ||
            props.booksDocument.isPartialBill ||
            (props?.booksDocument?.documentType === DOC_TYPE.ORDER &&
              props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
                'PURCHASE_REQUEST')
              ? false
              : true) ||
              !!(
                (props.booksDocument.isPartialInvoice ||
                  props.booksDocument.isPartialBill ||
                  props.booksDocument.isPartialSalesOrder) &&
                props.documentMode !== DOCUMENT_MODE.VIEW &&
                props.draftType !== DraftTypes.READONLY &&
                tenantInfo.additionalSettings?.EDIT_CONVERTED_DOC
              )) &&
            checkForCustomField;
          conf.dropdownConfig.data =
            productDataArray.length > 0
              ? DocumentConfigUtility.productDataParser(
                  { content: productDataArray },
                  props.booksDocument.documentType
                )
              : [];
          switch (props?.booksDocument?.documentType) {
            case DOC_TYPE.QUOTE:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_SALES
                );
              break;
            case DOC_TYPE.SALES_ORDER:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_SALES
                );
              break;
            case DOC_TYPE.INVOICE:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_SALES
                );
              break;
            case DOC_TYPE.REQUISITION:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_PURCHASE
                );
              break;
            case DOC_TYPE.BILL:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_PURCHASE
                );
              break;
            case DOC_TYPE.ORDER:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_PURCHASE
                );
              break;
            case DOC_TYPE.JOB_WORK_OUT_ORDER:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(
                  search,
                  tenantInfo,
                  PRODUCT_TRANSACTIONTYPE_PURCHASE,
                  'active=true,hasVariants=false,type=BILL_OF_MATERIALS'
                );
              break;
            default:
              conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
                DocumentConfigManager.getProductURL(search, tenantInfo, '');
          }

          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.productDataParser(
              data,
              props.booksDocument.documentType
            );
          if (checkUserPermission(PERMISSIONS_BY_MODULE.PRODUCTS.CREATE)) {
            conf.dropdownConfig.button.onClick = () =>
              setShowProductPopup(true);
          } else {
            conf.dropdownConfig.button = null;
          }
          break;
        case 'tax':
          let taxData = Utility.isSalesDocument(props.booksDocument)
            ? salesTaxes
            : purchaseTaxes;
          const tmpDocumentDate = new Date(documentDate.setHours(0, 0, 0, 0));
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                tmpDocumentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                tmpDocumentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                tmpDocumentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX)) &&
            checkForCustomField;
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                props.booksDocument.documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(
              data,
              props.booksDocument.documentType
            );
          if (!checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX)) {
            conf.dropdownConfig.button = null;
          } else {
            conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true);
          }

          break;
        case 'uom':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            checkForCustomField;
          conf.dropdownConfig.data = selectedProductUOMS || [];
          break;
        case 'taxAmount':
          conf.hidden = hideTaxAmountColumn();
          conf.name =
            getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
          conf.editable =
            !(!hideTaxAmountColumn() && contact && contact.taxExempted) &&
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            !coutriesWithTaxAmountNonEditable.includes(tenantInfo.country) &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX)) &&
            checkForCustomField;
          conf.type =
            !hideTaxAmountColumn() && contact && contact.taxExempted
              ? INPUT_TYPE.TEXT
              : INPUT_TYPE.NUMBER;
          conf.formatter = (data: any) => {
            if (
              !hideTaxAmountColumn() &&
              ((contact && contact.taxExempted) ||
                data?.rowData?.product?.taxable === false)
            ) {
              // Tax exempted contact for US
              return 'Exempted';
            } else {
              return DocumentConfigUtility.amountFormatter(
                data.value,
                booksDocument.currency
              );
            }
          };
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          break;
        case 'discount':
          conf.hidden = showCascadingDiscountTotal;
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_DISCOUNT)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_DISCOUNT)) &&
            checkForCustomField;
          conf.renderer = (data: any) => {
            const hasPercent = productRows[data.rowIndex]?.discountInPercent;
            return (
              <div className="column parent-width align-items-end">
                <DKLabel
                  className="fs-m"
                  text={
                    hasPercent
                      ? data.value + '%'
                      : DocumentConfigUtility.amountFormatter(
                          data.value,
                          booksDocument.currency
                        )
                  }
                />
              </div>
            );
          };
          break;
        case 'totalDiscountAmount':
          conf.hidden = !showCascadingDiscountTotal;
          conf.editable = false;
          conf.formatter = (data: any) => {
            return DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          };
          break;
        case 'unitPrice':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_PRICE)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_PRICE)) &&
            checkForCustomField;
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          conf.dropdownConfig.data = selectedProductPriceList;
          break;
        case 'productQuantity':
          conf.editable =
            ((Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.ORDER) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY
              ? false
              : true) && checkForCustomField;
          break;
        case 'qtyConvertedToWo':
          conf.editable = false;
          break;
        default:
          // CASCADING_DISCOUNT: handle column config update
          if (conf.key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
            conf.editable =
              props.documentMode !== DOCUMENT_MODE.VIEW &&
              props.draftType !== DraftTypes.READONLY &&
              (Utility.isEmpty(props.permissionKeys?.EDIT_DISCOUNT)
                ? true
                : checkUserPermission(props.permissionKeys.EDIT_DISCOUNT)) &&
              checkForCustomField;
            conf.renderer = (data: any) => {
              const hasPercent = data?.value?.toString()?.includes('%');
              return (
                <div className="column parent-width align-items-end">
                  <DKLabel
                    className="fs-m"
                    text={
                      hasPercent
                        ? data.value
                        : DocumentConfigUtility.amountFormatter(
                            data.value,
                            booksDocument.currency
                          )
                    }
                  />
                  {hasPercent ? (
                    <DKLabel
                      className="text-gray fs-s"
                      text={
                        data?.rowData?.[`${conf.key}_details`]
                          ? DocumentConfigUtility.amountFormatter(
                              data?.rowData?.[`${conf.key}_details`]
                                ?.unitDiscount,
                              booksDocument.currency
                            )
                          : ''
                      }
                    />
                  ) : null}
                </div>
              );
            };
          } else {
            // CUSTOM_FIELDS: handle column config update
            conf.editable =
              props.documentMode !== DOCUMENT_MODE.VIEW &&
              props.draftType !== DraftTypes.READONLY &&
              (checkForCustomField ||
                booksDocument.documentType === DOC_TYPE.BILL);
            const classData = dimensionData?.content?.find(
              (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
            );
            if (classData && classData.id === conf.id) {
              conf.hidden = hideClassColumn();
              conf.dropdownConfig.button = {
                title: '+ Add New',
                className: 'bg-button text-white',
                onClick: () => setShowAddClassPopup(true)
              };
            }
            if (conf.type === INPUT_TYPE.DROPDOWN && !!conf.dropdownConfig) {
              conf.dropdownConfig.allowSearch =
                conf.dropdownConfig?.data?.length > 5;
              conf.dropdownConfig.searchableKey = 'value';
            }
            if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
              const soQtyCols = [
                'qtyOrdered',
                'qtyCommitted',
                'pickedQty',
                'packedQty',
                'shippedFulfilledQty',
                'backorderQty',
                'availableQty',
                'invoicedQty'
              ];
              if (soQtyCols.includes(conf.key)) {
                conf.editable = false;
              }
            }
            if (
              props.booksDocument.documentType === DOC_TYPE.INVOICE ||
              props.booksDocument.documentType === DOC_TYPE.QUOTE
            ) {
              const soDetailsCols = ['availableQuantity'];
              if (tenantInfo?.additionalSettings?.TRACKING_DETAILS_IN_SO) {
                if (soDetailsCols.includes(conf.key)) {
                  conf.editable = false;
                }
              }
            }
          }
          break;
      }
      if (!checkUserPermission(props.permissionKeys.VIEW_PRICE)) {
        if (PricingColumnKeys.includes(conf.key)) {
          conf.hidden = true;
        }
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const getDiscountRelatedKeys = (discount: string) => {
    let updatedItemKeys: any = {};
    let discountInNum = 0;
    if (discount) {
      if (discount.toString().indexOf('%') > -1) {
        updatedItemKeys.discountInPercent = true;
        discountInNum = Number(discount.replace('%', ''));
      } else {
        discountInNum = Number(discount);
        updatedItemKeys.discountInPercent = false;
      }
      updatedItemKeys.discount = Utility.roundOff(
        discountInNum,
        CURRENCY_PRECISION
      );
      return updatedItemKeys;
    } else {
      updatedItemKeys.discount = discountInNum;
    }
    return updatedItemKeys;
  };

  const getSelectedUomForEdit = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };

  const calculateTaxesAndAmount = (
    rowIndex: number,
    unsavedRows?: any,
    isBarcode?: boolean,
    priceBook?: any
  ) => {
    if (!unsavedRows) {
      unsavedRows = [...productRows];
    }

    let row = { ...unsavedRows[rowIndex] };
    if (!Utility.isEmpty(row?.product)) {
      let item = {
        ...row,
        taxCode: row?.tax?.code,
        taxName: row?.tax?.name,
        taxSystem: getTenantTaxSystem(),
        gstType: Utility.getValue(row.gstType, booksDocument.gstType),
        unitPriceGstInclusive: row.unitPriceGstInclusive,
        exciseAmount: 0,
        cessRule: row.product.cessRule,
        isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
          props.booksDocument.documentType,
          contact?.gstTreatment,
          row.product,
          booksDocument.applyRcmCheck
        )
      };
      item = {
        ...item,
        ...getDiscountRelatedKeys(
          row['discountInPercent'] ? row['discount'] + '%' : row['discount']
        )
      };
      ItemTaxCalculator.item = item;
      if (!ItemTaxCalculator.item.taxAmount) {
        ItemTaxCalculator.item.taxAmount = 0;
      }
      ItemTaxCalculator.tenantInfo = tenantInfo;
      ItemTaxCalculator.setInitialValues();
      let taxAmount = 0;
      if (skipCalculateTaxLocal.current) {
        taxAmount = item.taxAmount;
      } else if (item.userSetTaxes) {
        taxAmount = item?.taxAmount;
      } else {
        taxAmount = ItemTaxCalculator.calculateTaxAmount();
        // TODO: HANDLE MANUAL TAX AMOUNT UPDATE HERE
      }
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      // for landed cost calculation for india only
      setTotalAmountforLandedCost(rowIndex);
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          props.booksDocument.isPartialInvoice ||
          !Utility.isEmpty(contact))
      ) {
        setGSTValueManual(rowIndex, row, item);
      }
      ItemTaxCalculator.item.amount = ItemTaxCalculator.item.totalAmount || 0;
      unsavedRows[rowIndex] = { ...ItemTaxCalculator.item };
      lastUpdatedIndex.current = null;
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          items: [...unsavedRows],
          priceListId: priceBook?.id ?? prevBooksDocument?.priceListId,
          priceListName: priceBook?.name ?? prevBooksDocument?.priceListName,
          isDocumentTouched:
            lineItemsTouched || prevBooksDocument.isDocumentTouched
        };
      });
      if (lineItemsTouched) {
        setLineItemsTouched(false);
      }
    }
    if (isBarcode) {
      // return 'ItemTaxCalculator.item', only if it is not empty
      if (!Utility.isEmpty(ItemTaxCalculator.item)) {
        return { ...ItemTaxCalculator.item };
      } else {
        return row;
      }
    } else {
      setProductRows([...unsavedRows]);
    }
  };

  // for landed cost calculation for india only

  const setTotalAmountforLandedCost = (rowIndex: any) => {
    let row = productRows[rowIndex];
    let landedCostDetails = row?.landedCostDetails;
    let category =
      !Utility.isEmpty(landedCostDetails) &&
      JSON.parse(landedCostDetails?.landedCostCategory);
    let flag =
      currentRowLandedCostInfo?.rowIndex === rowIndex
        ? currentRowLandedCostInfo?.rowIndex === rowIndex
        : props.documentMode === DOCUMENT_MODE.EDIT;
    if (
      flag &&
      category &&
      category.value === 'CUSTOM_DUTY' &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST
    ) {
      let totalSum = row?.landedCostDetails?.productDetails.reduce(function (
        prev: any,
        cur: any
      ) {
        return prev + cur['customDutyAndOtherCharges'];
      },
      0);
      ItemTaxCalculator.item['totalAmount'] = Number(totalSum);
      ItemTaxCalculator.item['total'] = Number(totalSum);
      ItemTaxCalculator.item['subTotal'] = Number(totalSum);
      ItemTaxCalculator.item['unitPrice'] = Number(totalSum);
      let sampleTax = purchaseTaxes.find((tax) => tax.percent === 0);
      ItemTaxCalculator.item['tax'] = sampleTax;
      ItemTaxCalculator.item['taxCode'] = sampleTax.code;
      ItemTaxCalculator.item['taxDetails'] = [];
      ItemTaxCalculator.item['taxName'] = sampleTax.name;
      const taxAmount = ItemTaxCalculator.calculateTaxAmount();
      ItemTaxCalculator.item.taxAmount = taxAmount;
      setCurrentRowLandedCostInfo(null);
    }
  };

  const setGSTValueManual = (rowIndex: any, productRow: any, rowItem: any) => {
    let row = productRow;
    let gstType = rowItem.gstType ? rowItem.gstType : row && row.gstType;
    if (typeof gstType === 'undefined' || gstType === null || gstType === '') {
      gstType = updateGstType(booksDocument);
    }
    if (
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST &&
      ItemTaxCalculator.item &&
      row &&
      row.userSetTaxes &&
      gstType !== GST_TYPE.EXEMPT
    ) {
      let amount = 0;
      if (gstType === GST_TYPE.INTER) {
        amount = Number(row.igstAmount);
      } else if (gstType === GST_TYPE.INTRA) {
        amount = Number(row.cgstAmount) + Number(row.sgstAmount);
      }
      let subTotal = row.subTotal;
      if (Utility.isEmpty(subTotal)) {
        subTotal = rowItem.subTotal;
      }
      if (!row.unitPriceGstInclusive) {
        let per = (Number(amount) / Number(subTotal)) * 100;
        let tax = deepClone(ItemTaxCalculator.item['tax']);
        let prevTax = ItemTaxCalculator.item['tax'];
        if (typeof tax !== 'undefined' && tax !== null) {
          tax['percent'] = per;
          tax['isTaxGroup'] = false;
        }
        ItemTaxCalculator.item['tax'] = tax;
        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
        }
        ItemTaxCalculator.item.taxAmount =
          ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item['tax'] = prevTax;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item['igstRate'] = prevTax.percent;
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Number(row.cgstAmount) + Number(row.sgstAmount);
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
          ItemTaxCalculator.item['cgstRate'] = prevTax.percent / 2;
          ItemTaxCalculator.item['sgstRate'] = prevTax.percent / 2;
        }
      } else {
        // making unitPriceGstInclusive flag false for calculating subtotal without unitPriceGstInclusive flag
        //for calculating manual user taxes and subtotal
        // ItemTaxCalculator.item.unitPriceGstInclusive = false;
        ItemTaxCalculator.setSubTotal();
        let calculatedSubTotal =
          ItemTaxCalculator.item.subTotal || row.subTotal;
        // ItemTaxCalculator.item.unitPriceGstInclusive = true;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            row.igstAmount + row.cessAmount,
            tenantInfo.decimalScale
          );
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Utility.roundingOff(
            Number(row.cgstAmount) + Number(row.sgstAmount),
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            amount + row.cessAmount,
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
        }
        if (ItemTaxCalculator && ItemTaxCalculator.item) {
          let taxAmount = ItemTaxCalculator?.item?.taxAmount || 0;
          ItemTaxCalculator.item.subTotal = Utility.roundingOff(
            calculatedSubTotal,
            tenantInfo.decimalScale
          );
        }
      }
      setCurrentRowGSTInfo(null);
    }
  };

  /// For new Tax System of SG (Product tax to new tax mapping)
  const getDefaultTax = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG || taxSystem === TAX_SYSTEM.MALAYSIA) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };

  const getUKTax = (product: any) => {
    let tax: any = null;
    let taxCode: any = null;
    if (Utility.isSalesDocument(props.booksDocument)) {
      taxCode = contact?.ukDefaultSalesTaxRate
        ? contact?.ukDefaultSalesTaxRate
        : product.salesTaxCode;
      tax = salesTaxes.find((tax) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    } else if (
      booksDocument.documentType === DOC_TYPE.BILL ||
      booksDocument.documentType === DOC_TYPE.ORDER ||
      booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      taxCode = contact?.ukDefaultPurchaseTaxRate
        ? contact?.ukDefaultPurchaseTaxRate
        : product.purchaseTaxCode;
      tax = purchaseTaxes.find((tax) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  };
  const getILTax = (product: any) => {
    let tax: any = null;
    let taxCode: any = null;
    if (Utility.isSalesDocument(props.booksDocument)) {
      taxCode = contact?.salesTaxCodeIsrael
        ? contact?.salesTaxCodeIsrael
        : product.salesTaxCode;
      tax = salesTaxes.find((tax) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    } else if (
      booksDocument.documentType === DOC_TYPE.BILL ||
      booksDocument.documentType === DOC_TYPE.ORDER ||
      booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      taxCode = contact?.purchaseTaxCodeIsrael
        ? contact?.purchaseTaxCodeIsrael
        : product.purchaseTaxCode;
      tax = purchaseTaxes.find((tax) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  };

  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.UK) {
      tax = getUKTax(product);
    }
    if (taxSystem === TAX_SYSTEM.IL) {
      tax = getILTax(product);
    } else {
      // Tax System for Malaysia(Tax not exempted) and Singapore
      let taxCode: any = null;
      if (Utility.isSalesDocument(props.booksDocument)) {
        taxCode = product.salesTaxCode;
        tax = salesTaxes.find((tax) => tax.code === taxCode);
        tax = getDefaultTax(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      } else if (
        booksDocument.documentType === DOC_TYPE.BILL ||
        booksDocument.documentType === DOC_TYPE.ORDER ||
        booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        taxCode = product.purchaseTaxCode;
        tax = purchaseTaxes.find((tax) => tax.code === taxCode);
        tax = getDefaultTax(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      }
    }
    return tax;
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy?.uomSchemaDto?.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  useEffect(() => {
    if (productToEdit) {
      setShowProductPopup(true);
    }
  }, [productToEdit]);

  const validateProduct = (product: any) => {
    const offeringType = product.offeringType;
    const productType = product.type;
    const hsnOrSacCode = product.hsnOrSacCode;
    const uqcIndia = product.uqcIndia;

    let showPopup = false;
    let message = '';

    if (
      offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
      (productType === PRODUCT_TYPE.TRACKED ||
        productType === PRODUCT_TYPE.NON_TRACKED) &&
      (Utility.isEmpty(hsnOrSacCode) || Utility.isEmpty(uqcIndia))
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code and UQC required. Please update or select another product.';
    }

    if (
      offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
      productType === PRODUCT_TYPE.NON_TRACKED &&
      Utility.isEmpty(hsnOrSacCode)
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code required. Please update or select another product.';
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && showPopup) {
      showAlert('Missing details', message, [
        {
          title: 'Cancel',
          className: 'bg-gray1 text-black-light',
          onClick: () => {
            return;
          }
        },
        {
          title: 'Update Product',
          className: 'bg-button text-white ml-2',
          onClick: () => {
            setProductToEdit(product);
            return;
          }
        }
      ]);
      return false;
    }

    return true;
  };

  // Update this for user CF
  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          const activeFilteredCFAttributes = filteredCF?.attributes?.filter(
            (attr: any) => attr?.status?.toUpperCase() === STATUS_TYPE.ACTIVE
          );
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = activeFilteredCFAttributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = activeFilteredCFAttributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const getAllCascadingDiscountKeyValuesForLineItem = (selectedRow: any) => {
    const allKeys = Object.keys(selectedRow);
    const selectedRowHasCascadingDiscounts = allKeys?.some((key: string) =>
      key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)
    );
    let updatedObj: any = {};
    if (selectedRowHasCascadingDiscounts) {
      allKeys.forEach((key: string) => {
        if (key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
          updatedObj[key] = selectedRow[key];
        }
      });
      // Handle edit case for old documents
    }
    return updatedObj;
  };

  const updateExpectedDeliveryDateForLineItem = (
    leadTime: number,
    newDocDate: Date
  ) => {
    let duedocumentDate = newDocDate || new Date();
    let leadTimeDate = addDays(duedocumentDate, leadTime);
    return leadTimeDate;
  };

  const isUpdateDateRecieveByDateRequiredOnProductChange = () => {
    let maxDateProductObject = productRows?.reduce(function (a: any, b: any) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    if (
      typeof maxDateProductObject.expectedDeliveryDt === 'undefined' ||
      maxDateProductObject.expectedDeliveryDt === null
    ) {
      maxDateProductObject.expectedDeliveryDt = documentDate;
    }
    // convert into Date object
    let dateObject = new Date(maxDateProductObject.expectedDeliveryDt);
    if (
      dateObject.getTime() === shipByDate.getTime() ||
      dateObject.getTime() > shipByDate.getTime()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const fetchProductAttachments = (
    productID: any,
    rowIndex: number,
    newProduct: any
  ) => {
    // setIsFetchingDetails(true);
    let entityId = productID;
    if (!entityId) return [];
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: DOC_TYPE.PRODUCT_DOCUMENT,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments()
      .then((attachmentList: any[]) => {
        let productAttachments = [...attachmentList];
        // setAttachments(attachmentList);
        // setNewAttachments([...newAttachments, ...attachmentList]);
        // setIsFetchingDetails(false);
        let attachmentsAfterRemovingExistingProductsAttachments = [
          ...attachments
        ];
        let newAttachmetnsAfterRemovingExistingProductsAttachments = [
          ...newAttachments
        ];

        let existingProducts = [...productRows];
        let existingProductId = existingProducts[rowIndex]?.product?.id;
        if (!productToAttachmentsMapping?.[newProduct?.id]) {
          if (Utility.isEmptyValue(existingProductId)) {
            // case: new product add
            setProductToAttachmentsMapping({
              ...productToAttachmentsMapping,
              [newProduct?.id]: productAttachments
            });
            attachmentsAfterRemovingExistingProductsAttachments = [
              ...attachmentsAfterRemovingExistingProductsAttachments,
              ...productAttachments
            ];
            newAttachmetnsAfterRemovingExistingProductsAttachments = [
              ...newAttachmetnsAfterRemovingExistingProductsAttachments,
              ...productAttachments
            ];
          } else if (!Utility.isEmptyValue(existingProductId)) {
            // case: Replace existing product
            if (productToAttachmentsMapping?.[existingProductId]) {
              let existingAttachmentsToBeRemovedIds =
                productToAttachmentsMapping?.[existingProductId]?.map(
                  (item: any) => item?.attachmentId
                );
              let isProductExistInArray = existingProducts.some(
                (item: any) => item.productCode === newProduct?.productId
              );
              if (isProductExistInArray) {
                attachmentsAfterRemovingExistingProductsAttachments = [
                  ...attachmentsAfterRemovingExistingProductsAttachments
                ]?.filter(
                  (fltItem: any) =>
                    !existingAttachmentsToBeRemovedIds?.includes(
                      fltItem?.attachmentId
                    )
                );

                newAttachmetnsAfterRemovingExistingProductsAttachments = [
                  ...newAttachmetnsAfterRemovingExistingProductsAttachments
                ]?.filter(
                  (fltItem: any) =>
                    !existingAttachmentsToBeRemovedIds?.includes(
                      fltItem?.attachmentId
                    )
                );
                let copyProductToAttachment = {
                  ...productToAttachmentsMapping
                };
                delete copyProductToAttachment[existingProductId];
                copyProductToAttachment[newProduct?.id] = productAttachments;
                setProductToAttachmentsMapping({
                  ...copyProductToAttachment
                });
              }
              attachmentsAfterRemovingExistingProductsAttachments = [
                ...attachmentsAfterRemovingExistingProductsAttachments,
                ...productAttachments
              ];
              newAttachmetnsAfterRemovingExistingProductsAttachments = [
                ...newAttachmetnsAfterRemovingExistingProductsAttachments,
                ...productAttachments
              ];
            } else {
              setProductToAttachmentsMapping({
                ...productToAttachmentsMapping,
                [newProduct?.id]: productAttachments
              });
              attachmentsAfterRemovingExistingProductsAttachments = [
                ...attachmentsAfterRemovingExistingProductsAttachments,
                ...productAttachments
              ];
              newAttachmetnsAfterRemovingExistingProductsAttachments = [
                ...newAttachmetnsAfterRemovingExistingProductsAttachments,
                ...productAttachments
              ];
              setProductToAttachmentsMapping({
                ...productToAttachmentsMapping,
                [newProduct?.id]: productAttachments
              });
            }
          }
          setAttachments(attachmentsAfterRemovingExistingProductsAttachments);
          setNewAttachments(
            newAttachmetnsAfterRemovingExistingProductsAttachments
          );
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              attachmentIds:
                newAttachmetnsAfterRemovingExistingProductsAttachments.map(
                  (attachment: any) => attachment.attachmentId
                ),
              attachments:
                newAttachmetnsAfterRemovingExistingProductsAttachments.map(
                  (attachment: any) => JSON.stringify(attachment)
                )
            };
          });
        }
      })
      .catch((err: any) => {
        console.log('Failed to fetch attachments');
        return [];
      });
  };

  const updateAttachments = (rowIndex: number, newProduct: any) => {
    fetchProductAttachments(newProduct?.id, rowIndex, newProduct);
  };

  const onProductChange = (
    rowIndex: number,
    product: any,
    isBarcode?: boolean,
    items?: DocumentItem[]
  ) => {
    // setUomsForSelectedProduct(product);
    // For forward product attachments to Bill and order attachments.
    //1. for bill different permission
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      tenantInfo?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
        ?.forwardToPurchaseBill
    ) {
      updateAttachments(rowIndex, product);
    }
    //2. for Purchase Order different permission
    if (
      props.booksDocument.documentType === DOC_TYPE.ORDER &&
      tenantInfo?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
        ?.forwardToPurchaseOrder
    ) {
      updateAttachments(rowIndex, product);
    }
    //////
    let isUpdateReceiveByDateRequired = false;
    if (
      Utility.isMRPWithURLCheck() &&
      (props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType ===
          DOC_TYPE.PURCHASE_INWARD_QUOTATION) &&
      !Utility.isEmpty(productRows)
    ) {
      isUpdateReceiveByDateRequired =
        isUpdateDateRecieveByDateRequiredOnProductChange();
    }
    if (!booksDocument.isDocumentTouched) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }

    let rows = items ? [...items] : [...productRows];
    let selectedRow: any = rows[rowIndex];
    const cascadingDiscountKeyValues =
      getAllCascadingDiscountKeyValuesForLineItem(selectedRow);
    const itemDiscountMethod = selectedRow?.itemDiscountMethod;

    if (!Utility.isEmpty(product)) {
      let updatedLineItem: DocumentItem | any = {} as any;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.COPY
      ) {
        updatedLineItem =
          !Utility.isEmpty(selectedRow?.productCode) &&
          product?.productId === selectedRow?.productCode
            ? {
                ...selectedRow,
                gstType: Utility.getValue(
                  selectedRow.gstType,
                  booksDocument.gstType
                )
              }
            : createLineItem(
                product,
                Utility.getValue(selectedRow.gstType, booksDocument.gstType),
                selectedRow.lineNumber,
                booksDocument.documentType as DOC_TYPE
              );
      } else {
        updatedLineItem = createLineItem(
          product,
          Utility.getValue(selectedRow.gstType, booksDocument.gstType),
          selectedRow.lineNumber,
          booksDocument.documentType as DOC_TYPE
        );
      }

      /** For supporting favourite quote grouping feature in CRM, ZEN-12975 */
      if (
        [DOC_TYPE.QUOTE, DOC_TYPE.INVOICE].includes(booksDocument.documentType)
      ) {
        updatedLineItem = {
          ...updatedLineItem,
          productGroupId: selectedRow.productGroupId,
          productGroupName: selectedRow.productGroupName,
          productGroupUuid: selectedRow.productGroupUuid,
          productGroupIndex: selectedRow.productGroupIndex
        };
      }

      if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
        updatedLineItem = {
          ...updatedLineItem,
          bomComponentGroupDetails: rows[rowIndex]?.bomComponentGroupDetails,
          unmodifiedBomComponentGroupDetails:
            rows[rowIndex]?.unmodifiedBomComponentGroupDetails
        };
      }

      if (
        tenantInfo &&
        tenantInfo.additionalSettings &&
        tenantInfo.additionalSettings.REV_REC
      ) {
        updatedLineItem.basePrice = product.basePrice;
        updatedLineItem.allocationType = product.allocationType;
      }

      if (rows[rowIndex]) {
        const isDefaultPriceBook =
          selectedPriceBook?.id === DEFAULT_PRICE_BOOK_ID;
        if (!Utility.isEmptyObject(selectedPriceBook) && !isDefaultPriceBook) {
          const priceDetailsFromPriceBook = getPriceFromPriceBook({
            ...product
          });
          rows[rowIndex]['unitPrice'] = priceDetailsFromPriceBook?.price;
          rows[rowIndex]['previousPrice'] = priceDetailsFromPriceBook?.price;
          rows[rowIndex]['upliftPrice'] = 0;
          rows[rowIndex]['downliftPrice'] = 0;
          rows[rowIndex]['minimumQuantity'] =
            priceDetailsFromPriceBook?.minimumQuantity || 1;
          rows[rowIndex]['discount'] = priceDetailsFromPriceBook?.discount;
          rows[rowIndex]['discountInPercent'] =
            priceDetailsFromPriceBook?.discountInPercent;
        }
      }

      updatedLineItem = {
        ...updatedLineItem,
        ...cascadingDiscountKeyValues,
        itemDiscountMethod: itemDiscountMethod,
        advancedTracking: product.advancedTracking,
        unitPriceGstInclusive: selectedRow.unitPriceGstInclusive,
        basePrice: selectedRow?.basePrice ? selectedRow?.basePrice : '',
        allocationType:
          product?.revenueRecognitionInfo &&
          product?.revenueRecognitionInfo.allocationType
            ? product?.revenueRecognitionInfo.allocationType
            : '',
        isPartialInvoice: props.booksDocument?.isPartialInvoice ?? false,
        isPartialSalesOrder: props.booksDocument?.isPartialSalesOrder ?? false,
        isPartialBill: props.booksDocument?.isPartialBill ?? false,
        availableQty: product.inventory?.availableQuantity
      };
      if (
        props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        updatedLineItem.expectedDeliveryDt =
          updateExpectedDeliveryDateForLineItem(
            product?.leadTimeDetails?.[0]?.leadTime || 0,
            documentDate
          );
      }
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (contact && product) {
          updatedLineItem = {
            ...updatedLineItem,
            isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
              props.booksDocument.documentType,
              contact.gstTreatment,
              product,
              booksDocument.applyRcmCheck
            )
          };
        }
      } else if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedLineItem = {
          ...updatedLineItem,
          isTaxable: product ? product.exemptedMalaysia : true
        };
      }

      if (
        Utility.isSalesDocument(props.booksDocument) &&
        tenantInfo.registeredToCompositionScheme
      ) {
        updatedLineItem = {
          ...updatedLineItem,
          compositionTaxPercent:
            COMPOSTION_TAX_PERCENT[tenantInfo.compositionSchemeType]
        };
      }

      let tax = getTax({ ...product });

      updatedLineItem.invalidFields = updatedLineItem.invalidFields
        ? [...updatedLineItem.invalidFields].filter(
            (field) => field !== 'unitPrice'
          )
        : [];
      if (updatedLineItem.unitPrice === 0) {
        updatedLineItem.invalidFields.push('unitPrice');
      }

      if (
        (typeof product.taxPreference !== 'undefined' &&
          product.taxPreference !== null &&
          !product.taxPreference) ||
        !updatedLineItem.isTaxable
      ) {
        updatedLineItem.nonEditableColumns = updatedLineItem.nonEditableColumns
          ? [...updatedLineItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedLineItem?.nonEditableColumns?.push('tax');
      }
      if (updatedLineItem?.isRcmApplied) {
        let copyOfNonEditableColumns =
          updatedLineItem?.nonEditableColumns || [];
        copyOfNonEditableColumns =
          copyOfNonEditableColumns?.filter(
            (field: string) => field !== 'tax'
          ) || [];
        copyOfNonEditableColumns?.push('tax');
        updatedLineItem.nonEditableColumns = copyOfNonEditableColumns;
      }
      if (
        updatedLineItem?.product?.taxable === false &&
        !updatedLineItem?.nonEditableColumns?.includes('taxAmount')
      ) {
        if (updatedLineItem.nonEditableColumns?.length) {
          updatedLineItem.nonEditableColumns.push('taxAmount');
        } else {
          updatedLineItem.nonEditableColumns = ['taxAmount'];
        }
      }
      if (updatedLineItem && updatedLineItem.nonEditableColumns) {
        updatedLineItem.nonEditableColumns = [
          ...updatedLineItem.nonEditableColumns
        ];
        updatedLineItem.nonEditableColumns.push('allocationType');
      }

      //Update UOM Values

      if (isBarcode) {
        updatedLineItem.discount = product.discountAmount;
        updatedLineItem.discountAmount = product.discountAmount;
      }

      updatedLineItem.uomQuantity = getUomQuantity(
        selectedRow.productQuantity || updatedLineItem.productQuantity || 1,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomUnitPrice = getUomPrice(
        selectedRow.unitPrice,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomAvailableQuantity = getUomQuantity(
        selectedRow.availableQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomFulfilledQuantity = getUomQuantity(
        selectedRow.fulfilledQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomQuantityFulfilled = getUomQuantity(
        selectedRow.quantityFulfilled,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomPendingQuantity = getUomQuantity(
        selectedRow.pendingQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInBills = getUomQuantity(
        selectedRow.receivedQuantityInBills,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInOrder = getUomQuantity(
        selectedRow.receivedQuantityInOrder,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.basePrice = selectedRow.basePrice
        ? selectedRow.basePrice
        : 0;
      updatedLineItem.allocationType = selectedRow?.revenueRecognitionInfo
        ?.allocationType
        ? selectedRow.revenueRecognitionInfo.allocationType
        : '';

      // updatedLineItem.uomUnitPrice = selectedRow.unitPrice;
      // updatedLineItem.uomQuantity = selectedRow.productQuantity;

      updatedLineItem = addProductCustomFieldsToLineItem(
        { ...updatedLineItem },
        product
      );

      updatedLineItem = checkIfLineLevelCustomFieldIsValid(
        updatedLineItem,
        productCustomFields
      );

      updatedLineItem = {
        ...updatedLineItem,
        unitPrice: updatedLineItem.unitPrice
          ? updatedLineItem.unitPrice * booksDocument.exchangeRate
          : 0,
        unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
        documentUom: product.stockUom,
        cessRule: product.cessRule,
        cessRuleDescription: product.cessRuleDescription,
        cessNonAdvol: product?.cessNonAdvol,
        stockUom: product.stockUom,
        reservedQuantitiesData: null,
        basePrice: product?.basePrice ? product?.basePrice : 0,
        allocationType: product?.revenueRecognitionInfo?.allocationType
          ? product?.revenueRecognitionInfo?.allocationType
          : ''
      };

      // check if amortization enabled
      if (
        props.booksDocument.documentType === DOC_TYPE.BILL &&
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.AMORTIZATION
        ) &&
        tenantInfo?.additionalSettings?.AMORTIZATION
      ) {
        if (!updatedLineItem?.product?.amortizationTemplateCode) {
          if (updatedLineItem?.nonEditableColumns) {
            updatedLineItem.nonEditableColumns = [
              ...updatedLineItem.nonEditableColumns,
              'amortizationTemplate',
              'amortizationStartDate',
              'amortizationEndDate'
            ];
          } else {
            updatedLineItem.nonEditableColumns = [
              'amortizationTemplate',
              'amortizationStartDate',
              'amortizationEndDate'
            ];
          }
        }

        if (product?.amortizationPeriod > 0) {
          let startDate = new Date(documentDate.valueOf());
          let endDate = new Date(documentDate.valueOf());
          endDate.setMonth(
            endDate.getMonth() + product?.amortizationPeriod || 1
          );

          if (product?.amortizationPeriod > 0) {
            endDate.setDate(endDate.getDate() - 1);
          }

          let amortizationItemDetails = {
            startDate: DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            endDate: DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            deferralAccountCode: product?.deferredExpenseAccountCode,
            templateCode: product?.amortizationTemplateCode
          };

          let amortizationTemplate = amortizationTemplates.find(
            (template: any) =>
              template.documentSeqCode === product?.amortizationTemplateCode
          );

          if (!Utility.isEmpty(amortizationTemplate)) {
            updatedLineItem.amortizationDocumentItemDetails = {
              ...amortizationItemDetails
            };
            updatedLineItem.amortizationTemplate = amortizationTemplate;
            updatedLineItem.amortizationStartDate = startDate;
            updatedLineItem.amortizationEndDate = endDate;
          }
        } else {
          let amortizationItemDetails = {
            startDate: null,
            endDate: null,
            deferralAccountCode: product?.deferredExpenseAccountCode,
            templateCode: product?.amortizationTemplateCode
          };
          let amortizationTemplate = amortizationTemplates.find(
            (template: any) =>
              template.documentSeqCode === product?.amortizationTemplateCode
          );

          if (!Utility.isEmpty(amortizationTemplate)) {
            updatedLineItem.amortizationDocumentItemDetails = {
              ...amortizationItemDetails
            };
            updatedLineItem.amortizationTemplate = amortizationTemplate;
            updatedLineItem.amortizationStartDate = null;
            updatedLineItem.amortizationEndDate = null;
          }
        }
      }

      if (
        (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
          props.booksDocument.documentType === DOC_TYPE.INVOICE) &&
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.REVENUE_RECOGNITION_ADV
        ) &&
        tenantInfo?.additionalSettings?.REV_REC
      ) {
        if (!product?.revenueRecognitionInfo?.revRecRule) {
          if (updatedLineItem?.nonEditableColumns) {
            updatedLineItem.nonEditableColumns = [
              ...updatedLineItem.nonEditableColumns,
              'revRecStartDate',
              'revRecEndDate'
            ];
          } else {
            updatedLineItem.nonEditableColumns = [
              'revRecStartDate',
              'revRecEndDate'
            ];
          }
        }
      }

      if (
        updatedLineItem.type === PRODUCT_TYPE.NON_TRACKED &&
        updatedLineItem?.uom?.id === UOM_NA_ID
      ) {
        if (updatedLineItem.nonEditableColumns?.length) {
          updatedLineItem.nonEditableColumns = [
            ...updatedLineItem.nonEditableColumns,
            'productQuantity'
          ];
        } else {
          updatedLineItem.nonEditableColumns = ['productQuantity'];
        }
      }

      if (
        (booksDocument.documentType === DOC_TYPE.BILL ||
          booksDocument.documentType === DOC_TYPE.FA_BILL ||
          booksDocument.documentType === DOC_TYPE.FA_ORDER ||
          booksDocument.documentType === DOC_TYPE.ORDER) &&
        !updatedLineItem?.isRcmApplied
      ) {
        if (
          booksDocument.contact?.gstTreatment === OVERSEAS &&
          booksDocument.contact?.vendorType === VENDOR_IMPORT
        ) {
          const zeroTax = purchaseTaxes.find((tax: any) => tax.percent === 0);

          if (zeroTax) {
            const zeroTax = purchaseTaxes.find((tax: any) => tax.percent === 0);

            updatedLineItem.igstRate = 0;
            updatedLineItem.igstAmount = 0;
            updatedLineItem.taxCode = zeroTax.taxCode;
            updatedLineItem.taxName = zeroTax.name;
            updatedLineItem.taxAmount = 0;
            tax = { ...zeroTax };
          }
        }
      }

      rows[rowIndex] = { ...updatedLineItem, tax };
      updateMemoOnProductChange(rows[rowIndex]);
      if (skipCalculateTaxLocal.current) {
        skipCalculateTaxLocal.current = false;
      }
      lastUpdatedIndex.current = rowIndex;
      if (
        props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION
      ) {
        if (isUpdateReceiveByDateRequired) {
          updateShipByDateForLineLevelDate(documentDate, rows);
        } else {
          updateShipByDateForLineLevelDate(shipByDate, rows);
        }
      }
      if (isBarcode) {
        return { ...updatedLineItem, tax };
      } else {
        setProductRows(rows);
        const productsPayload = [
          { productId: product.productId, uomId: product.stockUom, quantity: 1 }
        ];
        getPricing(
          productsPayload,
          rows,
          true,
          booksDocument.currency,
          false,
          booksDocument.exchangeRate
        );
        fetchProductUnitPrice(rows);
      }
    }
  };

  const updateMemoOnProductChange = (lineUpdates: any) => {
    let memoText: string = booksDocument.memo ?? '';
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      if (
        lineUpdates.tax &&
        lineUpdates.tax.defaultMemoUk &&
        lineUpdates.tax.defaultMemoUk !== ''
      ) {
        memoText = lineUpdates?.tax?.defaultMemoUk;
      }
    }
    if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
      setPreviousMemo(booksDocument.memo);
      setBooksDocument((prevState: any) => ({
        ...prevState,
        memo: memoText.trim()
      }));
    }
  };

  const updateMemoOnProductDelete = (updatedLineItems: any[]) => {
    let memoText = '';
    let checkCurrentMemo = checkMemoExist(updatedLineItems);
    let checkPreviousMemo = checkMemoExist(updatedLineItems, previousMemo);
    if (!checkCurrentMemo && checkPreviousMemo) {
      memoText = previousMemo;
    }
    if (checkCurrentMemo && !checkPreviousMemo) {
      memoText = checkCurrentMemo;
    }
    if (checkCurrentMemo && checkPreviousMemo) {
      memoText = checkCurrentMemo;
    }
    setBooksDocument((prevState: any) => ({
      ...prevState,
      memo: memoText ? memoText.trim() : ''
    }));
  };

  const getItemsTax = (items: any[]) => {
    let itemsTax: any[] = [];
    items.forEach((item: any) => {
      itemsTax.push(item?.tax);
    });
    return itemsTax;
  };

  const checkMemoExist = (items: any[], memo: string = booksDocument.memo) => {
    let itemsTax = getItemsTax(items);
    let taxMemo = null;
    if (itemsTax && itemsTax?.length > 0) {
      if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
        taxMemo = itemsTax.find(
          (t: any) => t?.defaultMemoUk === memo.trim()
        )?.defaultMemoUk;
      }
    }
    return taxMemo;
  };

  const getAmortizationTemplate = (lineItem: any) => {
    setAmortizationTmpLoading(true);
    AmortizationService.getAmortizationTemplateByCode(
      lineItem?.product?.amortizationTemplateCode
    ).then((amortizationTemplate: any) => {
      const rows = [...productRows];
      let index = rows.findIndex(
        (item: any) =>
          item.lineNumber === lineItem.lineNumber &&
          item.product?.productId === lineItem.product?.productId
      );
      if (index !== -1) {
        let startDate = new Date(documentDate.valueOf());
        let endDate = new Date(documentDate.valueOf());
        endDate.setMonth(
          endDate.getMonth() + rows[index]?.product?.amortizationPeriod || 1
        );
        if (
          lineItem?.amortizationDocumentItemDetails?.startDate &&
          lineItem?.amortizationDocumentItemDetails?.endDate
        ) {
          startDate = DateFormatService.convertDDMMYYYToDate(
            lineItem?.amortizationDocumentItemDetails.startDate
          );
          endDate = DateFormatService.convertDDMMYYYToDate(
            lineItem?.amortizationDocumentItemDetails.endDate
          );
        } else {
          let amortizationItemDetails = {
            startDate: DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            endDate: DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            deferralAccountCode: lineItem.product?.deferredExpenseAccountCode,
            templateCode: lineItem?.product?.amortizationTemplateCode
          };

          rows[index].amortizationDocumentItemDetails = {
            ...amortizationItemDetails
          };
        }

        rows[index].amortizationTemplate = amortizationTemplate;
        rows[index].amortizationStartDate = startDate;
        rows[index].amortizationEndDate = endDate;
        setProductRows([...rows]);
        setBooksDocument((prevBooksDocument: any) => {
          return {
            ...prevBooksDocument,
            items: [...rows]
          };
        });
      }
      setAmortizationTmpLoading(false);
    });
  };

  const getClassFieldForProduct = (product: any) => {
    const classField = product?.customField?.find(
      (field: any) => field.label === LOCATION_CLASS_ENUM.CLASS
    );
    return classField;
  };

  const onRowClick = ({ columnData, rowData, rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    // lastUpdatedIndex.current = rowIndex;
    setUomsForSelectedProduct(lineitem.product);
    setPricesForSelectedProduct(lineitem);

    // Set current index to know on which row user clicked, before adding the product
    setCurrentIndex(rowIndex);
    if (
      tenantInfo.country === COUNTRY_CODES.UK &&
      columnData.key === 'taxAmount'
    ) {
      setShowTaxGroupDetailsPopup(true);
    }
    updateColumnConfigOnRowClick(
      columnData,
      rowData,
      columnConfig,
      productCustomFields,
      cfUpdatedTimeMap.current
    );
  };

  const getTaxGroupDetailsPopup = () => {
    const rowIndex = currentIndex;
    let lineItem = { ...booksDocument.items?.[rowIndex] };
    const isTaxGroup = lineItem?.tax?.isTaxGroup;
    if (isTaxGroup) {
      const taxDetails =
        !Utility.isEmpty(lineItem?.taxDetails) && lineItem?.taxDetails?.length
          ? [...lineItem?.taxDetails]
          : [];
      return (
        <TaxGroupDistribution
          taxList={taxDetails}
          onCancel={() => {
            setShowTaxGroupDetailsPopup(false);
          }}
          onApply={(taxList: any[]) => {
            const totalTaxAmount = taxList?.reduce(
              (prev: number, current: any) => prev + Number(current.taxAmount),
              0
            );
            const totalAmount =
              lineItem.subTotal - lineItem.discountAmount + totalTaxAmount;

            lineItem = {
              ...lineItem,
              taxAmount: totalTaxAmount,
              totalAmount: totalAmount,
              total: totalAmount,
              taxDetails: taxList
            };

            let existingLineItems = [...booksDocument.items];
            existingLineItems[rowIndex] = lineItem;
            setBooksDocument((prevState: any) => ({
              ...prevState,
              items: [...existingLineItems]
            }));
            setShowTaxGroupDetailsPopup(false);
          }}
        />
      );
    } else {
      setShowTaxGroupDetailsPopup(false);
    }
  };

  const setPricesForSelectedProduct = (lineItem: any) => {
    if (
      lineItem &&
      !Utility.isEmpty(lineItem.product) &&
      !Utility.isEmpty(productsLastUnitPrice) &&
      productsLastUnitPrice[lineItem.product.productId]
    ) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.product.productId]
      ]);
    }
  };

  const getUomQuantity = (
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) => {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        tenantInfo.decimalScale
      );
    }
    return baseQuantity;
  };

  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };

  const updateUomSchemaForLineItem = (index: number, uomData: any) => {
    let rows: any = [...productRows];
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;
    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.product.uomSchemaDto.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
    }
    return selectedRow;
  };

  const isPriceListEnabled = () => {
    const hiddenApps = appCustomizationData?.hiddenApps?.split(',');
    return !hiddenApps?.includes('PRICE_LIST');
  };

  const onPriceBookChange = (value: any) => {
    setSelectedPriceBook(value);
    if (value?.id !== 0) {
      const effectiveDate = DateFormatService.getDateFromStr(
        value?.effectiveDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const documentDateAsDateObj = DateFormatService.getDateFromStr(
        booksDocument.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const isDateValid =
        isEqual(effectiveDate, documentDateAsDateObj) ||
        isAfter(documentDateAsDateObj, effectiveDate);
      const priceBookCurrencyInCurrencyList = activeMultiCurrencyList.find(
        (currency: any) =>
          currency.currencyStatus === STATUS_TYPE.ACTIVE &&
          currency.currencyCode === value?.currencies?.[0]
      );
      const isCurrencyValid = !Utility.isEmpty(priceBookCurrencyInCurrencyList);
      let items = [...booksDocument.items];
      items = items.map((item: any) => {
        const productQuantity = Number(item.productQuantity);
        const priceList = value.priceListItems?.filter(
          (listItem: any) =>
            item?.product?.productId === listItem.productId &&
            productQuantity >= listItem.minimumQuantity
        );
        priceList?.sort(
          (a: any, b: any) =>
            b.minimumQuantity -
            productQuantity -
            (a.minimumQuantity - productQuantity)
        );
        if (priceList?.[0] && isDateValid && isCurrencyValid) {
          item.unitPrice = priceList?.[0]?.currencyPrice?.[0]?.price;
          item.discount = priceList?.[0].discount;
          item.discountInPercent = priceList[0]?.discountInPercent;
        } else {
          const priceKeyToRead = getProductPriceKeyFromDocType(
            props.booksDocument.documentType
          );
          item.unitPrice = item?.product?.[priceKeyToRead];
          // TODO: TBD... Do we keep the discount related keys, or we reset them
        }
        return { ...item };
      });
      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
        calculateUSTax(undefined, [...items], undefined, value);
      } else {
        [...items].forEach((item: any, index: number) => {
          calculateTaxesAndAmount(index, [...items], false, value);
        });
      }
    } else {
      const productsPayload = [...booksDocument.items]
        .filter((item: any) => !Utility.isEmpty(item.product))
        .map((item: any) => ({
          productId: item.product.productId,
          uomId: item.documentUom,
          quantity: +item.productQuantity
        }));
      getPricing(
        productsPayload,
        [...booksDocument.items],
        true,
        booksDocument.currency,
        true,
        booksDocument.exchangeRate
      );
    }
  };

  const getPriceBookField = () => {
    const mainModuleType = getMainModuleName(props.booksDocument.documentType);
    let filteredPriceBook: any =
      priceBookList?.content?.filter(
        (priceBook: any) =>
          priceBook.active && priceBook.type === mainModuleType
      ) ?? [];
    filteredPriceBook = [{ id: 0, name: 'Default' }, ...filteredPriceBook];

    return (
      <div
        className="column mt-s"
        style={{ width: '150px', maxWidth: '200px' }}
      >
        <DKInput
          title="Price Book"
          titleStyle={{ color: '#808080' }}
          required={false}
          type={INPUT_TYPE.DROPDOWN}
          value={
            !Utility.isEmptyObject(selectedPriceBook) ? selectedPriceBook : ''
          }
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatter={(obj: any) => {
            return obj.name;
          }}
          onChange={(value: any) => {
            onPriceBookChange(value);
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: false,
            data: [...filteredPriceBook] || [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  const getPriceFromPriceBook = (product: any) => {
    if (selectedPriceBook?.id !== DEFAULT_PRICE_BOOK_ID) {
      const effectiveDate = DateFormatService.getDateFromStr(
        selectedPriceBook?.effectiveDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const documentDateAsDateObj = DateFormatService.getDateFromStr(
        booksDocument.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      const isDateValid =
        isEqual(effectiveDate, documentDateAsDateObj) ||
        isAfter(documentDateAsDateObj, effectiveDate);
      const priceBookCurrencyInCurrencyList = activeMultiCurrencyList.find(
        (currency: any) =>
          currency.currencyStatus === STATUS_TYPE.ACTIVE &&
          currency.currencyCode === selectedPriceBook?.currencies?.[0]
      );
      const isCurrencyValid = !Utility.isEmpty(priceBookCurrencyInCurrencyList);

      const productQuantity = Number(product.productQuantity || 1);
      const priceList = selectedPriceBook?.priceListItems?.filter(
        (listItem: any) =>
          product?.productId === listItem.productId &&
          productQuantity >= listItem.minimumQuantity
      );
      priceList?.sort(
        (a: any, b: any) =>
          b.minimumQuantity -
          productQuantity -
          (a.minimumQuantity - productQuantity)
      );
      if (priceList?.[0] && isDateValid && isCurrencyValid) {
        return {
          price: priceList[0]?.currencyPrice?.[0]?.price,
          discount: priceList?.[0]?.discount,
          discountInPercent: priceList?.[0]?.discountInPercent,
          minimumQuantity: priceList[0].minimumQuantity
        };
      }
    } else {
      const productsPayload = [...booksDocument.items]
        .filter((item: any) => !Utility.isEmpty(item.product))
        .map((item: any) => ({
          productId: item.product.productId,
          uomId: item.documentUom,
          quantity: +item.productQuantity
        }));
      getPricing(
        productsPayload,
        [...booksDocument.items],
        true,
        booksDocument.currency,
        true,
        booksDocument.exchangeRate
      );
    }
  };

  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    showPriceUpdateAlert: boolean,
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any,
    returnResponse?: boolean
  ) => {
    const isPriceListEnabledTrue = isPriceListEnabled();
    const isPriceBookEnabled = tenantInfo.isAdvancePriceListEnabled;
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      (isPriceListEnabledTrue || isPriceBookEnabled)
    ) {
      // Removed check for empty contact to resolve ZEN-10989
      const mainModuleType = getMainModuleName(
        props.booksDocument.documentType
      );
      let payload: any = {
        contactCode: contact?.code ? contact?.code : '',
        priceListPricingItems: productsPayload,
        type: mainModuleType,
        currency: currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };
      // Modify the payload if price book is enabled
      if (isPriceBookEnabled) {
        let contactCFs = contact?.customField ?? [];
        let billingAddressCountry = '';
        let shippingAddressCountry = '';
        if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.SELL) {
          billingAddressCountry = booksDocument.billTo?.country;
          shippingAddressCountry = booksDocument.shipTo?.country;
        } else if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.BUY) {
          billingAddressCountry = booksDocument.shipFrom?.country;
          shippingAddressCountry = booksDocument.shipFrom?.country;
        }
        payload = {
          ...payload,
          isAdvance: true,
          contact: contact
            ? {
                billingAddress: [{ country: billingAddressCountry }],
                shippingAddress: [{ country: shippingAddressCountry }],
                customField: contactCFs
              }
            : null
        };
      }

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );
        if (returnResponse) {
          return priceListResponse;
        }
        // check and do not show alert if no multiple prices in updated product
        let isPriceListForUpdatedProduct = true;
        if (
          changedValues?.index !== null &&
          changedValues?.index !== undefined
        ) {
          let product = lineItems[changedValues?.index];
          if (product) {
            let priceListForProduct = priceListResponse.find(
              (item: any) => item.productId === product.productCode
            );
            const priceToCompare =
              Utility.isEmptyObject(selectedPriceBook) ||
              selectedPriceBook?.id === DEFAULT_PRICE_BOOK_ID
                ? product.unitPrice
                : getPriceFromPriceBook(product);
            if (
              priceListForProduct &&
              priceListForProduct?.priceListShortInfos?.length === 0 &&
              (priceListForProduct?.price === priceToCompare ||
                priceListForProduct?.price === 0)
            ) {
              isPriceListForUpdatedProduct = false;
            }
          }
        }
        if (
          showPriceUpdateAlert &&
          isPriceListForUpdatedProduct &&
          !Utility.isEmpty(changedValues) &&
          (changedValues.contactChanged ||
            changedValues.contactAddressChanged ||
            changedValues.dateChanged ||
            changedValues.quantityChanged ||
            changedValues.uomChanged ||
            changedValues.exchangeRateChanged)
        ) {
          if (booksDocument?.isConverting && changedValues.contactChanged) {
            setRoundOffDirty(false);
          }
          let docField: any = 'invoice';
          if (props.booksDocument.documentType === DOC_TYPE.BILL) {
            docField = 'bill';
          } else if (
            props.booksDocument.documentType === DOC_TYPE.ORDER ||
            props.booksDocument.documentType === DOC_TYPE.SALES_ORDER
          ) {
            docField = 'order';
          } else if (props.booksDocument.documentType === DOC_TYPE.INVOICE) {
            docField = 'invoice';
          } else if (props.booksDocument.documentType === DOC_TYPE.QUOTE) {
            docField = localizedText('quote');
          }
          showPriceListAlert(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem && isPriceListForUpdatedProduct,
            shippingInfo,
            docField
          );
        } else {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem && isPriceListForUpdatedProduct,
            shippingInfo
          );
        }
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };

  const showPriceListAlert = (
    productsPayload: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any,
    docField: any
  ) => {
    const alertButtonConfig = [
      {
        title: "Don't update",
        className: 'bg-gray2 border-m ',
        onClick: () => {
          // reset isDateUpdatedManually && isContactChangedManually
          isDocDateUpdatedManually.current = false;
          isContactChangedManually.current = false;
          isQuantityChangedManually.current = false;
          isUOMChangedManually.current = false;
          isDocContactAddressChangedManually.current = false;
          // If exchange rate is changed manually and user clicks don't update
          // then calculate taxes again to apply exchage rate converion without price list prices
          if (isExchangeRateChangedManually.current) {
            if (getTenantTaxSystem() === TAX_SYSTEM.US) {
              calculateUSTax(undefined, lineItems);
            } else {
              lineItems.forEach((item: any, index: number) => {
                calculateTaxesAndAmount(index, lineItems);
              });
            }
            isExchangeRateChangedManually.current = false;
          }
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      }
    ];

    showAlert(
      'Update Unit price',
      `Do you want to update all unit prices to follow the Price List of the new ${docField} date?`,
      alertButtonConfig
    );
  };

  const processUpdatePricingValue = (
    productsToUpdate: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    let itemsToUpdate: any[] = [];
    productsToUpdate.forEach((productToUpdate) => {
      let itemToUpdate: any = {};
      if (updateExistingItem) {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId ===
              (item.productCode || item?.product?.productId) &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            (!itemsToUpdate.length ||
              itemsToUpdate.findIndex(
                (x) => x.lineNumber === item.lineNumber
              ) === -1)
        );
      } else {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId ===
              (item.productCode || item?.product?.productId) &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            currentIndex === item.lineNumber - 1
        );
      }

      if (!Utility.isEmpty(itemToUpdate)) {
        itemsToUpdate.push(itemToUpdate);
      }
    });

    itemsToUpdate = itemsToUpdate.map((item, index) => {
      if (
        // productsToUpdate.includes(item.product.productId) &&
        updateExistingItem ||
        (!updateExistingItem && currentIndex === item.lineNumber - 1)
      ) {
        const priceIndexInPriceList = priceListResponse.findIndex(
          (resp: any) =>
            resp.productId === (item.productCode || item?.product?.productId) &&
            resp.quantity === +item.productQuantity &&
            resp.uomId === item.documentUom &&
            !!resp?.price
        );

        const priceInPriceList =
          priceIndexInPriceList !== -1
            ? priceListResponse[priceIndexInPriceList].price
            : 0;
        const moqInPriceList =
          priceIndexInPriceList !== -1
            ? priceListResponse[priceIndexInPriceList].minimumQuantity ?? 1
            : 1;
        const isPricePresentInPriceList = priceIndexInPriceList !== -1;

        let productData = item.product ? item.product : undefined;
        let unitPrice = 0;

        const isUnitPriceValid = item.unitPrice !== undefined;

        const isDocDateOrContactOrExchangeRateUpdatedManually =
          isDocDateUpdatedManually.current ||
          isContactChangedManually.current ||
          isQuantityChangedManually.current ||
          isExchangeRateChangedManually.current;

        const isContactAddressChanged =
          tenantInfo?.isAdvancePriceListEnabled &&
          isDocContactAddressChangedManually.current;

        const isSellUtilityDocument =
          getMainModuleName(props.booksDocument.documentType) ===
          MODULE_NAME_FOR_STORAGE_UTILITY.SELL;
        const isBuyUtilityDocument =
          getMainModuleName(props.booksDocument.documentType) ===
          MODULE_NAME_FOR_STORAGE_UTILITY.BUY;

        const isSalesPriceZero =
          isSellUtilityDocument && productData.salesPrice === 0;
        const isPurchasePriceZero =
          isBuyUtilityDocument && productData.purchasePrice === 0;

        const isCurrentIndexValid =
          currentIndex >= 0 && currentIndex !== item.lineNumber - 1;

        let docPriceIndexInPriceList: number = -1;
        // Set discount and discountInPercent, if PriceBook is enabled
        if (tenantInfo?.isAdvancePriceListEnabled) {
          docPriceIndexInPriceList = priceListResponse.findIndex(
            (resp: any) =>
              resp.productId === item.productCode &&
              resp.quantity === +item.productQuantity &&
              resp.uomId === item.documentUom
          );
          if (docPriceIndexInPriceList !== -1) {
            item = {
              ...item,
              discount:
                priceListResponse?.[docPriceIndexInPriceList]?.discount ?? 0,
              discountInPercent:
                priceListResponse?.[docPriceIndexInPriceList]
                  ?.discountInPercent ?? false
            };
          }
        }

        if (isPricePresentInPriceList) {
          unitPrice = priceInPriceList;
        } else if (
          isUnitPriceValid &&
          (isDocDateOrContactOrExchangeRateUpdatedManually ||
            isContactAddressChanged ||
            isSalesPriceZero ||
            isPurchasePriceZero ||
            isCurrentIndexValid)
        ) {
          // when contact/document date changed
          // when line item is not updated (uom, qty, etc) and no price in price list
          if (
            tenantInfo.isAdvancePriceListEnabled &&
            (isDocDateUpdatedManually.current ||
              isContactChangedManually.current ||
              isQuantityChangedManually.current ||
              isDocContactAddressChangedManually.current)
          ) {
            unitPrice =
              priceListResponse?.[docPriceIndexInPriceList]?.price ?? 0;
          } else {
            unitPrice = item.unitPrice;
          }
        } else {
          unitPrice =
            (getMainModuleName(props.booksDocument.documentType) ===
            MODULE_NAME_FOR_STORAGE_UTILITY.SELL
              ? productData.salesPrice
              : productData.purchasePrice) || 0;
          unitPrice = getUomPrice(unitPrice, item.documentUOMSchemaDefinition);
        }

        if (isPricePresentInPriceList) {
          // Below line is applying exchangeRate multiple time
          // unitPrice = unitPrice ? unitPrice * exchangeRate : 0;
          // } else {
          if (unitPrice < 0) {
            unitPrice = item.unitPrice;
          }
        }

        if (
          props.documentMode === DOCUMENT_MODE.NEW &&
          (item.isPartialInvoice || item.isPartialBill) &&
          item.product.stockUom === UOM_NA_ID
        ) {
          unitPrice = item.pendingAmount; // Need to check pending amount usage
        }

        if (typeof unitPrice !== 'undefined' && unitPrice !== null) {
          item = {
            ...item,
            unitPrice: unitPrice || item.unitPrice,
            previousPrice: unitPrice,
            minimumQuantity: moqInPriceList,
            upliftPrice: unitPrice === item.unitPrice ? item.upliftPrice : 0,
            downliftPrice:
              unitPrice === item.unitPrice ? item.downliftPrice : 0,
            uomUnitPrice: unitPrice
          };
        }
      }
      return item;
    });

    itemsToUpdate.forEach((item) => {
      // Remove 'unitPrice' from invalidFields if unit price is not 0
      const unitPriceIndexInInvalidFieldsArr = item.invalidFields?.findIndex(
        (field: string) => field === 'unitPrice'
      );
      if (item.unitPrice !== 0 && unitPriceIndexInInvalidFieldsArr !== -1) {
        item.invalidFields?.splice(unitPriceIndexInInvalidFieldsArr, 1);
      }

      const index = lineItems.findIndex(
        (x) => x.lineNumber === item.lineNumber
      );
      if (index !== -1) {
        lineItems[index] = item;
      }
    });

    // Calculate taxes based on updated line items
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (updateExistingItem) {
        calculateUSTax(undefined, lineItems, shippingInfo);
      } else {
        calculateUSTax(currentIndex, lineItems, shippingInfo);
      }
    } else {
      lineItems.forEach((item: any, index: number) => {
        calculateTaxesAndAmount(index, lineItems);
      });
    }

    // reset isDateUpdatedManually && isContactChangedManually
    isDocDateUpdatedManually.current = false;
    isContactChangedManually.current = false;
    isQuantityChangedManually.current = false;
    isUOMChangedManually.current = false;
    isExchangeRateChangedManually.current = false;
    isDocContactAddressChangedManually.current = false;
  };

  const fetchProductUnitPrice = async (rows?: DocumentItem[]) => {
    const inlineProductsList = rows?.length ? [...rows] : [...productRows];
    let productCodes: string[] = [];
    inlineProductsList.forEach((item) => {
      if (!Utility.isEmpty(item.product)) {
        const code = item.product.productId || item.productCode;
        productCodes.push(code);
      }
    });
    const contactCode = Utility.isObject(contact) ? contact.code : '';
    if (
      productCodes.length > 0 &&
      props.booksDocument.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER &&
      props.booksDocument.documentType !== DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ) {
      try {
        const unitePriceData = await PriceListService.fetchLastPricesOfProduct(
          productCodes,
          props.booksDocument.documentType,
          contactCode
        );
        const unitPricesList = unitePriceData.unitPrices;
        unitPricesList.forEach((product: any) => {
          setProductsLastUnitPrice((prevState: any) => {
            return {
              ...prevState,
              [product.productCode]: product.lastUnitPricesDetails
            };
          });
        });
      } catch (err) {
        console.error('Error fetchin last 3 prices of product: ', err);
      }
    }
  };

  // Check if user entered quantity is greater than pending quantity
  const isQuantityMoreThatPendingQuantity = (
    quantity: number,
    lineItem: any
  ) => {
    let pendingQuantity = +lineItem.pendingQuantity;
    if (
      props.booksDocument?.documentType === DOC_TYPE.SALES_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.INVOICE
    ) {
      pendingQuantity = +lineItem.pendingQtyToConvert;
    }
    if (!Utility.isEmpty(lineItem.documentUOMSchemaDefinition)) {
      pendingQuantity = pendingQuantity * lineItem.uomQuantity;
    }

    return quantity > pendingQuantity;
  };

  const fetchComponentGroupDetailsAndUpdateProductChange = async (
    rowIndex: any,
    dataToUpdate: any
  ) => {
    let rows: any = [...productRows];
    let newUpdatedLineItem: any[] = [];
    if (dataToUpdate?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
      newUpdatedLineItem = await getComponentDetailsForDocumentLineItems(
        [{ ...rows[rowIndex], product: dataToUpdate }],
        contact,
        props.booksDocument.documentType
      );
    }

    try {
      let updatedLineItem: any = newUpdatedLineItem?.[0]; // due to single dropdown selection
      rows[rowIndex] = { ...updatedLineItem };
      onProductChange(rowIndex, dataToUpdate, false, rows);
    } catch (error) {
      onProductChange(rowIndex, dataToUpdate);
    }
  };

  const handleProductQuantityChangeForComponentList = async (
    rowIndex: any,
    dataToUpdate: any,
    updatingRows: any[]
  ) => {
    let rows: any = [...updatingRows];
    let newUpdatedLineItem: any[] = [];
    if (rows?.[rowIndex]?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
      newUpdatedLineItem = await getComponentDetailsForDocumentLineItems(
        [{ ...rows[rowIndex] }],
        contact,
        props.booksDocument.documentType
      );
    }

    try {
      let updatedLineItem: any = newUpdatedLineItem?.[0]; // due to single dropdown selection
      rows[rowIndex] = updatedLineItem;
      setProductRows(rows);
      setLineItemsTouched(true);
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          items: [...rows]
        };
      });
    } catch (error) {
      setProductRows(rows);
      setLineItemsTouched(true);
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    if (!Utility.isEmpty(barcodeSearchData)) {
      setBarcodeSearchData([]);
    }
    setLastUpdatedColumn(columnKey);
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    if (selectedRows) {
      selectedRows['invalidFields'] = Array.isArray(selectedRows?.invalidFields)
        ? [...selectedRows['invalidFields']].filter(
            (field) => field !== columnKey
          )
        : [];
    }

    setRoundOffDirty(false);
    if (getTenantTaxSystem() !== TAX_SYSTEM.US && rows && rows[rowIndex]) {
      rows[rowIndex]['userSetTaxes'] = false;
    }
    switch (columnKey) {
      case 'product':
        setCurrentIndex(rowIndex);
        if (!validateProduct(rowData[columnKey])) {
          if (Utility.isEmpty(rows[rowIndex]?.product)) {
            rows[rowIndex] = {};
            selectedRows?.invalidFields?.push('product');
            setProductRows(rows);
          } else {
            setProductRows(rows);
          }
          return;
        }
        let rowToUpdate = { ...rows[rowIndex] };
        if (rowToUpdate) {
          rowToUpdate = {
            [columnKey]: dataToUpdate,
            documentSequenceCode: rowData?.product?.documentSequenceCode,
            availableQty: rowData?.product?.inventory?.availableQuantity
          };
          rows[rowIndex] = rowToUpdate;
        }
        break;
      case 'productQuantity':
        if (
          !Number.isInteger(Number(dataToUpdate)) &&
          selectedRows?.product?.advancedTracking === ADVANCE_TRACKING.SERIAL
        ) {
          showAlert(
            'Error',
            `The quantity of serial products cannot be entered as a decimal number.`
          );
          dataToUpdate = 0;
          selectedRows?.invalidFields?.push('productQuantity');
        } else {
          if (!dataToUpdate || isNaN(dataToUpdate)) {
            dataToUpdate = 0;
            selectedRows?.invalidFields?.push('productQuantity');
          } else {
            dataToUpdate = Utility.roundingOff(
              dataToUpdate,
              tenantInfo.decimalScale
            );
            selectedRows.uomQuantity = Utility.roundOff(
              dataToUpdate,
              tenantInfo.decimalScale
            );
          }
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (rowData.isPartialInvoice ||
            rowData.isPartialBill ||
            rowData.isPartialSalesOrder) &&
          isQuantityMoreThatPendingQuantity(+dataToUpdate, rowData)
        ) {
          dataToUpdate = 0;
          selectedRows.invalidFields.push('productQuantity');
          showAlert(
            'Error!',
            `Quantity should not be more than pending quantity`
          );
        }
        if (
          typeof dataToUpdate !== 'undefined' &&
          dataToUpdate !== null &&
          !isNaN(dataToUpdate) &&
          (+dataToUpdate === 0 || +dataToUpdate < 0)
        ) {
          dataToUpdate = 0;
          selectedRows.invalidFields.push('productQuantity');
        }
        if (!selectedRows.invalidFields.includes('productQuantity')) {
          if (!Utility.isEmpty(selectedRows?.product)) {
            isQuantityChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +dataToUpdate
                    : item.productQuantity
              }));

            if (rows[rowIndex]) {
              rows[rowIndex][columnKey] = +dataToUpdate;
            }

            let showPriceUpdateAlert = true;
            if (
              !rows[rowIndex]['firstAmountChangeDone'] &&
              !rows[rowIndex]['id']
            ) {
              rows[rowIndex]['firstAmountChangeDone'] = true;
              showPriceUpdateAlert = false;
            }
            if (
              Utility.isEmptyObject(selectedPriceBook) ||
              selectedPriceBook?.id === DEFAULT_PRICE_BOOK_ID
            ) {
              getPricing(
                products,
                rows,
                showPriceUpdateAlert,
                booksDocument.currency,
                false,
                booksDocument.exchangeRate,
                { quantityChanged: true, index: rowIndex }
              );
            } else {
              if (rows[rowIndex]) {
                const priceDetailsFromPriceBook = getPriceFromPriceBook({
                  ...rows[rowIndex]?.['product'],
                  productQuantity: dataToUpdate
                });
                const priceKeyToRead = getProductPriceKeyFromDocType(
                  props.booksDocument.documentType
                );
                const productInLineItem = rows[rowIndex]['product'];
                const previousPrice = rows[rowIndex]['unitPrice'];
                rows[rowIndex]['unitPrice'] =
                  priceDetailsFromPriceBook?.price ||
                  productInLineItem?.[priceKeyToRead];

                const isPriceUpdated =
                  rows[rowIndex].unitPrice !== previousPrice;
                rows[rowIndex].previousPrice = isPriceUpdated
                  ? rows[rowIndex].unitPrice
                  : rows[rowIndex].previousPrice;
                rows[rowIndex].upliftPrice = isPriceUpdated
                  ? 0
                  : rows[rowIndex].upliftPrice;
                rows[rowIndex].downliftPrice = isPriceUpdated
                  ? 0
                  : rows[rowIndex].downliftPrice;
                rows[rowIndex].minimumQuantity =
                  priceDetailsFromPriceBook?.minimumQuantity || 1;
                rows[rowIndex]['discount'] =
                  priceDetailsFromPriceBook?.discount || 0;
                rows[rowIndex]['discountInPercent'] =
                  priceDetailsFromPriceBook?.discountInPercent || false;
              }
            }
            rows[rowIndex].qtyOrdered = dataToUpdate;
          }
        }
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = dataToUpdate;

          if (
            Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
            Utility.isNotEmpty(rows[rowIndex]['bomComponentGroupDetails'])
          ) {
            handleProductQuantityChangeForComponentList(
              rowIndex,
              dataToUpdate,
              rows
            );
            return;
          }

          rows[rowIndex]['reservedQuantitiesData'] = null;
          if (!Utility.isEmpty(rows[rowIndex]['tdsInfoIndia'])) {
            rows[rowIndex]['tdsInfoIndia'] = null;
          }
        }
        break;
      case 'unitPrice':
        let updatedPrice;
        if (dataToUpdate) {
          if (typeof dataToUpdate === 'object' && 'price' in dataToUpdate) {
            updatedPrice = dataToUpdate.price;
          } else {
            updatedPrice = dataToUpdate;
          }
        } else {
          updatedPrice = 0;
        }
        if (updatedPrice === '') {
          updatedPrice = undefined;
          selectedRows?.invalidFields?.push('unitPrice');
        } else {
          updatedPrice = Utility.roundingOff(
            updatedPrice,
            tenantInfo.decimalScale
          );
          selectedRows.uomUnitPrice = Utility.roundingOff(
            updatedPrice,
            tenantInfo.decimalScale
          );
        }
        if (rows[rowIndex]) {
          rows[rowIndex]['firstAmountChangeDone'] = true;
          rows[rowIndex][columnKey] = updatedPrice;
          rows[rowIndex]['upliftPrice'] = 0;
          rows[rowIndex]['downliftPrice'] = 0;
          if (!Utility.isEmpty(rows[rowIndex]['tdsInfoIndia'])) {
            rows[rowIndex]['tdsInfoIndia'] = null;
          }
        }
        if (
          props.booksDocument?.isConvertFromPOToBill &&
          props.booksDocument?.items
        ) {
          const originalProductFromProps = props.booksDocument.items.find(
            (item: any) => item.product.id === selectedRows.product.id
          );
          if (originalProductFromProps && rows[rowIndex] && selectedRows) {
            const isChange =
              selectedRows['unitPrice'] - originalProductFromProps['unitPrice'];
            if (isChange < 0) {
              rows[rowIndex]['discountLineItemCost'] =
                originalProductFromProps['unitPrice'] -
                selectedRows['unitPrice'];
              rows[rowIndex]['additionalLineItemCost'] = 0;
            } else if (isChange > 0) {
              rows[rowIndex]['additionalLineItemCost'] = isChange;
              rows[rowIndex]['discountLineItemCost'] = 0;
            } else if (isChange === 0) {
              rows[rowIndex]['additionalLineItemCost'] = 0;
              rows[rowIndex]['discountLineItemCost'] = 0;
            }
          }
        }
        break;
      case 'discount':
        ItemTaxCalculator.item = selectedRows;
        ItemTaxCalculator.setInitialValues();
        const taxAmount = ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item.taxAmount = taxAmount;
        ItemTaxCalculator.updateCalculatedValues();
        const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
        if (rows[rowIndex]) {
          rows[rowIndex].discountInPercent = false;
        }
        if (!matcher) {
          dataToUpdate = 0;
        } else if ('%' === matcher[4]) {
          const percentDiscount = Number(dataToUpdate.replace('%', ''));
          if (percentDiscount > 100) {
            dataToUpdate = 100;
          } else {
            dataToUpdate = percentDiscount;
          }
          if (rows[rowIndex]) {
            rows[rowIndex].discountInPercent = true;
          }
        } else if (
          dataToUpdate < 0 ||
          (dataToUpdate as number) >
            (ItemTaxCalculator?.item?.subTotal as number)
        ) {
          selectedRows.invalidFields.push('discount');
        }
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = dataToUpdate;
          if (!Utility.isEmpty(rows[rowIndex]['tdsInfoIndia'])) {
            rows[rowIndex]['tdsInfoIndia'] = null;
          }
        }
        break;
      case 'tax':
        // If product is non-taxable, then don't update the tax value.
        // We need to handle this in grid by making the cell non-editable.
        if (
          typeof selectedRows.product.taxPreference !== 'undefined' &&
          selectedRows.product.taxPreference !== null &&
          !selectedRows.product.taxPreference
        ) {
          dataToUpdate = null;
        }
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = dataToUpdate;
        }
        // Update memo on tax change for UK
        if (getTenantTaxSystem() === TAX_SYSTEM.UK) {
          updateMemoOnProductChange(rows[rowIndex]);
        }
        break;
      case 'taxAmount':
        const tax = selectedRows.tax;
        const updates: any = {
          taxCode: tax ? tax.code : '',
          taxName: tax ? tax.name : ''
        };
        if (!tax) {
          updates.taxAmount = 0;
        }
        rows[rowIndex] = {
          ...rows[rowIndex],
          ...updates
        };
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = dataToUpdate;
          if (Number(dataToUpdate) > 0) {
            rows[rowIndex][columnKey] = Number(dataToUpdate);
          }
        }
        break;
      case 'uom':
        rows[rowIndex] = updateUomSchemaForLineItem(rowIndex, dataToUpdate);
        if (!selectedRows.invalidFields.includes('uom')) {
          if (
            !Utility.isEmpty(selectedRows?.product) &&
            !selectedRows.invalidFields.includes('productQuantity')
          ) {
            isUOMChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? rows[rowIndex]?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +selectedRows?.productQuantity
                    : item.productQuantity
              }));
            getPricing(
              products,
              rows,
              true,
              booksDocument.currency,
              true,
              booksDocument.exchangeRate,
              { uomChanged: true, index: rowIndex }
            );
          }
        }
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = dataToUpdate;
          rows[rowIndex]['reservedQuantitiesData'] = null;
        }
        if (
          dataToUpdate?.id === UOM_NA_ID &&
          dataToUpdate.type === PRODUCT_TYPE.NON_TRACKED
        ) {
          if (rows[rowIndex] && rows[rowIndex].nonEditableColumns?.length) {
            rows[rowIndex].nonEditableColumns = [
              ...rows[rowIndex].nonEditableColumns,
              'productQuantity'
            ];
          } else {
            rows[rowIndex].nonEditableColumns = ['productQuantity'];
          }
        }
        rows[rowIndex].availableQty = updateAvailableQtyBasedOnUOM(
          rows[rowIndex],
          rows[rowIndex].availableQuantity
        );
        break;
      case 'amortizationTemplate':
        if (rows[rowIndex]) {
          rows[rowIndex].amortizationTemplate = dataToUpdate;
        }
        if (dataToUpdate?.amortizationPeriod > 0) {
          let startDate = new Date(
            (rows[rowIndex]?.amortizationStartDate || documentDate).valueOf()
          );
          let endDate = new Date(
            (rows[rowIndex]?.amortizationStartDate || documentDate).valueOf()
          );
          endDate.setMonth(
            endDate.getMonth() + dataToUpdate?.amortizationPeriod || 1
          );

          if (dataToUpdate?.amortizationPeriod > 0) {
            endDate.setDate(endDate.getDate() - 1);
          }

          let amortizationItemDetails = {
            startDate: DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            endDate: DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            deferralAccountCode:
              rows[rowIndex]?.product?.deferredExpenseAccountCode,
            templateCode: dataToUpdate?.documentSeqCode
          };

          if (rows[rowIndex]) {
            rows[rowIndex].amortizationDocumentItemDetails =
              amortizationItemDetails;
            rows[rowIndex].amortizationStartDate = startDate;
            rows[rowIndex].amortizationEndDate = endDate;
          }
        } else {
          let amortizationItemDetails = {
            startDate: null,
            endDate: null,
            deferralAccountCode:
              rows[rowIndex]?.product?.deferredExpenseAccountCode,
            templateCode: dataToUpdate?.documentSeqCode
          };

          if (rows[rowIndex]) {
            rows[rowIndex].amortizationDocumentItemDetails =
              amortizationItemDetails;
            rows[rowIndex].amortizationStartDate = null;
            rows[rowIndex].amortizationEndDate = null;
          }
        }

        break;
      case 'amortizationStartDate':
        let amortizationItemDetailsForStartDate = {};
        dataToUpdate = new Date(dataToUpdate);
        let startDateStr = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (Utility.isEmpty(rows[rowIndex]?.amortizationDocumentItemDetails)) {
          amortizationItemDetailsForStartDate = {
            startDate: startDateStr
          };
        } else {
          amortizationItemDetailsForStartDate = {
            ...rows[rowIndex]?.amortizationDocumentItemDetails,
            startDate: startDateStr
          };
        }

        const starteDateCompare = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        const endDateCompare = DateFormatService.getDateStrFromDate(
          new Date(rows[rowIndex]?.amortizationEndDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        if (
          rows[rowIndex]?.amortizationEndDate === null ||
          (rows[rowIndex] && starteDateCompare <= endDateCompare)
        ) {
          rows[rowIndex].amortizationDocumentItemDetails =
            amortizationItemDetailsForStartDate;
          rows[rowIndex][columnKey] = dataToUpdate;
        } else {
          if (!Utility.isEmpty(rows[rowIndex].amortizationTemplate)) {
            let startDate = new Date(documentDate.valueOf());
            let endDate = new Date(documentDate.valueOf());
            endDate.setMonth(
              endDate.getMonth() +
                rows[rowIndex].amortizationTemplate?.amortizationPeriod || 1
            );
            if (rows[rowIndex].amortizationTemplate?.amortizationPeriod > 0) {
              endDate.setDate(endDate.getDate() - 1);
            }
            const currEndDateStr = DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            const currStartDate = DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            if (
              Utility.isEmpty(rows[rowIndex].amortizationDocumentItemDetails)
            ) {
              amortizationItemDetailsForStartDate = {
                endDate: currEndDateStr,
                startDate: currStartDate
              };
            } else {
              amortizationItemDetailsForStartDate = {
                ...rows[rowIndex]?.amortizationDocumentItemDetails,
                endDate: currEndDateStr,
                startDate: currStartDate
              };
            }

            rows[rowIndex].amortizationDocumentItemDetails =
              amortizationItemDetailsForStartDate;

            rows[rowIndex]['amortizationEndDate'] = endDate;
            rows[rowIndex]['amortizationStartDate'] = startDate;
            showAlert(
              'Alert',
              "Amortization Start Date Cann't be  after Amortization End Date"
            );
          } else {
            showAlert(
              'Alert',
              "Cann't set the Date Amortization Template is Empty"
            );
          }
        }
        break;
      case 'amortizationEndDate':
        let amortizationItemDetailsForEndDate = {};
        dataToUpdate = new Date(dataToUpdate);
        let endDateStr = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (Utility.isEmpty(rows[rowIndex].amortizationDocumentItemDetails)) {
          amortizationItemDetailsForEndDate = {
            endDate: endDateStr
          };
        } else {
          amortizationItemDetailsForEndDate = {
            ...rows[rowIndex]?.amortizationDocumentItemDetails,
            endDate: endDateStr
          };
        }
        const startDateCompareForEndDate = DateFormatService.getDateStrFromDate(
          new Date(rows[rowIndex]?.amortizationStartDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        const endDateCompareForEndDate = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        if (
          rows[rowIndex]?.amortizationStartDate === null ||
          (rows[rowIndex] &&
            endDateCompareForEndDate >= startDateCompareForEndDate)
        ) {
          rows[rowIndex].amortizationDocumentItemDetails =
            amortizationItemDetailsForEndDate;
          rows[rowIndex][columnKey] = dataToUpdate;
        } else {
          if (!Utility.isEmpty(rows[rowIndex].amortizationTemplate)) {
            let startDate = new Date(documentDate.valueOf());
            let endDate = new Date(documentDate.valueOf());

            endDate.setMonth(
              endDate.getMonth() +
                rows[rowIndex].amortizationTemplate?.amortizationPeriod || 1
            );
            if (rows[rowIndex].amortizationTemplate?.amortizationPeriod > 0) {
              endDate.setDate(endDate.getDate() - 1);
            }
            const currEndDateStr = DateFormatService.getDateStrFromDate(
              endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            const currStartDate = DateFormatService.getDateStrFromDate(
              startDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            );

            if (
              Utility.isEmpty(rows[rowIndex].amortizationDocumentItemDetails)
            ) {
              amortizationItemDetailsForEndDate = {
                endDate: currEndDateStr,
                startDate: currStartDate
              };
            } else {
              amortizationItemDetailsForEndDate = {
                ...rows[rowIndex]?.amortizationDocumentItemDetails,
                endDate: currEndDateStr,
                startDate: currStartDate
              };
            }

            rows[rowIndex].amortizationDocumentItemDetails =
              amortizationItemDetailsForEndDate;

            rows[rowIndex]['amortizationEndDate'] = endDate;
            rows[rowIndex]['amortizationStartDate'] = startDate;

            showAlert(
              'Alert',
              "Amortization End Date Cann't be  before Amortization Start Date"
            );
          } else {
            showAlert(
              'Alert',
              "Cann't set the Date Amortization Template is Empty"
            );
          }
        }

        break;
      case 'revRecStartDate':
        dataToUpdate = new Date(dataToUpdate);
        let revRecStartDate = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (rows[rowIndex]) {
          rows[rowIndex].revRecDocumentItemDetails = {
            ...(rows[rowIndex].revRecDocumentItemDetails || {}),
            startDate: revRecStartDate
          };
          rows[rowIndex][columnKey] = dataToUpdate;
        }
        break;
      case 'revRecEndDate':
        dataToUpdate = new Date(dataToUpdate);
        let revRecEndDate = DateFormatService.getDateStrFromDate(
          dataToUpdate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        if (rows[rowIndex]) {
          rows[rowIndex].revRecDocumentItemDetails = {
            ...(rows[rowIndex].revRecDocumentItemDetails || {}),
            endDate: revRecEndDate
          };
          rows[rowIndex][columnKey] = dataToUpdate;
        }

        break;
      case 'expectedDeliveryDt':
        if (rows[rowIndex]) {
          rows[rowIndex][columnKey] = new Date(dataToUpdate);
        }
        updateShipByDateForLineLevelDate(dataToUpdate, rows);
        break;
      default:
        if (columnKey?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX)) {
          const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
          let isPercent = false;
          // rows[rowIndex].discountInPercent = false;
          if (!matcher) {
            dataToUpdate = 0;
          } else if ('%' === matcher[4]) {
            const percentDiscount = Number(dataToUpdate.replace('%', ''));
            if (percentDiscount > 100) {
              dataToUpdate = 100;
            } else {
              dataToUpdate = percentDiscount;
            }
            isPercent = true;
          } else if (
            dataToUpdate < 0 ||
            (dataToUpdate as number) >
              (ItemTaxCalculator?.item?.subTotal as number)
          ) {
            selectedRows.invalidFields.push(columnKey);
          }
          // const discountId = columnKey.substring(
          //   columnKey.lastIndexOf('_') + 1
          // );
          const discountKeyToUpdate = columnKey;
          const discountDetailsKeyToUpdate = columnKey + `_details`;
          if (rows[rowIndex]) {
            rows[rowIndex][discountKeyToUpdate] = isPercent
              ? dataToUpdate + '%'
              : dataToUpdate;
            rows[rowIndex][discountDetailsKeyToUpdate] = {
              ...rows[rowIndex][discountDetailsKeyToUpdate],
              discount: dataToUpdate,
              isPercent: isPercent,
              discountIndex:
                rows[rowIndex][discountDetailsKeyToUpdate]?.['discountIndex']
            };
          }
          break;
        } else {
          const CFColumnConfig = columnConfig?.find(
            (cf: any) => cf?.id === columnKey && cf.isCustomField
          );
          const filteredCF: any = productCustomFields?.find(
            (field: any) => field.id === columnKey
          );
          let cfValue = '';
          if (!Utility.isEmpty(filteredCF)) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.USER.toLowerCase()
            ) {
              const tempCFOption = filteredCF?.attributes?.find(
                (attr: any) => attr.code === dataToUpdate.code
              );
              if (tempCFOption) {
                cfValue = tempCFOption?.code;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue =
                dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
              cfUpdatedTimeMap.current = {
                ...cfUpdatedTimeMap.current,
                [filteredCF.id]: new Date().getTime()
              };
            } else {
              cfValue =
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
                  ? DateFormatService.getDateStrFromDate(
                      new Date(dataToUpdate),
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    )
                  : dataToUpdate;
            }
            if (CFColumnConfig && filteredCF) {
              const cfToUpdate = {
                id: filteredCF.id,
                shortName: filteredCF.shortName,
                module: filteredCF.module,
                code: filteredCF.code,
                label: filteredCF.label,
                value: cfValue
              };
              let existingCFs = rows[rowIndex]?.customField
                ? [...rows[rowIndex].customField]
                : [];
              const existingCFIndex = existingCFs.findIndex(
                (cf: any) => cf?.id === cfToUpdate?.id
              );
              if (existingCFIndex === -1) {
                existingCFs = [...existingCFs, cfToUpdate];
              } else {
                existingCFs[existingCFIndex] = cfToUpdate;
              }
              if (rows[rowIndex]) {
                rows[rowIndex].customField = existingCFs;
              }
            }
            if (rows[rowIndex]) {
              if (
                filteredCF?.fieldType?.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                rows[rowIndex][columnKey] = new Date(dataToUpdate);
              } else if (Utility.isObject(dataToUpdate)) {
                if (
                  filteredCF?.fieldType?.toLowerCase() ===
                  INPUT_TYPE.DROPDOWN.toLowerCase()
                ) {
                  rows[rowIndex][columnKey] =
                    dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
                  const { rowData } = updateRowDataWithParentCFValues(
                    dataToUpdate.value,
                    { ...rows[rowIndex] },
                    filteredCF,
                    productCustomFields
                  );
                  rows[rowIndex] = rowData;
                } else {
                  rows[rowIndex][columnKey] = dataToUpdate.value;
                }
              } else {
                rows[rowIndex][columnKey] = dataToUpdate;
              }
            }
            rows[rowIndex] = checkIfLineLevelCustomFieldIsValid(
              rows[rowIndex],
              productCustomFields
            );
            break;
          } else {
            if (rows[rowIndex]) {
              rows[rowIndex][columnKey] = Utility.isObject(dataToUpdate)
                ? dataToUpdate.value
                : dataToUpdate;

              rows[rowIndex] = checkIfLineLevelCustomFieldIsValid(
                rows[rowIndex],
                productCustomFields
              );
            }
            break;
          }
        }
    }
    // if (columnKey !== 'uom') {
    // rows[rowIndex][columnKey] = dataToUpdate;
    // }
    lastUpdatedIndex.current = rowIndex;
    if (columnKey === 'product') {
      if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
        fetchComponentGroupDetailsAndUpdateProductChange(
          rowIndex,
          dataToUpdate
        );
        return;
      }
      onProductChange(rowIndex, dataToUpdate);
    } else {
      setProductRows(rows);
      setLineItemsTouched(true);
    }

    if (
      Utility.isMRPWithURLCheck() &&
      isSalesDocumentShortfallAlertsEnabled() &&
      Utility.isSalesDocument(booksDocument) &&
      (columnKey === 'product' ||
        columnKey === 'uom' ||
        columnKey === 'productQuantity')
    ) {
      const productDetails = rows[rowIndex]?.product;

      const productType = productDetails?.type;
      const productCode = productDetails?.productId;
      if (productType === PRODUCT_TYPE.BILL_OF_MATERIALS) {
        const productWithBOMDetails =
          populateProductDetailWithSelectedOrDefaultBom(productDetails);
        updateBOMDetailsForProdQtyRenderer([productWithBOMDetails], rows)
          .then((data: any) => {
            setColumnConfig((prevState: any[]) => [...prevState]);
          })
          .catch((err: any) =>
            console.error('Error while updating bom explosion details: ', err)
          );
      }
    }
  };

  const checkAndUpdateBomShortFallDetailsForLineItems = (items: any[]) => {
    if (
      Utility.isMRPWithURLCheck() &&
      isSalesDocumentShortfallAlertsEnabled() &&
      Utility.isSalesDocument(booksDocument)
    ) {
      let allProductsWithBOMDetails: any[] = [];
      items?.forEach((item: any) => {
        const productDetails = item?.product;
        const productType = productDetails?.type;
        const productCode = productDetails?.productId;
        if (
          productType === PRODUCT_TYPE.BILL_OF_MATERIALS &&
          Utility.isEmpty(
            DocumentConfigUtility.bomProductsTotalLeadTimeMap?.[productCode]
          )
        ) {
          const productWithBOMDetails =
            populateProductDetailWithSelectedOrDefaultBom(productDetails);
          allProductsWithBOMDetails.push(productWithBOMDetails);
        }
      });
      updateBOMDetailsForProdQtyRenderer(allProductsWithBOMDetails, items)
        ?.then((res: any) => {
          setColumnConfig((prevState: any[]) => [...prevState]);
        })
        .catch((err: any) =>
          console.error('Error while updating bom explosion details: ', err)
        );
    }
  };

  const updateShipByDateForLineLevelDate = (
    dataToUpdate: any,
    productRowsArray: any[]
  ) => {
    if (Utility.isEmpty(productRowsArray) || !Utility.isMRPWithURLCheck()) {
      return;
    }
    let dateToUpdate: Date = new Date(dataToUpdate);
    let calculatedShipByDate: Date;
    let maxDateProductObject = productRowsArray?.reduce(function (
      a: any,
      b: any
    ) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    // compoare it to new
    if (
      typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
      maxDateProductObject?.expectedDeliveryDt !== null
    ) {
      calculatedShipByDate =
        maxDateProductObject?.expectedDeliveryDt > documentDate
          ? maxDateProductObject?.expectedDeliveryDt
          : documentDate;
    } else {
      calculatedShipByDate = dateToUpdate;
    }
    setTimeout(() => {
      setShipByDate(calculatedShipByDate);
    }, 300);
  };

  const removeAttachmentOnDeleteItem = (rowIndex: number) => {
    let attachmentsAfterRemovingExistingProductsAttachments = [...attachments];
    let newAttachmetnsAfterRemovingExistingProductsAttachments = [
      ...newAttachments
    ];
    let existingProducts = [...productRows];
    let existingProductId = existingProducts[rowIndex]?.product?.id;

    let existingAttachmentsToBeRemovedIds = productToAttachmentsMapping?.[
      existingProductId
    ]?.map((item: any) => item?.attachmentId);

    attachmentsAfterRemovingExistingProductsAttachments = [
      ...attachmentsAfterRemovingExistingProductsAttachments
    ]?.filter(
      (fltItem: any) =>
        !existingAttachmentsToBeRemovedIds?.includes(fltItem?.attachmentId)
    );

    newAttachmetnsAfterRemovingExistingProductsAttachments = [
      ...newAttachmetnsAfterRemovingExistingProductsAttachments
    ]?.filter(
      (fltItem: any) =>
        !existingAttachmentsToBeRemovedIds?.includes(fltItem?.attachmentId)
    );
    let copyProductToAttachment = {
      ...productToAttachmentsMapping
    };
    delete copyProductToAttachment[existingProductId];
    setProductToAttachmentsMapping({
      ...copyProductToAttachment
    });
    setAttachments(attachmentsAfterRemovingExistingProductsAttachments);
    setNewAttachments(newAttachmetnsAfterRemovingExistingProductsAttachments);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...productRows];
    if (
      (props.booksDocument.documentType === DOC_TYPE.BILL &&
        tenantInfo?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToPurchaseBill) ||
      (props.booksDocument.documentType === DOC_TYPE.ORDER &&
        tenantInfo?.additionalSettings?.FORWARD_ATTACHMENTS_SETTING
          ?.forwardToPurchaseOrder)
    ) {
      removeAttachmentOnDeleteItem(rowIndex);
    }
    rows.splice(rowIndex, 1);

    // Update existing Line Number in case of copy
    if (existingLineNumbers.includes(rowIndex + 1)) {
      let existingLineNumbersCopy = [...existingLineNumbers];
      let indexToDelete = existingLineNumbersCopy.findIndex(
        (x) => x === rowIndex + 1
      );
      if (indexToDelete !== -1) {
        existingLineNumbersCopy.splice(indexToDelete, 1);
        setExistingLineNumbers(existingLineNumbersCopy);
      }
    }
    rows = rows.map((row: DocumentItem, index: number) => {
      return {
        ...row,
        lineNumber: index + 1
      };
    });

    if (booksDocument.memo && getTenantTaxSystem() === TAX_SYSTEM.UK) {
      updateMemoOnProductDelete(rows);
    }

    lastUpdatedIndex.current = null;
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION
    ) {
      if (
        shipByDate?.getTime() ===
        productRows?.[rowIndex]?.expectedDeliveryDt?.getTime()
      ) {
        updateShipByDateForLineLevelDate(documentDate, rows);
      } else {
        updateShipByDateForLineLevelDate(shipByDate, rows);
      }
    }
    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows],
        reservedStock: rows.length ? prevBooksDocument.reservedStock : false
      };
    });
  };

  const onDeductTDS = ({ rowIndex, rowData }: any) => {
    let rows = [...productRows];
    const lineAmount = rowData.totalWithDiscount;
    const payableAccount = DEFAULT_TDS_PAYABLE_ACCOUNT_CODE;
    const assessableAmount = lineAmount;
    const natureOfPayment = rowData?.product?.tdsNatureOfPaymentIndia;
    let documentDate = booksDocument?.documentDate;

    if (!booksDocument.contact && !documentDate && !natureOfPayment) return;

    let tdsInfoData = {
      lineAmount,
      assessableAmount,
      incomePayment: rowData?.product?.tdsNatureOfPaymentIndia,
      payableAccount,
      contact: booksDocument.contact.tdsInfoIndia
        ? booksDocument.contact
        : booksDocument.contactDto,
      documentDate: DateFormatService.getFormattedDateString(
        documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      isDeductedTds: isTDSDeducted
    };
    if (
      rowData?.tdsInfoIndia &&
      tdsInfoData.lineAmount === rowData.tdsInfoIndia.lineAmount
    ) {
      tdsInfoData = {
        ...rowData.tdsInfoIndia,
        contact: booksDocument.contact.tdsInfoIndia
          ? booksDocument.contact
          : booksDocument.contactDto,
        isDeductedTds: isTDSDeducted,
        documentDate: DateFormatService.getFormattedDateString(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };
    }

    rows[rowIndex] = {
      ...rowData,
      isTdsApplicableContact:
        booksDocument?.contact?.tdsInfoIndia?.deductionApplicable,
      isTdsApplicableProduct: rowData?.product?.tdsApplicableIndia,
      isTdsApplicableAccount: false
    };
    setCurrentRowTDSInfo(tdsInfoData);

    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows]
      };
    });
    setTimeout(() => (lastUpdatedIndex.current = rowIndex), 1000);
  };

  const onOpenITCOptions = ({ rowIndex, rowData }: any) => {
    let itcValue = rowData.itcIneligibleType;
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowITCInfo({ rowIndex: rowIndex, itcValue: itcValue });
  };

  const onOpenHSN_SAC = ({ rowIndex, rowData }: any) => {
    let hsnVal = rowData.hsnOrSacCode;
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowHSNInfo({ rowIndex: rowIndex, hsnVal: hsnVal });
  };

  const onOpenTaxValue = ({ rowIndex, rowData }: any) => {
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowTaxInfo({ rowIndex: rowIndex, rowData: rowData });
  };

  const onOpenGSTValue = ({ rowIndex, rowData }: any) => {
    if (Utility.isEmpty(contact)) {
      showAlert('Warning!', 'Please select conatct to edit tax.');
    } else {
      lastUpdatedIndex.current = rowIndex;
      setCurrentRowGSTInfo({ rowIndex: rowIndex, rowData: rowData });
    }
  };

  const onOpenAllocateCost = ({ rowIndex, rowData }: any) => {
    setShowLandedCostPopup(true);
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowLandedCostInfo({ rowIndex: rowIndex });
  };

  const getProductForm = () => (
    <CreateProductView
      product={productToEdit}
      needAdvanceTrackPopupAfterSave={true} // use this prop to have the Advance TrackPopup after product creation.
      passingInteraction={(callback: CallBackPayloadType) => {
        if (callback.type === POPUP_CALLBACKS_TYPE.CLOSE_POPUP) {
          if (lastUpdatedIndex.current !== null) {
            onRowUpdate({
              columnKey: 'product',
              rowIndex: lastUpdatedIndex,
              rowData: { product: callback.data }
            });
          }
          setShowProductPopup(false);
        }
      }}
      onCancel={() => {
        setShowProductPopup(false);
        setProductToEdit(null);
      }}
      selectedTab={productToEdit ? 3 : 0}
      onSuccess={(product: any) => {
        setShowProductPopup(false);
        setProductToEdit(null);
        if (
          Utility.isSalesDocument(props.booksDocument) ||
          (product.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
            (props.booksDocument.documentType === DOC_TYPE.BILL ||
              props.booksDocument.documentType === DOC_TYPE.ORDER ||
              props.booksDocument.documentType ===
                DOC_TYPE.PURCHASE_INWARD_QUOTATION))
        ) {
          onProductChange(currentIndex, product);
        }
        setCurrentIndex(-1);
        if (
          props?.booksDocument?.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          dispatch(
            fetchProductsWithVariants({
              searchTerm: '',
              query: 'active=true,hasVariants=false,type=BILL_OF_MATERIALS',
              params: PRODUCT_TRANSACTIONTYPE_PURCHASE
            })
          );
        } else {
          dispatch(
            fetchProductsWithVariants({
              searchTerm: '',
              query: 'active=true,hasVariants=false'
            })
          );
        }
      }}
    />
  );

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const getTaxForm = () => (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
      btnList={[
        {
          title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
          class: 'border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: t(`SETTINGS.TAX.BUTTON.CREATE`),
          class: 'bg-app text-white mr-ss',
          clickAction: POPUP_CLICK_TYPE.CREATE_TAX
        }
      ]}
      width="45%"
      height="75%"
    >
      <AddTax
        populateFormData={null}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );

  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={booksDocument.currency}
        GstExchangeRate={booksDocument.gstExchangeRate}
        onClose={() => setShowTaxExchangeRatePopup(false)}
        onUpdateTaxRate={(rate: number) => {
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              gstExchangeRate: rate
            };
          });
          setShowTaxExchangeRatePopup(false);
        }}
      />
    );
  };

  // Effect to update prices based on price book,
  // when contact address is changed manually
  useEffect(() => {
    if (
      tenantInfo?.isAdvancePriceListEnabled &&
      isDocContactAddressChangedManually.current
    ) {
      if (productRows.length) {
        const productsPayload = productRows
          .filter((item) => !Utility.isEmpty(item.product))
          .map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          [...booksDocument.items],
          true,
          booksDocument.currency,
          true,
          booksDocument.exchangeRate,
          { contactAddressChanged: true },
          { shipTo: booksDocument.shipTo, shipFrom: booksDocument.shipFrom }
        );
      }
    }
  }, [
    tenantInfo,
    booksDocument.shipTo,
    booksDocument.billTo,
    booksDocument.shipFrom,
    props.booksDocument.documentType
  ]);

  // Add new item when no products found
  useEffect(() => {
    if (props.canValidate) {
      if (
        isProductGroupingEnabled &&
        props.booksDocument.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        if (!productRows.length) {
          showAlert(
            'No products found',
            'Please add at least one product to proceed'
          );
        }
      } else {
        if (!productRows.length) {
          addNewItem();
        }
      }

      if (
        props.booksDocument.documentType === DOC_TYPE.BILL &&
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (!booksDocument.shipTo?.state || !booksDocument.shipFrom?.state)
      ) {
        showAlert(
          'Missing Information!',
          'Missing "State" information in "Ship To" or "Ship From" address.'
        );
      } else if (!checkGSTINPresentForSelectedContact(booksDocument)) {
        const alertButtonConfig = [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m mr-s mt-r',
            onClick: () => {}
          },
          {
            title: 'Update',
            className: 'bg-button text-white mt-r',
            onClick: () => {
              setContactMode(DOCUMENT_MODE.EDIT);
              setShowAddContactPopup(true);
            }
          }
        ];
        showAlert(
          'GSTIN required',
          'Please add GSTIN in contact form.',
          alertButtonConfig
        );
      }
    }
  }, [props.canValidate]);

  useEffect(() => {
    if (!Utility.isEmpty(amortizationTemplates)) {
      let lineItems = !Utility.isEmpty(props.booksDocument?.items)
        ? deepClone(props.booksDocument?.items)
        : [];
      let rows = [...productRows];
      lineItems?.forEach((lineItem: any) => {
        let index = rows.findIndex(
          (item: any) =>
            item.lineNumber === lineItem.lineNumber &&
            item.product?.productId === lineItem.product?.productId
        );
        if (index !== -1) {
          let startDate = new Date(documentDate.valueOf());
          let endDate = new Date(documentDate.valueOf());
          endDate.setMonth(
            endDate.getMonth() + rows[index]?.product?.amortizationPeriod || 1
          );
          if (
            lineItem?.amortizationDocumentItemDetails?.startDate &&
            lineItem?.amortizationDocumentItemDetails?.endDate
          ) {
            startDate = DateFormatService.convertDDMMYYYToDate(
              lineItem?.amortizationDocumentItemDetails.startDate
            );
            endDate = DateFormatService.convertDDMMYYYToDate(
              lineItem?.amortizationDocumentItemDetails.endDate
            );
          } else {
            let amortizationItemDetails = {
              startDate: DateFormatService.getDateStrFromDate(
                startDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              endDate: DateFormatService.getDateStrFromDate(
                endDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              deferralAccountCode: lineItem.product?.deferredExpenseAccountCode,
              templateCode: lineItem?.product?.amortizationTemplateCode
            };

            rows[index].amortizationDocumentItemDetails = {
              ...amortizationItemDetails
            };
          }
          let amortizationTemplate = amortizationTemplates.find(
            (template: any) =>
              template.documentSeqCode ===
              lineItem?.amortizationDocumentItemDetails?.templateCode
          );
          if (!Utility.isEmpty(amortizationTemplate)) {
            rows[index].amortizationTemplate = amortizationTemplate;
            rows[index].amortizationStartDate = startDate;
            rows[index].amortizationEndDate = endDate;
          }
        }
      });
      if (!Utility.isEmpty(rows)) {
        setProductRows([...rows]);
        setBooksDocument((prevBooksDocument: any) => {
          return {
            ...prevBooksDocument,
            items: [...rows]
          };
        });
      }
    }
  }, [amortizationTemplates]);

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    let itemsCopy = [...booksDocument.items];
    let tmpProductRows = [...productRows];
    const removedItem = itemsCopy.splice(fromIndex, 1)[0];
    itemsCopy.splice(toIndex, 0, removedItem);
    itemsCopy.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });
    const tmpRemovedItem = tmpProductRows.splice(fromIndex, 1)[0];
    tmpProductRows.splice(toIndex, 0, tmpRemovedItem);
    tmpProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let newBooksItems = tmpProductRows.filter(
      (item: any) => !Utility.isEmpty(item.productCode)
    );
    setProductRows([...tmpProductRows]);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: newBooksItems
    }));
  };

  // Datagrid and columns
  const getDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needColumnIcons={false}
      needBorder={true}
      needTrailingColumn={true}
      allowBulkOperation={false}
      allowColumnSort={false}
      allowColumnShift={false}
      filterData={[]}
      allowColumnDelete={false}
      allowRowEdit={true}
      allowColumnEdit={false}
      allowFilter={false}
      allowColumnAdd={false}
      allowBottomRowAdd={false}
      allowSearch={false}
      allowShare={false}
      allowRowDrag={props.documentMode !== DOCUMENT_MODE.VIEW}
      onDragRowEnd={(fromIndex: number, toIndex: number) =>
        handleDragEnd(fromIndex, toIndex)
      }
      rows={productRows.map((rowData) => getDataRows(rowData))}
      columns={getDataGridColumns()}
      onRowUpdate={onRowUpdate}
      onRowClick={onRowClick}
      width={gridWidth}
    />
  );

  const getDataRows = (rowData: any) => {
    // hide action buttons for reserved stock conversion case
    if (
      !Utility.isDropship(props.booksDocument) &&
      checkForCustomField &&
      (!props.booksDocument?.reservedStock ||
        props.draftType === DraftTypes.DRAFT)
    ) {
      return {
        ...rowData,
        rowContextMenu:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getContextMenu(rowData),
        rowButtons:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getButtonsForRow(rowData)
      };
    } else {
      return {
        ...rowData
      };
    }
  };

  const getDataGridColumns = () => {
    let columns = [...columnConfig];
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.AMORTIZATION
      ) &&
      tenantInfo?.additionalSettings?.AMORTIZATION
    ) {
      columns.push(
        {
          id: 'amortizationTemplate',
          key: 'amortizationTemplate',
          name: 'Amortization Template',
          type: INPUT_TYPE.DROPDOWN,
          width: 150,
          systemField: true,
          editable: true,
          hidden: false,
          required: true,
          uiVisible: true,
          formatter: ({ rowData, index }: any) => {
            // if (
            //   !rowData?.amortizationTemplate &&
            //   rowData?.product?.amortizationTemplateCode &&
            //   !amortizationTmpLoading
            // ) {
            //   getAmortizationTemplate(rowData);
            // }
            return !Utility.isEmpty(rowData.amortizationTemplate)
              ? rowData.amortizationTemplate?.name
              : '';
          },
          dropdownConfig: {
            title: 'Select Amortization Template',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: {
              getUrl: (searchValue: string) =>
                ApiConstants.URL.BASE +
                ApiConstants.URL.AMORTIZATION.GET_AMORTIZATION_TEMPLATES +
                '?limit=10&page=0&query=active=true&search=' +
                searchValue,

              dataParser: (response: any) => {
                return response?.content || [];
              },
              debounceTime: 300
            },
            data: [],
            renderer: (index: any, value: any) => (
              <DKLabel text={`${value?.name}`} />
            ),
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: {
              title: 'Clear Selection',
              className: 'bg-gray2 ',
              onClick: (data: any) => {
                setIsClearAmortizationSelection(true);
              }
            }
          }
        },
        {
          id: 'amortizationStartDate',
          key: 'amortizationStartDate',
          name: 'Amortization Start Date',
          type: INPUT_TYPE.DATE,
          width: 150
        },
        {
          id: 'amortizationEndDate',
          key: 'amortizationEndDate',
          name: 'Amortization End Date',
          type: INPUT_TYPE.DATE,
          width: 150
        }
      );
    }

    // add columns to set rev rec start and end date
    if (
      (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.INVOICE) &&
      featurePermissionsInfo?.Supported?.includes(
        FEATURE_PERMISSIONS.REVENUE_RECOGNITION_ADV
      ) &&
      tenantInfo?.additionalSettings?.REV_REC
    ) {
      columns.push(
        {
          id: 'revRecStartDate',
          key: 'revRecStartDate',
          name: 'Revenue Start Date',
          type: INPUT_TYPE.DATE,
          width: 150
        },
        {
          id: 'revRecEndDate',
          key: 'revRecEndDate',
          name: 'Revenue End Date',
          type: INPUT_TYPE.DATE,
          width: 150
        }
      );
    }

    if (
      Utility.isMRPWithURLCheck() &&
      (props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION)
    ) {
      columns.push({
        id: 'expectedDeliveryDt',
        key: 'expectedDeliveryDt',
        name: 'Expected Date of Delivery',
        width: 130,
        editable: true,
        type: INPUT_TYPE.DATE
      });
    }

    if (
      !Utility.isMRPWithURLCheck() ||
      props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER ||
      (props.documentMode !== DOCUMENT_MODE.VIEW &&
        props.documentMode !== DOCUMENT_MODE.EDIT)
    ) {
      columns = columns.filter((item: any) => item.id !== 'qtyConvertedToWo');
    }

    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: booksDocument.reservedStock ? 85 : 50,
      options:
        props.documentMode === DOCUMENT_MODE.VIEW ||
        props.draftType === DraftTypes.READONLY ||
        props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType ===
          DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
        Utility.isDropship(props.booksDocument)
          ? []
          : tenantInfo?.country === TAX_SYSTEM.INDIA_GST ||
            props.booksDocument?.reservedStock
          ? []
          : [
              {
                icon: DKIcons.ic_delete,
                className: 'p-0 padding-top-2px',
                onClick: (data: any) => onDelete(data)
              }
            ]
    });

    if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
      let hideReceivedBilledQtyColumns = true;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.VIEW
      ) {
        if (
          tenantInfo?.additionalSettings?.PO_SHOW_RECEIVED_BILLED_QTY_SETTING
        ) {
          hideReceivedBilledQtyColumns = false;
        }
      }

      if (hideReceivedBilledQtyColumns) {
        const colsToExclude = [
          'receivedQuantity',
          'balanceReceivedQuantity',
          'billedQuantity',
          'balanceBilledQuantity'
        ];
        columns = columns.filter(
          (col: any) => !colsToExclude.includes(col.key)
        );
      }
    }
    if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
      const soColsToExlude = [
        'qtyOrdered',
        'qtyCommitted',
        'pickedQty',
        'packedQty',
        'shippedFulfilledQty',
        'backorderQty',
        'availableQty',
        'invoicedQty'
      ];
      if (tenantInfo?.additionalSettings?.TRACKING_DETAILS_IN_SO) {
        if (
          props.documentMode !== DOCUMENT_MODE.EDIT &&
          props.documentMode !== DOCUMENT_MODE.VIEW
        ) {
          columns = columns.filter((col) => !soColsToExlude.includes(col.key));
        }
      } else {
        columns = columns.filter((col) => !soColsToExlude.includes(col.key));
      }
    }
    if (
      props.booksDocument.documentType === DOC_TYPE.QUOTE ||
      props.booksDocument.documentType === DOC_TYPE.INVOICE
    ) {
      if (!tenantInfo?.additionalSettings?.TRACKING_DETAILS_IN_SO) {
        const colsToExlude = ['availableQuantity'];
        columns = columns.filter((col) => !colsToExlude.includes(col.key));
      }
    }
    const sgPeppolEnabledModule = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER
    ];
    const isPeppolModule = sgPeppolEnabledModule.includes(
      props?.booksDocument?.documentType
    );
    const itemsIncludeLineRefNo = props?.booksDocument?.items?.filter((item) =>
      Utility.isNotEmpty(item.lineRefNo)
    );
    const isPeppolEnaled = isSGAndPeppolOptInEnabled();
    const peppolColumns = ['lineRefNo'];
    if (!isPeppolModule && !isPeppolEnaled) {
      columns = columns.filter((col) => !peppolColumns.includes(col.key));
    } else {
      if (itemsIncludeLineRefNo && itemsIncludeLineRefNo?.length <= 0) {
        columns = columns.filter((col) => !peppolColumns.includes(col.key));
      }
    }
    return columns;
  };
  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = () => {
    let moduleType = '';
    const docCopy = { ...props.booksDocument } as any;
    if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.INVOICE
    ) {
      if (docCopy?.sourceDocTypeForConversion === DOC_TYPE.SALES_ORDER) {
        moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.SALES_ORDER];
      } else if (docCopy?.sourceDocTypeForConversion === DOC_TYPE.QUOTE) {
        moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
      }
    } else if (
      docCopy.isPartialSalesOrder &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.SALES_ORDER
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.BILL
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.ORDER];
    } else {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    }

    const entityId = props.booksDocument.id || props.booksDocument.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
      if (
        props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW
      ) {
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: attachmentList.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: attachmentList.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
        if (
          (docCopy.isPartialInvoice && docCopy.isConverting) ||
          (docCopy.isPartialSalesOrder && docCopy.isConverting) ||
          props.documentMode === DOCUMENT_MODE.COPY
        ) {
          const newlyAddedAttachments = [...attachmentList];
          setNewAttachments(newlyAddedAttachments);
        }
      }
    });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType =
      DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    const entityId = booksDocument.id || booksDocument.entityId || '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId:
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType ===
          DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.QUOTE
          ? entityId
          : ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch((err) => {
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r gap-2">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            {props.draftType !== DraftTypes.READONLY && (
              <DKIcon
                src={DKIcons.ic_delete}
                className={`ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60 ${
                  props.draftType === DraftTypes.READONLY
                    ? 'pointer-events-none'
                    : ''
                }`}
                onClick={() => removeAttachment(attachment.attachmentId)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  ////////////////////////////////////////////////////
  ////////// US CUSTOM TAX FUNCTIONALITIES ///////////
  ////////////////////////////////////////////////////
  // This is triggered when contact is set/changed
  const calculateUSTaxOnContactChange = (tempDocument: Document) => {
    const tempProductRows = [...(tempDocument.items as DocumentItem[])];
    if (contact && tempProductRows.length > 0) {
      ItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: tempDocument.shipTo,
        shipFrom: tempDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (ignoreCalculateUSTax === true) {
        const nonUsResident =
          payload.shipTo &&
          payload.shipTo.country !== COUNTRY_CODES.US &&
          payload.shipTo &&
          payload.shipTo.country !== 'United States of America' &&
          payload.shipTo.country !== 'United States'
            ? true
            : false;
        enableTaxColumForUS(nonUsResident);
        setIgnoreCalculateUSTax(false);
        return;
      }

      if (contact.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      [...tempProductRows].forEach((row: any, index: number) => {
        ItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        ItemTaxCalculator.setInitialValues();
        payload.lines.push({
          amount: ItemTaxCalculator.item.totalWithDiscount,
          description: ItemTaxCalculator.item.productDescription,
          quantity: ItemTaxCalculator.item.productQuantity,
          taxAmount: row.product?.taxable ? null : 0,
          taxCode: ItemTaxCalculator.item.product?.categoryCode
        });
        ItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...tempProductRows];
            taxes.lines.forEach((taxLine: any, index: number) => {
              ItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              ItemTaxCalculator.setInitialValues();
              ItemTaxCalculator.item.taxAmount = null as any;
              ItemTaxCalculator.item.taxAmount = taxLine.tax;
              ItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = ItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                items: [...prodRows]
              };
            });
          },
          (err) => {
            console.error(
              'Error calculating tax on contact change: ',
              err.data
            );
            if (
              !usTaxCalculateAlertVisible.current &&
              calculateUSTaxAPIErrorMessages.some((msg: string) =>
                err.data?.errorMessage?.includes(msg)
              )
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              undefined,
              tempProductRows,
              payload.shipTo
            );
          }
        );
      }
    }
  };

  const calculateUSTax = (
    updatedItemIndex: any,
    items?: DocumentItem[],
    shippingInfo?: any,
    priceBook?: any
  ) => {
    let lineItems = items?.length ? items : [...productRows];

    if (lineItems.length > 0) {
      ItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact?.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: shippingInfo ? shippingInfo.shipTo : booksDocument.shipTo,
        shipFrom: shippingInfo ? shippingInfo.shipFrom : booksDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (contact?.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      lineItems = lineItems?.length
        ? lineItems?.map((item: any) => {
            let expectedDeliveryDateObject = item.expectedDeliveryDt;
            let itemCopy = deepClone(item);
            // Date object should not be converted in String
            itemCopy.expectedDeliveryDt = expectedDeliveryDateObject;
            ItemTaxCalculator.item = itemCopy;
            ItemTaxCalculator.setInitialValues();
            itemCopy = { ...ItemTaxCalculator.item };
            return itemCopy;
          })
        : [];

      if (
        typeof additionalCharges.current?.additionalChargesDetails !==
          'undefined' &&
        additionalCharges.current?.additionalChargesDetails !== null
      ) {
        let remainingCharges =
          additionalCharges.current?.additionalChargesDetails?.map(
            (item: any) => item.additionalCharge
          );

        lineItems = lineItems.map((item: any) => {
          let itemCopy = { ...item };
          let additionalChargesDetails =
            itemCopy?.additionalCharges?.additionalChargesDetails ?? [];
          additionalChargesDetails = additionalChargesDetails.filter(
            (charge: any) => remainingCharges.includes(charge.additionalCharge)
          );
          return {
            ...itemCopy,
            additionalCharges: {
              additionalChargesDetails
            }
          };
        });

        additionalCharges.current?.additionalChargesDetails?.forEach(
          (charge: any) => {
            lineItems = updateLineLevelAdditionalChargeAllocation(
              charge,
              lineItems
            );
          }
        );
      }

      lineItems.forEach((row: any, index: number) => {
        ItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        ItemTaxCalculator.setInitialValues();
        let taxAmount: any = ItemTaxCalculator.calculateTaxAmount();
        if (updatedItemIndex === undefined || updatedItemIndex === index) {
          taxAmount = row?.product?.taxable ? null : 0;
        }

        if (row.taxAmount !== undefined && row.taxAmount !== null) {
          if (row.taxAmount !== taxAmount) {
            if (row.taxAmount > 0 && !row.product.taxable) {
              taxAmount = row.taxAmount;
            }
          }
        }
        payload.lines.push({
          amount: ItemTaxCalculator.item.totalWithDiscount,
          description: ItemTaxCalculator.item.productDescription,
          quantity: ItemTaxCalculator.item.productQuantity,
          taxAmount: taxAmount,
          taxCode: ItemTaxCalculator.item.product?.categoryCode
        });
        ItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...lineItems];
            taxes.lines.forEach((taxLine: any, index: number) => {
              ItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              ItemTaxCalculator.setInitialValues();
              ItemTaxCalculator.item.taxAmount = null as any;
              ItemTaxCalculator.item.taxAmount = taxLine.tax;
              ItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = ItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                priceListId: priceBook?.id ?? prevBooksDocument?.priceListId,
                priceListName:
                  priceBook?.name ?? prevBooksDocument?.priceListName,
                items: [...prodRows]
              };
            });
          },
          (err) => {
            console.error('Error calculating tax: ', err.data);
            if (
              !usTaxCalculateAlertVisible.current &&
              calculateUSTaxAPIErrorMessages.some((msg: string) =>
                err.data?.errorMessage?.includes(msg)
              )
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              updatedItemIndex,
              lineItems,
              payload.shipTo,
              priceBook
            );
          }
        );
      } else {
        handleUSLineItemsWhenTaxIsOff(
          updatedItemIndex,
          lineItems,
          payload.shipTo,
          priceBook
        );
      }
    }
  };

  const handleUSLineItemsWhenTaxIsOff = (
    updatedItemIndex: any,
    lineItems: DocumentItem[],
    shipTo: any,
    priceBook?: any
  ) => {
    let prodRows: any[] = [...lineItems];
    lineItems.forEach((row: any, index: number) => {
      ItemTaxCalculator.item = {
        ...row,
        ...getDiscountRelatedKeys(
          row.discountInPercent ? row.discount + '%' : row.discount
        )
      };
      ItemTaxCalculator.setInitialValues();
      let taxAmount = ItemTaxCalculator.calculateTaxAmount();
      if (updatedItemIndex === undefined || updatedItemIndex === index) {
        taxAmount = (row.product.taxable ? null : 0) as number;
      }
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      prodRows[index] = ItemTaxCalculator.item;
    });
    if (shipTo !== undefined && shipTo !== null) {
      setBooksDocument((prevState: any) => ({
        ...prevState,
        shipTo: shipTo,
        priceListId: priceBook?.id ?? prevState?.priceListId,
        priceListName: priceBook?.name ?? prevState?.priceListName,
        items: [...prodRows]
      }));
    } else {
      setBooksDocument((prevState: any) => ({
        ...prevState,
        priceListId: priceBook?.id ?? prevState?.priceListId,
        priceListName: priceBook?.name ?? prevState?.priceListName,
        items: [...prodRows]
      }));
    }
    setProductRows([...prodRows]);
  };

  const showUSInvalidZipCodeAlert = (message: string) => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m mr-s',
        onClick: () => {
          usTaxCalculateAlertVisible.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white',
        onClick: () => {
          setContactMode(DOCUMENT_MODE.EDIT);
          setShowAddContactPopup(true);
          usTaxCalculateAlertVisible.current = false;
        }
      }
    ];
    showAlert('Error', message, alertButtonConfig);
  };

  const enableTaxColumForUS = (flag: boolean) => {
    let config = columnConfig;
    config = config.map((column: any) => {
      if (column.key === 'taxAmount') {
        column.editable = flag;
      }
    });
    setColumnConfig(columnConfig);
  };

  const showTaxField = () =>
    getTenantTaxSystem() === TAX_SYSTEM.US &&
    tenantInfo.complianceEnabled &&
    !(
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    );

  const getButtonsForRow = (row: any) => {
    let buttons: any[] = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      booksDocument.reservedStock &&
      row.product &&
      (row.product.type === PRODUCT_TYPE.TRACKED ||
        row.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS)
    ) {
      buttons.push({
        title: '',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: 'p-v-0 inventory-icon-document',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    return buttons;
  };

  const AddCopyofRow = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...productRows];
    let copiedRow: any = { ...tempProductRows[rowIndex] };
    copiedRow.id = null;

    if (!Utility.isEmpty(copiedRow.reservedQuantitiesData)) {
      copiedRow.reservedQuantitiesData = null;
    }

    tempProductRows.splice(rowIndex + 1, 0, copiedRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });
    setProductRows(tempProductRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: tempProductRows,
      isDocumentTouched: true
    }));
    setLineItemsTouched(true);
  };

  const InsertNewItem = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...productRows];
    let newRow = addNewItem(true);
    tempProductRows.splice(rowIndex + 1, 0, newRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });
    setProductRows(tempProductRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: tempProductRows,
      isDocumentTouched: true
    }));
    setLineItemsTouched(true);
  };

  const getContextMenu = (row: any) => {
    let contextMenu = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      // props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
      booksDocument.reservedStock &&
      row.product &&
      (row.product.type === PRODUCT_TYPE.TRACKED ||
        row.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS)
    ) {
      contextMenu.push({
        title: row.reservedQuantitiesData
          ? 'Update reserved stock'
          : 'Add reserved stock',
        icon: row.reservedQuantitiesData ? ic_barcode_green : ic_barcode_red,
        className: ' p-0',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    if (tenantInfo.country === TAX_SYSTEM.INDIA_GST) {
      contextMenu.push({
        title: 'HSN/SAC',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenHSN_SAC(data)
      });
    }
    let contactObj = contact || props.booksDocument.contact;
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      contactObj?.tdsInfoIndia?.deductionApplicable &&
      row?.product?.tdsApplicableIndia
    ) {
      contextMenu.push({
        title: 'Deduct TDS',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any) => onDeductTDS(data)
      });
    }
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      !Utility.isEmpty(row?.product) &&
      (props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType ===
          DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER)
    ) {
      contextMenu.push({
        title: 'ITC',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenITCOptions(data)
      });
    }
    if (
      tenantInfo?.landedCostSettings?.landedCost &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      (props.booksDocument.landedCost || booksDocument.landedCost)
    ) {
      contextMenu.push({
        title: 'Allocate Cost',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenAllocateCost(data)
      });
    }
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && row?.gstType !== 3) {
      contextMenu.push({
        title: 'Tax',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenGSTValue(data)
      });
    } else if (
      getTenantTaxSystem() !== TAX_SYSTEM.INDIA_GST &&
      getTenantTaxSystem() !== TAX_SYSTEM.US &&
      getTenantTaxSystem() !== TAX_SYSTEM.UK
    ) {
      contextMenu.push({
        title: 'Tax',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenTaxValue(data)
      });
    }
    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenProductCFSettings(true)
    });

    if (
      !(
        props.booksDocument.isPartialInvoice ||
        props.booksDocument.isPartialBill ||
        props.booksDocument.isPartialSalesOrder ||
        Utility.isDropship(props.booksDocument) ||
        props.documentMode === DOCUMENT_MODE.VIEW ||
        (props?.booksDocument?.documentType === DOC_TYPE.ORDER &&
          props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
            'PURCHASE_REQUEST')
      )
    ) {
      contextMenu.push({
        title: 'Insert',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any, index: any) => InsertNewItem(data)
      });

      contextMenu.push({
        title: 'Copy',
        icon: DKIcons.ic_copy,
        className: ' p-0',
        onClick: (data: any, index: any) => AddCopyofRow(data)
      });
    }

    if (
      props?.booksDocument?.linkedDocuments?.[0]?.documentType !==
      'PURCHASE_REQUEST'
    ) {
      contextMenu.push({
        title: 'Delete',
        icon: DKIcons.ic_delete,
        className: ' p-0',
        onClick: (data: any) => onDelete(data)
      });
    }
    return contextMenu;
  };
  const onTDSDeduct = (tdsData: any) => {
    let rows = [...productRows];
    setCurrentRowTDSInfo(null);
    rows[lastUpdatedIndex.current].tdsInfoIndia = tdsData;
    setProductRows(rows);
  };
  const onITCOptionSelected = (itcData: any) => {
    let rows = [...productRows];
    setShowITCPopup(false);
    setCurrentRowITCInfo(null);
    rows[itcData.rowIndex].itcIneligibleType = itcData.itcValue;
    setProductRows(rows);
  };

  const onHSNChange = (hsnData: any) => {
    let rows = [...productRows];
    setShowHSNPopup(false);
    setCurrentRowHSNInfo(null);
    rows[hsnData.rowIndex].hsnOrSacCode = hsnData.hsnValue;
    setProductRows(rows);
  };

  const onGSTValueChange = (gstData: any) => {
    let rows = [...productRows];
    setShowGSTPopup(false);
    rows[gstData.rowIndex].igstAmount = Number(gstData.igstValue);
    rows[gstData.rowIndex].sgstAmount = Number(gstData.sgstValue);
    rows[gstData.rowIndex].cgstAmount = Number(gstData.cgstValue);
    rows[gstData.rowIndex].cessAmount = Number(gstData.cessValue);
    rows[gstData.rowIndex].userSetTaxes = true;
    setProductRows(rows);
  };

  const onTaxValueChange = (taxData: any) => {
    let rows = [...productRows];
    setShowTaxRowPopup(false);
    let currentRow = rows[taxData.rowIndex];
    currentRow.userSetTaxes = true;
    if (taxData?.isTaxGroup) {
      currentRow.taxDetails = currentRow?.taxDetails?.map(
        (taxDetailsRow: any) => {
          let tempTaxDetailsRow = { ...taxDetailsRow };
          const taxAmount =
            taxData?.taxDetailGroup?.find(
              (taxRow: any) => taxRow.taxId === tempTaxDetailsRow.taxId
            )?.taxAmount || 0;
          tempTaxDetailsRow.taxAmount =
            Utility.roundOffToTenantDecimalScale(taxAmount);

          return tempTaxDetailsRow;
        }
      );
    }
    currentRow.taxAmount = Utility.roundOffToTenantDecimalScale(
      Number(taxData.taxAmount)
    );
    setProductRows(rows);
  };

  const onLandedCostDataUpdate = (data: any) => {
    let rows = [...productRows];
    let landedCostCategory = JSON.parse(data?.formData?.landedCostCategory);
    rows[data.rowIndex]['landedCostDetails'] = data.formData;
    setProductRows(rows);
    setShowLandedCostPopup(false);
    setBooksDocument({
      ...booksDocument,
      landedCost: true
    });

    if (props.documentMode === DOCUMENT_MODE.NEW) {
      let updatedState = booksDocument;
      updatedState['linkedDocuments'] = [];
      props?.booksDocument?.landedProducts.forEach((element: any) => {
        updatedState.linkedDocuments.push({
          documentSequenceCode: element.documentSequenceCode,
          documentCode: element.purchaseInvoiceCode,
          documentType: DOC_TYPE.BILL
        });
      });
      setBooksDocument({
        ...updatedState,
        landedCost: true
      });
    }
  };

  const updateLineLevelAdditionalChargeAllocation = (
    selectedCharge: any,
    items: any
  ) => {
    let itemsCopy = items?.length
      ? [...items].map((item: any) => {
          let expectedDeliveryDateObject = item.expectedDeliveryDt;
          let itemCopy = deepClone(item);
          // Date object should not be converted in String
          itemCopy.expectedDeliveryDt = expectedDeliveryDateObject;
          return itemCopy;
        })
      : [];

    let existingChargeIndexInTransaction =
      additionalCharges.current?.additionalChargesDetails?.findIndex(
        (charge: any) =>
          charge.additionalCharge === selectedCharge.additionalCharge
      );
    let totalTempChargeAmount = 0;

    if (existingChargeIndexInTransaction > -1) {
      switch (selectedCharge?.apportionValue) {
        case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_QTY:
          let totalProductQty = 0;
          let perQtyItemCharge = 0;
          totalProductQty = itemsCopy
            ?.filter(
              (item: any) =>
                item?.product?.type === PRODUCT_TYPE.TRACKED ||
                item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
            )
            ?.reduce((total: number, array: any) => {
              return total + array.productQuantity;
            }, 0);

          perQtyItemCharge = selectedCharge?.chargeAmount / totalProductQty;

          totalTempChargeAmount = 0;
          itemsCopy = itemsCopy?.map((item: any, index: number) => {
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndexInLineItem =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];

            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              item?.productQuantity * perQtyItemCharge
            );
            totalTempChargeAmount += chargeAmount;
            if (index === itemsCopy?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
                const chargeDelta =
                  +selectedCharge.chargeAmount - totalTempChargeAmount;
                chargeAmount += chargeDelta;
              }
            }

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndexInLineItem !== -1) {
              existingChargeDetails[existingChargeIndexInLineItem] =
                tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });

          break;
        case ADDITIONAL_CHARGE_METHODS.APPORTION_ON_VALUE:
          let totalSumAllAmount = 0;
          totalSumAllAmount = itemsCopy
            ?.filter(
              (item: any) =>
                item?.product?.type === PRODUCT_TYPE.TRACKED ||
                item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
            )
            ?.reduce((total: number, obj: any) => {
              return total + +obj.totalAmount;
            }, 0);

          totalTempChargeAmount = 0;
          itemsCopy = itemsCopy?.map((item: any, index: number) => {
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];

            let chargeAmount = Utility.roundOffToTenantDecimalScale(
              (item?.totalAmount / totalSumAllAmount) *
                selectedCharge?.chargeAmount
            );
            totalTempChargeAmount += chargeAmount;
            if (index === itemsCopy?.length - 1) {
              if (totalTempChargeAmount !== +selectedCharge?.chargeAmount) {
                const chargeDelta =
                  +selectedCharge.chargeAmount - totalTempChargeAmount;
                chargeAmount = Utility.roundOffToTenantDecimalScale(
                  chargeAmount + chargeDelta
                );
              }
            }

            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: chargeAmount,
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });
          break;
        case ADDITIONAL_CHARGE_METHODS.APPORTION_MANUAL:
          itemsCopy = itemsCopy?.map((item: any) => {
            if (
              item?.product?.type !== PRODUCT_TYPE.TRACKED &&
              item?.product?.type !== PRODUCT_TYPE.BILL_OF_MATERIALS
            ) {
              return {
                ...item,
                additionalCharges: {
                  additionalChargesDetails: []
                }
              };
            }

            let copyAdditionalChargeDetails: any = item?.additionalCharges
              ? item?.additionalCharges
              : { additionalChargesDetails: [] };
            let existingChargeIndex =
              copyAdditionalChargeDetails?.additionalChargesDetails?.findIndex(
                (charge: any) =>
                  charge.additionalCharge === selectedCharge.additionalCharge
              );
            let existingChargeDetails = [
              ...copyAdditionalChargeDetails.additionalChargesDetails
            ];
            let tempDetails = {
              id: selectedCharge?.id,
              additionalCharge: selectedCharge?.additionalCharge,
              additionalChargeAccountCode:
                selectedCharge?.additionalChargeAccountCode,
              addtionalChargeTax: selectedCharge?.addtionalChargeTax ?? null,
              addtionalChargeTaxCode:
                selectedCharge?.addtionalChargeTaxCode ?? null,
              isPreCharge: selectedCharge.isPreCharge,
              isPercent: selectedCharge?.isPercent,
              percent: selectedCharge?.percent,
              chargeAmount: calculateManualAdditionalChargeDistribution(
                existingChargeDetails,
                existingChargeIndex,
                selectedCharge,
                itemsCopy?.filter(
                  (item: any) =>
                    item?.product?.type === PRODUCT_TYPE.TRACKED ||
                    item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
                ).length
              ),
              apportionFlag: selectedCharge?.apportionFlag || true,
              apportionValue: selectedCharge?.apportionValue || null
            };

            if (existingChargeIndex !== -1) {
              existingChargeDetails[existingChargeIndex] = tempDetails;
            } else {
              existingChargeDetails.push(tempDetails);
            }

            item = {
              ...item,
              additionalCharges: {
                additionalChargesDetails: existingChargeDetails
              }
            };

            return item;
          });
          break;
      }
    } else {
      itemsCopy = itemsCopy?.map((item: any) => {
        let existingChargeDetails =
          item?.additionalCharges?.additionalChargesDetails?.filter(
            (charge: any) =>
              charge.additionalCharge !== selectedCharge.additionalCharge
          );

        item = {
          ...item,
          additionalCharges: {
            additionalChargesDetails: existingChargeDetails
          }
        };
        return item;
      });
    }

    return itemsCopy;
  };

  const calculateManualAdditionalChargeDistribution = (
    existingChargeDetails: any[],
    existingChargeIndex: number,
    selectedCharge: any,
    itemsLength: number
  ) => {
    if (existingChargeIndex > -1) {
      return existingChargeDetails[existingChargeIndex].chargeAmount
        ? existingChargeDetails[existingChargeIndex].chargeAmount
        : 0;
    } else {
      let manualApportionChargesInItems: any[] = [];

      booksDocument.items.forEach((item: any) => {
        const additionalCharge =
          item.additionalCharges?.additionalChargesDetails?.find(
            (c: any) => c.additionalCharge === selectedCharge.additionalCharge
          );
        if (!Utility.isEmpty(additionalCharge)) {
          manualApportionChargesInItems.push(additionalCharge);
        }
      });
      if (manualApportionChargesInItems.length) {
        const manualApportionChargesTotal =
          manualApportionChargesInItems.reduce(
            (total: number, detail: any) =>
              total + Number(detail?.chargeAmount || 0),
            0
          );
        if (manualApportionChargesTotal !== selectedCharge.chargeAmount) {
          return Utility.roundOffToTenantDecimalScale(
            selectedCharge?.chargeAmount / itemsLength
          );
        } else {
          return 0;
        }
      } else {
        return Utility.roundOffToTenantDecimalScale(
          selectedCharge?.chargeAmount / itemsLength
        );
      }
    }
  };

  function reanderPEOPPLPaymentTerm() {
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    const sgSupportedDocuments = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.ORDER
    ];
    if (
      isSGPeppol &&
      sgSupportedDocuments.includes(props.booksDocument.documentType)
    ) {
      return (
        <div
          style={{
            width: 150,
            maxWidth: 200,
            wordBreak: 'break-word'
          }}
        >
          <DKInput
            className="parent-width"
            title={'Payment Term'}
            value={booksDocument?.paymentTerm}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.TEXT}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={true}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }
  function renderPeppolPaymentTermRightSide() {
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    const sgSupportedDocuments = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.ORDER
    ];
    if (
      isSGPeppol &&
      sgSupportedDocuments.includes(props.booksDocument.documentType)
    ) {
      return (
        !isDocumentInFullScreen && (
          <div className="position-relative walkthrough-step-4 p-v-xs">
            <div
              className={`row width-auto mb-xs justify-content-between`}
              style={{
                width: isDocumentInFullScreen ? 'auto' : 240
              }}
            >
              <div className="row width-auto">
                <DKIcon
                  src={DKIcons.data_type.ic_number}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel
                  text={'Payment Term'}
                  className={'fw-m ml-r'}
                  style={{ marginRight: isDocumentInFullScreen ? 20 : 0 }}
                />
              </div>
              <DKLabel text={booksDocument?.paymentTerm} className={'ml-r '} />
            </div>
          </div>
        )
      );
    } else {
      return <></>;
    }
  }
  function renderOrderReferenceNumber() {
    let sgSupportedDocuments = [
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.INVOICE,
      DOC_TYPE.BILL
    ];
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    if (
      isSGPeppol &&
      sgSupportedDocuments.includes(props.booksDocument.documentType)
    ) {
      return (
        <div className={`position-relative `}>
          <div className={`row width-auto mb-xs justify-content-between`}>
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel
                style={{
                  maxWidth: '100px  '
                }}
                text={'Order Reference'}
                className={'fw-m ml-r'}
              />
            </div>
            {
              <div
                style={{
                  overflowWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                  minWidth: '75px',
                  maxWidth: 120,
                  marginLeft: isDocumentInFullScreen ? 5 : 0
                }}
              >
                <DKLabel
                  className="text-align-right"
                  text={props.booksDocument.orderReference}
                />
              </div>
            }
          </div>
        </div>
      );
    }
  }
  function renderSIBuyerReference() {
    let value = '';
    let doesBuyerReferenceExist = false;
    if (booksDocument?.contactDto?.customField) {
      booksDocument?.contactDto?.customField?.forEach((item: any) => {
        if (item?.label == 'Buyer Reference') {
          doesBuyerReferenceExist = true;
          value = item?.value;
        }
      });
    }
    const isSGPeppol = isSGAndPeppolOptInEnabled();
    const sgSupportedDocuments = [
      DOC_TYPE.BILL,
      DOC_TYPE.INVOICE,
      DOC_TYPE.SALES_ORDER,
      DOC_TYPE.ORDER
    ];
    if (
      isSGPeppol &&
      sgSupportedDocuments.includes(booksDocument.documentType) &&
      doesBuyerReferenceExist
    ) {
      return (
        <div
          style={{
            width: 150,
            maxWidth: 200,
            wordBreak: 'break-word'
          }}
        >
          <DKInput
            className="parent-width"
            title={'Buyer Reference'}
            value={booksDocument?.buyerReference}
            titleStyle={{ color: 'gray' }}
            valueStyle={{ minHeight: 33 }}
            type={INPUT_TYPE.TEXT}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            required={false}
            readOnly={false}
            onChange={(value: any) => {
              setBooksDocument((prevState: any) => {
                return {
                  ...prevState,
                  buyerReference: value
                };
              });
            }}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }

  ////////////////// setup payment - start /////////////////
  const renderPaymentMethodAction = () => {
    const updatedPaymentOptions = SETUP_PAYMENT[getTenantTaxSystem()].map(
      (item: any) => {
        if (
          !Utility.isEmpty(multiPaymentOptions.current) &&
          !Utility.isEmpty(multiPaymentOptions.current?.[item.key])
        ) {
          item.isConnected =
            multiPaymentOptions.current?.[item.key]?.[0]?.connected;
        }

        if (
          props?.booksDocument?.paymentInformation?.connectionType.toLowerCase() ===
          item.key
        ) {
          item.paymentInfo = props?.booksDocument?.paymentInformation;
        }

        return item;
      }
    );

    const getByTitle = () => {
      if (Utility.isEmpty(booksDocument?.paymentInformation?.connectionType)) {
        return props.documentMode === DOCUMENT_MODE.VIEW ? '' : 'Pay';
      } else {
        return Utility.convertInTitleCase(
          booksDocument?.paymentInformation?.connectionType
        );
      }
    };

    return (
      <div
        className={`p-h-s p-v-xs border-radius-s-2 mr-3 position-relative fw-m ${
          props.documentMode !== DOCUMENT_MODE.VIEW ? 'cursor-hand' : ''
        }`}
        onClick={() => {
          if (
            Utility.isEmpty(contact) ||
            props.documentMode === DOCUMENT_MODE.VIEW
          ) {
            return;
          }
          setSetupPaymentListOpen(!setupPaymentListOpen);
        }}
      >
        <DKTooltipWrapper
          content={
            'Receive Payments via Stripe <br>, Wise, Paypal, Veem  Paytm <br> Razorpay, and Opennode <br> .And get paid faster. <br> Your customers can pay <br>you via cards, netbanking, <br> wallets and UPI.All <br> you have to do is <br> add your  Stripe, Wise,<br>  Paypal, Veem  Paytm, <br> Razorpay, or Opennode  <br> account  to start receiving  <br> payments.'
          }
          tooltipClassName="bg-deskera-secondary width-auto z-index-10"
        >
          <DKLabel
            className={`${
              Utility.isEmpty(contact) ||
              props.documentMode === DOCUMENT_MODE.VIEW
                ? 'text-gray'
                : 'text-blue'
            }`}
            text={getByTitle()}
          />
        </DKTooltipWrapper>

        {/* options */}
        {setupPaymentListOpen && (
          <DKListPicker2
            title="Payment Options"
            data={updatedPaymentOptions}
            className="position-absolute z-index-3 shadow-m ml-l border-s"
            style={{ right: -3, top: 32, width: 220 }}
            onSelect={(index: any, value: any) => {
              if (value.key === 'notApplicable') {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    paymentInformation: null
                  };
                });
              }

              if (!value.isConnected) {
                return;
              }
              if (
                !Utility.isEmpty(multiPaymentOptions.current) &&
                !Utility.isEmpty(multiPaymentOptions.current?.[value.key])
              ) {
                const po = multiPaymentOptions.current?.[value.key]?.[0];
                if (
                  props.documentMode === DOCUMENT_MODE.EDIT ||
                  props.documentMode === DOCUMENT_MODE.COPY
                ) {
                  if (
                    Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (
                    !Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected &&
                    value.key === 'stripe'
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (value.isConnected) {
                    setSelectedPaymentOption(value);
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        paymentInformation: {
                          ...prevState?.paymentInformation,
                          connectionType: value.key.toUpperCase(),
                          paymentAccountDto: {
                            ...props?.booksDocument?.paymentInformation
                              ?.paymentAccountDto,
                            modules: po.modules,
                            connected: po.connected,
                            connectionName: po.connectionName,
                            connectionId: po.connectionId
                          }
                        }
                      };
                    });
                    setSetupPaymentListOpen(!setupPaymentListOpen);
                  }
                }

                if (props.documentMode === DOCUMENT_MODE.NEW) {
                  if (
                    Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (
                    !Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected &&
                    value.key === 'stripe'
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  setSelectedPaymentOption(value);
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      paymentInformation: {
                        ...prevState?.paymentInformation,
                        paymentAccountDto: {
                          ...prevState?.paymentInformation?.paymentAccountDto,
                          modules: [DOC_TYPE.RECEIVE_PAYMENT],
                          connected: po.connected,
                          connectionName: po.connectionName,
                          connectionId: po.connectionId,
                          key: value.key,
                          logo: ''
                        },
                        paymentLink: '',
                        customerPhone: '',
                        connectionType: value.key.toUpperCase()
                      }
                    };
                  });
                  setSetupPaymentListOpen(!setupPaymentListOpen);
                }
              }
            }}
            onClose={() => {
              setSetupPaymentListOpen(false);
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div
                  className="row justify-content-between p-v-xs"
                  onClick={() => {
                    if (!obj.isConnected && obj.key !== 'notApplicable') {
                      window.open(obj.link);
                    }
                  }}
                >
                  {obj.key !== 'notApplicable' && (
                    <DKIcon src={obj.icon} className="ic-s" />
                  )}
                  {obj.key === 'notApplicable' && <DKLabel text={obj.label} />}
                  {
                    <DKLabel
                      className={`${
                        obj.isConnected ? 'text-green' : 'text-gray'
                      }`}
                      text={`${
                        obj.key !== 'notApplicable'
                          ? obj.isConnected
                            ? 'Connected'
                            : 'Connect'
                          : ''
                      }`}
                      onClick={() => {
                        window.open(obj.link);
                      }}
                    />
                  }
                </div>
              );
            }}
          />
        )}
      </div>
    );
  };
  ///////////////// setup payment -end /////////////////////

  //////////////// INVOICE NOW SECTION FOR DOCUMENT /////////////////////
  const showInvoiceNowDetails = () => {
    if (
      (props.documentMode === DOCUMENT_MODE.VIEW ||
        props.documentMode === DOCUMENT_MODE.EDIT) &&
      eInvoiceData.documentType === DOC_TYPE.INVOICE
    ) {
      if (eInvoiceData.einvoiceInfoIndia) {
        return (
          <div className="w-3/4">
            <div className="row">
              <span className="fw-m mr-2">IRN Number : </span>
            </div>
            <div className="row mb-1">{eInvoiceData.einvoiceInfoIndia.irn}</div>
            {getInvoiceNowStatus(eInvoiceData) !==
              INVOICE_NOW_TYPES.CANCELLED && (
              <>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Number : </span>
                  {eInvoiceData.einvoiceInfoIndia &&
                    eInvoiceData.einvoiceInfoIndia.ackNo}
                </div>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Date : </span>
                  {eInvoiceData.einvoiceInfoIndia &&
                    eInvoiceData.einvoiceInfoIndia.ackDt}
                </div>
              </>
            )}
          </div>
        );
      }
    }
  };

  const generateEInvoiceIndia = () => {
    const generateEInvoice = (authToken: any) => {
      try {
        let payload = { ...authToken };
        payload.gstin = tenantInfo.gstin;
        payload.id = props.booksDocument.id;

        EInvoiceService.generateEInvoice(payload).then(
          (res: any) => {
            if (!Utility.isEmpty(res?.errorDetails)) {
              let errors = res?.errorDetails;
              if (errors?.length) {
                let HTML = `<div>
                        <ul>
                          ${errors.map((error: any) => {
                            return `<li>${error?.ErrorMessage}</li>`;
                          })}
                        </ul>
                      </div>`;
                showAlert('eInvoice portal reported errors below', HTML);
              }
            }

            if (props.booksDocument.salesInvoiceCode) {
              InvoiceService.getInvoiceByCode(
                props.booksDocument.salesInvoiceCode
              ).then(
                (data: any) => {
                  let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                  seteInvoiceData(invoiceDetailsData);
                },
                (err) => {
                  console.error('Error loading detailed invoice: ', err);
                }
              );
            }
          },
          (err) => {
            console.error('Error generating eInvoice: ', err);
          }
        );
      } catch (error: any) {}
    };
    if (props && props.booksDocument && tenantInfo) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
            if (
              authToken?.payload?.status === '0' &&
              authToken?.payload?.errors?.[0]?.errorMessage
            ) {
              showAlert(
                'Error!',
                authToken?.payload?.errors?.[0]?.errorMessage
              );
            }
          } else {
            generateEInvoice(authToken.payload);
          }
        });
      } else {
        generateEInvoice(eInvoiceAuthInfoData);
      }
    }
  };

  const populateProductGroupOrBarcodeSearchData = async (
    data: any,
    isProductGroup = false
  ) => {
    setLastUpdatedColumn(undefined);
    let prodRows = [...productRows];
    const allRowsEmpty = prodRows.every((row: any) =>
      Utility.isEmpty(row?.product)
    );
    if (allRowsEmpty) {
      prodRows = [];
    }
    let rowsObj = [...prodRows];
    const oldRows = deepClone(prodRows);
    let count = prodRows.length;
    const updateFromIndex = count;
    setUpdateFromIndex(updateFromIndex);
    data.forEach((item: any) => {
      let newRow = addNewItem(true);
      newRow.lineNumber = count === 0 ? 1 : count + 1;
      if (!isProductGroup) {
        newRow.productQuantity = item.productQuantity;
      }
      rowsObj.push(newRow);
      count++;
    });

    let index = prodRows.length;
    data.forEach((item: any) => {
      rowsObj[index] = onProductChange(index, item, true, rowsObj);
      index++;
    });

    // Update sales document BOM shortfall details in DocumentConfigUtility
    checkAndUpdateBomShortFallDetailsForLineItems(rowsObj);

    const isPriceBookEnabled = tenantInfo.isAdvancePriceListEnabled;
    if (isPriceListEnabled() || isPriceBookEnabled) {
      if (
        Utility.isEmptyObject(selectedPriceBook) ||
        selectedPriceBook?.id === DEFAULT_PRICE_BOOK_ID
      ) {
        const rowsForPricingPayload = rowsObj.filter(
          (row: any, index: number) => index >= updateFromIndex
        );
        const productsPayload = rowsForPricingPayload.map((row: any) => ({
          productId: row.product.productId,
          uomId: row.stockUom,
          quantity: row.productQuantity
        }));

        const priceListResponse = await getPricing(
          productsPayload,
          rowsObj,
          false,
          booksDocument.currency,
          false,
          booksDocument.exchangeRate,
          null,
          null,
          true
        );

        rowsObj = rowsObj?.map((row: any, index: number) => {
          if (index >= updateFromIndex) {
            let priceListForProduct = priceListResponse.find(
              (item: any) => item.productId === row?.productCode
            );
            if (priceListForProduct?.price > 0) {
              row.unitPrice = priceListForProduct?.price;
              row.previousPrice = priceListForProduct?.price;
              row.minimumQuantity = priceListForProduct?.minimumQuantity ?? 1;
              row.upliftPrice = 0;
              row.downliftPrice = 0;
            }
          }
          return row;
        });
      } else {
        rowsObj = rowsObj.map((row: any, index: number) => {
          if (index >= updateFromIndex) {
            if (row) {
              const priceDetailsFromPriceBook = getPriceFromPriceBook({
                ...rowsObj[index]?.['product'],
                productQuantity: row?.productQuantity
              });
              const priceKeyToRead = getProductPriceKeyFromDocType(
                props.booksDocument.documentType
              );
              const productInLineItem = row['product'];
              const previousPrice = row['unitPrice'];
              row['unitPrice'] =
                priceDetailsFromPriceBook?.price ||
                productInLineItem?.[priceKeyToRead];

              const isPriceUpdated = row.unitPrice !== previousPrice;
              row.previousPrice = isPriceUpdated
                ? row.unitPrice
                : row.previousPrice;
              row.upliftPrice = isPriceUpdated ? 0 : row.upliftPrice;
              row.downliftPrice = isPriceUpdated ? 0 : row.downliftPrice;
              row.minimumQuantity =
                priceDetailsFromPriceBook?.minimumQuantity || 1;

              row['discount'] = priceDetailsFromPriceBook?.discount || 0;
              row['discountInPercent'] =
                priceDetailsFromPriceBook?.discountInPercent || false;
            }
          }
          return row;
        });
      }
    }

    if (Utility.isComponentDetailsForFGOnInvoiceSOQuote()) {
      let newRows = rowsObj?.filter((rowObj: any) => {
        const foundInOldRows = oldRows?.find(
          (oldRow: any) => oldRow?.lineNumber === rowObj?.lineNumber
        );
        return Utility.isEmpty(foundInOldRows);
      });
      if (
        newRows?.some(
          (rowObj: any) =>
            rowObj?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        )
      ) {
        newRows = await getComponentDetailsForDocumentLineItems(
          newRows,
          contact,
          props.booksDocument.documentType
        );
      }

      rowsObj = [...oldRows, ...newRows];
    }

    if (getTenantTaxSystem() !== TAX_SYSTEM.US) {
      let currentIndex = 0;
      rowsObj.forEach((item: any) => {
        rowsObj[currentIndex] = calculateTaxesAndAmount(
          currentIndex++,
          rowsObj,
          true
        ) as DocumentItem;
      });
      setProductRows([...rowsObj]);
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          items: [...rowsObj]
        };
      });
      setBarcodeSearchData(data);
    } else {
      setBarcodeSearchData(data);
      calculateUSTax(undefined, rowsObj);
    }
  };

  const cancelEInvoiceIndia = () => {
    if (props && props.booksDocument && tenantInfo) {
      if (
        Utility.isEmpty(eInvoiceAuthInfoData) ||
        eInvoiceAuthInfoData.status !== '1'
      ) {
        dispatch(fetchEInvoiceAuthInfo({})).then((authToken: any) => {
          if (Utility.isEmpty(authToken) || authToken.payload.status !== '1') {
            setShowIRPCredPopup(true);
          } else {
            setShowCancelEInvoice(true);
          }
        });
      } else {
        setShowCancelEInvoice(true);
      }
    }
  };

  const redirectEInvoiceFailureLog = () => {
    dispatch(removeDraft(props.booksDocument.id));
    RouteManager.navigateToPage(PAGE_ROUTES.FAILED_INVOICES);
  };

  const getInvoiceNow = (documentData?: any) => {
    if (
      documentData.documentType === DOC_TYPE.INVOICE &&
      tenantInfo.country === COUNTRY_CODES.IN &&
      tenantInfo.indiaEinvoice
    ) {
      if (
        (props.documentMode === DOCUMENT_MODE.VIEW ||
          props.documentMode === DOCUMENT_MODE.EDIT) &&
        !props.isDocumentLoading
      ) {
        let _status: any = {
          title: '',
          color: ''
        };
        switch (getInvoiceNowStatus(documentData)) {
          case INVOICE_NOW_TYPES.FAILED:
            _status = {
              title: INVOICE_NOW_TYPES.FAILED,
              color: 'bg-chip-red text-red '
            };
            break;
          case INVOICE_NOW_TYPES.GENERATED:
            _status = {
              title: INVOICE_NOW_TYPES.GENERATED,
              color: 'bg-chip-green text-green'
            };
            break;
          case INVOICE_NOW_TYPES.PENDING:
            _status = {
              title: INVOICE_NOW_TYPES.PENDING,
              color: 'bg-chip-orange text-orange'
            };
            break;
          case INVOICE_NOW_TYPES.CANCELLED:
            _status = {
              title: INVOICE_NOW_TYPES.CANCELLED,
              color: 'bg-chip-red text-red '
            };
            break;
        }

        return (
          <div className={`column parent-width p-h-xxl pt-s position-relative`}>
            {!isTabletView() && (
              <div className="row justify-content-between align-items-center mt-1 bg-gray-50 p-2 border-radius-m items-center">
                <div className="flex items-center">
                  <DKLabel className="fw-m" text={`e-Invoice Status :`} />
                  <DKLabel
                    text={Utility.convertInTitleCase(_status.title)}
                    style={{
                      textTransform: 'capitalize'
                    }}
                    className={`text-dark-gray ${_status.color} border-radius-r ml-r p-h-s p-v-xs fw-m mr-r`}
                  />
                  {_status.title === INVOICE_NOW_TYPES.PENDING && (
                    <DKLabel
                      className="ml-3"
                      text={`Push your invoice to the e-invoicing portal(IRP) to genrate an IRN`}
                    />
                  )}
                  {_status.title === INVOICE_NOW_TYPES.FAILED &&
                    documentData.einvoiceInfoIndia.errorDetails && (
                      <DKLabel
                        className="ml-3"
                        text={`${documentData.einvoiceInfoIndia.errorDetails[0].errorMessage}`}
                      />
                    )}
                </div>
                <div>
                  {_status.title === INVOICE_NOW_TYPES.PENDING && (
                    <DKButton
                      title={'Generate e-Invoice'}
                      className="text-green fw-m"
                      onClick={() => {
                        generateEInvoiceIndia();
                      }}
                    />
                  )}
                  {(_status.title === INVOICE_NOW_TYPES.CANCELLED ||
                    _status.title === INVOICE_NOW_TYPES.FAILED) && (
                    <DKButton
                      title={'View Failure Log'}
                      className="text-blue fw-m"
                      onClick={() => {
                        redirectEInvoiceFailureLog();
                      }}
                    />
                  )}
                  {_status.title === INVOICE_NOW_TYPES.GENERATED && (
                    <DKButton
                      title={'Cancel e-Invoice'}
                      className="text-red fw-m"
                      onClick={() => {
                        cancelEInvoiceIndia();
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  };

  const getPaymentMilestoneFormPopup = () => {
    const loadingPaymentMileStoneBtnConfig: LoadingBtnType[] = [
      {
        title: 'Cancel',
        class: 'border-m mr-s',
        type: 'CLOSE'
      },
      {
        title: 'Saving',
        class: 'bg-app text-white mr-ss',
        type: 'ACTION'
      }
    ];
    const paymentMilestonePopupBtnConfig: BtnType[] = [
      {
        title: 'Cancel',
        class: 'bg-gray1 border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: 'Save',
        class: 'bg-app text-white mr-s',
        clickAction: POPUP_CLICK_TYPE.SAVE_PAYMENT_MILESTONE
      }
    ];
    const isReadOnly = props.draftType === DraftTypes.READONLY;
    return (
      <PopupWrapper
        disableClickOutside={true}
        clickAction={catchClicks}
        btnList={
          !isReadOnly
            ? paymentMilestonePopupBtnConfig
            : [paymentMilestonePopupBtnConfig[0]]
        }
        loadingBtnList={loadingPaymentMileStoneBtnConfig}
        isLoading={paymentMilestoneBeingSaved}
        type={POPUP_TYPE.POPUP}
        title={`${isReadOnly ? 'View' : 'Add'} payment Milestones`}
        height={'60%'}
        width={'60%'}
      >
        <PaymentMileStone
          document={{
            ...getDataForMilestonePopup(
              props.booksDocument.documentType,
              props.booksDocument
            )
          }}
          allowEdit={!isReadOnly}
          onCancel={() => {
            setShowPaymentMilestonePopup(false);
          }}
          onSave={() => {
            setShowPaymentMilestonePopup(false);
          }}
          onError={() => {
            setPaymentMilestoneBeingSaved(false);
          }}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    );
  };
  //////////////// INVOICE NOW SECTION FOR DOCUMENT /////////////////////

  //////////////// ADDITIONAL COST SECTION FOR DOCUMENT /////////////////////

  //////////////// ADDITIONAL COST SECTION FOR DOCUMENT /////////////////////

  ////////////////////////////////////////////////////
  ///////////////// MAIN RENDERER ////////////////////
  ////////////////////////////////////////////////////
  const isSetupPaymentVisible =
    props.booksDocument.documentType === DOC_TYPE.INVOICE &&
    checkForCustomField &&
    // props.documentMode !== DOCUMENT_MODE.VIEW &&
    SETUP_PAYMENT[getTenantTaxSystem()] &&
    SETUP_PAYMENT[getTenantTaxSystem()].length > 0;

  const onRowSelectionFromPopup = (selectedProducts: any[]) => {
    populateProductGroupOrBarcodeSearchData(selectedProducts);
    setShowProductSelectionPopup(false);
  };

  const getProductSelectionPopup = () => {
    return (
      <DocProductSelectionPopup
        module={props.booksDocument.documentType}
        onSave={onRowSelectionFromPopup}
        onCancel={() => setShowProductSelectionPopup(false)}
      />
    );
  };

  const isDplChecked = () => {
    if (
      !dimensionData ||
      !contact ||
      Utility.isEmpty(props?.draftType) ||
      props?.draftType === DraftTypes.READONLY
    )
      return false;
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0)
      return false;
    return true;
  };
  const getDetailAccountSelectionPopup = () => {
    return (
      <DefaultAccountsSettingsPopup
        onCancel={() => {
          setShowDefaultAccountsSettings(false);
        }}
        onSave={() => {
          setShowDefaultAccountsSettings(false);
          dispatch(fetchDefaultAccounts());
        }}
      />
    );
  };
  return (
    <>
      {getInvoiceNow(
        !Utility.isEmpty(eInvoiceData) ? eInvoiceData : props.booksDocument
      )}
      <div
        className={`column parent-width p-h-xxl pb-xxl pt-s pt-l position-relative ${
          props.draftType === DraftTypes.READONLY ? 'pointer-events-none' : ''
        }`}
      >
        {props.draftType === DraftTypes.READONLY && false && (
          <div className="position-absolute parent-width parent-height bg-transparent z-index-2 left-0 top-0"></div>
        )}
        <div className="row justify-content-between align-items-start ">
          {!Utility.isEmpty(customLocation) &&
          props.booksDocument.documentType !== DOC_TYPE.BILL &&
          props.booksDocument.documentType !== DOC_TYPE.ORDER &&
          props.booksDocument.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
            ? getCustomCompanyDetails()
            : getCompanyDetails()}
          <div className="column align-items-end">
            {(props.booksDocument.documentType === DOC_TYPE.INVOICE ||
              props.booksDocument.documentType === DOC_TYPE.BILL) && (
              <>
                <DKLabel
                  text={
                    props.booksDocument.documentType === DOC_TYPE.INVOICE
                      ? 'Balance Due'
                      : 'Balance Payable'
                  }
                  className="fs-m"
                />
                <div className="row justify-content-end">
                  {/* show only in case if its invoice document and length of payment options is greater than 0 */}
                  {isSetupPaymentVisible && (
                    <div className="row justify-content-end">
                      {!Utility.isEmpty(contact) && renderPaymentMethodAction()}
                      {showEmailPopupForPayment && (
                        <GetEmailForPayment
                          contact={contact}
                          documentMode={props.documentMode}
                          paymentInformationDetials={
                            booksDocument?.paymentInformation
                          }
                          onSave={(data: any) => {
                            setBooksDocument((prevState: any) => {
                              return {
                                ...prevState,
                                contact: {
                                  ...prevState?.contact,
                                  emailId: data?.email
                                },
                                paymentInformation: {
                                  ...prevState?.paymentInformation,
                                  customerEmail: data?.email,
                                  autoCharge: data?.isAutoCharged
                                }
                              };
                            });
                            setContact((prevState: any) => {
                              return {
                                ...prevState,
                                emailId: data?.email
                              };
                            });
                            setShowEmailPopupForPayment(false);
                          }}
                          onCancel={() => {
                            setShowEmailPopupForPayment(false);
                            setSelectedPaymentOption(null);
                            setBooksDocument((prevState: any) => {
                              return {
                                ...prevState,
                                paymentInformation: null
                              };
                            });
                          }}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className={'fw-m fs-xxl ml-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                  >
                    {`${Utility.getCurrencySymbolFromCode(
                      booksDocument.currency
                    )} ${NumberFormatService.getNumber(dueAmount)}`}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`row justify-content-between align-items-stretch mt-l`}>
          <div className="column justify-content-between position-relative width-80 pr-l">
            {getContactPicker()}
            {getDeliveryAddressDetails()}
            {Utility.isUSorg() &&
              Utility.isDPLSettingEnabled() &&
              Utility.isNotEmpty(contact) &&
              Utility.isNotEmpty(dimensionData) &&
              isDplChecked() && (
                <div style={{ height: '50px' }} className="row">
                  <div className="row position-absolute ">
                    <ReCheckButton
                      onClick={() => {
                        const billingAddress: any[] = [];
                        const countryCodeMap: any = {};
                        COUNTRIES_WITH_CURRENCIES.forEach((country) => {
                          countryCodeMap[country?.country?.toLowerCase()] =
                            country?.countryCode;
                        });
                        if (contact?.billingAddress) {
                          contact?.billingAddress?.forEach((address: any) => {
                            if (address?.country) {
                              billingAddress.push({
                                ...address,
                                countryCode:
                                  countryCodeMap?.[
                                    address?.country?.toLowerCase()
                                  ]
                              });
                            }
                          });
                        }
                        const DplCustomFieldId = dimensionData?.content?.filter(
                          (customField: any) =>
                            customField?.system &&
                            customField?.shortName ===
                              'denied_parties_custom_field'
                        )?.[0]?.id;
                        const dplContactCustomField =
                          contact?.customField?.filter(
                            (cField: any) => cField?.id == DplCustomFieldId
                          );
                        if (
                          !dplContactCustomField ||
                          dplContactCustomField?.length == 0
                        )
                          return;
                        const payload = {
                          name: contact?.name ?? '',
                          billingAddress: billingAddress,
                          customField: dplContactCustomField
                        };
                        showLoader();
                        ContactService.recheck(contact?.id, payload)
                          .then((res) => {
                            removeLoader();
                            if (res) {
                              showToast(
                                'The contact you are using is under denied party list',
                                TOAST_TYPE.SUCCESS
                              );
                            } else {
                              showToast(
                                'The contact you are using is not under denied party list',
                                TOAST_TYPE.SUCCESS
                              );
                            }
                            dispatch(fetchContacts(''));
                          })
                          .catch((err) => {
                            removeLoader();
                          });
                      }}
                    />
                  </div>
                </div>
              )}
            {!isDocumentInFullScreen && (
              <div className="row width-auto align-items-start gap-3">
                {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                  !Utility.isEmpty(contact) &&
                  getPlaceOfSupply()}
                {getDestinationOfSupplyAccordingToDOCType(
                  props?.booksDocument?.documentType,
                  Utility.isDropship(props.booksDocument)
                ) &&
                  getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                  !Utility.isEmpty(contact) &&
                  getSourceOfDestination()}
              </div>
            )}
            {invoiceConfigTableId &&
              !isDocumentInFullScreen &&
              ((props?.documentMode === DOCUMENT_MODE.VIEW &&
                booksDocument.customField &&
                booksDocument.customField.length > 0) ||
                props.documentMode !== DOCUMENT_MODE.VIEW) &&
              getCustomFields()}
            {tenantInfo?.isAdvancePriceListEnabled &&
              !isDocumentInFullScreen &&
              props.booksDocument.documentType === DOC_TYPE.QUOTE &&
              getPriceBookField()}
          </div>
          {getRightInfoPanel()}
        </div>
        {isDocumentInFullScreen && (
          <div className="column parent-width mt-r">
            {getDateAndOtherInfoBlockForFullScreen()}
            <div className="row mt-m">
              {invoiceConfigTableId &&
                ((props.documentMode === DOCUMENT_MODE.VIEW &&
                  booksDocument.customField &&
                  booksDocument.customField.length > 0) ||
                  props.documentMode !== DOCUMENT_MODE.VIEW) &&
                getCustomFields()}
            </div>
            <div className="row">
              {tenantInfo?.isAdvancePriceListEnabled &&
                props.booksDocument.documentType === DOC_TYPE.QUOTE &&
                getPriceBookField()}
            </div>
          </div>
        )}

        <div
          ref={gridContainerRef}
          className={`column parent-width ${
            props.documentMode === DOCUMENT_MODE.VIEW
              ? 'pointer-events-auto'
              : ''
          } ${isDocumentInFullScreen ? 'mt-l' : ''}`}
        >
          {getDataGrid()}
        </div>
        {(!(
          props.booksDocument.isPartialInvoice ||
          props.booksDocument.isPartialBill ||
          props.booksDocument.isPartialSalesOrder ||
          Utility.isDropship(props.booksDocument) ||
          props.documentMode === DOCUMENT_MODE.VIEW ||
          (props?.booksDocument?.documentType === DOC_TYPE.ORDER &&
            props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
              'PURCHASE_REQUEST')
        ) ||
          ((props.booksDocument.isPartialInvoice ||
            props.booksDocument.isPartialBill ||
            props.booksDocument.isPartialSalesOrder) &&
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            tenantInfo.additionalSettings?.EDIT_CONVERTED_DOC)) &&
          checkForCustomField &&
          (!props.booksDocument?.reservedStock ||
            props.draftType === DraftTypes.DRAFT) && (
            <div className="row">
              <DKButton
                title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
                onClick={() => {
                  if (
                    isProductGroupingEnabled &&
                    props.booksDocument.documentType !==
                      DOC_TYPE.JOB_WORK_OUT_ORDER
                  ) {
                    setShowProductSelectionPopup(true);
                  } else {
                    addNewItem();
                  }
                }}
                className={`walkthrough-step-6 ${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
              />
              <DKButton
                title={`Scan Barcode`}
                onClick={() => setShowBarcodePopup(true)}
                icon={BarcodeIcon}
                className={`${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1 }}
              />
              {productRows.length > 0 && (
                <DKButton
                  title={`- Clear all items`}
                  onClick={() => clearAllItems()}
                  className={`${
                    props.draftType === DraftTypes.READONLY
                      ? 'text-gray'
                      : 'text-blue'
                  } fw-m p-0`}
                  style={{ marginTop: -10, zIndex: 1 }}
                />
              )}
              {Utility.isSalesDocument(props.booksDocument) &&
                checkFulfillment(
                  props.booksDocument?.sourceFulfillmentStatus ||
                    FULFILLMENT_STATUS.UNFULFILLED
                ) &&
                productRows.length > 0 && (
                  <DKButton
                    title={
                      (booksDocument.reservedStock
                        ? 'Deactivate '
                        : 'Activate ') + 'reserved stock'
                    }
                    onClick={() => toggleReserveStock()}
                    className={`text-blue fw-m p-0`}
                    style={{ marginTop: -10, zIndex: 1 }}
                  />
                )}
              {showComponenListSection(booksDocument?.items) && (
                <DKButton
                  title={`${
                    booksDocument?.showComponentList ? 'Hide' : 'Show'
                  } component list`}
                  onClick={() => {
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        showComponentList: !prevState?.showComponentList
                      };
                    });
                  }}
                  className={`${
                    props.draftType === DraftTypes.READONLY
                      ? 'text-gray'
                      : 'text-blue'
                  } fw-m p-0`}
                  style={{ marginTop: -10, zIndex: 1 }}
                />
              )}
            </div>
          )}
        {showComponenListSection(booksDocument?.items ?? []) &&
          (props?.booksDocument?.isPartialInvoice ||
            props?.booksDocument?.isPartialSalesOrder ||
            props?.documentMode === DOCUMENT_MODE.VIEW) && (
            <DKButton
              title={`${
                booksDocument?.showComponentList ? 'Hide' : 'Show'
              } component list`}
              onClick={() => {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    showComponentList: !prevState?.showComponentList
                  };
                });
              }}
              className={`text-blue fw-m p-0`}
              style={{
                marginTop: -10,
                zIndex: 1,
                padding: 0,
                pointerEvents: 'auto'
              }}
            />
          )}

        {/* Footer section */}
        <div className="flex flex-row items-start justify-content-between box-border w-full mt-m">
          <div
            className={`column ${
              props.draftType === DraftTypes.READONLY &&
              props.booksDocument.documentType === DOC_TYPE.INVOICE
                ? 'pointer-events-auto'
                : 'mt-xl'
            }`}
          >
            {/* component details for FG on documents */}
            {Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
              booksDocument?.showComponentList && (
                <div>
                  <ComponentDetailsForFG
                    componentGridWidth={gridWidth / 2 + 100}
                    booksDocument={booksDocument}
                    productRows={productRows}
                  />
                </div>
              )}

            <textarea
              className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
              style={{
                width: 500,
                height: 130,
                backgroundColor: 'rgb(250, 250, 250)',
                border: '1px dashed rgb(200, 200, 200)'
              }}
              placeholder={t(`DOCUMENT.MEMO_OPTIONAL`)}
              value={booksDocument.memo}
              onChange={(e: any) => {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    memo: e.target.value,
                    isDocumentTouched:
                      e.target.value?.trim() !== '' &&
                      booksDocument.memo?.trim() !== '' &&
                      e.target.value?.trim() !== booksDocument.memo?.trim()
                  };
                });
              }}
            ></textarea>
            {
              <Fragment>
                <DKButton
                  title={
                    <>
                      + Attach files
                      <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                    </>
                  }
                  className={`${
                    props.draftType === DraftTypes.READONLY ||
                    !checkForCustomField
                      ? 'text-gray'
                      : 'text-blue'
                  } mt-r fw-m`}
                  style={{ paddingLeft: 0 }}
                  disabled={
                    props.draftType === DraftTypes.READONLY ||
                    !checkForCustomField
                  }
                  onClick={triggerAttachmentUpload}
                />
                <div className="row pointer-events-auto">
                  {getAttachments()}
                </div>
              </Fragment>
            }

            <div className="row mt-r">
              {!props.draftData.isLoading &&
                (props.documentMode === DOCUMENT_MODE.EDIT ||
                  props.documentMode === DOCUMENT_MODE.VIEW) && (
                  <DocumentActionMenu
                    booksDocument={{ ...props.booksDocument, ...booksDocument }}
                    draftId={props.draftData.id}
                    position="bottom"
                    draftType={props.documentMode}
                    updateWithoutClosing={() => {
                      if (props.updateDocWithoutClosing) {
                        props.updateDocWithoutClosing();
                      }
                    }}
                    auditLogView={props.auditLogView}
                    closeMenuContainer={() => {
                      dispatch(removeDraft(props.draftData.id));
                      if (
                        DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname)
                      ) {
                        if (
                          history.location?.pathname?.includes(
                            PAGE_ROUTES.INVOICES
                          )
                        ) {
                          history.replace(PAGE_ROUTES.INVOICES);
                        }
                        if (
                          history.location?.pathname?.includes(
                            PAGE_ROUTES.QUOTES
                          )
                        ) {
                          history.replace(PAGE_ROUTES.QUOTES);
                        }
                        if (
                          history.location?.pathname?.includes(
                            PAGE_ROUTES.SALES_ORDERS
                          )
                        ) {
                          history.replace(PAGE_ROUTES.SALES_ORDERS);
                        }
                        if (
                          history.location?.pathname?.includes(
                            PAGE_ROUTES.BILLS
                          )
                        ) {
                          history.replace(PAGE_ROUTES.BILLS);
                        }
                        if (
                          history.location?.pathname?.includes(
                            PAGE_ROUTES.ORDERS
                          )
                        ) {
                          history.replace(PAGE_ROUTES.ORDERS);
                        }
                      }
                    }}
                  />
                )}
            </div>
            <div className="mt-">{showInvoiceNowDetails()}</div>
          </div>

          <div
            className={props.documentMode === DOCUMENT_MODE.VIEW ? 'mt-r' : ''}
            style={{ minWidth: 330 }}
          >
            <DocumentSummaryView
              booksDocument={booksDocument}
              roundOffDirty={roundOffDirty}
              documentMode={
                props.documentMode && checkForCustomField
                  ? props.documentMode
                  : DOCUMENT_MODE.VIEW
              }
              onTotalOrAdditionalChargeChange={(additionalInfo) => {
                let paidAmount =
                  booksDocument.knockoffInfo?.reduce(
                    (total: number, paymentInfo: any) =>
                      total +
                      Number(paymentInfo?.amount || 0) *
                        Number(paymentInfo?.exchangeRate || 1),
                    0
                  ) || 0;
                if (tenantInfo.country === COUNTRY_CODES.IN) {
                  const tdsAmount =
                    booksDocument.tdsInfo?.reduce(
                      (total: number, tdsData: any) =>
                        total + Number(tdsData?.tdsAmount || 0),
                      0
                    ) || 0;
                  paidAmount = paidAmount + tdsAmount;
                }
                setDueAmount(
                  Utility.roundOff(
                    additionalInfo.total - paidAmount,
                    Store.getState().authInfo.currentTenantInfo.data
                      .decimalScale
                  )
                );

                additionalCharges.current = additionalInfo.additionalCharges;

                let tempDoc = { ...booksDocument };
                if (Utility.isUSorg()) {
                  tempDoc = {
                    ...tempDoc,
                    items: tempDoc?.items?.length
                      ? tempDoc?.items?.map((item: any) => {
                          let expectedDeliveryDateObject =
                            item.expectedDeliveryDt;
                          let itemCopy = deepClone(item);
                          // Date object should not be converted in String
                          itemCopy.expectedDeliveryDt =
                            expectedDeliveryDateObject;
                          ItemTaxCalculator.item = itemCopy;
                          ItemTaxCalculator.setInitialValues();
                          itemCopy = { ...ItemTaxCalculator.item };
                          return itemCopy;
                        })
                      : []
                  };

                  if (
                    typeof additionalInfo.additionalCharges
                      ?.additionalChargesDetails !== 'undefined' &&
                    additionalInfo.additionalCharges
                      ?.additionalChargesDetails !== null
                  ) {
                    let remainingCharges =
                      additionalInfo.additionalCharges?.additionalChargesDetails?.map(
                        (item: any) => item.additionalCharge
                      );

                    tempDoc.items = tempDoc?.items?.map((item: any) => {
                      let itemCopy = { ...item };
                      let additionalChargesDetails =
                        itemCopy?.additionalCharges?.additionalChargesDetails ??
                        [];
                      additionalChargesDetails =
                        additionalChargesDetails.filter((charge: any) =>
                          remainingCharges.includes(charge.additionalCharge)
                        );
                      return {
                        ...itemCopy,
                        additionalCharges: {
                          additionalChargesDetails
                        }
                      };
                    });

                    additionalInfo.additionalCharges?.additionalChargesDetails?.forEach(
                      (charge: any) => {
                        tempDoc = {
                          ...tempDoc,
                          items: updateLineLevelAdditionalChargeAllocation(
                            charge,
                            tempDoc.items
                          )
                        };
                      }
                    );
                  }
                }

                if (tempDoc?.items?.length) {
                  setProductRows([...tempDoc.items]);
                }
                const shipFromVal = props.booksDocument?.shipFrom?.country
                  ? props.booksDocument?.shipFrom
                  : tempDoc.shipFrom;
                setBooksDocument({
                  ...tempDoc,
                  shipFrom: shipFromVal,
                  totalAmount: additionalInfo.total,
                  amountToReceiveOrPay: additionalInfo.total - paidAmount,
                  additionalCharges: additionalInfo.additionalCharges
                });
              }}
              onTCSChange={onTCSChange}
              hidden={!checkUserPermission(props.permissionKeys.VIEW_PRICE)}
              onTaxInclusiveFlagChange={setTaxInclusiveFlag}
              onRoundingOffChange={setRoundOffAmountInDocumentCurrency}
              onCurrencyAndExchangeRateChange={(
                currency: any,
                exchangeRate: number
              ) => {
                isExchangeRateChangedManually.current = true;
                if (props.draftData?.data?.isCashInvoice) {
                  loadAccountGroups(currency);
                }
                updateCurrencyAndExchangeRate(
                  { ...booksDocument, isDocumentTouched: true },
                  currency,
                  exchangeRate
                );
              }}
              onPrimaryCurrencyChange={(primaryExchangeRate: number) => {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    primaryExchangeRate: primaryExchangeRate
                  };
                });
              }}
              onAdditionalChargeAllocationOnLineLevel={(data: any) => {
                setProductRows([...data.items]);
                setBooksDocument({
                  ...data
                });
              }}
            />
          </div>
        </div>
        {showUpdateAddressPopup && (
          <UpdateAddress
            contactDetails={
              props.documentMode === DOCUMENT_MODE.EDIT
                ? booksDocument.contactDto
                : booksDocument.contact
            }
            addressList={
              booksAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                ? billingAddress?.billingAddress
                : shippingAddress?.shippingAddress
            }
            addressType={booksAddressType}
            onCancel={() => setShowUpdateAddressPopup(false)}
            onAddressUpdate={() => onAddressUpdated()}
          />
        )}
        {/* {showAddressPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add New Address'}
          btnList={popupBtnConfig}
          width={'50%'}
        >
          <EditAddress
            contactDetails={
              props.documentMode === DOCUMENT_MODE.EDIT
                ? booksDocument.contactDto
                : booksDocument.contact
            }
            addressList={
              booksAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                ? billingAddress?.billingAddress
                : shippingAddress?.shippingAddress
            }
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            addressType={booksAddressType}
          />
        </PopupWrapper>
          )} */}
        {showTDSCalculationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={`TDS Calculation`}
            btnList={[
              {
                title: `Cancel`,
                class: 'border-m mr-s',
                clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
              },
              {
                title: `Submit`,
                class: 'bg-app text-white mr-ss',
                clickAction: POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT
              }
            ]}
            width="40%"
            height="65%"
            disableClickOutside={true}
          >
            <TDSCalculation
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              tdsData={currentRowTDSInfo}
            />
          </PopupWrapper>
        )}
        {showITCPopup && (
          <ItcOptions
            itcData={currentRowITCInfo}
            setITCIneligibleOption={(data: any) => {
              onITCOptionSelected(data);
            }}
            onClose={() => {
              setCurrentRowITCInfo(null);
              setShowITCPopup(false);
            }}
          />
        )}
        {showHSNPopup && (
          <HSNForm
            HSNData={currentRowHSNInfo}
            setHSNChangeValue={(data: any) => {
              onHSNChange(data);
            }}
            onClose={() => {
              setCurrentRowHSNInfo(null);
              setShowHSNPopup(false);
            }}
          />
        )}
        {showGSTPopup && (
          <GSTValueForm
            GSTData={currentRowGSTInfo}
            setGSTChangeValue={(data: any) => {
              onGSTValueChange(data);
            }}
            onClose={() => {
              setCurrentRowGSTInfo(null);
              setShowGSTPopup(false);
            }}
          />
        )}
        {showTaxRowPopup && (
          <TaxValueEditForm
            taxData={currentRowTaxInfo}
            setTaxChangeValue={(data: any) => {
              onTaxValueChange(data);
            }}
            onClose={() => {
              setCurrentRowTaxInfo(null);
              setShowTaxRowPopup(false);
            }}
          />
        )}
        {showIRPCredPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={'Enter your IRP Credentials'}
            btnList={popupBtnConfigForEInvoice}
            isLoading={popupLoading}
            loadingBtnList={loadingIRPCredPopupBtnConfig}
            width={'35%'}
            maxHeight={'95%'}
            disableClickOutside={true}
          >
            <IRPCredPopup
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              sessionOutMessage={true}
              gstIn={tenantInfo.gstin}
              onCancel={() => {
                setShowIRPCredPopup(false);
                generateEInvoiceIndia();
              }}
            />
          </PopupWrapper>
        )}
        {showCancelEInvoice && (
          <CancelEInvoice
            data={{
              id: eInvoiceData.id,
              irn: eInvoiceData.einvoiceInfoIndia.irn
            }}
            onCancel={() => {
              setShowCancelEInvoice(false);
              if (props.booksDocument.salesInvoiceCode) {
                InvoiceService.getInvoiceByCode(
                  props.booksDocument.salesInvoiceCode
                ).then(
                  (data: any) => {
                    let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
                    seteInvoiceData(invoiceDetailsData);
                  },
                  (err) => {
                    console.error('Error loading detailed invoice: ', err);
                  }
                );
              }
            }}
          />
        )}
        {showLandedCostPopup && (
          <AllocateLandedCost
            setLandedCostData={(data: any) => {
              onLandedCostDataUpdate(data);
            }}
            bill={booksDocument}
            documentMode={props.documentMode}
            rowIndex={lastUpdatedIndex.current}
            landedProducts={props?.booksDocument?.landedProducts}
            onClose={() => {
              setShowLandedCostPopup(false);
              setCurrentRowLandedCostInfo(null);
            }}
          />
        )}
        {showProductPopup && getProductForm()}
        {showTaxPopup && getTaxForm()}
        {showAddClassPopup && getAddClassForm()}
        {getAddContactPopup()}
        {getUpdateOrgPopup()}
        {showTaxExchangeRatePopup && getTaxExchangeRatePopup()}
      </div>
      {showBarcodePopup && (
        <BarcodeSearchPopup
          isSellModule={
            props.booksDocument.documentType === DOC_TYPE.INVOICE ||
            props.booksDocument.documentType === DOC_TYPE.SALES_ORDER ||
            props.booksDocument.documentType === DOC_TYPE.QUOTE
          }
          onCancel={() => {
            setShowBarcodePopup(false);
          }}
          onDone={(data: any) => {
            setShowBarcodePopup(false);
            populateProductGroupOrBarcodeSearchData(data);
          }}
        />
      )}
      {openProductCFSettings && (
        <CustomFieldSettings
          fields={productCustomFields}
          moduleName={MODULES_NAME.PRODUCT}
          onSave={async () => {
            try {
              const updatedOrderedCFs = await dispatch(
                fetchProductCustomFields({
                  status: 'ACTIVE',
                  limit: '1000',
                  module: MODULES_NAME.PRODUCT
                })
              );
              setOpenProductCFSettings(false);
              updateLineLevelCForder(updatedOrderedCFs.payload?.content);
            } catch (err: any) {
              console.error('Error fetching product CFs: ', err);
            }
          }}
          onClose={() => setOpenProductCFSettings(false)}
        />
      )}
      {showReserveStockPopup && (
        <ReserveStockPopUp
          reservedQuantitiesDataOriginal={reservedQuantitiesOriginal}
          selectedProduct={reserveStockLineItem}
          itemsData={booksDocument.items}
          reservedQuantitiesData={reserveStockLineItem.reservedQuantitiesData}
          docType={props?.booksDocument?.documentType}
          onSave={(data: any) => {
            const lineNumber = data.lineNumber;
            const pRows = [...productRows].map((pRow: any) => {
              if (pRow.lineNumber === lineNumber) {
                return {
                  ...pRow,
                  reservedQuantitiesData: data.records
                };
              } else {
                return pRow;
              }
            });

            let lineItems = [...booksDocument.items];
            lineItems = lineItems.map((lineItem: any) => {
              if (lineItem.lineNumber === lineNumber) {
                return {
                  ...lineItem,
                  reservedQuantitiesData: data.records
                };
              } else {
                return lineItem;
              }
            });
            setProductRows([...pRows]);
            setBooksDocument((prevState: any) => ({
              ...prevState,
              items: lineItems
            }));
            setShowReserveStockPopup(false);
          }}
          onCancel={() => {
            setShowReserveStockPopup(false);
          }}
        />
      )}
      {showPaymentMilestonePopup && getPaymentMilestoneFormPopup()}
      {showTaxGroupDetailsPopup && getTaxGroupDetailsPopup()}
      {showProductSelectionPopup && getProductSelectionPopup()}
      {showDefaultAccountSettings && getDetailAccountSelectionPopup()}
    </>
  );
}
