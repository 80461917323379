import { showAlert } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { Document } from '../../Models/Document';
import { DraftTypes } from '../../Models/Drafts';
import {
  OrderInitialState,
  OrderProps,
  RequisitionOrder,
  RequisitionOrderbody
} from '../../Models/RequisitionOrder';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { updatePOPRAssociation } from '../../Redux/Slices/MRP/WorkOrderSlice';
import { updateRefreshCallback } from '../../Redux/Slices/PurchaseOrdersSlice';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import { fetchOrders } from '../../Redux/Slices/RequisitionSlice';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import AuthService from '../../Services/Auth';
import DateFormatService from '../../Services/DateFormat';
import DraftService from '../../Services/Drafts';
import NumberFormatService from '../../Services/NumberFormat';
import {
  default as OrderService,
  default as RequisitionOrderService
} from '../../Services/Requisition';
import {
  customFieldsContainsErrors,
  getDocumentAlert,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../Utility/Utility';
import { setItemsFromPurchaseOrderItems } from './RequisitionHelper';
import RequisitionForm from '../../SharedComponents/DocumentForm/RequisitionForm';
import { handleMachineShortFallPRCreate } from '../Mrp/MachineMaster/MachineMasterHelper';

const NewRequisition: React.FC<OrderProps> = (props) => {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [order, setOrder] = useState<RequisitionOrder>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromPurchaseOrderItems(props.populateFormData)
      : { ...OrderInitialState }
  );

  const [body, setbody] = useState<RequisitionOrderbody>();
  const [updatedOrder, setUpdatedOrder] = useState<RequisitionOrder>(order);
  const draftsTableId = useAppSelector(draftTableId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const dispatch = useAppDispatch();
  let history = useHistory();
  const [bodyForUpdate, setBodyForUpdate] = useState<RequisitionOrderbody>();
  const [validateItem, setValidateItem] = useState<boolean>(false);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: onSaveAsDraft
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ORDER,
        data: () => {
          updateOrderDetails();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_ORDER,
        data: (closeDoc: boolean) => {
          createOrder(closeDoc);
        }
      });
    }
  };

  useEffect(() => {
    if (order && order.dropShip && props.documentMode === DOCUMENT_MODE.EDIT) {
      showAlert(
        'Dropship Purchase Order',
        'This is a Dropship document and has only limited edit functionality'
      );
    }
    setIsCenterAlign(props.isCenterAlign);
    loadApprovalConditionList();
    return () => {
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
  }, [props]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setOrder({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = () => {
    const payload = { ...updatedOrder };

    if (props.documentMode !== DOCUMENT_MODE.EDIT) {
      delete payload.documentSequenceCode;
    }

    payload['approvalStatus'] = payload['approvalStatus']
      ? payload['approvalStatus']
      : APPROVAL_STATUS['NOT_REQUIRED'];
    payload['createdUserName'] = AuthService.getUserEmail();
    let parsedItems = payload?.items;
    if (Utility.isMRPWithURLCheck()) {
      parsedItems = parsedItems?.map((prItem: any) => {
        return {
          ...prItem,
          expectedDeliveryDt: DateFormatService.getDateStrFromDate(
            prItem.expectedDeliveryDt,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
    parsedItems = parsedItems?.map((prItem: any) => {
      return {
        ...prItem,
        customField: prItem?.customField?.map((field: any) => {
          const colKey: any = `${field?.id}`;
          return {
            ...field,
            value:
              field?.fieldType === 'DATE'
                ? DateFormatService.getDateStrFromDate(
                    new Date(prItem[colKey]),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : prItem[colKey] || field?.value || ''
          };
        })
      };
    });

    payload.items = parsedItems;
    payload.purchaseRequestItems = parsedItems;
    setButtonStatus(true);
    props.formData(payload);
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.REQUISITIONS)
    ) {
      history.replace(PAGE_ROUTES.REQUISITIONS);
    }
  };

  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }
    if (Utility.isEmpty(docToValidate.purchaseRequestItems) || validateItem) {
      showAlert(
        'Data Fields Error',
        'Please add at least one line item or if added please remove blank item line'
      );
      return false;
    }
    if (docToValidate.memo?.length > 200) {
      showAlert('Data Fields Error', 'Memo Length limit is of 200 characters');
      return false;
    }

    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }

    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.purchaseRequestItems.length; i++) {
      const item = docToValidate.purchaseRequestItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    RequisitionOrderService.fetchOrderDetails(docResp.purchaseRequestCode).then(
      (doc: any) => {
        // for MRP
        if (payload?.updateResponseInStore) {
          dispatch(updatePOPRAssociation(doc));
        }
        const formData = {
          ...doc,
          documentType: DOC_TYPE.REQUISITION,
          items: [...doc.purchaseRequestItems],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate,
          contactCode: payload.contactCode
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (!isUpdate) {
            if (payload.showCustomAlert) {
              showAlert(
                'Requisition created!',
                'Requisition has been created successfully.'
              );
              dispatch(updateRefreshCallback(new Date()));
              return;
            }

            getDocumentAlert(
              'Requisition created!',
              'Requisition has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.REQUISITIONS
            );
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
      }
    );
  };

  // Create Order
  const createOrder = async (closeDoc: boolean) => {
    let payload: any = deepClone(updatedOrder);
    payload = updateAddressAsPerLocationCF(payload);
    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    let productQuantityArray: any = [];
    payload.purchaseRequestItems = payload?.purchaseRequestItems?.map(
      (item: any) => {
        let expectedDeliveryDate = DateFormatService.getDateStrFromDate(
          item?.expectedDeliveryDt,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        item = {
          ...item,
          expectedDeliveryDt: Utility.isEmpty(expectedDeliveryDate)
            ? null
            : expectedDeliveryDate
        };
        productQuantityArray.push(item?.productQuantity);
        return item;
      }
    );

    payload['approvalStatus'] =
      payload['approvalStatus'] || APPROVAL_STATUS['NOT_REQUIRED'];

    let validateQuantity: boolean;
    productQuantityArray.includes(0) || productQuantityArray.includes(undefined)
      ? (validateQuantity = true)
      : (validateQuantity = false);

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.tableId}/record/${props.draftData?.data?.id}`;
    }

    if (validateItem || payload.items.length === 0) {
      showAlert(
        'Data Fields Error',
        'Please add at least one line item or if added please remove blank item line'
      );
    } else {
      if (validateQuantity) {
        showAlert('Error', 'Product Quantity must be greater than 0');
        return false;
      } else {
        if (payload) {
          payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];
        }
        setButtonStatus(true);
        setValidationDisplayStatus(true);
        OrderService.createOrder(
          payload,
          Utility.getTenantSpecificApiCode(
            COMPLIANCE_SPECIFIC_FIELD_NAME.REQUISITION
          )
        ).then(
          (response: any) => {
            if (props.draftData) {
              if (response.draft) {
                // Remove draft popup
                dispatch(removeDraft(props.draftData.id));
                setButtonStatus(false);

                const buttons = [
                  {
                    title: 'Ok',
                    className: 'bg-button, border-m',
                    onClick: () => {}
                  },
                  {
                    title: 'Goto Requisitions',
                    className: ' bg-blue text-white ml-r',
                    onClick: () => {
                      RouteManager.navigateToPage(PAGE_ROUTES.REQUISITIONS);
                    }
                  }
                ];
                showAlert(
                  'Requisitions sent for approval!',
                  'Document has been created successfully.',
                  buttons
                );
              } else {
                updateForm(response, payload, false);
              }
            }
            dispatch(
              fetchDrafts({
                tableId: draftsTableId,
                isSaveColumnId: isSavedColumnId
              })
            );

            handleMachineShortFallPRCreate(payload, response);

            if (response?.dropShip || response?.backOrder) {
              switch (response?.linkedDocuments?.[0]?.documentType) {
                case DOC_TYPE.INVOICE:
                  dispatch(fetchInvoices());
                  break;
                case DOC_TYPE.QUOTE:
                  dispatch(fetchQuotes());
                  break;
                case DOC_TYPE.SALES_ORDER:
                  dispatch(fetchSalesOrders());
                  break;
                default:
                  break;
              }
            } else {
              dispatch(fetchOrders());
            }
            dispatch(fetchOrders());
            setButtonStatus(true);
            replaceURLToModuleURL();
          },
          (err) => {
            console.error('Error while creating Order: ', err);
            setButtonStatus(false);
            showAlertOnDocAPIError(err);
            replaceURLToModuleURL();
          }
        );
      }
    }
  };

  const updateOrderDetails = (closeOnUpdate = true) => {
    let payload: any = deepClone(updatedOrder);

    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    payload.purchaseRequestItems = payload?.purchaseRequestItems?.map(
      (purchaseReqItem: any) => {
        let expectedDeliveryDate = DateFormatService.getDateStrFromDate(
          new Date(purchaseReqItem?.expectedDeliveryDt),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        return {
          ...purchaseReqItem,
          expectedDeliveryDt:
            expectedDeliveryDate === '' ||
            typeof expectedDeliveryDate === undefined
              ? null
              : expectedDeliveryDate
        };
      }
    );
    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    setButtonStatus(true);

    let copyBodyForUpdate: any = { ...bodyForUpdate };
    copyBodyForUpdate.purchaseRequestItems =
      copyBodyForUpdate.purchaseRequestItems?.map((purchaseReqItem: any) => {
        let expectedDeliveryDate = DateFormatService.getDateStrFromDate(
          new Date(purchaseReqItem?.expectedDeliveryDt),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        return {
          ...purchaseReqItem,
          expectedDeliveryDt:
            expectedDeliveryDate === '' ||
            typeof expectedDeliveryDate === undefined
              ? null
              : expectedDeliveryDate
        };
      });

    setValidationDisplayStatus(true);
    OrderService.updateOrder(
      copyBodyForUpdate,
      Utility.getTenantSpecificApiCode(
        COMPLIANCE_SPECIFIC_FIELD_NAME.REQUISITION
      )
    ).then(
      (res: any) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          setButtonStatus(false);
          updateForm(res, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId
          })
        );
        setButtonStatus(false);

        dispatch(fetchOrders());
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error updating PO: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.purchaseRequestItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || []
    };
    OrderService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err: any) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  const handleOrderUpdate = (doc: Document) => {
    let orderWithUpdate: RequisitionOrder = {
      ...order,
      ...doc,
      purchaseRequestItems: doc.items?.map((item: any, index) => {
        return {
          ...item,
          customField: item.customField?.map((field: any) => {
            return {
              ...field,
              value: item[field?.id + ''] || field?.value || ''
            };
          })
        };
      }),
      items: doc.items?.map((item: any, index) => {
        return {
          ...item,
          customField: item.customField?.map((field: any) => {
            return {
              ...field,
              value:
                field?.fieldType === 'DATE'
                  ? DateFormatService.getDateStrFromDate(
                      new Date(item[field?.id + '']),
                      BOOKS_DATE_FORMAT['MM/DD/YYYY']
                    )
                  : item[field?.id + ''] || field?.value || ''
            };
          })
        };
      }),

      taxAmount: 0,
      totalAmount: 0,
      unitPriceGstInclusive: false,
      orderDueDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      receiveByDate: doc.fulfillmentDate,
      vendorCode: doc.contactCode,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      documentDate: doc.documentDate,
      dropShipName: doc.dropShipName || '',
      attachmentIds: doc.attachmentIds,
      attachments: doc.attachments
    };

    let editBillWithExtraKeys = {
      documentCode: '',
      documentSequenceCode: doc.documentSequenceCode,
      documentType: DOC_TYPE.ORDER,
      currencyCode: doc.currencyCode,
      currency: doc.currency,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT ? order?.id : undefined,
      items: orderWithUpdate.purchaseRequestItems?.map((item) => {
        return {
          ...item,

          advancedTracking: item?.product?.advancedTracking,
          unitPriceGstInclusive: orderWithUpdate.unitPriceGstInclusive,
          exchangeRate: orderWithUpdate.exchangeRate,
          documentItemCode: Utility.getValue(
            item.documentItemCode,
            item.purchaseOrderItemCode,
            item.purchaseInvoiceItemCode
          )
        };
      }),
      purchaseRequestItems: orderWithUpdate.purchaseRequestItems?.map(
        (billDoc) => {
          return {
            ...billDoc,
            documentItemCode: billDoc.purchaseOrderItemCode
          };
        }
      )
    };

    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      orderWithUpdate = { ...orderWithUpdate, ...editBillWithExtraKeys };
    }
    setUpdatedOrder(orderWithUpdate);

    orderWithUpdate?.items?.map((object: any) => {
      if (Utility.isEmpty(object.product)) {
        setValidateItem(true);
      } else {
        setValidateItem(false);
      }
    });
    if (order.dueDate !== undefined)
      if (
        orderWithUpdate['items'] !== undefined &&
        props.documentMode !== DOCUMENT_MODE.VIEW
      ) {
        // const productCode = orderWithUpdate.map(element: any, index){
        //   code : element
        // };
        //const item = orderWithUpdate[].map((num) => num * 2)

        const item = orderWithUpdate['items'].map((element: any, index) => {
          let s = {
            discount: 0,
            discountInPercent: true,
            product: element['product'],
            productCode: element['product']?.productId
              ? element['product']?.productId
              : element.productCode,
            productDescription: element['productDescription'],
            productOrder: 0,
            lineNumber: index + 1,
            productQuantity: element['productQuantity'],
            taxAmount: 0,
            totalAmount: 0,
            documentUom: element.documentUom ? element.documentUom : null,
            uomQuantity: element.uomQuantity,
            uomUnitPrice: element.uomUnitPrice,
            documentUOMSchemaDefinition: element.documentUOMSchemaDefinition,
            expectedDeliveryDt: element?.expectedDeliveryDt,
            customField: element?.customField?.map((field: any) => {
              const colKey: any = `${field?.id}`;
              return {
                ...field,
                value:
                  field?.fieldType === 'DATE'
                    ? DateFormatService.getDateStrFromDate(
                        new Date(element[colKey]),
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      )
                    : element[colKey] || field?.value || ''
              };
            })
          };
          return s;
        });
        setUpdatedOrder((prevState: any) => {
          return {
            ...prevState,
            currency: orderWithUpdate['currency'],
            purchaseRequestDate: orderWithUpdate['documentDate'],
            purchaseRequestItems: item,
            taxAmount: 0,
            totalAmount: 0,
            unitPriceGstInclusive: false,
            receiveByDate: orderWithUpdate.receiveByDate,
            shipByDate: orderWithUpdate['dueDate'],
            shipTo: orderWithUpdate.shipTo,
            billTo: orderWithUpdate.billTo,
            attachmentId: orderWithUpdate.attachmentIds,
            attachments: orderWithUpdate.attachments,
            sequenceFormat: orderWithUpdate.sequenceFormat,
            memo: orderWithUpdate.memo,
            exchangeRate: orderWithUpdate.exchangeRate,
            linkedDocuments: orderWithUpdate?.linkedDocuments ?? [],
            customField: orderWithUpdate?.customField,
            documentSequenceCode: orderWithUpdate?.documentSequenceCode || null,
            seqCodeAlreadyDumped: orderWithUpdate?.seqCodeAlreadyDumped || false
          };
        });
        if (
          orderWithUpdate.manualMode === true &&
          Utility.isEmpty(orderWithUpdate.sequenceFormat)
        ) {
          setUpdatedOrder((prevState: any) => {
            return {
              ...prevState,
              manulMode: orderWithUpdate.manualMode,
              documentSequenceCode: orderWithUpdate.documentSequenceCode
            };
          });
        }
        const updateItems = orderWithUpdate['items'].map(
          (element: any, index) => {
            if (element['productCode'] === undefined) {
              let d = {
                discount: 0,
                discountInPercent: true,
                lineNumber: index + 1,
                productCode: element['product']['productId'],
                productDescription: element['productDescription'],
                productOrder: 0,
                productQuantity: element['productQuantity'],
                taxAmount: 0,
                totalAmount: 0,
                documentUom: element.uom ? element.uom.id : null,
                expectedDeliveryDt: element?.expectedDeliveryDt,
                customField: element?.customField?.map((field: any) => {
                  const colKey: any = `${field?.id}`;
                  return {
                    ...field,
                    value: element[colKey] || field?.value || ''
                  };
                })
              };
              return d;
            } else {
              let d = {
                discount: 0,
                discountInPercent: true,
                productCode: element['productCode'],
                productDescription: element['productDescription'],
                lineNumber: index + 1,
                productOrder: 0,
                productQuantity: element['productQuantity'],
                taxAmount: 0,
                totalAmount: 0,
                documentUom: element.uom ? element.uom.id : null,
                expectedDeliveryDt: element?.expectedDeliveryDt,
                customField: element?.customField?.map((field: any) => {
                  const colKey: any = `${field?.id}`;
                  return {
                    ...field,
                    value:
                      field?.fieldType === 'DATE'
                        ? DateFormatService.getDateStrFromDate(
                            new Date(element[colKey]),
                            BOOKS_DATE_FORMAT['MM/DD/YYYY']
                          )
                        : element[colKey] || field?.value || ''
                  };
                })
              };
              return d;
            }
          }
        );

        setBodyForUpdate((prevState: any) => {
          return {
            ...prevState,
            id: orderWithUpdate['id'],
            currency: orderWithUpdate['currency'],
            purchaseRequestDate: orderWithUpdate['documentDate'],
            shipByDate: orderWithUpdate['dueDate'],
            receiveByDate: orderWithUpdate.receiveByDate,
            purchaseRequestItems: updateItems,
            taxAmount: 0,
            totalAmount: 0,
            unitPriceGstInclusive: false,
            attachmentId: orderWithUpdate.attachmentIds,
            attachments: orderWithUpdate.attachments,
            memo: orderWithUpdate.memo,
            shipTo: orderWithUpdate.shipTo,
            billTo: orderWithUpdate.billTo,
            exchangeRate: orderWithUpdate.exchangeRate,
            customField: orderWithUpdate?.customField,
            documentSequenceCode: orderWithUpdate?.documentSequenceCode || null,
            seqCodeAlreadyDumped: orderWithUpdate?.seqCodeAlreadyDumped || false
          };
        });
      }
  };

  return (
    <RequisitionForm
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      booksDocument={order}
      id={order.id}
      onDocumentUpdate={(x: Document) => handleOrderUpdate(x)}
    />
  );
};

export default NewRequisition;
