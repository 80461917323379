import React, { Fragment, useEffect, useRef, useState } from 'react';
import './NewDocument.scss';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import {
  checkContact,
  checkGSTINPresentForSelectedContact,
  convertToCurrenctExchangeRate,
  getFormattedAddressObj,
  getMainModuleName,
  getNewCFItem,
  getNewColumnForCF,
  getTenantTaxSystem,
  getValidTillDateFromDocDate,
  isGSTExchangeRateRequired,
  rcmAppliedIndia,
  roundingOffStr,
  updateGstType,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo
} from './NewDocumentHelper';
import {
  activeTenantInfo,
  appCustomizationInfo
} from '../../Redux/Slices/AuthSlice';

import { DocumentItem } from '../../Models/DocumentItem';
import {
  AdditionalChargeDetails,
  BillingAddressType,
  Document,
  ShippingAddressType
} from '../../Models/Document';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  REGEX,
  COUNTRY_CODES,
  STATUS_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  FULFILLMENT_TYPE,
  COMPOSTION_TAX_PERCENT,
  SETUP_PAYMENT,
  CURRENCY_PRECISION,
  COMPLAINCE_CURRENCY,
  CURRENCIES,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  UOM_NA_ID,
  PRODUCT_TYPE,
  PRODUCT_OFFERING_TYPE,
  LOCATION_CLASS_ENUM,
  CLASS_ASSIGNMENT,
  INPUT_VIEW_DIRECTION,
  GST_TYPE,
  TRACKING_TYPE,
  COMMON_CONSTANT,
  CUSTOM_FIELD_TYPE,
  MODULE_TYPE,
  PAYMENT_STATUS,
  GOOGLE_NO_TRANSLATE_CLASS
} from '../../Constants/Constant';
import PopupWrapper from '../PopupWrapper';
import {
  BooksAddress,
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  LocationDTO,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKButton,
  showToast,
  showAlert,
  showLoader,
  removeLoader,
  DKDataGrid,
  INPUT_TYPE,
  DKCalendar,
  DKListPicker2,
  TOAST_TYPE,
  DKInput
} from 'deskera-ui-library';
import { TAX_SYSTEM } from '../../Constants/Constant';
import FADocumentSummaryView from './FADocumentSummaryView';
import CustomNumberFormatInput from '../CustomNumberFormat/CustomNumberFormatInput';
import { useTranslation } from 'react-i18next';
import DateFormatService from '../../Services/DateFormat';
import { addDays, subDays } from 'date-fns';
import UpdateAddress from './UpdateAddress';
import NumberFormatService from '../../Services/NumberFormat';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import {
  fetchTaxes,
  selectPurchaseTax,
  selectSalesTax,
  fetchUOMs,
  selectUOMs,
  selectCurrencyListWithExchangeRate,
  selectIRPData,
  selectIsLoadingPopupWrapper,
  fetchClassesByDimensionId,
  selectProductCustomFields,
  fetchProductCustomFields,
  selectFACustomFields
} from '../../Redux/Slices/CommonDataSlice';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { FAItemTaxCalculator } from './FAItemTaxCalculator';
import { createLineItem } from './NewDocumentHelper';
import CreateProductView from '../../Components/Product/CreateProductView';
import AddTax from '../../Components/Settings/Tax/AddTax';
import AddContact from '../../Components/Contacts/AddContact';
import AttachmentService from '../../Services/Attachment';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import {
  fetchContacts,
  selectContacts
} from '../../Redux/Slices/ContactsSlice';
import {
  fetchProductsWithVariants,
  selectProductsWithVariants
} from '../../Redux/Slices/ProductsSlice';
import { DraftTypes } from '../../Models/Drafts';
import TaxService from '../../Services/Tax';
import OrganisationProfileForm from '../../Components/Settings/OrganisationProfile/OrganisationProfileForm';
import TDSCalculation from './TDSCalculation';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../Models/TDS';
import {
  fetchPaymentOptions,
  selectInvoicesColumnConfig,
  selectInvoicesColumnConfigTableId,
  selectPaymentOptions
} from '../../Redux/Slices/InvoicesSlice';
import GetEmailForPayment from './GetEmailForPayment';
import ItcOptions from './ItcOptions';
import { GSTExchangeRateDialog } from './GSTExchangeRateDialog';
import AuthService from '../../Services/Auth';
import PriceListService from '../../Services/PriceList';
import {
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import {
  selectQuotesColumnConfig,
  selectQuotesColumnConfigTableId
} from '../../Redux/Slices/QuotesSlice';
import {
  selectPurchaseOrdersColumnConfig,
  selectPurchaseOrdersColumnConfigTableId
} from '../../Redux/Slices/PurchaseOrdersSlice';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import {
  getInvoiceNowStatus,
  getUpdatedInvoiceObject,
  INVOICE_NOW_TYPES
} from '../../Components/Invoices/InvoiceHelper';
import EInvoiceService from '../../Services/EInvoice';
import { IRPCredPopup } from '../../Components/Settings/OrganisationProfile/IRPCredPopup';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { removeDraft } from '../../Redux/Slices/DraftsSlice';
import InvoiceService from '../../Services/Invoice';
import CancelEInvoice from '../../Components/Settings/OrganisationProfile/CancelEInvoice';
import {
  fetchCategoryDimensions,
  locationsData,
  selectDimensions
} from '../../Redux/Slices/LocationSlice';
import AddClass from '../../Components/Settings/Classes/AddClass';
import LocationService from '../../Services/Location';
import DocumentActionMenu from './DocumentActionMenu';
import AllocateLandedCost from './AllocateLandedCost';
import {
  CONTACT_FORM_TAB,
  ORGANISATION_FORM_TAB,
} from '../../Constants/Enum';
import { INDIAN_STATES_MOCK_DATA } from '../../Constants/StaticData';
import HSNForm from './HSNForm';
import { Store } from '../../Redux/Store';
import GSTValueForm from './GSTValueEdit';
import ic_inventory_pending from '../../Assets/Icons/ic_inventory_pending.svg';
import ic_inventory_fulfill from '../../Assets/Icons/ic_inventory_fulfill.svg';
import ProductService from '../../Services/Product';
import ReserveStockPopUp from '../../Components/Invoices/ReserveStockPopUp';
import QuotationService from '../../Services/Quotation';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import {
  selectSalesOrderColumnConfig,
  selectSalesOrderColumnConfigTableId
} from '../../Redux/Slices/SalesOrderSlice';
import AutoChargePopup from './AutoChargePopup';
import SalesOrderService from '../../Services/SalesOrder';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import { localizedText } from '../../Services/Localization/Localization';
import AccountsService from '../../Services/Accounts';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import { makePaymentMethod } from '../../Models/PaymentMethod';

import BarcodeIcon from '../../Assets/ic_barcode.png';
import { BarcodePopup } from '../BarcodePopup';
import BarcodeSearchPopup from '../../Components/BarcodeSerach/BarcodeSearchPopUp';
import CustomFieldSettings from '../CustomFieldsHolder/CustomFieldSettings';
import CustomFieldService from '../../Services/CustomField';
import {
  selectWorkOutsColumnConfig,
  selectWorkOutsColumnConfigTableId
} from '../../Redux/Slices/WorkOutSlice';
import { initialRowData } from '../../Models/PriceList';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import {
  selectAdditionalBuyCharges,
  selectAddtionalSellCharges
} from '../../Redux/Slices/AdditionalChargesSlice';
import AddAdditionalCharges from '../../Components/Invoices/AddAdditionalCharges';
import { FADocumentItem } from '../../Models/FADocumentItem';
import {
  fetchAssetGroups,
  fetchAssets,
  selectAssetGroups,
  selectFixedAssets
} from '../../Redux/Slices/AssetSlice';
import FixedAssetDetails from './FixedAssetDetails';
import CommonStateListPicker from './CommonStateListPicker';
import ReCheckButton from '../ReCheckButton';
import { COUNTRIES_WITH_CURRENCIES } from '../../Components/PriceBook/CountriesWithCurrencies';

export interface NewDocumentProps2 {
  booksDocument: Document;
  onDocumentUpdate: (document: Document) => void;
  isCenterAlign: boolean;
  documentMode?: DOCUMENT_MODE;
  draftData?: any;
  draftType?: any;
  canValidate?: any;
  isDocumentLoading?: boolean;
  updateDocWithoutClosing?: () => void;
  permissionKeys: any;
  autoProcessDoc?: boolean;
}

interface TotalCalculationDeps {
  subTotal: number;
  totalWithDiscount: number;
  tax: number;
  discount: number;
  tdsAmount: number;
  tcsAmount: number;
}

export default function NewFADocument(props: NewDocumentProps2) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const lastUpdatedIndex = useRef<any>(null);
  const isDocDateUpdatedManually = useRef<boolean>(false);
  const isContactChangedManually = useRef<boolean>(false);
  const isQuantityChangedManually = useRef<boolean>(false);
  const isUOMChangedManually = useRef<boolean>(false);
  const usTaxCalculateAlertVisible = useRef<boolean>(false);
  const [isTDSDeducted, setIsTDSDeducted] = useState(false);
  const shippingAddressBlock = useRef<any>(null);

  const invoiceColumnConfig = useAppSelector(selectInvoicesColumnConfig);
  const invoiceConfigTableId = useAppSelector(
    selectInvoicesColumnConfigTableId
  );
  const billColumnConfig = useAppSelector(selectBillsColumnConfig);
  const billConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  const quoteColumnConfig = useAppSelector(selectQuotesColumnConfig);
  const quoteConfigTableId = useAppSelector(selectQuotesColumnConfigTableId);
  const salesOrderColumnConfig = useAppSelector(selectSalesOrderColumnConfig);
  const salesOrderConfigTableId = useAppSelector(
    selectSalesOrderColumnConfigTableId
  );
  const poColumnConfig = useAppSelector(selectPurchaseOrdersColumnConfig);
  const jobWorkOutColumnConfig = useAppSelector(selectWorkOutsColumnConfig);
  const poConfigTableId = useAppSelector(
    selectPurchaseOrdersColumnConfigTableId
  );
  const jobWorkOutConfigTableId = useAppSelector(
    selectWorkOutsColumnConfigTableId
  );
  const IRPData = useAppSelector(selectIRPData);
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const locationData = useAppSelector(locationsData);
  const productCFfromStore = useAppSelector(selectFACustomFields);

  /** Additinoal Charges - selectors -START */
  const addtionalBuyCharges = useAppSelector(selectAdditionalBuyCharges);
  const additionalSellCharges = useAppSelector(selectAddtionalSellCharges);
  const [checkifCustomFieldCanEdit, setCheckifCustomFieldCanEdit] =
    useState(true);
  /** Additinoal Charges - selectors -END */

  const popupBtnConfig: BtnType[] = [
    {
      title: t(`DOCUMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`DOCUMENT.BUTTON.UPDATE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.UPDATE_ADDRESS
    }
  ];

  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];

  const loadingIRPCredPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Saving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };
  const editAddressRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const currentDate = new Date();
  const laterDate = addDays(currentDate, 30);

  /* document level states */
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    props.isCenterAlign
  );
  const [booksDocument, setBooksDocument] = useState<any>(props.booksDocument);
  const [roundOffDirty, setRoundOffDirty] = useState<any>(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY
      ? true
      : false
  );
  const [dueAmount, setDueAmount] = useState<number>(
    (props.booksDocument.isPartialInvoice
      ? props.booksDocument.totalAmount
      : props.booksDocument.dueAmount) || 0
  );

  /* contact panel states  */
  const contactsData = useAppSelector(selectContacts);
  const [openContactSelector, setOpenContactSelector] = useState(false);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const [contact, setContact] = useState<any>(null);
  // const [showAddressPopup, setShowAddressPopup] = useState<boolean>(false);
  const [showUpdateAddressPopup, setShowUpdateAddressPopup] =
    useState<boolean>(false);
  const [booksAddressType, setBooksAddressType] = useState<BOOKS_ADDRESS_TYPES>(
    BOOKS_ADDRESS_TYPES.NONE
  );
  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType | null>(null);
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddressType | null>(null);
  const [editModeBillingAddresses, setEditModeBillingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.billingAddress);
  const [editModeShippingAddresses, setEditModeShippingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.shippingAddress);

  // setup payment states
  const [setupPaymentListOpen, setSetupPaymentListOpen] =
    useState<boolean>(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();
  const [showEmailPopupForPayment, setShowEmailPopupForPayment] =
    useState<boolean>(false);

  /* right info panel states */
  const [customNumberFormatModule, setCustomNumberFormatModule] = useState('');
  const [invoiceDateOpen, setInvoiceDateOpen] = useState(false);
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const [eInvoiceData, seteInvoiceData] = useState<any>(props.booksDocument);
  const [shipByDateOpen, setShipByDateOpen] = useState(false);
  const [shipByDate, setShipByDate] = useState<Date>(laterDate);
  const [dueDateOpen, setDueDateOpen] = useState(false);
  const [dueDate, setDueDate] = useState<Date>(laterDate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const paymentOptions = useAppSelector(selectPaymentOptions);
  const accountList = useAppSelector(selectedAccounts);
  const [paymentAccountGroupsOptions, setPaymentAccountGroupsOptions] =
    useState<any[]>([]);

  /* Grid & summary states */
  const productsData = useAppSelector(selectProductsWithVariants);
  const assetGroupData = useAppSelector(selectAssetGroups);
  const fixedAssetData = useAppSelector(selectFixedAssets);
  const uomsData = useAppSelector(selectUOMs);
  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const [columnConfig, setColumnConfig] = useState(
    DocumentConfigManager.get(props.booksDocument.documentType)
  );
  const [productRows, setProductRows] = useState<FADocumentItem[]>([]);
  const [assetGroupRows, setAssetGroupRows] = useState<FADocumentItem[]>([]);
  const [barcodeSearchData, setBarcodeSearchData] = useState<any[]>([]);
  const [barcodeCalculation, setBarcodeCalculation] = useState(false);

  const salesTaxes = useAppSelector(selectSalesTax);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const dimensionData = useAppSelector(selectDimensions);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [showTDSCalculationPopup, setShowTDSCalculationPopup] = useState(false);
  const [showITCPopup, setShowITCPopup] = useState(false);
  const [showIRPCredPopup, setShowIRPCredPopup] = useState(false);
  const [showCancelEInvoice, setShowCancelEInvoice] = useState(false);
  const [showLandedCostPopup, setShowLandedCostPopup] = useState(false);
  const addTaxRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const tdsAmountRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [currentRowTDSInfo, setCurrentRowTDSInfo] = useState<any>(null);
  const [currentRowITCInfo, setCurrentRowITCInfo] = useState<any>(null);
  const [currentRowHSNInfo, setCurrentRowHSNInfo] = useState<any>(null);
  const [showHSNPopup, setShowHSNPopup] = useState(false);
  const [currentRowGSTInfo, setCurrentRowGSTInfo] = useState<any>(null);
  const [currentRowData, setCurrentRowData] = useState<any>(null);
  const [showGSTPopup, setShowGSTPopup] = useState(false);

  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(-1); // used only for tracking addition of new product
  const [contactMode, setContactMode] = useState(DOCUMENT_MODE.NEW);
  const [showTaxExchangeRatePopup, setShowTaxExchangeRatePopup] =
    useState(false);
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const [existingLineNumbers, setExistingLineNumbers] = useState<number[]>([]);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();
  const [ignoreCalculateUSTax, setIgnoreCalculateUSTax] = useState(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY ||
      props.booksDocument.isPartialBill ||
      props.booksDocument.isPartialInvoice
  );
  const [paymentOptionState, setPaymentOptionState] = useState<any>();
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const [currentRowLandedCostInfo, setCurrentRowLandedCostInfo] =
    useState<any>(null);

  const [openBillingAddressList, setOpenBillingAddressList] = useState(false);
  const [openShippingAddressList, setOpenShippingAddressList] = useState(false);
  const [openShipFromForBuy, setOpenShipFromForBuy] = useState(false);
  const [activeContactTab, setActiveContactTab] = useState<CONTACT_FORM_TAB>(
    CONTACT_FORM_TAB.GENERAL_INFO
  );
  const [activeOrgProfileTab, setActiveOrgProfileTab] =
    useState<ORGANISATION_FORM_TAB>(ORGANISATION_FORM_TAB.GENERAL_INFO);
  const [needToUpdateAddresses, setNeedToUpdateAddresses] = useState(
    props.booksDocument?.needToUpdateAddresses ? true : false
  );
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const [indianStatesOptions, setIndianStatesOptions] = useState<any>([]);
  const [pos, setPos] = useState<any>([]);
  const [dos, setDos] = useState<any>([]);
  const [showAutoChargePopup, setShowAutoChargePopup] = useState(false);
  const [hasShownProductLimitAlert, setHasShownProductLimitAlert] =
    useState(false);

  const [lineItemsTouched, setLineItemsTouched] = useState(false);
  const [dropshipShipToContact, setDropshipShipToContact] = useState<string>();

  const [productStockData, setProductStockData] = useState<any>({});
  const [showReserveStockPopup, setShowReserveStockPopup] = useState(false);
  const [showBarcodePopup, setShowBarcodePopup] = useState(false);
  const [shipToOrFromAddressChangedForUS, setShipToOrFromAddressChangedForUS] =
    useState(false);
  const [openProductCFSettings, setOpenProductCFSettings] = useState(false);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [filteredTaxList, setFilteredTaxList] = useState<any[]>([]);
  const [showAssetDetailsForm, setShowAssetDetailsForm] = useState(false);

  useEffect(() => {
    dispatch(fetchAssetGroups({ searchTerm: '', query: 'active' }));
    dispatch(fetchAssets({ searchTerm: '', query: 'active' }));
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };
  
  useEffect(() => {
    if (lastUpdatedIndex && lastUpdatedIndex.current !== null) {
      let index = lastUpdatedIndex.current as number;
      lastUpdatedIndex.current = null;
      calculateTaxAfterProductRowsUpdate(index);
    }

    // if (
    //   lastUpdatedIndex.current !== null &&
    //   !Utility.isEmpty(productRows[lastUpdatedIndex.current as any]?.product) &&
    //   Utility.isEmpty(barcodeSearchData)
    // ) {
    //   let index = lastUpdatedIndex.current as number;
    //   lastUpdatedIndex.current = null;
    //   calculateTaxAfterProductRowsUpdate(index);
    // } else {
    //   setBarcodeSearchData([]);
    // }
    // if (!hasShownProductLimitAlert) {
    //   if (productRows.length >= 50) {
    //     setHasShownProductLimitAlert(true);
    //     showAlert(
    //       'Warning',
    //       'Application may behave slower as there are more than 50 line items in the form.'
    //     );
    //   }
    // }
  }, [productRows]);

  const calculateTaxAfterProductRowsUpdate = (
    index: number,
    isBarcode?: boolean | undefined
  ) => {
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      lastUpdatedColumn &&
      lastUpdatedColumn !== 'taxAmount'
    ) {
      calculateUSTax(index);
    } else {
      setLastUpdatedColumn(null);
      return calculateFATaxesAndAmount(index, undefined, isBarcode);
    }
  };

  useEffect(() => {
    updateConfig();
  }, [
    productsData,
    salesTaxes,
    purchaseTaxes,
    uomsData,
    productRows,
    selectedProductPriceList,
    selectedProductUOMS,
    dimensionData,
    productCFfromStore,
    props.draftType,
    props.documentMode
  ]);

  const updateTaxList = () => {
    let taxData = Utility.isSalesDocument(props.booksDocument)
      ? salesTaxes
      : purchaseTaxes;
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          documentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          documentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          documentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  useEffect(() => {
    if (documentDate && documentDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          documentDate: DateFormatService.getDateStrFromDate(
            documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
      if (isDocDateUpdatedManually.current) {
        const updatedValidTillDate = getValidTillDateFromDocDate(
          documentDate,
          contact
        );
        setDueDate((prevState) =>
          updatedValidTillDate ? updatedValidTillDate : prevState
        );
        setShipByDate(documentDate);

        if (productRows.length && isDocDateUpdatedManually.current) {
          //Update tax object on line item level for new tax system of sg
          let mappedProductRows = [...productRows];
          if (getTenantTaxSystem() === TAX_SYSTEM.SG) {
            mappedProductRows = mappedProductRows.map(
              (item: any, index: number) => {
                if (item && Object.keys(item.product).length !== 0) {
                  let taxObject = getTax(item?.product);
                  let copyItem = { ...item, tax: taxObject };
                  FAItemTaxCalculator.item = copyItem;
                  FAItemTaxCalculator.setInitialValues();
                  const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
                  FAItemTaxCalculator.item.taxAmount = taxAmount;
                  FAItemTaxCalculator.updateCalculatedValues();
                  return {
                    ...FAItemTaxCalculator.item,
                    taxCode: taxObject?.code,
                    taxName: taxObject?.name
                  };
                } else {
                  return item;
                }
              }
            );
            setProductRows(mappedProductRows);
            setBooksDocument((prevState: any) => {
              return {
                ...prevState,
                items: mappedProductRows
              };
            });
          }

          const productsPayload = mappedProductRows
            .filter((item) => !Utility.isEmpty(item.product))
            .map((item) => ({
              productId: item.product.productId,
              uomId: item.documentUom,
              quantity: +item.productQuantity
            }));
          getPricing(
            productsPayload,
            mappedProductRows,
            booksDocument.currency,
            true,
            booksDocument.exchangeRate,
            { dateChanged: true }
          );
        }

        if (
          getTenantTaxSystem() === TAX_SYSTEM.US &&
          Utility.isSalesDocument(props.booksDocument)
        ) {
          calculateUSTax(undefined);
        }
      }
    }
  }, [documentDate]);

  useEffect(() => {
    if (shipByDate && shipByDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          fulfillmentDate: DateFormatService.getDateStrFromDate(
            shipByDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
  }, [shipByDate]);

  useEffect(() => {
    if (dueDate && dueDate instanceof Date) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          validTillDate: DateFormatService.getDateStrFromDate(
            dueDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        };
      });
    }
  }, [dueDate]);

  useEffect(() => {
    setIsCenterAlign(props.isCenterAlign);
    /**
     * Custom number format module
     */
    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.INVOICE
        );
        break;
      case DOC_TYPE.BILL:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.BILL
        );
        break;
      case DOC_TYPE.FA_BILL:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.BILL
        );
        break;
      case DOC_TYPE.FA_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER
        );
        break;
      case DOC_TYPE.ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER
        );
        break;
      case DOC_TYPE.QUOTE:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.QUOTE
        );
        break;
      case DOC_TYPE.SALES_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.SELL
            : CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER
        );
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        setCustomNumberFormatModule(
          !tenantInfo.useSeparateSequenceFormat
            ? CUSTOM_NUMBER_INPUT_MODULES.BUY
            : CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT
        );
        break;
    }
    seteInvoiceData(props.booksDocument);

    if (props.draftData.actionFromDocument) {
      // Evaluate taxSystem and gstType
      setBooksDocument({
        ...props.booksDocument,
        taxSystem: getTenantTaxSystem(),
        gstType: booksDocument.gstType
      });
      setTimeout(() => {
        if (props.booksDocument.items) {
          const lineItems = [...props.booksDocument.items];
          if (lineItems && lineItems.length > 0) {
            lineItems.forEach((rowData, index) => {
              calculateFATaxesAndAmount(index, lineItems);
              calculateAndSetUomRelatedDataOnEdit(index, lineItems);
            });
          }
        }
      }, 100);
      if (
        typeof props.booksDocument?.dueAmount !== 'undefined' &&
        typeof props.booksDocument?.dueAmount === 'number'
      ) {
        setDueAmount(props.booksDocument.dueAmount);
      }
    }
    if (
      booksDocument?.paymentStatus !== undefined &&
      booksDocument?.paymentStatus !== null &&
      props.booksDocument.documentType === DOC_TYPE.FA_BILL
    ) {
      setCheckifCustomFieldCanEdit(
        !(booksDocument.paymentStatus !== PAYMENT_STATUS.PENDING)
      );
    }
  }, [props.booksDocument]);

  useEffect(() => {
    if (eInvoiceData) {
      getInvoiceNow(eInvoiceData);
    }
  }, [eInvoiceData]);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  const calculateAndSetUomRelatedDataOnEdit = (
    rowIndex: number,
    unsavedRows?: any
  ) => {
    let item = unsavedRows[rowIndex];
    let clonedItem: any = {};

    let unitPrice = item.unitPrice;

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      (item.isPartialInvoice || item.isPartialBill) &&
      item.product.stockUom === UOM_NA_ID
    ) {
      unitPrice = item.pendingAmount;
    }

    const pendingQuantity = item.pendingQuantity;
    let finalQty;
    if (item.isPartialInvoice && !item.id) {
      finalQty = pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else if (item.isPartialBill && !item.id) {
      const receivedButNotBilled = Utility.receivedButNotBilledQuantity(item);
      finalQty =
        receivedButNotBilled > 0 && receivedButNotBilled < pendingQuantity
          ? receivedButNotBilled
          : pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else {
      finalQty = item.productQuantity;
    }

    item.productQuantity = finalQty;
    item.unitPrice = unitPrice;

    item.uom = getSelectedUomForEdit(item);
    if (item.documentUOMSchemaDefinition) {
      item.unitPrice = item.uomUnitPrice || item.unitPrice;
      item.productQuantity = item.uomQuantity || item.productQuantity;
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      clonedItem = deepClone(item);
    }

    FAItemTaxCalculator.item = item;
    if (!FAItemTaxCalculator.item.taxAmount) {
      FAItemTaxCalculator.item.taxAmount = 0;
    }
    FAItemTaxCalculator.tenantInfo = tenantInfo;
    FAItemTaxCalculator.setInitialValues();
    const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
    FAItemTaxCalculator.item.taxAmount = taxAmount;
    FAItemTaxCalculator.updateCalculatedValues();
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      (props.documentMode !== DOCUMENT_MODE.NEW ||
        props.booksDocument.isPartialInvoice ||
        !Utility.isEmpty(contact))
    ) {
      let row = { ...unsavedRows[rowIndex] };
      setGSTValueManual(rowIndex, clonedItem, clonedItem);
    }

    unsavedRows[rowIndex] = { ...FAItemTaxCalculator.item };
    lastUpdatedIndex.current = rowIndex;
    setProductRows([...unsavedRows]);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...unsavedRows]
      };
    });
  };

  const getLineItemsWithUpdatedColumnConfig = (
    tmpItems: any[],
    existingCFs?: any[]
  ) => {
    let items = deepClone(tmpItems);
    let productCFs: any[] = [];
    if (existingCFs && existingCFs.length) {
      productCFs = [...existingCFs];
    } else if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      productCFs = [...productCFfromStore?.content];
    }
    productCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );
    if (!Utility.isEmpty(productCFs)) {
      let updatedConfigs = [...columnConfig].filter(
        (field: any) => !field.isCustomField
      );

      productCFs?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(
          prodCF,
          props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY
        );
        updatedConfigs.push(newItem);
      });

      if (
        props.documentMode !== DOCUMENT_MODE.NEW &&
        !Utility.isEmpty(productCFs)
      ) {
        items?.forEach((lineItem: any, lineItemIndex: any) => {
          lineItem?.customField?.forEach((item: any, index: any) => {
            let filteredCF: any = productCFs?.find(
              (cf: any) => cf.id === item.id
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW &&
                  props.draftType !== DraftTypes.READONLY
              );

              let alreadyPresentConfig = updatedConfigs?.find(
                (col: any) => col.id === item.id
              );
              if (Utility.isEmpty(alreadyPresentConfig)) {
                updatedConfigs.push(newItem);
              }

              if (filteredCF) {
                if (
                  typeof item.value !== 'undefined' &&
                  item.value !== null &&
                  item.value !== ''
                ) {
                  if (
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    items[lineItemIndex][item.id] =
                      DateFormatService.getDateFromStr(
                        item.value,
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      );
                  } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                    const tempCF = filteredCF?.attributes?.find(
                      (attr: any) => attr.code === item.value
                    );
                    if (tempCF) {
                      items[lineItemIndex][item.id] = tempCF.value;
                    }
                  } else {
                    items[lineItemIndex][item.id] = item.value;
                  }
                } else {
                  items[lineItemIndex][item.id] = '';
                }
              }
            }
          });
        });
      }
      return { items, updatedConfigs };
    } else {
      return { items: null, updatedConfigs: [...columnConfig] };
    }
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let updatedLineItemsWithConfigs = getLineItemsWithUpdatedColumnConfig(
      [...booksDocument.items],
      updatedCFs
    );
    const classData = dimensionData?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    let updatedConfigs = [...updatedLineItemsWithConfigs.updatedConfigs];
    updatedConfigs = updatedConfigs.map((cf: any) => {
      if (cf.id === classData.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(updatedConfigs.filter((col: any) => !col.hidden));
  };

  const getLineItemsWithNonEditableCells = (lineItems: any[]) => {
    lineItems = lineItems.map((item: any) => {
      let updatedItem = { ...item, isTaxable: true };
      updatedItem.nonEditableColumns = [];
      if (
        updatedItem.assetDetails !== null &&
        updatedItem.assetDetails !== undefined &&
        updatedItem.assetDetails.length > 0
      ) {
        updatedItem.nonEditableColumns.push('fixedAssetGroup');
        updatedItem.nonEditableColumns.push('quantity');
        updatedItem.nonEditableColumns.push('productQuantity');
        updatedItem.nonEditableColumns.push('unitPrice');
        updatedItem.nonEditableColumns.push('discount');
        updatedItem.nonEditableColumns.push('tax');
      }

      //   if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
      //     updatedItem = {
      //       ...updatedItem,
      //       isTaxable: updatedItem.product
      //         ? updatedItem?.product.exemptedMalaysiax
      //         : true
      //     };
      //   }
      //   if (
      //     (typeof updatedItem?.product?.taxPreference !== 'undefined' &&
      //       updatedItem?.product?.taxPreference !== null &&
      //       !updatedItem?.product?.taxPreference) ||
      //     !updatedItem.isTaxable
      //   ) {
      //     updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
      //       ? [...updatedItem.nonEditableColumns].filter(
      //           (field) => field !== 'tax'
      //         )
      //       : [];
      //     updatedItem?.nonEditableColumns?.push('tax');
      //   }
      return updatedItem;
    });

    return lineItems;
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    setIsCenterAlign(props.isCenterAlign);

    // Add a blank row for new document
    if (props.documentMode === DOCUMENT_MODE.NEW && !productRows.length) {
      addNewAssetGroup();
    }

    let lineItems = [...(props.booksDocument?.items as DocumentItem[])];

    if (lineItems?.length && props.documentMode === DOCUMENT_MODE.COPY) {
      setExistingLineNumbers(lineItems?.map((item) => item.lineNumber));
    }

    let updatedLineItemsWithConfigs = getLineItemsWithUpdatedColumnConfig([
      ...lineItems
    ]);

    if (updatedLineItemsWithConfigs?.items) {
      lineItems = [...updatedLineItemsWithConfigs.items];
    }

    if (updatedLineItemsWithConfigs?.updatedConfigs) {
      setColumnConfig([...updatedLineItemsWithConfigs?.updatedConfigs]);
    }

    lineItems = getLineItemsWithNonEditableCells(lineItems);

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let states: ReactOptionType[] = [];
      const indianStates = INDIAN_STATES_MOCK_DATA;
      indianStates.forEach((state) => {
        let option: ReactOptionType = {
          label: state.name,
          value: state.code,
          code: state.stateCode,
          searchableKey: `${state.name} ${state.stateCode}`
        };
        states.push(option);
      });
      setIndianStatesOptions(states);
    }

    // updateConfig();
    if (props.documentMode) {
      // Don't show tooltip in VIEW mode
      DocumentConfigUtility.documentMode = props.documentMode;
    }
    // Set doc type in dicument config utility
    DocumentConfigUtility.documentType = props.booksDocument.documentType;

    if (lineItems && lineItems.length > 0) {
      lineItems.forEach((rowData, index) => {
        calculateFATaxesAndAmount(index, [...(lineItems as DocumentItem[])]);
        calculateAndSetUomRelatedDataOnEdit(index, [
          ...(lineItems as DocumentItem[])
        ]);
      });
    }

    if (
      props.documentMode !== DOCUMENT_MODE.NEW &&
      props.booksDocument.documentType === DOC_TYPE.INVOICE &&
      !Utility.isEmpty(props.booksDocument?.paymentInformation)
    ) {
      setSelectedPaymentOption(props.booksDocument.paymentInformation);
    }

    let documentUpdates: any = {};
    if (!Utility.isEmpty(props.booksDocument.contact)) {
      if (
        (props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
        Utility.isSalesDocument(props.booksDocument) &&
        props.draftType === DraftTypes.DRAFT
      ) {
        setDetailedContact(props.booksDocument.contact.id);
      } else {
        setContact(props.booksDocument.contact);
      }
    } else {
      // Set default compliance fields
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        documentUpdates.gstType = Utility.getIndiaDefaultTaxType(null, null);
      }
    }

    if (
      props.documentMode === DOCUMENT_MODE.NEW &&
      !Utility.isFaDropship(props.booksDocument)
    ) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(true);
      if (billingAddress) {
        documentUpdates.billTo = billingAddress;
      }
      if (shippingAddress) {
        documentUpdates.shipTo = shippingAddress;
      }
    }

    if (props.booksDocument.documentDate) {
      setDocumentDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.fulfillmentDate) {
      setShipByDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.fulfillmentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (props.booksDocument.validTillDate) {
      setDueDate(
        DateFormatService.getDateFromStr(
          props.booksDocument.validTillDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
    }

    if (
      props.draftType === DraftTypes.DRAFT &&
      props.booksDocument.attachments
    ) {
      const newAttachments: any[] = props.booksDocument.attachments.map(
        (attachment: any) => JSON.parse(attachment)
      );
      setAttachments(newAttachments);
    } else {
      fetchAttachments();
    }

    if (!contactsData?.content) {
      dispatch(fetchContacts());
    }

    // if (!productsData?.content) {
    dispatch(
      fetchProductsWithVariants({
        searchTerm: '',
        query: 'active=true,hasVariants=false'
      })
    );
    // }

    if (!salesTaxes?.length || !purchaseTaxes?.length) {
      dispatch(fetchTaxes());
    }

    if (!uomsData?.length) {
      dispatch(fetchUOMs);
    }

    if (
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY) &&
      props.draftData?.data?.isCashInvoice &&
      props.booksDocument.documentType === DOC_TYPE.INVOICE &&
      !paymentAccountGroupsOptions.length
    ) {
      loadAccountGroups(props.booksDocument.currency || tenantInfo.currency);
    }

    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        ...documentUpdates,
        currency: booksDocument.currency
          ? booksDocument.currency
          : tenantInfo.currency,
        currencyCode: booksDocument.currencyCode
          ? booksDocument.currencyCode
          : tenantInfo.currency,
        taxSystem: getTenantTaxSystem(),
        documentDate: props.booksDocument.documentDate
          ? props.booksDocument.documentDate
          : DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        fulfillmentDate: props.booksDocument.fulfillmentDate
          ? props.booksDocument.fulfillmentDate
          : DateFormatService.getDateStrFromDate(
              currentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        validTillDate: props.booksDocument.validTillDate
          ? props.booksDocument.validTillDate
          : DateFormatService.getDateStrFromDate(
              laterDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
        amountToReceiveOrPay: props.booksDocument.totalAmount,
        totalAmount: props.booksDocument.totalAmount
      };
    });

    if (props.booksDocument.dropShip) {
      getLinkedDocumentDetailsForDropship(props.booksDocument.linkedDocuments);
    }

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onMouseUp = (e: any) => {
    const target = e.target;
    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setopenCustomNumberList(false);
    }
  };

  const getLinkedDocumentDetailsForDropship = (linkDocument: any) => {
    const code = linkDocument ? linkDocument[0]?.documentCode : '';
    if (code) {
      let docType = linkDocument ? linkDocument[0]?.documentType : '';
      if (docType === DOC_TYPE.QUOTE) {
        QuotationService.getQuoteByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
              setBooksDocument((prevState: any) => ({
                ...prevState,
                dropShipName: data?.contact?.name || ''
              }));
            }
          },
          (err) => {
            console.error('Error loading detailed invoice: ', err);
          }
        );
      } else if (docType === DOC_TYPE.SALES_ORDER) {
        SalesOrderService.getSalesOrderByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
              setBooksDocument((prevState: any) => ({
                ...prevState,
                dropShipName: data?.contact?.name || ''
              }));
            }
          },
          (err) => {
            console.error('Error loading detailed SO: ', err);
          }
        );
      } else if (docType === DOC_TYPE.ORDER) {
        PurchaseOrderService.fetchOrderDetails(code).then(
          (data: any) => {
            if (data) {
              if (!Utility.isEmpty(data.linkedDocuments)) {
                getLinkedDocumentDetailsForDropship(data.linkedDocuments);
              } else {
                let contactName = data?.contact?.name || contact.name;
                setDropshipShipToContact(contactName);
                setBooksDocument((prevState: any) => ({
                  ...prevState,
                  dropShipName: data?.contact?.name || ''
                }));
              }
            }
          },
          (err) => {
            console.error('Error loading detailed SO: ', err);
          }
        );
      } else {
        InvoiceService.getInvoiceByCode(code).then(
          (data: any) => {
            if (data) {
              let contactName = data?.contact?.name || contact.name;
              setDropshipShipToContact(contactName);
              setBooksDocument((prevState: any) => ({
                ...prevState,
                dropShipName: data?.contact?.name || ''
              }));
            }
          },
          (err) => {
            console.error('Error loading detailed invoice: ', err);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(contact)) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(false);

      const updateAddresses =
        Utility.isEmpty(booksDocument.contact) ||
        booksDocument.contactCode !== contact.code ||
        needToUpdateAddresses ||
        booksDocument.isPartialInvoice ||
        booksDocument.isPartialBill;

      let tempDocument = {
        ...booksDocument,
        contact: contact,
        contactCode: contact.code
      };

      if (
        props.documentMode === DOCUMENT_MODE.NEW ||
        (props.documentMode === DOCUMENT_MODE.COPY &&
          isContactChangedManually.current)
      ) {
        const contactCurrency = activeMultiCurrencyList.find(
          (currency) => currency.currencyCode === contact.currencyCode
        );
        if (
          typeof contactCurrency !== 'undefined' &&
          contactCurrency !== null
        ) {
          let currencyUpdatedDoc = updateCurrencyAndExchangeRate(
            tempDocument,
            contactCurrency.currencyCode,
            contactCurrency.currencyExchangeRate,
            false
          );
          tempDocument = {
            ...tempDocument,
            ...currencyUpdatedDoc
          };
        }

        if (
          tenantInfo.multicurrencyEnabled ||
          isGSTExchangeRateRequired(getTenantTaxSystem(), tenantInfo.currency)
        ) {
          if (COMPLAINCE_CURRENCY[AuthService.userDetails?.country]) {
            const currencyDetails = activeMultiCurrencyList.find(
              (x: any) =>
                x.currencyCode ===
                COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
            );
            if (currencyDetails) {
              const gstExchangeRate = currencyDetails.currencyExchangeRate;
              tempDocument = {
                ...tempDocument,
                gstExchangeRate: 1 / gstExchangeRate
              };
            }
          }
        }
      }

      // Check for opening tax exchange rate popup
      if (
        !isTenantAndCurrencySg() &&
        showGstCurrencyTax() &&
        props.documentMode !== DOCUMENT_MODE.VIEW &&
        !Utility.isEmpty(contact)
      ) {
        let currencyCode = contact.currencyCode;
        if (isGSTExchangeRateRequired(getTenantTaxSystem(), currencyCode)) {
          setShowTaxExchangeRatePopup(true);
        }
      }

      if (!tempDocument.documentDate)
        tempDocument.documentDate = DateFormatService.getDateStrFromDate(
          currentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );

      tempDocument = checkContact(
        { ...tempDocument },
        { ...contact },
        updateAddresses
      );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (Utility.isSalesDocument(props.booksDocument)) {
          let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
            ? tempDocument?.shipTo?.placeOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;
          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
          );
          setPos(pos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
        if (
          props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
          props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
          props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
          Utility.isFaDropship(props.booksDocument)
        ) {
          let posState: any = !Utility.isEmpty(
            tempDocument?.shipFrom?.placeOfSupply
          )
            ? tempDocument?.shipFrom?.placeOfSupply
            : tempDocument?.shipFrom?.state || shippingAddress?.state;

          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
          );
          setPos(pos[0]);
          let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null;
          let shipFromAddress = {
            ...shipFrom,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipFrom: shipFromAddress };

          let dosState: any = !Utility.isEmpty(
            tempDocument?.shipTo?.destinationOfSupply
          )
            ? tempDocument?.shipTo?.destinationOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;

          let dos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
          );
          setDos(dos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            destinationOfSupply: dos ? dos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
      }
      // Keep the shipTo value if document is not new
      // if (props.documentMode !== DOCUMENT_MODE.NEW) {
      //   tempDocument = {
      //     ...tempDocument,
      //     shipTo: booksDocument.shipTo
      //   };
      // }

      // Update validTillDate only if contact is changed manually
      if (isContactChangedManually.current) {
        // Load account groups if contact currency is different
        if (props.draftData?.data?.isCashInvoice) {
          loadAccountGroups(tempDocument.currency);
        }
        if (tempDocument.validTillDate) {
          setDueDate(
            DateFormatService.getDateFromStr(
              tempDocument.validTillDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          );
        } else {
          tempDocument.validTillDate = DateFormatService.getDateStrFromDate(
            laterDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
        }
      } else {
        // Set initial dates while opening an existing document, if contact is not changed manually
        tempDocument = {
          ...tempDocument,
          documentDate: props.booksDocument.documentDate
            ? props.booksDocument.documentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          fulfillmentDate: props.booksDocument.fulfillmentDate
            ? props.booksDocument.fulfillmentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          validTillDate: props.booksDocument.validTillDate
            ? props.booksDocument.validTillDate
            : DateFormatService.getDateStrFromDate(
                laterDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
        };
      }

      tempDocument = {
        ...tempDocument,
        items: [...tempDocument.items].sort(
          (item1: any, item2: any) => item1.lineNumber - item2.lineNumber
        )
      };

      tempDocument = {
        ...tempDocument,
        items: tempDocument.items?.map((item: any) => {
          const tdsInfoIndia =
            props.documentMode === DOCUMENT_MODE.EDIT ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            (props.draftType === DraftTypes.DRAFT &&
              props.booksDocument?.contact?.code === contact.code)
              ? item.tdsInfoIndia
              : undefined;
          let tax =
            props.documentMode === DOCUMENT_MODE.NEW
              ? !Utility.isEmpty(item.tax)
                ? item.tax
                : getTax({ ...item.product })
              : !Utility.isEmpty(item.tax)
              ? item.tax
              : null;
          return { ...item, gstType: tempDocument.gstType, tdsInfoIndia, tax };
        })
      };

      if (tempDocument.items && tempDocument.items.length > 0) {
        if (productRows.length && getTenantTaxSystem() === TAX_SYSTEM.US) {
          calculateUSTaxOnContactChange(tempDocument);
        } else {
          tempDocument.items.forEach((rowData: any, index: number) => {
            calculateFATaxesAndAmount(index, tempDocument.items);
          });
        }
      }

      // const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      //   props.booksDocument.documentType
      // );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        let gstType = booksDocument.gstType;
        gstType = updateGstType(tempDocument);
        tempDocument = {
          ...tempDocument,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument,
          billTo: Utility.isEmpty(tempDocument.billTo)
            ? billingAddress
            : tempDocument.billTo,
          shipTo:
            props.documentMode !== DOCUMENT_MODE.NEW
              ? tempDocument.shipTo
              : tempDocument.shipTo || shippingAddress,
          shipFrom: !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null
        };
      });

      setNeedToUpdateAddresses(false);

      if (productRows.length && isContactChangedManually.current) {
        const productsPayload = productRows
          .filter((item) => !Utility.isEmpty(item.product))
          .map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          tempDocument.currency,
          true,
          tempDocument.exchangeRate,
          { contactChanged: true },
          { shipTo: tempDocument.shipTo, shipFrom: tempDocument.shipFrom }
        );
      }
      if (productRows.length) {
        fetchProductUnitPrice();
      }
    }
  }, [contact]);

  useEffect(() => {
    if (Utility.isEmpty(contact)) {
      return;
    }
    const { billingAddress, shippingAddress } = !Utility.isFaDropship(
      props.booksDocument
    )
      ? updateBillingShippingAddress(false)
      : {
          billingAddress: {},
          shippingAddress: {}
        };

    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.FA_ORDER,
      DOC_TYPE.FA_BILL,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);

    let updatedDocument: any = {};

    if (isBillOrOrder) {
      updatedDocument.billTo = Utility.isFaDropship(props.booksDocument)
        ? {}
        : billingAddress || booksDocument.billTo;
      updatedDocument.shipTo = Utility.isFaDropship(props.booksDocument)
        ? props.booksDocument.shipTo
        : shippingAddress || booksDocument.shipTo;

      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
          props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
          props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER)
      ) {
        updatePlaceOfSupplyOnAddress(shippingAddress);
      }
    } else {
      const preferredShippingAddress = tenantInfo.shippingAddresses?.find(
        (address: any) => address.preferred
      );
      updatedDocument.shipFrom = !Utility.isEmpty(preferredShippingAddress)
        ? preferredShippingAddress
        : !Utility.isEmpty(props.booksDocument.shipFrom)
        ? props.booksDocument.shipFrom
        : null;
    }
    if (billingAddress || shippingAddress) {
      setBooksDocument((prevState: any) => {
        return { ...prevState, ...updatedDocument };
      });
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (shipToOrFromAddressChangedForUS) {
      calculateUSTax(undefined);
      setShipToOrFromAddressChangedForUS(false);
    }
    setIsCenterAlign(props.isCenterAlign);
    props.onDocumentUpdate(booksDocument);
  }, [booksDocument]);

  useEffect(() => {
    if (currentRowTDSInfo) {
      setShowTDSCalculationPopup(true);
    }
  }, [currentRowTDSInfo]);

  useEffect(() => {
    if (currentRowITCInfo) {
      setShowITCPopup(true);
    }
  }, [currentRowITCInfo]);

  useEffect(() => {
    if (currentRowHSNInfo) {
      setShowHSNPopup(true);
    }
  }, [currentRowHSNInfo]);

  useEffect(() => {
    if (currentRowGSTInfo) {
      setShowGSTPopup(true);
    }
  }, [currentRowGSTInfo]);

  useEffect(() => {
    setPaymentOptionState(paymentOptions);
  }, [paymentOptions]);

  const onFocus = () => {
    loadPaymentOptions();
  };

  const loadPaymentOptions = () => {
    dispatch(fetchPaymentOptions(tenantInfo?.currency));
  };

  //////////////////////////////////////////////////////////
  ////////////////////POPUP HANDLERS////////////////////////
  //////////////////////////////////////////////////////////
  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        // showAddressPopup && setShowAddressPopup(false);
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        setShowIRPCredPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        editAddressRef.current?.storeCallbacksRef.updateContact();
        break;
      case POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ADDRESS:
        editAddressRef.current?.storeCallbacksRef.updateAddress();
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        addTaxRef.current?.storeCallbacksRef.createTax();
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        editAddressRef.current.storeCallbacksRef.updateContact =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
        setShowAddContactPopup(false);
        setDetailedContact(passingData.data.id);
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ADDRESS:
        editAddressRef.current.storeCallbacksRef.updateAddress =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        editAddressRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.ADDRESS_UPDATE_SUCCESSFUL:
        onAddressUpdated();
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        addTaxRef.current.storeCallbacksRef.createTax = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT:
        tdsAmountRef.current.storeCallbacksRef.submitTDSAmount =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS:
        if (passingData?.data?.incomePayment) {
          setIsTDSDeducted(true);
          onTDSDeduct(passingData.data);
          showTDSCalculationPopup && setShowTDSCalculationPopup(false);
        }
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        if (lastUpdatedIndex.current !== null) {
          let rows = [...productRows];
          rows[lastUpdatedIndex.current]['tax'] = passingData.data;
          updateConfig();
          setProductRows(rows);
        }
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showUpdateOrgPopup && setShowUpdateOrgPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        setShowAddContactPopup(false);
        break;
    }
  };

  //////////////////////////////////////////////////////////
  ///////////////// TENANT ADDRESS HANDLERS ////////////////
  //////////////////////////////////////////////////////////

  const getUpdateOrgPopup = () => {
    const popupConfigForOrganisationProfile: BtnType[] = [
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.UPDATE`),
        class: 'bg-app text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
      }
    ];
    return showUpdateOrgPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`Update ${getCapitalized(
          localizedText('organisation')
        )} Profile`}
        btnList={popupConfigForOrganisationProfile}
        width={'40%'}
        height={!isDesktop ? '75%' : '95%'}
        disableClickOutside
      >
        <OrganisationProfileForm
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          activeTab={activeOrgProfileTab}
        />
      </PopupWrapper>
    ) : null;
  };

  const showTenantAddressAlert = () => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m ',
        onClick: () => {}
      },
      {
        title: 'Add address',
        className: 'bg-button text-white ml-r',
        onClick: () => setShowUpdateOrgPopup(true)
      }
    ];

    const isBillOrOrder =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER;
    showAlert(
      `Tax Compliance`,
      isBillOrOrder
        ? `To calculate purchase tax correctly, we would need your organisation's address.`
        : `To calculate sales tax correctly, we would need your organisation's address where you collect tax.`,
      alertButtonConfig
    );
  };

  const updateBillingShippingAddress = (requireAddressCheck?: boolean) => {
    const isBillOrOrder =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
      props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER;

    const billingAddressList: BooksAddress[] = isBillOrOrder
      ? tenantInfo.billingAddresses
      : props.documentMode === DOCUMENT_MODE.EDIT
      ? editModeBillingAddresses
      : contact?.billingAddress;

    const shippingAddressList: BooksAddress[] =
      isBillOrOrder && !Utility.isFaDropship(props.booksDocument)
        ? tenantInfo.shippingAddresses
        : props.documentMode === DOCUMENT_MODE.EDIT
        ? editModeShippingAddresses
        : contact?.shippingAddress;

    let billingAddress: BooksAddress | undefined;
    let shippingAddress: BooksAddress | undefined;

    let canShowTenantAddressAlert = false;
    const complianceStatus = Utility.getTenantComplianceSettings(tenantInfo);
    if (
      !complianceStatus.complianceSetting &&
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY)
    ) {
      canShowTenantAddressAlert = true;
    }

    if (isBillOrOrder) {
      billingAddress =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      shippingAddress =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);

      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !shippingAddress
      ) {
        showTenantAddressAlert();
      }
    } else {
      billingAddress = billingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      shippingAddress = shippingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      const tenantHasShippingAddress = Boolean(
        tenantInfo.shippingAddresses?.length
      );
      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !tenantHasShippingAddress
      ) {
        showTenantAddressAlert();
      }
    }

    setBillingAddress({
      billingAddress: billingAddressList,
      currentBillingAddress: billingAddress as BooksAddress
    });

    setShippingAddress({
      shippingAddress: shippingAddressList,
      currentShippingAddress: shippingAddress as BooksAddress
    });

    return {
      billingAddress,
      shippingAddress
    };
  };

  //////////////////////////////////////////////////////////
  //////////// CONTACT & ADDRESS PANEL HANDLERS ////////////
  //////////////////////////////////////////////////////////
  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };
  const onAddressUpdated = () => {
    // setProductRows([]);
    // setShowAddressPopup(false);
    setShowUpdateAddressPopup(false);
    const response = ContactService.getContactDetailsById(contact.id);
    response?.then((res: any) => {
      if (props.documentMode === DOCUMENT_MODE.EDIT) {
        setEditModeBillingAddresses(res.billingAddress);
        setEditModeShippingAddresses(res.shippingAddress);
      }
      setContact({ ...res });
    });
  };

  const onPlaceOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      if (Utility.isSalesDocument(props.booksDocument)) {
        let shipToAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipToAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: value.value
          };
        }
        const gstType = updateGstType({
          ...tempDocument,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipTo: shipToAddress,
          gstType: gstType,
          placeOfSupply: value.value,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      } else {
        let shipFromAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipFromAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipFromAddress = {
            ...tempDocument.shipFrom,
            placeOfSupply: value.value
          };
        }
        let shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: dos.value
        };
        const gstType = updateGstType({
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      setBooksDocument({
        ...tempDocument
      });
      tempDocument.items.forEach((item: any, index: number) => {
        calculateFATaxesAndAmount(index, tempDocument.items);
      });
    }
  };

  const onDestinationOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      let shipToAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipToAddress = {
          ...address,
          destinationOfSupply: value.value
        };
      } else {
        shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: value.value
        };
      }
      let shipFromAddress = {
        ...tempDocument.shipFrom,
        placeOfSupply: pos.value,
        destinationOfSupply: value.value
      };
      const gstType = updateGstType({
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress
      });
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
      setBooksDocument({
        ...tempDocument
      });
      tempDocument.items.forEach((item: any, index: number) => {
        calculateFATaxesAndAmount(index, tempDocument.items);
      });
    }
  };

  const updatePlaceOfSupplyOnAddress = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele.value?.toLowerCase() === (address.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    if (Utility.isSalesDocument(props.booksDocument)) {
      setPos(pos[0]);
      onPlaceOfsupplyChange(obj, address);
    } else {
      setDos(pos[0]);
      onDestinationOfsupplyChange(obj, address);
    }
  };
  const updatePlaceOfSupplyOnAddressBuy = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele?.value?.toLowerCase() === (address?.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    setPos(pos[0]);
    onPlaceOfsupplyChange(obj, address);
  };

  const getContactSelector = () => {
    return (
      <div
        className={`walkthrough-step-3 ${
          props.canValidate
            ? 'bg-chip-red border-red text-red'
            : 'bg-chip-blue border-blue text-blue'
        } p-v-s p-h-r border-radius-s mt-s cursor-pointer`}
        style={{ border: 'dashed', borderWidth: 1 }}
        onClick={() => setOpenContactSelector((prevValue) => !prevValue)}
      >
        <DKLabel text={`+ Add a Contact`} />
      </div>
    );
  };

  const getCustomCompanyDetails = (title?: string) => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.FA_BILL,
      DOC_TYPE.FA_ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);
    const orgName = customLocation?.locationDetails?.title;
    const address = getFormattedAddress(
      customLocation?.locationDetails?.address,
      isBillOrOrder
    );
    const phone = customLocation?.locationDetails?.phone;
    const email = customLocation?.locationDetails?.email;

    return (
      <div
        className={`column`}
        style={{
          maxWidth: 250
        }}
      >
        <div className="column width-auto">
          <div className="row">
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
          <div className={`row`}>
            <DKLabel text={orgName} className="fw-m fs-r" />
          </div>
          <DKLabel text={address} className="parent-width" />
          {phone && <DKLabel text={phone} className="parent-width" />}
          {email && <DKLabel text={email} className="parent-width" />}
        </div>
      </div>
    );
  };

  const getCompanyDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.FA_BILL,
      DOC_TYPE.FA_ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER,
      DOC_TYPE.FA_BILL || DOC_TYPE.FA_ORDER
    ].includes(props.booksDocument.documentType);

    const canEditContact =
      isBillOrOrder && props.documentMode !== DOCUMENT_MODE.EDIT;

    const companyAddress = isBillOrOrder
      ? booksDocument.shipFrom
        ? booksDocument.shipFrom
        : contact?.billingAddress?.length
        ? contact?.billingAddress[0]
        : null
      : tenantInfo?.address;

    return (
      <div
        className={`column parent-block`}
        style={{
          maxWidth: 250,
          marginTop: canEditContact ? -10 : 0,
          marginLeft: canEditContact ? -10 : 0,
          padding: canEditContact ? 10 : 0
        }}
      >
        <div className="row width-auto">
          {isBillOrOrder && Utility.isEmpty(contact) ? (
            getContactSelector()
          ) : (
            <div
              className={`${
                canEditContact && !Utility.isEmpty(contact)
                  ? 'cursor-pointer border-box'
                  : ''
              }`}
              onClick={() => {
                if (canEditContact && !Utility.isEmpty(contact)) {
                  setOpenContactSelector((prevValue) => !prevValue);
                  setBooksAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
                }
              }}
            >
              <div
                className={`row justify-content-between ${
                  canEditContact ? 'listPickerBG' : ''
                }`}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? `${contact.name}-(${contact.currencyCode})`
                      : tenantInfo.name
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
              </div>
            </div>
          )}
        </div>
        {Utility.isEmpty(companyAddress) ? null : (
          // Ship from for Bill/Order
          <>
            {isBillOrOrder ? (
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      isBillOrOrder &&
                      checkUserPermission(
                        PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                      ) &&
                      checkifCustomFieldCanEdit
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(companyAddress, isBillOrOrder)}
                    className={'parent-width ' + GOOGLE_NO_TRANSLATE_CLASS}
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={contact?.billingAddress}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      ></div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.FA_ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.FA_BILL ||
                          DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddressBuy(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
              </div>
            ) : (
              // Ship from for Invoice/Quote
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      !isBillOrOrder &&
                      checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(
                      !Utility.isEmpty(booksDocument.shipFrom)
                        ? booksDocument.shipFrom
                        : companyAddress,
                      isBillOrOrder
                    )}
                    className={'parent-width ' + GOOGLE_NO_TRANSLATE_CLASS}
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={tenantInfo?.shippingAddresses}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      >
                        {/* {getFormattedAddress(addressObj)} */}
                      </div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                        setShipToOrFromAddressChangedForUS(true);
                      }
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.FA_ORDER ||
                          props.booksDocument.documentType ===
                            DOC_TYPE.FA_BILL ||
                          DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddress(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const getContactCard = (
    title: string,
    addressType: BOOKS_ADDRESS_TYPES,
    isBillOrOrder: boolean
  ) => {
    if (isBillOrOrder && !Utility.isEmpty(customLocation)) {
      return getCustomCompanyDetails(title);
    }

    const canEditContact =
      props.documentMode !== DOCUMENT_MODE.EDIT &&
      !isBillOrOrder &&
      checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE);
    return (
      <div
        ref={
          addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
            ? shippingAddressBlock
            : null
        }
        className={`column document-address-block`}
        style={{
          minWidth: 170,
          maxWidth: 250,
          marginLeft: -10,
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10
        }}
      >
        <div className="row parent-block justify-content-between">
          <div
            className={`row width-auto ${
              canEditContact ? 'cursor-pointer listPickerBG' : ''
            }`}
            onClick={() => {
              if (canEditContact) {
                setOpenContactSelector((prevValue) => !prevValue);
                setBooksAddressType(addressType);
              }
            }}
          >
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
        </div>
        <div className={`mt-s`}>
          {addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS && (
            <div className="position-relative">
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                title={canEditContact ? 'Change contact' : ''}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? tenantInfo.name
                      : `${contact.name}-(${contact.currencyCode})`
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
              </div>
              <div
                className={`row ${
                  !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ? ''
                    : 'cursor-pointer listPickerBG'
                }`}
                onClick={(e: any) => {
                  if (
                    checkUserPermission(
                      PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                    ) &&
                    checkifCustomFieldCanEdit
                  ) {
                    setOpenBillingAddressList(true);
                  }
                }}
              >
                <DKLabel
                  text={
                    !Utility.isEmpty(
                      booksDocument.billTo ||
                        billingAddress?.currentBillingAddress ||
                        (isBillOrOrder ? tenantInfo?.address : null)
                    )
                      ? getFormattedAddress(
                          booksDocument.billTo ||
                            billingAddress?.currentBillingAddress ||
                            (isBillOrOrder ? tenantInfo?.address : null)
                        )
                      : ''
                  }
                  className={GOOGLE_NO_TRANSLATE_CLASS}
                />
              </div>
              {openBillingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.billingAddresses
                      : billingAddress?.billingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={
                    isBillOrOrder && Utility.isFaDropship(props.booksDocument)
                      ? null
                      : {
                          title: 'Manage address',
                          className:
                            'text-white fw-m bg-button justify-content-center',
                          onClick: () => {
                            if (isBillOrOrder) {
                              setActiveOrgProfileTab(
                                ORGANISATION_FORM_TAB.ADDRESS_INFO
                              );
                              setShowUpdateOrgPopup(true);
                            } else {
                              setActiveContactTab(
                                CONTACT_FORM_TAB.ADDRESS_INFO
                              );
                              setContactMode(DOCUMENT_MODE.EDIT);
                              setShowAddContactPopup(true);
                            }
                            setOpenBillingAddressList(false);
                          }
                        }
                  }
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setBillingAddress({
                      billingAddress:
                        billingAddress?.billingAddress as BooksAddress[],
                      currentBillingAddress: addressObj
                    });
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      billTo: addressObj
                    }));
                    setOpenBillingAddressList(false);
                  }}
                  onClose={() => {
                    setOpenBillingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
            <>
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                title={canEditContact ? 'Change contact' : ''}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder && !Utility.isFaDropship(props.booksDocument)
                      ? tenantInfo.name
                      : dropshipShipToContact
                      ? dropshipShipToContact
                      : contact?.name
                      ? `${contact.name}-(${contact.currencyCode})`
                      : ''
                  }
                  className={'fw-m fs-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                />
              </div>
              <div
                className={`row ${
                  !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ? ''
                    : 'cursor-pointer listPickerBG'
                }`}
                onClick={(e: any) => {
                  if (
                    checkUserPermission(
                      PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                    ) &&
                    !Utility.isFaDropship(props.booksDocument) &&
                    checkifCustomFieldCanEdit
                  ) {
                    setOpenShippingAddressList(true);
                  }
                }}
              >
                <DKLabel
                  text={
                    !Utility.isEmpty(
                      booksDocument.shipTo ||
                        shippingAddress?.currentShippingAddress ||
                        (isBillOrOrder &&
                        !Utility.isFaDropship(props.booksDocument)
                          ? tenantInfo?.address
                          : null)
                    )
                      ? getFormattedAddress(
                          booksDocument.shipTo ||
                            shippingAddress?.currentShippingAddress ||
                            (isBillOrOrder &&
                            !Utility.isFaDropship(props.booksDocument)
                              ? tenantInfo?.address
                              : null)
                        )
                      : ''
                  }
                  className={GOOGLE_NO_TRANSLATE_CLASS}
                />
              </div>
              {openShippingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.shippingAddresses
                      : shippingAddress?.shippingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={{
                    title: 'Manage address',
                    className:
                      'text-white fw-m bg-button justify-content-center',
                    onClick: () => {
                      if (isBillOrOrder) {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                      } else {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                      }
                      setOpenShippingAddressList(false);
                    }
                  }}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setShippingAddress({
                      shippingAddress:
                        shippingAddress?.shippingAddress as BooksAddress[],
                      currentShippingAddress: addressObj
                    });
                    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                      setShipToOrFromAddressChangedForUS(true);
                    }
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      shipTo: addressObj
                    }));
                    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
                      updatePlaceOfSupplyOnAddress(addressObj);
                    }
                    setOpenShippingAddressList(false);
                  }}
                  onClose={() => {
                    setOpenShippingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getDeliveryAddressDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER,
      DOC_TYPE.FA_BILL,
      DOC_TYPE.FA_ORDER
    ].includes(props.booksDocument.documentType);

    return !isBillOrOrder && Utility.isEmpty(contact) ? (
      getContactSelector()
    ) : (
      <div>
        <div className="row width-auto align-items-start gap-2">
          {getContactCard(
            t(`DOCUMENT.BILL_TO`),
            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS,
            isBillOrOrder
          )}
          {getContactCard(
            t(`DOCUMENT.SHIP_TO`),
            BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS,
            isBillOrOrder
          )}
        </div>
      </div>
    );
  };

  const setDetailedContact = async (id: number) => {
    try {
      const detailedContact = await ContactService.getContactDetailsById(id);
      setNeedToUpdateAddresses(true);
      setContact(detailedContact);
      if (!booksDocument.isDocumentTouched) {
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      }
      setBillingAddress({
        billingAddress: detailedContact.billingAddress,
        currentBillingAddress: booksDocument.billTo
      });
      setShippingAddress({
        shippingAddress: detailedContact.shippingAddress,
        currentShippingAddress: booksDocument.shipTo
      });
    } catch (err) {
      console.error('Error loading detailed contact: ', err);
    }
  };

  const getAddContactPopup = () => {
    const buttonConfig: BtnType[] = [
      {
        title: t(`DOCUMENT.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: contactMode === DOCUMENT_MODE.NEW ? 'Create' : 'Update',
        class: 'bg-button text-white mr-ss',
        clickAction:
          contactMode === DOCUMENT_MODE.NEW
            ? POPUP_CLICK_TYPE.CREATE_CONTACT
            : POPUP_CLICK_TYPE.UPDATE_CONTACT
      }
    ];
    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={
          contactMode === DOCUMENT_MODE.NEW
            ? 'Create Contact'
            : 'Update Contact'
        }
        btnList={buttonConfig}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          activeTab={activeContactTab}
          contactMode={contactMode}
          populateFormData={
            contactMode === DOCUMENT_MODE.NEW ? null : { ...contact }
          }
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          onSuccess={(res: any) => setDetailedContact(res.id)}
        />
      </PopupWrapper>
    ) : null;
  };

  const getLeftPosForShippingAddress = () => {
    if (shippingAddressBlock) {
      const popup = shippingAddressBlock.current?.closest('.popup-window');
      if (popup) {
        const popupLeft = popup.getBoundingClientRect().left;
        const rect = shippingAddressBlock.current?.getBoundingClientRect();
        return rect.left - popupLeft - 22;
      } else {
        return 198;
      }
    } else {
      return 198;
    }
  };

  const getContactPicker = () => {
    let contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      contactList =
        contactList.filter((contact: any) => contact.vendor === true) || [];
    }

    const contactPickerTop =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
      props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ? -50
        : 50;

    return openContactSelector ? (
      <DKListPicker2
        data={contactList}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          top: contactPickerTop,
          left: [
            BOOKS_ADDRESS_TYPES.NONE,
            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
          ].includes(booksAddressType)
            ? 0
            : getLeftPosForShippingAddress(),
          minWidth: 200
        }}
        renderer={(index: number, contactObj: any) => contactObj.name}
        onSelect={(index: number, contactObj: any) => {
          // setProductRows([]);
          if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
            checkForDpl(contactObj);
          }
          isContactChangedManually.current = true;
          setOpenContactSelector(false);
          setDetailedContact(contactObj.id);
          setSelectedPaymentOption(null);
          setBooksDocument({
            ...booksDocument,
            paymentInformation: null
          });
        }}
        onClose={() => setTimeout(() => setOpenContactSelector(false), 100)}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query:
                props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
                  ? 'status=active,vendor=true'
                  : 'status=active'
            };

            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        }}
        button={
          checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
            ? {
                title: '+ Add New',
                icon: DKIcons.ic_contact,
                className: 'bg-button text-white',
                style: {},
                onClick: () => {
                  setContactMode(DOCUMENT_MODE.NEW);
                  setShowAddContactPopup(true);
                }
              }
            : null
        }
      />
    ) : null;
  };

  //////////////////////////////////////////////////////////
  //////////// GST EXCHANGE RATE FOR SG HANDLERS ///////////
  //////////////////////////////////////////////////////////
  const isTenantAndCurrencySg = (): boolean => {
    return (
      tenantInfo &&
      tenantInfo.currency === CURRENCIES.SG &&
      tenantInfo.country === COUNTRY_CODES.SG
    );
  };

  const showGstCurrencyTax = () => {
    return isGSTExchangeRateRequired(
      tenantInfo && tenantInfo.country,
      tenantInfo && tenantInfo.currency
    );
  };

  //////////////////////////////////////////////////////////
  ///// DOCUMENT RIGHT PANEL INFO (DOC CODE & DATES) ///////
  //////////////////////////////////////////////////////////
  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};

    if (selected.manualMode) {
      updatedState.documentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
    } else {
      if (selected.id) {
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      }
    }

    setBooksDocument((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };

  const getDateText = () => {
    if (props.booksDocument.documentType === DOC_TYPE.QUOTE) {
      return Utility.isUSorg() ? 'Estimate Date' : t(`QUOTES.QUOTE_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.INVOICE) {
      return t(`INVOICES.INVOICE_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.SALES_ORDER) {
      return 'Order Date';
    }
    if (props.booksDocument.documentType === DOC_TYPE.ORDER) {
      return t(`PURCHASE_ORDER.ORDER_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      return t(`PURCHASE_ORDER.ORDER_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.BILL) {
      return t(`BILLS.BILL_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.FA_BILL) {
      return t(`BILLS.BILL_DATE`);
    }
    if (props.booksDocument.documentType === DOC_TYPE.FA_ORDER) {
      return t(`PURCHASE_ORDER.ORDER_DATE`);
    }
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        showAlert(
          'Invalid Date',
          `${getDateText()} should not before financial year close date`
        );
        return;
      }
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          setDocumentDate,
          `${getDateText()} should be in active date range`
        );
        return;
      }
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    callback: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `${getDateText()} should not be less than back date : ${formatedDate}.`
          );
          return;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        callback(newDate);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      } else {
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }
  };

  const getRightInfoPanel = () => {
    const docDateLabel = getDateText();
    const shipByDateLabel = Utility.isSalesDocument(props.booksDocument)
      ? t(`DOCUMENT.SHIP_BY_EXPECTED`)
      : `Receive By`;

    let linkedDocument = null;

    switch (props.booksDocument.documentType) {
      case DOC_TYPE.QUOTE:
        if (
          booksDocument.linkedSalesInvoices &&
          booksDocument.linkedSalesInvoices?.length
        ) {
          linkedDocument = [...booksDocument.linkedSalesInvoices];
        } else if (
          booksDocument.linkedSalesOrders &&
          booksDocument.linkedSalesOrders?.length
        ) {
          linkedDocument = [...booksDocument.linkedSalesOrders];
        } else if (
          booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
          booksDocument.backOrder
        ) {
          linkedDocument = booksDocument.linkedDocuments;
        } else {
          linkedDocument = null;
        }
        break;
      case DOC_TYPE.SALES_ORDER:
        linkedDocument = booksDocument.linkedQuotationDocuments?.length
          ? booksDocument.linkedQuotationDocuments
          : booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
            booksDocument.backOrder
          ? booksDocument.linkedDocuments
          : null;
        break;
      case DOC_TYPE.ORDER:
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        linkedDocument = booksDocument.linkedPurchaseInvoices?.length
          ? booksDocument.linkedPurchaseInvoices
          : booksDocument.dropShip || booksDocument.backOrder
          ? booksDocument.linkedDocuments
          : null;
        linkedDocument = booksDocument.linkedPurchaseInvoices?.length
          ? booksDocument?.linkedDocuments?.[0]?.documentType ===
            'PURCHASE_REQUEST'
            ? linkedDocument.concat(booksDocument.linkedDocuments)
            : linkedDocument
          : booksDocument.linkedDocuments;
        break;
      case DOC_TYPE.INVOICE:
      case DOC_TYPE.BILL:
      default:
        linkedDocument = booksDocument.linkedDocuments?.length
          ? booksDocument.linkedDocuments
          : booksDocument.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP ||
            booksDocument.backOrder
          ? booksDocument.linkedDocuments
          : null;
    }

    return (
      <div className="column align-items-end">
        <div className="position-relative walkthrough-step-4">
          <div
            className={`row width-auto mb-xs justify-content-between ${
              props.documentMode !== DOCUMENT_MODE.EDIT
                ? 'listPickerBG'
                : 'p-v-xs'
            }`}
            style={{
              width: 240
            }}
          >
            <div
              className="row width-auto cursor-pointer"
              onClick={() => setopenCustomNumberList((value) => !value)}
            >
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'No.'} className={'fw-m ml-r'} />
            </div>
            {(props.documentMode === DOCUMENT_MODE.EDIT ||
              props.documentMode === DOCUMENT_MODE.VIEW) &&
              props.booksDocument.documentSequenceCode && (
                <DKLabel
                  text={props.booksDocument.documentSequenceCode}
                  className={'ml-r '}
                />
              )}
            {(props.documentMode === DOCUMENT_MODE.NEW ||
              props.documentMode === DOCUMENT_MODE.COPY ||
              (props.documentMode === DOCUMENT_MODE.VIEW &&
                !props.booksDocument.documentSequenceCode)) && (
              <div className="w-9/12 -mr-1">
                <CustomNumberFormatInput
                  module={customNumberFormatModule}
                  selectedFormat={selectedFormat}
                  showCompact={true}
                  extraClass={'top-12 right-0'}
                  openList={openCustomNumberList}
                />
              </div>
            )}
          </div>
        </div>
        <div className="position-relative walkthrough-step-5">
          <div
            className="row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs"
            style={{
              width: 240
            }}
            onClick={() => {
              if (checkifCustomFieldCanEdit) {
                setInvoiceDateOpen(!invoiceDateOpen);
              }
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={docDateLabel} className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(documentDate)}
              className={'ml-r '}
            />
          </div>
          {invoiceDateOpen &&
            getCalendarView(
              documentDate,
              (newDate: any) => {
                validateAndUpdateDate(
                  newDate,
                  DateFormatService.getDateFromStr(
                    tenantInfo.bookBeginningStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ),
                  setDocumentDate,
                  `${docDateLabel} cannot be before books beginning date.`,
                  true
                );
                isDocDateUpdatedManually.current = true;
              },
              setInvoiceDateOpen
            )}
        </div>
        <div className="position-relative">
          <div
            className="row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs"
            style={{
              width: 240
            }}
            onClick={() => {
              if (checkifCustomFieldCanEdit) {
                setDueDateOpen(!dueDateOpen);
              }
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={t(`DOCUMENT.DUE_DATE`)} className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(dueDate)}
              className={'ml-r '}
            />
          </div>
          {dueDateOpen &&
            getCalendarView(
              dueDate,
              (newDate: any) =>
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setDueDate,
                  `${t(`DOCUMENT.DUE_DATE`)} cannot be before ${docDateLabel}.`,
                  false
                ),
              setDueDateOpen
            )}
        </div>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between cursor-pointer listPickerBG p-v-xs ${
              linkedDocument ? 'mb-xs' : ''
            }`}
            style={{
              width: 240
            }}
            onClick={() => {
              if (checkifCustomFieldCanEdit) {
                setShipByDateOpen(!shipByDateOpen);
              }
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={shipByDateLabel} className={'fw-m ml-r'} />
            </div>
            <DKLabel
              text={DateFormatService.getDateStrFromDate(shipByDate)}
              className={'ml-r '}
            />
          </div>
          {shipByDateOpen &&
            getCalendarView(
              shipByDate,
              (newDate: any) =>
                validateAndUpdateDate(
                  newDate,
                  documentDate,
                  setShipByDate,
                  `${shipByDateLabel} date cannot be before ${docDateLabel}.`,
                  false
                ),
              setShipByDateOpen
            )}
        </div>
        {linkedDocument ? (
          <div className="position-relative">
            <div
              className="row width-auto justify-content-between p-v-xs"
              style={{
                minWidth: 240
              }}
            >
              <div className="row" style={{ width: 110 }}>
                <DKIcon
                  src={DKIcons.data_type.ic_url}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={`Linked To`} className={'fw-m ml-r'} />
              </div>
              <div className="ml-r fs-r">
                {linkedDocument
                  .map((doc: any) => doc.documentSequenceCode)
                  .join(', ')}
              </div>
            </div>
          </div>
        ) : null}
        {(((props.documentMode === DOCUMENT_MODE.NEW ||
          props.documentMode === DOCUMENT_MODE.COPY) &&
          props.draftData?.data?.isCashInvoice &&
          props.booksDocument.documentType === DOC_TYPE.INVOICE) ||
          props.autoProcessDoc) &&
          getPaymentRelatedFields()}
        {tenantInfo.country === COUNTRY_CODES.IN &&
          (props.booksDocument.documentType === DOC_TYPE.BILL ||
            props.booksDocument.documentType === DOC_TYPE.FA_BILL) && (
            <div className="position-relative">
              <div
                className="row width-auto mb-m justify-content-between"
                style={{
                  minWidth: 240
                }}
              >
                <div className="row width-auto">
                  <DKIcon
                    src={DKIcons.data_type.ic_number}
                    className="ic-xs-2"
                    style={{ opacity: 0.6 }}
                  />
                  <DKLabel text={'Supplier Inv No.'} className={'fw-m ml-r'} />
                </div>
                <div style={{ width: 100 }}>
                  <DKInput
                    title=""
                    textAlign="right"
                    value={booksDocument.supplierInvoiceNo}
                    valueStyle={{
                      paddingTop: 0,
                      paddingBottom: 0
                    }}
                    canValidate={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.TEXT}
                    readOnly={
                      (props.documentMode === DOCUMENT_MODE.VIEW &&
                        props.draftType === DraftTypes.READONLY) ||
                      checkifCustomFieldCanEdit === false
                    }
                    onChange={(value: string) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          supplierInvoiceNo: value
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    );
  };

  const loadAccountGroups = async (currency: string) => {
    AccountsService.fetchAccountGroup(currency)
      .then((accountGroups: any[]) => {
        const filterAccounts =
          tenantInfo?.additionalSettings?.ACCOUNT?.showCardCashBankAccountsOnly;
        let filteredAccountGroups = [];
        if (filterAccounts) {
          filteredAccountGroups = accountGroups?.filter(
            (acc: any) =>
              ([COMMON_CONSTANT.CASH, COMMON_CONSTANT.BANK].includes(
                acc.accountGroup
              ) ||
                (acc.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                  acc.isCreditCard === true)) &&
              acc.status === STATUS_TYPE.ACTIVE
          );
        } else {
          filteredAccountGroups = accountGroups.filter(
            (el) =>
              el.accountGroup === COMMON_CONSTANT.BANK ||
              el.accountGroup === COMMON_CONSTANT.CASH ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                el.isCreditCard) ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_ASSETS &&
                el.isUndepositedFundAccount &&
                props.booksDocument.documentType === DOC_TYPE.INVOICE)
          );
        }

        let paymentMethodOptions: any[] = [];
        const paymentMethods = filteredAccountGroups.map(
          (filteredAccountGroup) => makePaymentMethod(filteredAccountGroup)
        );

        paymentMethods.forEach((method) => {
          let option: any = {
            label: method.name,
            accountGroupName: Utility.convertInTitleCase(method.accountGroup),
            value: method.code,
            isUndepositedFundAccount: method.isUndepositedFundAccount
              ? true
              : false
          };
          let found = false;
          paymentMethodOptions.forEach((paymentMethodOption) => {
            if (
              paymentMethodOption.label === option.label &&
              paymentMethodOption.accountGroup === option.accountGroup
            ) {
              found = true;
            }
          });
          if (!found) {
            paymentMethodOptions.push(option);
          }
        });

        const defaultAccountGroupOption =
          paymentMethodOptions.find(
            (el: any) => el.value === COMMON_CONSTANT.DEFAULT_DEPOSIT_TO
          ) || paymentMethodOptions[0];
        setPaymentAccountGroupsOptions(paymentMethodOptions);
        setBooksDocument((prevState: any) => ({
          ...prevState,
          accountGroupForPayment: defaultAccountGroupOption,
          paymentType: 'BANK_TRANSFER'
        }));
      })
      .catch((err: any) => {
        console.error('Error loading account groups: ', err);
      });
  };

  const getOptionForPaymentType = (options: any[]) => {
    switch (booksDocument?.accountGroupForPayment?.accountGroupName) {
      case 'Current Assets':
        return [
          ...options,
          {
            label: 'Cash',
            value: 'CASH'
          }
        ];
      case 'Bank':
        return [
          ...options,
          {
            label: 'ACH',
            value: 'ACH'
          }
        ];
      default:
        return options;
    }
  };

  const getPaymentRelatedFields = () => {
    const PAYMENT_TYPE: any[] = [
      { value: 'CHEQUE', label: getCapitalized(localizedText('cheque')) },
      { value: 'BANK_TRANSFER', label: COMMON_CONSTANT.BANK_TRANSFER_VALUE },
      { value: 'CARD', label: COMMON_CONSTANT.CARD_VALUE }
    ];

    return (
      <>
        <div className="position-relative">
          <div
            className={`row width-auto justify-content-between mb-xs mt-xs`}
            style={{
              minWidth: 240
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'Amt. to Receive'} className={'fw-m ml-r'} />
            </div>
            <div style={{ width: 100 }}>
              <DKInput
                title=""
                textAlign="right"
                value={booksDocument.amountToReceiveOrPay}
                valueStyle={{
                  minHeight: 33
                }}
                validator={(value: string) => {
                  return (
                    REGEX.DECIMAL_NUMBER.test(
                      booksDocument.amountToReceiveOrPay
                    ) &&
                    (+value >= 0 || booksDocument.totalAmount === 0)
                  );
                }}
                canValidate={
                  !REGEX.DECIMAL_NUMBER.test(
                    booksDocument.amountToReceiveOrPay
                  ) ||
                  (booksDocument.totalAmount > 0 &&
                    +booksDocument.amountToReceiveOrPay < 1) ||
                  +booksDocument.amountToReceiveOrPay > +dueAmount
                }
                errorMessage={'Invalid'}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.TEXT}
                readOnly={
                  props.documentMode === DOCUMENT_MODE.VIEW &&
                  props.draftType === DraftTypes.READONLY
                }
                onChange={(value: string) => {
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      amountToReceiveOrPay: value
                    };
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div
            className="row width-auto mb-xs justify-content-between"
            style={{
              minWidth: 240
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_select}
                className="ic-xs-2"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'Deposit To'} className={'fw-m ml-r'} />
            </div>
            <div style={{ width: 120 }}>
              <DKInput
                title=""
                value={
                  paymentAccountGroupsOptions.find(
                    (aGroup: any) =>
                      aGroup.value ===
                      booksDocument.accountGroupForPayment?.value
                  )?.label
                }
                valueStyle={{
                  minHeight: 33,
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}
                canValidate={false}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.DROPDOWN}
                readOnly={
                  props.documentMode === DOCUMENT_MODE.VIEW &&
                  props.draftType === DraftTypes.READONLY
                }
                onChange={(obj: any) => {
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      accountGroupForPayment: obj,
                      paymentType:
                        obj?.accountGroupName === 'Cash'
                          ? 'CASH'
                          : booksDocument.paymentType
                    };
                  });
                }}
                dropdownConfig={{
                  className: '',
                  style: {},
                  allowSearch: false,
                  searchableKey: 'label',
                  data: paymentAccountGroupsOptions,
                  renderer: (index: any, obj: any) => {
                    return (
                      <DKLabel
                        text={obj.label}
                        style={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}
                      />
                    );
                  },
                  onSelect: (index: any, value: any) => {}
                }}
              />
            </div>
          </div>
        </div>
        {booksDocument?.accountGroupForPayment?.accountGroupName !== 'Cash' && (
          <div className="position-relative">
            <div
              className="row width-auto mb-xs justify-content-between"
              style={{
                minWidth: 240
              }}
            >
              <div className="row width-auto">
                <DKIcon
                  src={DKIcons.data_type.ic_select}
                  className="ic-xs-2"
                  style={{ opacity: 0.6 }}
                />
                <DKLabel text={'Payment Type'} className={'fw-m ml-r'} />
              </div>
              <div style={{ width: 120 }}>
                <DKInput
                  title=""
                  value={
                    getOptionForPaymentType(PAYMENT_TYPE).find(
                      (pType: any) => pType.value === booksDocument.paymentType
                    )?.label
                  }
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  type={INPUT_TYPE.DROPDOWN}
                  readOnly={
                    props.documentMode === DOCUMENT_MODE.VIEW &&
                    props.draftType === DraftTypes.READONLY
                  }
                  onChange={(obj: any) => {
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        paymentType: obj.value
                      };
                    });
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'label',
                    data: getOptionForPaymentType(PAYMENT_TYPE),
                    renderer: (index: any, obj: any) => {
                      return (
                        <div className="flex flex-row w-full justify-content-between">
                          <DKLabel
                            style={{ fontSize: '13px' }}
                            className="text-base border-radius-s"
                            text={obj.label}
                          />
                        </div>
                      );
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const getPlaceOfSupply = () => {
    return (
      <div style={{ width: 150 }}>
        <CommonStateListPicker
          value={pos || ''}
          title={
            Utility.isSalesDocument(props.booksDocument)
              ? 'Place of Supply'
              : 'Source of Supply'
          }
          className="parent-width mt-5"
          dropdownOptions={indianStatesOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            setPos(value);
            let updatedState = {
              ...booksDocument,
              placeOfSupply: value.value
            };
            setBooksDocument({ ...updatedState });
            onPlaceOfsupplyChange(value);
          }}
          readOnly={checkifCustomFieldCanEdit === false}
        />
      </div>
    );
  };
  const getSourceOfDestination = () => {
    return (
      <div style={{ width: 150 }}>
        <CommonStateListPicker
          value={dos || ''}
          title={'Destination of Supply'}
          className="parent-width mt-5"
          dropdownOptions={indianStatesOptions}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatterProperty={'label'}
          isSearchable={true}
          searchableKey={'searchableKey'}
          onChange={(value: any) => {
            setDos(value);
            let updatedState = {
              ...booksDocument
            };
            setBooksDocument({ ...updatedState });
            onDestinationOfsupplyChange(value);
          }}
          readOnly={checkifCustomFieldCanEdit === false}
        />
      </div>
    );
  };
  //////////////////////////////////////////////////////////
  /////////////////// CUSTOM FIELDS HANDLERS ///////////////
  //////////////////////////////////////////////////////////
  const getCFModuleFromDocType = () => {
    let module: any;

    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        module = MODULES_NAME.INVOICE;
        break;
      case DOC_TYPE.BILL:
        module = MODULES_NAME.BILL;
        break;
      case DOC_TYPE.QUOTE:
        module = MODULES_NAME.QUOTATION;
        break;
      case DOC_TYPE.SALES_ORDER:
        module = MODULES_NAME.SALESORDER;
        break;
      case DOC_TYPE.ORDER:
        module = MODULES_NAME.ORDER;
        break;
      case DOC_TYPE.FA_ORDER:
        module = MODULES_NAME.ORDER;
        break;
      case DOC_TYPE.FA_BILL:
        module = MODULES_NAME.BILL;
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        module = MODULES_NAME.JOB_WORK_OUT;
        break;
      default:
        break;
    }

    return module as MODULES_NAME;
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (props.booksDocument.documentType) {
      case DOC_TYPE.INVOICE:
        columnConfigInfo = {
          columnConfig: invoiceColumnConfig,
          tableId: invoiceConfigTableId
        };
        break;
      case DOC_TYPE.BILL:
        columnConfigInfo = {
          columnConfig: billColumnConfig,
          tableId: billConfigTableId
        };
        break;
      case DOC_TYPE.FA_BILL:
        columnConfigInfo = {
          columnConfig: billColumnConfig,
          tableId: billConfigTableId
        };
        break;
      case DOC_TYPE.QUOTE:
        columnConfigInfo = {
          columnConfig: quoteColumnConfig,
          tableId: quoteConfigTableId
        };
        break;
      case DOC_TYPE.SALES_ORDER:
        columnConfigInfo = {
          columnConfig: salesOrderColumnConfig,
          tableId: salesOrderConfigTableId
        };
        break;
      case DOC_TYPE.ORDER:
        columnConfigInfo = {
          columnConfig: poColumnConfig,
          tableId: poConfigTableId
        };
        break;
      case DOC_TYPE.FA_ORDER:
        columnConfigInfo = {
          columnConfig: poColumnConfig,
          tableId: poConfigTableId
        };
        break;
      case DOC_TYPE.JOB_WORK_OUT_ORDER:
        columnConfigInfo = {
          columnConfig: jobWorkOutColumnConfig,
          tableId: jobWorkOutConfigTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  const getCustomFields = () => {
    return (
      <div
        className={`${
          booksDocument.customField?.length || locationData?.length
            ? 'mb-r mr-l'
            : ''
        }`}
        style={{
          marginLeft: booksDocument.customField?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={getCFModuleFromDocType()}
          customFieldsList={
            booksDocument.customField ? booksDocument.customField : []
          }
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          documentMode={props.documentMode}
          onUpdate={async (updatedCFList) => {
            // Handle location CF, when CF list is updated
            const listContainsLocation = updatedCFList.find(
              (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
            );
            if (listContainsLocation && listContainsLocation?.value) {
              const locationObjForCF = locationData.find(
                (loc: any) => loc.label === listContainsLocation.value
              );
              if (locationObjForCF) {
                setCustomLocation(locationObjForCF);
              } else if (
                props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW
              ) {
                // Handle edit mode when location CF is deleted ...
                // Get the address from payload
                const locationCFinDocument = booksDocument.customField?.find(
                  (x: any) => x.label === LOCATION_CLASS_ENUM.LOCATION
                );

                if (locationCFinDocument) {
                  if (locationCFinDocument.value === '') {
                    setCustomLocation(undefined);
                  } else {
                    try {
                      const locationObj =
                        await LocationService.getLocationByLabel(
                          locationCFinDocument.value
                        );
                      if (!Utility.isEmpty(locationObj)) {
                        setCustomLocation(locationObj);
                      }
                    } catch (err) {
                      console.error('Error loading location by label: ', err);
                    }
                  }
                }
              }
            }
            setBooksDocument((prevState: any) => {
              return {
                ...prevState,
                customField: [...updatedCFList]
              };
            });
          }}
          onLocationUpdate={(loc: LocationDTO) => {
            if (Utility.isEmpty(loc)) {
              const isBillOrOrder = [
                DOC_TYPE.BILL,
                DOC_TYPE.ORDER,
                DOC_TYPE.JOB_WORK_OUT_ORDER
              ].includes(props.booksDocument.documentType);
              const orgPreferredShippingAddress: any =
                tenantInfo.shippingAddresses?.find(
                  (address: any) => address.preferred
                );
              const orgPreferredBillingAddress: any =
                tenantInfo.billingAddresses?.find(
                  (address: any) => address.preferred
                );
              let tempDocument = { ...booksDocument };
              if (Utility.isSalesDocument(booksDocument)) {
                tempDocument = {
                  ...tempDocument,
                  shipFrom: orgPreferredShippingAddress
                };
              }
              if (isBillOrOrder) {
                if (!Utility.isFaDropship(props.booksDocument)) {
                  tempDocument = {
                    ...tempDocument,
                    billTo: orgPreferredBillingAddress,
                    shipTo: orgPreferredShippingAddress
                  };
                } else {
                  tempDocument = {
                    ...tempDocument,
                    billTo: orgPreferredBillingAddress
                  };
                }
              }
              setBooksDocument((prevState: any) => ({
                ...prevState,
                ...tempDocument
              }));
            }
            setCustomLocation(loc);
          }}
          contact={contact}
        />
      </div>
    );
  };

  //////////////////////////////////////////////////////////
  ///////////////// PRODUCT GRID HANDLERS //////////////////
  //////////////////////////////////////////////////////////
  const addNewAssetGroup = () => {
    //add new fa
    let rows = [...productRows];
    let newRow = DocumentConfigManager.getBlankRow(
      props.booksDocument.documentType
    );
    newRow['productQuantity'] = 1;
    newRow['unitPrice'] = 0;
    newRow.invalidFields = DocumentConfigManager.getRequiredFields(
      props.booksDocument.documentType
    );
    newRow['lineNumber'] = productRows.length + 1;
    newRow['firstAmountChangeDone'] = false;

    if (!Utility.isEmpty(productCustomFields)) {
      // Set default values of CFs when new line is added
      productCustomFields?.forEach((item: any) => {
        if (
          item.modules?.includes(MODULES_NAME.ASSET) &&
          item.status === STATUS_TYPE.ACTIVE
        ) {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            if (
              item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              newRow[item.id] = DateFormatService.getDateFromStr(
                item.defaultValue,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              newRow[item.id] = item.defaultValue;
            }
          } else {
            newRow[item.id] = '';
          }
        }
      });
    }

    rows.push(newRow);
    setProductRows(rows);
  };

  const clearAllItems = () => {
    setProductRows([]);
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        items: [],
        reservedStock: false
      };
    });
  };

  const toggleReserveStock = () => {
    const reservedStock = !booksDocument.reservedStock;
    if (!reservedStock) {
      resetStockReservedData();
    }
    setBooksDocument((prevState: any) => ({
      ...prevState,
      reservedStock: reservedStock
    }));
  };

  const resetStockReservedData = () => {
    let pRows = [...productRows];
    let lineItems = [...booksDocument.items];
    pRows = pRows.map((pRow: any) => {
      return {
        ...pRow,
        reservedQuantitiesData: null
      };
    });
    lineItems = lineItems.map((lineItem: any) => {
      return {
        ...lineItem,
        reservedQuantitiesData: null
      };
    });
    setProductRows(pRows);
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: lineItems
    }));
    setProductStockData({});
  };

  const [reserveStockLineItem, setReserveStockLineItem] = useState<any>();
  const [reservedQuantitiesOriginal, setReservedQuantitiesOriginal] =
    useState<any[]>();

  const fetchProductReservedInfo = ({ rowData }: any) => {
    const product = rowData?.product;
    const productId = product?.productId;
    if (!Utility.isEmpty(productId)) {
      ProductService.getProductReservedInfo([productId]).then(
        (reserveInfo: any[]) => {
          if (reserveInfo?.length) {
            const selectedProductInfo = reserveInfo?.filter(
              (obj) => obj.productCode === productId
            );

            if (selectedProductInfo?.length) {
              openReservedStockPopup(rowData, selectedProductInfo);
            }
          }
        },
        (err) => {
          console.error('Error while fetching product reserved info: ', err);
        }
      );
    }
  };

  const openReservedStockPopup = (
    lineItem: any,
    selectedProductInfo: any[]
  ) => {
    const productId = lineItem.product.id;
    const exitingReservedQuantitiesDataInLines = booksDocument.items
      ?.filter(
        (item: any) =>
          item.lineNumber !== lineItem.lineNumber &&
          item.product?.id === productId &&
          !Utility.isEmpty(item.reservedQuantitiesData)
      )
      .map((data: any) =>
        rebuildAdvancedTrackingMetaDtosAndUOMInfo(
          data.reservedQuantitiesData,
          data.documentUOMSchemaDefinition
        )
      )
      .reduce((acc: any[], curVal: any[]) => {
        return acc.concat(curVal);
      }, []);

    if (selectedProductInfo && exitingReservedQuantitiesDataInLines) {
      selectedProductInfo.forEach((obj) => {
        const warehouseObj = exitingReservedQuantitiesDataInLines.find(
          (o: any) => o.warehouseCode === obj.warehouseCode
        );
        if (warehouseObj) {
          const availableQty =
            obj.reservedQuantity + Number(warehouseObj.reservedQuantity);
          obj.reservedQuantity = availableQty < 0 ? 0 : Number(availableQty);
          if (
            obj.advancedTrackingType !== TRACKING_TYPE.NONE &&
            obj.advancedTrackingMetaDtos &&
            obj.advancedTrackingMetaDtos.length > 0 &&
            warehouseObj.advancedTrackingMetaDtos
          ) {
            obj.advancedTrackingMetaDtos.forEach((e: any) => {
              const batchObj = warehouseObj.advancedTrackingMetaDtos.find(
                (o: any) => o.serialBatchNumber === e.serialBatchNumber
              );
              if (batchObj) {
                const availableBatchQty =
                  e.reservedQuantity + Number(batchObj.reservedQuantity);
                e.reservedQuantity =
                  availableBatchQty < 0 ? 0 : Number(availableBatchQty);
              }
            });
          }
        }
      });
    }

    setReserveStockLineItem({ ...lineItem });

    convertReservedBaseUOMToSchema(selectedProductInfo, lineItem);

    setReservedQuantitiesOriginal(selectedProductInfo);
    setShowReserveStockPopup(true);
  };

  const convertReservedBaseUOMToSchema = (
    selectedProductInfo: any[],
    lineItem: any
  ) => {
    const reservedData = selectedProductInfo?.[0];
    if (
      reservedData &&
      reservedData.advancedTrackingType !== TRACKING_TYPE.SERIAL
    ) {
      let availableQuantity = lineItem.availableQuantity;
      const documentUOMSchemaDefinition = lineItem.documentUOMSchemaDefinition;
      if (
        documentUOMSchemaDefinition &&
        availableQuantity &&
        !documentUOMSchemaDefinition.isBaseUom
      ) {
        selectedProductInfo.forEach((obj) => {
          if (obj.availableQuantity) {
            obj.availableQuantity = Utility.getUomQuantityDecimalPrecision(
              obj.availableQuantity,
              documentUOMSchemaDefinition,
              10
            );
          }
          if (obj.reservedQuantity) {
            obj.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
              obj.reservedQuantity,
              documentUOMSchemaDefinition,
              10
            );
          }
          if (obj.advancedTrackingType === TRACKING_TYPE.BATCH) {
            obj.advancedTrackingMetaDtos.forEach((item: any) => {
              if (item.batchSize) {
                item.batchSize = Utility.getUomQuantityDecimalPrecision(
                  item.batchSize,
                  documentUOMSchemaDefinition,
                  10
                );
              }
              if (item.reservedQuantity) {
                item.reservedQuantity = Utility.getUomQuantityDecimalPrecision(
                  item.reservedQuantity,
                  documentUOMSchemaDefinition,
                  10
                );
              }
            });
          }
        });
      }
    }
  };

  const setTaxInclusiveFlag = () => {
    const taxInclusiveFlag = booksDocument.unitPriceGstInclusive;
    let documentItems = [...(booksDocument.items as Array<DocumentItem>)];
    documentItems = documentItems.map((item, index) => {
      return { ...item, unitPriceGstInclusive: !taxInclusiveFlag };
    });
    setRoundOffDirty(false);
    documentItems.map((item, index) =>
      calculateFATaxesAndAmount(index, documentItems)
    );
    setBooksDocument((prevState: Document) => {
      return {
        ...prevState,
        items: [...documentItems],
        unitPriceGstInclusive: !taxInclusiveFlag
      };
    });
  };

  const onTCSChange = (newTCS: any) => {
    let doc: Document = { ...booksDocument };
    doc.tcsRateId = newTCS?.id;
    doc.tcsPercentage = newTCS?.ratePercentage;
    doc.tcsAmount = getTCSAmount(doc);
    setBooksDocument(doc);
  };

  const getTCSAmount = (doc: any) => {
    let total = doc.items
      ? doc.items.reduce(
          (total: number, docItem: any) => total + docItem.total,
          0
        )
      : 0;
    return (Number(total) * Number(doc.tcsPercentage)) / 100;
  };

  const setRoundOffAmountInDocumentCurrency = (val: number) => {
    setRoundOffDirty(true);
    setBooksDocument((prevState: Document) => {
      return {
        ...prevState,
        roundOffAmountInDocumentCurrency: val
      };
    });
  };

  const updateCurrencyAndExchangeRate = (
    tempDocument: any,
    currencyCode: string,
    exchangeRate: number,
    updateState = true
  ) => {
    const preciseCurrencyExchangeRate = roundingOffStr(
      1 / exchangeRate,
      CURRENCY_PRECISION
    );
    return updateCurrency({
      tempDocument,
      currencyCode: currencyCode,
      exchangeRate: 1 / parseFloat(preciseCurrencyExchangeRate),
      gstExchangeRate: tempDocument.gstExchangeRate,
      updateState
    });
  };

  const updateCurrency = ({
    tempDocument,
    currencyCode,
    exchangeRate,
    gstExchangeRate,
    updateState = true
  }: any) => {
    const oldCurrency = tempDocument.currency;
    const currencyChanged = oldCurrency !== currencyCode;
    const previousExchangeRate = tempDocument.exchangeRate;

    let calculatedGSTExchangeRate = 1;
    if (currencyChanged && gstExchangeRate) {
      calculatedGSTExchangeRate =
        (gstExchangeRate * exchangeRate) / tempDocument.previousExchangeRate;
    }
    tempDocument = {
      ...tempDocument,
      currencyCode: currencyCode,
      currency: currencyCode,
      exchangeRate: exchangeRate,
      previousExchangeRate: previousExchangeRate,
      gstExchangeRate: calculatedGSTExchangeRate
    };

    if (updateState) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument
        };
      });
    }

    if (currencyChanged || exchangeRate) {
      if (!updateState) {
        return updateBasedOnCurrency(
          tempDocument,
          exchangeRate,
          previousExchangeRate,
          updateState
        );
      } else {
        updateBasedOnCurrency(tempDocument, exchangeRate, previousExchangeRate);
      }
    }
  };

  const updateBasedOnCurrency = (
    tempDocument: any,
    exchangeRate: number,
    previousExchangeRate: number,
    updateState = true
  ) => {
    if (!tempDocument.items) {
      return;
    }
    let documentItems = [...(tempDocument.items as Array<DocumentItem>)];
    documentItems = documentItems.map((item, index) => {
      const discount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        item.discountAmount
      );
      if (item.discountInPercent) {
        item = {
          ...item,
          discountAmount: discount
        };
      } else {
        item = {
          ...item,
          discountAmount: discount,
          discount: discount
        };
      }
      return {
        ...item,
        unitPrice: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.unitPrice
        ),
        taxAmount: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.taxAmount
        )
      };
    });

    let tcsAmount = 0;
    let totalTdsAmount = 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      tcsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.tcsAmount
      );
      totalTdsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.totalTdsAmount
      );
    }

    tempDocument = {
      ...tempDocument,
      items: documentItems,
      tcsAmount,
      totalTdsAmount
    };

    if (tempDocument.items && tempDocument.items.length > 0 && updateState) {
      // if pricelist is enabled, then calculate taxes after pricelist prices are applied
      if (isPriceListEnabled()) {
        const productsPayload = tempDocument.items
          .filter((item: any) => !Utility.isEmpty(item.product))
          .map((item: any) => ({
            productId: item.product.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          tempDocument.currency,
          true,
          exchangeRate
        );
      } else {
        if (getTenantTaxSystem() === TAX_SYSTEM.US) {
          calculateUSTax(undefined, tempDocument.items);
        } else {
          tempDocument.items.forEach((item: any, index: number) => {
            calculateFATaxesAndAmount(index, tempDocument.items);
          });
        }
      }
    } else {
      return tempDocument;
    }

    // this.calculateTaxLocalAndUpdate();
    // this.updateFormFieldConfig();
    // this.setCurrencyInDropdown();
  };

  const hideTaxAmountColumn = () => {
    let flag = true;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      Utility.isComplianceEnabled()
    ) {
      flag = false;
    }

    return flag;
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  // Update grid's column config
  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'fixedAssetGroup':
          let assetGroupArray =
            assetGroupData?.content?.length > 0 ? assetGroupData.content : [];
          conf.editable =
            (props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY ||
            props.booksDocument.isPartialInvoice ||
            props.booksDocument.isPartialBill
              ? false
              : true) && checkifCustomFieldCanEdit;
          conf.dropdownConfig.data =
            assetGroupArray.length > 0
              ? DocumentConfigUtility.fixedAssetGroupDataParser(
                  { content: assetGroupArray },
                  props.booksDocument.documentType
                )
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getAssetGroupURL(search, tenantInfo);
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.fixedAssetGroupDataParser(
              data,
              props.booksDocument.documentType
            );
          conf.dropdownConfig.button = null;
          break;
        case 'tax':
          let taxData = Utility.isSalesDocument(props.booksDocument)
            ? salesTaxes
            : purchaseTaxes;
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                documentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                documentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                documentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });

          setFilteredTaxList(taxData);

          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX)) &&
            checkifCustomFieldCanEdit;
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                props.booksDocument.documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(
              data,
              props.booksDocument.documentType
            );
          if (!checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX)) {
            conf.dropdownConfig.button = null;
          } else {
            conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true);
          }

          break;
        case 'taxAmount':
          conf.hidden = hideTaxAmountColumn();
          conf.name =
            getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
          conf.editable =
            !(!hideTaxAmountColumn() && contact && contact.taxExempted) &&
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX)) &&
            checkifCustomFieldCanEdit;
          conf.type =
            !hideTaxAmountColumn() && contact && contact.taxExempted
              ? INPUT_TYPE.TEXT
              : INPUT_TYPE.NUMBER;
          conf.formatter = (data: any) => {
            if (!hideTaxAmountColumn() && contact && contact.taxExempted) {
              // Tax exempted contact for US
              return 'Exempted';
            } else {
              return DocumentConfigUtility.amountFormatter(
                data.value,
                booksDocument.currency
              );
            }
          };
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          break;
        case 'discount':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_DISCOUNT)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_DISCOUNT)) &&
            checkifCustomFieldCanEdit;
          conf.formatter = (data: any) => {
            const row = productRows[data.rowIndex];
            return row?.discountInPercent
              ? data.value + '%'
              : DocumentConfigUtility.amountFormatter(
                  data.value,
                  booksDocument.currency
                );
          };
          break;
        case 'unitPrice':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_PRICE)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_PRICE)) &&
            checkifCustomFieldCanEdit;
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );
          conf.dropdownConfig.data = selectedProductPriceList;
          break;
        case 'productQuantity':
          conf.editable =
            ((Utility.isFaDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.FA_ORDER) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.draftType === DraftTypes.READONLY ||
            (props?.booksDocument?.documentType === DOC_TYPE.FA_ORDER &&
              props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
                'PURCHASE_REQUEST')
              ? false
              : true) && checkifCustomFieldCanEdit;
          break;
        default:
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY;
          const classData = dimensionData?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN && !!conf.dropdownConfig) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const getDiscountRelatedKeys = (discount: string) => {
    let updatedItemKeys: any = {};
    let discountInNum = 0;
    if (discount) {
      if (discount.toString().indexOf('%') > -1) {
        updatedItemKeys.discountInPercent = true;
        discountInNum = Number(discount.replace('%', ''));
      } else {
        discountInNum = Number(discount);
        updatedItemKeys.discountInPercent = false;
      }
      updatedItemKeys.discount = Utility.roundOff(discountInNum);
      return updatedItemKeys;
    } else {
      updatedItemKeys.discount = discountInNum;
    }
    return updatedItemKeys;
  };

  const getSelectedUomForEdit = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };

  const calculateFATaxesAndAmount = (
    rowIndex: number,
    unsavedRows?: any,
    isBarcode?: boolean
  ) => {
    if (!unsavedRows) {
      unsavedRows = [...productRows];
    }

    let row = { ...unsavedRows[rowIndex] };
    if (!Utility.isEmpty(row)) {
      let item = {
        ...row,
        taxCode: row?.tax?.code,
        taxName: row?.tax?.name,
        taxSystem: getTenantTaxSystem(),
        gstType: Utility.getValue(row.gstType, booksDocument.gstType),
        unitPriceGstInclusive:
          row.unitPriceGstInclusive !== null &&
          row.unitPriceGstInclusive !== undefined
            ? row.unitPriceGstInclusive
            : booksDocument.unitPriceGstInclusive,
        // unitPriceGstInclusive: booksDocument.unitPriceGstInclusive ? booksDocument.unitPriceGstInclusive : row.unitPriceGstInclusive,
        exciseAmount: 0,
        //TODO: Check
        // cessRule: row.product.cessRule,
        cessRule: null,
        isRcmApplied: null
        // isRcmApplied: rcmAppliedIndia(
        //   props.booksDocument.documentType,
        //   contact?.gstTreatment,
        //   row.product
        // )
      };
      item = {
        ...item,
        ...getDiscountRelatedKeys(
          row['discountInPercent'] ? row['discount'] + '%' : row['discount']
        )
      };
      FAItemTaxCalculator.item = item;
      if (!FAItemTaxCalculator.item.taxAmount) {
        FAItemTaxCalculator.item.taxAmount = 0;
      }
      FAItemTaxCalculator.tenantInfo = tenantInfo;
      FAItemTaxCalculator.setInitialValues();
      //Here start calculate tax
      const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
      FAItemTaxCalculator.item.taxAmount = taxAmount;
      FAItemTaxCalculator.updateCalculatedValues();
      // for landed cost calculation for india only
      setTotalAmountforLandedCost(rowIndex);
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          props.booksDocument.isPartialInvoice ||
          !Utility.isEmpty(contact))
      ) {
        setGSTValueManual(rowIndex, row, item);
      }
      FAItemTaxCalculator.item.amount =
        FAItemTaxCalculator.item.totalAmount || 0;
      unsavedRows[rowIndex] = { ...FAItemTaxCalculator.item };
      lastUpdatedIndex.current = null;
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          items: [...unsavedRows],
          isDocumentTouched:
            lineItemsTouched || prevBooksDocument.isDocumentTouched
        };
      });
      if (lineItemsTouched) {
        setLineItemsTouched(false);
      }
    }
    if (isBarcode) {
      return { ...FAItemTaxCalculator.item };
    } else {
      setProductRows([...unsavedRows]);
    }
  };

  const calculateTaxesAndAmount = (
    rowIndex: number,
    unsavedRows?: any,
    isBarcode?: boolean
  ) => {
    if (!unsavedRows) {
      unsavedRows = [...productRows];
    }

    let row = { ...unsavedRows[rowIndex] };
    if (!Utility.isEmpty(row?.product)) {
      let item = {
        ...row,
        taxCode: row?.tax?.code,
        taxName: row?.tax?.name,
        taxSystem: getTenantTaxSystem(),
        gstType: Utility.getValue(row.gstType, booksDocument.gstType),
        unitPriceGstInclusive: row.unitPriceGstInclusive,
        exciseAmount: 0,
        cessRule: row.product.cessRule,
        isRcmApplied: rcmAppliedIndia(
          props.booksDocument.documentType,
          contact?.gstTreatment,
          row.product
        )
      };
      item = {
        ...item,
        ...getDiscountRelatedKeys(
          row['discountInPercent'] ? row['discount'] + '%' : row['discount']
        )
      };
      FAItemTaxCalculator.item = item;
      if (!FAItemTaxCalculator.item.taxAmount) {
        FAItemTaxCalculator.item.taxAmount = 0;
      }
      FAItemTaxCalculator.tenantInfo = tenantInfo;
      FAItemTaxCalculator.setInitialValues();
      //Here start calculate tax
      const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
      FAItemTaxCalculator.item.taxAmount = taxAmount;
      FAItemTaxCalculator.updateCalculatedValues();
      // for landed cost calculation for india only
      setTotalAmountforLandedCost(rowIndex);
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          props.booksDocument.isPartialInvoice ||
          !Utility.isEmpty(contact))
      ) {
        setGSTValueManual(rowIndex, row, item);
      }
      FAItemTaxCalculator.item.amount =
        FAItemTaxCalculator.item.totalAmount || 0;
      unsavedRows[rowIndex] = { ...FAItemTaxCalculator.item };
      lastUpdatedIndex.current = null;
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          items: [...unsavedRows],
          isDocumentTouched:
            lineItemsTouched || prevBooksDocument.isDocumentTouched
        };
      });
      if (lineItemsTouched) {
        setLineItemsTouched(false);
      }
    }
    if (isBarcode) {
      return { ...FAItemTaxCalculator.item };
    } else {
      setProductRows([...unsavedRows]);
    }
  };

  // for landed cost calculation for india only

  const setTotalAmountforLandedCost = (rowIndex: any) => {
    let row = productRows[rowIndex];
    let landedCostDetails = row?.landedCostDetails;
    let category =
      !Utility.isEmpty(landedCostDetails) &&
      JSON.parse(landedCostDetails?.landedCostCategory);
    let flag =
      currentRowLandedCostInfo?.rowIndex === rowIndex
        ? currentRowLandedCostInfo?.rowIndex === rowIndex
        : props.documentMode === DOCUMENT_MODE.EDIT;
    if (
      flag &&
      category &&
      category.value === 'CUSTOM_DUTY' &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST
    ) {
      let totalSum = row?.landedCostDetails?.productDetails.reduce(function (
        prev: any,
        cur: any
      ) {
        return prev + cur['customDutyAndOtherCharges'];
      },
      0);
      FAItemTaxCalculator.item['totalAmount'] = Number(totalSum);
      FAItemTaxCalculator.item['total'] = Number(totalSum);
      FAItemTaxCalculator.item['subTotal'] = Number(totalSum);
      FAItemTaxCalculator.item['unitPrice'] = Number(totalSum);
      let sampleTax = purchaseTaxes.find((tax) => tax.percent === 0);
      FAItemTaxCalculator.item['tax'] = sampleTax;
      FAItemTaxCalculator.item['taxCode'] = sampleTax.code;
      FAItemTaxCalculator.item['taxDetails'] = [];
      FAItemTaxCalculator.item['taxName'] = sampleTax.name;
      const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
      FAItemTaxCalculator.item.taxAmount = taxAmount;
      setCurrentRowLandedCostInfo(null);
    }
  };

  const setGSTValueManual = (rowIndex: any, productRow: any, rowItem: any) => {
    let row = productRow;
    let gstType = rowItem.gstType ? rowItem.gstType : row && row.gstType;
    if (typeof gstType === 'undefined' || gstType === null || gstType === '') {
      gstType = updateGstType(booksDocument);
    }
    if (
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST &&
      FAItemTaxCalculator.item &&
      row &&
      row.userSetTaxes &&
      gstType !== GST_TYPE.EXEMPT
    ) {
      let amount = 0;
      if (gstType === GST_TYPE.INTER) {
        amount = Number(row.igstAmount);
      } else if (gstType === GST_TYPE.INTRA) {
        amount = Number(row.cgstAmount) + Number(row.sgstAmount);
      }
      let subTotal = row.subTotal;
      if (Utility.isEmpty(subTotal)) {
        subTotal = rowItem.subTotal;
      }
      if (!row.unitPriceGstInclusive) {
        let per = (Number(amount) / Number(subTotal)) * 100;
        let tax = deepClone(FAItemTaxCalculator.item['tax']);
        let prevTax = FAItemTaxCalculator.item['tax'];
        tax['percent'] = per;
        tax['isTaxGroup'] = false;
        FAItemTaxCalculator.item['tax'] = tax;
        if (gstType === GST_TYPE.INTER) {
          FAItemTaxCalculator.item['igstAmount'] = row.igstAmount;
        }
        FAItemTaxCalculator.item.taxAmount =
          FAItemTaxCalculator.calculateTaxAmount();
        FAItemTaxCalculator.item['tax'] = prevTax;
        FAItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          FAItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          FAItemTaxCalculator.item['igstRate'] = prevTax.percent;
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Number(row.cgstAmount) + Number(row.sgstAmount);
          FAItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          FAItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
          FAItemTaxCalculator.item['cgstRate'] = prevTax.percent / 2;
          FAItemTaxCalculator.item['sgstRate'] = prevTax.percent / 2;
        }
      } else {
        // making unitPriceGstInclusive flag false for calculating subtotal without unitPriceGstInclusive flag
        //for calculating manual user taxes and subtotal
        // FAItemTaxCalculator.item.unitPriceGstInclusive = false;
        FAItemTaxCalculator.setSubTotal();
        let calculatedSubTotal =
          FAItemTaxCalculator.item.subTotal || row.subTotal;
        // FAItemTaxCalculator.item.unitPriceGstInclusive = true;
        FAItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          FAItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          FAItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            row.igstAmount + row.cessAmount,
            tenantInfo.decimalScale
          );
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Utility.roundingOff(
            Number(row.cgstAmount) + Number(row.sgstAmount),
            tenantInfo.decimalScale
          );
          FAItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            amount + row.cessAmount,
            tenantInfo.decimalScale
          );
          FAItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          FAItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
        }
        if (FAItemTaxCalculator && FAItemTaxCalculator.item) {
          let taxAmount = FAItemTaxCalculator?.item?.taxAmount || 0;
          FAItemTaxCalculator.item.subTotal = Utility.roundingOff(
            calculatedSubTotal,
            tenantInfo.decimalScale
          );
        }
      }
      setCurrentRowGSTInfo(null);
    }
  };

  /// For new Tax System of SG (Product tax to new tax mapping)
  const getDefaultTaxForSG = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };

  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = null;
      if (Utility.isSalesDocument(props.booksDocument)) {
        taxCode = product.salesTaxCode;
        tax = salesTaxes.find((tax) => tax.code === taxCode);
        tax = getDefaultTaxForSG(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      } else if (
        booksDocument.documentType === DOC_TYPE.BILL ||
        booksDocument.documentType === DOC_TYPE.FA_BILL ||
        booksDocument.documentType === DOC_TYPE.FA_ORDER ||
        booksDocument.documentType === DOC_TYPE.ORDER ||
        booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        taxCode = product.purchaseTaxCode;
        tax = purchaseTaxes.find((tax: any) => tax.code === taxCode);
        tax = getDefaultTaxForSG(tax);
        return tax ? tax : taxCode === '' ? null : taxCode;
      }
    }
    return tax;
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  useEffect(() => {
    if (productToEdit) {
      setShowProductPopup(true);
    }
  }, [productToEdit]);

  const validateProduct = (product: any) => {
    const offeringType = product.offeringType;
    const productType = product.type;
    const hsnOrSacCode = product.hsnOrSacCode;
    const uqcIndia = product.uqcIndia;

    let showPopup = false;
    let message = '';

    if (
      offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
      (productType === PRODUCT_TYPE.TRACKED ||
        productType === PRODUCT_TYPE.NON_TRACKED) &&
      (Utility.isEmpty(hsnOrSacCode) || Utility.isEmpty(uqcIndia))
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code and UQC required. Please update or select another product.';
    }

    if (
      offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
      productType === PRODUCT_TYPE.NON_TRACKED &&
      Utility.isEmpty(hsnOrSacCode)
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code required. Please update or select another product.';
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && showPopup) {
      showAlert('Missing details', message, [
        {
          title: 'Cancel',
          className: 'bg-gray1 text-black-light',
          onClick: () => {
            return;
          }
        },
        {
          title: 'Update Product',
          className: 'bg-button text-white ml-2',
          onClick: () => {
            setProductToEdit(product);
            return;
          }
        }
      ]);
      return false;
    }
    return true;
  };

  // Update this for user CF
  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (
              filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DROPDOWN.toLowerCase() ||
              filteredCF.fieldType.toLowerCase() === 'user'
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };
  const addFACustomFieldsToLineItem = (lineItem: any, asset: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(asset.customField)
    ) {
      // Set default values of CFs when new line is added
      asset.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (
              filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DROPDOWN.toLowerCase() ||
              filteredCF.fieldType.toLowerCase() === 'user'
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const onProductChange = (
    rowIndex: number,
    product: any,
    isBarcode?: boolean,
    items?: DocumentItem[]
  ) => {
    // setUomsForSelectedProduct(product);
    if (!booksDocument.isDocumentTouched) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }

    let rows = items ? [...items] : [...productRows];
    let selectedRow = rows[rowIndex];
    if (!Utility.isEmpty(product)) {
      let updatedLineItem: DocumentItem | any = {} as any;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.COPY
      ) {
        updatedLineItem =
          !Utility.isEmpty(selectedRow.productName) &&
          product.name === selectedRow.productName
            ? {
                ...selectedRow,
                gstType: Utility.getValue(
                  selectedRow.gstType,
                  booksDocument.gstType
                )
              }
            : createLineItem(
                product,
                Utility.getValue(selectedRow.gstType, booksDocument.gstType),
                selectedRow.lineNumber,
                booksDocument.documentType as DOC_TYPE
              );
      } else {
        updatedLineItem = createLineItem(
          product,
          Utility.getValue(selectedRow.gstType, booksDocument.gstType),
          selectedRow.lineNumber,
          booksDocument.documentType as DOC_TYPE
        );
      }

      updatedLineItem = {
        ...updatedLineItem,
        advancedTracking: product.advancedTracking,
        unitPriceGstInclusive: selectedRow.unitPriceGstInclusive
      };

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (contact && product) {
          updatedLineItem = {
            ...updatedLineItem,
            isRcmApplied: rcmAppliedIndia(
              props.booksDocument.documentType,
              contact.gstTreatment,
              product
            )
          };
        }
      } else if (getTenantTaxSystem() === TAX_SYSTEM.MALAYSIA) {
        updatedLineItem = {
          ...updatedLineItem,
          isTaxable: product ? product.exemptedMalaysia : true
        };
      }

      if (
        Utility.isSalesDocument(props.booksDocument) &&
        tenantInfo.registeredToCompositionScheme
      ) {
        updatedLineItem = {
          ...updatedLineItem,
          compositionTaxPercent:
            COMPOSTION_TAX_PERCENT[tenantInfo.compositionSchemeType]
        };
      }

      let tax = getTax({ ...product });

      updatedLineItem.invalidFields = updatedLineItem.invalidFields
        ? [...updatedLineItem.invalidFields].filter(
            (field) => field !== 'unitPrice'
          )
        : [];
      if (updatedLineItem.unitPrice === 0) {
        updatedLineItem.invalidFields.push('unitPrice');
      }

      if (
        (typeof product.taxPreference !== 'undefined' &&
          product.taxPreference !== null &&
          !product.taxPreference) ||
        !updatedLineItem.isTaxable
      ) {
        updatedLineItem.nonEditableColumns = updatedLineItem.nonEditableColumns
          ? [...updatedLineItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedLineItem?.nonEditableColumns?.push('tax');
      }

      //Update UOM Values

      if (isBarcode) {
        updatedLineItem.discount = product.discountAmount;
        updatedLineItem.discountAmount = product.discountAmount;
      }

      updatedLineItem.uomQuantity = getUomQuantity(
        selectedRow.productQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomUnitPrice = getUomPrice(
        selectedRow.unitPrice,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomAvailableQuantity = getUomQuantity(
        selectedRow.availableQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomFulfilledQuantity = getUomQuantity(
        selectedRow.fulfilledQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomQuantityFulfilled = getUomQuantity(
        selectedRow.quantityFulfilled,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomPendingQuantity = getUomQuantity(
        selectedRow.pendingQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInBills = getUomQuantity(
        selectedRow.receivedQuantityInBills,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInOrder = getUomQuantity(
        selectedRow.receivedQuantityInOrder,
        selectedRow.documentUOMSchemaDefinition
      );

      // updatedLineItem.uomUnitPrice = selectedRow.unitPrice;
      // updatedLineItem.uomQuantity = selectedRow.productQuantity;

      updatedLineItem = addProductCustomFieldsToLineItem(
        { ...updatedLineItem },
        product
      );

      updatedLineItem = {
        ...updatedLineItem,
        unitPrice: updatedLineItem.unitPrice
          ? updatedLineItem.unitPrice * booksDocument.exchangeRate
          : 0,
        unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
        documentUom: product.stockUom,
        cessRule: product.cessRule,
        cessRuleDescription: product.cessRuleDescription,
        cessNonAdvol: product?.cessNonAdvol,
        stockUom: product.stockUom,
        reservedQuantitiesData: null
      };
      rows[rowIndex] = { ...updatedLineItem, tax };
      lastUpdatedIndex.current = rowIndex;

      if (isBarcode) {
        return { ...updatedLineItem, tax };
      } else {
        setProductRows(rows);
        const productsPayload = [
          { productId: product.productId, uomId: product.stockUom, quantity: 1 }
        ];
        getPricing(
          productsPayload,
          rows,
          booksDocument.currency,
          false,
          booksDocument.exchangeRate
        );
        fetchProductUnitPrice(rows);
      }
    }
  };

  const getClassFieldForProduct = (product: any) => {
    const classField = product?.customField?.find(
      (field: any) => field.label === LOCATION_CLASS_ENUM.CLASS
    );
    return classField;
  };

  const onRowClick = ({ rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    // lastUpdatedIndex.current = rowIndex;
    setUomsForSelectedProduct(lineitem.product);
    setPricesForSelectedProduct(lineitem);

    // Set current index to know on which row user clicked, before adding the product
    setCurrentIndex(rowIndex);
  };

  const setPricesForSelectedProduct = (lineItem: any) => {
    if (
      lineItem &&
      !Utility.isEmpty(lineItem.product) &&
      !Utility.isEmpty(productsLastUnitPrice) &&
      productsLastUnitPrice[lineItem.product.productId]
    ) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.product.productId]
      ]);
    }
  };

  const getUomQuantity = (
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) => {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor,
        tenantInfo.decimalScale
      );
    }
    return baseQuantity;
  };

  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };

  const updateUomSchemaForLineItem = (index: number, uomData: any) => {
    let rows: any = [...productRows];
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;
    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.product.uomSchemaDto.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
    }
    return selectedRow;
  };

  const isPriceListEnabled = () => {
    const hiddenApps = appCustomizationData?.hiddenApps?.split(',');
    return !hiddenApps?.includes('PRICE_LIST');
  };

  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any
  ) => {
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      isPriceListEnabled()
    ) {
      if (Utility.isEmpty(contact)) {
        return;
      }
      let payload = {
        contactCode: contact.code ? contact.code : '',
        priceListPricingItems: productsPayload,
        type: getMainModuleName(props.booksDocument.documentType),
        currency: currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );

        if (
          !Utility.isEmpty(changedValues) &&
          (changedValues.contactChanged ||
            changedValues.dateChanged ||
            changedValues.quantityChanged ||
            changedValues.uomChanged)
        ) {
          showPriceListAlert(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        } else {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate as number,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };

  const showPriceListAlert = (
    productsPayload: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    const alertButtonConfig = [
      {
        title: "Don't update",
        className: 'bg-gray2 border-m ',
        onClick: () => {
          // reset isDateUpdatedManually && isContactChangedManually
          isDocDateUpdatedManually.current = false;
          isContactChangedManually.current = false;
          isQuantityChangedManually.current = false;
          isUOMChangedManually.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white ml-r',
        onClick: () => {
          processUpdatePricingValue(
            productsPayload,
            lineItems,
            exchangeRate,
            priceListResponse,
            updateExistingItem,
            shippingInfo
          );
        }
      }
    ];

    showAlert(
      'Update Unit price',
      'Do you want to update all unit prices to follow the Price List of the new invoice date?',
      alertButtonConfig
    );
  };

  const processUpdatePricingValue = (
    productsToUpdate: any[],
    lineItems: any[],
    exchangeRate: number,
    priceListResponse: any,
    updateExistingItem: boolean,
    shippingInfo: any
  ) => {
    let itemsToUpdate: any[] = [];
    productsToUpdate.forEach((productToUpdate) => {
      let itemToUpdate: any = {};
      if (updateExistingItem) {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId === item.productCode &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            (!itemsToUpdate.length ||
              itemsToUpdate.findIndex(
                (x) => x.lineNumber === item.lineNumber
              ) === -1)
        );
      } else {
        itemToUpdate = lineItems.find(
          (item: any) =>
            productToUpdate.productId === item.productCode &&
            productToUpdate.quantity === +item.productQuantity &&
            productToUpdate.uomId === item.documentUom &&
            currentIndex === item.lineNumber - 1
        );
      }

      if (!Utility.isEmpty(itemToUpdate)) {
        itemsToUpdate.push(itemToUpdate);
      }
    });

    itemsToUpdate = itemsToUpdate.map((item, index) => {
      if (
        // productsToUpdate.includes(item.product.productId) &&
        updateExistingItem ||
        (!updateExistingItem && currentIndex === item.lineNumber - 1)
      ) {
        const priceIndexInPriceList = priceListResponse.findIndex(
          (resp: any) =>
            resp.productId === item.productCode &&
            resp.quantity === +item.productQuantity &&
            resp.uomId === item.documentUom
        );

        const priceInPriceList = priceListResponse[priceIndexInPriceList].price;
        const isPricePresentInPriceList = priceIndexInPriceList !== -1;

        let productData = item.product ? item.product : undefined;
        let unitPrice = productData
          ? isPricePresentInPriceList
            ? priceInPriceList
            : getMainModuleName(props.booksDocument.documentType) ===
              MODULE_NAME_FOR_STORAGE_UTILITY.SELL
            ? productData.salesPrice
            : productData.purchasePrice
          : 0;

        if (!isPricePresentInPriceList) {
          unitPrice = unitPrice ? unitPrice * exchangeRate : 0;
        } else {
          if (unitPrice <= 0) {
            unitPrice = item.unitPrice;
          }
        }

        if (
          props.documentMode === DOCUMENT_MODE.NEW &&
          (item.isPartialInvoice || item.isPartialBill) &&
          item.product.stockUom === UOM_NA_ID
        ) {
          unitPrice = item.pendingAmount; // Need to check pending amount usage
        }

        if (typeof unitPrice !== 'undefined' && unitPrice !== null) {
          item = { ...item, unitPrice, uomUnitPrice: unitPrice };
        }
      }
      return item;
    });

    itemsToUpdate.forEach((item) => {
      // Remove 'unitPrice' from invalidFields if unit price is not 0
      const unitPriceIndexInInvalidFieldsArr = item.invalidFields?.findIndex(
        (field: string) => field === 'unitPrice'
      );
      if (item.unitPrice !== 0 && unitPriceIndexInInvalidFieldsArr !== -1) {
        item.invalidFields?.splice(unitPriceIndexInInvalidFieldsArr, 1);
      }

      const index = lineItems.findIndex(
        (x) => x.lineNumber === item.lineNumber
      );
      if (index !== -1) {
        lineItems[index] = item;
      }
    });

    // Calculate taxes based on updated line items
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (updateExistingItem) {
        calculateUSTax(undefined, lineItems, shippingInfo);
      } else {
        calculateUSTax(currentIndex, lineItems, shippingInfo);
      }
    } else {
      lineItems.forEach((item: any, index: number) => {
        calculateFATaxesAndAmount(index, lineItems);
      });
    }

    // reset isDateUpdatedManually && isContactChangedManually
    isDocDateUpdatedManually.current = false;
    isContactChangedManually.current = false;
    isQuantityChangedManually.current = false;
    isUOMChangedManually.current = false;
  };

  const fetchProductUnitPrice = async (rows?: DocumentItem[]) => {
    const inlineProductsList = rows?.length ? [...rows] : [...productRows];
    let productCodes: string[] = [];
    inlineProductsList.forEach((item) => {
      if (!Utility.isEmpty(item.product)) {
        const code = item.product.productId || item.productCode;
        productCodes.push(code);
      }
    });
    const contactCode = Utility.isObject(contact) ? contact.code : '';
    if (
      productCodes.length > 0 &&
      props.booksDocument.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      try {
        const unitePriceData = await PriceListService.fetchLastPricesOfProduct(
          productCodes,
          props.booksDocument.documentType,
          contactCode
        );
        const unitPricesList = unitePriceData.unitPrices;
        unitPricesList.forEach((product: any) => {
          setProductsLastUnitPrice((prevState: any) => {
            return {
              ...prevState,
              [product.productCode]: product.lastUnitPrices
            };
          });
        });
      } catch (err) {
        console.error('Error fetchin last 3 prices of product: ', err);
      }
    }
  };

  // Check if user entered quantity is greater than pending quantity
  const isQuantityMoreThatPendingQuantity = (
    quantity: number,
    lineItem: any
  ) => {
    let pendingQuantity = +lineItem.pendingQuantity;
    if (!Utility.isEmpty(lineItem.documentUOMSchemaDefinition)) {
      pendingQuantity = pendingQuantity * lineItem.uomQuantity;
    }

    return quantity > pendingQuantity;
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    selectedRows.invalidFields = selectedRows.invalidFields
      ? [...selectedRows.invalidFields].filter((field) => field !== columnKey)
      : [];

    setRoundOffDirty(false);
    if (
      columnKey === 'fixedAssetGroup' ||
      columnKey === 'productQuantity' ||
      columnKey === 'unitPrice' ||
      columnKey === 'discount' ||
      columnKey === 'tax'
    ) {
      if (!Utility.isEmpty(selectedRows.assetDetails)) {
        showAlert('Note', 'Asset Details will be resetted');
        selectedRows.assetDetails = [];
      }
    }

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      rows[rowIndex]['userSetTaxes'] = false;
    }
    switch (columnKey) {
      case 'fixedAssetGroup':
        setCurrentIndex(rowIndex);
        let updatedData = {
          ...dataToUpdate,
          customField: []
        };
        // if (!validateProduct(rowData[columnKey])) {
        //   if (Utility.isEmpty(rows[rowIndex]?.product)) {
        //     rows[rowIndex] = {};
        //     selectedRows.invalidFields.push('product');
        //     setProductRows(rows);
        //   } else {
        //     setProductRows(rows);
        //   }
        //   return;
        // }

        rows[rowIndex][columnKey] = updatedData;
        if (Utility.isEmpty(rows[rowIndex]['customField'])) {
          rows[rowIndex]['customField'] = [];
        }

        // if (!rows[rowIndex][columnKey].customField) {
        //     rows[rowIndex][columnKey].customField = []
        // }

        // rows[rowIndex] = addFACustomFieldsToLineItem({...row[row[index], dataToUpdate]})
        break;
      case 'product':
        setCurrentIndex(rowIndex);
        if (!validateProduct(rowData[columnKey])) {
          if (Utility.isEmpty(rows[rowIndex]?.product)) {
            rows[rowIndex] = {};
            selectedRows.invalidFields.push('product');
            setProductRows(rows);
          } else {
            setProductRows(rows);
          }
          return;
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'productQuantity':
        if (!dataToUpdate || isNaN(dataToUpdate)) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        } else {
          dataToUpdate = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
          selectedRows.uomQuantity = Utility.roundOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (rowData.isPartialInvoice ||
            rowData.isPartialBill ||
            rowData.isPartialSalesOrder) &&
          isQuantityMoreThatPendingQuantity(+dataToUpdate, rowData)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
          showAlert(
            'Error!',
            `Quantity should not be more than pending quantity`
          );
        }
        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (+dataToUpdate === 0 || +dataToUpdate < 0)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        }
        if (!selectedRows.invalidFields.includes('productQuantity')) {
          if (!Utility.isEmpty(selectedRows?.product)) {
            isQuantityChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +dataToUpdate
                    : item.productQuantity
              }));

            rows[rowIndex][columnKey] = +dataToUpdate;

            // Fetch price list price after first change for new rows.
            // For existing rows price list price will be fetched as usual.
            let fetchUnitPrice = false;
            if (
              typeof rows[rowIndex]['id'] !== 'undefined' &&
              rows[rowIndex]['id'] !== null
            ) {
              fetchUnitPrice = true;
            } else {
              if (!rows[rowIndex]['firstAmountChangeDone']) {
                rows[rowIndex]['firstAmountChangeDone'] = true;
              } else {
                fetchUnitPrice = true;
              }
            }
            if (fetchUnitPrice) {
              getPricing(
                products,
                rows,
                booksDocument.currency,
                true,
                booksDocument.exchangeRate,
                { quantityChanged: true }
              );
            }
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      case 'unitPrice':
        if (dataToUpdate === '' || +dataToUpdate === 0 || +dataToUpdate < 0) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('unitPrice');
        } else {
          dataToUpdate = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
          selectedRows.uomUnitPrice = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'discount':
        FAItemTaxCalculator.item = selectedRows;
        FAItemTaxCalculator.setInitialValues();
        const taxAmount = FAItemTaxCalculator.calculateTaxAmount();
        FAItemTaxCalculator.item.taxAmount = taxAmount;
        FAItemTaxCalculator.updateCalculatedValues();
        const matcher = String(dataToUpdate).match(REGEX.PERCENT_NUMER);
        rows[rowIndex].discountInPercent = false;
        if (!matcher) {
          dataToUpdate = 0;
        } else if ('%' === matcher[4]) {
          const percentDiscount = Number(dataToUpdate.replace('%', ''));
          if (percentDiscount > 100) {
            dataToUpdate = 100;
          } else {
            dataToUpdate = percentDiscount;
          }
          rows[rowIndex].discountInPercent = true;
        } else if (
          dataToUpdate < 0 ||
          (dataToUpdate as number) >
            (FAItemTaxCalculator?.item?.subTotal as number)
        ) {
          selectedRows.invalidFields.push('discount');
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'tax':
        // If product is non-taxable, then don't update the tax value.
        // We need to handle this in grid by making the cell non-editable.
        // if (
        //   typeof selectedRows.product.taxPreference !== 'undefined' &&
        //   selectedRows.product.taxPreference !== null &&
        //   !selectedRows.product.taxPreference
        // ) {
        //   dataToUpdate = null;
        // }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'taxAmount':
        const tax = selectedRows.tax;
        const updates: any = {
          taxCode: tax ? tax.code : '',
          taxName: tax ? tax.name : ''
        };
        if (!tax) {
          updates.taxAmount = 0;
        }
        rows[rowIndex] = {
          ...rows[rowIndex],
          ...updates
        };
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'uom':
        rows[rowIndex] = updateUomSchemaForLineItem(rowIndex, dataToUpdate);
        if (!selectedRows.invalidFields.includes('uom')) {
          if (
            !Utility.isEmpty(selectedRows?.product) &&
            !selectedRows.invalidFields.includes('productQuantity')
          ) {
            isUOMChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? rows[rowIndex]?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +selectedRows?.productQuantity
                    : item.productQuantity
              }));
            getPricing(
              products,
              rows,
              booksDocument.currency,
              true,
              booksDocument.exchangeRate,
              { uomChanged: true }
            );
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      default:
        const CFColumnConfig = columnConfig?.find(
          (cf: any) => cf?.id === columnKey && cf.isCustomField
        );
        const filteredCF: any = productCustomFields?.find(
          (field: any) => field.id === columnKey
        );
        let cfValue = '';
        if (!Utility.isEmpty(filteredCF)) {
          if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.USER.toLowerCase()
          ) {
            const tempCFOption = filteredCF?.attributes?.find(
              (attr: any) => attr.code === dataToUpdate.code
            );
            if (tempCFOption) {
              cfValue = tempCFOption?.code;
            }
          } else if (
            filteredCF.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = dataToUpdate?.value;
          } else {
            cfValue =
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
                ? DateFormatService.getDateStrFromDate(
                    new Date(dataToUpdate),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : dataToUpdate;
          }
          if (CFColumnConfig && filteredCF) {
            const cfToUpdate = {
              id: filteredCF.id,
              shortName: filteredCF.shortName,
              module: filteredCF.module,
              code: filteredCF.code,
              label: filteredCF.label,
              value: cfValue
            };
            let existingCFs = [...rows[rowIndex].customField];
            const existingCFIndex = existingCFs.findIndex(
              (cf: any) => cf?.id === cfToUpdate?.id
            );
            if (existingCFIndex === -1) {
              existingCFs = [...existingCFs, cfToUpdate];
            } else {
              existingCFs[existingCFIndex] = cfToUpdate;
            }
            rows[rowIndex].customField = existingCFs;
          }
          rows[rowIndex][columnKey] =
            filteredCF?.fieldType?.toLowerCase() ===
            INPUT_TYPE.DATE.toLowerCase()
              ? new Date(dataToUpdate)
              : Utility.isObject(dataToUpdate)
              ? dataToUpdate.value
              : dataToUpdate;
          break;
        } else {
          rows[rowIndex][columnKey] = Utility.isObject(dataToUpdate)
            ? dataToUpdate.value
            : dataToUpdate;
          break;
        }
    }
    // if (columnKey !== 'uom') {
    // rows[rowIndex][columnKey] = dataToUpdate;
    // }
    lastUpdatedIndex.current = rowIndex;
    if (columnKey === 'product') {
      onProductChange(rowIndex, dataToUpdate);
    } else {
      setProductRows(rows);
      setLineItemsTouched(true);
    }
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...productRows];
    rows.splice(rowIndex, 1);

    // Update existing Line Number in case of copy
    if (existingLineNumbers.includes(rowIndex + 1)) {
      let existingLineNumbersCopy = [...existingLineNumbers];
      let indexToDelete = existingLineNumbersCopy.findIndex(
        (x) => x === rowIndex + 1
      );
      if (indexToDelete !== -1) {
        existingLineNumbersCopy.splice(indexToDelete, 1);
        setExistingLineNumbers(existingLineNumbersCopy);
      }
    }

    rows = rows.map((row: DocumentItem, index: number) => {
      return {
        ...row,
        lineNumber: index + 1
      };
    });
    lastUpdatedIndex.current = null;
    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows],
        reservedStock: rows.length ? prevBooksDocument.reservedStock : false
      };
    });
  };

  const onDeductTDS = ({ rowIndex, rowData }: any) => {
    let rows = [...productRows];
    const lineAmount = rowData.totalWithDiscount;
    const payableAccount = DEFAULT_TDS_PAYABLE_ACCOUNT_CODE;
    const assessableAmount = lineAmount;
    const natureOfPayment = rowData?.product?.tdsNatureOfPaymentIndia;
    let documentDate = booksDocument?.documentDate;

    if (!booksDocument.contact && !documentDate && !natureOfPayment) return;

    let tdsInfoData = {
      lineAmount,
      assessableAmount,
      incomePayment: rowData?.product?.tdsNatureOfPaymentIndia,
      payableAccount,
      contact: booksDocument.contact.tdsInfoIndia
        ? booksDocument.contact
        : booksDocument.contactDto,
      documentDate: DateFormatService.getFormattedDateString(
        documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      isDeductedTds: isTDSDeducted
    };
    if (
      rowData?.tdsInfoIndia &&
      tdsInfoData.lineAmount === rowData.tdsInfoIndia.lineAmount
    ) {
      tdsInfoData = {
        ...rowData.tdsInfoIndia,
        contact: booksDocument.contact.tdsInfoIndia
          ? booksDocument.contact
          : booksDocument.contactDto,
        isDeductedTds: isTDSDeducted,
        documentDate: DateFormatService.getFormattedDateString(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY'],
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };
    }

    rows[rowIndex] = {
      ...rowData,
      isTdsApplicableContact:
        booksDocument?.contact?.tdsInfoIndia?.deductionApplicable,
      isTdsApplicableProduct: rowData?.product?.tdsApplicableIndia,
      isTdsApplicableAccount: false
    };
    setCurrentRowTDSInfo(tdsInfoData);

    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows]
      };
    });
    setTimeout(() => (lastUpdatedIndex.current = rowIndex), 1000);
  };

  const onOpenITCOptions = ({ rowIndex, rowData }: any) => {
    let itcValue = rowData.itcIneligibleType;
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowITCInfo({ rowIndex: rowIndex, itcValue: itcValue });
  };

  const onOpenHSN_SAC = ({ rowIndex, rowData }: any) => {
    let hsnVal = rowData.hsnOrSacCode;
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowHSNInfo({ rowIndex: rowIndex, hsnVal: hsnVal });
  };

  const onOpenGSTValue = ({ rowIndex, rowData }: any) => {
    if (Utility.isEmpty(contact)) {
      showAlert('Warning!', 'Please select conatct to edit tax.');
    } else {
      lastUpdatedIndex.current = rowIndex;
      setCurrentRowGSTInfo({ rowIndex: rowIndex, rowData: rowData });
    }
  };

  const onOpenAllocateCost = ({ rowIndex, rowData }: any) => {
    setShowLandedCostPopup(true);
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowLandedCostInfo({ rowIndex: rowIndex });
  };

  const getProductForm = () => (
    <CreateProductView
      product={productToEdit}
      needAdvanceTrackPopupAfterSave={true}
      passingInteraction={(callback: CallBackPayloadType) => {
        if (callback.type === POPUP_CALLBACKS_TYPE.CLOSE_POPUP) {
          if (lastUpdatedIndex.current !== null) {
            onRowUpdate({
              columnKey: 'product',
              rowIndex: lastUpdatedIndex,
              rowData: { product: callback.data }
            });
          }
          setShowProductPopup(false);
        }
      }}
      onCancel={() => {
        setShowProductPopup(false);
        setProductToEdit(null);
      }}
      selectedTab={productToEdit ? 3 : 0}
      onSuccess={(product: any) => {
        setShowProductPopup(false);
        setProductToEdit(null);
        if (
          Utility.isSalesDocument(props.booksDocument) ||
          (product.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
            (props.booksDocument.documentType === DOC_TYPE.BILL ||
              props.booksDocument.documentType === DOC_TYPE.ORDER ||
              booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER))
        ) {
          onProductChange(currentIndex, product);
        }
        setCurrentIndex(-1);
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false'
          })
        );
      }}
    />
  );

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const getTaxForm = () => (
    <PopupWrapper
      clickAction={catchClicks}
      type={POPUP_TYPE.POPUP}
      title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
      btnList={[
        {
          title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
          class: 'border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: t(`SETTINGS.TAX.BUTTON.CREATE`),
          class: 'bg-app text-white mr-ss',
          clickAction: POPUP_CLICK_TYPE.CREATE_TAX
        }
      ]}
      width="45%"
      height="75%"
    >
      <AddTax
        populateFormData={null}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );

  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={booksDocument.currency}
        GstExchangeRate={booksDocument.gstExchangeRate}
        onClose={() => setShowTaxExchangeRatePopup(false)}
        onUpdateTaxRate={(rate: number) => {
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              gstExchangeRate: rate
            };
          });
          setShowTaxExchangeRatePopup(false);
        }}
      />
    );
  };

  // Add new item when no products found
  useEffect(() => {
    if (props.canValidate) {
      if (!productRows.length) {
        addNewAssetGroup();
      }

      if (
        (props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.FA_BILL) &&
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (!booksDocument.shipTo?.state || !booksDocument.shipFrom?.state)
      ) {
        let tmpBooks = deepClone(booksDocument);
        if (
          tenantInfo &&
          tenantInfo.shippingAddresses &&
          tenantInfo.shippingAddresses.length > 0
        ) {
          let tenantShipToAddress = tenantInfo.shippingAddresses[0];
          tmpBooks.shipTo = {
            ...tmpBooks.shipTo,
            ...tenantShipToAddress
          };
          setBooksDocument(tmpBooks);
        } else {
          showAlert(
            'Missing Information!',
            'Missing "State" information in "Ship To" or "Ship From" address.'
          );
        }
      } else if (!checkGSTINPresentForSelectedContact(booksDocument)) {
        const alertButtonConfig = [
          {
            title: 'Cancel',
            className: 'bg-gray2 border-m mr-s mt-r',
            onClick: () => {}
          },
          {
            title: 'Update',
            className: 'bg-button text-white mt-r',
            onClick: () => {
              setContactMode(DOCUMENT_MODE.EDIT);
              setShowAddContactPopup(true);
            }
          }
        ];
        showAlert(
          'GSTIN required',
          'Please add GSTIN in contact form.',
          alertButtonConfig
        );
      }
    }
  }, [props.canValidate]);

  // Datagrid and columns
  const getDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needColumnIcons={false}
      needBorder={true}
      needTrailingColumn={false}
      allowBulkOperation={false}
      allowColumnSort={false}
      filterData={[]}
      allowColumnDelete={false}
      allowRowEdit={true}
      allowColumnEdit={false}
      allowFilter={false}
      allowColumnAdd={false}
      allowBottomRowAdd={false}
      allowSearch={false}
      allowShare={false}
      rows={productRows.map((rowData) => getDataRows(rowData))}
      columns={getDataGridColumns()}
      onRowUpdate={onRowUpdate}
      onRowClick={onRowClick}
    />
  );

  const getDataRows = (rowData: any) => {
    if (!Utility.isFaDropship(props.booksDocument)) {
      return {
        ...rowData,
        rowContextMenu:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? getContextMenu(rowData)
            : getContextMenu(rowData),
        rowButtons:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getButtonsForRow(rowData)
      };
    } else {
      return {
        ...rowData
      };
    }
  };

  const getDataGridColumns = () => {
    let columns = [...columnConfig];

    /* if (
      props?.booksDocument?.documentType === DOC_TYPE.ORDER &&
      props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
        'PURCHASE_REQUEST'
    ) {
      columns.map((col: any) => {
        if (col.key === 'productQuantity') {
          col.editable = false;
        } else if (col.key === 'product') {
          col.editable = false;
        }
      });
    } */

    columns.push({
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: booksDocument.reservedStock ? 80 : 50,
      options:
        props.documentMode === DOCUMENT_MODE.VIEW ||
        props.draftType === DraftTypes.READONLY ||
        props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
        Utility.isFaDropship(props.booksDocument)
          ? []
          : tenantInfo?.country === TAX_SYSTEM.INDIA_GST
          ? []
          : [
              {
                icon: DKIcons.ic_delete,
                className: 'p-0 padding-top-2px',
                onClick: (data: any) => onDelete(data)
              }
            ]
    });
    return columns;
  };
  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = () => {
    let moduleType = '';
    const docCopy = { ...props.booksDocument } as any;
    if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.INVOICE
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      docCopy.isPartialSalesOrder &&
      docCopy.isConverting &&
      docCopy.documentType === DOC_TYPE.SALES_ORDER
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.QUOTE];
    } else if (
      docCopy.isPartialInvoice &&
      docCopy.isConverting &&
      (docCopy.documentType === DOC_TYPE.BILL ||
        docCopy.documentType === DOC_TYPE.FA_BILL)
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.ORDER];
    } else if (
      props.booksDocument?.linkedDocuments?.[0]?.documentType ===
      DOC_TYPE.PURCHASE_REQUEST
    ) {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.PURCHASE_REQUISITION];
    } else {
      moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    }

    const entityId = props.booksDocument.id || props.booksDocument.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
      if (
        props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW
      ) {
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: attachmentList.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: attachmentList.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      }
    });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType =
      DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    const entityId = booksDocument.id || booksDocument.entityId || '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId:
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.QUOTE ||
        props.booksDocument.documentType === DOC_TYPE.SALES_ORDER
          ? entityId
          : ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch((err) => {
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  ////////////////////////////////////////////////////
  ////////// US CUSTOM TAX FUNCTIONALITIES ///////////
  ////////////////////////////////////////////////////
  // This is triggered when contact is set/changed
  const calculateUSTaxOnContactChange = (tempDocument: Document) => {
    const tempProductRows = [...(tempDocument.items as DocumentItem[])];
    if (contact && tempProductRows.length > 0) {
      FAItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: tempDocument.shipTo,
        shipFrom: tempDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (ignoreCalculateUSTax === true) {
        const nonUsResident =
          payload.shipTo &&
          payload.shipTo.country !== COUNTRY_CODES.US &&
          payload.shipTo &&
          payload.shipTo.country !== 'United States of America' &&
          payload.shipTo.country !== 'United States'
            ? true
            : false;
        enableTaxColumForUS(nonUsResident);
        setIgnoreCalculateUSTax(false);
        return;
      }

      if (contact.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      [...tempProductRows].forEach((row: any, index: number) => {
        FAItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        FAItemTaxCalculator.setInitialValues();
        payload.lines.push({
          amount: FAItemTaxCalculator.item.totalWithDiscount,
          description: FAItemTaxCalculator.item.productDescription,
          quantity: FAItemTaxCalculator.item.productQuantity,
          taxAmount: 0,
          taxCode: null
        });
        FAItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...tempProductRows];
            taxes.lines.forEach((taxLine: any, index: number) => {
              FAItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              FAItemTaxCalculator.setInitialValues();
              FAItemTaxCalculator.item.taxAmount = null as any;
              FAItemTaxCalculator.item.taxAmount = taxLine.tax;
              FAItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = FAItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                items: [...prodRows]
              };
            });
          },
          (err) => {
            console.error(
              'Error calculating tax on contact change: ',
              err.data
            );
            if (
              !usTaxCalculateAlertVisible.current &&
              err.data?.errorMessage?.includes('postal code is not valid')
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              undefined,
              tempProductRows,
              payload.shipTo
            );
          }
        );
      }
    }
  };

  const calculateUSTax = (
    updatedItemIndex: any,
    items?: DocumentItem[],
    shippingInfo?: any
  ) => {
    let lineItems = items?.length ? items : [...productRows];
    if (lineItems.length > 0) {
      FAItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact?.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: shippingInfo ? shippingInfo.shipTo : booksDocument.shipTo,
        shipFrom: shippingInfo ? shippingInfo.shipFrom : booksDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (contact?.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      lineItems.forEach((row: any, index: number) => {
        FAItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        FAItemTaxCalculator.setInitialValues();
        let taxAmount: any = FAItemTaxCalculator.calculateTaxAmount();

        if (updatedItemIndex === undefined || updatedItemIndex === index) {
          taxAmount = 0;
        }
        if (items !== undefined) {
          taxAmount = null;
        }

        payload.lines.push({
          amount: FAItemTaxCalculator.item.totalWithDiscount,
          description: FAItemTaxCalculator.item.productDescription,
          quantity: FAItemTaxCalculator.item.productQuantity,
          taxAmount: taxAmount,
          taxCode: ''
        });
        FAItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...lineItems];
            taxes.lines.forEach((taxLine: any, index: number) => {
              FAItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              FAItemTaxCalculator.setInitialValues();
              FAItemTaxCalculator.item.taxAmount = null as any;
              FAItemTaxCalculator.item.taxAmount = taxLine.tax;
              FAItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = FAItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                items: [...prodRows]
              };
            });
          },
          (err) => {
            console.error('Error calculating tax: ', err.data);
            if (
              !usTaxCalculateAlertVisible.current &&
              err.data?.errorMessage?.includes('postal code is not valid')
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              updatedItemIndex,
              lineItems,
              payload.shipTo
            );
          }
        );
      } else {
        handleUSLineItemsWhenTaxIsOff(
          updatedItemIndex,
          lineItems,
          payload.shipTo
        );
      }
    }
  };

  const handleUSLineItemsWhenTaxIsOff = (
    updatedItemIndex: any,
    lineItems: DocumentItem[],
    shipTo: any
  ) => {
    let prodRows: any[] = [...lineItems];
    lineItems.forEach((row: any, index: number) => {
      FAItemTaxCalculator.item = {
        ...row,
        ...getDiscountRelatedKeys(
          row.discountInPercent ? row.discount + '%' : row.discount
        )
      };
      FAItemTaxCalculator.setInitialValues();
      let taxAmount = FAItemTaxCalculator.calculateTaxAmount();
      if (updatedItemIndex === undefined || updatedItemIndex === index) {
        taxAmount = 0 as number;
      }
      FAItemTaxCalculator.item.taxAmount = taxAmount;
      FAItemTaxCalculator.updateCalculatedValues();
      prodRows[index] = FAItemTaxCalculator.item;
    });
    setBooksDocument((prevState: any) => ({
      ...prevState,
      shipTo: shipTo,
      items: [...prodRows]
    }));
    setProductRows([...prodRows]);
  };

  const showUSInvalidZipCodeAlert = (message: string) => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m mr-s',
        onClick: () => {
          usTaxCalculateAlertVisible.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white',
        onClick: () => {
          setContactMode(DOCUMENT_MODE.EDIT);
          setShowAddContactPopup(true);
          usTaxCalculateAlertVisible.current = false;
        }
      }
    ];
    showAlert('Error', message, alertButtonConfig);
  };

  const enableTaxColumForUS = (flag: boolean) => {
    let config = columnConfig;
    config = config.map((column: any) => {
      if (column.key === 'taxAmount') {
        column.editable = flag;
      }
    });
    setColumnConfig(columnConfig);
  };

  const showTaxField = () =>
    getTenantTaxSystem() === TAX_SYSTEM.US &&
    tenantInfo.complianceEnabled &&
    !(
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
      props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    );

  const getButtonsForRow = (row: any) => {
    let buttons: any[] = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      buttons.push({
        title: '',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: 'p-v-0 inventory-icon-document',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    return buttons;
  };

  const getContextMenu = (row: any) => {
    let contextMenu = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      contextMenu.push({
        title: row.reservedQuantitiesData
          ? 'Update reserved stock'
          : 'Add reserved stock',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: ' p-0',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    // if (tenantInfo.country === TAX_SYSTEM.INDIA_GST) {
    //   contextMenu.push({
    //     title: 'HSN/SAC',
    //     icon: DKIcons.ic_edit,
    //     className: ' p-0',
    //     onClick: (data: any) => onOpenHSN_SAC(data)
    //   });
    // }
    let contactObj = contact || props.booksDocument.contact;
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      contactObj?.tdsInfoIndia?.deductionApplicable &&
      row?.product?.tdsApplicableIndia
    ) {
      contextMenu.push({
        title: 'Deduct TDS',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any) => onDeductTDS(data)
      });
    }
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      !Utility.isEmpty(row?.product) &&
      (props.booksDocument.documentType === DOC_TYPE.BILL ||
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER)
    ) {
      contextMenu.push({
        title: 'ITC',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenITCOptions(data)
      });
    }
    if (
      tenantInfo?.landedCostSettings?.landedCost &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      (props.booksDocument.landedCost || booksDocument.landedCost)
    ) {
      contextMenu.push({
        title: 'Allocate Cost',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenAllocateCost(data)
      });
    }
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      row.gstType !== 3 &&
      checkifCustomFieldCanEdit
    ) {
      contextMenu.push({
        title: 'Tax',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenGSTValue(data)
      });
    }
    if (checkifCustomFieldCanEdit) {
      contextMenu.push({
        title: 'Add/View Asset Details',
        icon: DKIcons.ic_settings,
        className: ' p-0',
        onClick: (data: any) => openAssetDetails(data)
      });
    }

    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenProductCFSettings(true)
    });
    if (
      props?.booksDocument?.linkedDocuments?.[0]?.documentType !==
        'PURCHASE_REQUEST' &&
      checkifCustomFieldCanEdit
    ) {
      if (
        props.documentMode === DOCUMENT_MODE.EDIT &&
        row.assetDetails &&
        row.assetDetails.length > 0
      ) {
        let idList = row.assetDetails.map((asset: any) => asset.id);
        if (Utility.isEmpty(idList)) {
          contextMenu.push({
            title: 'Delete',
            icon: DKIcons.ic_delete,
            className: ' p-0',
            onClick: (data: any) => onDelete(data)
          });
        }
      } else {
        contextMenu.push({
          title: 'Delete',
          icon: DKIcons.ic_delete,
          className: ' p-0',
          onClick: (data: any) => onDelete(data)
        });
      }
    }

    if (props.draftType === DraftTypes.READONLY) {
      // if (row.assetDetails && row.assetDetails !== null && row.assetDetails !== undefined && row.assetDetails.length > 0) {
      contextMenu = contextMenu.filter((menu: any) => {
        return menu.title === 'Add/View Asset Details';
      });
      // }   else    {
      //     return []
      // }
    }
    return contextMenu;
  };
  const onTDSDeduct = (tdsData: any) => {
    let rows = [...productRows];
    setCurrentRowTDSInfo(null);
    rows[lastUpdatedIndex.current].tdsInfoIndia = tdsData;
    setProductRows(rows);
  };
  const onITCOptionSelected = (itcData: any) => {
    let rows = [...productRows];
    setShowITCPopup(false);
    setCurrentRowITCInfo(null);
    rows[itcData.rowIndex].itcIneligibleType = itcData.itcValue;
    setProductRows(rows);
  };

  const onHSNChange = (hsnData: any) => {
    let rows = [...productRows];
    setShowHSNPopup(false);
    setCurrentRowHSNInfo(null);
    rows[hsnData.rowIndex].hsnOrSacCode = hsnData.hsnValue;
    setProductRows(rows);
  };

  const onGSTValueChange = (gstData: any) => {
    let rows = [...productRows];
    setShowGSTPopup(false);
    rows[gstData.rowIndex].igstAmount = Number(gstData.igstValue);
    rows[gstData.rowIndex].sgstAmount = Number(gstData.sgstValue);
    rows[gstData.rowIndex].cgstAmount = Number(gstData.cgstValue);
    rows[gstData.rowIndex].cessAmount = Number(gstData.cessValue);
    rows[gstData.rowIndex].userSetTaxes = true;
    setProductRows(rows);
  };

  const onLandedCostDataUpdate = (data: any) => {
    let rows = [...productRows];
    let landedCostCategory = JSON.parse(data?.formData?.landedCostCategory);
    rows[data.rowIndex]['landedCostDetails'] = data.formData;
    setProductRows(rows);
    setShowLandedCostPopup(false);
    setBooksDocument({
      ...booksDocument,
      landedCost: true
    });

    if (props.documentMode === DOCUMENT_MODE.NEW) {
      let updatedState = booksDocument;
      updatedState['linkedDocuments'] = [];
      props?.booksDocument?.landedProducts.forEach((element: any) => {
        updatedState.linkedDocuments.push({
          documentSequenceCode: element.documentSequenceCode,
          documentCode: element.purchaseInvoiceCode,
          documentType: DOC_TYPE.BILL
        });
      });
      setBooksDocument({
        ...updatedState,
        landedCost: true
      });
    }
  };

  ////////////////// setup payment - start /////////////////
  const renderPaymentMethodAction = () => {
    const updatedPaymentOptions = SETUP_PAYMENT[getTenantTaxSystem()].map(
      (item: any) => {
        if (
          !Utility.isEmpty(paymentOptionState) &&
          !Utility.isEmpty(paymentOptionState[item.key])
        ) {
          item.isConnected = paymentOptionState[item.key][0].connected;
        }

        if (
          props?.booksDocument?.paymentInformation?.connectionType.toLowerCase() ===
          item.key
        ) {
          item.paymentInfo = props?.booksDocument?.paymentInformation;
        }

        return item;
      }
    );

    const getByTitle = () => {
      if (Utility.isEmpty(booksDocument?.paymentInformation?.connectionType)) {
        return props.documentMode === DOCUMENT_MODE.VIEW ? '' : 'Pay';
      } else {
        return Utility.convertInTitleCase(
          booksDocument?.paymentInformation?.connectionType
        );
      }
    };

    return (
      <div
        className={`p-h-s p-v-xs border-radius-s-2 position-relative fw-m ${
          props.documentMode !== DOCUMENT_MODE.VIEW ? 'cursor-hand' : ''
        }`}
        onClick={() => {
          if (
            Utility.isEmpty(contact) ||
            props.documentMode === DOCUMENT_MODE.VIEW
          ) {
            return;
          }
          setSetupPaymentListOpen(!setupPaymentListOpen);
        }}
      >
        <DKLabel
          className={`${
            Utility.isEmpty(contact) ||
            props.documentMode === DOCUMENT_MODE.VIEW
              ? 'text-gray'
              : 'text-blue'
          }`}
          text={getByTitle()}
        />

        {/* options */}
        {setupPaymentListOpen && (
          <DKListPicker2
            title="Payment Options"
            data={updatedPaymentOptions}
            className="position-absolute z-index-3 shadow-m ml-l border-s"
            style={{ right: -3, top: 32, width: 220 }}
            onSelect={(index: any, value: any) => {
              if (value.key === 'notApplicable') {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    paymentInformation: null
                  };
                });
              }

              if (!value.isConnected) {
                return;
              }
              if (
                !Utility.isEmpty(paymentOptionState) &&
                !Utility.isEmpty(paymentOptionState[value.key])
              ) {
                const po = paymentOptionState[value.key][0];
                if (
                  props.documentMode === DOCUMENT_MODE.EDIT ||
                  props.documentMode === DOCUMENT_MODE.COPY
                ) {
                  if (
                    Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (
                    !Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected &&
                    value.key === 'stripe'
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (value.isConnected) {
                    setSelectedPaymentOption(value);
                    setBooksDocument((prevState: any) => {
                      return {
                        ...prevState,
                        paymentInformation: {
                          ...prevState?.paymentInformation,
                          connectionType: value.key.toUpperCase(),
                          paymentAccountDto: {
                            ...props?.booksDocument?.paymentInformation
                              ?.paymentAccountDto,
                            modules: po.modules,
                            connected: po.connected,
                            connectionName: po.connectionName,
                            connectionId: po.connectionId
                          }
                        }
                      };
                    });
                    setSetupPaymentListOpen(!setupPaymentListOpen);
                  }
                }

                if (props.documentMode === DOCUMENT_MODE.NEW) {
                  if (
                    Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  if (
                    !Utility.isEmpty(booksDocument?.contact?.emailId) &&
                    value.isConnected &&
                    value.key === 'stripe'
                  ) {
                    setShowEmailPopupForPayment(true);
                  }

                  setSelectedPaymentOption(value);
                  setBooksDocument((prevState: any) => {
                    return {
                      ...prevState,
                      paymentInformation: {
                        ...prevState?.paymentInformation,
                        paymentAccountDto: {
                          ...prevState?.paymentInformation?.paymentAccountDto,
                          modules: [DOC_TYPE.RECEIVE_PAYMENT],
                          connected: po.connected,
                          connectionName: po.connectionName,
                          connectionId: po.connectionId,
                          key: value.key,
                          logo: ''
                        },
                        paymentLink: '',
                        customerPhone: '',
                        connectionType: value.key.toUpperCase()
                      }
                    };
                  });
                  setSetupPaymentListOpen(!setupPaymentListOpen);
                }
              }
            }}
            onClose={() => {
              setSetupPaymentListOpen(false);
            }}
            renderer={(index: number, obj: any) => {
              return (
                <div
                  className="row justify-content-between p-v-xs"
                  onClick={() => {
                    if (!obj.isConnected && obj.key !== 'notApplicable') {
                      window.open(obj.link);
                    }
                  }}
                >
                  {obj.key !== 'notApplicable' && (
                    <DKIcon src={obj.icon} className="ic-s" />
                  )}
                  {obj.key === 'notApplicable' && <DKLabel text={obj.label} />}
                  {
                    <DKLabel
                      className={`${
                        obj.isConnected ? 'text-green' : 'text-gray'
                      }`}
                      text={`${
                        obj.key !== 'notApplicable'
                          ? obj.isConnected
                            ? 'Connected'
                            : 'Connect'
                          : ''
                      }`}
                      onClick={() => {
                        window.open(obj.link);
                      }}
                    />
                  }
                </div>
              );
            }}
          />
        )}
      </div>
    );
  };
  ///////////////// setup payment -end /////////////////////

  //////////////// INVOICE NOW SECTION FOR DOCUMENT /////////////////////
  const showInvoiceNowDetails = () => {
    if (
      (props.documentMode === DOCUMENT_MODE.VIEW ||
        props.documentMode === DOCUMENT_MODE.EDIT) &&
      eInvoiceData.documentType === DOC_TYPE.INVOICE
    ) {
      if (eInvoiceData.einvoiceInfoIndia) {
        return (
          <div className="w-3/4">
            <div className="row">
              <span className="fw-m mr-2">IRN Number : </span>
            </div>
            <div className="row mb-1">{eInvoiceData.einvoiceInfoIndia.irn}</div>
            {getInvoiceNowStatus(eInvoiceData) !==
              INVOICE_NOW_TYPES.CANCELLED && (
              <>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Number : </span>
                  {eInvoiceData.einvoiceInfoIndia &&
                    eInvoiceData.einvoiceInfoIndia.ackNo}
                </div>
                <div className="row">
                  <span className="fw-m mr-2">Acknowledgement Date : </span>
                  {eInvoiceData.einvoiceInfoIndia &&
                    eInvoiceData.einvoiceInfoIndia.ackDt}
                </div>
              </>
            )}
          </div>
        );
      }
    }
  };

  const generateEInvoiceIndia = () => {
    if (props && props.booksDocument && tenantInfo) {
      let payload: any = {};
      let eInvoiceData: any = localStorage.getItem('e-Invoice_data');
      if (eInvoiceData) {
        let currentTime = new Date();
        let currentIndiaDate = new Date(
          currentTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
        );
        let tokenExpiry: any = localStorage.getItem('e-Invoice_tokenExpiry');

        if (tokenExpiry) {
          let tokenExpiryDate = new Date(tokenExpiry);

          if (currentIndiaDate < tokenExpiryDate) {
            payload = JSON.parse(eInvoiceData);
            payload.gstin = tenantInfo.gstin;
            payload.id = props.booksDocument.id;
            try {
              EInvoiceService.generateEInvoice(payload).then(
                (res: any) => {
                  if (!Utility.isEmpty(res?.errorDetails)) {
                    let errors = res?.errorDetails;
                    if (errors?.length) {
                      let HTML = `<div>
                        <ul>
                          ${errors.map((error: any) => {
                            return `<li>${error?.errorMessage}</li>`;
                          })}
                        </ul>
                      </div>`;
                      showAlert('eInvoice portal reported errors below', HTML);
                    }
                  }

                  if (props.booksDocument.salesInvoiceCode) {
                    InvoiceService.getInvoiceByCode(
                      props.booksDocument.salesInvoiceCode
                    ).then(
                      (data: any) => {
                        let invoiceDetailsData: any =
                          getUpdatedInvoiceObject(data);
                        seteInvoiceData(invoiceDetailsData);
                      },
                      (err) => {
                        console.error('Error loading detailed invoice: ', err);
                      }
                    );
                  }
                },
                (err) => {
                  console.error('Error generating eInvoice: ', err);
                }
              );
            } catch (error: any) {}
          } else {
            setShowIRPCredPopup(true);
          }
        } else {
          setShowIRPCredPopup(true);
        }
      } else {
        setShowIRPCredPopup(true);
      }
    }
  };

  const getFADetailsPopup = () => {
    return (
      <FixedAssetDetails
        documentType={props.booksDocument.documentType}
        draftType={props.draftType}
        documentDate={documentDate}
        lineItemData={currentRowData}
        booksDocument={booksDocument}
        total={booksDocument.total}
        documentStatus={props.documentMode}
        currency={booksDocument.currency || booksDocument.currencyCode}
        onSave={(rowIndex: number, assetDetails: any) => {
          let itemsCopy = [...booksDocument.items];
          itemsCopy[rowIndex]['assetDetails'] = assetDetails;
          setBooksDocument((preState: any) => ({
            ...preState,
            items: itemsCopy
          }));
          setShowAssetDetailsForm(false);
        }}
        onClose={() => setShowAssetDetailsForm(false)}
      />
    );
  };

  const openAssetDetails = ({ rowIndex, rowData }: any) => {
    setCurrentRowData({ rowIndex, rowData });
    setShowAssetDetailsForm(true);
  };

  const populateBarcodeSearchData = (data: any) => {
    setLastUpdatedColumn(undefined);
    let rowsObj = [...productRows];
    let count = productRows.length;
    data.forEach((item: any) => {
      let newRow = DocumentConfigManager.getBlankRow(
        props.booksDocument.documentType
      );
      newRow.productQuantity = item.productQuantity;
      newRow.unitPrice = 0;
      newRow.lineNumber = count === 0 ? 0 : count;
      newRow.firstAmountChangeDone = false;
      newRow.invalidFields = DocumentConfigManager.getRequiredFields(
        props.booksDocument.documentType
      );
      rowsObj.push(newRow);
      count++;
    });

    let index = productRows.length;
    data.forEach((item: any) => {
      rowsObj[index] = onProductChange(index, item, true, rowsObj);
      index++;
    });

    if (getTenantTaxSystem() !== TAX_SYSTEM.US) {
      let currentIndex = 0;
      rowsObj.forEach((item: any) => {
        rowsObj[currentIndex] = calculateFATaxesAndAmount(
          currentIndex++,
          rowsObj,
          true
        ) as DocumentItem;
      });
      setProductRows([...rowsObj]);
      setBarcodeSearchData(data);
    } else {
      setBarcodeSearchData(data);
      calculateUSTax(0, rowsObj);
    }
  };

  const cancelEInvoiceIndia = () => {
    if (props && props.booksDocument && tenantInfo) {
      let eInvoiceData: any = localStorage.getItem('e-Invoice_data');
      if (eInvoiceData) {
        let currentTime = new Date();
        let currentIndiaDate = new Date(
          currentTime.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
        );
        let tokenExpiry: any = localStorage.getItem('e-Invoice_tokenExpiry');

        if (tokenExpiry) {
          let tokenExpiryDate = new Date(tokenExpiry);

          if (currentIndiaDate < tokenExpiryDate) {
            setShowCancelEInvoice(true);
          } else {
            setShowIRPCredPopup(true);
          }
        } else {
          setShowIRPCredPopup(true);
        }
      } else {
        setShowIRPCredPopup(true);
      }
    }
  };

  const redirectEInvoiceFailureLog = () => {
    dispatch(removeDraft(props.booksDocument.id));
    RouteManager.navigateToPage(PAGE_ROUTES.FAILED_INVOICES);
  };

  const getInvoiceNow = (documentData?: any) => {
    if (
      documentData.documentType === DOC_TYPE.INVOICE &&
      tenantInfo.country === COUNTRY_CODES.IN &&
      tenantInfo.indiaEinvoice
    ) {
      if (
        (props.documentMode === DOCUMENT_MODE.VIEW ||
          props.documentMode === DOCUMENT_MODE.EDIT) &&
        !props.isDocumentLoading
      ) {
        let _status: any = {
          title: '',
          color: ''
        };
        switch (getInvoiceNowStatus(documentData)) {
          case INVOICE_NOW_TYPES.FAILED:
            _status = {
              title: INVOICE_NOW_TYPES.FAILED,
              color: 'bg-chip-red text-red '
            };
            break;
          case INVOICE_NOW_TYPES.GENERATED:
            _status = {
              title: INVOICE_NOW_TYPES.GENERATED,
              color: 'bg-chip-green text-green'
            };
            break;
          case INVOICE_NOW_TYPES.PENDING:
            _status = {
              title: INVOICE_NOW_TYPES.PENDING,
              color: 'bg-chip-orange text-orange'
            };
            break;
          case INVOICE_NOW_TYPES.CANCELLED:
            _status = {
              title: INVOICE_NOW_TYPES.CANCELLED,
              color: 'bg-chip-red text-red '
            };
            break;
        }

        return (
          <div className={`column parent-width p-h-xxl pt-s position-relative`}>
            <div className="row justify-content-between align-items-center mt-1 bg-gray-50 p-2 border-radius-m items-center">
              <div className="flex items-center">
                <DKLabel className="fw-m" text={`e-Invoice Status :`} />
                <DKLabel
                  text={Utility.convertInTitleCase(_status.title)}
                  style={{
                    textTransform: 'capitalize'
                  }}
                  className={`text-dark-gray ${_status.color} border-radius-r ml-r p-h-s p-v-xs fw-m mr-r`}
                />
                {_status.title === INVOICE_NOW_TYPES.PENDING && (
                  <DKLabel
                    className="ml-3"
                    text={`Push your invoice to the e-invoicing portal(IRP) to genrate an IRN`}
                  />
                )}
                {_status.title === INVOICE_NOW_TYPES.FAILED &&
                  documentData.einvoiceInfoIndia.errorDetails && (
                    <DKLabel
                      className="ml-3"
                      text={`${documentData.einvoiceInfoIndia.errorDetails[0].errorMessage}`}
                    />
                  )}
              </div>
              <div>
                {_status.title === INVOICE_NOW_TYPES.PENDING && (
                  <DKButton
                    title={'Generate e-Invoice'}
                    className="text-green fw-m"
                    onClick={() => {
                      generateEInvoiceIndia();
                    }}
                  />
                )}
                {(_status.title === INVOICE_NOW_TYPES.CANCELLED ||
                  _status.title === INVOICE_NOW_TYPES.FAILED) && (
                  <DKButton
                    title={'View Failure Log'}
                    className="text-blue fw-m"
                    onClick={() => {
                      redirectEInvoiceFailureLog();
                    }}
                  />
                )}
                {_status.title === INVOICE_NOW_TYPES.GENERATED && (
                  <DKButton
                    title={'Cancel e-Invoice'}
                    className="text-red fw-m"
                    onClick={() => {
                      cancelEInvoiceIndia();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const isDplChecked = () => {
    if (!dimensionData || !contact || Utility.isEmpty(props?.draftType) || props?.draftType === DraftTypes.READONLY) return false;
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0)
      return false;
    return true;
  };

  const checkForDpl = (contact: any) => {
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    )?.[0];
    if (!dplContactCustomField) return;
    if (dplContactCustomField?.value === 'Yes') {
      showToast(
        'The contact you are using is under denied party list',
        TOAST_TYPE.SUCCESS
      );
    }
  };

  //////////////// INVOICE NOW SECTION FOR DOCUMENT /////////////////////

  //////////////// ADDITIONAL COST SECTION FOR DOCUMENT /////////////////////

  //////////////// ADDITIONAL COST SECTION FOR DOCUMENT /////////////////////

  ////////////////////////////////////////////////////
  ///////////////// MAIN RENDERER ////////////////////
  ////////////////////////////////////////////////////
  const isSetupPaymentVisible =
    props.booksDocument.documentType === DOC_TYPE.INVOICE &&
    // props.documentMode !== DOCUMENT_MODE.VIEW &&
    SETUP_PAYMENT[getTenantTaxSystem()] &&
    SETUP_PAYMENT[getTenantTaxSystem()].length > 0;
  return (
    <>
      {getInvoiceNow(
        !Utility.isEmpty(eInvoiceData) ? eInvoiceData : props.booksDocument
      )}
      <div
        className={`column parent-width p-h-xxl pb-xxl pt-s pt-l position-relative ${
          props.draftType === DraftTypes.READONLY ? 'pointer-events-none' : ''
        }`}
      >
        {props.draftType === DraftTypes.READONLY && (
          <div className="position-absolute parent-width parent-height bg-transparent z-index-2 left-0 top-0"></div>
        )}
        <div className="row justify-content-between align-items-start ">
          {!Utility.isEmpty(customLocation) &&
          props.booksDocument.documentType !== DOC_TYPE.BILL &&
          props.booksDocument.documentType !== DOC_TYPE.FA_ORDER &&
          props.booksDocument.documentType !== DOC_TYPE.FA_BILL &&
          props.booksDocument.documentType !== DOC_TYPE.ORDER &&
          props.booksDocument.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
            ? getCustomCompanyDetails()
            : getCompanyDetails()}
          <div className="column align-items-end">
            {(props.booksDocument.documentType === DOC_TYPE.INVOICE ||
              props.booksDocument.documentType === DOC_TYPE.BILL ||
              props.booksDocument.documentType === DOC_TYPE.FA_BILL) && (
              <>
                <DKLabel
                  text={
                    props.booksDocument.documentType === DOC_TYPE.INVOICE
                      ? 'Balance Due'
                      : 'Balance Payable'
                  }
                  className="fs-m"
                />
                <div className="row justify-content-end">
                  {/* show only in case if its invoice document and length of payment options is greater than 0 */}
                  {isSetupPaymentVisible && (
                    <div className="row justify-content-end">
                      {!Utility.isEmpty(contact) && renderPaymentMethodAction()}
                      {showEmailPopupForPayment && (
                        <GetEmailForPayment
                          contact={contact}
                          documentMode={props.documentMode}
                          paymentInformationDetials={
                            booksDocument?.paymentInformation
                          }
                          onSave={(data: any) => {
                            setBooksDocument((prevState: any) => {
                              return {
                                ...prevState,
                                contact: {
                                  ...prevState?.contact,
                                  emailId: data?.email
                                },
                                paymentInformation: {
                                  ...prevState?.paymentInformation,
                                  customerEmail: data?.email,
                                  autoCharge: data?.isAutoCharged
                                }
                              };
                            });
                            setContact((prevState: any) => {
                              return {
                                ...prevState,
                                emailId: data?.email
                              };
                            });
                            setShowEmailPopupForPayment(false);
                          }}
                          onCancel={() => {
                            setShowEmailPopupForPayment(false);
                            setSelectedPaymentOption(null);
                            setBooksDocument((prevState: any) => {
                              return {
                                ...prevState,
                                paymentInformation: null
                              };
                            });
                          }}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className={'fw-m fs-xxl ml-r ' + GOOGLE_NO_TRANSLATE_CLASS}
                  >
                    {`${Utility.getCurrencySymbolFromCode(
                      booksDocument.currency
                    )} ${NumberFormatService.getNumber(dueAmount)}`}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {Utility.isUSorg() &&
          Utility.isDPLSettingEnabled() &&
          Utility.isNotEmpty(contact) &&
          Utility.isNotEmpty(dimensionData) &&
          isDplChecked() && (
            <div className="row">
              <ReCheckButton
                onClick={() => {
                  const billingAddress: any[] = [];
                  const countryCodeMap: any = {};
                  COUNTRIES_WITH_CURRENCIES.forEach((country) => {
                    countryCodeMap[country?.country?.toLowerCase()] =
                      country?.countryCode;
                  });
                  if (contact?.billingAddress) {
                    contact?.billingAddress?.forEach((address: any) => {
                      if (address?.country) {
                        billingAddress.push({
                          ...address,
                          countryCode:
                            countryCodeMap?.[address?.country?.toLowerCase()]
                        });
                      }
                    });
                  }
                  const DplCustomFieldId = dimensionData?.content?.filter(
                    (customField: any) =>
                      customField?.system &&
                      customField?.shortName === 'denied_parties_custom_field'
                  )?.[0]?.id;
                  const dplContactCustomField = contact?.customField?.filter(
                    (cField: any) => cField?.id == DplCustomFieldId
                  );
                  if (
                    !dplContactCustomField ||
                    dplContactCustomField?.length == 0
                  )
                    return;
                  const payload = {
                    name: contact?.name ?? '',
                    billingAddress: billingAddress,
                    customField: dplContactCustomField
                  };
                  showLoader();
                  ContactService.recheck(contact?.id, payload)
                    .then((res) => {
                      removeLoader();
                      if (res) {
                        showToast(
                          'The contact you are using is under denied party list',
                          TOAST_TYPE.SUCCESS
                        );
                      } else {
                        showToast(
                          'The contact you are using is not under denied party list',
                          TOAST_TYPE.SUCCESS
                        );
                      }
                      dispatch(fetchContacts(''));
                    })
                    .catch((err) => {
                      removeLoader();
                    });
                }}
              />
            </div>
          )}
        <div className={`row justify-content-between align-items-stretch mt-l`}>
          <div className="column justify-content-between position-relative width-80 pr-l">
            {getContactPicker()}
            {getDeliveryAddressDetails()}
            <div className="row width-auto align-items-start gap-3">
              {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                !Utility.isEmpty(contact) &&
                getPlaceOfSupply()}
              {(props.booksDocument.documentType === DOC_TYPE.BILL ||
                props.booksDocument.documentType === DOC_TYPE.ORDER ||
                props.booksDocument.documentType === DOC_TYPE.FA_ORDER ||
                props.booksDocument.documentType === DOC_TYPE.FA_BILL ||
                props.booksDocument.documentType ===
                  DOC_TYPE.JOB_WORK_OUT_ORDER ||
                Utility.isFaDropship(props.booksDocument)) &&
                getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                !Utility.isEmpty(contact) &&
                getSourceOfDestination()}
            </div>
            {/* {getCustomFields()} */}
            {invoiceConfigTableId &&
              ((props.documentMode === DOCUMENT_MODE.VIEW &&
                booksDocument.customField?.length > 0) ||
                props.documentMode !== DOCUMENT_MODE.VIEW) &&
              props.booksDocument.documentType !==
                DOC_TYPE.JOB_WORK_OUT_ORDER &&
              getCustomFields()}
          </div>
          {getRightInfoPanel()}
        </div>
        <div className="flex flex-row flex-wrap items-start justify-between">
          <div
            className={`flex flex-col items-start w-80 ${
              isCenterAlign ? 'sm:-mt-1' : ''
            }`}
          >
            {/* {getComplianceHeader()} */}
            {/* <div className="flex flex-row w-full justify-between mt-2">
            <label className="mt-3 text-gray-500">Tax Invoice No:</label>
            <div className="position-relative">
              <div className="text-align-left cursor-pointer font-medium">
                <div className="flex flex-row justify-between pl-2 rounded">
                  <CustomInput
                    hasError={false}
                    defaultValue={''}
                    onChange={
                      (event) => {}
                      // updateProductData(fieldName, event.currentTarget.value)
                    }
                    placeholder={''}
                    labelName={''}
                    type={'string'}
                  />
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
        <div
          className={`column parent-width ${
            props.documentMode === DOCUMENT_MODE.VIEW
              ? 'pointer-events-auto'
              : ''
          }`}
        >
          {getDataGrid()}
        </div>

        {!(
          props.booksDocument.isPartialInvoice ||
          props.booksDocument.isPartialBill ||
          props.booksDocument.isPartialSalesOrder ||
          Utility.isFaDropship(props.booksDocument) ||
          props.documentMode === DOCUMENT_MODE.VIEW ||
          ((props?.booksDocument?.documentType === DOC_TYPE.ORDER ||
            props?.booksDocument?.documentType === DOC_TYPE.FA_ORDER) &&
            props?.booksDocument?.linkedDocuments?.[0]?.documentType ===
              'PURCHASE_REQUEST')
        ) &&
          checkifCustomFieldCanEdit && (
            <div className="row">
              <DKButton
                title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
                onClick={() => addNewAssetGroup()}
                className={`walkthrough-step-6 ${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
              />
              {/*
// Change to FA
            {productRows.length > 0 && (
              <DKButton
                title={`- Clear all items`}
                onClick={() => clearAllItems()}
                className={`${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1 }}
              />
            )} */}
              {Utility.isSalesDocument(props.booksDocument) &&
                props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
                productRows.length > 0 &&
                checkifCustomFieldCanEdit && (
                  <DKButton
                    title={
                      (booksDocument.reservedStock
                        ? 'Deactivate '
                        : 'Activate ') + 'reserved stock'
                    }
                    onClick={() => toggleReserveStock()}
                    className={`text-blue fw-m p-0`}
                    style={{ marginTop: -10, zIndex: 1 }}
                  />
                )}
            </div>
          )}

        {/* Footer section */}
        <div className="flex flex-row items-start justify-content-between box-border w-full mt-m">
          <div
            className={`column ${
              props.draftType === DraftTypes.READONLY &&
              props.booksDocument.documentType === DOC_TYPE.INVOICE
                ? 'pointer-events-auto'
                : 'mt-xl'
            }`}
          >
            <textarea
              className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
              style={{
                width: 500,
                height: 130,
                backgroundColor: 'rgb(250, 250, 250)',
                border: '1px dashed rgb(200, 200, 200)'
              }}
              placeholder={t(`DOCUMENT.MEMO_OPTIONAL`)}
              value={booksDocument.memo}
              onChange={(e: any) => {
                setBooksDocument((prevState: any) => {
                  return {
                    ...prevState,
                    memo: e.target.value,
                    isDocumentTouched:
                      e.target.value?.trim() !== '' &&
                      booksDocument.memo?.trim() !== '' &&
                      e.target.value?.trim() !== booksDocument.memo?.trim()
                  };
                });
              }}
            ></textarea>
            {
              <Fragment>
                <DKButton
                  title={
                    <>
                      + Attach files
                      <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                    </>
                  }
                  className={`${
                    props.draftType === DraftTypes.READONLY ||
                    checkifCustomFieldCanEdit === false
                      ? 'text-gray'
                      : 'text-blue'
                  } mt-r fw-m`}
                  style={{ paddingLeft: 0 }}
                  disabled={
                    props.draftType === DraftTypes.READONLY ||
                    checkifCustomFieldCanEdit === false
                  }
                  onClick={triggerAttachmentUpload}
                />
                <div className="row">{getAttachments()}</div>
              </Fragment>
            }

            <div className="row mt-r">
              {!props.draftData.isLoading &&
                (props.documentMode === DOCUMENT_MODE.EDIT ||
                  props.documentMode === DOCUMENT_MODE.VIEW) && (
                  <DocumentActionMenu
                    booksDocument={{ ...props.booksDocument, ...booksDocument }}
                    draftId={props.draftData.id}
                    position="bottom"
                    draftType={props.documentMode}
                    updateWithoutClosing={() => {
                      if (props.updateDocWithoutClosing) {
                        props.updateDocWithoutClosing();
                      }
                    }}
                  />
                )}
            </div>
            <div className="mt-">{showInvoiceNowDetails()}</div>
          </div>
          <div
            className={props.documentMode === DOCUMENT_MODE.VIEW ? 'mt-r' : ''}
            style={{ width: 330 }}
          >
            <FADocumentSummaryView
              booksDocument={booksDocument}
              documentMode={
                props.documentMode && checkifCustomFieldCanEdit
                  ? props.documentMode
                  : DOCUMENT_MODE.VIEW
              }
              roundOffDirty={roundOffDirty}
              onTotalOrAdditionalChargeChange={(additionalInfo) => {
                let paidAmount =
                  booksDocument.knockoffInfo?.reduce(
                    (total: number, paymentInfo: any) =>
                      total +
                      Number(paymentInfo?.amount || 0) *
                        Number(paymentInfo?.exchangeRate || 1),
                    0
                  ) || 0;
                if (tenantInfo.country === COUNTRY_CODES.IN) {
                  const tdsAmount =
                    booksDocument.tdsInfo?.reduce(
                      (total: number, tdsData: any) =>
                        total + Number(tdsData?.tdsAmount || 0),
                      0
                    ) || 0;
                  paidAmount = paidAmount + tdsAmount;
                }
                setDueAmount(
                  Utility.roundOff(
                    additionalInfo.total - paidAmount,
                    Store.getState().authInfo.currentTenantInfo.data
                      .decimalScale
                  )
                );
                setBooksDocument({
                  ...booksDocument,
                  totalAmount: additionalInfo.total,
                  amountToReceiveOrPay: additionalInfo.total - paidAmount,
                  additionalCharges: additionalInfo.additionalCharges
                });
              }}
              onTCSChange={onTCSChange}
              onTaxInclusiveFlagChange={setTaxInclusiveFlag}
              onRoundingOffChange={setRoundOffAmountInDocumentCurrency}
              onCurrencyAndExchangeRateChange={(
                currency: any,
                exchangeRate: number
              ) => {
                if (props.draftData?.data?.isCashInvoice) {
                  loadAccountGroups(currency);
                }
                updateCurrencyAndExchangeRate(
                  { ...booksDocument },
                  currency,
                  exchangeRate
                );
              }}
              onAdditionalChargeAllocationOnLineLevel={(data: any) => {
                setBooksDocument({
                  ...booksDocument,
                  items: data?.bookDocumentItems,
                  additionalCharges: data?.additionalCharges
                });
              }}
              isReadonly={!checkifCustomFieldCanEdit}
            />
          </div>
        </div>
        {showUpdateAddressPopup && (
          <UpdateAddress
            contactDetails={
              props.documentMode === DOCUMENT_MODE.EDIT
                ? booksDocument.contactDto
                : booksDocument.contact
            }
            addressList={
              booksAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                ? billingAddress?.billingAddress
                : shippingAddress?.shippingAddress
            }
            addressType={booksAddressType}
            onCancel={() => setShowUpdateAddressPopup(false)}
            onAddressUpdate={() => onAddressUpdated()}
          />
        )}
        {/* {showAddressPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add New Address'}
          btnList={popupBtnConfig}
          width={'50%'}
        >
          <EditAddress
            contactDetails={
              props.documentMode === DOCUMENT_MODE.EDIT
                ? booksDocument.contactDto
                : booksDocument.contact
            }
            addressList={
              booksAddressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                ? billingAddress?.billingAddress
                : shippingAddress?.shippingAddress
            }
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            addressType={booksAddressType}
          />
        </PopupWrapper>
          )} */}
        {/* {showTDSCalculationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={`TDS Calculation`}
            btnList={[
              {
                title: `Cancel`,
                class: 'border-m mr-s',
                clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
              },
              {
                title: `Submit`,
                class: 'bg-app text-white mr-ss',
                clickAction: POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT
              }
            ]}
            width="40%"
            height="65%"
            disableClickOutside={true}
          >
            <TDSCalculation
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              tdsData={currentRowTDSInfo}
            />
          </PopupWrapper>
        )}
        {showITCPopup && (
          <ItcOptions
            itcData={currentRowITCInfo}
            setITCIneligibleOption={(data: any) => {
              onITCOptionSelected(data);
            }}
            onClose={() => {
              setCurrentRowITCInfo(null);
              setShowITCPopup(false);
            }}
          />
        )} */}
        {showHSNPopup && (
          <HSNForm
            HSNData={currentRowHSNInfo}
            setHSNChangeValue={(data: any) => {
              onHSNChange(data);
            }}
            onClose={() => {
              setCurrentRowHSNInfo(null);
              setShowHSNPopup(false);
            }}
          />
        )}
        {showGSTPopup && (
          <GSTValueForm
            GSTData={currentRowGSTInfo}
            setGSTChangeValue={(data: any) => {
              onGSTValueChange(data);
            }}
            onClose={() => {
              setCurrentRowGSTInfo(null);
              setShowGSTPopup(false);
            }}
          />
        )}
        {showTaxPopup && getTaxForm()}
        {/* {showAddClassPopup && getAddClassForm()} */}
        {getAddContactPopup()}
        {getUpdateOrgPopup()}
        {showTaxExchangeRatePopup && getTaxExchangeRatePopup()}
        {showAssetDetailsForm && getFADetailsPopup()}
        {openProductCFSettings && (
          <CustomFieldSettings
            fields={productCustomFields}
            moduleName={MODULES_NAME.ASSET}
            // moduleName={MODULES_NAME.PRODUCT}
            onSave={async () => {
              try {
                const updatedOrderedCFs = await dispatch(
                  fetchProductCustomFields({
                    status: 'ACTIVE',
                    limit: '1000',
                    module: MODULES_NAME.ASSET
                  })
                );
                setOpenProductCFSettings(false);
                updateLineLevelCForder(updatedOrderedCFs.payload?.content);
              } catch (err: any) {
                console.error('Error fetching product CFs: ', err);
              }
            }}
            onClose={() => setOpenProductCFSettings(false)}
          />
        )}
      </div>
    </>
  );
}
