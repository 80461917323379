import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { GridState } from '../../Models/ReduxStore';
import ProductService from '../../Services/Product';
import { RootState } from '../Store';

const initialState: GridState = {
  data: [] as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE
};

export const fetchBatchTrackingProducts = createAsyncThunk(
  'batchTrackingProduct',
  async (param: any) => {
    const response = await ProductService.fetchProductAdvancedTrackingWarehouse(
      param?.productCode,
      param?.enableQCWarehouse,
      param?.includeRejectedWarehouse
    );
    return response;
  }
);

export const BatchTrackingProductsSlice = createSlice({
  name: 'batchTrackingProducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBatchTrackingProducts.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBatchTrackingProducts.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const selectBatchTrackingProduct = (state: RootState) =>
  state.batchTrackingProducts.data;
export const selectBatchTrackingProductsLoadingStatus = (state: RootState) =>
  state.batchTrackingProducts.status;

export default BatchTrackingProductsSlice.reducer;
